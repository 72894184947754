import { includes } from 'lodash';
import keyCodes from '../constants/keyCodes';
import PUIBase from './pui-base';
import getVersionedClassesAndSelector from '../functions/styleClassVersionUtils';

const ESAPI = require('node-esapi');
const INPUT_STYLES = ["pui-2_0"];
const STYLES_AND_SELECTOR_NAMES = {
  SELECTED:"input-selected", 
  ERROR:"input-error",
  CONTAINER_CLASS:"pui-input-container",
  INPUT_CONTAINER:".pui-input-container",
};

//statuses which have a 2.0 version
const SUPPORTED_PUI_2_0_STATUS = ["selected", "error"];
export default class PUIInput extends PUIBase {
  constructor() {
    super();
    this.defaultSpacingTop = 'small';
    this.defaultInputType = 'text';
  }

  static get observedAttributes() {
    return [
      ...super.observedAttributes,
      'inputid', 'label', 'placeholder', 'textinputtype', 'status', 'disabled', 'name', 'value', 'maxlength',
      'allowedcharacters', 'disableautocomplete', 'notabbing', 'encode', 'inputstyle', 'nevershowclearicon',
      'a11ydescribedby', 'enablerightbutton', 'rightbuttoniconmargin', 'rightbuttonclass', 'required'];
  }

  connectedCallback() {
    super.connectedCallback();
    this.render();
  }

  /*
  Based on the passed in input style, we decide whether to use new or old styles and selectors.
  Need this function because status is not generic.
  */
  _decideStyle(status) {
    var classAndSelectorNames = {}
    var statusSuffix = "";
    if (this._isVersion2()) {
      classAndSelectorNames = getVersionedClassesAndSelector(STYLES_AND_SELECTOR_NAMES, "2_0");
      if (status && includes(SUPPORTED_PUI_2_0_STATUS, status)) { // since some statuses like "disabled" don't have a 2_0 version, we only want to set STATUS with the 2_0 suffix if they have a 2.0 version
        statusSuffix = "-2_0";
      }
    } else {
      classAndSelectorNames = getVersionedClassesAndSelector(STYLES_AND_SELECTOR_NAMES, "");
    }
    if (status) {
      classAndSelectorNames["STATUS"] = `input-${status}${statusSuffix}`;
    }
    return classAndSelectorNames;
  }

  attributeChangedCallback(attrName) {
    super.attributeChangedCallback();
    if (this._innerInput && attrName === "value") {
      this.setValue(this.value);
    } else if (this._innerInput && attrName === "placeholder") {
      this.setPlaceholder(this.placeholder);
    } else {
      this.render();
    }
  }

  rightButtonClickHandler() {
    // Clients may use this callback
    if (this.rightButtonClick) {
      this.rightButtonClick(this.getValue());
    }
  }

  get inputId() {
    return this.getAttribute('inputId') || '';
  }

  set inputId(value) {
    this.setAttribute('inputId', value);
  }

  get label() {
    return this.getAttribute('label') || '';
  }

  set label(value) {
    this.setAttribute('label', value);
  }

  get placeholder() {
    return this.getAttribute('placeholder') || '';
  }

  set placeholder(value) {
    this.setAttribute('placeholder', value);
  }

  /** The type of input to support.
   * 
   * ### Supported values
   * 
   * This control supports any valid `type` that can be passed to an `<input>`
   * element, but has special-cased support for a few specific types:
   * 
   * #### date
   * 
   * `date`, in combination with `allowedCharacters="numeric"`, will auto-format
   * and validate the date as the customer types.
   * 
   * #### numeric
   * 
   * `numeric`, in combination with `allowedCharacters="numeric"`, will prevent
   * the customer from inputting any non-numeric characters
   * 
   * @type {'tel' | 'date' | 'numeric' | string}
   */
  get textInputType() {
    return this.getAttribute('textInputType') || '';
  }

  set textInputType(value) {
    this.setAttribute('textInputType', value);
  }

  get status() {
    return this.getAttribute('status') || '';
  }

  set status(value) {
    this.setAttribute('status', value);
  }

  get disabled() {
    return this.getBooleanAttribute('disabled');
  }

  set disabled(value) {
    this.setBooleanAttribute('disabled', value);
  }

  get name() {
    return this.getAttribute('name') || '';
  }

  set name(value) {
    this.setAttribute('name', value);
  }

  get value() {
    return this.getAttribute('value') || '';
  }

  set value(value) {
    this.setAttribute('value', value);
  }

  get maxLength() {
    return this.getAttribute('maxLength') || '';
  }

  set maxLength(value) {
    this.setAttribute('maxLength', value);
  }

  get iconClass() {
    return this.getAttribute('iconClass') || '';
  }

  set iconClass(value) {
    this.setAttribute('iconClass', value);
  }

  get iconRightClass() {
    return this.getAttribute('iconRightClass') || '';
  }

  set iconRightClass(value) {
    this.setAttribute('iconRightClass', value);
  }

  get allowedCharacters() {
    return this.getAttribute('allowedCharacters') || '';
  }

  set allowedCharacters(value) {
    this.setAttribute('allowedCharacters', value);
  }

  get inputStyle() {
    return this.getAttribute('inputStyle');
  }

  set inputStyle(value) {
    this.setAttribute('inputStyle', value);
  }

  get disableAutocomplete() {
    return this.getBooleanAttribute('disableAutocomplete');
  }

  set disableAutocomplete(value) {
    this.setBooleanAttribute('disableAutocomplete', value);
  }

  get noTabbing() {
    return this.getBooleanAttribute('noTabbing');
  }

  set noTabbing(value) {
    this.setBooleanAttribute('noTabbing', value);
  }

  /**
   * Used to avoid XSS vulnerability
   */
  get encode() {
    return this.getBooleanAttribute('encode');
  }

  set encode(value) {
    return this.setBooleanAttribute('encode', value);
  }

  get neverShowClearIcon() {
    return this.getBooleanAttribute('nevershowclearicon');
  }

  set neverShowClearIcon(value) {
    this.setBooleanAttribute('nevershowclearicon', this.value)
  }

  get required() {
    return this.getBooleanAttribute('required');
  }

  set required(value) {
    this.setBooleanAttribute('required', value);
  }


  /**
   * Value of [aria-describedby](https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-describedby) on the inner input element.
   * @type {string}
   */
  get a11yDescribedBy() {
    return this.getAttribute('a11yDescribedBy')
  }

  set a11yDescribedBy(value) {
    return this.setAttribute('a11yDescribedBy', value)
  }

  /**
   * Whether or not the right aligned button in the input box is enabled
   * @type {boolean}
   */
  get enableRightButton() {
    return this.getAttribute('enableRightButton')
  }

  set enableRightButton(value) {
    return this.setAttribute('enableRightButton', value)
  }

  /**
   * Margin class for the icon of the right button
   * @type {string}
   */
  get rightButtonIconMargin() {
    return this.getAttribute('rightButtonIconMargin')
  }

  set rightButtonIconMargin(value) {
    return this.setAttribute('rightButtonIconMargin', value)
  }

  /**
   * Class for the right button
   * @type {string}
   */
  get rightButtonClass() {
    return this.getAttribute('rightButtonClass')
  }

  set rightButtonClass(value) {
    return this.setAttribute('rightButtonClass', value)
  }

  focus() {
    this.querySelector('input').focus();
    this._inputContainer.classList.add(this.stylesAndSelectors.SELECTED);
    if (this._isVersion2() && this._inputContainer.classList.contains(this.stylesAndSelectors.ERROR)) {
      this._inputContainer.classList.add('input-error-selected-2_0');
    } else {
      this.hideError();
    }
    if (this.getValue().length > 0) {
      this.showCloseIcon();
    } else {
      this.hideCloseIcon();
    }
  }

  unfocus() {
    if (this._isVersion2() && this._inputContainer.classList.contains(this.stylesAndSelectors.ERROR)) {
      this._inputContainer.classList.remove('input-error-selected-2_0');
    }
    this._inputContainer.classList.remove(this.stylesAndSelectors.SELECTED);
  }

  clearInput() {
    this._innerInput.value = '';
    this.hideCloseIcon();
    // [Accessibility] Immediately focus on input field post clearing input
    this.focus();
  }

  getInput() {
    return this._innerInput;
  }

  getValue() {
    return this._innerInput.value;
  }

  setValue(value) {
    this._innerInput.value = value;

    if (this.getValue().length > 0) {
      this.showCloseIcon();
    }

    // Clients may use this callback
    if (this.onSetValue) {
      this.onSetValue(value);
    }
  }

  setPlaceholder(placeholder) {
    this._innerInput.placeholder = placeholder;
  }

  getISOValue() {
    const date = new Date(this.getValue());
    const isoDate = date.toISOString();
    return isoDate;
  }

  showError(errorMessage) {
    this._errorMessage.setText(errorMessage);
    this._errorMessage.show();
    if (this._isVersion2() && this._inputContainer.classList.contains(this.stylesAndSelectors.SELECTED)){// due to the specific edge case of 2.0 date of birth input, if the date is still invalid even after a changing the input, we want to maintain the selected error style
      this._inputContainer.classList.add('input-error-selected-2_0');
    }
    this._inputContainer.classList.add(this.stylesAndSelectors.ERROR);
    this._innerInput.classList.add('pui-input-error');
  }

  hideError() {
    this._errorMessage.hide();
    this._inputContainer.classList.remove('input-error-selected-2_0');
    this._inputContainer.classList.remove(this.stylesAndSelectors.ERROR);
    this._innerInput.classList.remove('pui-input-error');

  }

  getDataSearchUrl() {
    const dataSearchUrl = this.getElementsByClassName('pui-input-container')[0].attributes[0].nodeValue;
    return dataSearchUrl;
  }

  inputChangedHandler(event) {
    if (this.getValue().length > 0) {
      this.showCloseIcon();
      if (this.encode) {
        this.setValue(this.getValue().replace(/[<>]/g, ''));
      }
    }
    if (event.data === '/') {
      this.setValue(this.getValue().substr(0, this.getValue().length - 1));
    }
    if (this.allowedCharacters === 'alphanumeric') {
      this.setValue(this.getValue().replace(/[\W_]+/g, ''));
    }
    if (this.allowedCharacters === 'alphanumericGroupId') {
      this.setValue(this.getValue().replace(/[^\w@,]+/g, ''));
    }
    if (this.allowedCharacters === 'numeric') {
      if (this.textInputType === 'date' && event.inputType !== 'deleteContentBackward') {
        const inputElement = this.querySelector("input");
        const size = inputElement.value.length;
        const months = [
          {
            month: 'january',
            days: 31,
          },
          {
            month: 'february',
            days: 29,
          },
          {
            month: 'march',
            days: 31,
          },
          {
            month: 'april',
            days: 30,
          },
          {
            month: 'may',
            days: 31,
          },
          {
            month: 'june',
            days: 30,
          },
          {
            month: 'july',
            days: 31,
          },
          {
            month: 'august',
            days: 31,
          },
          {
            month: 'september',
            days: 30,
          },
          {
            month: 'october',
            days: 31,
          },
          {
            month: 'november',
            days: 30,
          },
          {
            month: 'december',
            days: 31,
          },
        ];

        const selectedMonth = parseInt(inputElement.value.slice(0, 2));
        const selectedMonthIndex = selectedMonth - 1;

        if ((size === 2 && inputElement.value > 12) || (size === 5 && Number(inputElement.value.split('/')[1]) > months[selectedMonthIndex].days)) {
          this.showError('Invalid date format');
          inputElement.value = '';
          return;
        }

        if ((size === 2 && inputElement.value < 13) || (size === 5 && Number(inputElement.value.split('/')[1]) < 32)) {
          inputElement.value += '/';
        }
        this.setValue(this.getValue().replace(/[A-Za-z]/g, ''));
      } else {
        this.setValue(this.getValue().replace(/[^0-9/]/, ''));
      }
    }

    if (this._isVersion2()) {
      this.hideError();
    }
    if (this.onInputChange) {
      this.onInputChange(this.getValue());
    }
  }

  inputFocusedHandler() {
    // Apply style and selectors when input field is focused
    this.focus();
    // Clients may use this callback
    if (this.onInputFocus) {
      this.onInputFocus();
    }
  }

  focusOutHandler() {
    // Remove style and selectors when input field is out of focus
      this.unfocus();
    // Clients may use this callback
    if (this.onFocusOut) {
      this.onFocusOut();
    }
  }

  hideInputIconHandler(event) {
    if (event.target.id !== 'xIcon' && !this._inputContainer.classList.contains(this.stylesAndSelectors.SELECTED)) {
      this.hideCloseIcon();
    }
  }

  showCloseIcon() {
    if (this._clearTextIcon && !this.neverShowClearIcon) {
      this._clearTextIcon.classList.remove('pui-hidden');
    }
  }

  hideCloseIcon() {
    if (this._clearTextIcon) {
      this._clearTextIcon.classList.add('pui-hidden');
    }
  }

  formatDate(inputDate) {
    const date = new Date(`${inputDate}T00:00:00`);
    if (!isNaN(date.getTime())) {
      // Months use 0 index.
      const monthValue = (date.getMonth() + 1).toString().length < 2 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
      const dayValue = date.getDate().toString().length < 2 ? `0${date.getDate()}` : date.getDate();

      return `${monthValue}/${dayValue}/${date.getFullYear()}`;
    }
    return null;
  }

  _formatNonDateValue(value, encode) {
    if (value) {
      if (encode) {
        return ESAPI.encoder().encodeForHTML(value);
      }
    }
    return value;
  }

  _isVersion2() {
    return (this.inputStyle && this.inputStyle === INPUT_STYLES[0]);
  }

  _encodeForHtml(value) {
    if (value) {
      return ESAPI.encoder().encodeForHTML(value);
    }
    return value;
  }

  _formatDateValue(value) {
    if (value) {
      return this.formatDate(value);
    }
    return value;
  }

  render() {
    this.innerHTML = '';
    const {
      inputId,
      label,
      placeholder,
      name,
      value,
      maxLength,
      iconClass,
      iconRightClass,
      disabled,
      disableAutocomplete,
      noTabbing,
      encode,
      allowedCharacters,
      enableRightButton,
      rightButtonIconMargin,
      rightButtonClass,
      required
    } = this;

    this.stylesAndSelectors = this._decideStyle(this.status);

    let {
      textInputType,
      status,
    } = this;

    let containerClass = this.stylesAndSelectors.CONTAINER_CLASS;

    if (status) {
      status = this.stylesAndSelectors.STATUS;
    }

    if (!textInputType) {
      textInputType = this.defaultInputType;
    }

    let labelHidden = '';
    if (label === '') {
      labelHidden = 'pui-input-label-hidden';
    }

    let iconHidden = '';
    if (iconClass === '') {
      iconHidden = 'pui-hidden';
    }

    let iconNativeClass = '';
    if (iconRightClass === '') {
      iconNativeClass = 'pui-clear-button';
    }

    let pui2_0Label = `
      <pui-section spacingBottom="mini">
        <pui-text input="${this._encodeForHtml(label)}" class="${labelHidden}" textSize="medium" fontWeight="medium"></pui-text>
      </pui-section>
    `;

    this.classList.add('pui-block');
    const puiInput = document.createElement('span');
    puiInput.innerHTML = `
        ${this._isVersion2() ? pui2_0Label : ``}
        <div class="${containerClass} ${status} ${this.enableRightButton ? `${containerClass}-right-button` : ``}">
            ${this._isVersion2() ? `` : `<pui-text input="${this._encodeForHtml(label)}" class="${labelHidden}" textSize="mini"></pui-text>`}
            <div class="pui-input-line-container">
              <pui-icon class="${iconHidden}" paddingRight="small" imgClass="${iconClass}"></pui-icon>
              <input id="${inputId}" 
                    class="pui-input ${this.classList.contains('pui-dropdown-two') ? 'pui-dropdown-two' : ''}" 
                    name="${name}"
                    spellcheck="false"
                    value="${textInputType === 'date' ? this._formatDateValue(value) : this._formatNonDateValue(value, encode)}"
                    ${disabled ? 'readonly' : ''}
                    autocomplete="${disableAutocomplete ? 'off' : 'on'}"
                    maxLength="${maxLength}"
                    type="${textInputType === 'date' ? 'tel' : textInputType}" 
                    ${textInputType === 'number' ? `onKeyDown="if(this.value.length==${maxLength} && event.keyCode!=8) return false;"` : ''} 
                    ${textInputType === 'tel' ? 'onKeyPress="if(event.keyCode == 35) return false;"' : ''}
                    ${allowedCharacters === 'numeric' ? 'inputmode="numeric"' : ''}
                    placeholder="${this._encodeForHtml(placeholder)}" 
                    style="padding: 0; background: transparent; border: none; box-shadow: none;"
                    tabindex="${noTabbing ? '-1' : '0'}"
                    ${required ? 'required' : ''}>
              </input>
              ${iconRightClass ? `<pui-icon aria-label="Dropdown Icon" role="button" class="${iconNativeClass}" imgClass="${iconRightClass}"></pui-icon>` 
              : ``}
              ${enableRightButton ? `<pui-button id="pui-input-right-button" class="${rightButtonClass ? rightButtonClass : `pui-input-right-button`}" iconMargin="${rightButtonIconMargin ? rightButtonIconMargin : ''}" disableFullWidth></pui-button>` : ``}
              ${!iconRightClass && !enableRightButton ? `<pui-icon id="xIcon" tabindex="0" aria-label="Clear ${inputId.replace(/[-_]/g, ' ')}" role="button" class="${iconNativeClass} pui-hidden" imgClass="clear-input-icon"></pui-icon>` : ``}
              <div id="input-loader"></div>
            </div>
        </div>
        <pui-text input="" class="pui-input-error-message pui-hidden" textSize="small" textColor="red" spacingTop="small"></pui-text>
        `;
    this.appendChild(puiInput);

    this._innerInput = this.querySelector('input');
    this._innerInput.addEventListener('input', this.inputChangedHandler.bind(this));
    this._innerInput.addEventListener('focus', this.inputFocusedHandler.bind(this));
    this._innerInput.addEventListener('focusout', this.focusOutHandler.bind(this));
    this._innerInput.ariaLabel = label;

    const rightButton = this.querySelector('#pui-input-right-button');
    if (rightButton) {
      this._rightButton = rightButton;
      this._rightButton.addEventListener('click', this.rightButtonClickHandler.bind(this));
      // Clients may use this callback
      if (this.onRightButtonRender) {
        this.onRightButtonRender(this.getValue());
      }
    }

    if(this.a11yDescribedBy){
      this._innerInput.setAttribute('aria-describedby', this.a11yDescribedBy)
    }

    this._inputContainer = this.querySelector(this.stylesAndSelectors.INPUT_CONTAINER);
    this._errorMessage = this.querySelector('.pui-input-error-message');

    this._clearTextIcon = this.querySelector('#xIcon');

    if (this.getValue().length > 0) {
      this.showCloseIcon();
    }

    if (this._clearTextIcon) {
      // Hide input clear icon
      document.body.addEventListener('click', this.hideInputIconHandler.bind(this));
      document.body.addEventListener('keyup', this.hideInputIconHandler.bind(this));
      // Clear input field
      this._clearTextIcon.onkeyup = (event) => {
        if (event.keyCode === keyCodes.ENTER_KEYCODE) {
          this.clearInput();
          if (this.onInputClear) {
            this.onInputClear();
          }
        }
      };
      this._clearTextIcon.onclick = () => {
        this.clearInput();
        if (this.onInputClear) {
          this.onInputClear();
        }
      };
    }
  }
}

window.customElements.define('pui-input', PUIInput);
