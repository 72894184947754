import PUIBase from "./pui-base";

/**
 * This component contains a button that starts a webcam view
 * There are 2 ways the webcam can be displayed as of now:
 * 1. Opens webcam view in a modal - Katara Use Case
 * 2. Hides elements specified by an id and opens webcam in the same view - Pharmacy Use Case
 *
 * This means either openInModal or containerIdToHide is required for this component
 *
 * Used in pui-file-upload-two
 *
 * 'inputId' attribute is required for this component to set up an event listener on an element to handle
 * sending files through the custom event
 */

export default class PUIWebcam extends PUIBase {
    constructor() {
        super();

        this.defaultConfirmPhotoText = "Use this photo";
        this.defaultWebcamCaptureText = "Take photo";
        this.defaultRestartWebcamText = "Re-take photo";
    }

    connectedCallback() {
        super.connectedCallback();
        this.render();
    }

    static get observedAttributes() {
        return [
            'openInModal',
            'containerIdToHide',
            'inputId',
            'useIcons',
            'iconClassName',
            'webcamCaptureText',
        ];
    }

    /**
     * Value to indicate whether the webcam should open in a modal
     * @type {boolean}
     * @attr
     */
    get openInModal() {
        return this.getBooleanAttribute('openInModal');
    }

    set openInModal(value) {
        return this.setBooleanAttribute('openInModal', value);
    }

    /**
     * Id of the root container to hide while webcam view is active. Webcam view will then be
     * appended next to root element
     *
     * @type {string}
     * @attr
     */
    get containerIdToHide() {
        return this.getAttribute('containerIdToHide');
    }

    set containerIdToHide(value) {
        return this.setAttribute('containerIdToHide', value);
    }

    /**
     * Id of the input element to store result of webcam capture
     * This value is required
     *
     * @type {string}
     * @attr
     */
    get inputId() {
        const inputId = this.getAttribute('inputId');
        if (inputId) {
            return inputId;
        }
        throw new Error('Please specify a valid id to store the result of webcam capture');
    }

    set inputId(value) {
        return this.setAttribute('inputId', value);
    }
    /**
     * Boolean value to determine whether to show icons in the initial file upload buttons
     * TO BE DEPRECATED. Use iconClassName attribute.
     * @type {boolean}
     * @attr
     */
    get useIcons() {
        return this.getBooleanAttribute('useIcons');
    }

    set useIcons(value) {
        return this.setBooleanAttribute('useIcons', value);
    }

    /**
     * String value of the icon name to display. Name should be a supported class within pui-icon
     *
     * @type {string}
     * @attr
     */
    get iconClassName() {
        return this.getAttribute('iconClassName');
    }

    set iconClassName(value) {
        return this.setAttribute('iconClassName', value);
    }

    /**
     * Text for the button to start webcam view
     *
     * @type {string}
     * @attr
     */
    get webcamCaptureText() {
        return this.getAttribute('webcamCaptureText') || this.defaultWebcamCaptureText;
    }

    set webcamCaptureText(value) {
        return this.setAttribute('webcamCaptureText', value);
    }

    _setupWebcamView() {
        const {
            openInModal,
            containerIdToHide
        } = this;
        if (openInModal) {
            if (this._webcamModal) {
                this._cleanupWebcamViewElement();
                this._webcamModal.remove();
            }
            // create a pui-bottom-sheet
            this._webcamModal = document.createElement('pui-bottom-sheet');
            this._webcamModal.setAttribute('id', 'webcamModal');
            this._webcamModal.setAttribute('hideLink', 'true');
            this._webcamModal.addEventListener('hideBottomSheet', () => this._cleanupWebcamViewElement());

            // append webcam view element inside
            this._appendChildElement(this._webcamModal, this._createWebcamViewElement());
            this._appendAfterElement(this._webcamEnableButton, this._webcamModal);
            this._webcamModal.show();
        } else if (containerIdToHide) {
            this._containerElement = document.getElementById(`${containerIdToHide}`);
            this._hideElement(this._containerElement);
            this._appendAfterElement(this._containerElement, this._createWebcamViewElement());
        }
    }

    _cleanupWebcamViewElement() {
        // stop all video streams
        if (this._videoElement) {
            if (this._videoElement.srcObject) {
                this._videoElement.srcObject.getVideoTracks().forEach(track => track.stop());
            }
            this._videoElement.remove();
        }
        if (this._webcamCaptureButton) {
            this._webcamCaptureButton.remove();
        }
        if (this._webcamElement) {
            this._webcamElement.remove();
        }
        this._webcamElement = null;
        this._videoElement = null;
        this._webcamCaptureButton = null;
    }

    _createWebcamViewElement() {
        this._webcamElement = document.createElement('div');
        this._webcamElement.setAttribute('id', 'webcamActive');
        this._videoElement = document.createElement('video');
        this._videoElement.setAttribute('id', 'videoElement');
        this._videoElement.setAttribute('autoplay', 'true');
        this._webcamCaptureButton = document.createElement('pui-button');
        this._webcamCaptureButton.setAttribute('id', 'webcamCaptureButton');
        this._webcamCaptureButton.setAttribute('label', 'Capture');
        this._webcamCaptureButton.setAttribute('disabled', 'true');
        this._webcamCaptureButton.setAttribute('textSize', 'extra-large');
        this._webcamCaptureButton.onButtonPress = this._handleWebcamCapture.bind(this);

        this._webcamElement.appendChild(this._videoElement);
        this._webcamElement.appendChild(this._webcamCaptureButton);

        var that = this;
        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
            navigator.mediaDevices.getUserMedia({
                video: true
            })
            .then(function (stream) {
                that._webcamCaptureButton.removeAttribute('disabled')
                that._videoElement.srcObject = stream;
            })
            .catch(function (error) {
                console.log("Something went wrong!", error);
            });
        }
        return this._webcamElement;
    }

    _cleanupPhotoConfirmElement() {
        if (this._canvasElement) {
            this._canvasElement.remove();
        }
        if (this._restartWebcamButton) {
            this._restartWebcamButton.remove();
        }
        if (this._confirmPhotoButton) {
            this._confirmPhotoButton.remove();
        }
        if (this._photoConfirmContainer) {
            this._photoConfirmContainer.remove();
        }
        this._canvasElement = null;
        this._restartWebcamButton = null;
        this._confirmPhotoButton = null;
        this._photoConfirmContainer = null;
    }

    _appendPhotoConfirmElement(element) {
        this._photoConfirmContainer = document.createElement('div');
        this._photoConfirmContainer.setAttribute('id', 'photoConfirmContainer');
        this._canvasElement = document.createElement('canvas');
        this._canvasElement.setAttribute('id', 'photoCanvas');

        this._confirmationButtons = document.createElement('div');
        this._confirmationButtons.setAttribute('id', 'confirmationButtons');

        this._restartWebcamButton = document.createElement('div');
        this._restartWebcamButton.setAttribute('id', 'restartWebcamButton');
        this._restartWebcamButton.setAttribute('class', 'pui-webcam-button');
        this._restartWebcamButton.innerHTML = `
            <pui-text textAlign="center" textSize="extra-large" input="${this.defaultRestartWebcamText}"></pui-text>
        `;
        this._restartWebcamButton.addEventListener('click', () => this._handleRestartWebcam());

        this._confirmPhotoButton = document.createElement('pui-button');
        this._confirmPhotoButton.setAttribute('id', 'confirmPhotoButton');
        this._confirmPhotoButton.setAttribute('label', this.defaultConfirmPhotoText);
        this._confirmPhotoButton.setAttribute('width', '100%');
        this._confirmPhotoButton.setAttribute('textSize', 'extra-large');
        this._confirmPhotoButton.classList.remove('pui-button-full-width');
        this._confirmPhotoButton.onButtonPress = this._handleConfirmPhoto.bind(this);

        this._confirmationButtons.appendChild(this._restartWebcamButton);
        this._confirmationButtons.appendChild(this._confirmPhotoButton);
        this._photoConfirmContainer.appendChild(this._canvasElement);
        this._photoConfirmContainer.appendChild(this._confirmationButtons);

        this._appendAfterElement(element, this._photoConfirmContainer);
    }

    _handleWebcamCapture() {
        this._appendPhotoConfirmElement(this._webcamElement);
        var context = this._canvasElement.getContext('2d');
        this._canvasElement.width = this._videoElement.videoWidth;
        this._canvasElement.height = this._videoElement.videoHeight;
        context.drawImage(this._videoElement, 0, 0, this._videoElement.videoWidth, this._videoElement.videoHeight);
        // Stop all video streams.
        this._cleanupWebcamViewElement();
    }

    _handleConfirmPhoto() {
        const {
            openInModal,
            containerIdToHide,
            inputId
        } = this;
        this._canvasElement.toBlob(function(blob) {
            // trigger event that pui-file-upload component can listen to retrieve blob
            var inputElement = document.getElementById(inputId);
            const file = new File([blob], "image.jpg", {
                type: blob.type
            })
            inputElement.dispatchEvent(new CustomEvent('webcamCapture', {
                bubbles: true,
                detail: file
            }));

        });
        this._cleanupPhotoConfirmElement();
        if (openInModal) {
            this._webcamModal.hide();
            this._webcamModal.remove();
        }
        if (containerIdToHide) {
            this._showElement(this._containerElement);
        }
    }

    _handleRestartWebcam() {
        const {
            openInModal,
            containerIdToHide
        } = this;
        if (openInModal) {
            this._appendChildElement(this._webcamModal, this._createWebcamViewElement());
        } else if (containerIdToHide) {
            this._appendAfterElement(this._containerElement, this._createWebcamViewElement());
        }

        this._cleanupPhotoConfirmElement();
    }

    _showElement(element) {
        element instanceof Element && element.classList.remove('pui-hidden');
    }

    _hideElement(element) {
        element instanceof Element && element.classList.add('pui-hidden');
    }

    _appendChildElement(ele1, ele2) {
        ele1 instanceof Element && ele2 instanceof Element && ele1.appendChild(ele2);
    }

    _appendAfterElement(ele1, ele2) {
        ele1 instanceof Element && ele2 instanceof Element && ele1.after(ele2);
    }

    render() {
        const {
            id,
            useIcons,
            iconClassName,
            webcamCaptureText,
        } = this;
        const iconClass = iconClassName || (useIcons ? 'capture-icon-transparent' : '');
        this.innerHTML = `
            <div id="pui-webcam-enable-button" class="pui-webcam">
                ${useIcons ? `
                    <pui-icon imgClass="${iconClass}" flowDirection="horizontal"></pui-icon>
                    &nbsp;
                ` : ''}
                <pui-text textAlign="center" textSize="extra-large" input="${webcamCaptureText}"></pui-text>
            </div>
        `;

        this._webcamEnableButton = document.getElementById(id);
        this._webcamEnableButton.addEventListener('click', () => this._setupWebcamView());
    }
}

window.customElements.define('pui-webcam', PUIWebcam);
