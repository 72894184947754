import * as shadyCss from '@webcomponents/shadycss';
import PUIBase from './pui-base';

const defaultClass = 'pui-carousel-element';
const defaultSize = 'small';
const properties = ['elementValue', 'disabled', 'flowDirection', 'selected'];

const innerTemplate = `
<style>
  :host {
    display: block;
  }
  button {
    border: none;
    background-color: transparent;
    padding: 0px;
    cursor: pointer;
  }
  </style>
  <button tabindex="-1">
    <slot> Inner Elements </slot>
  </button>
`;

const template = document.createElement('template');

template.innerHTML = innerTemplate;

shadyCss.prepareTemplate(template, 'pui-carousel-element');

class PUICarouselElement extends PUIBase {
  constructor() {
    super();

    shadyCss.styleElement(this);
    if (!this.shadowRoot) {
      this.attachShadow({ mode: 'open' });
      this.shadowRoot.appendChild(
        document.importNode(template.content, true),
      );
    }
  }

  attributeChangedCallback(name, oldValue, newValue) {
    if (this.disabled) {
      this.classList.add('pui-carousel-element-disabled');
    }

    if (this.selected) {
      this.classList.add('focused');
    } else {
      this.classList.remove('focused');
    }
  }

  static get observedAttributes() { return ['elementValue', 'disabled', 'flowDirection', 'selected', 'minWidth']; }

  connectedCallback() {
    super.connectedCallback();
    this.classList.add(defaultClass);
    this.classList.add(`carousel-element-${this.flowDirection}`);
    if (!this.padding && !this.paddingLeft && !this.paddingRight && !this.paddingTop && !this.paddingBottom) {
      this.classList.add('pui-carousel-element-default-padding');
    }
    if (this.minWidth) {
      this.addToAttribute('style', `min-width: ${this.minWidth}`);
    } else {
      this.classList.add('pui-carousel-element-default-min-width');
    }
    if (this.border) {
      this.addToAttribute('style', `border: ${this.border}`);
    } else {
      this.classList.add('pui-carousel-element-default-border');
    }
    this.setAttribute('tabindex', '0');

    this.upgradeProperty = this.upgradeProperty.bind(this);
    properties.forEach(this.upgradeProperty);
  }

  get elementValue() {
    return this.getAttribute('elementValue') || '';
  }

  set elementValue(value) {
    this.setAttribute('elementValue', value);
  }

  get disabled() {
    return this.getBooleanAttribute('disabled');
  }

  set disabled(value) {
    this.setBooleanAttribute('disabled', value);
  }

  get flowDirection() {
    return this.getAttribute('flowDirection');
  }

  set flowDirection(value) {
    this.setAttribute('flowDirection', value);
  }

  get selected() {
    return this.getBooleanAttribute('selected');
  }

  set selected(value) {
    this.setBooleanAttribute('selected', value);
  }

  get minWidth() {
    return this.getAttribute('minWidth');
  }

  set minWidth(value) {
    this.setAttribute('minWidth', value);
  }

  get border() {
    return this.getAttribute('border');
  }

  set border(value) {
    this.setAttribute('border', value);
  }

  getElementValue() {
    return this.elementValue;
  }

  getSelected() {
    return this.selected;
  }

  select() {
    this.selected = true;
  }

  deselect() {
    this.selected = false;
  }
}

window.customElements.define('pui-carousel-element', PUICarouselElement);
