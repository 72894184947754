const includes = (list, val) => {
  for (let i = 0; i < list.length; i += 1) {
    if (list[i] === val) {
      return true;
    }
  }
  return false;
};

export default includes;
