import PUIBase from './pui-base';
import getGlowColors from '../attributeValues/glowColorVariants';
import includes from '../functions/includes';

/**
 *  This component is intended to be used as a location ingress on the top-nav for the GLOW modal
 */
class PUIGlowIngress extends PUIBase {
  constructor() {
    super();
    this.defaultClass = 'glow-ingress';
    this.defaultTitle = '';
    this.defaultCity = '';
    this.defaultZipCode = '';
    this.defaultGlowColor = 'black';
  }

  connectedCallback() {
    super.connectedCallback();
    this.render();
  }

  static get observedAttributes() {
    return ['title', 'city', 'zipCode', 'glowColor'];
  }

  /**
   * @classprop {string} title: used to display the text 'Get care in' or 'Deliver to' on the ingress
   */
  get title() {
    return this.getAttribute('title') || '';
  }

  set title(value) {
    this.setAttribute('title', value);
  }

  /**
   * @classprop {string} city: used to display the city
   */
  get city() {
    return this.getAttribute('city') || '';
  }

  set city(value) {
    this.setAttribute('city', value);
  }

  /**
   * @classprop {string} zipCode: used to display the zipcode of the location
   */
  get zipCode() {
    return this.getAttribute('zipCode') || '';
  }

  set zipCode(value) {
    this.setAttribute('zipCode', value);
  }

  /**
   * @classprop {string} glowColor: used to determine white or black color variants
   */
  get glowColor() {
    return this.getAttribute('glowColor') || this.defaultGlowColor;
  }

  set glowColor(value) {
    if (!includes(getGlowColors(), value)) {
      this.setAttribute('glowColor', this.defaultGlowColor);
      return;
    }
    this.setAttribute('glowColor', value);
  }

  render() {
    const { title, city, zipCode, glowColor } = this;

    this.classList.add('pui-inline-block');
    this._innerGlow = document.createElement('div');
    this._innerGlow.classList.add(this.defaultClass);
    this._innerGlow.innerHTML = `
        <pui-section flowDirection='horizontal' class='glow-ingress glow-ingress-wrapper'>
            <pui-icon imgClass="glow-location-${glowColor}"></pui-icon>
            <pui-section flowDirection='vertical'>
                <pui-text input="${title}" textColor="silver-grey" textSize="medium" fontWeight="normal"></pui-text>
                <pui-text input="${city} ${zipCode}" textColor="${glowColor}" textSize="extra-large" fontWeight="bold"></pui-text>
            </pui-section>
       
        </pui-section>
    `;
    this.appendChild(this._innerGlow);
  }
}

window.customElements.define('pui-glow-ingress', PUIGlowIngress);
