const generateVerificationFixUpFiledsMessage = (fixupFields) => {
    let message = '';
    if (!fixupFields) return '';
    fixupFields.forEach(filed => {
      switch (filed) {
        case 'BIN':
          message += 'RxBIN, '
          break;
        case 'PCN':
          message += 'RxPCN, '
          break;
        case 'GROUP_ID':
          message += 'RxGROUP, '
          break;
        case 'CARDHOLDER_ID':
          message += 'Member ID, '
          break;
        case 'SECONDARY_INSURANCE':
          message += 'insurance information, '
        default:
          break;
      }
    });
    message = message.slice(0, -2);
    return message;
  };

  export default generateVerificationFixUpFiledsMessage;