
class PUIPersonPickerElement extends HTMLElement {
  static get observedAttributes() { return ['personName', 'elementValue', 'status']; }

  connectedCallback() {
    this.render();
  }

  get personName() {
    return this.getAttribute('personName') || '';
  }

  set personName(value) {
    this.setAttribute('personName', value);
  }

  get elementValue() {
    return this.getAttribute('elementValue') || '';
  }

  set elementValue(value) {
    this.setAttribute('elementValue', value);
  }

  get status() {
    return this.getAttribute('status') || '';
  }

  set status(value) {
    this.setAttribute('status', value);
  }

  render() {
    this.innerHTML = '';
    const {
      personName,
      status,
    } = this;

    this._innerPersonPickerElement = document.createElement('div');
    this._innerPersonPickerElement.classList.add('pui-person-picker-element');
    if (status) {
      this._innerPersonPickerElement.classList.add(status);
    }
    this._innerPersonPickerElement.innerHTML = `
        ${personName}
    `;
    this._innerPersonPickerElement.setAttribute('tabindex', '0');
    this.appendChild(this._innerPersonPickerElement);
  }
}

customElements.define('pui-person-picker-element', PUIPersonPickerElement);
