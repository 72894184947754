import getTextSize from '../attributeValues/textSize';
import getThemes from '../attributeValues/themes';
import includes from '../functions/includes';
import PUIBase from './pui-base';
import PUIIcon from './pui-icon';

const ESAPI = require('node-esapi');
const BUTTON_TYPES = ['pui-2_0'];
/**
 * @element pui-button
 * 
 * ### Events
 * 
 * This element emits a `pressed` event when the button is interacted with.
 */
class PUIButton extends PUIBase {
  constructor() {
    super();
    this.defaultClass = 'pui-button';
    this.defaultText = 'PUI Button';
    this.defaultSpacingTop = 'small';
    this.defaultTheme = 'primary';    
  }

  connectedCallback() {
    super.connectedCallback();
    this.render();
    this.attachEventListener();
  }

  attributeChangedCallback() {
    this.render();
  }

  static get observedAttributes() {
    return [
      'label', 'form-action', 'theme', 'url',
      'disabled', 'borderless', 'showspinner', 'icon',
      'elementvalue', 'width', 'height', 'textcolor', 'textsize',
      'transitionlabel', 'successlabel', 'failedlabel',
      'appendicon', 'iconmargin', 'disablefullwidth', 'spinnericon', 'showspinnericon',
      'hidetext', 'buttontype', 'a11ydescribedby'
    ];
  }

  /** A callback invoked when this button is clicked
   * @type {((this: void) => void) | undefined} */
  onButtonPress = undefined;

  /**
   * This is the text for the button that will be displayed to the user
   * @type {string}
   * @attr
   */
  get label() {
    return this.getAttribute('label') || '';
  }

  set label(value) {
    this.setAttribute('label', value);
  }

  /**
   * This is the text for the button when in a loading state
   * @type {string}
   * @attr
   */
  get transitionLabel() {
    return this.getAttribute('transitionLabel') || '';
  }

  set transitionLabel(value) {
    this.setAttribute('transitionLabel', value);
  }

  get buttonType() {
    return this.getAttribute('buttontype') || '';
  }

  set buttonType(value) {
    this.setAttribute('buttontype', value);

  }
  /**
   * This is the text for the button that will display after an action
   * has been completed successfully
   * @type {string}
   * @attr
   */
  get successLabel() {
    return this.getAttribute('successLabel') || '';
  }

  set successLabel(value) {
    this.setAttribute('successLabel', value);
  }

  /**
   * This is the text for the button that will display after an action
   * has been failed
   * @type {string}
   * @attr
   */
  get failedLabel() {
    return this.getAttribute('failedLabel') || '';
  }

  set failedLabel(value) {
    this.setAttribute('failedLabel', value);
  }

  /**
   * Specifies the type of styling to be used for this button
   * @type {"primary"|"secondary"|"line-item-delete"}
   * @attr
   */
  get theme() {
    return this.getAttribute('theme') || '';
  }

  set theme(value) {
    this.setAttribute('theme', value);
  }

  /**
   * Optional value to specify where the user should be redirected to after
   * clicking this button
   * @type {string}
   * @attr
   */
  get url() {
    return this.getAttribute('url') || '';
  }

  set url(value) {
    this.setAttribute('url', value);
  }

  /**
   * If present, will determine if this button is clickable or not
   * @type {boolean}
   * @attr
   */
  get disabled() {
    return this.getBooleanAttribute('disabled');
  }

  set disabled(value) {
    this.setBooleanAttribute('disabled', value, this._innerButton);
  }

  /**
   * If present, will add borderless styling
   * @type {boolean}
   * @attr
   */
  get borderless() {
    return this.getBooleanAttribute('borderless');
  }

  set borderless(value) {
    this.setBooleanAttribute('borderless', value, this._innerButton);
  }

  /**
   * If present, will show the loading indicator
   * @type {boolean}
   * @attr
   */
  get showSpinner() {
    return this.getBooleanAttribute('showSpinner');
  }

  set showSpinner(value) {
    this.setBooleanAttribute('showSpinner', value, this._innerButton);
  }

  /**
   * Specifies the icon class to be used for this button
   * @type {string}
   * @attr
   */
  get icon() {
    return this.getAttribute('icon') || '';
  }

  set icon(value) {
    this.setAttribute('icon', value);
  }

  /**
   * Used to set a specific width for a button (eg. 40px). If not present,
   * the button will take up the entire width of the screen.
   * @type {string}
   * @attr
   */
  get width() {
    return this.getAttribute('width') || '';
  }

  set width(value) {
    this.setAttribute('width', value);
  }

    /**
   * Used to set a specific height for a button (eg. 40px). If not present,
   * the button will take up the default height set in the base button or 
   * specific theme css class.
   * @type {string}
   * @attr
   */
    get height() {
      return this.getAttribute('height') || '';
    }
  
    set height(value) {
      this.setAttribute('height', value);
    }

  /**
   * Used to set the color for the button label
   * @type {"black-color"|"grey-color"|"white-color"|"link-color"|"red-color"}
   * @attr
   */
  get textColor() {
    return this.getAttribute('textColor') || '';
  }

  set textColor(value) {
    this.setAttribute('textColor', value);
  }

  /**
   * Used to set the text size for the button label
   * @type {"mini"|"small"|"medium"|"large"|"extra-large"|"double-extra-large"}
   * @attr
   */
  get textSize() {
    return this.getAttribute('textSize') || '';
  }

  set textSize(value) {
    this.setAttribute('textSize', value);
  }

  /**
   * The action a button performs. When set to submit, button will submit form data.
   * When set to reset, the button will clear a form.
   * @type {"button"|"submit"|"reset"}
   * @attr [form-action=button]
   */
  get formAction() {
    return this.getAttribute('form-action') || 'button';
  }

  set formAction(value) {
    this.setAttribute('form-action', value);
  }

  /**
   * The value that will be used in the button press callback
   * @type {string}
   * @attr
   */
  get elementValue() {
    return this.getAttribute('elementValue') || '';
  }

  set elementValue(value) {
    this.setAttribute('elementValue', value);
  }

  /**
   * Boolean attribute to determine if we should append the icon after the label
   * By default we prepend the icon, leading to the icon showing left of the label
   */
  get appendIcon() {
    return this.getBooleanAttribute('appendIcon');
  }

  set appendIcon(value) {
    this.setBooleanAttribute('appendIcon', value);
  }

  /**
   * This value determines the margin an icon has (margins defined in _base.scss)
   * @type {string}
   * @attr
   */
  get iconMargin() {
    return this.getAttribute('iconMargin') || 'small-margin-right';
  }

  set elementValue(value) {
    this.setAttribute('iconMargin', value);
  }

  /**
   * Boolean attribute to determine if we should disable full-width styling
   */
  get disableFullWidth() {
    return this.getBooleanAttribute('disableFullWidth');
  }

  set disableFullWidth(value) {
    this.setBooleanAttribute('disableFullWidth', value);
  }
  
  /**
   * If present, will show the loading indicator
   * @type {boolean}
   * @attr
   */
  get showspinnericon() {
    return this.getBooleanAttribute('showspinnericon');
  }

  set showspinnericon(value) {
    this.setBooleanAttribute('showspinnericon', value, this._innerButton);
  }

  /**
   * If present, will show the loading indicator icon class
   * @type {string}
   * @attr
   */
  get spinnericon() {
    return this.getAttribute('spinnericon');
  }

  set spinnericon(value) {
    this.setAttribute('spinnericon', this.encodedValue(value));
  }

  get hidetext() {
    return this.getBooleanAttribute('hidetext');
  }

  set hidetext(value) {
    this.setBooleanAttribute('hidetext', value, this._innerButton);
  }

  /**
   * Value of [aria-describedby](https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-describedby) on the inner input element.
   * @type {string}
   */
  get a11yDescribedBy() {
    return this.getAttribute('a11yDescribedBy')
  }

  set a11yDescribedBy(value) {
    return this.setAttribute('a11yDescribedBy', value)
  }

  encodedValue(value) {
    return (value ? ESAPI.encoder().encodeForHTML(value) : value);
  }

  buttonClicked() {
    if (this.url) {
      window.location.href = this.url;
      return;
    }
    if (this.onButtonPress && this.isEnabled()) {
      this.onButtonPress(this.elementValue);
    }
    const event = new CustomEvent('pressed');
    this.dispatchEvent(event);
  }

  isEnabled() {
    return !this.disabled;
  }

  updateLabel(text) {
    this._innerButton.innerText = text;
  }

  enable() {
    this.disabled = false;
  }

  disable() {
    this.disabled = true;
  }

  /**
   * displays spinner over button to indicate callback is loading
   */
  displaySpinner() {
    this.showSpinner = true;
    this.render();
  }

  hideSpinner() {
    this.showSpinner = false;
    this.render();
  }

  displayLabel() {
    const button = this.querySelector('button');
    button.innerText = this.label;
  }

  displayTransitionLabel() {
    const button = this.querySelector('button');
    button.innerText = this.transitionLabel;
    this.generateSpinner();
  }

  displaySuccessLabel() {
    const button = this.querySelector('button');
    button.innerText = this.successLabel;
    this.generateCompleteIcon();
  }

  displayFailedLabel() {
    const button = this.querySelector('button');
    button.innerText = this.failedLabel;
    this.generateFailedIcon();
  }

  generateSpinner() {
    const spinner = new PUIIcon();
    spinner.setAttribute('imgClass', 'btn-spinner-icon');
    spinner.setAttribute('spacingRight', 'small');
    this._innerButton.insertBefore(spinner, this._innerButton.childNodes[0]);
  }

  generateCompleteIcon() {
    const successIcon = new PUIIcon();
    successIcon.setAttribute('imgClass', 'btn-complete-icon');
    this._innerButton.insertBefore(successIcon, this._innerButton.childNodes[0]);
  }

  generateFailedIcon() {
    const failedIcon = new PUIIcon();
    failedIcon.setAttribute('imgClass', 'btn-failed-icon');
    this._innerButton.insertBefore(failedIcon, this._innerButton.childNodes[0]);
  }


  render() {
    this.innerHTML = '';
    const {
      appendIcon,
      name,
      formAction,
      label,
      icon,
      iconMargin,
      width,
      height,
      disabled,
      disableFullWidth,
      borderless,
      showSpinner,
      textColor,
      textSize,
      showspinnericon,
      spinnericon,
      hidetext,
      buttonType,
      a11yDescribedBy,
    } = this;
    let {
      theme,
    } = this;

    if (!includes(getThemes(), theme)) {
      theme = this.defaultTheme;
    }

    if (width) {
      this.classList.remove('pui-button-full-width');
      this.style.width = `${width}`;
    } else {
      this.classList.add('pui-button-full-width');
    }

    if (disableFullWidth) {
      this.classList.remove('pui-button-full-width');
    }

    let spinnerClass = '';

    if (showspinnericon && spinnericon) {
      spinnerClass = spinnericon;
    } else if (showSpinner) {
      spinnerClass = 'showSpinner';
    }

    const transparentTextClass = hidetext ? 'transparent-text' : '';

    if (includes(BUTTON_TYPES, buttonType)) {
      if (buttonType === 'pui-2_0') {
        this.defaultClass='pui-button-2_0'
      }
    }

    this.classList.add('pui-block');
    this.innerHTML = `
      <button class="${this.defaultClass} ${theme} ${textColor} ${spinnerClass} ${transparentTextClass}" !important" name=${name}>
        ${label}
      </button>
    `;
    this.style.textAlign = 'center';
    this._innerButton = this.querySelector('button');
    this._innerButton.style.height = height ?? null
    this._innerButton.disabled = disabled;
    if (this._innerButton.disabled) {
      this._innerButton.style.cursor = 'default';
    } else {
      this._innerButton.style.cursor = 'pointer';
    }

    if (icon && !showSpinner) {
      this._innerIcon = document.createElement('pui-icon');
      this._innerIcon.classList.add(this.icon);
      this._innerIcon.classList.add(iconMargin);
      if (appendIcon) {
        this._innerButton.append(this._innerIcon);
      } else {
        this._innerButton.prepend(this._innerIcon);
      }
    }

    if (includes(getTextSize(), textSize)) {
      this._innerButton.classList.add(`${textSize}-font`);
    }

    if (borderless) {
      this._innerButton.classList.add('borderless');
    }

    if (showSpinner && !showspinnericon) {
      this.generateSpinner();
    }

    if (formAction) {
      this._innerButton.setAttribute('type', formAction);
    }

    if(a11yDescribedBy){
      this._innerButton.setAttribute('aria-describedby', a11yDescribedBy)
    }
  }

  attachEventListener() {
    this.addEventListener('click', this.buttonClicked.bind(this));
  }
}

window.customElements.define('pui-button', PUIButton);

export default PUIButton;
