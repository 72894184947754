class PUITabContainer extends HTMLElement {
  static get observedAttributes() { return []; }

  connectedCallback() {
    this.render();
  }

  tabSwitchHandler(event) {
    const { clickIndex } = event.detail;
    const tabPageElements = this.getElementsByTagName('pui-tab-page');
    let currentFocusedIndex = -1;
    for (let i = 0; i < tabPageElements.length; i += 1) {
      const element = tabPageElements[i];
      if (element.classList.contains('tab-page-selected')) {
        currentFocusedIndex = i;
      }
    }
    if (clickIndex !== currentFocusedIndex) {
      tabPageElements[currentFocusedIndex].classList.remove('tab-page-selected');
      tabPageElements[clickIndex].classList.add('tab-page-selected');
    }
  }

  render() {
    this.attachShadow({ mode: 'open' });
    this.shadowRoot.innerHTML = `
      <style>
        :host {
          display: block;
        }
      </style>
      <slot>
        Tab Container Slot
      </slot>
    `;

    this.shadowRoot.addEventListener('tabSwitch', event => this.tabSwitchHandler(event));
  }
}

customElements.define('pui-tab-container', PUITabContainer);
