import PUIBase from './pui-base';

export class PUISSNInput extends PUIBase {
  constructor() {
    super();
    this.defaultSpacingTop = 'small';
    this.maxLength = 11; // length of SSN after formatting
    this.ssn = '';
  }

  static get observedAttributes() {
    return ['inputId', 'label', 'placeholder', 'status', 'name', 'value'];
  }

  connectedCallback() {
    super.connectedCallback();
    this.render();
  }

  attributeChangedCallback(attrName) {
    super.attributeChangedCallback();
    if (this._innerInputField && attrName === "value") {
      this.setValue(this.value)
    } else {
      this.render();
    }
  }

  get inputId() {
    return this.getAttribute('inputId') || '';
  }

  set inputId(value) {
    this.setAttribute('inputId', value);
  }

  get label() {
    return this.getAttribute('label') || '';
  }

  set label(value) {
    this.setAttribute('label', value);
  }

  get placeholder() {
    return this.getAttribute('placeholder') || '';
  }

  set placeholder(value) {
    this.setAttribute('placeholder', value);
  }

  get name() {
    return this.getAttribute('name') || '';
  }

  set name(value) {
    this.setAttribute('name', value);
  }

  get value() {
    return this.getAttribute('value') || '';
  }

  set value(value) {
    this.setAttribute('value', value);
  }

  get status() {
    return this.getAttribute('status') || '';
  }
  
  set status(value) {
    this.setAttribute('status', value);
  }

  inputClicked(event) {
    const inputContainer = this.querySelector('.pui-ssn-input-container');
    if (!inputContainer.classList.contains('input-disabled')) {
      if (this.contains(event.target)) {
        this.querySelector('input').focus();
        inputContainer.classList.remove('input-error');
        inputContainer.classList.add('input-selected');
      } else {
        inputContainer.classList.remove('input-selected');
      }
    }
  }

  getInput() {
    const inputElement = this.getElementsByClassName('pui-ssn-input')[0];
    return inputElement;
  }

  getValue() {
    return this._innerInputField.value;
  }

  getFormattedValue() {
    return this._innerInputField.value;
  }

  setValue(value) {
    this.ssn = value;
    this._innerInputField.value = this.formatSSN2(value);
  }

  getDataSearchUrl() {
    const dataSearchUrl = this.getElementsByClassName('pui-ssn-input-container')[0].attributes[0].nodeValue;
    return dataSearchUrl;
  }

  inputChangedHandler(event) {
    if (this.onInputChange) {
      this.setValue(this.getValue());
      this.onInputChange(this.getValue());
    }
  }

  // TODO: cleanup with weblab - PHARMACY_C3_C5_WAVE1_EXP_WL_1111105
  formatSSN(raw_ssn) {
    const ssn = (`${raw_ssn}`).replace(/[^-0-9]/g, '');
    const match = ssn.match(/^(\d{1,3})-?(\d{0,2})-?(\d{0,4})$/);

    if (match) {
      return `${match[1]}${match[2] ? '-' : ''}${match[2]}${match[3] ? '-' : ''}${match[3]}`;
    } else {
      return ssn;
    }
  }

  /**
   * Formats SSN as entered on each input change
   */
  formatSSN2(raw_ssn) {
    // Remove all non-digit characters
    const digits = raw_ssn.replace(/\D/g, '');
    
    // Split the string into groups
    const firstGroup = digits.slice(0, 3);
    const secondGroup = digits.slice(3, 5);
    const thirdGroup = digits.slice(5, 9);
  
    // Build the formatted SSN
    let formattedSSN = firstGroup;
    if (digits.length > 3) formattedSSN += '-' + secondGroup;
    if (digits.length > 5) formattedSSN += '-' + thirdGroup;
  
    return formattedSSN;
  }
  
  render() {
    this.innerHTML = '';
    const {
      label,
      placeholder,
      name,
      value,
      maxLength,
    } = this;

    let {
      status,
    } = this;

    if (status) {
      status = `input-${status}`;
    }

    if (value) {
        this.ssn = value;
    }

    let labelHidden = '';
    if (label === '') {
      labelHidden = 'pui-hidden';
    }

    this.classList.add('pui-block');
    this._innerContainer = document.createElement('span');
    this._innerContainer.innerHTML = `
        <div class="pui-ssn-input-container ${status || ''}" >
            <pui-text input="${label}" class="${labelHidden}" textSize="mini"></pui-text>
            <div class="pui-ssn-input-line-container">
                <span class="pui-ssn-input-dash-mask" aria-hidden="true">
                    &nbsp;&nbsp;&nbsp;<span class="dash">-</span>&nbsp;&nbsp;<span class="dash">-</span>
                </span>
            </div>
        </div>
        `;
    this._innerInputField = document.createElement('input');
    this._innerInputField.setAttribute('id', this.inputId);
    this._innerInputField.classList.add('pui-ssn-input');
    this._innerInputField.setAttribute('name', name);
    this._innerInputField.setAttribute('value', this.formatSSN(value));
    this._innerInputField.setAttribute('autocomplete', 'off');
    this._innerInputField.setAttribute('maxLength', maxLength);
    this._innerInputField.setAttribute('type', 'password');
    this._innerInputField.setAttribute('inputmode', 'numeric');
    this._innerInputField.setAttribute('placeholder', placeholder);
    this._innerInputField.addEventListener('input',  (event) => this.inputChangedHandler(event));

    const inputSlot = this._innerContainer.querySelector('.pui-ssn-input-line-container');
    inputSlot.appendChild(this._innerInputField);
    this.appendChild(this._innerContainer);

    document.body.addEventListener('click', this.inputClicked.bind(this));

    this._innerInputField.addEventListener('keyup', (event => {
      const value = this._innerInputField.value;
      const new_value = this.formatSSN(value);

      if (value.toString().replace(/\D/g, '').length < maxLength - 2) {
        this.ssn = new_value;
        this._innerInputField.value = new_value;
      }
    }), false);
  }
}

window.customElements.define('pui-ssn-input', PUISSNInput);
