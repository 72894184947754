import PUIBase from './pui-base';
import keyCodes from '../constants/keyCodes';

/**
 * This is the component which creates a card holding all the relevant information
 * for a pizza tracker component.
 */
class PUIPizzaTrackerCard extends PUIBase {
  constructor() {
    super();
    this.defaultClass = 'pui-pizza-tracker-card';
  }

  connectedCallback() {
    super.connectedCallback();
    this.render();
  }

  attributeChangedCallback() {
    this.render();
  }

  static get observedAttributes() {
    return ['featuretitle', 'title', 'redirecttitle', 'href', 'loading', 'totalsteps', 'currentstep'];
  }

  /**
   * The feature title holds the string of the feature we are currently working with.
   * This will be displayed on the top-left of the card.
   * @type {string}
   * @attr
   */
  get featureTitle() {
    return this.getAttribute('featureTitle') || '';
  }

  set featureTitle(value) {
    return this.setAttribute('featureTitle', value);
  }

  /**
   * The title holds the string of the specific item we are currently focused on.
   * This will be displayed as the main title in the middle of the card.
   * @type {string}
   * @attr
   */
  get title() {
    return this.getAttribute('title') || '';
  }

  set title(value) {
    return this.setAttribute('title', value);
  }

  /**
   * The redirect title holds the string denoting the action of the href.
   * This will be displayed on the top-right of the card.
   * @type {string}
   * @attr
   */
  get redirectTitle() {
    return this.getAttribute('redirectTitle') || '';
  }

  set redirectTitle(value) {
    return this.setAttribute('redirectTitle', value);
  }

  /**
   * The href holds the href we will redirect to when the redirect title is clicked.
   * This should be a telephone number denoted with 'tel:###-###-####'
   * @type {string}
   * @attr
   */
  get href() {
    return this.getAttribute('href') || '';
  }

  /**
   * The loading attribute determines whether to display loading indicator in place of title.
   * @type {boolean}
   * @attr
   */
  get loading() {
    return this.getBooleanAttribute('loading');
  }

  set loading(value) {
    this.setAttribute('loading', value);
  }

  set href(value) {
    return this.setAttribute('href', value);
  }

  /**
   * The totalSteps attribute holds how many steps/milestones we should render in the progress bar.
   * @type {string}
   * @attr
   */
  get totalSteps() {
    return this.getAttribute('totalSteps') || '';
  }

  set totalSteps(value) {
    this.setAttribute('totalSteps', value);
  }

  /**
   * The currentStep attribute holds the current step/milestone we are currently on.
   *
   * The operator of this component must update this
   * attribute for progress on the bar to be reflected.
   * @type {string}
   * @attr
   */
  get currentStep() {
    return this.getAttribute('currentStep') || '';
  }

  set currentStep(value) {
    this.setAttribute('currentStep', value);
  }

  _handleKeyPressModalPopup(event) {
    if (event.keyCode === keyCodes.ENTER_KEYCODE ||
        event.keyCode === keyCodes.SPACE_KEYCODE) {
      this._helpModal.show();
    }
  }

  _handleClickModalPopup() {
    this._helpModal.show();
  }

  render() {
    this.innerHTML = '';
    this.classList.add('fullWidth');
    this.classList.add('pui-inline-block');
    this.classList.add('pui-pizza-tracker-card');

    const {
      featureTitle,
      title,
      redirectTitle,
      loading,
    } = this;

    this._topContainer = document.createElement('div');
    this._topContainer.classList.add('top-container');

    this._featureTitle = document.createElement('div');
    this._featureTitle.classList.add('feature-title');
    this._featureTitle.innerText = `${featureTitle}`;

    this._topContainer.appendChild(this._featureTitle);

    /**
     * Building the modal pop-up card displaying information for the Customer Care Phone Number.
     * TODO: Align with UI/UX on best long-term approach for the redirectTitle/href.
     */
    this._helpModal = document.createElement('pui-bottom-sheet');
    this._helpModal.setAttribute('id', 'pizzatrackerModal');
    this._helpModal.setAttribute('hideLink', 'true');
    const phoneNumber = this.href.split(':')[1];

    this._helpModal.innerHTML = `
      <pui-text
        fontWeight="bold"
        input="Call Customer Care"
        textSize="extra-large"
      ></pui-text>
      <div style="margin-top: 10px">
      </div>
      <pui-link
        href="${this.href}"
        text="${phoneNumber}"
        textSize="double-extra-large"
      ></pui-link>
      <div style="margin-top: 10px">
      </div>
      <pui-text
        input="8 AM–10 PM ET weekdays"
      ></pui-text>
      <pui-text
        input="10 AM–8 PM ET weekends"
      ></pui-text>
    `;

    this.appendChild(this._helpModal);

    if (redirectTitle != '') {
      this._redirectTitle = document.createElement('a');
      this._redirectTitle.classList.add('redirect-title');
      this._redirectTitle.innerText = `${redirectTitle}`;
      this._redirectTitle.addEventListener('click', () => this._handleClickModalPopup());
      this._redirectTitle.addEventListener('keydown', (event) => this._handleKeyPressModalPopup(event));
      this._redirectTitle.setAttribute('tabindex', '0');

      this._topContainer.appendChild(this._redirectTitle);
    }

    this._title = document.createElement('div');
    this._title.classList.add('title');
    this._title.innerText = `${title}`;

    this.appendChild(this._topContainer);

    this.classList.add('pui-inline-block');
    this._loadingIcon = document.createElement('div');
    this._loadingIcon.innerHTML = `
            <div class="pui-loading-icon">
              <pui-section spacingTop="small" spacingBottom="large" style="position: relative; max-width: 100%;">
                  <pui-loading-indicator/>
              </pui-section>
            </div>
    `;

    if (loading) {
      this.appendChild(this._loadingIcon);
    } else {
      this.appendChild(this._title);
    }

    this._progressBar = document.createElement('pui-pizza-tracker-bar');
    this._progressBar.setAttribute('totalSteps', this.totalSteps);
    this._progressBar.setAttribute('currentStep', this.currentStep);

    this.appendChild(this._progressBar);
  }
}

window.customElements.define('pui-pizza-tracker-card', PUIPizzaTrackerCard);
