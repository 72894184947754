import PUIBase from "../pui-base";
import { injectPUIStyles } from "../../functions/domUtils";

class PUITracker extends PUIBase {
    constructor() {
        super();
        this.defaultClass = 'pui-tracker';
    }

    connectedCallback() {
        super.connectedCallback();
        this.render();
    }

    attributeChangedCallback() {
        this.render();
    }

    static get observedAttributes() {
        return [...super.observedAttributes, 'addborder', 'innerborder'];
    }

    /**
     * This boolean will be used to add border around the tracker component
     * @type {boolean}
     * @attr
     */
    get addBorder() {
        return this.getBooleanAttribute('addBorder');
    }

    set addBorder(value) {
        this.setBooleanAttribute('addBorder', value);
    }

    get innerBorder() {
        return this.getBooleanAttribute('innerBorder');
    }

    set innerBorder(value) {
        this.setBooleanAttribute('innerBorder', value);
    }

    render() {
        this.classList.add('pui-block');
        this.classList.add(this.defaultClass);

        const {
            addBorder
        } = this;
        if (addBorder) {
            this.classList.add('pui-tracker-border');
        }

        const {
            innerBorder
        } = this;
        if (innerBorder) {
            this.classList.add('pui-tracker-inner-border');
        }

        if (!this.shadowRoot) {
            this.attachShadow({
                mode: 'open',
            });
        }
        this.shadowRoot.innerHTML = `
          <div id="pui-tracker-shadow">
            <div id="pui-tracker-promise-header">
                <slot name="pui-tracker-promise-header"></slot>
            </div>
            <div id="pui-tracker-header">
                <slot name="pui-tracker-header"></slot>
            </div>
            <div id="pui-tracker-primary">
                <div id="pui-tracker-primary-slots">
                    <div id="pui-tracker-title">
                        <slot name="pui-tracker-title"></slot>
                    </div>
                    <div id="pui-tracker-milestones">
                        <slot name="pui-milestone-milestones"></slot>
                    </div>
                    <div id="pui-tracker-button">
                        <slot name="pui-tracker-button"></slot>
                    </div>
                </div>
            </div>
            <div id="pui-tracker-footer">
                <slot name="pui-tracker-footer"></slot>
            </div>
          </div>
        `;

        injectPUIStyles(this.shadowRoot);
        const trackerMilestonesDiv = this.shadowRoot.querySelector('#pui-tracker-milestones'); 
        trackerMilestonesDiv.style.padding = '20px';

        // If present, add padding for promise header
        const trackerPromiseHeaderSlot = this.shadowRoot.querySelector('[name="pui-tracker-promise-header"]');
        const trackerPromiseHeaderDiv = this.shadowRoot.querySelector('#pui-tracker-promise-header'); 
        if (trackerPromiseHeaderSlot.assignedNodes().length > 0) {
            trackerPromiseHeaderDiv.style.padding = '20px'
            if (addBorder || innerBorder) {
                trackerPromiseHeaderDiv.style.borderBottom = '1px solid #DDE3E6';
            };
        }

        // Add padding and border for heading and footer if present 
        const trackerHeaderSlot = this.shadowRoot.querySelector('[name="pui-tracker-header"]');
        const trackerHeaderDiv = this.shadowRoot.querySelector('#pui-tracker-header'); 
        if (trackerHeaderSlot.assignedNodes().length > 0) {
            trackerHeaderDiv.style.padding = '20px'
            if (addBorder || innerBorder) {
                trackerHeaderDiv.style.borderBottom = '1px solid #DDE3E6';
            };
        }
        const trackerFooterSlot = this.shadowRoot.querySelector('[name="pui-tracker-footer"]');
        const trackerFooterDiv = this.shadowRoot.querySelector('#pui-tracker-footer');
        if (trackerFooterSlot.assignedNodes().length > 0) {
            trackerFooterDiv.style.padding = '20px';
            if (addBorder || innerBorder) {
                trackerFooterDiv.style.borderTop = '1px solid #DDE3E6';
            }
        }

        // Add padding for milestones title and button if present
        const trackerMilestonesTitle = this.shadowRoot.querySelector('[name="pui-tracker-title"]');
        const trackerMilestonesTitleDiv = this.shadowRoot.querySelector('#pui-tracker-title');
        if(trackerMilestonesTitle.assignedNodes().length > 0) {
            trackerMilestonesTitleDiv.style.padding = '20px 20px 0px 20px';
        }
        const trackerButton = this.shadowRoot.querySelector('[name="pui-tracker-button"]');
        const trackerButtonDiv = this.shadowRoot.querySelector('#pui-tracker-button');
        if(trackerButton.assignedNodes().length > 0) {
            trackerButtonDiv.style.padding = '0px 20px 20px 20px';
        }
    }
}
window.customElements.define('pui-tracker', PUITracker);
