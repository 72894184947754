import * as shadyCss from '@webcomponents/shadycss';
import PUIBase from './pui-base';
import PUIChiclet from './pui-chiclet';
import keyCodes from '../constants/keyCodes';

/* eslint-disable import/no-unresolved */
import baseCSS from '../../css/_base.scss?inline';
import chicletContainerCSS from '../../css/_chiclet-container.scss?inline';
/* eslint-enable import/no-unresolved */

const defaultClass = 'pui-chiclet-container';

const innerTemplate = `
  <style>
    ${chicletContainerCSS}
    ${baseCSS}

  </style>
  <div class="${defaultClass}">
    <div class="customSectionTitle"></div>
    <div class="customSectionDescription mini-margin-top"></div>
    <div class="customSection mini-margin-top"></div>
    <div class="selectedTitle small-margin-top"></div>
    <div class="section selected mini-margin-top"></div>
    <div class="title small-margin-top"></div>
    <div class="section suggested mini-margin-top"></div>
    <slot></slot>
  </div>
`;

const template = document.createElement('template');

template.innerHTML = innerTemplate;

shadyCss.prepareTemplate(template, 'pui-chiclet-container');

class PUIChicletContainer extends PUIBase {
  constructor() {
    super();

    shadyCss.styleElement(this);
    if (!this.shadowRoot) {
      this.attachShadow({ mode: 'open' });
      this.shadowRoot.appendChild(
        document.importNode(template.content, true),
      );
    }

    this._slot = this.shadowRoot.querySelector('slot');
    this._selectedSection = this.shadowRoot.querySelector('div.selected');
    this._selectedSectionTitle = this.shadowRoot.querySelector('div.selectedTitle');
    this._suggestedSectionTitle = this.shadowRoot.querySelector('div.title');
    this._suggestedSection = this.shadowRoot.querySelector('div.suggested');

    this._customSection = this.shadowRoot.querySelector('div.customSection');
    this._customSectionTitle = this.shadowRoot.querySelector('div.customSectionTitle');
    this._customSectionDescription = this.shadowRoot.querySelector('div.customSectionDescription');

    if (this.selectedSectionTitle === 'undefined') {
      this._selectedSectionTitle.classList.add('pui-hidden');
    }

    if (this.showCustom !== true) {
      this._customSection.classList.add('pui-hidden');
      this._customSectionTitle.classList.add('pui-hidden');
      this._customSectionDescription.classList.add('pui-hidden');
    }

    this._pressSelectedChiclet = this._pressSelectedChiclet.bind(this);
    this._clickSelectedChiclet = this._clickSelectedChiclet.bind(this);
    this._pressSuggestedChiclet = this._pressSuggestedChiclet.bind(this);
    this._clickSuggestedChiclet = this._clickSuggestedChiclet.bind(this);
  }

  connectedCallback() {
    super.connectedCallback();

    this.upgradeProperty('suggestedSectionTitle');

    this._setupComponent();
  }

  get selectedSectionTitle() {
    return this.getAttribute('selectedSectionTitle') || '';
  }

  set selectedSectionTitle(selectedSectionTitle) {
    this.setAttribute('selectedSectionTitle', selectedSectionTitle);
  }

  get suggestedSectionTitle() {
    return this.getAttribute('suggestedSectionTitle') || '';
  }

  set suggestedSectionTitle(suggestedSectionTitle) {
    this.setAttribute('suggestedSectionTitle', suggestedSectionTitle);
  }

  get customSectionTitle() {
    return this.getAttribute('customSectionTitle') || '';
  }

  set customSectionTitle(customSectionTitle) {
    this.setAttribute('customSectionTitle', customSectionTitle);
  }

  get customSectionDescription() {
    return this.getAttribute('customSectionDescription') || '';
  }

  set customSectionDescription(customSectionDescription) {
    this.setAttribute('customSectionDescription', customSectionDescription);
  }

  get hideSuggested() {
    return this.getBooleanAttribute('hideSuggested');
  }

  set hideSuggested(hideSuggested) {
    this.setBooleanAttribute('hideSuggested', hideSuggested);
  }

  get encode() {
    return this.getBooleanAttribute('encode');
  }

  /**
   * Flag to encode chiclet label. It's important for security risk.
   * Setting this to true will prevent payload injection that interacts with the victim's browser.
   * @param value
   */
  set encode(value) {
    this.setBooleanAttribute('encode', value);
  }

  get showCustom() {
    return this.getBooleanAttribute('showCustom');
  }

  set showCustom(showCustom) {
    this.setBooleanAttribute('showCustom', showCustom);
  }

  get selectedOptions() {
    return Array.from(this._selectedMap);
  }

  get itemCloseLabel() {
    return this.getAttribute('itemCloseLabel') || '';
  }

  set itemCloseLabel(itemCloseLabel) {
    this.setAttribute('itemCloseLabel', itemCloseLabel);
  }

  addSelectedOption(label, value) {
    const labelTitleCase = this._toTitleCase(label);
    const chiclet = new PUIChiclet();
    chiclet.selected = true;
    chiclet.label = labelTitleCase;
    chiclet.value = value;
    chiclet.closeLabel = this.itemCloseLabel;

    if (this._suggestedMap.has(chiclet.label)) {
      chiclet.suggested = true;
      this._removeChicletFromSuggested(this._suggestedMap.get(chiclet.label));
    }
    this._addChicletToSelected(chiclet);
  }

  addSuggestedOption(label, value) {
    const labelTitleCase = this._toTitleCase(label);
    const chiclet = new PUIChiclet();
    chiclet.label = labelTitleCase;
    chiclet.suggested = true;
    chiclet.value = value;
    chiclet.closeLabel = this.itemCloseLabel;

    this._addChicletToSuggested(chiclet);
  }

  removeAllSelections() {
    const selections = this._selectedSection.children;
    const self = this;
    Array.from(selections).forEach((element) => {
      element.selected = false;
      self._removeChiclet(element);
      self._addChicletToSuggested(element);
    });
  }

  showSuggestedChiclets() {
    const suggestedElements = [this._suggestedSection, this._suggestedSectionTitle];

    suggestedElements.forEach((el) => {
      el.classList.remove('pui-hidden');
    });
  }

  hideSuggestedChiclets() {
    const suggestedElements = [this._suggestedSection, this._suggestedSectionTitle];

    suggestedElements.forEach((el) => {
      el.classList.add('pui-hidden');
    });
  }

  _setupComponent() {
    this.setAttribute('role', 'listbox');
    this.setAttribute('aria-multiselectable', true);

    this._selectedSection.innerHTML = '';
    this._suggestedSectionTitle.innerText = this.suggestedSectionTitle;
    this._suggestedSectionTitle.setAttribute('tabindex', '0');
    this._suggestedSectionTitle.setAttribute('aria-label', this.suggestedSectionTitle);
    this._suggestedSection.innerHTML = '';
    if (this.hideSuggested === true) {
      this._suggestedSectionTitle.classList.add('pui-hidden');
      this._suggestedSection.classList.add('pui-hidden');
    }
    this._customSection.innerHTML = '';
    this._customSectionTitle.innerHTML = this.customSectionTitle;
    this._customSectionDescription.innerText = this.customSectionDescription;
    this._selectedSectionTitle.innerHTML = this.selectedSectionTitle;
    this._selectedMap = new Map();
    this._suggestedMap = new Map();

    window.addEventListener('DOMContentLoaded', this._addSlotItems.bind(this));
    if (document.readyState === 'complete' || document.readyState === 'loaded') {
      this._addSlotItems();
    }
  }

  _addSlotItems() {
    Array.from(this.getElementsByTagName('pui-chiclet'))
      .map(chiclet => chiclet.cloneNode(true))
      .forEach((chiclet) => {
        if (chiclet.selected) {
          this._addChicletToSelected(chiclet);
        } else if (chiclet.suggested) {
          this._addChicletToSuggested(chiclet);
        }
      });
    const chiclets = this.getElementsByTagName('pui-chiclet'); // chiclet references no longer needing to be stored in the DOM

    setTimeout(() => {
      for (let i = chiclets.length - 1; i >= 0; --i) {
        if (chiclets[i].classList.length == 0) {
          // Create Element.remove() function if not exist
          if (!('remove' in Element.prototype)) {
            Element.prototype.remove = function () {
              if (this.parentNode) {
                this.parentNode.removeChild(this);
              }
            };
          }
          chiclets[i].remove();
        }
      }
    }, 0);
  }

  _addInitializedItem(chiclet) {
    if (chiclet.selected) {
      this._addChicletToSelected(chiclet);
    } else if (chiclet.suggested) {
      this._addChicletToSuggested(chiclet);
    }
  }

  _addChicletToSuggested(chiclet) {
    if (!this._selectedMap.has(chiclet.label)) {
      chiclet.setAttribute('encode', this.encode);
      chiclet.addEventListener('click', this._clickSuggestedChiclet);
      chiclet.addEventListener('keyup', this._pressSuggestedChiclet);
      this._suggestedMap.set(chiclet.label, chiclet);
      this._suggestedSection.appendChild(chiclet);
    }
  }

  _addChicletToCustom(chiclet) {
    chiclet.setAttribute('encode', this.encode);
    this._customSection.appendChild(chiclet);
  }

  _removeChicletFromSuggested(chiclet) {
    chiclet.removeEventListener('click', this._clickSuggestedChiclet);
    chiclet.removeEventListener('keyup', this._pressSuggestedChiclet);
    this._suggestedMap.delete(chiclet.label);
    this._suggestedSection.removeChild(chiclet);
  }

  _addChicletToSelected(chiclet) {
    if (!this._suggestedMap.has(chiclet.label) && !this._selectedMap.has(chiclet.label)) {
      chiclet.setAttribute('encode', this.encode);
      chiclet.addEventListener('click', this._clickSelectedChiclet);
      chiclet.addEventListener('keyup', this._pressSelectedChiclet);
      if (chiclet.data) {
        this._selectedMap.set(chiclet.label, chiclet);
      } else {
        this._selectedMap.set(chiclet.label, chiclet.value);
      }

      this._selectedSection.appendChild(chiclet);

      if (this.onAddChiclet) {
        this.onAddChiclet({
          label: chiclet.label,
          value: chiclet.value,
        });
      }
    }
  }

  _removeChicletFromSelected(chiclet) {
    if (this.removeChickletCallback) {
      this.removeChickletCallback(chiclet);
    } else {
      this._removeChiclet(chiclet);
    }
  }

  _removeChiclet(chiclet) {
    chiclet.removeEventListener('click', this._clickSelectedChiclet);
    chiclet.removeEventListener('keyup', this._pressSelectedChiclet);
    this._selectedMap.delete(chiclet.label);
    this._selectedSection.removeChild(chiclet);

    if (this.onRemoveChiclet) {
      this.onRemoveChiclet({
        label: chiclet.label,
        value: chiclet.value,
      });
    }
  }

  _pressSelectedChiclet(event) {
    if (event.keyCode === keyCodes.ENTER_KEYCODE) {
      this._clickSelectedChiclet(event);
    }
  }

  _clickSelectedChiclet(event) {
    const selectedChiclet = event.target;
    selectedChiclet.selected = false;

    this._removeChicletFromSelected(selectedChiclet);

    if (selectedChiclet.suggested) {
      this._addChicletToSuggested(selectedChiclet);
    }
  }

  _pressSuggestedChiclet(event) {
    if (event.keyCode === keyCodes.ENTER_KEYCODE) {
      this._clickSuggestedChiclet(event);
    }
  }

  _clickSuggestedChiclet(event) {
    const suggestedChiclet = event.target;
    suggestedChiclet.selected = true;

    this._removeChicletFromSuggested(suggestedChiclet);
    this._addChicletToSelected(suggestedChiclet);
  }

  _toTitleCase(text) {
    return text.toLowerCase()
      .split(' ')
      .map(s => s.replace(/\w/, firstLetter => firstLetter.toUpperCase()))
      .join(' ');
  }

  _getSuggestedChiclets() {
    return this._suggestedMap;
  }

  _getSelectedChiclets() {
    return this._selectedMap;
  }
}

window.customElements.define('pui-chiclet-container', PUIChicletContainer);
