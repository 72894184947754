import PUIBase from '../pui-base';
import PUIInsuranceSaver from './pui-insurance-saver';
import PUIInsuranceUploadCard from './pui-insurance-upload-card';
import PUIInsuranceError from "./pui-insurance-error";
import keyCodes from '../../constants/keyCodes';

/**
 * This component is responsible for manually adding new insurances or
 * updating an existing insurance
 *
 * Configuration:
 *
 * @param {PUIInsuranceSelectConfig} this.config - The configuration for PUIInsuranceSelect
 * @param {string} this.config.strings.defaultErrorMessage - The default error message to fall back to
 * @param {string} this.config.strings.backButton - The text of the back button (go to previous page)
 * @param {string} this.config.strings.cancelButton - The text of the cancel button (go to previous page)
 * @param {string} this.config.strings.errorTitle - The title for the page level error box
 * @param {string} this.config.strings.pageTitle - The title for the insurance input selection page
 * @param {string} this.config.strings.pageLabel: - The message displayed on the insurance input selection page
 * @param {string} this.config.strings.captureCardTitle: - The title of the insurance image capture card
 * @param {string} this.config.strings.captureCardLabel: - The text of the insurance image capture card
 * @param {string} this.config.strings.manualCardTitle: - The title of the manual insurance input card
 * @param {string} this.config.strings.manualCardLabel: - The text of the manual insurance input card
 *
 *
 */
export default class PUIInsuranceSelect extends PUIBase {
  connectedCallback() {
    super.connectedCallback();
    this.upgradeProperty('config');
    this._render();
  }

  showError(error) {
    let message = this.config.strings.defaultErrorMessage;
    if (error && error.message) {
      message = error.message;
    }
    this._pageError.setDescription(message);
    this._pageError.show();
  }

  showGeneralAddInsuranceError_Treatment() {
    this._pageError_Treatment.show();
  }
  
  hideError() {
    this._pageError.hide();
  }

  setConfigs(insuranceSaverConfig, insuranceUploadConfig) {
    this.insuranceSaverConfig = insuranceSaverConfig;
    this.insuranceSaverConfig.callbacks.onSuccess = this.close.bind(this);
    this.insuranceUploadConfig = insuranceUploadConfig;
    this.insuranceUploadConfig.callbacks.onSuccess = this.close.bind(this);
    this.insuranceUploadConfig.callbacks.uploadFailed = this.openInsuranceSaver.bind(this);
  }

  _render() {
    this.classList.add('pui-block');
    // TODO: Use config strings
    this.innerHTML = `
        <div class="pui-full-page">
            <pui-section style="margin:auto">
                ${this.header('select-input')}
                <pui-insurance-error id="apex-insurance-image-error" title="${this.config.strings.errorTitle}" 
                               class="pui-hidden"></pui-insurance-error>
                <pui-heading input="${this.config.strings.pageTitle}" textSize="large" spacingBottom="small"></pui-heading>
                <pui-text input="${this.config.strings.pageLabel}" spacingBottom="medium"></pui-text>
                <pui-box theme="selected" id="apex-insurance-image-capture" flowDirection="vertical" spacingBottom="medium">
                    <pui-section secondaryAxisArrangement="center">
                      <pui-icon imgClass="capture-icon" flowDirection="horizontal"></pui-icon>
                      <pui-heading input="${this.config.strings.captureCardTitle}" textSize="small" textAlign="center" spacingTop="small"></pui-heading>
                      <pui-text input="${this.config.strings.captureCardLabel}" textAlign="center" spacing="small"></pui-text>
                    </pui-section>
                </pui-box>
                <pui-box theme="selected" id="apex-insurance-manual-input" flowDirection="vertical" spacingBottom="medium">
                    <pui-section secondaryAxisArrangement="center">
                      <pui-icon imgClass="form-icon" flowDirection="horizontal"></pui-icon>
                      <pui-heading input="${this.config.strings.manualCardTitle}" textSize="small" textAlign="center" spacingTop="small"></pui-heading>
                      <pui-text input="${this.config.strings.manualCardLabel}" textAlign="center" spacing="small"></pui-text>
                    </pui-section>
                </pui-box>
            </pui-section>
        </div>
        `;

    this._pageError = this.querySelector('#apex-insurance-image-error');

    this._imageCaptureButton = this.querySelector('#apex-insurance-image-capture');
    this._imageCaptureButton.addEventListener('click', this._captureInsuranceImage.bind(this));
    this._imageCaptureButton.addEventListener('keyup', this._captureInsuranceImageEnter.bind(this));

    this._manualInputButton = this.querySelector('#apex-insurance-manual-input');
    this._manualInputButton.addEventListener('click', this._addInsurancePress.bind(this));
    this._manualInputButton.addEventListener('keyup', this._addInsurancePressEnter.bind(this));

    this._cancelButton = this.querySelector('.select-input-cancel-button');
    this._cancelButton.addEventListener('click', this._onCancelPress.bind(this));
    this._cancelButton.addEventListener('keyup', this._onCancelPressEnter.bind(this));

    this._backButton = this.querySelector('.select-input-back-button');
    this._backButton.addEventListener('click', this._onCancelPress.bind(this));
    this._backButton.addEventListener('keyup', this._onCancelPressEnter.bind(this));
    this._pageError_Treatment = this.querySelector('#apex-insurance-page-error-treatment');
  }

  close() {
    this.parentNode.removeChild(this);
  }

  _onCancelPress() {
    this.close();
  }

  _onCancelPressEnter(event) {
    if (event.keyCode === keyCodes.ENTER_KEYCODE) { // Pressed Enter
      this._onCancelPress();
    }
  }

  header(pageClass) {
    const html = `
        <div class="insuranceUploadHeader">
            <pui-section flowDirection="horizontal" mainAxisArrangement="space-between" spacingBottom="medium">
                <pui-section flowDirection="horizontal" secondaryAxisArrangement="center">
                  <pui-icon imgClass="info-back-icon"></pui-icon>
                  <pui-text class="${pageClass}-back-button" style="cursor: pointer;" spacingLeft="small"
                    textColor="link" input="${this.config.strings.backButton}">
                  </pui-text>
                </pui-section>
                <pui-text class="${pageClass}-cancel-button" style="cursor: pointer;"
                  textColor="link" input="${this.config.strings.cancelButton}">
                </pui-text>
            </pui-section>
        </div>
    `;
    return html;
  }

  _addInsurancePressEnter(event) {
    if (event.keyCode === keyCodes.ENTER_KEYCODE) { // Pressed Enter
      this._addInsurancePress();
    }
  }

  _addInsurancePress() {
    this.openInsuranceSaver();
  }

  _captureInsuranceImageEnter(event) {
    if (event.keyCode === keyCodes.ENTER_KEYCODE) { // Pressed Enter
      this._captureInsuranceImage();
    }
  }

  _captureInsuranceImage() {
    this.openInsuranceUpload();
  }

  openInsuranceSaver() {
    const insuranceSaver = new PUIInsuranceSaver();
    insuranceSaver.configure(this.insuranceSaverConfig);
    this.appendChild(insuranceSaver);
    insuranceSaver.setAttribute('tabindex', '0');
    insuranceSaver.focus();
  }

  openInsuranceUpload() {
    const insuranceUpload = new PUIInsuranceUploadCard();
    insuranceUpload.configure(this.insuranceUploadConfig);
    this.appendChild(insuranceUpload);
    insuranceUpload.setAttribute('tabindex', '0');
    insuranceUpload.focus();
  }
}

window.customElements.define('pui-insurance-select', PUIInsuranceSelect);
