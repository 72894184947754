import PUIBase from "./pui-base"
import PUIText from "./pui-text";
import "../../css/_roundel.scss";

// make sure these correspond to each other
const DEFAULT_CLASS_NAME = 'pui-sub-nav-person-icon';
const DEFAULT_SIZE ='medium';
const DEFAULT_BACKGROUND_COLOR = 'ghost';

type sizeType = 'small' | 'medium' | 'small-medium';
type backgroundColorType = 'transparent' | 'ghost';
/**
 * An element that renders a set of uppercase initials inside a circle
 * 
 * This is typically used to show the initials of the currently authenticated
 * customer, so that customers know who they're shopping on behalf of.
 */
export class PUIRoundelElement extends PUIBase {
    static get observedAttributes() {
        return [
            ...super.observedAttributes,
            'initials',
            'size',
            'backgroundColor',
        ];
    }

    private textNode?: PUIText;

    public get initials() {
        return this.getAttribute('initials') || '';
    }

    public set initials(newValue: string) {
        this.setAttribute('initials', newValue);
    }
    /**
    * Specifies the size of the roundel along with its specific style
    * @type {"small"|"medium"}
    * @attr
    */

    public get size() {
        let value = this.getAttribute('size');
        if (value === 'small' || value === 'medium' || value === 'small-medium') {
            return value;
        } else {
            return DEFAULT_SIZE;
        }
        
    }

    public set size(newValue: sizeType) {
        this.setAttribute('size', newValue);
    }

    /**
     * Whether to indicate background color of the circle
     * @attr
     */
    public get backgroundColor() {
        let value = this.getAttribute('backgroundColor');
        if (value == 'transparent') {
            return value;
        } else {
            return DEFAULT_BACKGROUND_COLOR;
        }
    }

    public set backgroundColor(newValue: backgroundColorType) {
        this.setAttribute('backgroundColor', newValue);
    }

    connectedCallback() {
        this.render();
    }

    attributeChangedCallback() {
        super.attributeChangedCallback();
        this.update();
    }

    private render() {
        let sizeClass = '';
        let textColor = 'black';
        let backgroundClass = '';
        let textSize = '';
        let fontWeight = '';

        switch (this.size) {
            case 'medium':
                sizeClass = 'pui-sub-nav-person-icon';
                textColor = 'black';
                textSize = 'small';
                break;
            case 'small-medium':
                sizeClass = 'pui-small-med-dot-icon';
                textColor = 'black';
                break;
            case 'small':
                sizeClass = 'pui-step-dot-icon';
                textColor = "med-grey";
                textSize = 'small';
                break;
            default:
                sizeClass = DEFAULT_CLASS_NAME;
                textColor = 'black';
                textSize = 'small';
                break;
        }

        switch (this.backgroundColor) {
            case 'transparent':
                backgroundClass = 'pui-transparent-dot-icon';
                textColor = 'black';
                textSize = 'large';
                fontWeight = 'bold';
                break;
            default: 
                backgroundClass = DEFAULT_CLASS_NAME;
                textColor = 'black';
                textSize = 'small';
                break;
        }


        this.innerHTML = `<pui-section class="${sizeClass} ${backgroundClass ?? ''}" spacingRight="small">
            <pui-text textSize="${textSize}" textColor=${textColor} fontWeight=${fontWeight}></pui-text>
        </pui-section>`;
        this.textNode = this.querySelector('pui-text')!;
        this.update();
    }

    private update() {
        this.textNode?.setTextSafe(this.initials)
    }
}
window.customElements.define('pui-roundel', PUIRoundelElement);
