import PUIBase from "./pui-base";
import Request from "../networking/request";
import getGlowColors from "../attributeValues/glowColorVariants";
import includes from "../functions/includes";
import keyCodes from "../constants/keyCodes";

/**
 * @class PUIMiniGlowIngress
 * @extends PUIBase
 * @description A component that displays a location selector in a mini format, typically used in navigation bars.
 * When clicked, it opens a bottom sheet modal allowing users to change their ZIP code location.
 * The component handles ZIP code validation, location updates, and page reloading after location changes.
 * Regarding `skipReload` attribute - Glow team recommends for best practice, the page should be refreshed
 * after the location is updated so the context is updated, and all delivery related information on the page is 
 * updated after the page refresh.
 *
 * @property {string} zipCode - The current ZIP code to display. Defaults to 'Update Location' if invalid
 * @property {string} glowColor - Color variant for the component (black/white). Defaults to 'black'
 * @property {string} skipReload - Flag to reload page after location change. Defaults to false.
 * @property {string} getLocationUrl - URL endpoint for fetching the location
 * @property {string} updateLocationUrl - URL endpoint for updating the location
 * @property {string} glowModalHeaderLabel - Label for the modal header
 * @property {string} glowModalSubHeaderLabel - Label for the modal subheader
 * @property {string} glowModalZipCodeInputLabel - Label for the ZIP code input field
 * @property {string} glowModalSubmitButtonLabel - Label for the submit button
 * @property {string} glowModalClientErrorMessage - Error message for client-side errors
 * @property {string} glowModalServerErrorMessage - Error message for server-side errors
 * @property {string} defaultZipCode - Default ZIP code text to display when invalid
 * @property {string} defaultGlowColor - Default glow color variant
 * @property {string} glowCsrfToken - csrf token for glow
 *
 * @example
 *   
     <pui-mini-glow-ingress glowColor="black" zipCode="98109"
      glowModalHeaderLabel="Some Glow Modal Header"
      glowModalSubHeaderLabel="Some Glow Modal Sub Header"
      glowModalZipCodeInputLabel="Some Glow Modal ZipCode Input Label"
      glowModalSubmitButtonLabel="Some Glow Modal Submit Button Label"
      glowModalClientErrorMessage="Some Glow Modal Client Error Message"
      glowModalServerErrorMessage="Some Glow Modal Server Error Message"
    >
    </pui-mini-glow-ingress>
 */
export default class PUIMiniGlowIngress extends PUIBase {
  constructor() {
    super();
    this.defaultClass = "glow-ingress";
    this.defaultGlowColor = "black";
    this.defaultZipCode = "Update location";
  }

  connectedCallback() {
    super.connectedCallback();
    //skip rendering the component if the glow strings are empty
    if (!this.hasMandatoryGlowAttributes()) {
      return;
    }
    this.initialize();
    this.render();
  }

  static get observedAttributes() {
    return ["zipcode", "glowcolor"];
  }

  /**
   * Gets the current ZIP code value
   * @returns {string} The current ZIP code if valid, otherwise returns defaultZipCode
   */
  get zipCode() {
    const zip = this.getAttribute("zipCode");
    return this._isValidUSZip(zip) ? zip : this.defaultZipCode;
  }

  /**
   * Sets the ZIP code value
   * @param {string} value - The ZIP code to set
   */
  set zipCode(value) {
    const zip = this._isValidUSZip(value) ? value : this.defaultZipCode;
    this.setAttribute("zipCode", zip);
  }

  /**
   * Gets the glow color variant
   * @returns {string} The glow color variant if valid, otherwise returns defaultGlowColor
   */
  get glowColor() {
    return this.getAttribute("glowColor") || this.defaultGlowColor;
  }

  /**
   * Sets the glow color variant
   * @param {string} value - The color variant to set
   */
  set glowColor(value) {
    if (!includes(getGlowColors(), value)) {
      this.setAttribute("glowColor", this.defaultGlowColor);
      return;
    }
    this.setAttribute("glowColor", value);
  }

  /**
   * Gets the skipReload flag
   * @returns {string} The skipReload flag
   */
  get skipReload() {
    return this.getAttribute("skipReload") || "false";
  }

  /**
   * Sets the skipReload flag
   * @param {string} value - The value to set
   */
  set skipReload(value) {
    this.setAttribute("skipReload", value);
  }

  /**
   * Gets the URL for fetching the location
   * @returns {string} The URL for fetching the location
   */
  get getLocationUrl() {
    return this.getAttribute("getLocationUrl") || "";
  }

  /**
   * Sets the URL for fetching the location
   * @param {string} value - The URL to set
   */
  set getLocationUrl(value) {
    this.setAttribute("getLocationUrl", value);
  }

  /**
   * Gets the URL for updating the location
   * @returns {string} The URL for updating the location
   */
  get updateLocationUrl() {
    return this.getAttribute("updateLocationUrl") || "";
  }

  /**
   * Sets the URL for updating the location
   * @param {string} value - The URL to set
   */
  set updateLocationUrl(value) {
    this.setAttribute("updateLocationUrl", value);
  }

  /*
   * Gets the glow modal header label
   * @returns {string} The glow modal header label
   */
  get glowModalHeaderLabel() {
    return (
      this.getAttribute("glowModalHeaderLabel") ||
      this.defaultGlowModalHeaderLabel
    );
  }

  /**
   * Sets the glow modal header label
   * @param {string} value - The header label to set
   */
  set glowModalHeaderLabel(value) {
    this.setAttribute("glowModalHeaderLabel", value);
  }

  /**
   * Gets the glow modal sub header label
   * @returns {string} The glow modal sub header label
   */
  get glowModalSubHeaderLabel() {
    return (
      this.getAttribute("glowModalSubHeaderLabel") ||
      this.defaultGlowModalSubHeaderLabel
    );
  }

  /**
   * Sets the glow modal sub header label
   * @param {string} value - The sub header label to set
   */
  set glowModalSubHeaderLabel(value) {
    this.setAttribute("glowModalSubHeaderLabel", value);
  }

  /**
   * Gets the glow modal zip code input label
   * @returns {string} The glow modal zip code input label
   */
  get glowModalZipCodeInputLabel() {
    return this.getAttribute("glowModalZipCodeInputLabel") || "";
  }

  /**
   * Sets the glow modal zip code input label
   * @param {string} value - The zip code input label to set
   */
  set glowModalZipCodeInputLabel(value) {
    this.setAttribute("glowModalZipCodeInputLabel", value);
  }

  /**
   * Gets the glow modal submit button label
   * @returns {string} The glow modal submit button label
   */
  get glowModalSubmitButtonLabel() {
    return this.getAttribute("glowModalSubmitButtonLabel") || "";
  }

  /**
   * Sets the glow modal submit button label
   * @param {string} value - The submit button label to set
   */
  set glowModalSubmitButtonLabel(value) {
    this.setAttribute("glowModalSubmitButtonLabel", value) || "";
  }

  /**
   * Gets the glow modal client error message
   * @returns {string} The client error message
   */
  get glowModalClientErrorMessage() {
    return this.getAttribute("glowModalClientErrorMessage") || "";
  }
  /**
   * Sets the glow modal client error message
   * @param {string} value - The client error message to set
   */
  set glowModalClientErrorMessage(value) {
    this.setAttribute("glowModalClientErrorMessage", value);
  }

  /**
   * Gets the glow modal server error message
   * @returns {string} The server error message
   */
  get glowModalServerErrorMessage() {
    return this.getAttribute("glowModalServerErrorMessage") || "";
  }

  /**
   * Sets the glow modal server error message
   * @param {string} value - The server error message to set
   */
  set glowModalServerErrorMessage(value) {
    this.setAttribute("glowModalServerErrorMessage", value);
  }

  /**
   * Gets the glow CSRF token
   * @returns {string} The glow CSRF token
   */
  get glowCsrfToken() {
    return this.getAttribute("glowCsrfToken") || "";
  }

  /**
   * Sets the glow CSRF token
   * @param {string} value - The CSRF token to set
   */
  set glowCsrfToken(value) {
    this.setAttribute("glowCsrfToken", value);
  }

  /**
   * Initializes the component by setting default color and fetching ZIP code if needed
   */
  initialize() {
    this.glowColor = this.glowColor || this.defaultGlowColor;
    if (this.zipCode === this.defaultZipCode || this.glowCsrfToken === "") {
      this._fetchZipCode();
    }
  }

  /*
   * Checks if the component has all the mandatory glow strings
   * @returns {boolean} True if all mandatory glow strings are present, false otherwise
   */
  hasMandatoryGlowAttributes() {
    return (
      this.glowModalHeaderLabel &&
      this.glowModalSubHeaderLabel &&
      this.glowModalZipCodeInputLabel &&
      this.glowModalSubmitButtonLabel &&
      this.glowModalClientErrorMessage &&
      this.glowModalServerErrorMessage &&
      this.getLocationUrl &&
      this.updateLocationUrl
    );
  }

  /**
   * Renders the component's DOM structure
   * Creates the mini glow widget and associated modal
   * @protected
   */
  render() {
    const {
      zipCode,
      glowColor,
      glowModalHeaderLabel,
      glowModalSubHeaderLabel,
      glowModalZipCodeInputLabel,
      glowModalSubmitButtonLabel,
    } = this;

    this.classList.add("pui-inline-block");
    this._innerMiniGlow = document.createElement("div");
    this._innerMiniGlow.classList.add(this.defaultClass);
    this._innerMiniGlow.innerHTML = `
        <pui-section flowDirection="horizontal" class="glow-ingress mini-glow-wrapper">
            <pui-icon imgClass="glow-location-${glowColor}"></pui-icon>
            <pui-text input="${zipCode}" textColor="${glowColor}" textSize="medium" fontWeight="normal" class="glow-zip-text"></pui-text>
            <pui-icon imgClass="chevron-down-${glowColor}"></pui-icon>
        </pui-section>
    `;

    this._glowModal = document.createElement("pui-bottom-sheet");
    this._glowModal.setAttribute("id", "glowModal");
    this._glowModal.setAttribute("hideLink", "true");

    this._glowModal.innerHTML = `
    <pui-section flowDirection="vertical">
      <pui-text fontWeight="bold" input="${glowModalHeaderLabel}" textSize="large"></pui-text>
      <pui-text textSize="medium" spacingTop="mini" spacingBottom="medium"
        input="${glowModalSubHeaderLabel}">
      </pui-text>
      <pui-input maxLength="5" allowedCharacters="numeric" class="pui-glow-modal-zipcode-input" placeholder="${glowModalZipCodeInputLabel}" spacingBottom="medium"></pui-input>
      <pui-button theme="yellow-button" label="${glowModalSubmitButtonLabel}" formAction="submit" class="pui-button-full-width pui-block pui-glow-modal-apply-button" style="text-align: center;">
    </pui-button>
    </pui-section>
    `;

    this.appendChild(this._innerMiniGlow);
    this.appendChild(this._glowModal);

    this._zipText = this._innerMiniGlow.querySelector(".glow-zip-text");
    this._modalZipInput = this._glowModal.querySelector(
      ".pui-glow-modal-zipcode-input"
    );
    this._modalGlowApplyButton = this._glowModal.querySelector(
      ".pui-glow-modal-apply-button"
    );
    this._modalZipInput.addEventListener("keydown", (event) =>
      this._handleZipInputKeyDown(event)
    );
    this._innerMiniGlow.addEventListener(
      "click",
      this._handleGlowWidgetClick.bind(this)
    );

    this._modalGlowApplyButton.addEventListener(
      "click",
      this._handleSubmitModal.bind(this)
    );
  }

  /**
   * Validates if a string is a valid US ZIP code
   * @param {string} zip - The ZIP code to validate
   * @returns {boolean} True if valid US ZIP code format (12345 or 12345-6789), false otherwise
   */
  _isValidUSZip(zip) {
    // Basic 5-digit or 5+4 format (12345 or 12345-6789)
    const zipRegex = /^\d{5}(-\d{4})?$/;
    return zipRegex.test(zip);
  }

  /**
   * Shows the bottom sheet modal when the glow widget is clicked
   */
  _handleGlowWidgetClick() {
    this._glowModal.show();
  }

  /**
   * Fetches the ZIP code from the server
   */
  _fetchZipCode() {
    Request.get(this.getLocationUrl)
      .then((response) => {
        if (response.successful) {
          const zipFromResponse =
            response?.getLocationLabelOutput?.customerIntent?.zipCode ||
            this.defaultZipCode;
          this.zipCode = zipFromResponse;
          this.glowCsrfToken = response?.glowCsrfToken;
          this._zipText.setAttribute("input", zipFromResponse);
        }
      })
      .catch((e) => {
        console.error(e);
        this._zipText.setAttribute("input", this.defaultZipCode);
      });
  }

  /**
   * Handles the submission of a new ZIP code
   * Validates the input and makes a server request to update the location
   */
  async _handleSubmitModal() {
    this._modalZipInput.hideError();
    const { glowModalClientErrorMessage } = this;
    const zipInputValue = this._modalZipInput.getValue();
    if (!this._isValidUSZip(zipInputValue)) {
      this._modalZipInput.showError(glowModalClientErrorMessage);
    } else {
      let response = await fetch(this.updateLocationUrl, {
        method: "POST",
        headers: {
          "anti-csrftoken-a2z": this.glowCsrfToken,
          "Content-Type": "application/json;charset=UTF-8",
        },
        body: JSON.stringify({ zipCode: zipInputValue }),
      });

      if (!response.ok) {
        this._handleError(response.status);
      } else {
        // Note: Skipping refresh is not recommended by Glow team.
        // Glow team's recommendation: The best practice, the page should be refreshed
        // after the location is updated so the context is updated, and all delivery
        // related information on the page is updated after the page refresh.
        if (this.skipReload === "true") {
          this.zipCode = zipInputValue;
          this._zipText.setAttribute("input", zipInputValue);
          // emit glowLocationUpdated event
          const event = new CustomEvent("glowLocationUpdated");
          this.dispatchEvent(event);
          this._glowModal.hide();
        } else {
          this._reloadPage();
        }
      }
    }
  }

  /*
   * Handles errors from the server response
   * Displays an error message based on the response status
   */
  _handleError(status) {
    const { glowModalClientErrorMessage, glowModalServerErrorMessage } = this;
    if (status >= 400 && status <= 499) {
      this._modalZipInput.showError(glowModalClientErrorMessage);
    } else if (status >= 500 && status <= 599) {
      this._modalZipInput.showError(glowModalServerErrorMessage);
    }
  }

  /**
   * Handles keydown events in the ZIP code input field
   * Submits the form when Enter key is pressed
   * @param {KeyboardEvent} event - The keyboard event
   */
  _handleZipInputKeyDown(event) {
    if (event.keyCode === keyCodes.ENTER_KEYCODE) {
      this._handleSubmitModal();
    }
  }

  /**
   * Reloads the page
   */
  _reloadPage() {
    // In android webview reloading the current page (location.href or location.replace) does not work.
    // However, on changing the url e.g. by adding a random search parameter it loads successfully.
    // Adding this temporary fix until a native android solution is implemented.
    const url = new URL(
      window.location.origin +
        window.location.pathname +
        window.location.search +
        window.location.hash
    );
    const reloadParam = parseInt(url.searchParams.get("reload") ?? "0") + 1;
    url.searchParams.set("reload", reloadParam.toString());
    window.location.replace(url);
  }
}

window.customElements.define("pui-mini-glow-ingress", PUIMiniGlowIngress);
