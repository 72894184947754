import * as shadyCss from '@webcomponents/shadycss';
import PUIBase from './pui-base';

const properties = ['elementValue', 'width', 'height', 'scrollable', 'flowDirection', 'mainAxisArrangement', 'secondaryAxisArrangement', 'centerlane', 'pageContainer', 'backgroundImage', 'backgroundColor', 'fullWidth'];

const innerTemplate = `
  <slot>
    Inner Section Columns
  </slot>
`;

const template = document.createElement('template');

template.innerHTML = innerTemplate;

shadyCss.prepareTemplate(template, 'pui-section');


/**
 * Component for arranging HTML elements in a stack, either vertically or horizontally
 * 
 * This element abstracts away a bunch of fuss involved with Flexboxes, and lets
 * you specify how the elements should be arranged. It lets you center things
 * on screen, evenly space out a bunch of items, or have one item grow to
 * fill the remaining space.
 * 
 * ### Example
 * 
 *  ```html
 *  <pui-section flowDirection="horizontal" fullWidth>
 *    <pui-roundel initials="HB"></pui-roundel>
 *    <pui-text input="Harper Beckham"></pui-text>
 *  </pui-section>
 *  ```
 * 
 * This will render a horizontal line item with a roundel and some text arranged
 * inline.
 */
export default class PUISection extends PUIBase {
  constructor() {
    super();
    this.defaultClass = 'pui-section';

    if (!this.shadowRoot) {
      this.attachShadow({ mode: 'open' });
      this.shadowRoot.appendChild(
        document.importNode(template.content, true),
      );
    }
  }

  static get observedAttributes() {
    return [
        ...super.observedAttributes,
        'elementvalue', 
        'width', 
        'height', 
        'scrollable', 
        'flowdirection',
        'mainaxisarrangement', 
        'secondaryaxisarrangement', 
        'centerlane',
        'pagecontainer', 
        'backgroundimage', 
        'backgroundcolor', 
        'fullwidth',
        'padding', 
        'paddingtop', 
        'paddingbottom', 
        'borderradius'
      ];
  }

  connectedCallback() {
    super.connectedCallback();
    if (this.onSectionPress) {
      this.setAttribute('tabindex', '0');
      this.addEventListener('click', this.#sectionClicked.bind(this));
    }
    this.classList.add(this.defaultClass);
    this.render();
  }

  attributeChangedCallback() {
    if (!this.isConnected) return;
    super.attributeChangedCallback();

    this.clearSectionClasses();

    this.render();
  }

  disconnectedCallback() {
    this.removeEventListener('click', this.#sectionClicked);
  }

  render() {
    const {
      width,
      height,
      scrollable,
      fullWidth,
      pageContainer,
      centerlane,
      flowDirection,
      mainAxisArrangement,
      secondaryAxisArrangement,
      backgroundImage,
      backgroundColor,
      borderRadius,
    } = this;

    if (width) {
      this.style.width = width;
    } else {
      this.style.width = null;
      this.classList.add('pui-section-full-width');
    }

    if (height) {
      this.style.height = height;
    } else {
      this.style.height = null;
    }

    if (scrollable) {
      if (flowDirection === 'horizontal') {
        this.classList.add('pui-section-horizontal-scroll');
      } else {
        this.classList.add('pui-section-vertical-scroll');
      }
    }

    if (fullWidth) {
      this.classList.remove('pui-section-medium-width');
    } else {
      this.classList.add('pui-section-medium-width');
    }

    if (pageContainer) {
      this.classList.add('pui-section-padding');
    }

    if (centerlane) {
      this.classList.add('centerlane');
    }

    if (flowDirection) {
      this.classList.add(`flow-direction-${flowDirection}`);
    } else {
      this.classList.add('flow-direction-vertical');
    }

    if (mainAxisArrangement) {
      this.classList.add(`main-axis-arrangement-${mainAxisArrangement}`);
    }

    if (secondaryAxisArrangement) {
      this.classList.add(`secondary-axis-arrangement-${secondaryAxisArrangement}`);
    }

    if (backgroundImage) {
      this.style.backgroundImage = `url("${backgroundImage}")`;
      this.style.backgroundRepeat = 'no-repeat';
      this.style.backgroundSize = 'cover';
    } else {
      this.style.backgroundImage = null;
      this.style.backgroundRepeat = null;
      this.style.backgroundSize = null;
    }

    if (backgroundColor) {
      this.style.backgroundColor = backgroundColor;
    } else {
      this.style.backgroundColor = null;
    }

    if (borderRadius) {
      this.style.borderRadius = borderRadius;
    } else {
      this.style.borderRadius = null;
    }
  }

  get elementValue() {
    return this.getAttribute('elementValue') || '';
  }

  set elementValue(value) {
    this.setAttribute('elementValue', value);
  }

  /**
   * Set the width of this section in CSS.
   * 
   * ### Example
   * 
   * ```
   * <pui-section width="calc(100vw - 42px)"></pui-section>
   * ```
   */
  get width() {
    return this.getAttribute('width') || '';
  }

  set width(value) {
    this.setAttribute('width', value);
  }

  /**
   * Set the height of this section in CSS.
   * 
   * Accepts any value that the CSS `height` rule accepts.
   * 
   * ### Example
   * 
   * ```
   * <pui-section height="100vh"></pui-section>
   * ```
   */
  get height() {
    return this.getAttribute('height') || '';
  }

  set height(value) {
    this.setAttribute('height', value);
  }

  get backgroundImage() {
    return this.getAttribute('backgroundImage') || '';
  }

  set backgroundImage(value) {
    this.setAttribute('backgroundImage', value);
  }

  get backgroundColor() {
    return this.getAttribute('backgroundColor') || '';
  }

  set backgroundColor(value) {
    this.setAttribute('backgroundColor', value);
  }


  /**
   * Whether this PUI Section should be scrollable.
   * 
   * By default, PUI Sections will hide any content that overflows and can't fit
   * inside the container. Setting this to true will instead show a scrollbar.
   */
  get scrollable() {
    return this.getBooleanAttribute('scrollable');
  }

  set scrollable(value) {
    this.setBooleanAttribute('scrollable', value);
  }

  /**
   * Whether this PUI Section should take the full width of it's container.
   * 
   * By default, PUI sections on Desktop constrain their maximum width to ~550px,
   * to make responsive page layouts easier to author. Setting fullWidth negates
   * this rule, and the PUI Section will instead take up `width: 100%`.
   */
  get fullWidth() {
    return this.getBooleanAttribute('fullWidth');
  }

  set fullWidth(value) {
    this.setBooleanAttribute('fullWidth', value);
  }

  get pageContainer() {
    return this.getBooleanAttribute('pageContainer');
  }

  set pageContainer(value) {
    this.setBooleanAttribute('pageContainer', value);
  }

  get centerlane() {
    return this.getBooleanAttribute('centerlane');
  }

  set centerlane(value) {
    this.setBooleanAttribute('centerlane', value);
  }

  /** Which direction this PUI Section should render it's children in.
   * 
   * Defaults to horizontal.
   * 
   * @type {"horizontal" | "vertical"}
   */
  get flowDirection() {
    return this.getAttribute('flowDirection') || '';
  }

  set flowDirection(value) {
    this.setAttribute('flowDirection', value);
  }

  /** How elements should be aligned in the `flowDirection` of this section.
   * 
   * The "main" axis is whichever direction the stack is in- a `flowDirection`
   * of `horizontal` means the main axis is also horizontal.
   * 
   * ### Possible values
   * 
   * `start` means that each child will be left-aligned, if `flowDirection` is vertical,
   * and top-aligned, if flowDirection is horizontal. `end` is the inverse of this,
   * and `center` will render each child in the middle.
   * 
   * @type {"start" | "center" | "end" | "space-around" | "space-between"}
   */
  get mainAxisArrangement() {
    return this.getAttribute('mainAxisArrangement') || '';
  }

  set mainAxisArrangement(value) {
    this.setAttribute('mainAxisArrangement', value);
  }

  /**
   * How elements should be arranged in the opposite direction of `flowDirection`.
   * 
   * Like the main axis, the secondary axis is defined based on `flowDirection`.
   * If `flowDirection` is horizontal, then the secondary axis is vertical.
   * 
   * @type {"start" | "center" | "end" | "stretch" | "baseline"}
   */
  get secondaryAxisArrangement() {
    return this.getAttribute('secondaryAxisArrangement') || '';
  }

  set secondaryAxisArrangement(value) {
    this.setAttribute('secondaryAxisArrangement', value);
  }

  get borderRadius() {
    return this.getAttribute('borderRadius') || '';
  }

  set borderRadius(value) {
    this.setAttribute('borderRadius', value);
  }

  getElementValue() {
    return this.elementValue;
  }

  #sectionClicked() {
    this.onSectionPress(this.elementValue);
  }

  clearSectionClasses() {
    let classesToRemove = [
      'pui-section-full-width',
      'pui-section-horizontal-scroll',
      'pui-section-vertical-scroll',
      'pui-section-padding',
      'centerlane',
      'flow-direction-vertical',
      'flow-direction-horizontal'
    ];
    for (const mainArrangement of ['start', 'center', 'end', 'space-around', 'space-between']) {
      classesToRemove.push(`main-axis-arrangement-${mainArrangement}`);
    }
    for (const secondaryArrangement of ['start', 'center', 'end', 'stretch', 'baseline']) {
      classesToRemove.push(`secondary-axis-arrangement-${secondaryArrangement}`)
    }
    this.classList.remove(...classesToRemove);
  }
}

window.customElements.define('pui-section', PUISection);
