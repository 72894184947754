import PUIBase from './pui-base';

// Extend the LitElement base class
class PUIBadge extends PUIBase {
  constructor() {
    super();
    this.defaultClass = 'pui-badge';
    this.defaultBadgeText = '';
  }

  connectedCallback() {
    super.connectedCallback();
    this.render();
  }

  get iconClass() {
    return this.getAttribute('iconClass') || '';
  }

  set iconClass(value) {
    this.setAttribute('iconClass', value);
  }

  get text() {
    return this.getAttribute('text') || '';
  }

  set text(value) {
    this.setAttribute('text', value);
  }

  get fontWeight() {
    return this.getAttribute('fontWeight') || '';
  }

  set fontWeight(value) {
    this.setAttribute('fontWeight', value);
  }

  get textColor() {
    return this.getAttribute('textColor') || '';
  }

  set textColor(value) {
    this.setAttribute('textColor', value);
  }

  get textSize() {
    return this.getAttribute('textSize') || '';
  }

  set textSize(value) {
    this.setAttribute('textSize', value);
  }

  get backgroundColor() {
    return this.getAttribute('backgroundColor') || '';
  }

  set backgroundColor(value) {
    this.setAttribute('backgroundColor', value);
  }

  /**
   * badgeType will set the style of the badge
   * Valid Types: 'upper-right-tab'
   */
  get badgeType() {
    return this.getAttribute('badgeType') || '';
  }
  
  set badgeType(value) {
    this.setAttribute('badgeType', value);
  }

  render() {
    const {
      iconClass,
      text,
      fontWeight,
      textColor,
      textSize,
      backgroundColor,
      badgeType,
    } = this;

    this.classList.add('pui-inline-block');
    this._innerBadge = document.createElement('div');
    this._innerBadge.classList.add(this.defaultClass);
    this._innerBadge.classList.add(`${this.defaultClass}-${backgroundColor}`);
    this._innerBadge.classList.add(`${this.defaultClass}-type-${badgeType}`);
    this._innerBadge.innerHTML = `
        ${iconClass ? `<pui-icon imgClass=${iconClass} spacingRight="mini"></pui-icon>` : ''}
        <pui-text 
          input="${text}" 
          fontWeight="${fontWeight}" 
          textColor="${textColor}"
          textSize="${textSize}"
        ></pui-text>
    `;
    this.appendChild(this._innerBadge);
  }
}

window.customElements.define('pui-badge', PUIBadge);
