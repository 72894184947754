import Urls from "../../../constants/urls";
import { injectPUIStyles, keyListener, template } from "../../../functions/domUtils";
import { getInitials } from "../../../functions/nameUtils";
import PUIBase from "../../pui-base";
import PUIBottomSheet from "../../pui-bottom-sheet";
import PUIButton from "../../pui-button";
import PUIHeading from "../../pui-heading";
import { PUILink } from "../../pui-link";
import { PUIRoundelElement } from "../../pui-roundel";

/**
 * This component allows customers to pick a profile from a list of profiles,
 * so that they can change which profile they are authenticated as.
 */
export class PUIProfileWidget extends PUIBase {
    static get observedAttributes(): string[] {
        return [
            ...super.observedAttributes,
            'friendlyname',
            'data-string-account-holder',
            'data-string-change-profile-link',
            'data-string-sign-out'
        ]
    }

    private roundel?: PUIRoundelElement;
    private accountHolder?: PUIHeading;
    private accountHolderHeading?: PUIHeading;
    private switchCustomerLink?: PUILink;
    private signOutLink?: PUILink | null;
    private personBottomSheet?: PUIBottomSheet;
    private bottomSheetButton?: PUIButton;
    private isRendered = false;

    /** The name used to refer to this customer by */
    public get friendlyName() {
        return this.getAttribute('friendlyName') ?? '';
    }

    public set friendlyName(value: string) {
        this.setAttribute('friendlyName', value);
    }

    connectedCallback(): void {
        super.connectedCallback();
        this.render();
    }

    attributeChangedCallback(): void {
        super.attributeChangedCallback();
        this.update();
    }

    private render() {
        this.attachShadow({ mode: "open" });
        injectPUIStyles(this.shadowRoot!);
        const templateElement = template`
            <button id="person-bottomsheet-button" class="pui-sub-nav-bottom-sheet-button">
                <pui-roundel></pui-roundel>
            </button>
            <pui-bottom-sheet id="person-bottomsheet" hideLink ${this.isMShop ? 'isMShop' : ''}>
                <pui-section>
                    <pui-section flowDirection="horizontal" secondaryAxisArrangement="center">
                        <pui-icon imgClass="person-icon" spacingRight="medium"></pui-icon>
                        <pui-section mainAxisArrangement="space-between">
                            <pui-heading id="profile-widget-heading"></pui-heading>
                            <pui-text id="account-holder-text"></pui-text>
                        </pui-section>
                    </pui-section>
                    <pui-divider spacingTop="medium"></pui-divider>
                    <slot></slot>
                    <pui-link id="switch-or-add-customer-link" style="white-space: nowrap" class="hover-underline" textcolor="link" textSize="medium" href="${Urls.PEOPLE_PICKER_URL}" spacingTop="small"></pui-link>
                    ${this.isMShop ? '' : `<pui-link id="sign-out-link" style="white-space: nowrap" textcolor="link" textSize="medium" href="${Urls.SIGN_OUT_URL}" spacingTop="small"></pui-link>`}
                </pui-section>
            </pui-bottom-sheet>
        `;

        this.shadowRoot!.appendChild(
            templateElement.content,
        );

        this.roundel = this.shadowRoot!.querySelector("pui-roundel")!;
        this.accountHolder = this.shadowRoot!.querySelector("#account-holder-text")!;
        this.accountHolderHeading = this.shadowRoot!.querySelector("#profile-widget-heading")!;
        this.switchCustomerLink = this.shadowRoot!.querySelector("#switch-or-add-customer-link")!;
        this.signOutLink = this.shadowRoot!.querySelector("#sign-out-link");
        this.personBottomSheet = this.shadowRoot!.querySelector("#person-bottomsheet")!;
        this.bottomSheetButton = this.shadowRoot!.querySelector("#person-bottomsheet-button")!;
        this.isRendered = true;
        this.update();
        this.setupEventHooks();
    }

    private update() {
        if (!this.isRendered) return;
        this.roundel!.initials = getInitials(this.friendlyName);
        this.accountHolder?.setTextSafe(this.dataset.stringAccountHolder);
        this.accountHolderHeading?.setTextSafe(this.friendlyName);
        this.switchCustomerLink!.text = this.dataset.stringChangeProfileLink ?? '';
        if (this.signOutLink) {
            this.signOutLink.text = this.dataset.stringSignOut ?? '';
        }
    }

    private setupEventHooks() {
        this.bottomSheetButton?.addEventListener('click', this.openPersonBottomSheet.bind(this));
        this.bottomSheetButton?.addEventListener('keyup', keyListener(this.openPersonBottomSheet.bind(this)));
    }

    private openPersonBottomSheet() {
        this.personBottomSheet?.show();
    }
}

window.customElements.define('pui-profile-widget', PUIProfileWidget);
