import PUIBase from './pui-base';
import keyCodes from '../constants/keyCodes';

class PUIToggle extends PUIBase {
  constructor() {
    super();
    this.defaultClass = 'pui-toggle';
  }

  connectedCallback() {
    super.connectedCallback();
    this.render();
  }

  attributeChangedCallback(name, oldValue, newValue) {
    if (name === 'toggled' && this._innerInput) {
      this._setToggleState(newValue);
    }
  }

  static get observedAttributes() {
    return ['toggled', 'value'];
  }

  get toggled() {
    return this.getBooleanAttribute('toggled');
  }

  set toggled(value) {
    this.setBooleanAttribute('toggled', value);
  }

  get disabled() {
    return this.getBooleanAttribute('disabled');
  }

  set disabled(value) {
    this.setBooleanAttribute('disabled', value);
  }

  get value() {
    return this.getAttribute('value') || '';
  }

  set value(value) {
    this.setAttribute('value', value);
  }

  on() {
    this.setAttribute('toggled', true);
  }

  off() {
    this.removeAttribute('toggled');
  }

  /**
   * Called from attributeChangedCallback. Takes the latest attribute value
   * and propagates it to the innerInput checkbox state.
   * @param {*} value 
   */
  _setToggleState(value) {
    if (value) {
      this._innerInput.checked = true;
    } else {
      this._innerInput.checked = false;
    }
  }

  /**
   * Called from the togglePressed and toggleChanged handlers which are 
   * listeners on the innerInput element. If the inner input is checked, 
   * the attribute value on the parent component should match. This does
   * not produce a cycle since the inner input is changed with javascript
   * and not by the browser user, therefore handlers are not triggered.
   */
  _syncAttributeState() {
    if (this._innerInput.checked) {
      this.on();
    } else {
      this.off();
    }
  }

  togglePressedHandler(event) {
    if (event.keyCode === keyCodes.ENTER_KEYCODE) {
      this._innerInput.checked = this._innerInput.checked === false;
      this._syncAttributeState();
      this._propagateEvent();
    }
  }

  toggleChangedHandler() {
    this._syncAttributeState();
    this._propagateEvent();
  }

  _propagateEvent() {
    if (this.onToggle) {
      this.onToggle(this._innerInput.checked);
    }
  }

  render() {
    const {
      name,
      value,
      defaultClass,
      toggled,
      disabled,
    } = this;

    this.classList.add('pui-inline-block');
    this._innerToggle = document.createElement('label');
    this._innerToggle.classList.add(defaultClass);
    this._innerToggle.classList.add('switch');
    this._innerInput = document.createElement('input');
    this._innerInput.type = "checkbox";
    if (!!name) {
      this._innerInput.name = name ;
    }
    this._innerInput.value = value;
    this._innerInput.tabIndex = "-1";
    if (toggled) {
      this._innerInput.checked = true;
    }
    if (disabled) {
      this._innerInput.disabled = true;
      this._innerToggle.classList.add('pui-toggle-disabled');
    }
    this._innerToggle.appendChild(this._innerInput);
    const slider = document.createElement('span');
    slider.className = 'slider';
    this._innerToggle.appendChild(slider);
  
    this.appendChild(this._innerToggle);
    this._innerInput = this._innerToggle.firstElementChild;
    this._innerInput.addEventListener('input', this.toggleChangedHandler.bind(this));
    this.addEventListener('keyup', this.togglePressedHandler.bind(this));
    this.setAttribute('tabindex', '0');
  }
}

window.customElements.define('pui-toggle', PUIToggle);

export default PUIToggle;
