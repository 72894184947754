import PUIBase from './pui-base';

import includes from '../functions/includes';
import getBannerStatus from '../attributeValues/bannerStatus';

class PUIBanner extends PUIBase {
  constructor() {
    super();
    this.defaultClass = 'pui-banner';
    this.defaultStatus = 'success';
  }
    
  connectedCallback() {
    super.connectedCallback();
    this.render();
  }
    
  static get observedAttributes() {
    return ['status'];
  }

  /**
  * @classprop {string} status: determines what kind of banner should be displayed (e.g. error, success)
  */
  get status() {
    return this.getAttribute('status') || '';
  }

  set status(value) {
    this.setAttribute('status', value);
  }

  render() {
    let { status } = this;

    if (!includes(getBannerStatus(), this.status)) {
        status = this.defaultStatus;
    }
    status = `${status}-banner`;

    this.setAttribute('tabindex', '0');
    this.classList.add(this.defaultClass);
    this.classList.add(status);

    this.attachShadow({
      mode: 'open',
    });
    this.shadowRoot.innerHTML = `
        <slot>
          Inner Elements
        </slot>
    `;
  }
}

window.customElements.define('pui-banner', PUIBanner);