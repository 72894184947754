import * as shadyCss from '@webcomponents/shadycss';
import PUIBase from './pui-base';
import PUISelectorItem from './pui-selector-item';

const properties = ['onSelect', 'multiselect'];
const template = document.createElement('template');
const innerTemplate = `
  <slot></slot>
`;

template.innerHTML = innerTemplate;

shadyCss.prepareTemplate(template, 'pui-selector');

export default class PUISelector extends PUIBase {
  constructor() {
    super();
    this.selectedItems = [];
    this.count = 0;

    shadyCss.styleElement(this);
    if (!this.shadowRoot) {
      this.attachShadow({ mode: 'open' });
      this.shadowRoot.appendChild(
        document.importNode(template.content, true),
      );
    }

    this.upgradeProperty = this.upgradeProperty.bind(this);
    this._slot = this.shadowRoot.querySelector('slot');
  }

  // static get observedAttributes() { return []; }

  /**
  *    @classprop {boolean} multiselect Determines whether multiple items can be selected at a time
  *
  */
  get multiselect() {
    return this.getBooleanAttribute('multiselect') || false;
  }

  set multiselect(value) {
    this.setBooleanAttribute('multiselect', value);
  }

  connectedCallback() {
    super.connectedCallback();
    this._setupComponent();
    properties.forEach(this.upgradeProperty);
    this._addSlotActions();
  }

  getSelectedValue() {
    if (this.multiselect) {
      return this.selectedItems.map(item => item.getValue());
    }
    if (this.selectedItems.length) {
      return this.selectedItems[0].getValue();
    }
    return null;
  }

  setSelectedValue(value) {
    Array.from(this.querySelectorAll('pui-selector-item'))
      .forEach((selectorItem) => {
        if (selectorItem.getValue() === value) {
          this.selectedItems.forEach(item => item.deselect());
          selectorItem.select();
          this.selectedItems = [selectorItem];
        }
      });
  }

  _pushOrReplace(selectorItem) {
    if (selectorItem.selected) {
      if (this.multiselect) {
        this.selectedItems.push[selectorItem];
      } else {
        this.selectedItems = [selectorItem];
      }
    }
  }

  /**
   * Updates the options for the pui selector component
   * @param {Item[]} items - The items to be added to the selector component
   * @param {string} item.id - string id to uniquely identify this selector item
   * @param {string} item.content - The html string to be used inside a selector item option
   * @param {any} item.value - The value for this option
   * @param {boolean} item.selected - Determines whether this items should be preselected or not
   * @param {string} item.showRadioButton - Determines whether to display or hide radio button on this item
   * @param {string} item.showCheckbox - Determines whether to display or hide checkbox on this item
   */
  updateItems(items) {
    this._slot.innerHTML = '';
    this.itemMap = {};

    items.forEach((item) => {
      const selectorItem = new PUISelectorItem();
      selectorItem.id = item.id;
      selectorItem.value = item.value;
      selectorItem.selected = item.selected;
      selectorItem.showRadioButton = item.showRadioButton;
      selectorItem.showCheckbox = item.showCheckbox;
      this._slot.appendChild(selectorItem);
      selectorItem.addSlotContent(item.content);
      this.itemMap[selectorItem.id] = selectorItem;
      this._pushOrReplace(selectorItem);
      selectorItem.addEventListener('click', this._handleSelectorItemPress.bind(this));
    });
  }

  _handleSelectorItemPress(event) {
    const selectorItem = event.currentTarget;

    if (this.multiselect) {
      if (selectorItem.selected) {
        // Clicking on a selected item will deselect it
        selectorItem.deselect();
        this.selectedItems = this.selectedItems.filter(item => item.selected);
      } else if (selectorItem.exclusive) {
        // Select item and deselect others
        this.setSelectedValue(selectorItem.value);
      } else {
        // Clear any exclusive items (should only be one)
        const exclusiveItems = this.selectedItems.filter(item => item.exclusive);
        exclusiveItems.forEach(item => item.deselect());
        if (exclusiveItems.length) {
          this.selectedItems = [];
        }

        // Select item if it hasn't been selected
        selectorItem.select();
        this.selectedItems.push(selectorItem);
      }
    } else {
      this.selectedItems.forEach(item => item.deselect());
      selectorItem.select();
      this.selectedItems = [selectorItem];
    }

    // Call onSelect callback if available
    if (this.onSelect) {
      this.onSelect(this.getSelectedValue());
    }
  }


  _setupComponent() {
    this.setAttribute('role', 'listbox');
    this.classList.add('pui-block');

    // Populate selectedItems
    Array.from(this.querySelectorAll('pui-selector-item'))
      .forEach((selectorItem) => {
        if (selectorItem.selected) {
          this.selectedItems.push(selectorItem);
        }
      });
  }

  _addSlotActions() {
    this.itemMap = {};
    Array.from(this.querySelectorAll('pui-selector-item'))
      .forEach((selectorItem) => {
        this.itemMap[selectorItem.id] = selectorItem;
        this._pushOrReplace(selectorItem);
        selectorItem.addEventListener('click', this._handleSelectorItemPress.bind(this));
      });
  }
}

window.customElements.define('pui-selector', PUISelector);
