import * as shadyCss from '@webcomponents/shadycss';
import PUIBase from './pui-base';

/* eslint-disable import/no-unresolved */
import productCardGridCss from '../../css/_product-card-grid.scss?inline';
/* eslint-enable import/no-unresolved */

const template = document.createElement('template');
const innerTemplate = `
  <style>
    ${productCardGridCss}
  </style>
  <slot></slot>
`;

template.innerHTML = innerTemplate;

shadyCss.prepareTemplate(template, 'pui-product-card-grid');

export default class PUIProductCardGrid extends PUIBase {
  constructor() {
    super();

    shadyCss.styleElement(this);
    if (!this.shadowRoot) {
      this.attachShadow({ mode: 'open' });
      this.shadowRoot.appendChild(
        document.importNode(template.content, true),
      );
    }

    this.upgradeProperty = this.upgradeProperty.bind(this);
  }

  connectedCallback() {
    super.connectedCallback();
  }
}

window.customElements.define('pui-product-card-grid', PUIProductCardGrid);
