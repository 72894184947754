import PUIBase from '../pui-base';
import PUIInsuranceError from "./pui-insurance-error";
import PUIInsuranceInfo from './pui-insurance-info';
import Request from '../../networking/request';
import keyCodes from '../../constants/keyCodes';
import generateVerificationFixUpFiledsMessage from '../../functions/parseInsuranceFixupFields';

/**
 * This component is responsible for manually adding new insurances or
 * updating an existing insurance
 *
 * @callback onSaveComplete
 * @param {string} insuranceId - The insuranceId of the saved insurance
 * @param {Insurance} insurance = The previous insurance
 *
 * Configuration:
 *
 * @param {PUIInsuranceSaverConfig} this.config - The configuration for PUIInsuranceSaver
 * @param {string} this.config.strings.weblabPurchaseTwo - String indicating current weblab status for purchase 2.0 
 * @param {string} this.config.saveInsuranceUrl - The url for creating/updating an insurance
 * @param {Insurance} this.config.insurance - Will only present if updating an existing insurance
 * @param {string} this.config.strings.weblabPharmacyInsuranceInputSearch - String indicating current weblab status for insurance search input enhancement
 * @param {string} this.config.strings.cancelButton - The text of the cancel button
 * @param {string} this.config.strings.addInsuranceTitle - The title for the insurance saver
 *                                                                        if creating a new insurance
 * @param {string} this.config.strings.updateInsuranceTitle - The title for the insurance saver
 *                                                                           if updating an existing insurance
 * @param {string} this.config.strings.helperText - Helper text for the insurance saver
 * @param {string} this.config.strings.moreInfoLink - The text for the more info link
 * @param {string} this.config.strings.memberIdInputLabel - Label text for the memberId input
 * @param {string} this.config.strings.memberIdInputPlaceholder - Placeholder text for memberId input
 * @param {string} this.config.strings.binInputLabel - Label text for the bin input
 * @param {string} this.config.strings.binInputPlaceholder - Placeholder text for bin input
 * @param {string} this.config.strings.groupInputLabel - Label text for the group input
 * @param {string} this.config.strings.groupInputPlaceholder - Placeholder text for group input
 * @param {string} this.config.strings.pcnInputLabel - Label text for the pcn input
 * @param {string} this.config.strings.pcnInputPlaceholder - Placeholder text for pcn input
 * @param {string} this.config.strings.addInsuranceButton - The save button when creating insurance
 * @param {string} this.config.strings.updateInsuranceButton - The save button when updating insurance
 * @param {string} this.config.strings.suffixTooShort - Error text for RxBin length
 * @param {string} this.config.strings.rxbinTooShort - Error text for RxBin length
 *
 * @param {string} this.config.strings.insuranceInfo.title - Title for the insurance info page
 * @param {string} this.config.strings.insuranceInfo.cancelButton - The text for the cancel button
 * @param {string} this.config.strings.insuranceInfo.helpfulTipsHeader - The subheader for the helpful tips section
 * @param {string} this.config.strings.insuranceInfo.helpfulTipOne - The first tip
 * @param {string} this.config.strings.insuranceInfo.helpfulTipTwo - The second tip
 * @param {string} this.config.strings.insuranceInfo.helpfulTipThree - The third tip
 * @param {string} this.config.strings.insuranceInfo.helpfulTipFour - The fourth tip
 * @param {string} this.config.strings.insuranceInfo.helpfulTipFive - The fifth tip
 * @param {string} this.config.strings.insuranceInfo.helpfulTipSix - The sixth tip
 * @param {string} this.config.strings.insuranceInfo.helpfulTipSeven - The seventh tip
 * @param {string} this.config.strings.insuranceInfo.helpfulTipEight - the eighth tip
 * @param {string} this.config.strings.insuranceInfo.helpfulTipNine - the ninth tip
 * @param {string} this.config.strings.insuranceInfo.helpfulTipTen - the tenth tip
 *
 * @param {string} this.config.strings.updateInsuranceTitleEnhancement - The text for update insurance page title
 * @param {string} this.config.strings.updateInsuranceDescriptionEnhancement - The text for update insurance description
 * @param {string} this.config.strings.updateInsuranceMemberIdEnhancement - The text for update insurance page memeber id
 * @param {string} this.config.strings.updateInsuranceRxBinEnhancement - The text for update insurance page rxbin
 * @param {string} this.config.strings.updateInsuranceRxPcnEnhancement - The text for update insurance page rxpcn
 * @param {string} this.config.strings.updateInsuranceRxGrpEnhancement - - The text for update insurance page rx group
 * @param {string} this.config.strings.verificationDetailErrorTitle - The title for verification error banner in insurance card
 * @param {string} this.config.strings.verificationDetailErrorMessageOne - The first part of desc for verification error banner in insurance card
 * @param {string} this.config.strings.verificationDetailErrorMessageTwo - The second part of desc for verification error banner in insurance card
 *
 * @param {onSaveComplete} this.config.callbacks.onSaveComplete - On Save Complete Callback
 * @param {close} this.config.callbacks.onSuccess - Closes Insurance Select on complete Callback
 *
 */

export default class PUIInsuranceSaver extends PUIBase {
  connectedCallback() {
    super.connectedCallback();
    this.upgradeProperty('config');
    this._render();
  }

  close() {
    this.parentNode.removeChild(this);
  }

  _render() {
    const {
      insurance,
      strings,
    } = this.config;

    this.classList.add('pui-block');
    const isInsuranceCreate = this._isInsuranceCreate();
    let titleHeadingsHTML = '';
    const searchWeblabConditionIsT = this.config.strings.weblabPharmacyInsuranceInputSearch && this.config.strings.weblabPharmacyInsuranceInputSearch !== 'C';
    const isWeblabPurchaseTwo_T1 = this.config.strings.weblabPurchaseTwo && this.config.strings.weblabPurchaseTwo !== 'C';
    const isAddPersonCodeWeblabEnable = this.config.strings.weblabPharmacyAddPersonCodeInsurance === 'T1';
    let verificationFixUpFieldsMessage = '';
    let displayVerificationErrorMessage = (insurance.rejectTypes && insurance.rejectTypes[0] === 'CUSTOMER_INSURANCE') && (insurance.verificationFixUpFields && insurance.verificationFixUpFields.length !== 0);
    verificationFixUpFieldsMessage = generateVerificationFixUpFiledsMessage(insurance.verificationFixUpFields);
    const insuranceErrorBanner = (`
      <pui-banner status="error" spacingBottom="medium" ">
        <pui-text
            input="${strings.verificationDetailErrorTitle}"
            textColor="error"
            fontWeight="bold"
        ></pui-text>
        <pui-text inline textSize="small" input="${strings.verificationDetailErrorMessageOne}"></pui-text>
        <pui-text inline textSize="small" fontWeight="bold" input="${verificationFixUpFieldsMessage}"></pui-text>
        <pui-text inline textSize="small" input="${strings.verificationDetailErrorMessageTwo}"></pui-text>
      </pui-banner>
      `);
    titleHeadingsHTML = `
      <pui-section>
        <pui-insurance-error class="page-error pui-hidden" spacingBottom="small"></pui-insurance-error>
          <pui-heading id="apex-add-update-title-label" textSize="extra-large"
                      input="${isInsuranceCreate ? strings.addInsuranceTitle : strings.updateInsuranceTitleEnhancement}">
          </pui-heading>
          <pui-section class = "${strings.helperText ? '' : 'pui-hidden'}">
            <pui-text textSize="medium" input="${strings.updateInsuranceDescriptionEnhancement}" spacingTop="medium"></pui-text>
          </pui-section>
      </pui-section>
    `;
    let rxBinPlacehodler = !isInsuranceCreate && this._displayMaskAsPlaceholder(insurance.binNumber) ? insurance.binNumber : '';
    let rxSuffixPlacehodler = !isInsuranceCreate && this._displayMaskAsPlaceholder(insurance.personCode) ? insurance.personCode : '';
    let rxGrpPlaceholder = !isInsuranceCreate && this._displayMaskAsPlaceholder(insurance.groupNumber) ? insurance.groupNumber : '';
    let rxPcnPlaceholder = !isInsuranceCreate && this._displayMaskAsPlaceholder(insurance.pcn) ? insurance.pcn : '';

    let suffixInputHTML = '';
    if (isAddPersonCodeWeblabEnable) {
      suffixInputHTML = `
        <pui-text
          input="${strings.updateInsuranceSuffixEnhancement}"
          spacingTop="med-small"
          spacingBottom="mini"
        ></pui-text>
        <pui-input id="apex-insurance-saver-suffix-input"
          name="binNumber"
          placeholder="${rxSuffixPlacehodler}"
          maxLength="2"
          textInputType="tel"
          allowedCharacters="numeric"
        ></pui-input>`;
    }

    let saveInsuranceInputHTML = '';
    if (searchWeblabConditionIsT) {
      saveInsuranceInputHTML = `
      <pui-section>
            <pui-text
                input="${strings.updateInsuranceMemberIdEnhancement}"
                spacingTop="med-small"
                spacingBottom="mini"
            ></pui-text>
            <pui-input id="apex-insurance-saver-member-id-input"
                name="identificationNumber"
                placeholder="${isInsuranceCreate ? ''
    : insurance.cardHolderId}"
                maxLength="20"
                allowedCharacters="alphanumeric"
            >
            </pui-input>

            ${suffixInputHTML}

            <pui-text
                input="${strings.updateInsuranceRxBinEnhancement}"
                spacingTop="med-small"
                spacingBottom="mini"
            ></pui-text>
            <pui-search-input 
                        dataSearchUrl="/dashboard/shared/getInsuranceBin"
                        id="apex-insurance-saver-bin-number-input"
                        placeholder="${rxBinPlacehodler}"
                        name="binNumber"
                        style="margin-bottom: 8px"
                        maxLength="6"
                        allowedCharacters="numeric"
                        iconClass='none'
                        onInputChangeValue={_setRxBinStateValue}
                        inline="true"
                        onResultClick={_onRxBinSearchResultClick}
                        keepInputOnClick="true"
                        >
            </pui-search-input>

            <pui-text
                input="${strings.updateInsuranceRxPcnEnhancement}"
                spacingTop="med-small"
                spacingBottom="mini"
            ></pui-text>
            <pui-search-input 
                        dataSearchUrl="/dashboard/shared/getInsurancePCN"
                        id="apex-insurance-saver-pcn-number-input"
                        placeholder="${rxPcnPlaceholder}"
                        name="pcnNumber"
                        style="margin-bottom: 8px"
                        maxLength="10"
                        allowedCharacters="alphanumeric"
                        iconClass='none'
                        onInputChangeValue={_setPcnStateValue}
                        inline="true"
                        onResultClick={_onPcnSearchResultClick}
                        minimumRequiredCharacters="2"
                        keepInputOnClick="true"
                        >
            </pui-search-input>

            <pui-text
                input="${strings.updateInsuranceRxGrpEnhancement}"
                spacingTop="med-small"
                spacingBottom="mini"
            ></pui-text>
            <pui-search-input 
                        dataSearchUrl="/dashboard/shared/getInsuranceRxGroup"
                        id="apex-insurance-saver-group-number-input"
                        placeholder="${rxGrpPlaceholder}"
                        name="groupNumber"
                        style="margin-bottom: 8px"
                        maxLength="15"
                        allowedCharacters="alphanumericGroupId"
                        iconClass='none'
                        onInputChangeValue={_setRxGroupStateValue}
                        inline="true"
                        onResultClick={_onRxGroupSearchResultClick}
                        minimumRequiredCharacters="1"
                        keepInputOnClick="true"
                        >
            </pui-search-input>
            </pui-input>
          </pui-section> 
      `;
    } else {
      saveInsuranceInputHTML = `
        <pui-section>
              <pui-text
                  input="${strings.updateInsuranceMemberIdEnhancement}"
                  spacingTop="med-small"
                  spacingBottom="mini"
              ></pui-text>
              <pui-input id="apex-insurance-saver-member-id-input"
                  name="identificationNumber"
                  placeholder="${isInsuranceCreate ? '' : insurance.cardHolderId}"
                  maxLength="20"
                  allowedCharacters="alphanumeric"
              >
              </pui-input>

              ${suffixInputHTML}
  
              <pui-text
                  input="${strings.updateInsuranceRxBinEnhancement}"
                  spacingTop="med-small"
                  spacingBottom="mini"
              ></pui-text>
              <pui-input id="apex-insurance-saver-bin-number-input"
                  name="binNumber"
                  placeholder="${rxBinPlacehodler}"
                  maxLength="6"
                  textInputType="tel"
                  allowedCharacters="numeric"
              >
              </pui-input>
  
              <pui-text
                  input="${strings.updateInsuranceRxPcnEnhancement}"
                  spacingTop="med-small"
                  spacingBottom="mini"
              ></pui-text>
              <pui-input id="apex-insurance-saver-pcn-number-input" name="pcnNumber"
                  placeholder="${rxPcnPlaceholder}"
                  maxLength="10"
                  allowedCharacters="alphanumeric"
              >
              </pui-input>
  
              <pui-text
                  input="${strings.updateInsuranceRxGrpEnhancement}"
                  spacingTop="med-small"
                  spacingBottom="mini"
              ></pui-text>
              <pui-input id="apex-insurance-saver-group-number-input"
                  name="groupNumber"
                  placeholder="${rxGrpPlaceholder}"
                  maxLength="15"
                  allowedCharacters="alphanumericGroupId"
              >
              </pui-input>
            </pui-section> 
        `;
    }
    let moreInfoHTML = `
          <li>
              <pui-text 
                  inline
                  style="cursor: pointer;"
                  textColor="link"
                  input="${strings.moreInfoLink}"
                  id="apex-insurance-more-info-link"
                  spacingBottom="small"
                  class="${isInsuranceCreate || strings.moreInfoLink ? '' : 'pui-hidden'}"
              >
              </pui-text>
          </li>
          <pui-divider spacingTop="medium" spacingBottom="mini"></pui-divider>
      `;
    this.innerHTML = `
      <div class="pui-full-page">
      <pui-section style="margin:auto">
          <pui-section id="apex-insurance-save-cancel-button" flowDirection="horizontal" mainAxisArrangement="end"
                      spacingBottom="medium">
              <pui-text tabindex="0" style="cursor: pointer;" textColor="link" input="${strings.cancelButton}"></pui-text>
          </pui-section>
          <!--ERROR BANNER -->
          ${isWeblabPurchaseTwo_T1 && displayVerificationErrorMessage ? (displayVerificationErrorMessage && insuranceErrorBanner) : ''}

          <!--HEADING AND SUBHEADING -->
          ${titleHeadingsHTML}
          

          <!-- TOOLTIP -->
          ${moreInfoHTML}

          <!-- SAVE INSURANCE INPUT -->
          ${saveInsuranceInputHTML}
          <pui-section spacingTop="small" secondaryAxisArrangement="center">
              <pui-button id="insurance-submit-button"
                          label="${isInsuranceCreate ? strings.addInsuranceButton : strings.updateInsuranceButton}"
                          spacingBottom="small" disabled></pui-button>
          </pui-section>
        </pui-section>
      </div>
    `;
    this._insuranceSaveCancelButton = this.querySelector('#apex-insurance-save-cancel-button');
    this._insuranceSaveCancelButton.addEventListener('click', this._onCancelPress.bind(this));
    this._insuranceSaveCancelButton.addEventListener('keyup', this._onCancelPressEnter.bind(this));

    this._moreInfoLink = this.querySelector('#apex-insurance-more-info-link');
    this._moreInfoLink.addEventListener('click', this._onMoreInfoPress.bind(this));
    this._moreInfoLink.addEventListener('keyup', this._onMoreInfoPressEnter.bind(this));

    this._memberIdInput = this.querySelector('#apex-insurance-saver-member-id-input');
    this._memberIdInput.addEventListener('input', this._onInputUpdate.bind(this));
    this._memberIdInput.onInputClear = this._onInputClear.bind(this);

    if (isAddPersonCodeWeblabEnable) {
      this._suffixInput = this.querySelector('#apex-insurance-saver-suffix-input');
      this._suffixInput.addEventListener('input', this._onSuffixUpdate.bind(this));
      if (!searchWeblabConditionIsT) {
        this._suffixInput.addEventListener('focus', this._onInputFieldFocus.bind(this, 'SUFFIX_INPUT'), true);
      }
      this._suffixInput.onInputClear = this._onInputClear.bind(this);
    }

    if (searchWeblabConditionIsT) {
      this._rxBinSearchInput = this.querySelector('#apex-insurance-saver-bin-number-input');
      this._rxBinSearchInput.queryUrlBuilder = this._queryUrlBuilder.bind(this);
      this._rxBinSearchInput.querySuccessCb = this._querySuccessCbRxBin.bind(this);
      this._rxBinSearchInput.onSearchResultClick = this._onRxBinSearchResultClick.bind(this);
      this._rxBinSearchInput.addEventListener('keyup', this._onPressEnter.bind(this));
    }

    this._binInput = this.querySelector('#apex-insurance-saver-bin-number-input');
    this._binInput.addEventListener('input', this._onBinUpdate.bind(this));
    if (!searchWeblabConditionIsT) {
      this._binInput.addEventListener('focus', this._onInputFieldFocus.bind(this, 'BIN_INPUT'), true);
    }
    this._binInput.onInputClear = this._onInputClear.bind(this);

    if (searchWeblabConditionIsT) {
      this._rxGroupSearchInput = this.querySelector('#apex-insurance-saver-group-number-input');
      this._rxGroupSearchInput.queryUrlBuilder = this._queryUrlBuilder.bind(this);
      this._rxGroupSearchInput.querySuccessCb = this._querySuccessCbRxGroup.bind(this);
      this._rxGroupSearchInput.onSearchResultClick = this._onRxGroupSearchResultClick.bind(this);
    }

    this._groupInput = this.querySelector('#apex-insurance-saver-group-number-input');
    this._groupInput.addEventListener('input', this._onInputUpdate.bind(this));
    if (!searchWeblabConditionIsT) {
      this._groupInput.addEventListener('focus', this._onInputFieldFocus.bind(this, 'GROUP_INPUT'), true);
    }
    this._groupInput.onInputClear = this._onInputClear.bind(this);

    if (searchWeblabConditionIsT) {
      this._pcnSearchInput = this.querySelector('#apex-insurance-saver-pcn-number-input');
      this._pcnSearchInput.queryUrlBuilder = this._queryUrlBuilder.bind(this);
      this._pcnSearchInput.querySuccessCb = this._querySuccessCbPcn.bind(this);
      this._pcnSearchInput.onSearchResultClick = this._onPcnSearchResultClick.bind(this);
    }

    this._pcnInput = this.querySelector('#apex-insurance-saver-pcn-number-input');
    this._pcnInput.addEventListener('input', this._onInputUpdate.bind(this));
    if (!searchWeblabConditionIsT) {
      this._pcnInput.addEventListener('focus', this._onInputFieldFocus.bind(this, 'PCN_INPUT'), true);
    }
    this._pcnInput.onInputClear = this._onInputClear.bind(this);

    this._saveButton = this.querySelector('#insurance-submit-button');
    this._saveButton.addEventListener('click', this._onInsuranceSavePress.bind(this));

    if (!this._isInsuranceUpdate()) {
      this._setInputs();
    }
  }

  _displayMaskAsPlaceholder(value) {
    return this._isInsuranceUpdate() && !!value;
  }

  _setInputs() {
    const { insurance } = this.config;
    if (insurance) {
      this._binInput.setValue(insurance.binNumber);
      this._groupInput.setValue(insurance.groupNumber);
      this._pcnInput.setValue(insurance.pcn);
    }
  }

  _onInputClear() {
    this._validateAllInputs();
  }

  _onCancelPress() {
    this.close();
  }

  _onCancelPressEnter(event) {
    if (event.keyCode === keyCodes.ENTER_KEYCODE) { // Pressed Enter
      this._onCancelPress();
    }
  }

  _onPressEnter(event) {
    if (event.keyCode === keyCodes.ENTER_KEYCODE) { // Pressed Enter
      this._onBinUpdate();
    }
  }

  _onMoreInfoPress() {
    const insuranceInfoConfig = {
      strings: this.config.strings.insuranceInfo,
    };
    const insuranceInfo = new PUIInsuranceInfo();
    insuranceInfo.configure(insuranceInfoConfig);
    this.appendChild(insuranceInfo);
    insuranceInfo.setAttribute('tabindex', '0');
    insuranceInfo.focus();
  }

  _setRxBinStateValue(value) {
    this._rxBinSearchInput.setValue(value);
  }

  _setPcnStateValue(value) {
    this._pcnSearchInput.setValue(value);
  }

  _setRxGroupStateValue(value) {
    this._rxGroupSearchInput.setValue(value);
  }

  _queryUrlBuilder(dataSearchUrl, searchInput) {
    if (dataSearchUrl.toString() === '/dashboard/shared/getInsuranceBin') {
      return `${dataSearchUrl}?binPrefix=${searchInput}`;
    } else if (dataSearchUrl.toString() === '/dashboard/shared/getInsurancePCN') {
      return `${dataSearchUrl}?bin=${this._rxBinSearchInput.getValue()}&pcnPrefix=${searchInput}`;
    } else if (dataSearchUrl.toString() === '/dashboard/shared/getInsuranceRxGroup') {
      return `${dataSearchUrl}?bin=${this._rxBinSearchInput.getValue()}&pcn=${this._pcnSearchInput.getValue()}&rxgroupPrefix=${searchInput}`;
    }
    return `${dataSearchUrl}?binPrefix=${searchInput}`;
  }

  _querySuccessCbRxBin(response) {
    const searchResults = response;
    const rxBinResultBuilder = searchResults.map((result) => {
      const html = `
                <div>
                    <pui-text input="${result}"></pui-text>
                </div>
            `;
      return {
        values: result,
        html,
      };
    });
    if (rxBinResultBuilder.length) {
      this._binInput.hideError();
    }
    this._rxBinSearchInput.updateSearchResults(rxBinResultBuilder);
  }

  _querySuccessCbPcn(response) {
    const searchResults = response;
    const pcnResultBuilder = searchResults.map((result) => {
      const html = `
                <div>
                    <pui-text input="${result}"></pui-text>
                </div>
            `;
      return {
        values: result,
        html,
      };
    });
    this._pcnSearchInput.updateSearchResults(pcnResultBuilder);
  }

  _querySuccessCbRxGroup(response) {
    const searchResults = response;
    const rxGroupResultBuilder = searchResults.map((result) => {
      const html = `
                <div>
                    <pui-text input="${result}"></pui-text>
                </div>
            `;
      return {
        values: result,
        html,
      };
    });
    this._rxGroupSearchInput.updateSearchResults(rxGroupResultBuilder);
  }

  // onClick functionality works as we pass in searchResult value that user selected
  // and manually call callBack which updates button enable. This is tied to
  // multiple bindings on inputs due to 2 types while we have different weblabs
  // when we clean up old style, we should be able to remove onInputChange
  // manual call and should work
  _onRxBinSearchResultClick(searchResult) {
    this._setRxBinStateValue(searchResult.values);
    this._onInputUpdate();
  }

  _onPcnSearchResultClick(searchResult) {
    this._setPcnStateValue(searchResult.values);
    this._onInputUpdate();
  }

  _onRxGroupSearchResultClick(searchResult) {
    this._setRxGroupStateValue(searchResult.values);
    this._onInputUpdate();
  }

  _onMoreInfoPressEnter(event) {
    if (event.keyCode === keyCodes.ENTER_KEYCODE) { // Pressed Enter
      this._onMoreInfoPress();
    }
  }

  _isInsuranceCreate() {
    return this.config.insurance == null;
  }

  _isInsuranceUpdate() {
    return !this._isInsuranceCreate();
  }

  _isMemberIdInputValid() {
    return this._isInsuranceUpdate() || this._isInsuranceCreate() && this._memberIdInput.getValue();
  }

  _isBinInputValid() {
    const { insurance } = this.config;
    // 6 digits only or when weblab for maskInsurance is enabled and matches '• • • • • •' (\u2022 = '•' and \u00A0 = ' ')
    return (/^\d{6}$/.test(this._binInput.getValue())
      || (!this._binInput.getValue()
        && insurance
        && /^([\u2022][\u00A0]){5}[\u2022]$/.test(insurance.binNumber)));
  }

  _onBinUpdate() {
    if (this._isBinInputValid() || this._binInput.getValue().length === 0) {
      this._binInput.hideError();
    } else {
      this._binInput.showError(this.config.strings.rxbinTooShort);
    }
    this._validateAllInputs();
  }

  _isSuffixInputValid() {
    return (/^\d{2}$/.test(this._suffixInput.getValue()));
  }

  _onSuffixUpdate() {
    if (this._isSuffixInputValid() || this._suffixInput.getValue().length === 0) {
      this._suffixInput.hideError();
    } else {
      this._suffixInput.showError(this.config.strings.suffixTooShort);
    }
    this._validateAllInputs();
  }

  _onInputFieldFocus(inputFieldName) {
    if (this._isInsuranceUpdate()) {
      switch (inputFieldName) {
        case 'SUFFIX_INPUT':
          if (!this._suffixInput.getValue()) {
            this._suffixInput.setAttribute('placeholder', '');
            this._suffixInput.setAttribute('value', '');
            this._saveButton.disable();
          }
          break;
        case 'BIN_INPUT':
          if (!this._binInput.getValue()) {
            this._binInput.setAttribute('placeholder', '');
            this._binInput.setAttribute('value', '');
            this._saveButton.disable();
          }
          break;
        case 'GROUP_INPUT':
          if (!this._groupInput.getValue()) {
            this._groupInput.setAttribute('placeholder', '');
            this._groupInput.setAttribute('value', '');
            this._validateAllInputs();
          }
          break;
        case 'PCN_INPUT':
          if (!this._pcnInput.getValue()) {
            this._pcnInput.setAttribute('placeholder', '');
            this._pcnInput.setAttribute('value', '');
            this._validateAllInputs();
          }
          break;
        default:
          break;
      }
    }
  }

  _onInputUpdate() {
    this._validateAllInputs();
  }

  _validateAllInputs() {
    const { insurance } = this.config;
    const isAddPersonCodeWeblabEnable = this.config.strings.weblabPharmacyAddPersonCodeInsurance === 'T1';
    const groupNumber = insurance && insurance.groupNumber ? insurance.groupNumber : '';
    const pcn = insurance && insurance.pcn ? insurance.pcn : '';
    // Check if inputs have changed
    if (insurance
        && (!this._memberIdInput.getValue() || insurance.cardHolderId === this._memberIdInput.getValue())
        && insurance.binNumber === this._binInput.getValue()
        && groupNumber === this._groupInput.getValue()
        && pcn === this._pcnInput.getValue()) {
      if (!isAddPersonCodeWeblabEnable) {
        this._saveButton.disable();
        return;
      }

      // Check suffix input has changed if the weblab is T1
      if (insurance.personCode === this._suffixInput.getValue()) {
        this._saveButton.disable();
        return;
      }
    }

    // If mask insurance weblab is enabled with T2 treatment, and binNumber is not entered,
    // and placeholder is not mask, then disable the continue button
    if (this._binInput.placeholder === this.config.strings.binInputPlaceholder
      && !this._binInput.getValue()) {
      this._saveButton.disable();
      return;
    }

    // Check required input fields
    if (!this._isMemberIdInputValid() || !this._isBinInputValid()) {
      this._saveButton.disable();
      return;
    }

    if (this._binInput.getValue() === '' && this._binInput.placeholder === '') {
      this._saveButton.disable();
      return;
    }
    this._saveButton.enable();
  }

  _onInsuranceSavePress() {
    const isAddPersonCodeWeblabEnable = this.config.strings.weblabPharmacyAddPersonCodeInsurance === 'T1';
    const insurancePage = document.querySelector('pui-insurance-select')
      ? document.querySelector('pui-insurance-select')
      : document.querySelector('pui-insurance-page');
    insurancePage.hideError();
    if (this._saveButton.isEnabled()) {
      this._saveButton.disable();
      this._saveButton.displaySpinner();

      let identificationNumber;
      if (this._isInsuranceUpdate() && !this._memberIdInput.getValue()) {
        identificationNumber = this.config.insurance.cardHolderId;
      } else {
        identificationNumber = this._memberIdInput.getValue();
      }
      // If groupNumber is not entered, and placeholder is empty, then send null value to not update groupNumber
      const groupNumberEnhance = (this._isInsuranceUpdate()
        && !this._groupInput.getValue()
        && this._groupInput.placeholder !== '') ? null : this._groupInput.getValue();
      // If pcn value is not entered, and placeholder is empty, then send null value to not update pcn
      const pcnNumberEnhance = (this._isInsuranceUpdate()
        && !this._pcnInput.getValue()
        && this._pcnInput.placeholder !== '') ? null : this._pcnInput.getValue();
      const requestBody = {
        identificationNumber,
        ...(isAddPersonCodeWeblabEnable ? { personCode: this._suffixInput.getValue() } : {}),
        binNumber: this._binInput.getValue(),
        groupNumber: groupNumberEnhance,
        pcnNumber: pcnNumberEnhance,
      };
      if (this.config.insurance) {
        requestBody.insuranceId = this.config.insurance.insuranceId;
      }
      Request.post(this.config.saveInsuranceUrl, requestBody).then((response) => {
        const { insuranceId } = response;
        const { onSaveComplete, onSuccess } = this.config.callbacks;
        if (onSaveComplete) {
          onSaveComplete(insuranceId, this.config.insurance);
        }
        if (onSuccess) {
          onSuccess();
        }
        this.close();
      }).catch((response) => {
        // TODO: Handle duplicate scenario once mocks are made: [INSERT TICKET]
        if (response.error && response.error.code !== 'DUPLICATE' && response.error.code !== 'UNSUPPORTED') {
          insurancePage.showGeneralAddInsuranceError_Treatment();
        }
        if (response.error && response.error.code === 'UNSUPPORTED') {
          insurancePage.showAddUnsupportedInsuranceError(response.error);
        }
        if (response.error && response.error.code === 'DUPLICATE') {
          insurancePage.showDuplicateInsuranceError(response.error);
        }
        this.close();
      });
    }
  }
}

window.customElements.define('pui-insurance-saver', PUIInsuranceSaver);
