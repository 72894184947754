import PUIPage from '../pui-page';
import Request from '../../networking/request';
import keyCodes from '../../constants/keyCodes';

/**
 * A base page for health profile pages.
 *
 * Configuration:
 *
 * @param {PUIHealthEntityPageConfig} this.config - This configuration for PUIHealthEntityPageConfig
 * @param {PUISearchInput} this.config.searchInput - A PUISearchInput that can search health entities
 * @param {string} this.config.prefixId - A prefix that will be used when setting html ids
 * @param {string} this.config.pageType - Either 'signup' or 'dashboard'
 * @param {string} this.config.backUrl - The url used for going to the previous page (dashboard only)
 * @param {string} this.config.actionUrl - The url for saving health entities
 * @param {string} this.config.nextUrl - The url for the page to transition to after saving health entities
 * @param {string} this.config.searchUrl - The url used for searching health entities
 * @param {string} this.config.getUrl - The url used to get health entities
 * @param {string} this.config.headerIconClass - The icon class that is displayed in the header
 * @param {array} this.config.entities - The entities to add to the chiclet container
 * @param {array} this.config.commonEntities - The common entities to add to the chiclet container
 * @param {boolean} this.config.isReviewed - Flag if customer has already reviewed this
 * @param {boolean} this.config.hideCommonHealthEntities - Flag if need to hide common health entities
 * @param {boolean} this.config.disablePageContainer - Flag that will remove `pageContainer` created in underlying
 *                                                     pui-sections which consequently does not include
 *                                                     pui-section-padding scss class.
 * @param {string} this.config.strings.headerTitle - The page header title. Must be present to render the page header.
 * @param {string} this.config.strings.headerDescription - The page header description
 * @param {string} this.config.strings.subheaderText - The page subheader text. Must be present to render the page subheader.
 * @param {string} this.config.strings.continueButton The string for the saving the selected health entities
 * @param {string} this.config.strings.commonHealthEntitiesTitle - The title text for common health entities
 * @param {string} this.config.strings.noHealthEntitiesLabel - The label text for no health entities
 * @param {string} this.config.strings.noSelectionErrorText - The text used for when there's no health entities selected
 * @param {string} this.config.strings.serverErrorText - The text used for when there's a server error
 * @param {string} this.config.strings.itemCloseLabel - The text that indicates pui-chiclet item's close pui-icon name for accessibility usage
 *
 * @callback onSave
 * @param {onSave} this.config.callbacks.onSave - A callback that is invoked when saving health entities
 *
 * @callback onAddChiclet
 * @param {onAddChiclet} this.config.callbacks.onAddChiclet - A callback that is invoked when a chiclet is added
 *
 * @callback onRemoveChiclet
 * @param {onRemoveChiclet} this.config.callbacks.onRemoveChiclet - A callback that is invoked when a chiclet is removed
 *
 * @callback selectedOptionConverter
 * @param {selectedOptionConverter} this.config.selectedOptionConverter - A function to convert the search input's
 *                                                                        selected options to an expected health entity
 * @callback fetchResponseConverter
 * @param {fetchResponseConverter} this.config.fetchResponseConverter - A function to convert the fetch response
 *                                                                      to selected and suggested options for the chiclet container
 *
 * @callback saveConverter
 * @param {saveConverter} this.config.saveConverter - A function to convert the entities to a response for saving
 */
export default class PUIHealthEntityPage extends PUIPage {
  getEntitiesToAdd() {
    const { selectedOptions } = this._chicletContainer;
    const { selectedOptionConverter } = this.config;
    const entitiesToAdd = [];
    for (let i = 0; i < selectedOptions.length; i++) {
      const newEntity = selectedOptionConverter(selectedOptions[i]);
      entitiesToAdd.push(newEntity);
    }

    return entitiesToAdd;
  }

  addEntity(label, value) {
    this._chicletContainer.addSelectedOption(label, value);
    this._check();
  }

  addEntities(entities) {
    entities.forEach((entity) => {
      this.addEntity(entity.name, entity.id);
    });
  }

  addCommonEntity(label, value) {
    this._chicletContainer.addSuggestedOption(label, value);
  }

  addCommonEntities(commonEntities) {
    commonEntities.forEach((commonEntity) => {
      this.addCommonEntity(commonEntity.name, commonEntity.id);
    });
  }

  _render() {
    this.classList.add('pui-block');

    const { pageType, strings } = this.config;
    this.config.pageStyle = (pageType === 'signup') ? 'card-form' : 'standard-form';

    const header = strings.headerTitle ? `
      <pui-section id="${this.config.prefix}-header" spacingTop="medium" spacingBottom="small">
        <pui-section flowDirection="horizontal" secondaryAxisArrangement="center">
          <pui-section-column>
              <pui-icon imgClass="${this.config.headerIconClass}" spacingRight="small"></pui-icon>
          </pui-section-column>
          <pui-section-column>
              <pui-heading input="${strings.headerTitle}" textSize="large"></pui-heading>
          </pui-section-column>
        </pui-section>
        <pui-text input="${strings.headerDescription}" textSize="medium" spacingTop="small"></pui-text>
      </pui-section>
    ` : '';

    const subheader = strings.subheaderText ? `
      <pui-text input="${strings.subheaderText}" textSize="large" fontWeight="bold" hideIfEmpty></pui-text>
    ` : '';

    const content = {
      main: `
        ${header}
        ${subheader}
        <pui-section spacingTop="medium">
          <pui-banner id="${this.config.prefixId}-error-banner" status="error" class="pui-hidden" spacingBottom="medium">
              <pui-text input="default" textColor="red" inline></pui-text>
          </pui-banner>

          <div id="${this.config.prefixId}-search-container">
          </div>

          <pui-chiclet-container id="${this.config.prefixId}-chiclet-container" 
             ${this.config.hideCommonHealthEntities ? "hideSuggested" : ""} 
             itemCloseLabel="${strings.itemCloseLabel ? strings.itemCloseLabel : ''}" 
             suggestedSectionTitle="${strings.commonHealthEntitiesTitle ? strings.commonHealthEntitiesTitle : ''}"
             encode="">
          </pui-chiclet-container>

          <pui-checkbox id="no-${this.config.prefixId}-check-box" label="${strings.noHealthEntitiesLabel}" style="border-top:1px solid #DFE0E4" spacingTop="medium">
          </pui-checkbox>
        </pui-section>
      `,
      footerDesktop: `
        <pui-button id="${this.config.prefixId}-save-button-desktop" label="${strings.continueButton}">
        </pui-button>
      `,
      footerMobile: `
        <pui-button id="${this.config.prefixId}-save-button-mobile" label="${strings.continueButton}">
        </pui-button>
      `,
    };
    super._render(content);

    this._errorBanner = this.querySelector(`#${this.config.prefixId}-error-banner`);

    this._chicletContainer = this.querySelector(`#${this.config.prefixId}-chiclet-container`);
    this._chicletContainer.addEventListener('click', this._chicletContainerClicked.bind(this));
    this._chicletContainer.addEventListener('keyup', this._chicletContainerPressed.bind(this));
    this._chicletContainer.onAddChiclet = this._onAddChiclet.bind(this);
    this._chicletContainer.onRemoveChiclet = this._onRemoveChiclet.bind(this);

    this._checkBox = this.querySelector(`#no-${this.config.prefixId}-check-box`);
    this._checkBox.onCheckBoxToggle = this._onCheckBoxToggle.bind(this);
    this._checkBox.addEventListener('click', this._checkBoxClicked.bind(this));
    this._checkBox.addEventListener('keyup', this._checkBoxPressed.bind(this));

    this._saveButtonDesktop = this.querySelector(`#${this.config.prefixId}-save-button-desktop`);
    this._saveButtonDesktop.addEventListener('click', this._onSaveButtonClicked.bind(this));
    this._saveButtonDesktop.addEventListener('keyup', this._onSaveButtonPressed.bind(this));

    this._saveButtonMobile = this.querySelector(`#${this.config.prefixId}-save-button-mobile`);
    this._saveButtonMobile.addEventListener('click', this._onSaveButtonClicked.bind(this));
    this._saveButtonMobile.addEventListener('keyup', this._onSaveButtonPressed.bind(this));

    if (this.config.entities) {
      this.addEntities(this.config.entities);
    }

    if (this.config.commonEntities) {
      this.addCommonEntities(this.config.commonEntities);
    }

    if (this.config.isReviewed) {
      this._check();
    }

    this._fetch();

    this.querySelector(`#${this.config.prefixId}-search-container`).appendChild(this.config.searchInput);
  }

  _isNoEntitiesChecked() {
    return this._checkBox.isChecked();
  }

  _setErrorText(value) {
    this._errorBanner.firstElementChild.setText(value);
  }

  _showError() {
    this._errorBanner.classList.remove('pui-hidden');
  }

  _hideError() {
    this._errorBanner.classList.add('pui-hidden');
  }

  _fetch() {
    const { getUrl, fetchResponseConverter } = this.config;
    if (getUrl) {
      Request.get(getUrl).then((response) => {
        const { entities, commonEntities, isReviewed } = fetchResponseConverter(response);
        // Ignore response if already configured in an attribute
        if (!this.config.entities && entities) {
          this.addEntities(entities);
        }

        if (!this.config.commonEntities && commonEntities) {
          this.addCommonEntities(commonEntities);
        }

        if (!this.config.isReviewed && isReviewed) {
          this._check();
        }
      }).catch((error) => {
        console.log(error);
      });
    }
  }

  _check() {
    if (this._chicletContainer.selectedOptions.length === 0) {
      this._checkBox.check();
    } else {
      this._checkBox.uncheck();
    }
  }

  _onCheckBoxToggle() {
    this._hideError();
  }

  _chicletContainerClicked(event) {
    this._check();
  }

  _chicletContainerPressed(event) {
    if (event.keyCode === keyCodes.ENTER_KEYCODE) {
      this._chicletContainerClicked(event);
    }
  }

  _checkBoxClicked(event) {
    if (this._checkBox.isChecked()) {
      this._chicletContainer.removeAllSelections();
    }
  }

  _checkBoxPressed(event) {
    if (event.keyCode === keyCodes.ENTER_KEYCODE) {
      this._checkBoxClicked(event);
    }
  }

  _onAddChiclet(chiclet) {
    const { callbacks } = this.config;
    if (callbacks && callbacks.onAddChiclet) {
      callbacks.onAddChiclet(chiclet);
    }
  }

  _onRemoveChiclet(chiclet) {
    const { callbacks } = this.config;
    if (callbacks && callbacks.onRemoveChiclet) {
      callbacks.onRemoveChiclet(chiclet);
    }
  }

  _onSave() {
    const entitiesToAdd = this.getEntitiesToAdd();
    if (entitiesToAdd.length === 0 && !this._isNoEntitiesChecked()) {
      this._setErrorText(this.config.strings.noSelectionErrorText);
      this._showError();
      return;
    }

    const { callbacks, saveConverter } = this.config;
    const entitiesToSave = saveConverter(entitiesToAdd);

    if (callbacks && callbacks.onSave) {
      callbacks.onSave(entitiesToSave);
    } else {
      Request.post(this.config.actionUrl, entitiesToSave)
        .then(() => {
          window.location.href = this.config.nextUrl;
        })
        .catch((error) => {
          this._setErrorText(this.config.strings.serverErrorText);
          this._showError();
        });
    }
  }

  _onSaveButtonClicked(event) {
    this._onSave();
  }

  _onSaveButtonPressed(event) {
    if (event.keyCode === keyCodes.ENTER_KEYCODE) {
      this._onSaveButtonClicked(event);
    }
  }
}

window.customElements.define('pui-health-entity-page', PUIHealthEntityPage);
