import PUIBase from '../pui-base';
import productCardTitleCss from '../../../css/_product-card-title.scss?inline';
import baseCss from '../../../css/_base.scss?inline';

const ESAPI = require('node-esapi').encoder();

class PUIProductCardTitle extends PUIBase {
  constructor() {
    super();
    this.defaultClass = 'pui-product-card-title';
  }

  connectedCallback() {
    super.connectedCallback();
    this.render();
  }

  static get observedAttributes() {
    return ['heading', 'subheading', 'headinglevel'];
  }

  get heading() {
    return this.getAttribute('heading') || '';
  }

  set heading(value) {
    this.setAttribute('heading', value);
  }

  get subheading() {
    return this.getAttribute('subHeading') || '';
  }

  set subheading(value) {
    this.setAttribute('subHeading', value);
  }

  get headingLevel() {
    return this.getAttribute('headingLevel') || '3';
  }

  set headingLevel(value) {
    this.setAttribute('headingLevel', value);
  }

  attributeChangedCallback() {
    this.render();
  }

  render() {
    const {
      heading,
      subheading,
      headingLevel,
    } = this;

    this.classList.add('pui-block');
    this.classList.add(this.defaultClass);

    const encodedHeading = heading ? ESAPI.encodeForHTML(heading) : '';
    const encodedSubheading = subheading ? ESAPI.encodeForHTML(subheading) : '';
    const encodedHeadingLevel = headingLevel ? ESAPI.encodeForHTML(headingLevel) : '';

    const spacingTop = this.spacingTop || 'medium';

    const defaultHeading = encodedHeading && `
      <pui-heading
        class="pui-product-card-title-heading"
        input="${encodedHeading}"
        headingLevel="${encodedHeadingLevel}"
        spacingTop="${spacingTop}"
      >
      </pui-heading>
    `;

    const defaultSubheading = encodedSubheading && `
      <div class="pui-product-card-title-subheading pui-inline-block">
        ${encodedSubheading}
      </div>
    `;

    if (!this.shadowRoot) {
      this.attachShadow({ mode: 'open' });
    }

    this.shadowRoot.innerHTML = `
      <style>
        ${productCardTitleCss}
        ${baseCss} 
      </style>
        ${defaultHeading || '<div class="pui-product-card-title-heading"><slot name="heading"></slot></div>'}
        ${defaultSubheading || '<div class="pui-product-card-title-subheading"><slot name="subheading"></slot></div>'}
    `;
  }
}

window.customElements.define('pui-product-card-title', PUIProductCardTitle);
