import PUIHealthEntityPage from '../health-entity/pui-health-entity-page';
import PUIProcedureSearch from './pui-procedure-search';

/**
 * Configuration:
 *
 * @param {PUIProceduresPageConfig} this.config - This configuration for PUIProceduresPage
 * @param {string} this.config.pageType - Either 'signup' or 'dashboard'
 * @param {string} this.config.backUrl - The url used for going to the previous page (dashboard only)
 * @param {string} this.config.actionUrl - The url for saving procedures
 * @param {string} this.config.nextUrl - The url for the page to transition to after saving procedures
 * @param {string} this.config.searchUrl - The url used for searching procedures
 * @param {string} this.config.getUrl - The url used to get all procedures and common procedures
 * @param {array} this.config.procedures - A list of procedures that will be added to the chiclet container on load
 * @param {array} this.config.commonProcedures - The common procedures to add to the chiclet container
 * @param {boolean} this.config.isReviewed - Flag if customer has already reviewed their procedures
 * @param {boolean} this.config.hideCommonProcedures - Flag if hide common procedures
 * @param {boolean} this.config.disablePageContainer - Flag that will remove `pageContainer` created in underlying
 *                                                     pui-sections which consequently does not include
 *                                                     pui-section-padding scss class.
 * @param {array} this.config.strings.months - An ordered list of 12 months
 * @param {string} this.config.strings.continueButton The string for the saving the selected procedures
 * @param {string} this.config.strings.noSelectionErrorText - The text used for when there's no procedures selected
 * @param {string} this.config.strings.serverErrorText - The text used for when there's a server error
 * @param {string} this.config.strings.commonProceduresTitle - The title text for common procedures
 * @param {string} this.config.strings.noProceduresLabel - The label text for no procedures
 * @param {string} this.config.strings.searchInputTitle - The title text used for this procedure search input
 * @param {string} this.config.strings.searchInputPlaceholder - The placeholder text for this procedure search input
 * @param {string} this.config.strings.noResultsFound - The text that shows when no results are found
 * @param {string} this.config.strings.addManuallyText - The text that shows when no results are found
 * @param {string} this.config.strings.addManuallyHref - The link
 *
 * @callback onSave
 * @param {onSave} this.config.callbacks.onSave - A callback that is invoked when saving procedures
 *
 * @callback onAddProcedureChiclet
 * @param {onAddProcedureChiclet} this.config.callbacks.onAddProcedureChiclet - A callback that is invoked when a procedure is
 *                                                                              added to the chiclet container
 *
 * @callback onRemoveProcedureChiclet
 * @param {onRemoveProcedureChiclet} this.config.callbacks.onRemoveProcedureChiclet - A callback that is invoked when a procedure is
 *                                                                                    removed from the chiclet container
 *
 * @callback onProcedureSelect
 * @param {onProcedureSelect} this.config.callbacks.onProcedureSelect - This callback gets called when a user selects
 *                                                                       a procedure from the search results
 *
 * @callback onProcedureManualAdd
 * @param {onProcedureManualAdd} this.config.callbacks.onProcedureManualAdd - This callback gets called when a user selects
 *                                                                            add manually
 */
export default class PUIProceduresPage extends PUIHealthEntityPage {
  connectedCallback() {
    super.connectedCallback();
    this.upgradeProperty('config');
    this._render();
  }

  _render() {
    const { strings, callbacks } = this.config;

    const searchInput = new PUIProcedureSearch();
    searchInput.configure({
      ...this.config,
      callbacks: {
        onProcedureSelect: this._onProcedureSelect.bind(this),
        onProcedureManualAdd: this._onProcedureManualAdd.bind(this),
      },
    });

    const config = {
      ...this.config,
      headerIconClass: this.config.headerIconClass ? this.config.headerIconClass : 'image-placeholder-sm',
      entities: this._toEntities(this.config.procedures, strings.months),
      commonEntities: this._toCommonEntities(this.config.commonProcedures),
      hideCommonHealthEntities: this.config.hideCommonProcedures,
      disablePageContainer: this.config.disablePageContainer,
      searchInput,
      prefixId: 'procedures',
      callbacks: {
        ...callbacks,
        onAddChiclet: this._onAddProcedureChiclet.bind(this),
        onRemoveChiclet: this._onRemoveProcedureChiclet.bind(this),
      },
      strings: {
        ...strings,
        commonHealthEntitiesTitle: strings.commonProceduresTitle,
        noHealthEntitiesLabel: strings.noProceduresLabel,
      },
      fetchResponseConverter: (response) => {
        const { procedures, commonProcedures, isReviewed } = response;

        const entities = this._toEntitiesOrDefault(procedures, strings.months, []);
        const commonEntities = this._toCommonEntitiesOrDefault(commonProcedures, []);

        return {
          entities,
          commonEntities,
          isReviewed,
        };
      },
      selectedOptionConverter: selected => ({
        name: selected[0],
        // Deserialize the JSON string stored in the chiclet value
        ...JSON.parse(selected[1]),
      }),
      saveConverter: procedures => ({ procedures }),
    };

    super.configure(config);
    super._render();
  }

  _toEntities(procedures, months) {
    return this._toEntitiesOrDefault(procedures, months, undefined);
  }

  _toEntitiesOrDefault(procedures, months, defaultValue) {
    if (!procedures) {
      return defaultValue;
    }

    return procedures.map(
      (procedure) => {
        let name = `${procedure.name}`;
        if (procedure.date) {
          name += ` (${months[procedure.date.month - 1]} ${procedure.date.year})`;
        }

        return {
          name,
          // Serialize the object and save to the chiclet value
          id: JSON.stringify(procedure),
        };
      }, [],
    );
  }

  _toCommonEntities(commonProcedures) {
    return this._toCommonEntitiesOrDefault(commonProcedures, undefined);
  }

  _toCommonEntitiesOrDefault(commonProcedures, defaultValue) {
    if (!commonProcedures) {
      return defaultValue;
    }

    return commonProcedures.map(
      commonProcedure => (
        {
          name: commonProcedure.friendly,
          id: commonProcedure.id,
        }
      ), [],
    );
  }

  _onProcedureSelect(procedure) {
    const { callbacks } = this.config;
    if (callbacks && callbacks.onProcedureSelect) {
      callbacks.onProcedureSelect(procedure);
    }
  }

  _onProcedureManualAdd(procedureLabel) {
    const { callbacks } = this.config;
    if (callbacks && callbacks.onProcedureManualAdd) {
      callbacks.onProcedureManualAdd(procedureLabel);
    }
  }

  _onAddProcedureChiclet(chiclet) {
    const { callbacks } = this.config;
    if (callbacks && callbacks.onAddProcedureChiclet) {
      callbacks.onAddProcedureChiclet(JSON.parse(chiclet.value));
    }
  }

  _onRemoveProcedureChiclet(chiclet) {
    const { callbacks } = this.config;
    if (callbacks && callbacks.onRemoveProcedureChiclet) {
      callbacks.onRemoveProcedureChiclet(JSON.parse(chiclet.value));
    }
  }
}

window.customElements.define('pui-procedures-page', PUIProceduresPage);
