import PUIBase from './pui-base';

/**
 *  This is the component which provides functionality to render input type file
 *
 */
export default class PUIFileUpload extends PUIBase {
  constructor() {
    super();
    this._hiddenClass = 'pui-hidden';

    this.defaultAcceptedType = 'image/*';
    this.defaultPlaceholderIconClass = 'capture-icon-borderless';
    this.defaultPlaceholderText = 'Add Photo';
    this.defaultMaximumFilesize = '5242880';
  }

  connectedCallback() {
    super.connectedCallback();
    this.render();
  }

  attributeChangedCallback(name, oldValue, newValue) {
    if (name === 'previewimgurl' && this._innerImageContainer) {
      this._renderImageContainer(newValue);
    }
  }

  static get observedAttributes() {
    return [
      'acceptedType',
      'placeholderIconClass',
      'placeholderText',
      'maximumFilesize',
      'errorMaximumFileSizeId',
      'previewimgurl'
    ];
  }

  /**
   * A preview image url. 
   * It will be null if preview image is deleted by user or replaced by a new local file.
   * @type {string}
   * @attr
   */
  get previewImgUrl() {
    return this.getAttribute('previewImgUrl');
  }

  set previewImgUrl(value) {
    this.setAttribute('previewImgUrl', value);
  }

  /**
   * Define the file types the file input should accept.
   * https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file#accept
   * @type {string}
   * @attr
   */
  get acceptedType() {
    return this.getAttribute('acceptedType') || this.defaultAcceptedType;
  }

  set acceptedType(value) {
    this.setAttribute('acceptedType', value);
  }

  /**
   * Default icon class for the placeholder
   * @type {string}
   * @attr
   */
  get placeholderIconClass() {
    return this.getAttribute('placeholderIconClass') || this.defaultPlaceholderIconClass;
  }

  set placeholderIconClass(value) {
    this.setAttribute('placeholderIconClass', value);
  }

  /**
   * Default text for the placeholder
   * @type {string}
   * @attr
   */
  get placeholderText() {
    return this.getAttribute('placeholderText') || this.defaultPlaceholderText;
  }

  set placeholderText(value) {
    this.setAttribute('placeholderText', value);
  }

  /**
   * Default maximum file size of the input
   * @type {string}
   * @attr
   */
  get maximumFilesize() {
    return this.getAttribute('maximumFilesize') || this.defaultMaximumFilesize;
  }

  set maximumFilesize(value) {
    this.setAttribute('maximumFilesize', value);
  }

  /**
   * ID of element that responsible to display error message if the file chosen
   * exceeds the maximum file size
   * @type {string}
   * @attr
   */
  get errorMaximumFileSizeId() {
    const errorMaxFileSizeId = this.getAttribute('errorMaximumFileSizeId');
    if (errorMaxFileSizeId === null) {
      throw new Error('Please specify valid id to display file size limit error message');
    }
    return errorMaxFileSizeId;
  }

  set errorMaximumFileSizeId(value) {
    this.setAttribute('errorMaximumFileSizeId', value);
  }

  fileInput() {
    return this._innerFileInput;
  }

  render() {
    const {
      id,
      name,
      acceptedType,
      previewImgUrl,
    } = this;
    this.innerHTML = `
    <div class="pui-file-upload pui-file-upload-grey">
        <input class="pui-hidden" id="${id}" type="file" name="${name}" accept="${acceptedType}"/>
        <pui-icon id="dismiss-icon" class="pui-file-upload-dismiss-icon ${this._hiddenClass}" imgClass="capture-icon-dismiss" flowDirection="horizontal"></pui-icon>
        <div class="pui-file-upload-img-container"></div>
    </div>`;

    this._innerFileInput = this.querySelector(`#${id}`);
    this._innerFileInput.addEventListener('change', this._handleFileInputOnChange.bind(this));

    this._innerImageContainer = this.querySelector('.pui-file-upload-img-container');
    this._innerImageContainer.addEventListener('click', () => this._innerFileInput.click());

    this._dismissHandler = this.querySelector('#dismiss-icon');
    this._dismissHandler.addEventListener('click', () => this._dismissHandlerOnClick());

    this._errorMaximumFileSizeElement = document.getElementById(this.errorMaximumFileSizeId);

    this._renderImageContainer(previewImgUrl);
  }

  _renderImageContainer(previewImgUrl) {
    !!previewImgUrl ? this._renderImagePreview(previewImgUrl) : this._renderEmptyPreview();
  }

  _renderImagePreview(previewImgUrl) {
    this._show(this._dismissHandler);
    this._innerImageContainer.innerHTML = `<img src="${previewImgUrl}" />`;
  }

  _renderEmptyPreview() {
    const {
      placeholderIconClass,
      placeholderText,
    } = this;
    this._innerImageContainer.innerHTML = `<div class="preview"><pui-icon imgClass="${placeholderIconClass}" flowDirection="horizontal"></pui-icon>
      <pui-text textAlign="center" textColor="grey" input="${placeholderText}"></pui-text></div>`;
  }

  /**
   * Handling logic when user selected a photo
   */
  _handleFileInputOnChange(event) {
    const _this = this;
    const theFile = event.target.files[0];
    if (theFile) {
      this.previewImgUrl = '';
      // Validate filesize
      if (theFile.size > parseInt(this.maximumFilesize, 10)) {
        this._show(this._errorMaximumFileSizeElement);
        this._dismissHandlerOnClick();
        return;
      }

      const reader = new FileReader();
      reader.onload = function (e) {
        const imagePreviewTag = document.createElement('img');
        imagePreviewTag.setAttribute('src', e.target.result.toString());
        _this._innerImageContainer.innerHTML = imagePreviewTag.outerHTML;
        _this._show(_this._dismissHandler);
        _this._hide(_this._errorMaximumFileSizeElement);
      };

      reader.readAsDataURL(theFile);
    }
  }

  _show(element) {
    element instanceof Element && element.classList.remove(this._hiddenClass);
  }

  _hide(element) {
    element instanceof Element && element.classList.add(this._hiddenClass);
  }

  _dismissHandlerOnClick() {
    this._innerFileInput.value = '';
    this.previewImgUrl = '';
    this._renderEmptyPreview();
    this._hide(this._dismissHandler);
  }
}

window.customElements.define('pui-file-upload', PUIFileUpload);
