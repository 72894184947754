import * as shadyCss from '@webcomponents/shadycss';

import PUIBase from './pui-base';
// eslint-disable-next-line import/no-unresolved
import accordionPageCSS from '../../css/_accordion.scss?inline';
import keyCodes from '../constants/keyCodes';

const defaultClass = 'pui-accordion-item';

const innerTemplate = `
  <style>
    :host {
      display: block;
    }
    ${accordionPageCSS}
  </style>
  <div tabindex='0' class="${defaultClass} ${defaultClass}-default-theme">
    <input tabindex='-1' type="checkbox">
    <label class="${defaultClass}-label">
      <span class="${defaultClass}-label-text">
        <div class="${defaultClass}-label-title"></div>
        <div class="${defaultClass}-label-value"></div>
      </span>
    </label>
    <div class="${defaultClass}-content">
      <slot>
        Inner Contents
      </slot>
    </div>
  </div>
`;

const template = document.createElement('template');

template.innerHTML = innerTemplate;

shadyCss.prepareTemplate(template, 'pui-accordion-item');

class PUIAccordionItem extends PUIBase {
  constructor() {
    super();

    shadyCss.styleElement(this);
    if (!this.shadowRoot) {
      this.attachShadow({ mode: 'open' });
      this.shadowRoot.appendChild(
        document.importNode(template.content, true),
      );
    }
  }

  connectedCallback() {
    super.connectedCallback();

    const accordionItem = this.shadowRoot.querySelector(`.${defaultClass}`);
    if (this.theme === 'minimal') {
      accordionItem.classList.remove(`${defaultClass}-default-theme`);
      accordionItem.classList.add(`${defaultClass}-minimal`);
    } else if (this.theme === 'clinic') {
      accordionItem.classList.remove(`${defaultClass}-default-theme`);
      accordionItem.classList.add(`${defaultClass}-clinic`);
    }

    const id = this.id ? this.id : this.randomStr();
    this.inputItem = this.shadowRoot.querySelector('input');
    this.inputItem.setAttribute('id', id);
    if (this.initialState === 'open') {
      this.inputItem.checked = true;
    }

    const labelItem = this.shadowRoot.querySelector(`.${defaultClass}-label`);
    labelItem.setAttribute('for', id);

    const labelTitleItem = this.shadowRoot.querySelector(`.${defaultClass}-label-title`);
    const labelText = this.label || this.labelTitle;
    if (!!labelText){
      labelTitleItem.innerHTML = labelText;
    } else {
      // hide title if text is empty/null to avoid expansion animation flicker
      labelTitleItem.classList.add('pui-hidden')
    }

    this.labelValueItem = this.shadowRoot.querySelector(`.${defaultClass}-label-value`);
    this.labelValueItem.innerHTML = this.labelValue;

    this.addEventListener('keyup', this.onKeyUp.bind(this));
    this.addEventListener('click', this.onClick.bind(this));
  }

  attributeChangedCallback() {
    this.updateValue(this.labelValue);
  }

  onKeyUp(event) {
    if (event.keyCode === keyCodes.ENTER_KEYCODE) { // Pressed Enter
      if (this.inputItem.checked === false) {
        this.inputItem.checked = true;
      } else {
        this.inputItem.checked = false;
      }

      this.handleToggle(event);
    }
  }

  onClick(event) {
    this.handleToggle();
  }

  handleToggle() {
    if (this.onToggle) {
      this.onToggle(this.inputItem.checked);
    }
  }

  updateValue(value) {
    if (this.labelValueItem) {
      this.labelValueItem.innerHTML = value;
    }
  }

  static get observedAttributes() {
    return ['id', 'label', 'labeltitle', 'labelvalue', 'theme'];
  }

  get id() {
    return this.getAttribute('id') || '';
  }

  set id(value) {
    this.setAttribute('id', value);
  }

  /**
   * Specifies the label that is used to represent this accordion. Functionally equivalent to labelTitle
   * @type {string}
   * @attr
   */
  get label() {
    return this.getAttribute('label') || '';
  }

  set label(value) {
    this.setAttribute('label', value);
  }

  /**
   * Specifies the title that is used to represent this accordion.
   * @type {string}
   * @attr
   */
  get labelTitle() {
    return this.getAttribute('labelTitle') || '';
  }

  set labelTitle(value) {
    this.setAttribute('labelTitle', value);
  }

  /**
   * Specifies the selected value that is used to represent this accordion.
   * @type {string}
   * @attr
   */
  get labelValue() {
    return this.getAttribute('labelValue') || '';
  }

  set labelValue(value) {
    this.setAttribute('labelValue', value);
  }

  get theme() {
    return this.getAttribute('theme') || '';
  }

  set theme(value) {
    this.setAttribute('theme', value);
  }

  /**
   * Specifies the initial state for this accordion item.
   * This attribute defaults to 'closed' if not specified
   * @type {"open"|"closed"}
   * @attr
   */
  get initialState() {
    return this.getAttribute('initialState') || '';
  }

  set initialState(value) {
    this.setAttribute('initialState', value);
  }

  randomStr() {
    let str = '';
    for (let i = 5; i > 0; i--) {
      str += Math.floor(Math.random() * 10);
    }
    return str;
  }
}

window.customElements.define('pui-accordion-item', PUIAccordionItem);
