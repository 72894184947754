import PUIBase from './pui-base';
import { getUniformPriceSize } from '../attributeValues/priceSize';
import includes from '../functions/includes';
import '../../css/_price.scss';
import { injectPUIStyles } from '../functions/domUtils';

/**
 * Component which displays the price value for a medication offer.
 * Built to resemble AUI price: https://console.harmony.a2z.com/aui/v3/components/price/
 *
 * Like AUI price, sizes on this component can either be uniform or differentiated.
 * Specifically, for some sizes, each part of the price (i.e. currency symbol,
 * whole value, fractional value) might be sized differently from others.
 *
 * Also, note that for differentiated price sizes such as `priceSize="large"`:
 * - the decimal separator attribute is ignored
 * - the strikethrough attribute is ignored
 *
 * Conversely, for uniform price sizes such as `priceSize="base"`:
 * - the decimal separator would be visible
 * - the strikethrough is applied if set
 */
export default class PUIPrice extends PUIBase {
  constructor() {
    super();
    this.defaultPriceSize = 'large';
    this.defaultPriceColor = 'unspecified';
  }

  connectedCallback() {
    super.connectedCallback();
    this.render();
  }

  attributeChangedCallback() {
    if (!this.isConnected) return;
    super.attributeChangedCallback();
    this.render();
  }

  static get observedAttributes() {
    return [
      ...super.observedAttributes,
      'currencysymbol', 'wholevalue', 'decimalseparator', 'fractionalvalue', 'pricesize', 'strikethrough', 'pricecolor', 'priceweight',
    ];
  }

  /**
   * A string indicating that the number next to it is a monetary value.
   * @attr
   */
  get currencySymbol() {
    return this.getAttribute('currencySymbol') || '';
  }

  set currencySymbol(value) {
    this.setAttribute('currencySymbol', value);
  }

  /**
   * Whole, non-fractional part of the price component, which can include digit
   * group separators such as commas or periods appropriate to the locale,
   * e.g. '10,000' for US locale.
   * @attr
   */
  get wholeValue() {
    return this.getAttribute('wholeValue') || '';
  }

  set wholeValue(value) {
    this.setAttribute('wholeValue', value);
  }

  /**
   * The character used to separate the integer value from the fractional value
   * appropriate to target locale, eg. '.' for US locale, ',' for DE locale.
   * @attr
   */
  get decimalSeparator() {
    return this.getAttribute('decimalSeparator') || '';
  }

  set decimalSeparator(value) {
    this.setAttribute('decimalSeparator', value);
  }


  /**
   * Fractional value of the price.
   * @attr
   */
  get fractionalValue() {
    return this.getAttribute('fractionalValue') || '';
  }

  set fractionalValue(value) {
    this.setAttribute('fractionalValue', value);
  }


  /**
   * The size of the price. Impacts whether the price components are shown
   * in a uniform or differentiated style and if the strikethrough effect
   * is supported.
   * @type {"base"|"medium-plus"|"large"|"extra-large"}
   * @attr
   */
  get priceSize() {
    return this.getAttribute('priceSize') || this.defaultPriceSize;
  }

  set priceSize(value) {
    this.setAttribute('priceSize', value);
  }

  /**
   * Whether to indicate a visual strikethrough, provided the targeted
   * price component size supports this option.
   * @attr
   */
  get strikethrough() {
    return this.getBooleanAttribute('strikethrough');
  }

  set strikethrough(value) {
    this.setBooleanAttribute('strikethrough', value);
  }

  /**
   * The color of the price
   * @type  {"unspecified"|"price"|"secondary"}
   * @attr
   */
  get priceColor() {
    return this.getAttribute('priceColor') || this.defaultPriceColor;
  }

  set priceColor(value) {
    this.setAttribute('priceColor', value);
  }

  /**
   * The font weight of the whole and fractional values of a differentiated price. Uniform price will not be affected by this attr.
   * @type {"bold"}
   * @attr
   */
  get priceWeight() {
    return this.getAttribute('priceWeight') || '';
  }

  set priceWeight(value) {
    this.setAttribute('priceWeight', value);
  }

  /**
   * Resolve pui-text textColor attr from pui-price priceColor attr.
   * Matches the styles defined for AUI's Price element: https://console.harmony.a2z.com/aui/v3/components/price/
   */
  getPriceTextColor() {
    switch (this.priceColor) {
      case 'price':
        return 'red';
      case 'secondary':
        return 'dark-grey';
      case 'unspecified':
      default:
        return '';
    }
  }

  /**
   * Resolve pui-text textSize attr values for the currency, whole, and fractional text elements based on the pui-price priceSize attr value.
   * Matches the styles defined for AUI's Price element: https://console.harmony.a2z.com/aui/v3/components/price/
   */
  getPriceTextSizes() {
    switch (this.priceSize) {
      case 'base':
        return { currency: 'small-medium', whole: 'small-medium', fractional: 'small-medium' };
      case 'medium-plus':
        return { currency: 'small-medium', whole: 'extra-large', fractional: 'small-medium' };
      case 'extra-large':
        return { currency: 'large', whole: 'triple-extra-large', fractional: 'large' };
      case 'large':
      default:
        return { currency: 'small-medium', whole: 'double-extra-large', fractional: 'large' };
    }
  }

  render() {
    const {
      currencySymbol,
      wholeValue,
      decimalSeparator,
      fractionalValue,
      priceSize,
      strikethrough,
      priceWeight,
    } = this;

    if (!this.shadowRoot) {
      this.attachShadow({ mode: 'open' });
    }

    const textColor = this.getPriceTextColor();
    const priceSizes = this.getPriceTextSizes();

    if (includes(getUniformPriceSize(), priceSize)) {
      // Note that strikethrough and decimal separator are only enabled for uniform price sizes
      this.shadowRoot.innerHTML = `
        <pui-text
          inline="true"
          textDecorationLine="${strikethrough ? 'line-through' : 'none'}"
          textSize="${priceSizes.whole}"
          input="${currencySymbol}${wholeValue}${decimalSeparator}${fractionalValue}"
          textcolor="${textColor}"
        >
        </pui-text>
      `;
    } else {
      this.shadowRoot.innerHTML = `
        <span class="pui-price-differentiated pui-price-size-${priceSize}">
          <span class="pui-price-offscreen">${currencySymbol}${wholeValue}${decimalSeparator ? decimalSeparator : '.'}${fractionalValue}</span>
          <pui-text
            aria-hidden="true"
            inline="true" 
            class="pui-price-symbol"
            textSize="${priceSizes.currency}"
            input="${currencySymbol}"
            textcolor="${textColor}"
          >
          </pui-text>
          <pui-text
            aria-hidden="true"
            inline="true"
            class="pui-price-whole"
            variant="price"
            textSize="${priceSizes.whole}"
            input="${wholeValue}"
            textcolor="${textColor}"
            fontWeight="${priceWeight}"
          >
          </pui-text>
          <pui-text
            aria-hidden="true"
            inline="true"
            class="pui-price-fraction"
            textSize="${priceSizes.fractional}"
            input="${fractionalValue}"
            textcolor="${textColor}"
            fontWeight="${priceWeight}"
          >
          </pui-text>
        </span>
      `;
    }
    injectPUIStyles(this.shadowRoot);
  }
}

window.customElements.define('pui-price', PUIPrice);