import * as shadyCss from '@webcomponents/shadycss';
import PUIBase from './pui-base';

/* eslint-disable import/no-unresolved */
import baseCss from '../../css/_base.scss?inline';
import selectorCss from '../../css/_selector.scss?inline';
import textCss from '../../css/_text.scss?inline';
/* eslint-enable import/no-unresolved */

const properties = ['selected', 'value'];
const template = document.createElement('template');
const innerTemplate = `
  <style>
    ${baseCss}
    ${selectorCss}
    ${textCss}
  </style>
  <button class="pui-selector-item small-margin-top">
    <div class="pui-selector-radio-button">
      <div class="pui-selector-radio-button-selected">
      </div>
    </div>
    <div class="pui-selector-checkbox">
      <div class="pui-selector-checkbox-selected">
      <span class="pui-selector-checkbox-checkmark"></span>
      </div>
    </div>
    <slot></slot>
  </button>
`;

template.innerHTML = innerTemplate;

shadyCss.prepareTemplate(template, 'pui-selector-item');

/**
 * Please add the aria-labelledby or aria-label attribute to support accessibility
 */
export default class PUISelectorItem extends PUIBase {
  constructor() {
    super();

    shadyCss.styleElement(this);
    if (!this.shadowRoot) {
      this.attachShadow({ mode: 'open' });
      this.shadowRoot.appendChild(
        document.importNode(template.content, true),
      );
    }

    this.upgradeProperty = this.upgradeProperty.bind(this);
    this._container = this.shadowRoot.querySelector('.pui-selector-item');
    this._radioButton = this.shadowRoot.querySelector('.pui-selector-radio-button');
    this._checkbox = this.shadowRoot.querySelector('.pui-selector-checkbox');
    this._slot = this.shadowRoot.querySelector('slot');
  }

  connectedCallback() {
    super.connectedCallback();
    this._setupComponent();
    properties.forEach(this.upgradeProperty);
  }

  addSlotContent(content) {
    const contentContainer = document.createElement('div');
    contentContainer.innerHTML = content;
    this._slot.appendChild(contentContainer);
  }

  deselect() {
    this.selected = false;
  }

  select() {
    this.selected = true;
  }

  getValue() {
    return this.value;
  }

  get initialValue() {
    return this.getAttribute('initialValue') || '';
  }

  set initialValue(value) {
    this.setAttribute('initialValue', value);
  }

  get selected() {
    return this.getBooleanAttribute('selected');
  }

  set selected(value) {
    this.setAttribute('aria-selected', value);
    this.setBooleanAttribute('selected', value);
    if (!this._container) return;
    if (value) {
      this._container.classList.add('selected');
    } else {
      this._container.classList.remove('selected');
    }
  }

  get showRadioButton() {
    return this.getBooleanAttribute('showRadioButton');
  }

  set showRadioButton(value) {
    this.setBooleanAttribute('showRadioButton', value);
  }

  get showCheckbox() {
    return this.getBooleanAttribute('showCheckbox');
  }

  set showCheckbox(value) {
    this.setBooleanAttribute('showCheckbox', value);
  }

  get hoverColor() {
    return this.getAttribute('hoverColor');
  }

  set hoverColor(value) {
    this.setAttribute('hoverColor', value);
  }

  get exclusive() {
    return this.getAttribute('exclusive') || '';
  }

  set exclusive(value) {
    this.setAttribute('exclusive', value);
  }

  _setupComponent() {
    this.setAttribute('role', 'option');
    this.classList.add('pui-block');
    if (this.initialValue) {
      this.value = this.initialValue;
    }
    if (this.selected) {
      this.setAttribute('aria-selected', true);
      this._container.classList.add('selected');
    }
    if (this.hoverColor) {
      this._container.classList.add(`${this.hoverColor}-hover-color`);
    }
    if (!this.showRadioButton) {
      this._radioButton.classList.add('pui-hidden');
    }
    if (!this.showCheckbox) {
      this._checkbox.classList.add('pui-hidden');
    }
  }
}

window.customElements.define('pui-selector-item', PUISelectorItem);
