import PUIBase from "../pui-base";

/**
 * This class is responsible for displaying a Discount Card.
 * It is similar to the primerx-discount-card but has slightly different UI.
 * This component does not use exclusively PUI components (e.g. text) since the
 * UX of the discount card is significantly different from other
 * Pharmacy components.
 *
 * There are no actions a customer can take on the discount card.
 *
 * Callbacks:
 *  None
 * Configuration:
 *
 * @typedef PUIDiscountCardItemConfig
 * @type {object}
 * @property {string} insuranceId - The Pillpack Generated insurance id
 * @property {string} planName - Name for the discount card
 * @property {string} cardType - STANDARD/MEDICARE/COUPON/PRIMERX
 * @property {string} cardHolderId - Also known as the memberId
 * @property {string} groupNumber - Insurance card group number
 * @property {string} binNumber - Insurance card bin number
 * @property {string} pcn - Insurance card pcn
 * @property {PUIDiscountCardItemConfigStrings} strings
 *
 * @typedef PUIDiscountCardItemConfigStrings
 * @type {object}
 * @property {string} discountCardTitle - Title that will be placed in the header of the discount card.
 * @property {string} discountCardDisclaimer - Text that disclaims this is not an insurance, but a discount card.
 * @property {string} discountCardSubtitle - Text shown above the card.
 * @property {string} discountCardName - Text shown on the card as the name.
 * @property {string} discountCardTitle - Additional text shown on the card next to the name.
 * @property {string} discountCardDisclaimer - Rich text showing a disclaimer about the card, with a link to a full Terms and Conditions page.
 * @property {string} fullTermsText - Text for this discount card's terms page.
 *
 * @param {PUIDiscountCardItemConfig} this.config - The configuration for DiscountCard
 */
 export default class PUIDiscountCardItem extends PUIBase {
  connectedCallback() {
    super.connectedCallback();
    this.upgradeProperty('config');
    this._render();
  }

  getInsurance() {
    return this.config.insurance;
  }

  _render() {
    /** @type {PUIDiscountCardItemConfig} */
    const {
        insuranceId,
        cardHolderId,
        groupNumber,
        binNumber,
        pcn,
        strings
    } = this.config;

    this.setAttribute('id', `discount-card-${insuranceId}`);
    this.innerHTML = `
    <pui-section secondaryAxisArrangement="center" fullWidth="true">
        <pui-section class="discount-card-subtitle-container">
            <div class="discount-card-page-subtitle">${strings.discountCardSubtitle}</div>
        </pui-section>
        <div class="discount-card">
            <!-- Discount Card Heading -->
            <div class="discount-card-content discount-card-top-section">
                <pui-section>
                    <pui-section fullWidth="true" flowDirection="horizontal" mainAxisArrangement="space-around" secondaryAxisArrangement="center">
                        <pui-section-column flexGrid="50">
                            <pui-section secondaryAxisArrangement="start">
                                <pui-heading textSize="large" textColor="white" input="${strings.discountCardName}"></pui-heading>
                            </pui-section>
                        </pui-section-column>
                        <pui-section-column flexGrid="50">
                            <pui-section secondaryAxisArrangement="end">
                                <pui-heading textSize="small" textColor="white" input="${strings.discountCardTitle}"></pui-heading>
                            </pui-section>
                        </pui-section-column>
                    </pui-section>
                </pui-section>
            </div>
            
            <!-- Details -->
            <div class="discount-card-details-section discount-card-content">
                <pui-section flowDirection="horizontal"
                            mainAxisArrangement="space-between"
                            secondaryAxisArrangement="center"
                            fullWidth>
                
                    <!-- Left Side of Details -->
                    <pui-section-column flexGrid="1">
                        <!-- top row -->
                        <div class="discount-card-details-section-row">
                            <pui-section flowDirection="horizontal"
                                        mainAxisArrangement="start"
                                        secondaryAxisArrangement="center"
                                        fullWidth>
                                <pui-section-column flexGrid="50">
                                    <div class="discount-card-detail-label">MEMBER ID</div>
                                    <div class="discount-card-detail-value">${cardHolderId}</div>
                                </pui-section-column>
                                <pui-section-column flexGrid="50">
                                    <div class="discount-card-detail-label">GROUP</div>
                                    <div class="discount-card-detail-value">${groupNumber}</div>
                                </pui-section-column>
                            </pui-section>
                        </div>

                        <!-- Bottom Row -->
                        <div class="discount-card-details-section-row">
                            <pui-section flowDirection="horizontal"
                                        mainAxisArrangement="start"
                                        secondaryAxisArrangement="center"
                                        fullWidth>
                                <pui-section-column flexGrid="50">
                                    <div class="discount-card-detail-label">BIN</div>
                                    <div class="discount-card-detail-value">${binNumber}</div>
                                </pui-section-column>
                                <pui-section-column flexGrid="50">
                                    <div class="discount-card-detail-label">PCN</div>
                                    <div class="discount-card-detail-value">${pcn}</div>
                                </pui-section-column>
                            </pui-section>
                        </div>
                    </pui-section-column>
                </pui-section>
            </div>
        </div>
        <pui-section fullWidth="true" id="discount-card-card-messages" class="discount-card-card-messages">
            <div class="discount-card-disclaimer-text">${strings.discountCardDisclaimer.replace(/\n/g, "<br />")}</div>
        </pui-section>
    </pui-section>
    `;
  }
}

window.customElements.define('pui-discount-card-item', PUIDiscountCardItem);
