/**
 * Returns true if it's a PDF file
 * @param puiFile PUIFile
 * @returns {boolean}
 */
const isPDFFile = puiFile => puiFile.contentType && puiFile.contentType === 'application/pdf';

/**
 * Returns true if it's an image file
 * @param puiFile PUIFile
 * @returns {boolean}
 */
const isImageFile = puiFile => puiFile.contentType && puiFile.contentType.split('/')[0] === 'image';

export { isPDFFile, isImageFile };
