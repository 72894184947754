import keyCodes from '../../constants/keyCodes';
import PUIBase from '../pui-base';

/**
 * This component contains a button that's used to upload a file
 *
 * Used in pui-file-upload-button-container
 *
 * 'inputId' attribute is required for this component to set up an event listener on an element to handle
 * sending files through the custom event
 */
export default class PUIFileSelect extends PUIBase {
  constructor() {
    super();
    this.defaultUploadFileText = 'Upload photo';
    this._isNativeMobileDevicePermissionsGranted = false;
  }

  connectedCallback() {
    super.connectedCallback();
    this._innerFileInput = document.getElementById(this.inputId);
    this.render();
  }

  static get observedAttributes() {
    return [
      'inputId',
      'useIcons',
      'iconClassName',
      'customUploadFileText',
    ];
  }

  /**
   * Id of the input element to store result of file upload operation
   * This value is required
   *
   * @type {string}
   * @attr
   */
  get inputId() {
    const inputId = this.getAttribute('inputId');
    if (inputId) {
      return inputId;
    }
    throw new Error('Please specify a valid id to store the result of file upload');
  }

  set inputId(value) {
    return this.setAttribute('inputId', value);
  }

  /**
   * Boolean value to determine whether to show icons in the initial file upload buttons
   *
   * @type {boolean}
   * @attr
   */
  get useIcons() {
    return this.getBooleanAttribute('useIcons');
  }

  set useIcons(value) {
    return this.setBooleanAttribute('useIcons', value);
  }

  /**
   * String value of the icon name to display. Name should be a supported class within pui-icon
   *
   * @type {string}
   * @attr
   */
  get iconClassName() {
    return this.getAttribute('iconClassName');
  }

  set iconClassName(value) {
    return this.setAttribute('iconClassName', value);
  }

  /**
   * Button text for the upload button
   * @type {string}
   * @attr
   */
  get customUploadFileText() {
    return this.getAttribute('customUploadFileText') || this.defaultUploadFileText;
  }

  set customUploadFileText(value) {
    this.setAttribute('customUploadFileText', value);
  }

  /** See {@link PUIFileUploadTwo#featureId} */
  get featureId() {
    return this.getAttribute('featureId');
  }

  set featureId(value) {
    this.setAttribute('featureId', value);
  }

  /** See {@link PUIFileUploadTwo#requestId} */
  get requestId() {
    return this.getAttribute('requestId');
  }

  set requestId(value) {
    this.setAttribute('requestId', value);
  }

  render() {
    const {
      useIcons,
      iconClassName,
      customUploadFileText,
    } = this;
    const iconClass = iconClassName || (useIcons ? 'attachment-icon-transparent' : '');
    this.innerHTML = `
                      <div id="pui-file-upload-two-button" class="pui-file-upload-two-button">
                        <div class="pui-file-upload-two-button-information">
                          ${useIcons ? `
                            <pui-icon imgClass="${iconClass}" flowDirection="horizontal"></pui-icon>
                            &nbsp;
                          ` : ''}
                          <pui-text textAlign="center" textSize="extra-large" input="${customUploadFileText}"></pui-text>
                        </div>
                      </div>`;

    this._innerFileUploadButton = this.querySelector('.pui-file-upload-two-button');
    this._innerFileUploadButton.addEventListener('click', () => this._handleInputOnClick());
    this._innerFileUploadButton.addEventListener('keyup', () => this._handleInputOnPressed());
  }

  /**
   * For customers accessing Pharmacy/Katara pages through mShop, we need permission from them to access their
   * device camera or photos. This is done through an integration with the native PermissionService library.
   *
   * For Android, input tags are being natively integrated with PermissionService so no additional work is required
   * For iOS, we must call a javascript API exposed in iOS mshop that bridges JS with native code. This bridge API
   * will call into the native PermissionService API and request permission from the customer, providing us with
   * a callback method with the success/denied response.
   *
   */
  _handleInputOnClick() {
    if (this._isIOSPermissionsEnvironment()) {
      if (this._isNativeMobileDevicePermissionsGranted) {
        // If we have already gained access before, no need to go through the flow again
        this._innerFileInput.click();
      } else {
        if (!this.id) {
          this.setAttribute('id', 'puiPermissions');
        }

        this._callIOSPermissionServiceBridge({
          featureId: this.featureId,
          requestId: this.requestId,
          callbackId: this.id,
          callbackMethodName: 'handlePermissionsResponse', // Defined in this component
        });
      }
    } else {
      // Not in an iOS mShop environment, so proceed with the user's click
      this._innerFileInput.click();
    }
  }

  _handleInputOnPressed(event) {
    if (event.keyCode === keyCodes.ENTER_KEYCODE) {
      this._handleInputOnClick();
    }
  }

  handlePermissionsResponse(response) {
    if (response.accessGranted) {
      this._isNativeMobileDevicePermissionsGranted = true;
      this._innerFileInput.click();
    }
  }

  /**
   * Calls into the native iOS bridge. The featureId and requestId should come from PermissionService.
   * The callbackId and callbackMethodName should be defined on the current HTML page and able to handle the response.
   */
  _callIOSPermissionServiceBridge(requestParams) {
    const {
      featureId,
      requestId,
      callbackId,
      callbackMethodName,
    } = requestParams;

    if (!featureId || !requestId || !callbackId || !callbackMethodName) {
      this._innerFileInput.click(); // Backwards compatibility: allow interaction if no feature/request is provided
      return;
    }

    window.webkit.messageHandlers.mshopwolfgang.postMessage({
      action: 'PermissionService',
      featureId,
      requestId,
      apiOperation: 'requireForFeature',
      callbackId,
      callbackMethodName,
    });
  }

  /**
   * Determine if we are in an iOS Permissions environment based on the presence of the iOS webkit messagehandler
   */
  _isIOSPermissionsEnvironment() {
    return (typeof window === 'object')
      && (typeof window.webkit === 'object')
      && (typeof window.webkit.messageHandlers === 'object')
      && (typeof window.webkit.messageHandlers.mshopwolfgang === 'object')
      && (typeof window.webkit.messageHandlers.mshopwolfgang.postMessage === 'function');
  }
}

window.customElements.define('pui-file-select', PUIFileSelect);
