import PUIPage from '../pui-page';
import Request from '../../networking/request';
import getScrollParent from '../../functions/getScrollParent';

/**
 * This page allows a user to search for pharmacies based upon a zip and search string query
 *
 * Configuration:
 *
 * @param {PharmacySearchPageConfig} this.config - This configuration for PUIPharmacySearchPage
 * @param {string} this.config.searchUrl - The url used for searching pharmacies from latitude/longitude coordinates
 * @param {string} this.config.getCoordinatesUrl - The url used for getting the coordinates for a zipcode
 * @param {string} this.config.contactUsUrl - The url for the help page when no search results are found
 * @param {string} this.config.searchQueryDelay - The search query delay
 * @param {string} this.config.defaultLoadingSize - Define size of initial results to show
 * @param {string} this.config.callbacks.onPharmacySelect - The callback when a user clicks the pharmacy select CTA button
 * @param {string} this.config.strings.pageTitle - The title text for the page
 * @param {string} this.config.strings.pageDescription - The description text for the page
 * @param {string} this.config.strings.pageSubTitle - The subtitle text for the page
 * @param {string} this.config.strings.zipInputTitle - The title text for the zip input component
 * @param {string} this.config.strings.zipInputPlaceholder - The placeholder text for the zip input component
 * @param {string} this.config.strings.useThisPharmacyButtonText - The button text for the pharmacy select CTA
 * @param {string} this.config.strings.useThisPharmacyButtonTransitionText - The transition text after the pharmacy select
 *                                                                           CTA button has been clicked
 * @param {string} this.config.strings.pharmacySearch.searchInputTitle - The title text used for the search input
 * @param {string} this.config.strings.pharmacySearch.searchInputPlaceholder - The placeholder text for search input
 * @param {string} this.config.strings.pharmacySearch.noResultsFound - The text that shows when no results are found
 * @param {string} this.config.strings.pharmacySearch.contactUsPromptText - A prompt for the user to contact us,
 *                                                                          when no pharmacy are found.
 * @param {string} this.config.strings.pharmacySearch.contactUsLinkText - The text for the contact us link
 */
export default class PUIPharmacySearchPage extends PUIPage {
  connectedCallback() {
    super.connectedCallback();
    this._render();
  }

  _render() {
    this.classList.add('pui-block');
    const { strings } = this.config;

    if (!strings.pageTitle) {
      strings.pageTitle = '';
    }
    if (!strings.pageDescription) {
      strings.pageDescription = '';
    }
    if (!strings.pageSubTitle) {
      strings.pageSubTitle = '';
    }
    this.config.pageStyle = 'standard-form-no-footer';
    const content = {
      main: `
        <pui-text input="${strings.pageTitle}" textSize="double-extra-large" fontWeight="bold" hideIfEmpty></pui-text>

        <pui-text input="${strings.pageDescription}" spacingTop="small" textSize="small" hideIfEmpty></pui-text>

        <pui-text input="${strings.pageSubTitle}" textSize="medium" fontWeight="bold"
                  spacingTop="small" spacingBottom="small" hideIfEmpty></pui-text>

        <pui-input id="apex-zip-update-input" class="zip-input" allowedCharacters="numeric" maxLength="5"
                   label="${strings.zipInputTitle}" textInputType="tel"
                   placeholder="${strings.zipInputPlaceholder}"></pui-input>

        <pui-search-input id="apex-pharmacy-search-input" class="small-margin-top" inline
                          label="${strings.pharmacySearch.searchInputTitle}"
                          placeholder="${strings.pharmacySearch.searchInputPlaceholder}"
                          minimumRequiredCharacters="2" searchQueryDelay="${this.config.searchQueryDelay}"
                          noResultsStyle="contact-us" noResultsText="${strings.pharmacySearch.noResultsFound}"
                          contactUsPromptText="${strings.pharmacySearch.contactUsPromptText}"
                          contactUsLinkText="${strings.pharmacySearch.contactUsLinkText}"
                          contactUsLinkHref="${this.config.contactUsUrl}">
        </pui-search-input>

        <div id="apex-pharmacy-search-results-container"></div>
      `
    };
    super._render(content);

    this._defaultLoadingSize = this.config.defaultLoadingSize ? parseInt(this.config.defaultLoadingSize) : 10;
    this._pharmacyResults = [];
    this._coordinates = null;
    this._selectedButton = null;
    this._selectedRadioButton = null;

    this._zipInput = this.querySelector('.zip-input');
    this._zipInput.onInputClear = this._zipInputOnInputClear.bind(this);
    this._zipInput.addEventListener('input', this._zipInputChangedHandler.bind(this));

    this._searchInput = this.querySelector('#apex-pharmacy-search-input');
    this._searchInput.queryUrlBuilder = this._searchQueryUrlBuilder.bind(this);
    this._searchInput.querySuccessCb = this._searchQuerySuccessCb.bind(this);
    this._searchInput.queryErrorCb = this._searchQueryErrorCb.bind(this);
    this._searchInput.onSearchResultClick = this._onSearchResultClick.bind(this);
    this._searchInput.updateSearchResults = this._clearPharmacyResults.bind(this);

    this._pharmacySearchResultsContainer = this.querySelector("#apex-pharmacy-search-results-container");

    // viewport height - padding (aui modal padding - top/bottom)
    this.style.height = "calc(100vh - 320px)";
    const scrollParent = getScrollParent(this, false);
    scrollParent.addEventListener('scroll', () => {
      const { scrollTop, scrollHeight, clientHeight } = scrollParent;
      const windowHasScrolledToBottom = scrollTop + Math.ceil(clientHeight) >= scrollHeight;
      const hasMoreResultsToDisplay = this._pharmacyResults.length > 0;
      if (windowHasScrolledToBottom && hasMoreResultsToDisplay) {
        setTimeout(() => this._displayPharmacyResults(), 500);
      }
    });
  }

  _formatPhoneNumber(phoneNumberString) {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return null;
  }

  _zipInputOnInputClear() {
    this._clearComponentState();
  }

  _zipInputChangedHandler() {
    const zipInputValue = this._zipInput.getValue();
    if (zipInputValue.length === 5) {
      this._getCoordinates(zipInputValue);
    } else {
      this._clearComponentState();
    }
  }

  _getCoordinates(searchZip) {
    Request.get(this.config.getCoordinatesUrl, { searchZip })
      .then((response) => {
        if (response && response.latitude && response.longitude) {
          this._coordinates = response;
          if (this._searchInput.getValue()) {
              this._searchInput.refresh();
          }
        }
      }).catch((error) => {
        this._clearComponentState();
      });
  }

  _searchQueryUrlBuilder(dataSearchUrl, pharmacySearchInputValue) {
    if (this._coordinates) {
      const { latitude, longitude } = this._coordinates;
      return `${this.config.searchUrl}?pharmacyName=${pharmacySearchInputValue}&latitude=${latitude}&longitude=${longitude}`;
    }
  }

  _searchQueryErrorCb(error) {
    this._searchInput.hideSpinner();
    if (this._coordinates) {
      this._searchInput._displayNoResultsFound();
    }
  }

  _searchQuerySuccessCb(response) {
    this._clearPharmacyResults();
    this._pharmacyResults = response;

    if (this._pharmacyResults.length === 0) {
      this._displayNoResultsFound();
    } else {
      this._searchInput._hideNoResultsFound();
      this._displayPharmacyResults();
    }
  }

  /**
   * Restores initial state: ie, clears coordinates and all rendered/non-rendered pharmacy search results (and errors)
   * @private
   */
  _clearComponentState() {
    this._coordinates = null;
    this._searchInput._hideNoResultsFound();
    this._clearPharmacyResults();
  }

  /**
   * Removes rendered/non-rendered pharmacy search results.This is relevant if pharmacy name search parameter has
   * changed and there are new results to display.
   * @private
   */
  _clearPharmacyResults() {
    this._pharmacyResults = [];
    this._pharmacySearchResultsContainer.replaceChildren();
    this._selectedButton = null;
    this._selectedRadioButton = null;
  }

  _displayNoResultsFound() {
    this._searchInput.hideSpinner();
    this._searchInput._displayNoResultsFound();
  }

  _displayPharmacyResults() {
    const { strings } = this.config;
    for (let index = 0; index < this._defaultLoadingSize && index < this._pharmacyResults.length; index++) {
      const pharmacy = this._pharmacyResults[index];
      const pharmacyName = (pharmacy.name) ? pharmacy.name : pharmacy.legalBusinessName;
      const physicalLocationPhoneNumber = this._formatPhoneNumber(pharmacy.physicalLocationPhoneNumber);
      const pharmacyNode = document.createElement("button");
      pharmacyNode.classList.add("pui-block", "pui-selector-item", "small-margin-top");
      pharmacyNode.addEventListener('click', this._onPharmacySelect.bind(this, pharmacy));
      pharmacyNode.innerHTML = `
        <pui-section flowDirection="horizontal">
          <pui-section-column flexGrid="10" spacingRight="small">
            <pui-radio-button id="pharmacy-select-radio-button-${pharmacy.ncpdpid}" value="${pharmacy.ncpdpid}">
            </pui-radio-button>
          </pui-section-column>
          <pui-section-column flexGrid="90">
            <pui-text fontWeight="bold" style="white-space:normal;" input="${pharmacyName}"></pui-text>
            <pui-text input="${pharmacy.physicalLocationAddress1}" overflowStyle="ellipsis"></pui-text>
            <pui-text input="${pharmacy.physicalLocationCity}, ${pharmacy.physicalLocationStateCode} ${pharmacy.physicalLocationZipCode}"
              overflowStyle="ellipsis"></pui-text>
            <pui-text input="${physicalLocationPhoneNumber}"></pui-text>
            <pui-button id="pharmacy-select-button-${pharmacy.ncpdpid}" class="pui-hidden" theme="primary"
              label="${strings.useThisPharmacyButtonText}" spacingTop="small"></pui-button>
          </pui-section-column>
        </pui-section>
      `;
      this._pharmacySearchResultsContainer.appendChild(pharmacyNode);
      const pharmacySelectButton = pharmacyNode.querySelector("#pharmacy-select-button-" + pharmacy.ncpdpid);
      pharmacySelectButton.addEventListener('click', this._onSearchResultClick.bind(this, pharmacy));
    }

    //remove rendered results from results queue
    this._pharmacyResults.splice(0, this._defaultLoadingSize);
  }

  _onPharmacySelect(pharmacy) {
    if (this._selectedButton !== null) {
      this._selectedButton.hide();
    }
    if (this._selectedRadioButton !== null) {
      this._selectedRadioButton.checked = false;
    }
    this._selectedButton =
      this._pharmacySearchResultsContainer.querySelector("#pharmacy-select-button-" + pharmacy.ncpdpid);
    this._selectedRadioButton =
      this._pharmacySearchResultsContainer.querySelector("#pharmacy-select-radio-button-" + pharmacy.ncpdpid);
    this._selectedButton.show();
    this._selectedRadioButton.checked = true;
  }

  _onSearchResultClick(pharmacy) {
    const { callbacks: { onPharmacySelect }, strings } = this.config;
    if (onPharmacySelect) {
      this._selectedButton.updateLabel(strings.useThisPharmacyButtonTransitionText);
      this._selectedButton._innerButton.disabled = true;
      onPharmacySelect(pharmacy);
    }
  }
}

window.customElements.define('pui-pharmacy-search-page', PUIPharmacySearchPage);