import * as shadyCss from '@webcomponents/shadycss';
import PUIBase from './pui-base';

// eslint-disable-next-line import/no-unresolved
import chicletCSS from '../../css/_chiclet.scss?inline';
import { keyListener } from '../functions/domUtils';

const ESAPI = require('node-esapi');

const defaultClass = 'pui-chiclet';
const chicletCloseClass = 'pui-chiclet-close';
const properties = ['value', 'selected', 'filled', 'suggested'];

const innerTemplate = `
  <style>
    ${chicletCSS}
  </style>
  <span class="${defaultClass}">
    <slot class="pui-chiclet-slot"></slot>
    <pui-icon class="${chicletCloseClass}" imgClass="close-chiclet-icon"></pui-icon>
  </span>
`;

const template = document.createElement('template');

template.innerHTML = innerTemplate;

shadyCss.prepareTemplate(template, 'pui-chiclet');


/**
 * A component that displays it's content inside a rounded blue button.
 * 
 * This is used to allow customers to manage a set of things, such as their
 * allergies or medications. Each chiclet displays one element, and optionally
 * a 'close' icon (which can be used to, for instance, remove an allergy from a
 * customer's health profile).
 * 
 * ### Events
 * 
 * This component publishes a 'close' event whenever the close button is clicked.
 * If you call `.preventDefault()` on the close event, the underlying event
 * that triggered this action will also have it's default action prevented.
 */
export default class PUIChiclet extends PUIBase {
  constructor() {
    super();

    shadyCss.styleElement(this);
    if (!this.shadowRoot) {
      this.attachShadow({ mode: 'open' });
      this.shadowRoot.appendChild(
        document.importNode(template.content, true),
      );
    }
    this.upgradeProperty = this.upgradeProperty.bind(this);
    this._updateLabel = this._updateLabel.bind(this);
    this._content = this.shadowRoot.querySelector('span');
    this._icon = this.shadowRoot.querySelector('pui-icon');
  }

  connectedCallback() {
    super.connectedCallback();
    this.setAttribute('role', 'option');
    properties.forEach(this.upgradeProperty);
    if (this.encode) {
      this.shadowRoot.querySelector('slot').innerHTML = ESAPI.encoder().encodeForHTML(this.label);
    } else {
      this.shadowRoot.querySelector('slot').innerHTML = this.label;
    }
    this.shadowRoot.querySelector('slot').addEventListener('slotchange', this._updateLabel);
    this.setAttribute('tabindex', '0');
    this.setAttribute('id', `apex-chiclet-${this.label.toLowerCase().replace(/[^a-z]/g, '')}`);
    this.setCloseIconAttributes();
    this.setCloseIconEventListeners();
  }

  attributeChangedCallback( name, oldValue, newValue) {
    super.attributeChangedCallback();
    this.setAttribute('aria-selected', this.selected);
    if (this.selected) {
      this._content.classList.add('selected');
      this._icon.classList.add('selected');
    } else if (this.filled) {
      this._content.classList.add('filled');
      this._icon.classList.add('filled');
    } else {
      this._content.classList.remove('filled');
      this._icon.classList.remove('filled');
      this._content.classList.remove('selected');
      this._icon.classList.remove('selected');
    }
    this.setCloseIconAttributes();
  }

  setCloseIconAttributes() {
    this._icon.setAttribute('aria-label', this.closeLabel);
    this._icon.setAttribute('role', 'button');
    this._icon.setAttribute('tabindex', '0');
  }

  setCloseIconEventListeners() {
    this._icon.addEventListener('click', this.dispatchCloseEvent.bind(this));
    this._icon.addEventListener('keydown', keyListener(this.dispatchCloseEvent.bind(this)));
  }

  dispatchCloseEvent(sourceEvent) {
    const event = new CustomEvent('close', { cancelable: true });
    const shouldPerformDefaultAction = this.dispatchEvent(event);
    if (!shouldPerformDefaultAction) {
      sourceEvent.preventDefault();
      sourceEvent.stopPropagation();
    }
  }

  static get observedAttributes() {
    return [...super.observedAttributes, 'selected', 'filled', 'closelabel'];
  }

  disable() {
    this._content.classList.add('disable');
    this._icon.classList.add('disable');
  }

  get label() {
    return this.getAttribute('label') || '';
  }

  set label(value) {
    this.setAttribute('label', value);
  }

  get closeLabel() {
    return this.getAttribute('closeLabel') || '';
  }

  set closeLabel(value) {
    if (value) {
      this.setAttribute('closeLabel', value);
    } else {
      this.setAttribute('closeLabel', '');
    }
  }

  get value() {
    return this.getAttribute('value') || '';
  }

  set value(value) {
    if (value) {
      this.setAttribute('value', value);
    }
  }

  get provenance() {
    return this.getAttribute('provenance') || '';
  }

  set provenance(value) {
    this.setAttribute('provenance', value);
  }

  get selected() {
    return this.hasAttribute('selected');
  }

  set selected(value) {
    if (value) {
      this.setAttribute('selected', '');
    } else {
      this.removeAttribute('selected');
    }
  }

  get filled() {
    return this.hasAttribute('filled');
  }

  set filled(value) {
    if (value) {
      this.setAttribute('filled', '');
    } else {
      this.removeAttribute('filled');
    }
  }

  get suggested() {
    return this.hasAttribute('suggested');
  }

  set suggested(value) {
    if (value) {
      this.setAttribute('suggested', '');
    } else {
      this.removeAttribute('suggested');
    }
  }

  get encode() {
    return this.getBooleanAttribute('encode');
  }

  set encode(value) {
    return this.setBooleanAttribute('encode', value);
  }

  _updateLabel() {
    this.setAttribute('aria-label', this.label);
  }
}

window.customElements.define('pui-chiclet', PUIChiclet);
