import PUIBase from './pui-base';
import { injectPUIStyles } from '../functions/domUtils';

export default class PUITextArea extends PUIBase {
  constructor() {
    super();
    this.defaultClass = 'pui-text-area';
    this.defaultSpacingTop = 'small';
    this.defaultInputType = 'text';
  }

  connectedCallback() {
    super.connectedCallback();
    this._render();
  }

  static get observedAttributes() {
    return ['inputId', 'placeholder', 'backgroundColor', 'maxlength'];
  }

  inputClicked(event) {
    if (this.contains(event.target)) {
      this.shadowRoot.querySelector('textarea').focus();
      this._textContainer.classList.add('text-area-selected');
    } else {
      this._textContainer.classList.remove('text-area-selected');
    }
  }

  inputChangedHandler(event) {
    if (this.onInputChange) {
      this.onInputChange(this.getValue());
    }
  }

  focus() {
    this._textArea.focus();
    this._textContainer.classList.add('text-area-selected');
  }

  unfocus() {
    this._textArea.blur();
    this._textContainer.classList.remove('text-area-selected');
  }

  get inputId() {
    return this.getAttribute('inputId') || '';
  }

  set inputId(value) {
    this.setAttribute('inputId', value);
  }

  get placeholder() {
    return this.getAttribute('placeholder') || '';
  }

  set placeholder(value) {
    this.setAttribute('placeholder', value);
  }

  /**
   * Used to set the background color for text area
   * @type {"ghost-color"}
   */
  get backgroundColor() {
    return this.getAttribute('backgroundColor') || '';
  }

  set backgroundColor(value) {
    this.setAttribute('backgroundColor', value);
  }

  get maxLength() {
    return this.getAttribute('maxLength') || '';
  }

  set maxLength(value) {
    this.setAttribute('maxLength', value);
  }

  getValue() {
    return this.shadowRoot.querySelector('textarea').value;
  }

  setValue(value) {
    this.shadowRoot.querySelector('textarea').value = value;
  }

  clearInput() {
    this.shadowRoot.querySelector('textarea').value = '';
  }

  showError(errorMessage) {
    this._errorMessage.setText(errorMessage);
    this._errorMessage.show();
    this._textContainer.classList.add('text-area-error');
    this.classList.add('pui-text-area-error');
  }

  hideError() {
    this._errorMessage.hide();
    this._textContainer.classList.remove('text-area-error');
    this.classList.remove('pui-text-area-error');
  }

  _render() {
    const {
      inputId,
      placeholder,
      backgroundColor,
      maxLength
    } = this;

    this.classList.add('pui-block');

    this.attachShadow({ mode: 'open' });
    this.shadowRoot.innerHTML = `
    <div class="pui-text-area-container ${this.defaultClass}-${backgroundColor}">
        <textarea id="${inputId}" 
            spellcheck="false" 
            class="pui-text-area ${this.defaultClass}-${backgroundColor}"
            placeholder="${placeholder}"
            maxLength="${maxLength}"></textarea>
        <slot name="text-area-button"></slot>
    </div>
    <pui-text input="" class="pui-text-area-error-message pui-hidden" textSize="small" textColor="red" spacingTop="small"></pui-text>
    `;
    injectPUIStyles(this.shadowRoot);
    document.body.addEventListener('click', this.inputClicked.bind(this));

    this._textContainer = this.shadowRoot.querySelector('.pui-text-area-container');
    this._errorMessage = this.shadowRoot.querySelector('.pui-text-area-error-message');
    this._textArea = this.shadowRoot.querySelector('textarea');
    this._textArea.addEventListener('input', this.inputChangedHandler.bind(this));
  }
}

window.customElements.define('pui-text-area', PUITextArea);
