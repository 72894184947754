import PUIBase from '../pui-base';
import PUIMedPicker from './pui-med-picker';
import PUIMedItem from './pui-med-item';
import PUIMedChickletContainer from './pui-med-chicklet-container';
import Request from '../../networking/request';

const uuidv4 = require('uuid/v4');
/**
 * This component is responsible for displaying medications and handling interactions with the user with respect
 * to their medications
 *
 * It supports the ability to search and add new medications through its interaction with the search component.
 * It supports the deletion of medications that have been previously added.
 *
 * To get a list of medications that the user has added to their list, call getSelectedMedications. The source of
 * truth for these medications will be stored in the PUIMedItem DOM element. When we getSelectedMedications, we will
 * get a list of PUIMedItem and then call getMedication to get the actual medication object
 *
 * Configuration:
 *
 * @param {MedicationsConfig} this.config - The configuration for PUIMedications
 * @param {string} this.config.searchUrl - The url used for searching medications
 * @param {string} this.config.searchRequestMethod - The request method type for search request, can be 'GET' or 'POST', default is 'POST'
 * @param {string} this.config.environment - The environment this will be rendered in (optional)
 * @param {boolean} this.config.hideCommonMedications - boolean attribute to hide/show Common Medications
 * @param {string} this.config.searchQueryDelay - Specify the delay in millliseconds before initiating a search query
 * @param {string} this.config.currentMedicationsUrl - The url used for getting the current medications
 * @param {boolean} this.config.displayUnknownOption - The boolean for displaying the "I don't know" option
 * @param {boolean} this.config.commonMedications - the common medications
 * @param {boolean} this.config.showPrescriptions - the boolean to show/hide prescription
 * @param {boolean} this.config.prescriptions - the prescriptions
 * @param {boolean} this.config.strictDescriptorRendering - This option enables a strict type of rendering for titles and descriptions, where
 *                                                          they will not render if their subsequent elements are empty or supposed to be hidden.
 *                                                          For example, in contrast to the current rendering strategy, the prescription title and
 *                                                          description will not be displayed if the user is not taking any Amazon Pharmacy
 *                                                          prescriptions (prescriptions list is empty). It will also only render the
 *                                                          commonMedications iff both the prescriptions list and medications list are empty.
 *                                                          Note: if prescriptions are empty, it will not display a title for medications either,
 *                                                          but it will display the medication(s) as chicklet(s).
 * @param {string} this.config.medicationsAndPrescriptionsUrl - The url used for getting the current medications and prescriptions
 * @param {boolean} this.config.isMedicationsReviewed - The boolean for medication review status
 * @param {string} this.config.strings.searchInputTitle - The title text for the search input
 * @param {string} this.config.strings.searchInputPlaceholder - The placeholder text fof the search input
 * @param {string} this.config.strings.searchResultGeneric - Label to show the search item is generic
 * @param {string} this.config.strings.noResultsFound - The text that displays when no results found
 * @param {string} this.config.strings.cancelButton - The cancel button text
 * @param {string} this.config.strings.addButton - The add button text
 * @param {string} this.config.strings.removeButton - The remove button text
 * @param {string} this.config.strings.updateButton - The update button text
 * @param {string} this.config.strings.strengthSelectLabel - The label for the strenght select input
 * @param {string} this.config.strings.dontKnowOption - The string for the don't know option
 * @param {string} this.config.strings.strengthSelectCancel - The string for the cancel option on strength selection
 * @param {string} this.config.strings.selectedSectionTitle -  The string for title of selectedSection.
 * @param {string} this.config.strings.prescriptionsSectionTitle -  The string for title of prescriptionSection.
 * @param {string} this.config.strings.prescriptionSectionsDescription -  The string for description of prescriptionSection.
 *
 * @param {string} this.config.strings.configuratorTitle - The title for this configurator
 * @param {string} this.config.strings.packagingQuestion - The question asking which dosage form to select
 * @param {string} this.config.strings.strengthQuestion - The question asking which strength to select
 * @param {string} this.config.strings.completeConfigurationButton - The string for the configurator finish button
 * @param {string} this.config.strings.completeUpdateConfigurationButton - The string for the configurator finish button
 * @param {string} this.config.strings.configurationCancel - The string for cancelling configurator
 */
export default class PUIMedications extends PUIBase {
  connectedCallback() {
    super.connectedCallback();
    this._fetchCurrentMedications();
    if (this.config.showPrescriptions === true) {
      this._fetchPrescriptionList();
    }
    if (!this._isEnvironmentSignUpOrDefault()) {
      this._render();
    }
  }

  /**
   * Public function that returns the list of medications that user has added or kept on their profile
   * @returns: An array of medication objects that the user has selected.
   *
   * The medication object returned may look different depending on whether it was one that fetched from the health
   * profile service or if it was added through elastic search. Each medication is guaranteed to have at least name and
   * groupingId.  Rxcui and gsddMarketedProductId will be populated if a valid strength is selected.
   *
   * Look at _fetchCurrentMedications to see what a medication from the health profile service looks like
   * Look at _onSearchAdd to see what a medication from medication search component looks like
   */
  getSelectedMedications() {
    if (this._isEnvironmentSignUpOrDefault()) {
      return this._getSelectedMedicationsFromChickletContainer();
    }

    const medItems = this._medList.querySelectorAll('pui-med-item');
    const medications = [];
    medItems.forEach((medItem) => {
      medications.push(medItem.getMedication());
    });
    return medications;
  }

  removeAllMedications() {
    const medItems = this._medList.querySelectorAll('pui-med-item');
    Array.from(medItems).forEach((medItem) => {
      medItem._onCancel();
    });
  }

  _render() {
    this.classList.add('pui-block');

    if (this.config.currentMedications) {
      this._addCurrentMedicationsToMedicationsList();
    }
    if (this.config.commonMedications) {
      this._addCommonMedicationsList();
    } else if (this._isEnvironmentSignUpOrDefault()) {
      this._renderChickletContainer();
    }

    if (!this._isEnvironmentSignUpOrDefault()) {
      this._renderMedPicker();
    }
  }

  _renderMedPicker(currentMedications = null) {
    this._medPicker = new PUIMedPicker();
    this._medPicker.setAttribute('id', 'apex-medication-picker');
    const medPickerConfig = {
      environment: this.config.environment ? this.config.environment : '',
      searchUrl: this.config.searchUrl,
      searchRequestMethod: this.config.searchRequestMethod,
      currentMedications: currentMedications || '',
      commonMedications: this.config.commonMedications,
      searchQueryDelay: this.config.searchQueryDelay,
      displayUnknownOption: this.config.displayUnknownOption,
      strings: this.config.strings,
      callbacks: {
        onMedicationAdd: this._onMedPickerAdd.bind(this), // dashboard
        onMedItemSelected: this._onMedItemSelected.bind(this), // signup
      },
    };
    this._medPicker.configure(medPickerConfig);
    this._medPicker.setAttribute('spacingTop', 'small');
    if (this.children.length > 0) {
      for (let i = 0; i < this.children.length; i++) {
        this.removeChild(this.children[i]);
      }
    }
    this.appendChild(this._medPicker);

    this._medList = document.createElement('div');
    this.appendChild(this._medList);
    if (this._isEnvironmentSignUpOrDefault()) {
      this._render();
    }
  }

  _renderChickletContainer(medication = null) {
    const oldNode = document.getElementById('pui-med-chicklet-container');
    if (oldNode) {
      this.removeChild(oldNode);
    }

    this.medChickletContainer = new PUIMedChickletContainer();
    this.medChickletContainer.setAttribute('id', 'pui-med-chicklet-container');
    if (this.renderBottomSheet) {
      this.medChickletContainer.renderBottomSheet = this.renderBottomSheet.bind(this);
    }

    if (medication) {
      this._buildMedicationsList(medication);
    }
    const medChickletContainerConfig = {
      displayUnknownOption: this.config.displayUnknownOption,
      medicationsList: this.medicationsList,
      commonMedicationsList: this.commonMedicationsList,
      prescriptionsList: this.config.prescriptions,
      strictDescriptorRendering: this.config.strictDescriptorRendering,
      showPrescriptions: this.config.showPrescriptions,
      prescriptionsSectionDescription: this.config.prescriptionsSectionDescription,
      prescriptionsSectionTitle: this.config.prescriptionsSectionTitle,
      hideCommonMedications: this.config.hideCommonMedications,
      strings: this.config.strings,
      callbacks: {
        onItemClick: this._onItemClick.bind(this),
      },
    };
    this.medChickletContainer.configure(medChickletContainerConfig);
    this.appendChild(this.medChickletContainer);
  }

  _isEnvironmentSignUpOrDefault() {
    return ['signup', 'default'].includes(this.config.environment);
  }

  _addCommonMedicationsList() {
    const { commonMedications } = this.config;
    for (const med in commonMedications) {
      this._buildCommonMedicationsList(commonMedications[med].name);
    }
  }

  _addCurrentMedicationsToMedicationsList() {
    const { currentMedications } = this.config;
    const { medications } = currentMedications;
    if (!medications || medications.length === 0) {
      const checkbox = document.getElementById('no-medications-check-box');
      checkbox.check();
    } else {
      for (const med in medications) {
        this._buildMedicationsList(medications[med].searchResult);
      }
    }
  }

  _buildMedicationsList(medication) {
    const checkbox = document.getElementById('no-medications-check-box');
    checkbox.uncheck();
    if (!this.medicationsList) {
      this.medicationsList = [];
    }
    // if med has been searched for before, don't create new med. Instead pull existing med into selected
    if (this.medicationsList.some(medListItem => medListItem.rxcui === medication.rxcui)) {
      const duplicate = this.medicationsList.find(medListItem => medListItem.rxcui === medication.rxcui);
      duplicate.selected = true;
    } else {
      medication.selected = true;
      medication.suggested = true;
      this.medicationsList.push(medication);
    }
    return this.medicationsList;
  }

  _buildCommonMedicationsList(name) {
    const searchRequestMethod = this.config.searchRequestMethod || 'POST';
    const queryUrl = this._queryUrlBuilder(null, name);
    const thisComponent = this;
    let responsePromise;
    if (searchRequestMethod === 'GET') {
      responsePromise = Request.get(queryUrl);
    } else {
      // use POST as default for backward compatibility
      responsePromise = Request.post(queryUrl);
    }
    responsePromise.then((response) => {
      const result = response.results[0];
      result.selected = false;
      result.suggested = true;
      if (!this.commonMedicationsList) {
        this.commonMedicationsList = [];
      }
      this.commonMedicationsList.push(result);
      const self = this;
      setTimeout(() => {
        self._renderChickletContainer();
      }, 0);
    }).catch((error) => {
      if (thisComponent.queryErrorCb) {
        thisComponent.queryErrorCb(error);
      }
    });
  }

  _fetchPrescriptionList() {
    Request.get(this.config.medicationsAndPrescriptionsUrl).then((response) => {
      const { medications } = response;
      const prescriptions = [];
      for (const med in medications) {
        if (medications[med].searchResult.provenance === 'PRESCRIPTION') {
          prescriptions.push(medications[med]);
        }
      }
      this.config.prescriptions = prescriptions;
    }).catch((error) => {
      const checkbox = document.getElementById('no-medications-check-box');
      checkbox.check();
      this._renderMedPicker(this.config.currentMedications);
    });
  }

  _onMedItemSelected(medication) {
    this._renderChickletContainer(medication);
  }

  _queryUrlBuilder(dataSearchUrl, medicationSearchInputValue) {
    // ignore the dataSearchUrl and instead use this.searchUrl, as pui-search-input is not been given the
    // search url input
    const onlyShowSellableDrugs = this.config.onlyShowSellableDrugs ? 1 : 0;
    return `${this.config.searchUrl}?searchTerm=${medicationSearchInputValue}&onlyShowSellableDrugs=${onlyShowSellableDrugs}`;
  }

  _onItemClick(selected, suggested) {
    const checkbox = document.getElementById('no-medications-check-box');
    for (const med in this.medicationsList) {
      if (suggested.has(this.medicationsList[med].displayName)) {
        this.medicationsList[med].selected = false;
      } else if (selected.has(this.medicationsList[med].displayName)) {
        checkbox.uncheck();
        this.medicationsList[med].selected = true;
      }
    }


    for (const med in this.commonMedicationsList) {
      if (suggested.has(this.commonMedicationsList[med].displayName)) {
        this.commonMedicationsList[med].selected = false;
      } else if (selected.has(this.commonMedicationsList[med].displayName)) {
        checkbox.uncheck();
        this.commonMedicationsList[med].selected = true;
      }
    }
  }

  _getSelectedMedicationsFromChickletContainer() {
    const container = this.medChickletContainer;
    return container._getSelected();
  }

  /**
   *
   * @param {Medication} medication - the medication returned from the onSearchAdd callback function.
   * See the _onConfirm function in PUIMedSearch component to get more details about the medication object.
   */
  _onMedPickerAdd(medication) {
    if (this._isDuplicateOfExistingMedication(medication)) {
      this._showDuplicateMedicationError();
      return;
    }
    this._removeDuplicateMedicationError();
    this._addMedication(medication);
  }

  _addMedication(medication) {
    const medItem = new PUIMedItem();
    const addedMedication = medication;
    addedMedication.id = uuidv4();
    const { strings } = this.config;
    const medItemConfig = {
      displayUnknownOption: this.config.displayUnknownOption,
      medication: addedMedication,
      hideCommonMedications: this.config.hideCommonMedications,
      hideToggle: false,
      strings: {
        cancelButton: strings.removeButton,
        confirmButton: strings.updateButton,
        strengthSelectLabel: strings.strengthSelectLabel,
        dontKnowOption: strings.dontKnowOption,
        strengthSelectCancel: strings.strengthSelectCancel,
        configuratorTitle: strings.configuratorTitle,
        packagingQuestion: strings.packagingQuestion,
        strengthQuestion: strings.strengthQuestion,
        completeConfigurationButton: strings.completeConfigurationButton,
        completeUpdateConfigurationButton: strings.completeUpdateConfigurationButton,
        configurationCancel: strings.configurationCancel,
      },
      callbacks: {
        onCancel: this._removeMedication.bind(this),
        onConfirm: this._updateMedication,
      },
    };
    medItem.configure(medItemConfig);
    this._medList.prepend(medItem);
  }

  _removeMedication(medItem) {
    medItem.parentNode.removeChild(medItem);
  }

  _updateMedication(medItem) {
    medItem.toggleExpandedSection();
  }

  _isDuplicateOfExistingMedication(medication) {
    // get all medItems on the page
    const existingMeds = Array.from(this.getElementsByClassName('med-detail'));
    for (let i = 0; i < existingMeds.length; i += 1) {
      const med = JSON.parse(decodeURIComponent(existingMeds[i].getAttribute('data-med')));
      // check newly added med against existing med
      if (med.strengthKnown === medication.strengthKnown && medication.rxcui === med.rxcui) {
        return true;
      }
    }
    return false;
  }

  _showDuplicateMedicationError() {
    const medProfileTitleSection = document.getElementById('med-profile-title-section');
    this._removeDuplicateMedicationError();
    const alertContainer = document.createElement('div');
    alertContainer.setAttribute('id', 'alertContainer');
    alertContainer.innerHTML = `
      <pui-box id="duplicate-alert" theme="alert" spacingBottom="small">
        <pui-text id="duplicate-alert-title-text" input="${this.config.strings.duplicateAlertTitleText}" fontWeight="bold" textColor="red"></pui-text>
        <pui-section id="duplicate-alert-description">
            <pui-text id="duplicate-alert-description-text" input="${this.config.strings.duplicateAlertDescription}"></pui-text>
        </pui-section>
      </pui-box>
    `;
    medProfileTitleSection.prepend(alertContainer);
  }

  _removeDuplicateMedicationError() {
    const containerCheck = document.getElementById('alertContainer');
    if (containerCheck !== null) {
      containerCheck.parentNode.removeChild(containerCheck);
    }
  }


  /**
   * Make call to get the current list of medication from health profile service and then
   * add them to medication list
   */
  _fetchCurrentMedications() {
    Request.get(this.config.currentMedicationsUrl).then((response) => {
      const { medications } = response;
      if (!this._isEnvironmentSignUpOrDefault()) {
        medications.forEach((medication) => {
          /**
           * @param {Medication} medication A medication object returned by the health profile service
           * @param {string} medication.name Name of the medication which includes the dosage form
           * @param {string} medication.groupingId Id used to identify a group medications that share the same name
           *                                       and dosage form
           * @param {string} medication.rxcui RXCUI for the selected strength.
           *                                  This will be rxcui of the first strength if 'don't know' was selected
           * @param {string} medication.gsddMarketedProductId gsddMarketedProductId of selected strength.
           *                                                  This will be the productId of the first strength if
           *                                                  don't know was selected
           * @param {string} medication.source Currently, will always be 'MANUAL', but could be something different with
           *                                   RXHistory.
           * @param {boolean} medication.taking Currently, will always be true
           */
          this._addMedication(medication);
        });
      } else {
        this.config.currentMedications = response;

        const { commonMedications, isReviewed } = response;
        if (commonMedications !== undefined) {
          this.config.commonMedications = commonMedications;
        }

        if (isReviewed !== undefined) {
          this.config.isMedicationsReviewed = isReviewed;
        }

        this._renderMedPicker(this.config.currentMedications);
        this._addCurrentMedicationsToMedicationsList();

        // No medications checkbox should be unchecked
        // if medication is not reviewed yet.
        if (this.config.isMedicationsReviewed !== undefined && !this.config.isMedicationsReviewed) {
          const checkbox = document.getElementById('no-medications-check-box');
          checkbox.uncheck();
        }
      }
    }).catch((error) => {
      const checkbox = document.getElementById('no-medications-check-box');
      checkbox.check();
      this._renderMedPicker(this.config.currentMedications);
    });
  }
}

window.customElements.define('pui-medications', PUIMedications);
