import PUIBase from './pui-base';

/**
 * This component requests native permissions
 */
export default class PUIPermissions extends PUIBase {
  constructor() {
    super();
    this._isNativeMobileDevicePermissionsGranted = false;
    this._isInIOSPermissionsEnvironment = this._isIOSPermissionsEnvironment();
  }

  isNativeMobileDevicePermissionsGranted() {
    return this._isNativeMobileDevicePermissionsGranted;
  }

  isInIOSPermissionsEnvironment() {
    return this._isInIOSPermissionsEnvironment;
  }

  /**
   * Calls into the native iOS bridge. The featureId and requestId should come from PermissionService.
   * The callbackId and callbackMethodName should be defined on the current HTML page and able to handle the response.
   */
  callIOSPermissionServiceBridge(requestParams) {
    const {
      featureId,
      requestId,
    } = requestParams;

    if (!this.id) {
      this.setAttribute('id', 'puiPermissions');
    }

    window.webkit.messageHandlers.mshopwolfgang.postMessage({
      action: 'PermissionService',
      featureId,
      requestId,
      apiOperation: 'requireForFeature',
      callbackId: this.id,
      callbackMethodName: 'handlePermissionsResponse',
    });
  }

  /**
   * Determine if we are in an iOS Permissions environment based on the presence of the iOS webkit messagehandler
   */
  _isIOSPermissionsEnvironment() {
    return (typeof window === 'object')
      && (typeof window.webkit === 'object')
      && (typeof window.webkit.messageHandlers === 'object')
      && (typeof window.webkit.messageHandlers.mshopwolfgang === 'object')
      && (typeof window.webkit.messageHandlers.mshopwolfgang.postMessage === 'function');
  }

  handlePermissionsResponse(response) {
    if (response.accessGranted) {
      this._isNativeMobileDevicePermissionsGranted = true;
      this.dispatchEvent(new Event('permissionGranted'));
    }
  }
}

window.customElements.define('pui-permissions', PUIPermissions);
