const convertToMedication = (searchResult, medicationConfiguration) => {
  const medicationItem = {};

  medicationItem.name = medicationConfiguration.displayName;
  medicationItem.groupingId = searchResult.rxcui;
  medicationItem.searchResult = searchResult;
  medicationItem.strengthKnown = medicationConfiguration.strengthKnown;
  if (searchResult.ndc) {
    medicationItem.ndc = searchResult.ndc;
  }

  // Get RXCUI and GSDD
  if (medicationConfiguration.isOtc) {
    medicationItem.rxcui = searchResult.rxcui;
    medicationItem.rxcuiTty = searchResult.medicationResultTermType;
    medicationItem.gsddMarketedProductId = searchResult.gsddMarketedProductId;
  } else if (medicationConfiguration.packagingType === 'dosageForm') {
    const { dosageFormRxcui, strengthRxcui } = medicationConfiguration;
    const dosageForm = searchResult.dosageForms.find(dosageFormElement => dosageFormElement.rxcui === dosageFormRxcui);
    const strength = dosageForm.strengths.find(strengthElement => strengthElement.rxcui === strengthRxcui);
    medicationItem.rxcui = strength.rxcui;
    medicationItem.rxcuiTty = strength.termType;
    medicationItem.gsddMarketedProductId = strength.gsddMarketedProductId;
  } else {
    const { packRxcui } = medicationConfiguration;
    let pack;
    if (searchResult.medicationResultTermType === 'BPCK') {
      pack = searchResult;
      medicationItem.rxcuiTty = pack.medicationResultTermType;
    } else {
      pack = searchResult.packs.find(packElement => packElement.rxcui === packRxcui);
      medicationItem.rxcuiTty = pack.termType;
    }
    medicationItem.rxcui = pack.rxcui;
    medicationItem.strengthKnown = true;
    medicationItem.gsddMarketedProductId = pack.gsddMarketedProductId;
  }
  return medicationItem;
};

export default convertToMedication;
