import PUIBase from '../pui-base';

/**
 * This class is responsible for displaying a single pharmacy entry on the pharmacy page
 *
 * Callbacks:
 *
 * Callback for when the trash icon is pressed
 * @callback onDelete
 *
 * Configuration:
 *
 * @param {PharmacyItemConfig} this.config - The configuration for this PUIPharmacyItem
 * @param {string} this.config.itemStyle - Either 'box' or 'divider'
 * @param {Pharmacy} this.config.pharmacy - The pharmacy
 * @param {string} this.config.pharmacy.npi - National Provider Identifier (only used in signup for insurance)
 * @param {string} this.config.pharmacy.spi - Surescripts Provider Identifier
 * @param {string} this.config.pharmacy.firstName - The pharmacy's first name
 * @param {string} this.config.pharmacy.lastName - The pharmacy's last name
 * @param {string} this.config.pharmacy.suffix - The pharmacy's suffix
 * @param {string} this.config.pharmacy.phoneNumber - The pharmacy's phone number
 * @param {string} this.config.pharmacy.addressLineOne - The pharmacy clinic's address line one
 * @param {string} this.config.pharmacy.addressLineTwo - The pharmacy clinic's address line two
 * @param {string} this.config.callbacks.onDelete - The callback for the delete button
 *
 */
export default class PUIPharmacyItem extends PUIBase {
  connectedCallback() {
    super.connectedCallback();
    this.upgradeProperty('config');
    this._render();
  }

  getPharmacy() {
    return this.config.pharmacy;
  }


  _formatPhoneNumber(phoneNumberString) {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3]
    }
    return null;
  }

  _render() {
    const {
      pharmacy,
      itemStyle,
      environment
    } = this.config;

    this.classList.add('pui-block');


    if (environment === "react") { // react version.
      const baseHTML = `
        <pui-section flowDirection="horizontal" mainAxisArrangement="space-between" secondaryAxisArrangement="center">
          <pui-section>
            <pui-text input="${pharmacy.name}"
                      fontWeight="bold"></pui-text>
            <pui-text input="${pharmacy.physicalLocationAddress1}"></pui-text>
            <pui-text input="${pharmacy.physicalLocationCity}, ${pharmacy.physicalLocationStateCode} ${pharmacy.physicalLocationZipCode}"></pui-text>
            <pui-text input="${this._formatPhoneNumber(pharmacy.physicalLocationPhoneNumber)}"></pui-text>
          </pui-section>

        </pui-section>
      `;
      if (itemStyle === 'box') {
        this.innerHTML = `
          <pui-box spacingTop="small" selected>
            <pui-section-column flexGrid="2">
            <pui-radio-button checked spacingTop="small"></pui-radio-button>
            </pui-section-column>

            <pui-section-column flexGrid="5">
              ${baseHTML}
            </pui-section-column>
          </pui-box>
        `;
      } else {
        this.innerHTML = `
          <pui-section spacingTop="small">
            ${baseHTML}
          </pui-section>
          <pui-divider spacingTop="medium"></pui-divider>
        `;
      }

    } else { // Webapp version. Will be removed once we fully transition over to react 
      const baseHTML = `
      <pui-section flowDirection="horizontal" mainAxisArrangement="space-between" secondaryAxisArrangement="center">
        <pui-section>
          <pui-text input="${pharmacy.name}"
                    fontWeight="bold"></pui-text>
          <pui-text input="${pharmacy.physicalLocationAddress1}"></pui-text>
          <pui-text input="${pharmacy.physicalLocationCity}, ${pharmacy.physicalLocationStateCode} ${pharmacy.physicalLocationZipCode}"></pui-text>
        </pui-section>
      </pui-section>
    `;
    if (itemStyle === 'box') {
      this.innerHTML = `
        <pui-box spacingTop="small">
          ${baseHTML}
        </pui-box>
      `;
    } else {
      this.innerHTML = `
        <pui-section spacingTop="small">
          ${baseHTML}
        </pui-section>
        <pui-divider spacingTop="medium"></pui-divider>
      `;
    }

    }
  }

}

window.customElements.define('pui-pharmacy-item', PUIPharmacyItem);
