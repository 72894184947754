import PUIBase from './pui-base';

export default class PUIRadioButton extends PUIBase {
  constructor() {
    super();
    this.defaultClass = 'pui-radio-button';
  }

  connectedCallback() {
    super.connectedCallback();
    this.render();
  }

  attributeChangedCallback() {
    if (this._innerInput) {
      this._innerInput.checked = this.checked;
    }
  }

  static get observedAttributes() {
    return ['value', 'label', 'disabled', 'checked', 'tabindex'];
  }

  get label() {
    return this.getAttribute('label') || '';
  }

  set label(value) {
    this.setAttribute('label', value);
  }

  get value() {
    return this.getAttribute('value') || '';
  }

  set value(value) {
    this.setAttribute('value', value);
  }

  get disabled() {
    return this.getBooleanAttribute('disabled');
  }

  set disabled(value) {
    this.setBooleanAttribute('disabled', value);
  }

  get checked() {
    return this.getBooleanAttribute('checked');
  }

  set checked(value) {
    this.setBooleanAttribute('checked', value, this._innerInput);
    const changeEvent = new Event('change');
    this._innerInput.dispatchEvent(changeEvent);
  }

  get tabindex() {
    return this.getAttribute('tabindex') || '';
  }

  set tabindex(value) {
    this.setAttribute('tabindex', value);
  }

  enable() {
    this.disabled = false;
    this.querySelector('input').removeAttribute('disabled');
  }

  disable() {
    this.disabled = true;
    this.querySelector('input').setAttribute('disabled', true);
  }

  isDisabled() {
    return Boolean(this.disabled);
  }

  check() {
    this.checked = true;
    this.querySelector('input').checked = true;
  }

  uncheck() {
    this.checked = false;
    this.querySelector('input').checked = false;
  }

  isChecked() {
    return Boolean(this.querySelector('input').checked);
  }

  focus() {
    this.querySelector('input').focus();
  }

  radioButtonInputChange(/** @type {Event} */ event) {
    if (!event.isTrusted) return;
    if (this.onRadioButtonToggle) {
      this.onRadioButtonToggle(this.value);
    }
  }

  render() {
    // This non-shadowDOM slot implementation is a necessary workaround for radio-groups being
    // isolated in shadowDOM trees. It would cause a problem if this component needed to be able to
    // nest inside itself, but that should never happen for a pui-radio-button
    const slot = this.innerHTML;
    this.innerHTML = '';
    const {
      name,
      value,
      disabled,
      label,
      checked,
      tabindex,
    } = this;

    this.classList.add('pui-block');
    this._innerLabel = document.createElement('label');
    this._innerLabel.classList.add('pui-radio-button-container');
    this._innerLabel.innerHTML = `
      <input type="radio" name="${name}" value="${value}" ${disabled ? 'disabled' : ''} ${checked ? 'checked' : ''}
             class="pui-radio-button-input" tabindex="${tabindex}">
      <i class="pui-radio-button-icon"></i>
    `;

    if (this.label) {
      this._innerLabel.innerHTML += `<pui-text input="${label}"></pui-text>`;
    } else {
      this._innerLabel.innerHTML += slot;
    }

    this._innerInput = this._innerLabel.firstElementChild;
    this._innerInput.addEventListener('change', this.radioButtonInputChange.bind(this));


    this.appendChild(this._innerLabel);
  }
}

window.customElements.define('pui-radio-button', PUIRadioButton);
