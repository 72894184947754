import PUIBase from "../../pui-base";
import { PUICart } from "../../pui-cart";
import { Urls } from '../../../constants';

export class PUICartNavWidget extends PUIBase {
    static get observedAttributes() {
        return [
            ...super.observedAttributes,
            'cartcount'
        ]
    }

    /** @type {PUICart | null} */
    #cart = null;

    /** Get/set the cartcount HTML attribute */
    get cartCount() {
        return this.getAttribute('cartcount')
    }

    set cartCount(newValue) {
        this.setAttribute('cartcount', newValue);
    }

    attributeChangedCallback() {
        this.#render();
    }

    connectedCallback() {
        super.connectedCallback();
        this.#render();
    }

    #render() {
        const {
            isMShop,
            cartCount
        } = this;

        if (!this.#cart) {
            this.innerHTML = `<pui-cart id="pharmacy-cart" href="${Urls.CART_URL}"></pui-cart>`;
            this.#cart = this.querySelector("pui-cart");
        }
        if (isMShop) {
            this.#cart.color = "white";
            this.#cart.textColor = "white";
        } else {
            this.#cart.color = "black";
            this.#cart.textColor = "black";
        }
        this.#cart.cartCount = cartCount;
    }
}

window.customElements.define('pui-cart-widget', PUICartNavWidget);
