import PUIBase from './pui-base';
import getTransitionChildTypes from '../attributeValues/transitionChildTypes';
import includes from '../functions/includes';

const timeout = 470;

class PUITransitionContainer extends PUIBase {
  constructor() {
    super();
    this.defaultClass = 'pui-transition-container';
    this.defaultChildType = 'page';
    this.type = '';
    this.index = 0;
    this.previousIndex = 0;
  }

  static get observedAttributes() {
    return ['childType'];
  }

  connectedCallback() {
    super.connectedCallback();
    window.addEventListener('DOMContentLoaded', this.render.bind(this));
    if (document.readyState === 'complete' || document.readyState === 'loaded') {
      this.render();
    }
  }


  get childType() {
    return this.getAttribute('childType') || '';
  }

  set childType(value) {
    this.setAttribute('childType', value);
  }

  next(isBrowserHistoryPress = false, idx = null) {
    if (this.index < this.pages.length - 1) {
      this.previousIndex = this.index;
      this.index = idx || this.index + 1;
      if (this.hasPages()) PUITransitionContainer.hide(this.pages[this.previousIndex], 'left');
      if (this.hasFooters()) PUITransitionContainer.hide(this.footers[this.previousIndex], 'down');
      setTimeout(() => {
        if (this.hasPages()) PUITransitionContainer.display(this.pages[this.index], 'left');
        if (this.hasFooters()) PUITransitionContainer.display(this.footers[this.index], 'up');
      }, timeout);
      const currentTime = new Date().valueOf();
      if (!isBrowserHistoryPress) {
        window.history.pushState({ time: currentTime }, '');
        this.lastHistoryPressTime = null;
      }
    } else {
      console.log('Next page does exist');
    }
  }

  previous(idx = null) {
    if (this.index > 0) {
      this.previousIndex = this.index;
      this.index = (idx != null) ? idx : this.index - 1;
      if (this.hasPages()) PUITransitionContainer.hide(this.pages[this.previousIndex], 'right');
      if (this.hasFooters()) PUITransitionContainer.hide(this.footers[this.previousIndex], 'down');
      setTimeout(() => {
        if (this.hasPages()) PUITransitionContainer.display(this.pages[this.index], 'right');
        if (this.hasFooters()) PUITransitionContainer.display(this.footers[this.index], 'up');
      }, timeout);
    } else {
      console.log('Previous page does not exist');
    }
  }

  static display(element, direction) {
    element.classList.remove('pui-transition-hidden');
    element.classList.add('pui-transition-show');
    element.classList.add(`pui-transition-in-${direction}`);
    setTimeout(() => {
      element.classList.remove(`pui-transition-in-${direction}`);
    }, timeout);
  }

  static hide(element, direction) {
    element.classList.add(`pui-transition-out-${direction}`);
    setTimeout(() => {
      element.classList.remove(`pui-transition-out-${direction}`);
      element.classList.remove('pui-transition-show');
      element.classList.add('pui-transition-hidden');
    }, timeout);
  }

  browserHistoryPress(event) {
    console.log('browserHistoryPress Event');
    if (event.state) {
      if (this.lastHistoryPressTime == null) {
        this.lastHistoryPressTime = event.state.time;
        this.previous();
      } else {
        const currentBrowserEventTime = event.state.time;
        if (currentBrowserEventTime < this.lastHistoryPressTime) {
          this.previous();
        } else {
          this.next(true);
        }
        this.lastHistoryPressTime = currentBrowserEventTime;
      }
    }
  }

  addPage(data) {
    const elementsContainer = document.createElement('div');
    elementsContainer.innerHTML = data;
    this.appendChild(elementsContainer);
    this.pages = [...this.getElementsByTagName('pui-transition-page')];
    this.footers = [...this.getElementsByTagName('pui-transition-footer')];
  }

  hasPages() {
    return this.pages.length !== 0;
  }

  hasFooters() {
    return this.footers.length !== 0;
  }

  render() {
    let {
      childType,
    } = this;
    this.classList.add(this.defaultClass);
    this.classList.add('pui-block');

    if (!includes(getTransitionChildTypes(), childType)) {
      childType = this.defaultChildType;
    }
    childType = `${childType}`;
    this.type = childType;

    if (!this.shadowRoot) {
      this.attachShadow({
        mode: 'open',
      });
    }
    this.shadowRoot.innerHTML = `
        <slot>
          Inner Transition Elements
        </slot>
    `;
    this.pages = [...this.getElementsByTagName('pui-transition-page')];
    this.footers = [...this.getElementsByTagName('pui-transition-footer')];
    if (this.hasPages()) {
      this.pages[0].classList.remove('pui-transition-hidden');
      this.pages[0].classList.add('pui-transition-show');
    }
    if (this.hasFooters()) {
      this.footers[0].classList.remove('pui-transition-hidden');
      this.footers[0].classList.add('pui-transition-show');
    }

    const currentTime = new Date().valueOf();
    window.history.replaceState({ time: currentTime }, '');
      window.onpopstate = this.browserHistoryPress.bind(this);
    // this is left here to call simulation below
    // uncomment to run simulation
    // this.callNextPage();
  }

  // This is left here for simulation purposes
  callNextPage() { // automates next page transitions at 2 second interval
    const self = this;
    setTimeout(() => {
      if (this.index === 3) {
        self.previous();
      } else {
        self.next();
      }
      self.callNextPage();
    }, 2000);
  }
}

window.customElements.define('pui-transition-container', PUITransitionContainer);
