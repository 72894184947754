import { attachmentPreviewType } from '../../constants/fileUpload';
import keyCodes from '../../constants/keyCodes';
import PUIBase from '../pui-base';
import { isPDFFile } from '../../functions/fileUtils';

/**
 * This class is responsible for creating the file upload preview.
 *
 * Callbacks:
 *
 * @callback onRemoved - Callback invoked upon removing an existing file preview.
 *
 * Configuration:
 *
 * @param this.puiFile - PUIFile that is being passed to the preview component
 * @param this.previewAppearanceType - File preview type (full or compact)
 * @param this.isMobileDevice - Client using mobile device or not
 * @param this.displayBottomSheetOnRemoval - Boolean to indicate whether bottom sheet needs to be displayed for user confirmation before deletion.
 *
 */
export default class PUIFilePreview extends PUIBase {
  constructor() {
    super();
    this._hiddenClass = 'pui-hidden';
    this.defaultPreviewAppearanceType = attachmentPreviewType.PREVIEW_TYPE_FULL;
    // TODO: Remove default name and make it injectable via config https://sim.amazon.com/KATARA-3083
    this.defaultImageName = 'Uploaded Image';
    this.defaultPDFName = 'Uploaded PDF';
    this.defaultDismissBottomSheetTitle = 'Are you sure?';
    this.defaultDismissBottomSheetCancelLabel = 'Cancel';
    this.defaultDismissBottomSheetDeleteLabel = 'Delete';
  }

  connectedCallback() {
    super.connectedCallback();
    this.render();
  }

  /**
   * Value to represent the appearance for the image preview screen. Can either be 'full' or 'compact'. Default is 'full'.
   * @returns {string}
   */
  get previewAppearanceType() {
    return this.getAttribute('previewAppearanceType') || this.defaultPreviewAppearanceType;
  }

  set previewAppearanceType(value) {
    this.setAttribute('previewAppearanceType', value);
  }

  /**
   * Value to represent if the client using mobile device or not.
   * @returns {boolean}
   */
  get isMobileDevice() {
    return this.getAttribute('isMobileDevice') || false;
  }

  set isMobileDevice(value) {
    this.setAttribute('isMobileDevice', value);
  }

  /**
   * Display bottom sheet on removal if this attribute is set to true.
   * @returns {boolean}
   */
  get displayBottomSheetOnRemoval() {
    return this.getBooleanAttribute('displayBottomSheetOnRemoval');
  }

  set displayBottomSheetOnRemoval(displayBottomSheetOnRemoval) {
    this.setBooleanAttribute('displayBottomSheetOnRemoval', displayBottomSheetOnRemoval);
  }

  render() {
    this.innerHTML = '<div class="pui-file-preview pui-hidden"></div>';
    this._innerImagePreview = this.querySelector('.pui-file-preview');

    const { puiFile } = this;
    if (puiFile === undefined) {
      throw new Error('puiFile is required to render PUIFilePreview');
    }

    if (this.previewAppearanceType === attachmentPreviewType.PREVIEW_TYPE_COMPACT) {
      this._renderAppearanceTypeCompact();
    } else if (this.previewAppearanceType === attachmentPreviewType.PREVIEW_TYPE_FULL) {
      this._renderAppearanceTypeFull();
    } else {
      throw new Error('previewAppearanceType property is not set');
    }

    this._show(this._innerImagePreview);
  }

  /**
   * Render compact view of file. This currently supports Image and PDF.
   */
  _renderAppearanceTypeCompact() {
    const { puiFile } = this;
    const fileNameAbbreviated = !this._isBlank(puiFile.filename) ? this._fileNameAbbreviated(puiFile.filename) : this._defaultFileName(puiFile);
    let previewHTML;

    if (isPDFFile(puiFile)) {
      // we can't render pdf so rather show pdf icon.
      previewHTML = '<pui-icon slot="icon" imgClass="pdf-file-icon"></pui-icon>';
    } else {
      const imagePreviewTag = document.createElement('img');
      imagePreviewTag.setAttribute('src', puiFile.previewLink);
      previewHTML = imagePreviewTag.outerHTML;
    }

    this._innerImagePreview.classList.add('pui-file-preview-compact');
    this._innerImagePreview.innerHTML = `
                ${previewHTML}
                <pui-text class="pui-file-preview-compact-filename" textAlign="left" textSize="normal" input="${fileNameAbbreviated}" inline></pui-text>
                <pui-icon id="compact-dismiss-icon" class="pui-file-dismiss-icon" imgClass="compact-file-preview-dismiss-icon" flowDirection="horizontal"></pui-icon>
                ${this.displayBottomSheetOnRemoval ? this._renderBottomSheetDismiss() : ''}
            `;

    this._compactDismissHandler = this.querySelector('.pui-file-dismiss-icon');
    this._compactDismissHandler.addEventListener('click', () => this._dismissHandlerOnClick());
    this._compactDismissHandler.addEventListener('keyup', () => this._dismissHandlerOnPressed());

    if (this.displayBottomSheetOnRemoval) this._addBottomSheetHandler();
  }

  /**
   * Render full view of file. This currently only supports Image.
   */
  _renderAppearanceTypeFull() {
    const { puiFile } = this;

    if (isPDFFile(puiFile)) {
      throw new Error('PDF preview is not supported in full view type.');
    }

    const imagePreviewTag = document.createElement('img');
    imagePreviewTag.setAttribute('src', puiFile.previewLink);

    this._innerImagePreview.classList.add('pui-file-preview-full');
    this._innerImagePreview.innerHTML = `
                <div class="pui-file-preview-full-img-container">${imagePreviewTag.outerHTML}</div>
                <pui-icon class="pui-file-preview-full-dismiss-icon" imgClass="full-file-preview-dismiss-icon" flowDirection="horizontal"></pui-icon>
                ${this.displayBottomSheetOnRemoval ? this._renderBottomSheetDismiss() : ''}
            `;

    this._defaultDismissHandler = this.querySelector('.pui-file-preview-full-dismiss-icon');
    this._defaultDismissHandler.addEventListener('click', () => this._dismissHandlerOnClick());
    this._defaultDismissHandler.addEventListener('keyup', () => this._dismissHandlerOnPressed());

    if (this.displayBottomSheetOnRemoval) this._addBottomSheetHandler();
  }

  _renderBottomSheetDismiss() {
    const {
      dismissBottomSheetTitle,
      dismissBottomSheetCancelLabel,
      dismissBottomSheetDeleteLabel,
    } = this.config.strings;

    const dismissTitle = dismissBottomSheetTitle || this.defaultDismissBottomSheetTitle;
    const dismissCancelLabel = dismissBottomSheetCancelLabel || this.defaultDismissBottomSheetCancelLabel;
    const dismissDeleteLabel = dismissBottomSheetDeleteLabel || this.defaultDismissBottomSheetDeleteLabel;

    return `<pui-bottom-sheet id="preview-removal-bottom-sheet" hideLink>
                    <div id="preview-removal-confirmation-container">
                        <pui-heading input="${dismissTitle}" textSize="large" spacingBottom="medium"></pui-heading>
                        <pui-section flowDirection="horizontal" spacingTop="small">
                            <pui-button id="bottomSheetCancelButton" 
                                        class="pui-file-preview-bottom-sheet-cancel-btn" 
                                        width="unset"
                                        textColor="black-color"
                                        textSize="extra-large"
                                        theme="secondary"
                                        label="${dismissCancelLabel}"></pui-button>
                            <pui-button id="bottomSheetConfirmButton" 
                                        class="pui-file-preview-bottom-sheet-delete-btn"
                                        width="unset" 
                                        textColor="red-color"
                                        textSize="extra-large"
                                        theme="secondary"
                                        label="${dismissDeleteLabel}"></pui-button>
                        </pui-section>
                    </div>
                </pui-bottom-sheet>`;
  }

  _addBottomSheetHandler() {
    this._previewRemovalBottomSheetHandler = this.querySelector('#preview-removal-bottom-sheet');
    this._bottomSheetCancelButton = this.querySelector('#bottomSheetCancelButton');
    this._bottomSheetCancelButton.addEventListener('click', this._onBottomSheetCancelClick.bind(this));
    this._bottomSheetConfirmButton = this.querySelector('#bottomSheetConfirmButton');
    this._bottomSheetConfirmButton.addEventListener('click', this._onBottomSheetConfirmClick.bind(this));
  }

  _show(element) {
    return element instanceof Element && element.classList.remove(this._hiddenClass);
  }

  _fileNameAbbreviated(fileName) {
    if (!this.isMobileDevice) {
      return fileName;
    }

    return fileName.length > 15 ? `${fileName.substring(0, 12)}...${fileName.substring(fileName.length - 4)}`
      : fileName;
  }

  _dismissHandlerOnClick() {
    if (this.displayBottomSheetOnRemoval) {
      this._previewRemovalBottomSheetHandler.show();
    } else {
      this._removeFromSelected();
    }
  }

  _dismissHandlerOnPressed(event) {
    if (event.keyCode === keyCodes.DELETE_KEYCODE) {
      this._dismissHandlerOnClick();
    }
  }

  _onBottomSheetCancelClick() {
    this._previewRemovalBottomSheetHandler.hide();
  }

  _onBottomSheetConfirmClick() {
    this._removeFromSelected();
    this._previewRemovalBottomSheetHandler.hide();
  }

  _removeFromSelected() {
    const { puiFile } = this;

    this._innerImagePreview.remove();

    if (this.onRemoved) {
      this.onRemoved(puiFile);
    }
  }

  _defaultFileName(puiFile) {
    if (isPDFFile(puiFile)) {
      return this.defaultPDFName;
    }
    return this.defaultImageName;
  }

  _isBlank(str) {
    return (!str || str.length === 0);
  }
}

window.customElements.define('pui-file-preview', PUIFilePreview);
