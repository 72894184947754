// TODO(https://sim.amazon.com/issues/ccf40da1-f162-41e1-9ef6-b94eff0edd73): PharmacyUserInterface is not setup to lint *.ts files
/* eslint-disable */
import getLinkColor from '../attributeValues/linkColor';
import getTextSize from '../attributeValues/textSize';
import keyCodes from '../constants/keyCodes';
import { injectPUIStyles } from '../functions/domUtils';
import includes from '../functions/includes';
import PUIBase from './pui-base';
import { PUISearchInput } from './pui-search-input';
import PUIText from './pui-text';

import "../../css/bottom-sheet.scss";

const ESAPI = require('node-esapi');

export class BottomSheetOpenEvent extends CustomEvent<string> {
  public static EVENT_NAME = "bottom-sheet-open";

  constructor() {
    super(BottomSheetOpenEvent.EVENT_NAME, {cancelable: true});
  }
}

export class BottomSheetCloseEvent extends CustomEvent<string> {
  public static EVENT_NAME = "bottom-sheet-close";

  constructor() {
    super(BottomSheetCloseEvent.EVENT_NAME, {cancelable: true});
  }
}

/**
 * ## Overview
 *
 * The PUI Bottom Sheet is a component that displays its content in a modal
 * dialog appearing over the page. Bottom sheets can be rendered in the middle
 * of the screen for Desktop environments, and on the bottom of the screen for
 * Mobile environments (hence the name).
 *
 * ## Features
 *
 * ### Customize Close button
 *
 * By default, the button to close a bottom sheet is labelled 'CLOSE' in all
 * caps. You can customize this by setting a `closeText` attribute on the bottom
 * sheet, such as `closeText="CANCEL"`.
 *
 * ### Customize the bottom sheet link
 *
 * Bottom sheets are triggered by a 'link' that is rendered inline, and the text
 * shown for this link is supplied with the `text` attribute. However, you can
 * also customize how this link is displayed, or even remove it entirely, with
 * the `hidelink`, `emphasizelink` and `linkcolor` attributes.
 *
 * You can also replace it with a PUI icon, by specifying a PUI Icon imgClass
 * with the `icon` attribute. Eg, `icon="medications-icon"`. The size of this
 * icon can be customized with the `iconWidth` and `iconHeight` attributes.
 *
 * ### Opening and closing via the JavaScript API
 *
 * Bottom sheets can also be controlled imperatively, by using the `.hide()` and
 * `.show()` methods, which hide and show the bottom sheet dialog respectively.
 *
 * ### Focus trapping
 *
 * As an accessibility measure for screen reader users, PUI Bottom Sheets will
 * 'trap' focus within the dialog while the dialog is open. PUI will constrain
 * the focusable elements to just within the dialog content. This ensures that
 * screen readers stay focused on the dialog, and don't let focus move 'beyond'
 * the bottom sheet to page elements underneath it.
 *
 * ### Scrolling, size, and padding
 *
 * If you have a lot of long-form content, you might wish to enable scrolling
 * to keep the bottom sheet from becoming unusually large and to ensure that
 * customers can view the entire content regardless of how big their screen is.
 * This is enabled with the `enablescrollbar` attribute. The size and padding
 * of bottom sheets can be further customized with the `disablemodalpadding` and
 * (desktop-only) `maxwidth` attributes.
 *
 * ### ARIA labels for content
 *
 * If you identify that your content is not being read out in a clear manner by
 * screen readers, you can sometimes address these with ARIA annotations to tell
 * the screen reader what the content is, and how it should be read out. These
 * can be set with the `contentariarole` and `contentarialabel` attributes,
 * which correspond to `aria-role` and `aria-label` respectively.
 *
 * You can read more about ARIA here:
 *
 * https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA
 *
 * ## Events
 *
 * PUI Bottom Sheets expose 2 custom events when a bottom sheet is opened or
 * closed. These events are cancellable, and if you call `.preventDefault()` on
 * these events, then PUI will not open or close the bottom sheet. This is
 * useful if you need to keep a bottom sheet open as part of a UX flow.
 *
 * - `bottom-sheet-open`
 * - `bottom-sheet-close`
 *
 * ## Caveats
 *
 * The mobile view introduces some limitations on what content can be displayed
 * in a bottom sheet. For instance, mobile bottom sheets cannot exceed 75% of
 * the viewport height. It is not reccomended to try to override these limits,
 * as it could lead to the close button and parts of the content clipping off
 * screen, preventing some customers from being able to dismiss the bottom
 * sheet.
 *
 */
export default class PUIBottomSheet extends PUIBase {
  public static readonly DEFAULT_TEXT_SIZE = 'large';
  private static readonly DEFAULT_CLOSE_TEXT = 'CLOSE';
  private static readonly SCROLL_DISABLED_CLASS = 'pui-scroll-disabled';

  private readonly isMobile;

  public onClose?: (event: Event) => void;
  public onOpen?: (event: Event) => void;

  constructor() {
    super();
    this.isMobile = document.documentElement.classList.contains('a-mobile');
  }

  connectedCallback() {
    super.connectedCallback();
    this.render();
    this.attachEventListener();
  }

  static get observedAttributes() {
    return [...super.observedAttributes, 'text', 'closetext', 'hidelink', 'emphasizelink', 'minheight', 'linkcolor', 'ishidden', 'enablescrollbar', 'focusbottomsheetid', 'contentariarole', 'contentarialabel', 'disablemodalpadding', 'maxwidth'];
  }

  /**
   * @classprop {string} text This is the attribute for the bottom sheet link text
   *
   */
  get text() {
    return this.getAttribute('text') || '';
  }

  set text(value) {
    this.setAttribute('text', value);
  }

  get closeText() {
    return this.getAttribute('closeText') || '';
  }

  set closeText(value) {
    this.setAttribute('closeText', this.encodedValue(value));
  }

  get textSize() {
    return this.getAttribute('textSize') || '';
  }

  set textSize(value) {
    this.setAttribute('textSize', value);
  }

  get hideLink() {
    return this.getBooleanAttribute('hideLink');
  }

  set hideLink(value) {
    this.setBooleanAttribute('hideLink', value);
  }

  get emphasizeLink() {
    return this.getBooleanAttribute('emphasizeLink');
  }

  set emphasizeLink(value) {
    this.setBooleanAttribute('emphasizeLink', value);
  }

  get hideClose() {
    return this.getBooleanAttribute('hideClose');
  }

  set hideClose(value) {
    this.setBooleanAttribute('hideClose', value);
  }

  get minHeight() {
    return this.getAttribute('minHeight') || '';
  }

  set minHeight(value) {
    this.setAttribute('minHeight', value);
  }

  /**
   * Set the max-width of modal. e.g. "600px"
   * A default max-width will be used if absent.
   * @type {string}
   * @attr
   */
  get maxWidth() {
    return this.getAttribute('maxWidth') || '';
  }

  set maxWidth(value) {
    this.setAttribute('maxWidth', value);
  }

  get linkColor() {
    return this.getAttribute('linkColor') || '';
  }

  set linkColor(value) {
    this.setAttribute('linkColor', value);
  }

  get isHidden() {
    return this.classList.contains('collapsed');
  }

  set icon(value) {
    this.setAttribute('icon', this.encodedValue(value ?? ''));
  }

  get icon() {
    return this.getAttribute('icon') || null;
  }

  set iconWidth(value) {
    this.setAttribute('iconWidth', this.encodedValue(value ?? ''));
  }

  get iconWidth() {
    return this.getAttribute('iconWidth') || null;
  }

  set iconHeight(value) {
    this.setAttribute('iconHeight', this.encodedValue(value ?? ''));
  }

  get iconHeight() {
    return this.getAttribute('iconHeight') || null;
  }

  set iconHover(value) {
    this.setBooleanAttribute('iconHover', value);
  }

  get iconHover() {
    return this.getBooleanAttribute('iconHover');
  }

  set iconAltText(value) {
    this.setAttribute('iconAltText', value ?? '');
  }

  get iconAltText() {
    return this.getAttribute('iconAltText');
  }

  set enableScrollbar(value) {
    this.setBooleanAttribute('enableScrollbar', value);
  }

  /**
   * Specifies whether to show scrollbars for overflow content or not (Chrome only)
   * Default behavior hides scrollbars
   */
  get enableScrollbar() {
    return this.getBooleanAttribute('enableScrollbar');
  }

  set contentAriaRole(value) {
    this.setAttribute('contentAriaRole', value ?? '');
  }

  get contentAriaRole() {
    return this.getAttribute('contentAriaRole');
  }

  set contentAriaLabel(value) {
    this.setAttribute('contentAriaLabel', value ?? '');
  }

  get contentAriaLabel() {
    return this.getAttribute('contentAriaLabel');
  }

  /**
   * Specifies whether to have modal padding or not. If false, a default padding will be added.
   * Default to false if absent.
   * @type {boolean}
   * @attr
   */
  set disableModalPadding(value) {
    this.setBooleanAttribute('disableModalPadding', value);
  }
  get disableModalPadding() {
    return this.getBooleanAttribute('disableModalPadding');
  }

  encodedValue(value: string) {
    return (value ? ESAPI.encoder().encodeForHTML(value) : value);
  }

  /**
   * Disable page scrolling when the bottom sheet opens.
   * Reference link: https://tiny.amazon.com/8x28fyp7/codeamazpackAmazblobbd29src
   */
  lockPageScroll() {
    const body = document.querySelector('body')!;
    if (this.isMobile) {
      const { scrollTop } = document.documentElement;
      body.style.top = `-${scrollTop}px`;
      body.classList.add(PUIBottomSheet.SCROLL_DISABLED_CLASS);
    } else {
      const hasScrollbar = window.innerWidth > document.documentElement.clientWidth;
      body.style.overflow = 'hidden';
      if (hasScrollbar) {
        // To prevent flickers, set margin-right when scrollbar is visible
        body.style.setProperty('margin-right', '15px', 'important');
      }
    }
  }

  /**
   * Enable page scrolling when the bottom sheet closes.
   * Reference link: https://tiny.amazon.com/w1on22o6/codeamazpackAmazblobbd29src
   */
  unlockPageScroll() {
    const body = document.querySelector('body')!;
    if (this.isMobile) {
      const scrollY = document.body.style.top;
      body.classList.remove(PUIBottomSheet.SCROLL_DISABLED_CLASS);
      body.style.top = '';
      window.scrollTo(0, Math.abs(parseInt(scrollY || '0')));
    } else {
      body.style.removeProperty('overflow');
      body.style.removeProperty('margin-right');
    }
  }

  bottomSheetPressed(event: KeyboardEvent) {
    const bottomSheetContainer = this.shadowRoot!.querySelector('div[role="dialog"]')!;
    // Only listen to Enter key if the bottomSheetContainer is collapsed, else the contents inside the sheet will not be selected.
    if (bottomSheetContainer.classList.contains('collapsed') && event.keyCode === keyCodes.ENTER_KEYCODE ) {
      this.show();
    }
  }

  bottomSheetClicked(event: MouseEvent) {
    // Use target instead of current target so that we know which element actually received the event.
    const clickTarget = event.target! as HTMLElement;
    const bottomSheetLink = this.shadowRoot!.querySelector('#pui-bottom-sheet-link')!;
    const bottomSheetContainer = this.shadowRoot!.querySelector('div[role="dialog"]')!;
    if (bottomSheetLink.contains(clickTarget)) {
      const shouldOpen = this.dispatchEvent(new BottomSheetOpenEvent());
      if (!shouldOpen) {
        return;
      }
      this.show();
      this._triggerOpenCallback(event);
    } else if (bottomSheetContainer.id === clickTarget.id) {
      this._onCloseClick(event);
    }
  }

  changeLinkText(text: string) {
    const bottomSheetLink = this.shadowRoot!.querySelector('#pui-bottom-sheet-link')!;
    bottomSheetLink.innerHTML = `
      ${text}
    `;
  }

  show() {
    const bottomSheetContainer = this.shadowRoot!.querySelector('div[role="dialog"]')!;
    bottomSheetContainer.classList.remove('collapsed');

    // disable page scrolling
    this.lockPageScroll();

    // Focus on Close button when modal opens
    const closeButton = this.shadowRoot!.querySelector<HTMLButtonElement>('#pui-bottom-sheet-close-button')!;
    requestAnimationFrame(() => closeButton.focus());
  }

  hide() {
    const bottomSheetContainer = this.shadowRoot!.querySelector<HTMLElement>('div[role="dialog"]')!;
    bottomSheetContainer.classList.add('collapsed');

    // enable page scrolling
    this.unlockPageScroll();
    const hideEvent = new Event('hideBottomSheet');
    this.dispatchEvent(hideEvent);
  }

  _triggerCloseCallback(event: Event) {
    if (this.onClose) {
      this.onClose(event);
    }
  }

  _onCloseClick(event: Event) {
    const shouldClose = this.dispatchEvent(new BottomSheetCloseEvent());
    if (!shouldClose) {
      return;
    }
    const searchInput = this.querySelector<PUISearchInput>('pui-search-input');
    if (searchInput) {
      searchInput._minimize();
    }
    this.hide();
    this.focus();
    this._triggerCloseCallback(event);
  }

  _onPressinModal(event: KeyboardEvent) {
    const bottomSheetContainer = this.shadowRoot!.querySelector('div[role="dialog"]')!;
    const closeButton = this.shadowRoot!.querySelector<HTMLButtonElement>('#pui-bottom-sheet-close-button')!;
    if (!bottomSheetContainer.classList.contains('collapsed')) {
      // Check if modal consists of focusable/actionable element
      let isFocusableElemExists = false;
      const focusableElems = this.querySelectorAll('button, [tabindex]:not([tabindex="-1"]), a, input, select, textarea, [role="button"]');
      if (focusableElems.length > 0) {
        isFocusableElemExists = true;
      }
      if (event.key === 'Tab') {
        // If on last element or if no focusable elements in modal, focus back on close button
        if ((focusableElems[focusableElems.length - 1]?.contains(event.target as Node)) || !isFocusableElemExists) {
          closeButton.focus();
          event.preventDefault();
        }
      }
    }
  }

  _triggerOpenCallback(event: Event) {
    if (this.onOpen) {
      this.onOpen(event);
    }
  }

  /**
   * Helper function which holds common logic
   * that's called at the end of the render() to
   * set up the functionality for the bottom sheet.
   */
  setupRender() {
    this.onkeydown = (event) => {
      this.bottomSheetPressed(event);

      if (event.key === 'Escape') {
        this.hide();
      }
    };

    this.setAttribute('tabindex', '0');
    this.addEventListener('keydown', this._onPressinModal.bind(this));
  }

  render() {
    let {
      textSize, linkColor
    } = this;
    const {
      spacingTop,
      hideLink,
      emphasizeLink,
      hideClose,
      text,
      closeText,
      minHeight,
      isMShop,
      icon,
      iconWidth,
      iconHeight,
      iconHover,
      iconAltText,
      enableScrollbar,
      contentAriaRole,
      contentAriaLabel,
      disableModalPadding,
      maxWidth
    } = this;

    if (!includes(getTextSize(), this.textSize)) {
      textSize = PUIBottomSheet.DEFAULT_TEXT_SIZE;
    }
    textSize = `${textSize}-font`;

    if (includes(getLinkColor(), this.linkColor)) {
      linkColor = `bottom-sheet-link-${linkColor}`;
    }

    if (hideLink) {
      this.classList.add('pui-block');
    } else {
      this.classList.add('pui-inline-block');
    }

    const maxWidthInlineCssForModal = !!maxWidth ? `max-width:${maxWidth};` : '';
    const minHeightInlineCssForModal = !!minHeight ? `min-height:${minHeight};` : '';
    const modalPaddingInlineCss = !!disableModalPadding ? `modal-padding-none` : '';
    const linkIcon = iconHover ? `
      <div class="bottom-sheet-link-hover-target">
        <pui-icon imgClass="${icon}" width="${iconWidth}" height="${iconHeight}" ${iconAltText ? `alt="${iconAltText}"` : ''}>
        </pui-icon>
      </div>
    ` : `<pui-icon imgClass="${icon}" width="${iconWidth}" height="${iconHeight}" ${iconAltText ? `alt="${iconAltText}"` : ''}>
    </pui-icon>`;

    this.attachShadow({ mode: 'open' });
    this.shadowRoot!.innerHTML = `
      <div>
        <div role="button" id="pui-bottom-sheet-link" class="bottom-sheet-link ${emphasizeLink ? 'bottom-sheet-link-emphasized' : ''}
        ${linkColor} ${spacingTop} ${hideLink ? 'pui-hidden' : ''} ${textSize}">
          ${icon ? linkIcon : text}
        </div>
        <div id="pui-bottom-sheet-container" class="bottom-sheet-container collapsed ${modalPaddingInlineCss}"
          role="dialog" aria-labelledby="bottom-sheet-modal-content" aria-modal="true">
          <div id="pui-bottom-sheet-modal" style="${minHeightInlineCssForModal} ${maxWidthInlineCssForModal}"
            class="bottom-sheet-modal ${isMShop ? 'mshop' : ''} ${enableScrollbar ? '' : 'bottom-sheet-modal-disable-scrollbar'}">
            <button id="pui-bottom-sheet-close-button" class="bottom-sheet-close-button ${hideClose ? 'pui-hidden' : ''}">
              <pui-text id="pui-bottom-sheet-close-button-text" textColor="white" textAlign="right"></pui-text>
            </button>
            <div id="bottom-sheet-modal-content" class="${isMShop ? 'bottom-sheet-modal-content-mshop' : 'bottom-sheet-modal-content'} ${enableScrollbar ? 'bottom-sheet-modal-content-enable-scrollbar' : 'bottom-sheet-modal-content-disable-scrollbar'}"
            ${contentAriaRole ? `role="${contentAriaRole}"` : ''} ${contentAriaLabel ? `aria-label="${contentAriaLabel}"` : ''}">
              <slot>
                Bottom Sheet Inner Elements
              </slot>
            </div>
          </div>
        </div>
      </div>
    `;
    injectPUIStyles(this.shadowRoot!);

    // On first render, PUI Text isn't guaranteed to be defined yet (especially for server-side rendered pages).
    // This check ensures that the close button _is_ eventually updated in that case.
    // Previously we fixed this with an ambient import of PUI Text to ensure the
    // load order of bottom sheet happened after PUI Text, but IDEs show that
    // import as 'unused' and it's proven too fragile to rely on that import
    // being left alone. It's imported again for TS typings, but those imports
    // are elided at compile time and don't make it into the final bundle.
    if (!!customElements.get("pui-text")) {
      this._updateCloseButton(closeText);
    } else {
      customElements.whenDefined("pui-text").then(() => {
        this._updateCloseButton(closeText);
      })
    }

    this.setupRender();
    const closeButton = this.shadowRoot!.querySelector('#pui-bottom-sheet-close-button')!;
    closeButton.addEventListener('click', this._onCloseClick.bind(this));
  }

  attachEventListener() {
    const bottomSheetLinkButton = this.shadowRoot!.querySelector<HTMLElement>('#pui-bottom-sheet-link')!;
    bottomSheetLinkButton.onclick = event => this.bottomSheetClicked(event);
    this.shadowRoot!.querySelector<HTMLElement>("div[role='dialog']")?.addEventListener("click", (event) => this.bottomSheetClicked(event));
  }

  _updateCloseButton(closeText: string) {
    this.shadowRoot!.querySelector<PUIText>('#pui-bottom-sheet-close-button pui-text')!.setTextSafe(closeText || PUIBottomSheet.DEFAULT_CLOSE_TEXT);
  }
}

window.customElements.define('pui-bottom-sheet', PUIBottomSheet);
