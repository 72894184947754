import PUIBase from '../pui-base';
import productCardCss from '../../../css/_product-card.scss?inline';
import baseCss from '../../../css/_base.scss?inline';
import iconsCss from '../../../css/_icons.scss?inline';
import imagesCss from '../../../css/_images.scss?inline';

const ESAPI = require('node-esapi').encoder();

/**
 * Component for Medication Card
 *
 * This element contains slot sections to provide customized medication card
 *
 * ## Example
 *     <pui-product-card
 *       spacingTop="small"
 *       spacingBottom="small"
 *       leftsideimgsrc="img_src"
 *       leftsideimgwidth="60px"
 *       leftsideimgheight="60px"
 *       noshadow
 *     >
 *        <div slot="pui-product-card-left-side-gutter">
 *            <pui-text input="Example for left side gutter"></pui-text>
 *        </div>
 *    </pui-product-card>
 *
 * This will render customized text message instead of medication image
 */
class PUIProductCard extends PUIBase {
  constructor() {
    super();
    this.defaultClass = 'pui-product-card';
  }

  connectedCallback() {
    super.connectedCallback();
    this.render();
  }

  attributeChangedCallback() {
    this.render();
  }

  static get observedAttributes() {
    return [
      'fullwidth',
      'noshadow',
      'noborder',
      'leftsideimgsrc',
      'leftsideimgwidth',
      'leftsideimgheight',
      'minwidth',
      'maxwidth',
      'nospacing',
      'nopadding',
    ];
  }

  /**
   * @classprop {string} fullWidth This is the class name which determines the whether or not this
   * card needs to fill the width of the viewport
   */
  get fullwidth() {
    return this.getBooleanAttribute('fullwidth');
  }

  set fullwidth(value) {
    this.setBooleanAttribute('fullwidth');
  }

  get noshadow() {
    return this.getBooleanAttribute('noshadow');
  }

  set noshadow(value) {
    this.setBooleanAttribute('noshadow', value);
  }

  get noborder() {
    return this.getBooleanAttribute('noborder');
  }

  set noborder(value) {
    this.setBooleanAttribute('noborder', value);
  }

  get leftsideimgsrc() {
    return this.getAttribute('leftsideimgsrc');
  }

  set leftsideimgsrc(value) {
    this.setAttribute('leftsideimgsrc', this.encodedValue(value));
  }

  get leftsideimgwidth() {
    return this.getAttribute('leftsideimgwidth');
  }

  set leftsideimgwidth(value) {
    this.setAttribute('leftsideimgwidth', this.encodedValue(value));
  }

  get leftsideimgheight() {
    return this.getAttribute('leftsideimgheight');
  }

  set leftsideimgheight(value) {
    this.setAttribute('leftsideimgheight', this.encodedValue(value));
  }

  get leftsideiconclass() {
    return this.getAttribute('leftsideiconclass');
  }

  set leftsideiconclass(value) {
    this.setAttribute('leftsideiconclass', this.encodedValue(value));
  }

  get minwidth() {
    return this.getAttribute('minwidth');
  }

  set minwidth(value) {
    this.setAttribute('minwidth', value);
  }

  get maxwidth() {
    return this.getAttribute('maxwidth');
  }

  set maxwidth(value) {
    this.setAttribute('maxwidth', value);
  }

  get noSpacing() {
    return this.getBooleanAttribute('noSpacing');
  }

  set noSpacing(value) {
    this.setBooleanAttribute('noSpacing', value);
  }

  get noPadding() {
    return this.getBooleanAttribute('noPadding');
  }

  set noPadding(value) {
    this.setBooleanAttribute('noPadding', value);
  }

  encodedValue(value) {
    return (value ? ESAPI.encoder().encodeForHTML(value) : value);
  }

  render() {
    this.classList.add('pui-block');
    this.classList.add(this.defaultClass);
    const {
      leftsideimgsrc,
      leftsideimgwidth,
      leftsideimgheight,
      leftsideiconclass,
      fullwidth,
      noshadow,
      noborder,
      minwidth,
      maxwidth,
    } = this;

    if (fullwidth) {
      this.classList.add(fullwidth ? 'full-width' : '');
    }
    if (noshadow) {
      this.classList.add(noshadow ? 'no-shadow' : '');
    }
    if (noborder) {
      this.classList.add(noborder ? 'no-border' : '');
    }
    const noSpacing = this.noSpacing ? "pui-product-card-no-spacing" : "";
    const noPadding = this.noPadding ? "pui-product-card-no-padding" : "";

    this.style.minwidth = minwidth || '';
    this.style.maxwidth = maxwidth || '';

    let leftSideImg = '';
    if (leftsideimgsrc) {
      leftSideImg = `
        <img src="${leftsideimgsrc}" width="${leftsideimgwidth}" height="${leftsideimgheight}" class="${noSpacing} ${noPadding}"/> 
      `;
    } else if (leftsideiconclass) {
      leftSideImg = `
        <pui-icon imgClass="${leftsideiconclass}" width="${leftsideimgwidth}" height="${leftsideimgheight}" class="${noSpacing} ${noPadding}"></pui-icon>
      `;
    }


    if (!this.shadowRoot) {
      this.attachShadow({
        mode: 'open',
      });
    }
    this.shadowRoot.innerHTML = `
      <style>
        ${productCardCss}
        ${baseCss}
        ${imagesCss}
        ${iconsCss}
      </style>
      <div class="pui-product-card-shadow ${noSpacing} ${noPadding}" part="pui-product-card-shadow">
        <div class="pui-product-card-shadow-primary ${noSpacing} ${noPadding}">
          <slot name="pui-product-card-left-side-gutter">
            ${leftSideImg}
          </slot>
          <div class="pui-product-card-shadow-primary-slots ${noSpacing} ${noPadding}">
            <slot>
              Inner Elements
            </slot>
          </div>
          <slot name="pui-product-card-right-side"></slot>
        </div>
        <slot name="pui-product-card-primary-bottom"></slot>
        <slot name="pui-product-card-primary-bottom-two"></slot>
        <slot name="pui-product-card-secondary"></slot>
      </div>
    `;
  }
}

window.customElements.define('pui-product-card', PUIProductCard);
