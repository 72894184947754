import PUIBase from './pui-base';

/**
 * This is a component which creates a category tab.
 */
class PUICategoryTab extends PUIBase {
  constructor() {
    super();
    this.defaultClass = 'pui-category-tab';
  }

  connectedCallback() {
    super.connectedCallback();
    this.render();
  }

  attributeChangedCallback() {
    this.render();
  }

  static get observedAttributes() {
    return ['title', 'selected'];
  }

  /**
   * The title attribute is the text displayed on the category tab.
   */
  get title() {
    return this.getAttribute('title') || '';
  }

  set title(value) {
    this.setAttribute('title', value);
  }

  /**
   * Boolean attribute to determine if the category tab is selected or not
   */
  get selected() {
    return this.getBooleanAttribute('selected');
  }

  set selected(value) {
    this.setBooleanAttribute('selected', value);
  }


  render() {
    this.innerHTML = '';
    this.classList.add('pui-category-tab');

    const {
      title,
      selected,
    } = this;

    this._container = document.createElement('button');
    this._container.classList.add('container');

    this._title = document.createElement('pui-text');
    this._title.setAttribute('input', title);
    this._title.setAttribute('textSize', 'extra-large');
    this._title.setAttribute('textAlign', 'center');
    this._title.classList.add('title');

    this._container.appendChild(this._title);

    if (selected) {
      this._title.setAttribute('textColor', 'link');

      this._selectedBar = document.createElement('div');
      this._selectedBar.classList.add('selected-bar');
      this._container.appendChild(this._selectedBar);
    } else {
      this._container.classList.add('unselected');
    }

    this.appendChild(this._container);
  }
}

window.customElements.define('pui-category-tab', PUICategoryTab);
