import getFontWeights from '../attributeValues/fontWeight';
import getFontStyles from '../attributeValues/fontStyle';
import getOverflowStyle from '../attributeValues/overflowStyle';
import getTextAlign from '../attributeValues/textAlign';
import getTextColor from '../attributeValues/textColor';
import getTextDecorationLine from '../attributeValues/textDecorationLine';
import getTextSize from '../attributeValues/textSize';
import getVariant from '../attributeValues/variant';
import includes from '../functions/includes';
import PUIBase from './pui-base';

const ESAPI = require('node-esapi');

// Extend the LitElement base class
export default class PUIText extends PUIBase {
  /** Helper method to create PUIText nodes that contain the given text */
  static Create(text) {
    const node = new PUIText();
    if (text) {
      node.setTextSafe(text);
    }
    return node;
  }

  constructor() {
    super();
    this.defaultClass = 'pui-text';
    this.defaultInput = '';
    this.defaultTextSize = 'medium';
    this.defaultFontWeight = 'normal';
    this.defaultFontStyle = 'normal';
    this.defaultTextAlign = 'left';
    this.defaultOverflowStyle = 'wrap';
    this.defaultTextColor = 'black';
    this.defaultTextDecorationLine = 'none';
  }

  connectedCallback() {
    super.connectedCallback();
    this.render();
  }

  attributeChangedCallback() {
    if (!this.isConnected) return;
    super.attributeChangedCallback();
    this.render();
  }

  static get observedAttributes() {
    return [
      ...super.observedAttributes,
      'input', 'textsize', 'fontweight', 'fontstyle', 'textalign', 'overflowstyle', 'textcolor', 'variant', 'textdecorationline',
    ];
  }

  /**
     * @classprop {string} input This is the class name which determines the element text
     *
     */
  get input() {
    return this.getAttribute('input') || '';
  }

  set input(value) {
    this.setAttribute('input', value);
  }

  /**
     * @classprop {string} textSize This is the class name which determines the text textSize
     *
     */
  get textSize() {
    return this.getAttribute('textSize') || '';
  }

  set textSize(value) {
    this.setAttribute('textSize', value);
  }

  get fontWeight() {
    return this.getAttribute('fontWeight') || '';
  }

  set fontWeight(value) {
    this.setAttribute('fontWeight', value);
  }

  get fontStyle() {
    return this.getAttribute('fontStyle') || '';
  }

  set fontStyle(value) {
    this.setAttribute('fontStyle', value);
  }

  get textAlign() {
    return this.getAttribute('textAlign') || '';
  }

  set textAlign(value) {
    this.setAttribute('textAlign', value);
  }

  /**
     * @classprop {string} overflowStyle This is the class name which determines what happens when text
     * overflows out of bounding box.  Currently, supports 'wrap' and 'ellipsis'
     *
     */
  get overflowStyle() {
    return this.getAttribute('overflowStyle') || '';
  }

  set overflowStyle(value) {
    this.setAttribute('overflowStyle', value);
  }

  /**
   * @classprop {string} textColor This is the class name which determines the text textColor
   *
   */
  get textColor() {
    return this.getAttribute('textColor') || '';
  }

  set textColor(value) {
    this.setAttribute('textColor', value);
  }

  /**
   * @classprop {string} textDecorationLine This is the class name
   * which determines the text's textDecorationLine.
   *
   */
  get textDecorationLine() {
    return this.getAttribute('textDecorationLine') || '';
  }

  set textDecorationLine(value) {
    return this.setAttribute('textDecorationLine', value);
  }

  get inline() {
    return this.getBooleanAttribute('inline');
  }

  set inline(value) {
    return this.setBooleanAttribute('inline', value);
  }

  getText() {
    return this.input;
  }

  /** Unsafely inject text into the DOM
   *
   * @deprecated Using this function may expose you to an XSS vector. Use
   * `encode` or `setTextSafe()` instead.
   */
  setText(text) {
    this._innerText.innerHTML = text;
  }

  /** Given a potentially XSS-unsafe string, safely insert it into this text block */
  setTextSafe(/** @type {string} */ text) {
    const el = document.createTextNode(text);
    this.input = el.textContent;
  }

  /**
   * @type {"price"}
   * @classprop {string} variant
   */
  get variant() {
    return this.getAttribute('variant') || null;
  }

  set variant(value) {
    this.setAttribute('variant', value);
  }

  get encode() {
    return this.getBooleanAttribute('encode');
  }

  set encode(value) {
    return this.setBooleanAttribute('encode', value);
  }

  render() {
    let {
      textSize,
      fontWeight,
      fontStyle,
      textAlign,
      overflowStyle,
      textColor,
      textDecorationLine,
      inline,
      encode,
    } = this;
    const { variant } = this;
    this.innerHTML = '';

    if (!includes(getTextSize(), this.textSize)) {
      textSize = this.defaultTextSize;
    }

    if (includes(getVariant(), variant)) {
      textSize = `${variant}-${textSize}`;
    }

    textSize = `${textSize}-font`;

    if (!includes(getFontWeights(), this.fontWeight)) {
      fontWeight = this.defaultFontWeight;
    }
    fontWeight = `${fontWeight}-font-weight`;

    if (!includes(getFontStyles(), this.fontStyle)) {
      fontStyle = this.defaultFontStyle;
    }
    fontStyle = `${fontStyle}-font-style`;

    if (!includes(getTextAlign(), this.textAlign)) {
      textAlign = this.defaultTextAlign;
    }
    textAlign = `text-align-${textAlign}`;

    if (!includes(getOverflowStyle(), this.overflowStyle)) {
      overflowStyle = this.defaultOverflowStyle;
    }
    overflowStyle = `${overflowStyle}-overflow`;

    if (!includes(getTextColor(), this.textColor)) {
      textColor = this.defaultTextColor;
    }
    textColor = `${textColor}-color`;

    if (!includes(getTextDecorationLine(), this.textDecorationLine)) {
      textDecorationLine = this.defaultTextDecorationLine;
    }
    textDecorationLine = `${textDecorationLine}-text-decoration-line`;

    let elementType = 'div';
    if (inline) {
      elementType = 'span';
      this.classList.add('pui-inline');
    } else {
      this.classList.add('pui-block');
    }
    this._innerText = document.createElement(elementType);
    this._innerText.classList.add(this.defaultClass);
    this._innerText.classList.add(textSize);
    this._innerText.classList.add(fontWeight);
    this._innerText.classList.add(fontStyle);
    this._innerText.classList.add(textAlign);
    this._innerText.classList.add(overflowStyle);
    this._innerText.classList.add(textColor);
    this._innerText.classList.add(textDecorationLine);
    if (encode) {
      this._innerText.innerHTML = this.input ? ESAPI.encoder().encodeForHTML(this.input) : ESAPI.encoder().encodeForHTML(this.defaultInput);
    } else {
      this._innerText.innerHTML = this.input ? this.input : this.defaultInput;
    }
    // For accessibility of strikethrough, it's better to use semantic HTML (like <s> or <del>) instead of just having visual styling but today
    // screen readers doesn't support these semantic tags so we have to use sr-only class to supply descriptive text for screen reader.
    // See https://webaim.org/techniques/textlayout/
    if ('line-through' === this.textDecorationLine) {
      this._innerText.innerHTML = `${this._innerText.innerHTML}<span class="sr-only">struckthrough</span>`;
    }

    this.appendChild(this._innerText);

    if (!this.input) {
      this.hide();
    } else {
      this.show();
    }
  }
}
// Register the new element with the browser.
window.customElements.define('pui-text', PUIText);
