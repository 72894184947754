// Differentiated sizes are sizes where the currency symbol, whole value, and
// fractional value of the price are of different font sizes. Uniform price
// sizes are simply the opposite — all parts of the price are of the same
// font size. This is meant to resemble the size attribute on AUI price.

const uniformPriceSize = ['base'];

const differentiatedPriceSize = ['large'];

const priceSize = [...uniformPriceSize, ...differentiatedPriceSize];


export function getUniformPriceSize() {
  return uniformPriceSize;
}

export function getDifferentiatedPriceSize() {
  return differentiatedPriceSize;
}

export default function getPriceSize() {
  return priceSize;
}
