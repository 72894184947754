
// eslint-disable-next-line import/no-unresolved
import transitionPageCSS from '../../css/_transition.scss?inline';

class PUITransitionPage extends HTMLElement {
  constructor() {
    super();
    this.defaultClass = 'pui-transition-page';
  }

  connectedCallback() {
    this.render();
  }


  render() {
    const shadowRoot = this.attachShadow({ mode: 'open' });
    this.classList.add(this.defaultClass);
    this.classList.add('pui-transition-hidden');
    shadowRoot.innerHTML = `
        <style>
          :host {
            display: block;
          }
          ${transitionPageCSS}
        </style>
        <div>
          <slot> Inner Elements </slot>
        </div>
      `;
  }
}

window.customElements.define('pui-transition-page', PUITransitionPage);
