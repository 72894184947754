import PUIBase from './pui-base';

/**
 * The base page component that can be used to render various styles of pages.
 * Responsibilities:
 * - Abstraction for a lot of the common elements between pages (e.g. Back button, card, footer)
 * - Handle page level errors
 * - Allow for form factor configuration (MOBILE, DESKTOP, TABLET)
 * - Page transition animation/logic
 */
export default class PUIPage extends PUIBase {
  /**
   * Determines the page template to use.
   * 'card-form' is primary used on the signup pages, while 'standard-form' is used in the dashboard page
   * Possible Values: 'card-form' | 'standard-form'
   */
  get pageStyle() {
    return this.getAttribute('pageStyle') || '';
  }

  set pageStyle(value) {
    this.setAttribute('pageStyle', value);
  }

  /**
   * Determines where this should go on pushing the back button.
   * If not present, the button will not be shown
   */
  get backUrl() {
    return this.getAttribute('backUrl') || '';
  }

  set backUrl(value) {
    this.setAttribute('backUrl', value);
  }

  _render(content = {}) {
    const {
      pageStyle,
      backUrl,
      embeddable,
      isMShop,
      disablePageContainer,
      useHistoryBack,
    } = this.config;
    this.classList.add('pui-block');

    let backButton = '';
    if (!embeddable) {
      if (backUrl) {
        backButton = `<pui-back url=${backUrl} spacingBottom="medium"></pui-back>`;
      } else if (useHistoryBack) {
        backButton = `<pui-back spacingBottom="medium"></pui-back>`;
      }
    }

    if (pageStyle === 'card-form') {
      this.innerHTML = `
        <pui-section id="apex-content-container" class="content-container" ${disablePageContainer ? '' : 'pageContainer'}>
            <pui-card>
              ${content.main}
              <pui-section class="footer-desktop">
                ${content.footerDesktop}
              </pui-section>
            </pui-card>
        </pui-section>

        <pui-section class="footer-mobile footer" ${isMShop ? 'isMShop' : ''}>
          ${content.footerMobile}
        </pui-section>
      `;
    } else if (pageStyle === 'standard-form') {
      this.innerHTML = `
        <pui-section id="apex-content-container" class="${!embeddable ? 'content-container' : ''}" ${disablePageContainer ? '' : 'pageContainer'}>
          ${backButton}
          ${content.main}
          <pui-section class="footer-desktop">
            ${content.footerDesktop}
          </pui-section>
        </pui-section>

        <pui-section class="footer-mobile footer" ${isMShop ? 'isMShop' : ''}>
          ${content.footerMobile}
        </pui-section>
      `;
    } else if (pageStyle === 'standard-form-no-footer') {
      this.innerHTML = `
        <pui-section id="apex-content-container" class="${!embeddable ? 'content-container' : 'embeddable'}" ${disablePageContainer ? '' : 'pageContainer'}>
          ${backButton}
          ${content.main}
        </pui-section>
      `;
    } else if (pageStyle === 'blank-form') {
      if (content.footerDesktop && content.footerMobile) {
        this.innerHTML = `
          <pui-section id="apex-content-container" width="auto" padding="medium" ${disablePageContainer ? '' : 'pageContainer'}
          class=${isMShop ? 'content-container-blank-form-mshop' : 'content-container-blank-form'}>
            ${content.main}
            <pui-section class="footer-desktop">
              ${content.footerDesktop}
            </pui-section>
          </pui-section>

          <pui-section class="footer-mobile footer" ${isMShop ? 'isMShop' : ''}>
            ${content.footerMobile}
          </pui-section>
        `;
      } else {
        this.innerHTML = `
        <pui-section id="apex-content-container" class="content-container" width="auto" padding="medium" ${disablePageContainer ? '' : 'pageContainer'}>
          ${content.main}
        </pui-section>
      `;
      }
    }
  }
}
