// Fixes the Flash of Unstyled Content (FOUC) issue with web components
window.addEventListener('DOMContentLoaded', () => {
  document.body.style.visibility = 'visible';
});

require('../../src/css/styles.scss');

// Common components
require('../../src/js/common');

// Separate entry for each program to have additional optimization
require('../../src/js/components/pui-component-loader');
