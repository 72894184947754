import PUIBase from './pui-base';

class PUITabPage extends PUIBase {
  constructor() {
    super();
    this.defaultClass = 'pui-tab-page';
  }

  connectedCallback() {
    super.connectedCallback();
    this.render();
  }

  static get observedAttributes() { return ['status']; }

  get status() {
    return this.getAttribute('status') || '';
  }

  set status(value) {
    this.setAttribute('status', value);
  }

  render() {
    const {
      status,
    } = this;

    this.classList.add(this.defaultClass);
    if (status) {
      this.classList.add(`tab-page-${status}`);
    }

    this.attachShadow({ mode: 'open' });
    this.shadowRoot.innerHTML = `
        <style>
          :host {
            display: block;
          }
        </style>
        <slot>
          Tab Page Slot
        </slot>
    `;
  }
}

customElements.define('pui-tab-page', PUITabPage);
