import includes from '../functions/includes';
import getTextSize from '../attributeValues/textSize';
import getFontWeights from '../attributeValues/fontWeight';
import getTextColor from '../attributeValues/textColor';
import getTextAlign from '../attributeValues/textAlign';
import PUIBase from './pui-base';

const MAX_HEADING_LEVEL = 1;
const MIN_HEADING_LEVEL = 6;

function sanitizeHeadingLevel(headingLevel) {
  const parsed = parseInt(headingLevel);
  if (parsed >= MAX_HEADING_LEVEL && parsed <= MIN_HEADING_LEVEL) {
    return headingLevel;
  }
  return undefined;
}

/**
 * @element pui-heading
 */
export default class PUIHeading extends PUIBase {
  constructor() {
    super();
    this.defaultClass = 'pui-heading';
    this.defaultInput = '';
    this.defaultTextSize = 'medium';
    this.defaultTextColor = 'black';
    this.defaultSpacingTop = 'no-spacing-top';
    this.defaultFontWeight = 'normal';
    this.defaultTextAlign = 'left';
  }

  connectedCallback() {
    super.connectedCallback();
    this.render();
  }

  attributeChangedCallback() {
    if (!this.isConnected) return;
    this.render();
  }

  static get observedAttributes() { return ['input', 'textSize', 'textAlign', 'fontWeight', 'headingLevel']; }

  /**
   * The text for this heading component
   * @type {string}
   * @attr
   */
  get input() {
    return this.getAttribute('input') || '';
  }

  set input(value) {
    this.setAttribute('input', value);
  }

  /**
   * The text for this heading component
   * @type {"small"|"medium"|"large"|"extra-large"|"double-extra-large"}
   * @attr [textSize=medium]
   */
  get textSize() {
    return this.getAttribute('textSize') || '';
  }

  set textSize(value) {
    this.setAttribute('textSize', value);
  }

  /**
   * The font weight for this heading component
   * @type {"light"|"normal"|"large"|"bold"}
   * @attr [fontWeight=normal]
   */
  get fontWeight() {
    return this.getAttribute('fontWeight') || '';
  }

  set fontWeight(value) {
    this.setAttribute('fontWeight', value);
  }

  /**
   * Used to set the color for the button label
   * @type {"black"|"grey"|"white"|"link"|"red"}
   * @attr
   */
  get textColor() {
    return this.getAttribute('textColor') || '';
  }

  set textColor(value) {
    this.setAttribute('textColor', value);
  }

  /** @deprecated Do not use with raw strings accepted from user input. Use setTextSafe instead. */
  setText(text) {
    this._innerText.innerHTML = text;
  }

  setTextSafe(text) {
    this._innerText.textContent = text;
  }

  /**
   * The text alignment for this heading component
   * @type {"left"|"center"|"right"}
   * @attr [textSize=left]
   */
  get textAlign() {
    return this.getAttribute('textAlign') || '';
  }

  set textAlign(value) {
    this.setAttribute('textAlign', value);
  }

  /**
   * HTML heading level (H1-H6)
   * @type {string}
   * @attr [headingLevel=1]
   */
  get headingLevel() {
    return this.getAttribute('headingLevel') || '';
  }

  set headingLevel(value) {
    this.setAttribute('headingLevel', value);
  }

  render() {
    let {
      textSize,
      fontWeight,
      textColor,
      textAlign,
      input,
    } = this;
    this.innerHTML = '';

    if (!includes(getTextSize(), this.textSize)) {
      textSize = this.defaultTextSize;
    }
    textSize = `${textSize}-heading-font`;

    if (!includes(getFontWeights(), this.fontWeight)) {
      fontWeight = this.defaultFontWeight;
    }
    fontWeight = `${fontWeight}-font-weight`;

    if (!includes(getTextColor(), this.textColor)) {
      textColor = this.defaultTextColor;
    }
    textColor = `${textColor}-color`;

    if (!includes(getTextAlign(), this.textAlign)) {
      textAlign = this.defaultTextAlign;
    }
    textAlign = `text-align-${textAlign}`;

    const label = input || this.defaultInput;

    this.classList.add('pui-block');
    this.classList.add(textAlign);

    const headingLevel = sanitizeHeadingLevel(this.headingLevel);
    if (headingLevel) {
      this._innerText = document.createElement('h' + headingLevel);
    } else {
      this._innerText = document.createElement('span');
      this._innerText.role = 'heading';
    }

    this._innerText.classList.add(this.defaultClass);
    this._innerText.classList.add(textSize);
    this._innerText.classList.add(fontWeight);
    this._innerText.classList.add(textColor);
    this._innerText.innerHTML = label;

    this.appendChild(this._innerText);
  }
}

window.customElements.define('pui-heading', PUIHeading);
