import PUIBase from './pui-base';
import keyCodes from '../constants/keyCodes';

class PUICarousel extends PUIBase {
  constructor() {
    super();
    this.defaultClass = 'pui-carousel';
    this.defaultSize = 'small';
    this.defaultSpacingTop = 'medium';
  }

  connectedCallback() {
    super.connectedCallback();
    this.render();
  }

  get flowDirection() {
    return this.getAttribute('flowDirection');
  }

  set flowDirection(value) {
    this.setAttribute('flowDirection', value);
  }

  get width() {
    return this.getAttribute('width');
  }

  set width(value) {
    this.setAttribute('width', value);
  }

  get hasBadges() {
    return this.getBooleanAttribute('hasBadges');
  }

  set hasBadges(value) {
    this.setBooleanAttribute('hasBadges', value);
  }

  getElementValue() {
    return this.elementValue;
  }

  render() {
    this.classList.add('pui-block');
    this.classList.add(this.defaultClass);
    this.classList.add(`carousel-${this.flowDirection}`);
    const {
      width,
      hasBadges,
    } = this;
    if (width === 'fit-content') {
      this.classList.add('pui-carousel-fit-content');
    } else if (width) {
      this.setAttribute('style', `width: ${width}`);
    } else {
      this.classList.add('pui-carousel-full-width');
    }
    this.elementValue = null;

    this.attachShadow({ mode: 'open' });
    this.shadowRoot.innerHTML = `
        <slot>
          Inner Cards
        </slot>
    `;

    this.shadowRoot.lastElementChild.onclick = (event) => {
      this.carouselClicked(event);
    };
    this.shadowRoot.lastElementChild.onkeyup = (event) => {
      this.carouselPressed(event);
    };
    if (hasBadges) {
      this.classList.add('pui-carousel-badges');
    }
  }

  carouselPressed(event) {
    if (event.keyCode === keyCodes.ENTER_KEYCODE) {
      this.carouselClicked(event);
    }
  }


  carouselClicked(event) {
    const carouselElements = this.getElementsByTagName('pui-carousel-element');
    let elementClickedIndex = -1;
    let currentFocusedIndex = -1;
    for (let i = 0; i < carouselElements.length; i += 1) {
      const element = carouselElements[i];
      element.removeAttribute('selected');
      if (element.contains(event.target)) {
        elementClickedIndex = i;
      }
      if (element.classList.contains('focused')) {
        currentFocusedIndex = i;
      }
    }
    if (elementClickedIndex >= 0 && elementClickedIndex !== currentFocusedIndex) {
      const selectedElement = carouselElements[elementClickedIndex];
      if (!selectedElement.disabled) {
        selectedElement.select();
        selectedElement.setAttribute('selected', true);
        this.elementValue = selectedElement.elementValue;
        if (currentFocusedIndex >= 0) {
          carouselElements[currentFocusedIndex].deselect();
        }
        // Call callback function for selected element
        if (this.onCarouselSelect) {
          this.onCarouselSelect(this.elementValue);
        }
      }
    }
  }
}

window.customElements.define('pui-carousel', PUICarousel);
