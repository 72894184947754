import { Urls, KYANITE_PREFIX } from '../../constants';
import Request from '../../networking/request';
import PUIBase from '../pui-base';
import keyCodes from '../../constants/keyCodes';
import SubNavWidgets from './pui-sub-nav-widgets';
import { getInitials } from '../../functions/nameUtils';

export default class PUISubNav extends PUIBase {
  constructor() {
    super();
    this.defuaultImgClassLogo = 'sub-nav-logo-img';
  }

  connectedCallback() {
    super.connectedCallback();
    this._fetchSubNavData();
  }

  attributeChangedCallback() {
    this._fetchSubNavData();
  }

  static get observedAttributes() { return ['imgclasslogo', 'isdashboard', 'isreadonly', 'isprofilereadonly', 'showhealthflyout']; }

  get showMembershipsView() {
    return this.getBooleanAttribute('showMembershipsView');
  }

  set showMembershipsView(value) {
    this.setBooleanAttribute('showMembershipsView', value);
  }

  get showBookVisit() {
    return this.getBooleanAttribute('showBookVisit');
  }

  set showBookVisit(value) {
    this.setBooleanAttribute('showBookVisit', value);
  }

  /**
   * @classprop {string} for pui-icon imgClass to show a logo (left side) of the navbar.
   */
  get imgClassLogo() {
    return this.getAttribute('imgClassLogo') || 'sub-nav-logo-img';
  }

  set imgClassLogo(value) {
    this.setAttribute('imgClassLogo', value);
  }

  /**
   * Specifies whether or not to allow changing profiles
   */
  get isReadOnlyProfile() {
    return this.getBooleanAttribute('isProfileReadOnly');
  }

  set isReadOnlyProfile(value) {
    this.setBooleanAttribute('isProfileReadOnly', value);
  }

  /**
   * Specifies whether or not to have full funcionality of sub nav or 
   * just a read only profile bar
   */
  get isReadOnlyProfileAwarenessBar() {
    return this.getBooleanAttribute('isReadOnly');
  }

  set isReadOnlyProfileAwarenessBar(value) {
    this.setBooleanAttribute('isReadOnly', value);
  }

  /**
   * Used for toggling the spinner modal when switching profiles
   */
  get isProfileSwitchLoading() {
    return this.getBooleanAttribute('isProfileSwitchLoading');
  }

  set isProfileSwitchLoading(value) {
    this.setBooleanAttribute('isProfileSwitchLoading', value);
  }

  /**
   * Specifies whether or not to use dashboard styling
   */
  get isDashboard() {
    return this.getBooleanAttribute('isDashboard');
  }

  set isDashboard(value) {
    this.setBooleanAttribute('isDashboard', value);
  }

  /**
   * Specifies whether or not to use health flyout. (Health flyout is suppose to be only on the landing page)
   */
  get showHealthFlyout() {
    return this.getBooleanAttribute('showHealthFlyout');
  }

  set showHealthFlyout(value) {
    this.setBooleanAttribute('showHealthFlyout', value);
  }

  get isMshopMode() {
    return this.getBooleanAttribute('isMshop');
  }

  set isMshopMode(value) {
    this.setBooleanAttribute('isMshop', value);
  }

  get subnavDataUrl() {
    return this.getAttribute('subnavDataUrl') || Urls.GET_SUB_NAV_DATA_URL;
  }

  set subnavDataUrl(value) {
    this.setAttribute('subnavDataUrl', value);
  }

  get zipCode(){
    return this.getAttribute('zipCode') || '';
  }

  set zipCode(value){
    return this.setAttribute('zipCode', value);
  }

  get skipGlowReload(){
    return this.getAttribute('skipGlowReload') || "false";
  }

  set skipGlowReload(value){
    return this.setAttribute('skipGlowReload', value);
  }

  get glowCsrfToken(){
    return this.getAttribute('glowCsrfToken') || '';
  }

  set glowCsrfToken(value){
    return this.setAttribute('glowCsrfToken', value);
  }

  increaseCartCount(n = 1) {
    if (this._cartIcon) {
      this._cartIcon.increase(n);
    }
  }

  decreaseCartCount(n = 1) {
    if (this._cartIcon) {
      this._cartIcon.decrease(n);
    }
  }

  setCartCount(itemCount) {
    if (this._cartIcon) this._cartIcon.totalItems = itemCount;
  }

  refreshCartCount() {
    Request.get(Urls.GET_CART_COUNT_URL).then((response) => {
      const { itemCount } = response;
      this.setCartCount(itemCount);
    });
  }

  get isGlowEnabled() {
    const { enabledWidgets, glowPlacementAllowed } = this._subNavData;
    return glowPlacementAllowed && enabledWidgets?.includes(SubNavWidgets.GLOW);
  }

  _fetchSubNavData() {
    Request.get(this.subnavDataUrl).then((response) => {
      this._subNavData = response;
      this._render();
    }).catch((e) => {
      console.log(e);
    });
  }

  _render() {
    const { isMShop } = this;
    this.classList.add('pui-block');

    const baseHTML = `
        ${this.isReadOnlyProfileAwarenessBar ? '' : `<pui-section class="pui-sub-nav-container" fullWidth flowDirection="horizontal" 
        mainAxisArrangement="space-between" secondaryAxisArrangement="center" ${isMShop ? 'isMShop' : ''}>
            ${isMShop ? '<pui-text textColor="white" input="Menu" spacingLeft="medium"></pui-text>' : `<pui-icon imgClass="${this.imgClassLogo}" 
            class="pui-sub-nav-logo"></pui-icon>`}
        </pui-section>`}
        <pui-section id="pui-sub-nav-profile-awareness-bar-container" 
            centerlane="true" 
            flowDirection="horizontal" 
            mainAxisArrangement="space-between" 
            secondaryAxisArrangement="center" 
            ${isMShop ? 'isMShop' : ''}>
          </pui-section>
    `;

    this.innerHTML = baseHTML;
    this._subNavContainer = this.querySelector('.pui-sub-nav-container');
    this._subNavProfileAwarenessBarContainer = this.querySelector('#pui-sub-nav-profile-awareness-bar-container');
    this._subNavProfileAwarenessBarForDashboard = this.querySelector('#pui-sub-nav-profile-awareness-bar-container-for-dashboard');

    this._renderSubNavContent();
  }

  _switchProfile(pid, friendlyName) {
    this.isProfileSwitchLoading = true;
    this.pendingProfileSwitchName = friendlyName;
    this._profileSwitchLoadingBottomSheetContent.innerHTML = this._getSubNavProfileSwitchLoadingBottomSheetContent();
    if (!this.isDashboard) {
      // Dashboard doesn't have ProfileAwarenessBottomSheet
      this._hideProfileAwarenessBottomSheet();
    }
    this._openProfileSwitchLoadingBottomSheet();

    Request.post(Urls.SUB_NAV_PROFILE_SWITCH, {
      returnUrl: Urls.DASHBOARD_URL,
      personId: pid,
    })
      .then((response) => {
        this.isProfileSwitchLoading = false;
        window.location.href = response.redirectUrl;
      });
  }

  _keyListener(event, callback) {
    if (event.keyCode === keyCodes.ENTER_KEYCODE) {
      callback();
    }
  }

  _addProfile() {
    window.location.href = Urls.ADD_DEPENDENT_URL;
  }

  _renderSubNavContent() {
    const {
      loggedIntoPharmacyProfile,
      requestFromMShop,
      profileAwarenessEnabled,
      signupComplete,
      hstPpvMShopSubnavWeblab,
      carePartnersWeblab,
      newPharmacySubNavLogoWeblab
    } = this._subNavData;

    // Weblab-gated new pharmacy logo. See: https://sim.amazon.com/issues/SPS-22371
    this.isNewPharmacyLogoEnabled = newPharmacySubNavLogoWeblab == "T1";

    // Will use isMShop flag if it is available and if it’s not available, then check the pulled in value
    this.isMShop = this.isMShop || requestFromMShop;

    if (this.isMShop) {
      if (!this.isReadOnlyProfileAwarenessBar) {
        this._subNavContainer.classList.add('mshop');
      }
      if (profileAwarenessEnabled) {
        this._subNavProfileAwarenessBarContainer.classList.add('mshop');
      }
    }

    if (!this.isReadOnlyProfileAwarenessBar) {
      const subNavLeftHTML = profileAwarenessEnabled ? this._getPABSupportedSubNavLeftHTML() : this._getSubNavLeftHTML();
      const subNavRightHTML = profileAwarenessEnabled ? this._getPABSupportedSubNavRightHTML() : this._getSubNavRightHTML();

      this._subNavContainer.innerHTML = this.showMembershipsView && !this.showBookVisit ? `${subNavLeftHTML}` : `${subNavLeftHTML} ${subNavRightHTML}`;

      // Handles Customer Care Chat button press
      this._customerCareChatButton = this.querySelector('.pui-sub-nav-customer-care-chat-button');
      if (this._customerCareChatButton) {
        this._customerCareChatButton.onButtonPress = function() {
          window.open(Urls.CUSTOMER_CARE_CHAT_URL,'popup','width=500,height=800');
        };
      }

      // Handles opening links bottom sheet
      this._linksBottomSheetButton = this.querySelector('#links-bottomsheet-button');
      this._linksBottomSheetButton.addEventListener('click', this._openLinksBottomSheet.bind(this));
      this._linksBottomSheetButton.addEventListener('keyup', this._keyListener.bind(this, this._openLinksBottomSheet.bind(this)));

      this._linksBottomSheet = this.querySelector('#links-bottomsheet');
      this._addEventListenerForHCPDropdownButton();

      if (hstPpvMShopSubnavWeblab == "T1") {
        this._addEventListenerForPPVDropdownButton();
      }
    }

    if (loggedIntoPharmacyProfile) {
      if (profileAwarenessEnabled) {
        const profileAwarenessBarLeftHTML = this._getSubNavProfileAwarenessBarLeftHTML();
        const profileAwarenessBarRightHTML = this._getSubNavProfileAwarenessBarRightHTML();

        this._subNavProfileAwarenessBarContainer.innerHTML = `${profileAwarenessBarLeftHTML} ${profileAwarenessBarRightHTML}`;
        this._subNavProfileAwarenessBarContainer.classList.add('pui-sub-nav-profile-awareness-bar-container');
        this._subNavProfileAwarenessBarContainer.classList.add('borderRadius');

        if (!this.isReadOnlyProfileAwarenessBar) {
          this._addEventListenerForProfileSwitchButtons();
          if (!(carePartnersWeblab === "T1") && signupComplete) {
            this._addEventListenerForAddDependentButton();
          }

          this._profileAwarenessBottomSheet = this.querySelector('#profile-awareness-bottom-sheet');
          // Handles opening the profile selection bottom sheet
          this._profileAwarenessBottomSheetButton = this.querySelector('#nav-link-profile-change');
          this._profileAwarenessBottomSheetButton.addEventListener('click', this._openProfileAwarenessBottomSheet.bind(this));
          this._profileAwarenessBottomSheetButton.addEventListener('keyup', this._keyListener.bind(this, this._openProfileAwarenessBottomSheet.bind(this)));

          this._profileSwitchLoadingBottomSheet = this.querySelector('#profile-switch-loading-bottom-sheet');
          this._profileSwitchLoadingBottomSheetContent = this.querySelector('#profile-switch-loading-bottom-sheet-content');
        }
      } else {
        // Handles opening the person bottom sheet
        this._personBottomSheetButton = this.querySelector('#person-bottomsheet-button');
        this._personBottomSheetButton.addEventListener('click', this._openPersonBottomSheet.bind(this));
        this._personBottomSheetButton.addEventListener('keyup', this._keyListener.bind(this, this._openPersonBottomSheet.bind(this)));

        this._personBottomSheet = this.querySelector('#person-bottomsheet');
      }


      // Create reference to pui-cart component
      this._cartIcon = this.querySelector('pui-cart');
    }
  }

  _addEventListenerForHCPDropdownButton() {
    // Handles opening hcp dropdown
    const mobileButton = this.querySelector('#pui-subnav-hcp-button-mobile');
    const desktopButton = this.querySelector('#pui-subnav-hcp-button-desktop');
    const chevronMobile = this.querySelector('#pui-subnav-hcp-chevron-right-mobile');
    const chevronDesktop = this.querySelector('#pui-subnav-hcp-chevron-right-desktop');
    const dropdownMobile = this.querySelector('#pui-subnav-hcp-sub-items-mobile');
    const dropdownDesktop = this.querySelector('#pui-subnav-hcp-sub-items-desktop');

    mobileButton?.addEventListener('click', () => this._toggleDropdown(chevronMobile, dropdownMobile));
    desktopButton?.addEventListener('click', () => this._toggleDropdown(chevronDesktop, dropdownDesktop));

    mobileButton?.addEventListener('keydown', (event) => this._keyListener(event, () => this._toggleDropdown(chevronMobile, dropdownMobile)));
    desktopButton?.addEventListener('keydown', (event) => this._keyListener(event, () => this._toggleDropdown(chevronDesktop, dropdownDesktop)));
  }

  _addEventListenerForPPVDropdownButton() {
    // Handles opening hcp dropdown
    const mobileButton = this.querySelector('#pui-subnav-ppv-button-mobile');
    const chevronMobile = this.querySelector('#pui-subnav-ppv-chevron-right-mobile');
    const dropdownMobile = this.querySelector('#pui-subnav-ppv-sub-items-mobile');

    mobileButton?.addEventListener('click', () => this._toggleDropdown(chevronMobile, dropdownMobile));

    mobileButton?.addEventListener('keydown', (event) => this._keyListener(event, () => this._toggleDropdown(chevronMobile, dropdownMobile)));
  }

  _isSelectedPath(linkUrl, windowPathName) {
    // Need to remove url parameters
    const url = new URL(linkUrl, window.location.origin);
    return url.pathname === windowPathName;
  }

  // TODO: Send URLs from backend configuration
  // SIM: https://sim.amazon.com/issues/KYANITE-SX-272
  createKyaniteLinks(urlString) {
    return KYANITE_PREFIX + urlString;
  }

  // Katara uses this version
  _getSubNavLeftHTML() {
    const { isMShop } = this;
    const { isNewPharmacyLogoEnabled } = this;
    const { strings, linkConfigs, logoConfig, loggedIntoPharmacyProfile, flyoutLinkConfigs, hstPpvMShopSubnavWeblab, hbcSubNavFlyoutWeblab } = this._subNavData;
    const links = linkConfigs.filter(link => link.id !== "book-a-visit-near-you").map(link => Object.assign({ text: strings[link.stringId] }, link));
    const logoHrefUrl = !!logoConfig ? logoConfig.hrefUrl : "";
    const windowPathName = window.location.pathname;
    const isHbcEnabled = hbcSubNavFlyoutWeblab === "T1";
    const mobileLinks = links.map(link => `<pui-list-link-item id="nav-link-${link.id}-mobile" input="${link.text}" url="${link.url}"></pui-list-link-item>`);
    const desktopLinks = links.filter(link => !(this.showMembershipsView && link.id === "pay-per-visit-dashboard"))
      .map(link => `
        <a
        id="nav-link-${link.id}-desktop" 
        href="${link.url}" style="white-space: nowrap"
        class="nav-link hover-underline ${this._isSelectedPath(link.url, windowPathName) && 'selected'}">
          <pui-text input="${link.text}"></pui-text>
        </a>`);
    const signInUrl = `<pui-list-link-item
                id="nav-link-sign-in-mobile"
                input="${strings['sign-in']}"
                url="${this.createKyaniteLinks(Urls.SIGN_IN_URL)}"></pui-list-link-item>`;
    if(!loggedIntoPharmacyProfile) {
      mobileLinks.push(signInUrl);
    }

      // Currently KATARA links config also includes "Browse all health" as a link in the API response instead of having it in the flyout link config.
      // Below is done to keep backwards compatible. Task to remove this after the launch https://sim.amazon.com/issues/Storefront-2778
      desktopLinks.splice(-1,1);

      // Backlog item to do this in ApexDashboardHZAVingilot during weblab clean up https://sim.amazon.com/issues/Storefront-2778
      const flyoutConfigMap = flyoutLinkConfigs.reduce((acc, item) => {
        const updatedItem = {
          ...item,
          url: item.url ? item.url.replace('ref_=pharmacy', 'ref_=health') : undefined
        };

        acc[item.id] = updatedItem;
        return acc;
      }, {});

      const browseAllHealthItem = flyoutConfigMap['browse-all-health'];
      const browseAllHealthFlyoutMobileLinks = this._getBrowseAllHealthFlyoutHTML(strings, flyoutConfigMap, loggedIntoPharmacyProfile, true, isHbcEnabled);
      const browseAllHealthFlyoutDesktopLinks = this._getBrowseAllHealthFlyoutHTML(strings, flyoutConfigMap, loggedIntoPharmacyProfile, false, isHbcEnabled);

      return `
      <pui-section fullWidth>
          <button id="links-bottomsheet-button" class="pui-sub-nav-bottom-sheet-button mobile-and-tablet-only">
              <pui-section flowDirection="horizontal" secondaryAxisArrangement="center">
                  ${isMShop ? `<pui-text textColor="white" input="${strings['menu-text']}" spacingLeft="medium" spacingRight="small"></pui-text>
                      <pui-icon imgClass="chevron-down-dash-white"></pui-icon>` : `<pui-icon imgClass="${this.imgClassLogo}" class="pui-sub-nav-logo"></pui-icon>
                      <pui-icon imgClass="chevron-down-dash"></pui-icon>`}
              </pui-section>
          </button>
          <pui-bottom-sheet id="links-bottomsheet" hideLink ${isMShop ? 'isMShop' : ''}>
              ${hstPpvMShopSubnavWeblab == "T1" && (this.showMembershipsView || !loggedIntoPharmacyProfile) ? '' : `<a id="mobile-kyanite-logo" href="${logoHrefUrl}"><pui-icon imgClass="${this.imgClassLogo}" style="padding-top: 20px"></pui-icon></a>`}
              ${hstPpvMShopSubnavWeblab == "T1" && (this.showMembershipsView || !loggedIntoPharmacyProfile)  ? browseAllHealthFlyoutMobileLinks.join('') : mobileLinks.join('')}
          </pui-bottom-sheet>
          <pui-section id="sub-nav-tabs" fullWidth class="desktop-only" flowDirection="horizontal" mainAxisArrangement="start" secondaryAxisArrangement="center">
              <a id="desktop-pharmacy-logo" href="${logoHrefUrl}"><pui-icon imgClass="${this.imgClassLogo} ${!isMShop && isNewPharmacyLogoEnabled ? 'new-pharmacy-desktop-logo' : ''}" class="pui-sub-nav-logo"></pui-icon></a>
              ${desktopLinks.join('')}
              <pui-flyout id="pui-sub-nav-browse-all-health-flyout" text="${strings[browseAllHealthItem.stringId]}" textSize="medium" minHeight="480px" minWidth="430px" >
                  ${browseAllHealthFlyoutDesktopLinks.join('')}
              </pui-flyout>
          </pui-section>
      </pui-section>`;
  }

  _getBrowseAllHealthFlyoutHTML(strings, flyoutConfigMap, loggedIntoPharmacyProfile, isMobileView, isHbcEnabled) {
    const browseAllHealthFlyoutLinks = [];

    const amazonHealthServicesItem = flyoutConfigMap['amazon-healthcare-services'];
    const omMembershipsItem = flyoutConfigMap['hornbill'];
    const omPPVItem = flyoutConfigMap['kyanite'];
    const pharmacyItem = flyoutConfigMap['pharmacy'];
    const healthConditionsProgramItem = flyoutConfigMap['health-condition-programs'];
    const healthBenefitsConnectorItem = flyoutConfigMap['health-benefits-connector'];
    const learnAboutProgramsItem = flyoutConfigMap['learn-about-programs'];
    const checkYourCoverageItem = flyoutConfigMap['check-your-coverage'];
    const seeYourCurrentProgramsItem = flyoutConfigMap['see-your-current-programs'];
    const seeYourCoverageResultsItem = flyoutConfigMap['see-your-coverage-results'];
    const fsaHsaItem = flyoutConfigMap['fsa-hsa'];
    const seeAllHealthServicesItem = flyoutConfigMap['see-all-health-services'];
    const helpFaqItem = flyoutConfigMap['help'];

    const omMembershipsLink = this._getFlyoutLinkHTML(strings, omMembershipsItem);
    const omPPVLink = isMobileView ? this._getPPVHTML(strings, omPPVItem, helpFaqItem, loggedIntoPharmacyProfile) : this._getFlyoutLinkHTML(strings, omPPVItem);
    const pharmacyLink = this._getFlyoutLinkHTML(strings, pharmacyItem);
    const hcpLink = this._getHCPHTML(strings, healthBenefitsConnectorItem, healthConditionsProgramItem, isHbcEnabled, learnAboutProgramsItem, checkYourCoverageItem, seeYourCurrentProgramsItem, seeYourCoverageResultsItem, isMobileView);

    const fsaHsaLink = `
    <a href=${fsaHsaItem.url} style="white-space: nowrap" class="hover-underline">
      <pui-line-item imgSrc="${fsaHsaItem.imgSrc}"
        imgWidth="60px" imgHeight="60px" headline="${strings[fsaHsaItem.stringId]}" subline="${strings[fsaHsaItem.sublineStringId]}"
        lineItemContentAxisArrangement="center" hideChevron hideDivider spacingLeft="small">
      </pui-line-item>
    </a>
    `;

    const seeAllHealthServicesLink = `<pui-link text="${strings[seeAllHealthServicesItem.stringId]}" href="${seeAllHealthServicesItem.url}" textSize="medium" spacingLeft="small"></pui-link>`;
    const amazonHealthServicesText = this._getFlyoutTextHTML(strings, amazonHealthServicesItem);
    browseAllHealthFlyoutLinks.push(amazonHealthServicesText, omMembershipsLink, omPPVLink, pharmacyLink, hcpLink, fsaHsaLink, seeAllHealthServicesLink);

    return browseAllHealthFlyoutLinks;
  }

  _getFlyoutLinkHTML(strings, flyoutLink) {
    return `
      ${flyoutLink.id != "hornbill" ? '' : `<div style="margin-left:10px; margin-right:10px;">
        <pui-divider>
        </pui-divider>
      </div>`}
      <a href=${flyoutLink.url} style="white-space: normal" class="hover-underline">
          <pui-line-item imgSrc="${flyoutLink.imgSrc}"
              imgWidth="60px" imgHeight="60px" headline="${strings[flyoutLink.stringId]}"
              ${flyoutLink.sublineStringId ? `subline="${strings[flyoutLink.sublineStringId]}"` : ``} lineItemContentAxisArrangement="center"
              hideChevron hideDivider spacingLeft="small" spacingTop="${flyoutLink.id != "hornbill" ? "" : "small"}"
              ${flyoutLink.tertiarylineStringId ? `tertiaryline="${strings[flyoutLink.tertiarylineStringId]}  <pui-link href='${flyoutLink.tertiarylineLinkURLId}' text='${strings[flyoutLink.tertiarylineLinkStringId]}' textsize='small'></pui-link>"` : ``}>
          </pui-line-item>
      </a>
    `;
  }

  _getFlyoutTextHTML(strings, flyoutLink) {
    return `<pui-text tabindex=0 paddingTop="small" paddingBottom="small" paddingLeft="small" textsize="double-extra-large" fontWeight="semi-bold" input="${strings[flyoutLink.stringId]}"></pui-text>`;
  }

  _getPPVHTML(strings, omPPVItem, helpFaqItem, loggedIntoPharmacyProfile) {
    const signInHeadline = loggedIntoPharmacyProfile ? strings['pay-per-visit-dashboard'] : strings['sign-in-to-pay-per-visit'];
    const signInUrl = `<a href=${this.createKyaniteLinks(Urls.SIGN_IN_URL)}>
        <pui-line-item imgWidth="60px" imgHeight="60px" headline="${signInHeadline}"
            hideChevron spacingRight="small" spacingTop="small" spacingBottom="small" style="margin-left: 90px;" headlineTextColor="link">
        </pui-line-item>
    </a>`;
    
    const ppvLanding = `
      <a href=${omPPVItem.url}>
          <pui-line-item imgWidth="60px" imgHeight="60px" headline="${strings['learn-more-about-pay-per-visit']}"
              hideChevron spacingRight="small" spacingTop="small" spacingBottom="small" style="margin-left: 90px;" headlineTextColor="link">
          </pui-line-item>
      </a>
    `;

    const helpFaq = `
      <a href=${helpFaqItem.url}>
          <pui-line-item imgWidth="60px" imgHeight="60px" headline="${strings[helpFaqItem.stringId]}"
              hideChevron spacingRight="small" spacingTop="small" spacingBottom="small" style="margin-left: 90px;" headlineTextColor="link">
          </pui-line-item>
      </a>
    `;

    return `
      <button id="pui-subnav-ppv-button-mobile" class="hover-underline">
        <pui-line-item
          id="pui-subnav-ppv-line-item"
          imgSrc=${omPPVItem.imgSrc}
          imgWidth="60px" imgHeight="60px" headline="${strings[omPPVItem.stringId]}"
          subline="${strings[omPPVItem.sublineStringId]}"
          lineItemContentAxisArrangement="center"
          hideChevron hideDivider paddingRight="small" spacingLeft="small"
          style="cursor: pointer; display: flex; white-space: normal">
        </pui-line-item>
        <pui-icon id="pui-subnav-ppv-chevron-right-mobile" imgClass='chevron-down-grey-large' paddingRight="small" style="margin-top:20px;"></pui-icon>
      </button>
      <pui-section id="pui-subnav-ppv-sub-items-mobile" hidden  style="margin-top: -10px;">
        <div>
          ${ppvLanding}
          ${helpFaq}
          ${signInUrl}
        </div>
      </pui-section>
    `;
  }


  _getHCPHTML(strings, healthBenefitsConnectorItem, healthConditionsProgramItem, isHbcEnabled, learnAboutProgramsItem, checkYourCoverageItem, seeYourCurrentProgramsItem, seeYourCoverageResultsItem, isMobileView) {
    const learnAboutProgramsLink = this._getHCPLinks(strings, learnAboutProgramsItem);
    const checkYourCoverageLink = this._getHCPLinks(strings, checkYourCoverageItem);
    const seeYourCurrentProgramsLink = this._getHCPLinks(strings, seeYourCurrentProgramsItem);
    const seeYourCoverageResultsLink = this._getHCPLinks(strings, seeYourCoverageResultsItem);
    return `
      <button id="pui-subnav-hcp-button-${isMobileView ? 'mobile' : 'desktop'}" class="hover-underline" >
        <pui-line-item
          id="pui-subnav-hcp-line-item"
          imgSrc=${isHbcEnabled ? healthBenefitsConnectorItem.imgSrc : healthConditionsProgramItem.imgSrc}
          imgWidth="60px" imgHeight="60px" headline="${strings[isHbcEnabled ? healthBenefitsConnectorItem.stringId : healthConditionsProgramItem.stringId]}"
          subline="${strings[isHbcEnabled ? healthBenefitsConnectorItem.sublineStringId : healthConditionsProgramItem.sublineStringId]}"
          lineItemContentAxisArrangement="center"
          hideChevron hideDivider paddingRight="small" spacingLeft="small"
          style="cursor: pointer; display: flex; white-space: normal">
        </pui-line-item>
        <pui-icon id="pui-subnav-hcp-chevron-right-${isMobileView ? 'mobile' : 'desktop'}" imgClass='chevron-down-grey-large' paddingRight="small" style="margin-top:20px;"></pui-icon>
      </button>
      <pui-section id="pui-subnav-hcp-sub-items-${isMobileView ? 'mobile' : 'desktop'}" hidden style="margin-top: -10px;">
        <div>
          ${learnAboutProgramsLink}
          ${checkYourCoverageLink}
          ${seeYourCurrentProgramsLink}
          ${seeYourCoverageResultsLink}
        </div>
      </pui-section>
    `;
  }

  _getHCPLinks(strings, flyoutLink) {
    return `
      <a href=${flyoutLink.url}>
          <pui-line-item imgWidth="60px" imgHeight="60px" headline="${strings[flyoutLink.stringId]}"
              hideChevron spacingRight="small" spacingTop="small" spacingBottom="small" style="margin-left: 90px;" headlineTextColor="link">
          </pui-line-item>
      </a>
    `;
  }

  _getPABSupportedSubNavLeftHTML() {
    const { isMShop } = this;
    const { isNewPharmacyLogoEnabled } = this;
    const {
      strings,
      linkConfigs,
      logoConfig,
      weblabSubnavFlyout,
      hbcSubNavFlyoutWeblab,
      flyoutLinkConfigs,
    } = this._subNavData;
    const links = linkConfigs.map(link => Object.assign({ text: strings[link.stringId] }, link));
    const flyoutLinks = flyoutLinkConfigs.map((flyoutLink) => Object.assign({ text: strings[flyoutLink.stringId] }, flyoutLink));
    const logoHrefUrl = !!logoConfig ? logoConfig.hrefUrl : Urls.PHARMACY_HOME_URL;
    const isHbcEnabled = hbcSubNavFlyoutWeblab === "T1";
    const mobileLinks = links.map((link) => {
      if (link.id === 'help') {
        // return help link and icon HTML
        const iconOptions = {
          imgClass: 'info-bubble-black',
          spacingRight: 'mini',
          spacingTop: 'none',
        };
        const iconOptionsString = JSON.stringify(iconOptions);
        return `<pui-list-link-item id="nav-link-${link.id}-mobile" input="${link.text}" url="/help?ref_=pharmacy_menu_subnav" iconType="img" iconOptions=${iconOptionsString}></pui-list-link-item>`;
      } else {
        // return existing HTML
        return `<pui-list-link-item id="nav-link-${link.id}-mobile" input="${link.text}" url="${link.url}"></pui-list-link-item>`;
      }
    });
    const desktopLinks = links.map((link) => {
      if (link.id === 'help') {
        // return new Get help link and icon HTML
        const id = "nav-link-help-desktop"
        const spacingRight = "med-large"
        const ref_ = "pharmacy_subnav"
        return this._getSubNavHelpLinkHTML(id, spacingRight, '', '', '', '', ref_);
      } else {
        // return existing HTML
        return `<a id="nav-link-${link.id}-desktop" href="${link.url}" style="white-space: nowrap" class="hover-underline"><pui-text input="${link.text}" textColor="black" spacingRight="med-large"></pui-text></a>`;
      }
    });
    //flyout configs
    const flyoutIngressItem = flyoutLinks.shift();
    const flyoutIngressText = flyoutIngressItem.text;
    const flyoutBrowseHealthByItem = flyoutLinks.shift();
    const flyoutTitleText = flyoutBrowseHealthByItem.text;
    const robinItem = flyoutLinks.shift();
    const healthBenefitsConnectorItem = flyoutLinks.shift();
    const fsaHsaItem = flyoutLinks[1];

    // render minors supported sub nav.
    if (this.showHealthFlyout && weblabSubnavFlyout && weblabSubnavFlyout == "T1") {
        
            // The logic below to construct a new links array is done for backwards compatibility
            const kyaniteFlyoutLinks = [];
            const seeAllHealthItem = flyoutLinks[6];

            kyaniteFlyoutLinks.push(
                ...flyoutLinks.slice(2, 6), 
                ...(isHbcEnabled ? [healthBenefitsConnectorItem] : [robinItem]), 
                fsaHsaItem);

            const kyaniteFlyoutDesktopLinks = kyaniteFlyoutLinks.map(
                (flyoutLink) => {
                    if (flyoutLink.sublineStringId) {
                        return `
                        <a href=${flyoutLink.url} style="white-space: normal" class="hover-underline">
                            <pui-line-item imgSrc="${flyoutLink.imgSrc}"
                                imgWidth="60px" imgHeight="60px" headline="${flyoutLink.text}"
                                subline="${strings[flyoutLink.sublineStringId]}"
                                hideChevron hideDivider spacing="small" spacingTop="small" spacingBottom="small"
                                ${flyoutLink.tertiarylineStringId ? `tertiaryline="${strings[flyoutLink.tertiarylineStringId]}  <pui-link href='${flyoutLink.tertiarylineLinkURLId}' text='${strings[flyoutLink.tertiarylineLinkStringId]}' textsize='small'></pui-link>"` : ``}>
                            </pui-line-item>
                        </a>
                        `;
                    } else {
                        return `
                        <pui-text tabindex=0 paddingBottom="mini" paddingLeft="small" textsize="double-extra-large" fontWeight="semi-bold" input="${flyoutLink.text}"></pui-text>
                        <div style="margin-left:10px; margin-right:10px;">
                            <pui-divider>
                            </pui-divider>
                        </div>
                        `;
                    }
                }
            );

            const kyaniteFlyoutMobileLinks = kyaniteFlyoutLinks.map(
              (flyoutLink) => {
                if (flyoutLink.sublineStringId) {
                  return `
                        <pui-divider>
                        </pui-divider>
                        <a href=${flyoutLink.url} style="white-space: normal" class="hover-underline">
                            <pui-line-item headline="${flyoutLink.text}"
                                subline="${strings[flyoutLink.sublineStringId]}"
                                hideDivider paddingTop="small" spacingBottom="small" spacingRight="small" 
                                ${flyoutLink.tertiarylineStringId ? `tertiaryline="${strings[flyoutLink.tertiarylineStringId]}  <pui-link href='${flyoutLink.tertiarylineLinkURLId}' text='${strings[flyoutLink.tertiarylineLinkStringId]}' textsize='small'></pui-link>"` : ``}>
                            </pui-line-item>
                        </a>
                        `;
                } else {
                  return `
                        <pui-text tabindex=0 input="${flyoutLink.text}" textColor="black" spacingRight="med-large" textsize="double-extra-large" fontWeight="semi-bold" paddingTop="medium" paddingBottom="mini" ></pui-text>
                        `;
                }
              }
            );

            return `
            <pui-section fullWidth>
                <button id="links-bottomsheet-button" class="pui-sub-nav-bottom-sheet-button mobile-and-tablet-only ${!isMShop ? '' : 'minorsSupported'}">
                    <pui-section flowDirection="horizontal" secondaryAxisArrangement="center">
                        ${isMShop ?
                        `<pui-text textColor="white" input="${strings['menu-text']}" spacingLeft="medium" spacingRight="small"></pui-text>
                            <pui-icon imgClass="chevron-down-dash-white"></pui-icon>` :
                        `<pui-icon imgClass="${this.imgClassLogo} ${!isMShop ? '' : 'minorsSupported'}" class="pui-sub-nav-logo"></pui-icon>
                            <pui-icon imgClass="chevron-down-dash" spacingTop="mini"></pui-icon>`}
                    </pui-section>
                </button>
                <pui-bottom-sheet id="links-bottomsheet" hideLink ${isMShop ? 'isMShop' : ''}>
                    ${mobileLinks.join('')}
                    ${kyaniteFlyoutMobileLinks.join('')}
                    <pui-divider spacingBottom="small"></pui-divider>
                    <pui-link text="${seeAllHealthItem.text}" href="${seeAllHealthItem.url}" textSize="medium"></pui-link>
                </pui-bottom-sheet>
        
                <pui-section fullWidth class="desktop-only" flowDirection="horizontal" mainAxisArrangement="start"  secondaryAxisArrangement="center">
                    <a id="desktop-pharmacy-logo" href="${logoHrefUrl}"><pui-icon imgClass="${this.imgClassLogo} 
                        ${!isMShop ? '' : 'minorsSupported'} ${!isMShop && isNewPharmacyLogoEnabled ? 'new-pharmacy-desktop-logo' : ''}" class="pui-sub-nav-logo"></pui-icon>
                    </a>
                    ${desktopLinks.join('')}
                    <pui-flyout text="${flyoutIngressText}" textSize="medium" minHeight="500px" minWidth="460px">
                        ${kyaniteFlyoutDesktopLinks.join('')}
                        <pui-link text="${seeAllHealthItem.text}" href="${seeAllHealthItem.url}" textSize="medium" spacingLeft="small"></pui-link>
                    </pui-flyout>
                </pui-section>
            </pui-section>`;
        
    };
    return `
    <pui-section fullWidth>
        <button id="links-bottomsheet-button" class="pui-sub-nav-bottom-sheet-button mobile-and-tablet-only 
        ${!isMShop ? '' : 'minorsSupported'}">
            <pui-section flowDirection="horizontal" secondaryAxisArrangement="center">
              ${isMShop ? `<pui-text textColor="white" input="${strings['menu-text']}" spacingLeft="medium" spacingRight="small"></pui-text>
                <pui-icon imgClass="chevron-down-dash-white"></pui-icon>` : `
                <pui-icon imgClass="${this.imgClassLogo} 
                  ${!isMShop ? '' : 'minorsSupported'}" 
                  class="pui-sub-nav-logo"></pui-icon>
                <pui-icon imgClass="chevron-down-dash" spacingTop="mini"></pui-icon>`}
            </pui-section>
        </button>
        <pui-bottom-sheet id="links-bottomsheet" hideLink ${isMShop ? 'isMShop' : ''}>
            ${mobileLinks.join('')}
        </pui-bottom-sheet>

        <pui-section fullWidth class="desktop-only" flowDirection="horizontal" mainAxisArrangement="start"  secondaryAxisArrangement="center">
            <a id="desktop-pharmacy-logo" href="${logoHrefUrl}"><pui-icon imgClass="${this.imgClassLogo} 
            ${!isMShop ? '' : 'minorsSupported'} ${!isMShop && isNewPharmacyLogoEnabled ? 'new-pharmacy-desktop-logo' : ''}" class="pui-sub-nav-logo"></pui-icon></a>
            ${desktopLinks.join('')}
        </pui-section>
    </pui-section>`;
  }


  // Katara is using this version
  _getSubNavRightHTML() {
    const { isMShop } = this;
    const {
      strings, 
      friendlyName, 
      loggedIntoPharmacyProfile, 
      personBottomsheetLinkConfigs,
      linkConfigs
    } = this._subNavData;
    let subNavRightContent = '';
    const linkColor = isMShop ? 'white' : 'link';
    const iconColor = isMShop ? 'white-' : '';
    const glowContentHTML = this._getGlowContentHTML();
    if (this.showBookVisit) {
      const bookVisitLink = linkConfigs.find(link => link.id === "book-a-visit-near-you");
      subNavRightContent = `
        <pui-link 
            style="white-space: nowrap"
            class="desktop-only"
            color="${linkColor}"
            textSize="medium"
            text="${strings[bookVisitLink.stringId]}"
            href="${bookVisitLink.url}">
        </pui-link>`;
    } else if (loggedIntoPharmacyProfile) {
      // TODO: Send URLs from backend configuration
      // SIM: https://sim.amazon.com/issues/KYANITE-SX-272
      const notificationLink = this.createKyaniteLinks(Urls.NOTIFICATIONS_URL);
      const peoplePickerLink = this.createKyaniteLinks(Urls.PEOPLE_PICKER_URL);
      const signOutLink = this.createKyaniteLinks(Urls.SIGN_OUT_URL);

      const personBottomsheetLinksContentHtml = personBottomsheetLinkConfigs.map(link => {
          const label = strings[link.subtitleStringId] ? strings[link.stringId] : '';
          const input = strings[link.subtitleStringId] || strings[link.stringId];
          return `<pui-list-link-item id="${link.id}" label="${label}" input="${input}" url="${link.url}"></pui-list-link-item>`;
        }).join('');
      const cartContentHTML = this._getCartContentHTML();
      
      subNavRightContent = `
        ${glowContentHTML}
        <a id="nav-link-notification" href="${notificationLink}">
          <pui-icon imgClass="${`notification-${iconColor}icon`}" spacingRight="small" style="display: flex"></pui-icon>
        </a>
        <button id="person-bottomsheet-button" class="pui-sub-nav-bottom-sheet-button">
            <pui-section class="pui-sub-nav-person-icon" spacingRight="small">
                <pui-text input="${this._getInitials(friendlyName)}" textSize="small"></pui-text>
            </pui-section>
        </button>
        <pui-bottom-sheet id="person-bottomsheet" hideLink ${isMShop ? 'isMShop' : ''}>
            <pui-section>
                <pui-section flowDirection="horizontal" secondaryAxisArrangement="center" paddingTop="small">
                    <pui-icon imgClass="person-icon" spacingRight="medium"></pui-icon>
                    <pui-section mainAxisArrangement="space-between">
                        <pui-heading input="${friendlyName}"></pui-heading>
                        <pui-text input="${strings['account-holder']}"></pui-text>
                    </pui-section>
                </pui-section>
                <pui-divider spacingTop="medium"></pui-divider>
                ${personBottomsheetLinksContentHtml}
                <pui-link 
                  id="switch-or-add-customer-link"
                  style="white-space: nowrap"
                  class="hover-underline"
                  textcolor="link"
                  textSize="medium"
                  text="${strings['change-profile-link']}"
                  href="${peoplePickerLink}" spacingTop="small"></pui-link>
                ${isMShop ? '' :
                `<pui-link 
                    id="sign-out-link"
                    style="white-space: nowrap"
                    textcolor="link"
                    textSize="medium"
                    text="${strings['sign-out']}"
                    href="${signOutLink}"
                    spacingTop="small">
                 </pui-link>`}
            </pui-section>
        </pui-bottom-sheet>
        
        ${cartContentHTML}
      `;
    } else {
      const defaultSignInString = strings['sign-in'];
      const shortSignInString = strings['sign-in-short'] ?? defaultSignInString;

      subNavRightContent = defaultSignInString && shortSignInString ? `
      ${glowContentHTML}
      <pui-link 
                style="white-space: nowrap"
                class="desktop-only"
                color="${linkColor}"
                textSize="medium"
                text="${defaultSignInString}"
                href="${this.createKyaniteLinks(Urls.SIGN_IN_URL)}">
            </pui-link>
            <pui-link 
                style="white-space: nowrap"
                class="mobile-and-tablet-only"
                color="${linkColor}"
                textSize="medium"
                text="${shortSignInString}"
                href="${this.createKyaniteLinks(Urls.SIGN_IN_URL)}">
            </pui-link>` : ``;
    }

    return `
        <pui-section fullWidth flowDirection="horizontal" mainAxisArrangement="end" secondaryAxisArrangement="center" spacingRight="medium">
            ${subNavRightContent}
        </pui-section>
    `;
  }

  _getPABSupportedSubNavRightHTML() {
    const {
      strings,
      loggedIntoPharmacyProfile,
      newHelpHubIngressesEnabled,
    } = this._subNavData;
    let subNavRightContent = '';
    let helpLinkHTML = '';
    if (newHelpHubIngressesEnabled) {
      const id = "nav-link-help-mobile-top";
      const spacingRight = "medium";
      const spacingLeft = "medium";
      const sectionClass = "mobile-and-tablet-only"; // We only want to show this on mobile and tablet
      const textColor = this.isMShop ? "white-color" : "";
      const iconClass = this.isMShop ? "info-bubble-white" : "";
      const ref_ = "pharmacy_top_subnav";
      helpLinkHTML = this._getSubNavHelpLinkHTML(id, spacingRight, spacingLeft, sectionClass, textColor, iconClass, ref_)
    }
    if (loggedIntoPharmacyProfile) {
      subNavRightContent = `
        ${helpLinkHTML}
        <a id="nav-link-notification" href="${Urls.NOTIFICATIONS_URL}"><pui-icon imgClass="${!this.isMShop ? 'notification-icon' : 'notification-white-icon'}" spacingRight="small" style="display: flex"></pui-icon></a>
        ${this._getMinorsSupportedCartContentHTML()}`;
    } else {
      subNavRightContent = `
        ${helpLinkHTML}
        <pui-link style="white-space: nowrap" color="${!this.isMShop ? 'black' : 'white'}" textSize="medium" text="${strings['sign-in']}" href="${Urls.SIGN_IN_URL}"></pui-link>
      `;
    }

    return `
        <pui-section fullWidth flowDirection="horizontal" mainAxisArrangement="end" secondaryAxisArrangement="center" spacingRight="medium">
            ${subNavRightContent}
        </pui-section>
    `;
  }

  _getMinorsSupportedCartContentHTML() {
    const { isMShop } = this;
    const { cartCount, newHelpHubIngressesEnabled } = this._subNavData;
    if (this._showcart()) {
      if (newHelpHubIngressesEnabled) {
        return isMShop
          ? `<pui-cart minorsSupported id="pharmacy-cart" color="white" totalItems="${cartCount}" href="${Urls.CART_URL}" spacingLeft="mini" textMobile=""></pui-cart>`
          : `<pui-cart id="pharmacy-cart" color="black" textColor="black totalItems="${cartCount}" href="${Urls.CART_URL}" spacingLeft="mini" textMobile=""></pui-cart>`;
      } else {
        return isMShop
          ? `<pui-cart minorsSupported id="pharmacy-cart" color="white" totalItems="${cartCount}" href="${Urls.CART_URL}" spacingLeft="mini"></pui-cart>`
          : `<pui-cart id="pharmacy-cart" color="black" textColor="black totalItems="${cartCount}" href="${Urls.CART_URL}" spacingLeft="mini"></pui-cart>`;
      }
    }
    return ``;
  }

  _getCartContentHTML() {
    const { isMShop } = this;
    const { cartCount } = this._subNavData;
    if (this._showcart()) {
      return isMShop
        ? `<pui-cart id="pharmacy-cart" color="white" totalItems="${cartCount}" href="${Urls.CART_URL}" spacingLeft="mini"></pui-cart>`
        : `<pui-cart id="pharmacy-cart" totalItems="${cartCount}" href="${Urls.CART_URL}" spacingLeft="mini"></pui-cart>`;
    }
    return ``;
  }

  _getGlowContentHTML() {
    const { isMShop, isGlowEnabled, zipCode, skipGlowReload, glowCsrfToken } = this;
    const { strings, glowConfig } = this._subNavData;
    const glowcolor = isMShop ? 'white' : 'black';
    return isGlowEnabled ?
      `<pui-mini-glow-ingress
          glowColor="${glowcolor}"
          zipCode="${zipCode}"
          skipReload="${skipGlowReload}"
          glowCsrfToken="${glowCsrfToken}"
          glowModalHeaderLabel="${strings['glow-modal-header-label']??''}"
          glowModalSubHeaderLabel="${strings['glow-modal-sub-header-label']??''}"
          glowModalZipCodeInputLabel="${strings['glow-modal-zipcode-input-label']??''}"
          glowModalSubmitButtonLabel="${strings['glow-modal-submit-button-label']??''}"
          glowModalClientErrorMessage="${strings['glow-modal-client-error-message']??''}"
          glowModalServerErrorMessage="${strings['glow-modal-server-error-message']??''}"
          getLocationUrl="${glowConfig?.getLocationLabelUrl}"
          updateLocationUrl="${glowConfig?.addressChangeUrl}"
          spacingRight="small"
        >
        </pui-mini-glow-ingress>`
        : ``;
  }

  _showcart() {
    const { enabledWidgets } = this._subNavData;
    return (enabledWidgets || [SubNavWidgets.CART]).includes(SubNavWidgets.CART);
  }

  _toggleDropdown(chevron, dropdown) {
    if (dropdown) {
      if (dropdown.hidden) {
        dropdown.show();
        chevron?.setAttribute('imgClass', 'chevron-up-grey-large');
      } else {
        dropdown.hide();
        chevron?.setAttribute('imgClass', 'chevron-down-grey-large');
      }
    }
  }

  _openLinksBottomSheet() {
    this._linksBottomSheet.show();
  }

  _openPersonBottomSheet() {
    this._personBottomSheet.show();
  }

  _openProfileSwitchLoadingBottomSheet() {
    this._profileSwitchLoadingBottomSheet.show();
  }

  _openProfileAwarenessBottomSheet() {
    this._profileAwarenessBottomSheet.show();
  }

  _hideProfileAwarenessBottomSheet() {
    this._profileAwarenessBottomSheet.hide();
  }

  _getInitials(name) {
    const initials = name.split(' ').map(x => x[0]);
    return initials.join('');
  }

  _getSubNavProfileAwarenessBarLeftHTML() {
    const {
      friendlyName,
      strings,
    } = this._subNavData;

    let chevronIcon = this.isReadOnlyProfileAwarenessBar ? `` : `<pui-icon imgclass="chevron-down-textlink"></pui-icon>`;

    return `
      <pui-section style="cursor: pointer" tabindex="0" id="nav-link-profile-change" flowDirection="horizontal" spacingLeft="medium" secondaryAxisArrangement="center">
        <pui-roundel initials="${getInitials(friendlyName)}"></pui-roundel>
        <pui-text fontWeight="bold" spacingRight="mini" input="${friendlyName}"></pui-text>
        ${chevronIcon}
      </pui-section>
    `;
  }

  _getSubNavProfileAwarenessBarProfileLinkHTML(friendlyName, isCurrentSignedIn, idModifier) {
    const {
      strings,
    } = this._subNavData;
    return `<pui-section fullWidth 
            spacingLeft="medium" 
            spacingRight="medium" 
            spacingBottom="small" 
            flowDirection="horizontal" 
            secondaryAxisArrangement="center"
            padding="small"
            id="profile-switch-${idModifier}"
            class="pui-block pui-box pui-sub-nav-bottom-sheet-button-container ${isCurrentSignedIn ? 'pui-sub-nav-bottom-sheet-active-profile' : ''}">
            <pui-section flowDirection="horizontal" secondaryAxisArrangement="center">
              <pui-section flowDirection="horizontal" mainAxisArrangement="start" secondaryAxisArrangement="center">
                  <pui-section class="pui-sub-nav-person-icon" class="pui-sub-nav-bottom-sheet-button">
                    <pui-text input="${this._getInitials(friendlyName)}" textSize="small"></pui-text>
                  </pui-section>
                  <pui-text spacingLeft="small" input="${friendlyName}"></pui-text>
              </pui-section>
              ${isCurrentSignedIn ? `
              <pui-section class="pui-sub-nav-bottom-sheet-active-profile-viewing" flowDirection="horizontal" mainAxisArrangement="center" secondaryAxisArrangement="center">
                <pui-text textAlign="center" textColor="white" spacingLeft="small" input="${strings.viewing}"></pui-text>
              </pui-section>` : `<pui-icon imgClass="chevron-right"></pui-icon>`}
            </pui-section>
       </pui-section>`;
  }

  _getSubNavProfileAwarenessBarAddChildLinkHTML() {
    const {
      strings,
    } = this._subNavData;
    return `
        <pui-section fullWidth
            spacingLeft="medium"
            spacingRight="medium"
            spacingBottom="small"
            flowDirection="horizontal"
            secondaryAxisArrangement="center"
            padding="small"
            id="profile-switch-add"
            class="pui-block pui-box pui-sub-nav-bottom-sheet-button-container">
            <pui-section flowDirection="horizontal" secondaryAxisArrangement="center">
              <pui-section flowDirection="horizontal" mainAxisArrangement="start" secondaryAxisArrangement="center">
                    <pui-icon imgClass="add-icon"></pui-icon>
                  <pui-link spacingLeft="small" color="black" text="${strings['add-child']}"></pui-link>
              </pui-section>
            </pui-section>
       </pui-section>`;
  }

  _getSubNavProfileAwarenessBarRightHTML() {
    const { isMShop } = this;
    const {
      personProfiles,
      strings,
      friendlyName,
      signupComplete,
      carePartnersWeblab,
    } = this._subNavData;

    if (this.isReadOnlyProfileAwarenessBar) {
      return '';
    }

    const destinationUrl = new URL(Urls.SELECT_ADDRESS_URL);
    destinationUrl.searchParams.append("returnUrl", window.location.href);

    const carePartnersEnabled = carePartnersWeblab === "T1";

    const link = `<pui-link style="white-space: nowrap" spacingRight="small" text="${this._subNavData.location}" href="${destinationUrl.href}"></pui-link>`;

    const links = [];

    // Add current signed in profile
    links.push(this._getSubNavProfileAwarenessBarProfileLinkHTML(friendlyName, true, 'signed-in-person'));

    if (personProfiles && !this.isReadOnlyProfile) {
      links.push(...personProfiles.map((profile, index) => (profile.currentSignedIn
        ? ''
        : this._getSubNavProfileAwarenessBarProfileLinkHTML(profile.friendlyName, false, index))));
    }

    if (!carePartnersEnabled && signupComplete) {
      links.push(this._getSubNavProfileAwarenessBarAddChildLinkHTML());
    }

    if (carePartnersEnabled) {
      return `
      ${link}
      <pui-bottom-sheet id="profile-awareness-bottom-sheet" hideLink ${isMShop ? 'isMShop' : ''}>
          <pui-section>
            <pui-heading input="${strings['choose-who-shopping-for']}" spacingBottom="medium"></pui-heading>
            <pui-section flowDirection="vertical" secondaryAxisArrangement="center">
              ${links.join(' ')}
            </pui-section>
            <pui-section spacingTop="medium" ${signupComplete ? '' : 'hidden'}>
              <pui-link id="back-to-pp-link" style="white-space: nowrap" textcolor="link" textSize="medium" text="${strings['add-a-child']}" href="${Urls.ADD_DEPENDENT_URL}"></pui-link>
            </pui-section>
            <pui-section spacingTop="medium">
              <pui-link id="back-to-pp-link" style="white-space: nowrap" textcolor="link" textSize="medium" text="${strings['invite-someone-to-help']}" href="${Urls.INVITE_CARE_PARTNER_URL}"></pui-link>
            </pui-section>
            <pui-divider spacingTop="medium"></pui-divider>
            <pui-section flowDirection="horizontal" spacingTop="small">
              <pui-section flowDirection="vertical" mainAxisArrangement="start" secondaryAxisArrangement="start">
                <pui-link id="back-to-pp-link" style="white-space: nowrap" textcolor="link" textSize="medium" text="${strings['switch-profiles']}" href="${Urls.PEOPLE_PICKER_URL}"></pui-link>
              </pui-section>
              <pui-section flowDirection="vertical" mainAxisArrangement="end" secondaryAxisArrangement="end">
                <pui-link id="back-to-pp-link" style="white-space: nowrap" textcolor="link" textSize="medium" text="${strings['manage-care-partners']}" href="${Urls.MANAGE_CARE_PARTNERS_URL}"></pui-link>
              </pui-section>
            </pui-section>
          </pui-section>  
      </pui-bottom-sheet>
      <pui-bottom-sheet id="profile-switch-loading-bottom-sheet" hideLink hideClose ${isMShop ? 'isMShop' : ''}>
        <pui-section id="profile-switch-loading-bottom-sheet-content"  paddingBottom="large">
          
        </pui-section>
      </pui-bottom-sheet>
    `;
    }

    return `
      ${link}
      <pui-bottom-sheet id="profile-awareness-bottom-sheet" hideLink ${isMShop ? 'isMShop' : ''}>
          <pui-section>
            <pui-heading input="${strings['who-shopping-for']}" spacingBottom="medium"></pui-heading>
            <pui-section flowDirection="vertical" secondaryAxisArrangement="center">
              ${links.join(' ')}
            </pui-section>
            <pui-link id="back-to-pp-link" style="white-space: nowrap" textcolor="link" textSize="medium" text="${strings['im-someone-else']}" href="${Urls.PEOPLE_PICKER_URL}" spacingTop="small"></pui-link>
          </pui-section>  
      </pui-bottom-sheet>
      <pui-bottom-sheet id="profile-switch-loading-bottom-sheet" hideLink hideClose ${isMShop ? 'isMShop' : ''}>
        <pui-section id="profile-switch-loading-bottom-sheet-content"  paddingBottom="large">
          
        </pui-section>
      </pui-bottom-sheet>
    `;
  }

  _getSubNavProfileSwitchLoadingBottomSheetContent() {
    const { pendingProfileSwitchName } = this;
    const {
      strings,
    } = this._subNavData;


    return `
        <pui-section flowDirection="vertical" secondaryAxisArrangement="center">
          <pui-text textSize="extra-large" fontWeight="bold" input="${strings['switching-to']} ${pendingProfileSwitchName}..."></pui-text>
          <pui-loading-indicator spacingTop="large" query></pui-loading-indicator>
        </pui-section>
    `;
  }

  _getProfileButtonWithInitials(friendlyName, isCurrentSignedIn, idModifier, isMobileView) {
    const buttonId = isMobileView ? `"profile-switch-${idModifier}-mobile"` : `"profile-switch-${idModifier}"`;
    return `<pui-button-with-initials
          id = ${buttonId}
          label = "${friendlyName}"
          icon = "${friendlyName[0].toUpperCase()}"
          ${isCurrentSignedIn ? 'isSelected = "true" textColor = "white-color"' : 'textColor = "black-color"'}
          paddingTop = "small"
          paddingRight = "small">
     </pui-button-with-initials>`;
  }

  _getAddChildButtonForDashboard(isMobileView) {
    const {
      strings,
    } = this._subNavData;
    return `<pui-button-with-initials
          ${isMobileView ? 'id = "profile-switch-add-mobile"' : 'id = "profile-switch-add"'}
          label = "${strings['dashboard-add-child-button']}"
          textColor = "black-color"
          paddingTop = "small"
          paddingRight = "small">
     </pui-button-with-initials>`;
  }

  _getSubNavProfileAwarenessBarButtonsForDashboard() {
    const { isMShop } = this;
    const {
      personProfiles,
      friendlyName,
    } = this._subNavData;

    if (!personProfiles || !friendlyName) {
      return `<pui-section 
            spacingBottom = "medium">
            <div class = "pui-loading-text-extra-large-light-dashboard" id = "loading-dashboard-profile-bar-buttons"></div>
            </pui-section>`;
    }

    if (this.isReadOnlyProfileAwarenessBar) {
      return '';
    }
    const links = [];
    const links_mobile = [];
    // Add shopping for person profile
    links.push(this._getProfileButtonWithInitials(friendlyName, true, 'shopping-for-person'));
    links_mobile.push(this._getProfileButtonWithInitials(friendlyName, true, 'shopping-for-person', true));

    if (personProfiles) {
      personProfiles.forEach((profile, index) => {
        if (!profile.currentSignedIn && !this.isReadOnlyProfile) {
          links.push(this._getProfileButtonWithInitials(profile.friendlyName, false, index));
          links_mobile.push(this._getProfileButtonWithInitials(profile.friendlyName, false, index, true));
        }
      });
    }

    links.push(this._getAddChildButtonForDashboard());
    links_mobile.push(this._getAddChildButtonForDashboard(true));

    return `<pui-section 
      flowDirection = "horizontal"
      scrollable
      spacingBottom = "medium"
      class = "touch-screen-enabled" ${isMShop ? 'isMShop' : ''}>
      ${links_mobile.join(' ')}
      </pui-section>
      
      <pui-section 
      flowDirection = "horizontal"
      spacingBottom = "medium"
      class = "pui-sub-nav-profile-awareness-bar-profile-buttons touch-screen-disabled">
      ${links.join(' ')}
      </pui-section>`;
  }

  _getSubNavProfileAwarenessBarTitleForDashboard() {
    const {
      strings,
    } = this._subNavData;

    const loadingBar = `<pui-section 
                      spacingBottom = "small" spacingTop = "medium">
                      <div class = "pui-loading-heading-large-light-dashboard" id = "loading-dashboard-heading"></div>
                      </pui-section>`;
    if (!strings) {
      return loadingBar;
    }
    return `<pui-section spacingBottom = "small" spacingTop = "medium">
      <pui-heading input = "${strings['dashboard-heading']}" textSize = "extra-large" id = "dashboard-heading">
        </pui-heading>
      </pui-section>`;
  }

  _getSubNavProfileAwarenessBarForDashboard() {
    const profileAwareBarTitleForDashboard = this._getSubNavProfileAwarenessBarTitleForDashboard();
    const profileAwareBarButtonsForDashboard = this._getSubNavProfileAwarenessBarButtonsForDashboard();
    const profileAwareBarLoadingBottomSheetForDashboard = this._getSubNavProfileAwarenessBarLoadingBottomSheetForDashboard();

    this._subNavProfileAwarenessBarForDashboard.innerHTML = `${profileAwareBarTitleForDashboard} 
  ${profileAwareBarButtonsForDashboard} ${profileAwareBarLoadingBottomSheetForDashboard}`;

    this._profileSwitchLoadingBottomSheet = this.querySelector('#profile-switch-loading-bottom-sheet');
    this._profileSwitchLoadingBottomSheetContent = this.querySelector('#profile-switch-loading-bottom-sheet-content');
    this._addEventListenerForProfileSwitchButtons();
    this._addEventListenerForAddDependentButton();
    this._addEventListenerForProfileSwitchButtons(true);
    this._addEventListenerForAddDependentButton(true);
  }

  _getSubNavProfileAwarenessBarLoadingBottomSheetForDashboard() {
    const { isMShop } = this;
    return `<pui-bottom-sheet id="profile-switch-loading-bottom-sheet" hideLink hideClose ${isMShop ? 'isMShop' : ''}>
      <pui-section id="profile-switch-loading-bottom-sheet-content"  paddingBottom="large">
      </pui-section>
    </pui-bottom-sheet>`;
  }

  /**
   * HTML for the exposed help sub nav link. Used in mweb and desktop.
   */
  _getSubNavHelpLinkHTML(id, spacingRight, spacingLeft, sectionClass, textColor, iconClass, ref_) {
    const navLinkId = id || '';
    const helpLinkSpacingRight = spacingRight || 'none';
    const helpLinkSpacingLeft = spacingLeft || 'none';
    const puiSectionClass = sectionClass || '';
    const helpLinkTextColor = textColor || 'black-color';
    const helpButtonIconClass = iconClass || "info-bubble-black";
    const helpLinkRefTag = ref_ ? `?ref_=${ref_}` : '';
    return `
      <pui-section flowdirection="horizontal" spacingRight="${helpLinkSpacingRight}" spacingLeft="${helpLinkSpacingLeft}" style="max-width: max-content" class="${puiSectionClass}">
        <a id="${navLinkId}" href="/help${helpLinkRefTag}" style="white-space: nowrap;" class="hover-underline">
          <pui-button class="pui-sub-nav-help-hub-button" icon="${helpButtonIconClass}" label="Help" textColor="${helpLinkTextColor}" textSize="medium"></pui-button>
        </a>
      </pui-section>
    `;
  }

  _addEventListenerForAddDependentButton(isMobileView) {
    const buttonId = isMobileView ? '#profile-switch-add-mobile' : '#profile-switch-add';
    this._profileAwarenessBottomSheetAddDependentButton = this.querySelector(buttonId);
    this._profileAwarenessBottomSheetAddDependentButton.addEventListener('click', this._addProfile.bind(this));
    this._profileAwarenessBottomSheetAddDependentButton.addEventListener('keyup', this._keyListener.bind(this, this._addProfile.bind(this)));
  }

  _addEventListenerForProfileSwitchButtons(isMobileView) {
    const {
      personProfiles,
    } = this._subNavData;

    if (personProfiles) {
      personProfiles.forEach((profile, index) => {
        if (!profile.currentSignedIn && !this.isReadOnlyProfile) {
          const buttonId = isMobileView ? `#profile-switch-${index}-mobile` : `#profile-switch-${index}`;
          const profileSwitchButton = this.querySelector(buttonId);
          profileSwitchButton.addEventListener('click', this._switchProfile.bind(this, profile.pid, profile.friendlyName));
          profileSwitchButton.addEventListener('keyup', this._keyListener.bind(this, this._switchProfile.bind(this, profile.pid, profile.friendlyName)));
        }
      });
    }
  }
}

window.customElements.define('pui-sub-nav', PUISubNav);
