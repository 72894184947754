import PUIBase from '../pui-base';
import formatPhoneNumber from '../../functions/phoneNumber';

/**
 * This component is responsible for allowing a user to search for a pharmacy
 *
 * @callback onPharmacySelect
 * @param {Pharmacy} pharmacy - The pharmacy that got selected
 * @param {string} pharmacy.npi - National Provider Identifier
 * @param {string} pharmacy.spi - Surescripts Provider Identifier
 * @param {string} pharmacy.displayName - The pharmacies name in a display friendly format
 * @param {string} pharmacy.firstName - The pharmacies first name
 * @param {string} pharmacy.lastName - The pharmacies last name
 * @param {string} pharmacy.suffixName - The pharmacies suffix
 * @param {string} pharmacy.clinicName - The clinic for this pharmacy
 * @param {string} pharmacy.businessPracticeAddressTelephoneNumber - The clinic's phone number
 * @param {string} pharmacy.displayAddressLine1 - Line One for the clinic's address
 * @param {string} pharmacy.displayAddressLine2 - Line Two for the clinic's address
 *
 * Configuration:
 *
 * @param {PUIPharmacySearchConfig} this.config - The configuration for PUIPharmacySearch
 * @param {string} this.config.zipUpdaterId - The id for the zip code updater used to get the search coordinates
 * @param {string} this.config.searchUrl - The url used for searching pharmacys
 * @param {string} this.config.searchQueryDelay - Specify the delay in milliseconds before initiating a search query
 * @param {string} this.config.contactUsUrl - The url for the help page
 * @param {string} this.config.nextUrl - url path for next page
 * @param {string} this.config.strings.searchInputTitle - The title text used for this pharmacy search input
 * @param {string} this.config.strings.searchInputPlaceholder - The placeholder text for this pharmacy search input
 * @param {string} this.config.strings.noResultsFound - The text that shows when no results are found
 * @param {string} this.config.strings.changeZipText - The text that prompts you to change your zip code and try again
 * @param {string} this.config.strings.addPrescriptionLaterText - The text that prompts tyou to contineu without pharmacy
 * @param {string} this.config.strings.pharmacySearch.addPharmacySubtext - The text that prompts tyou to continue without pharmacy
 * @param {string} this.config.strings.contactUsPromptText - A prompt for the user to contact us, when no pharmacies
 *                                                           are found.
 * @param {string} this.config.strings.contactUsLinkText - The text for the contact us link
 * @param {string} this.config.strings.searchResultHelpHeader - The header text for search help
 * @param {string} this.config.strings.searchResultHelpBody - The text for search help
 * @param {onPharmacySelect} this.config.callbacks.onPharmacySelect - This callback gets called when a user selects
 *                                                                        a pharmacy from the search results
 */
export default class PUIPharmacySearch extends PUIBase {
  connectedCallback() {
    super.connectedCallback();
    this.upgradeProperty('config');
    this._render();
  }

  _render() {
    const {
      strings,
      searchQueryDelay,
    } = this.config;
    this.classList.add('pui-block');
    this.innerHTML = `
      <pui-search-input id="apex-pharmacy-search-input"
                        label="${strings.searchInputTitle}" placeholder="${strings.searchInputPlaceholder}"
                        minimumRequiredCharacters="2" searchQueryDelay="${searchQueryDelay}"
                        noResultsStyle="contact-us" noResultsText="${strings.noResultsFound}" 
                        contactUsPromptText="${strings.contactUsPromptText}" 
                        contactUsOptionOneText="${strings.changeZipText}"
                        contactUsOptionOneSubText=""
                        contactUsOptionOneUrl=""
                        contactUsOptionTwoText="${strings.contactUsLinkText}"
                        contactUsLinkText="${strings.contactUsLinkText}"
                        contactUsOptionTwoSubText=""
                        contactUsOptionTwoUrl="${this.config.contactUsUrl}"
                        contactUsLinkHref="${this.config.contactUsUrl}"
                        contactUsOptionThreeText="${strings.addPrescriptionLaterText}"
                        contactUsOptionThreeSubText="${strings.addPharmacySubtext}"
                        contactUsOptionThreeUrl="${this.config.nextUrl}"
                        searchResultHelp="true" searchResultHelpHeader="${strings.searchResultHelpHeader}"
                        searchResultHelpBody="${strings.searchResultHelpBody}">
      </pui-search-input>
    `;

    this._searchInput = this.querySelector('#apex-pharmacy-search-input');

    this._searchInput.queryUrlBuilder = this._queryUrlBuilder.bind(this);
    this._searchInput.querySuccessCb = this._querySuccessCb.bind(this);
    this._searchInput.onSearchResultClick = this._onSearchResultClick.bind(this);
  }

  _queryUrlBuilder(dataSearchUrl, pharmacySearchInputValue) {
    const { latitude, longitude } = document.querySelector(`#${this.config.zipUpdaterId}`).getCoordinates();
    return `${this.config.searchUrl}?pharmacyName=${pharmacySearchInputValue}&latitude=${latitude}&longitude=${longitude}`;
  }

  _querySuccessCb(response) {
    const pharmacies = response;
    const searchResults = [];
    pharmacies.forEach((pharmacy) => {
      const legalBusinessName = (pharmacy.legalBusinessName) ? pharmacy.legalBusinessName : '';
      const physicalLocationPhoneNumber = formatPhoneNumber(pharmacy.physicalLocationPhoneNumber);
      const html = `
        <div>
          <pui-text fontWeight="bold" input="${pharmacy.name}"></pui-text>
          <pui-text input="${legalBusinessName}" hideIfEmpty></pui-text>
          <pui-text input="${pharmacy.physicalLocationAddress1}"></pui-text>
          <pui-text input="${pharmacy.physicalLocationCity}, ${pharmacy.physicalLocationStateCode} ${pharmacy.physicalLocationZipCode}"></pui-text>
          <pui-text input="${physicalLocationPhoneNumber}"></pui-text>
        </div>
      `;
      const searchResult = {
        html,
        ...pharmacy,
      };
      searchResults.push(searchResult);
    });
    this._searchInput.updateSearchResults(searchResults);
  }

  _onSearchResultClick(pharmacy) {
    const { onPharmacySelect } = this.config.callbacks;
    if (onPharmacySelect) {
      const selectedPharmacy = pharmacy;
      selectedPharmacy.addressLineOne = pharmacy.displayAddressLine1;
      selectedPharmacy.addressLineTwo = pharmacy.displayAddressLine2;
      onPharmacySelect(selectedPharmacy);
    }
  }
}

window.customElements.define('pui-pharmacy-search', PUIPharmacySearch);
