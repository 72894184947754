import * as shadyCss from '@webcomponents/shadycss';
import PUIBase from './pui-base';

// eslint-disable-next-line import/no-unresolved
import listItemCSS from '../../css/_list-item.scss?inline';

const properties = ['color', 'listStyle'];

const innerTemplate = `
  <style>
    ${listItemCSS}
  </style>
  <li class="pui-list-item">
    <slot></slot>
  </li>
`;

const template = document.createElement('template');

template.innerHTML = innerTemplate;

shadyCss.prepareTemplate(template, 'pui-list-item');

export default class PUIListItem extends PUIBase {
  constructor() {
    super();

    shadyCss.styleElement(this);
    if (!this.shadowRoot) {
      this.attachShadow({ mode: 'open' });
      this.shadowRoot.appendChild(
        document.importNode(template.content, true),
      );
    }
    this.upgradeProperty = this.upgradeProperty.bind(this);
    this.defaultColor = 'white';
    this.defaultListStyle = 'circle';
  }

  attributeChangedCallback(name, oldValue, newValue) {
    let {
      color, listStyle,
    } = this;

    if (!this.color) {
      color = this.defaultColor;
    }
    color = `pui-list-item-${color}`;
    this.classList.add(color);

    if (!this.listStyle) {
      listStyle = this.defaultListStyle;
    }
    listStyle = `pui-list-item-style-${listStyle}`;
    this.classList.add(listStyle);

    properties.forEach(this.upgradeProperty);
  }

  connectedCallback() {
    super.connectedCallback();
    this.classList.add('pui-list-item-container');
    this.setAttribute('role', 'listitem');
  }

  static get observedAttributes() {
    return ['color', 'listStyle'];
  }

  /**
   * @classprop {string} text This is the attribute for list item
   *
   */
  get color() {
    return this.getAttribute('color') || '';
  }

  set color(value) {
    this.setAttribute('color', value);
  }

  get listStyle() {
    return this.getAttribute('listStyle') || '';
  }

  set listStyle(value) {
    this.setAttribute('listStyle', value);
  }
}

window.customElements.define('pui-list-item', PUIListItem);
