import PUIBase from './pui-base';
import * as shadyCss from '@webcomponents/shadycss';

const properties = ['flexGrid', 'top'];

const innerTemplate = `
  <slot>
  </slot>
`;

let template = document.createElement('template');

template.innerHTML = innerTemplate;

shadyCss.prepareTemplate(template, 'pui-section-column');

class PUISectionColumn extends PUIBase {
  constructor() {
    super();
    this.defaultClass = 'pui-section-column';

    shadyCss.styleElement(this);
    if (!this.shadowRoot) {
      this.attachShadow({mode: 'open'});
      this.shadowRoot.appendChild(
        document.importNode(template.content, true));
    }

    this.upgradeProperty = this.upgradeProperty.bind(this);
  }

  connectedCallback() {
    super.connectedCallback();
    const {
      flexGrid,
    } = this;

    this.classList.add('pui-inline-block');
    this.classList.add(this.defaultClass);
    if (flexGrid) {
      this.setAttribute('style', `flex: ${flexGrid}`);
    }
  }

  static get observedAttributes() {
    return ['flexGrid', 'top'];
  }

  /**
   * @classprop {string} flexGrid This is the attribute that determines the flexGrid properties of the section column
   */
  get flexGrid() {
    return this.getAttribute('flexGrid') || '';
  }

  set flexGrid(value) {
    this.setAttribute('flexGrid', value);
  }

}

window.customElements.define('pui-section-column', PUISectionColumn);
