import PUIBase from "../../pui-base";
import PUIButton from "../../pui-button";

export class PUICustomerCareChatWidget extends PUIBase {
    /** @type {PUIButton} */
    #button;

    attributeChangedCallback() {
        this.#render();
    }

    connectedCallback() {
        super.connectedCallback();
        this.#render();
    }

    #render() {
        this.innerHTML = `<pui-button 
            class="pui-sub-nav-customer-care-chat-button desktop-only"
            width="auto"
            icon="chat-bubble-black-icon"
            label="Chat with Customer Care"
            textColor="black-color"
            spacingRight="small">
        </pui-button>`;
        this.#button = this.querySelector("pui-button");
        this.#button.onButtonPress = () => {
            window.open(Urls.CUSTOMER_CARE_CHAT_URL, 'popup', 'width=500,height=800');
        };
    }
}

window.customElements.define('pui-care-chat-widget', PUICustomerCareChatWidget);
