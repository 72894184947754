/**
 * @param token the base name of a class or a selector
 * @param version the version of the class or selector to be returned, usually will be empty (for 1.0) or "2" (for 2.0)
 * @returns the name of the 
 */
function generateVersionedName(name, version) {
    if (version) {
        return `${name}-${version}`;
    }
    return name
}
/**
 * 
 * @param  classes 
 * @param version 
 * @returns an object that enumerates all the correct class and selector names according to the version provided
 * (provided the classes are named correctly in the corresponding scss files, should be "{baseName}-2_0")
 */

function getVersionedClassesAndSelector(classes, version) {
    let classesAndSelectorNames = {};
    for (var key in classes) {
        classesAndSelectorNames[key] = generateVersionedName(classes[key], version);
    }
    return classesAndSelectorNames;
}

export default getVersionedClassesAndSelector;