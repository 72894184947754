import PUIBase from './pui-base';
import { injectPUIStyles } from '../functions/domUtils';

/**
 * Component which displays the price recipe for a medication offer.
 *
 * This element provides the skeletal structure that defines where each pricing
 * piece should be positioned in the price recipe (i.e. final price, original
 * price, price label).
 *
 * The full offer display template with breakdown of recipes can be found on Figma:
 * https://www.figma.com/file/aJvL7IvdBuq6Bq21pfA0mZ/Offer-Display-V2?node-id=616-16920
 */
export default class PUIPriceRecipe extends PUIBase {
  connectedCallback() {
    super.connectedCallback();
    this.render();
  }

  attributeChangedCallback() {
    if (!this.isConnected) return;
    super.attributeChangedCallback();
    this.render();
  }

  static get observedAttributes() {
    return [
      ...super.observedAttributes,
      'currencysymbol', 'wholevalue', 'fractionalvalue',
      'strikethroughwholevalue', 'strikethroughdecimalseparator',
      'strikethroughfractionalvalue', 'pricelabel',
    ];
  }


  /**
   * A string indicating that the numbers in the recipe are monetary values.
   * @attr
   */
  get currencySymbol() {
    return this.getAttribute('currencySymbol') || '';
  }

  set currencySymbol(value) {
    this.setAttribute('currencySymbol', value);
  }


  /**
   * Whole, non-fractional part of the final (i.e. post-discounted) price,
   * which can include digit group separators such as commas or periods
   * appropriate to the locale, e.g. '10,000' for US locale.
   * @attr
   */
  get wholeValue() {
    return this.getAttribute('wholeValue') || '';
  }

  set wholeValue(value) {
    this.setAttribute('wholeValue', value);
  }


  /**
   * Fractional value of the final price.
   * @attr
   */
  get fractionalValue() {
    return this.getAttribute('fractionalValue') || '';
  }

  set fractionalValue(value) {
    this.setAttribute('fractionalValue', value);
  }


  /**
   * Whole, non-fractional part of the original (i.e. pre-discounted),
   * strikethrough price, which can include digit group separators
   * such as commas or periods appropriate to the locale,
   * e.g. '10,000' for US locale.
   * Should be left empty if there are no discounts to show.
   * @attr
   */
  get strikethroughWholeValue() {
    return this.getAttribute('strikethroughWholeValue') || '';
  }

  set strikethroughWholeValue(value) {
    this.setAttribute('strikethroughWholeValue', value);
  }

  /**
   * The character used to separate the integer value from the fractional value
   * appropriate to target locale, eg. '.' for US locale, ',' for DE locale.
   * Should be left empty if there are no discounts to show.
   * @attr
   */
  get strikethroughDecimalSeparator() {
    return this.getAttribute('strikethroughDecimalSeparator') || '';
  }

  set strikethroughDecimalSeparator(value) {
    this.setAttribute('strikethroughDecimalSeparator', value);
  }

  /**
   * Fractional value of the original, strikethrough price.
   * Should be left empty if there are no discounts to show.
   * @attr
   */
  get strikethroughFractionalValue() {
    return this.getAttribute('strikethroughFractionalValue') || '';
  }

  set strikethroughFractionalValue(value) {
    this.setAttribute('strikethroughFractionalValue', value);
  }

  /**
   * Label on the price recipe, e.g. 'Retail price', 'Prime price'.
   * @attr
   */
  get priceLabel() {
    return this.getAttribute('priceLabel') || '';
  }

  set priceLabel(value) {
    this.setAttribute('priceLabel', value);
  }


  render() {
    const {
      currencySymbol,
      wholeValue,
      fractionalValue,
      strikethroughWholeValue,
      strikethroughDecimalSeparator,
      strikethroughFractionalValue,
      priceLabel,
    } = this;

    if (!this.shadowRoot) {
      this.attachShadow({ mode: 'open' });
    }

    const finalPriceHTML = `
      <pui-price 
        currencySymbol="${currencySymbol}"
        wholeValue="${wholeValue}"
        fractionalValue="${fractionalValue}"
        priceSize="large"
      >
      </pui-price>
    `;

    const shouldRenderStrikethroughPrice = strikethroughWholeValue
      || strikethroughDecimalSeparator || strikethroughFractionalValue;
    const strikethroughPriceHTML = shouldRenderStrikethroughPrice
      ? `<pui-price
            spacingLeft="mini"
            currencySymbol="${currencySymbol}"
            wholeValue="${strikethroughWholeValue}"
            decimalSeparator="${strikethroughDecimalSeparator}"
            fractionalValue="${strikethroughFractionalValue}"
            priceSize="base"
            strikethrough="true"
         >
         </pui-price>`
      : '';

    this.shadowRoot.innerHTML = `
      <pui-section flowDirection="vertical">
        <pui-section flowDirection="horizontal" secondaryAxisArrangement="center">
          ${finalPriceHTML}
          ${strikethroughPriceHTML}
        </pui-section>
        <pui-text textSize="small-medium" input="${priceLabel}"></pui-text>
        <slot></slot>
      </pui-section>
    `;

    injectPUIStyles(this.shadowRoot);
  }
}

window.customElements.define('pui-price-recipe', PUIPriceRecipe);
