import PUIBase from './pui-base';

class PUITouchLink extends PUIBase {
  constructor() {
    super();
    this.defaultClass = 'pui-touch-link';
  }

  connectedCallback() {
    super.connectedCallback();
    this.render();
  }

  render() {
    // TODO: Refactor using shadowDOM
    const innerElements = this.innerHTML;

    this.innerHTML = '';

    // TODO turn back into an a tag when UX settles.
    this.classList.add('pui-block');
    this._innerTouchLink = document.createElement('span');
    this._innerTouchLink.classList.add(this.defaultClass);
    this._innerTouchLink.classList.add('pui-box-shadow');
    this._innerTouchLink.innerHTML = `
                <div class="pui-box-inner" tabindex="0">
                    <pui-section flowDirection="horizontal" mainAxisArrangement="space-between" secondaryAxisArrangement="center">
                      <pui-text input="${innerElements}"></pui-text>
                      <pui-icon imgClass="chevron-right"></pui-icon>
                    </pui-section>
                </div>
            `;
    this.appendChild(this._innerTouchLink);
  }
}
// Register the new element with the browser.
window.customElements.define('pui-touch-link', PUITouchLink);
