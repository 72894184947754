import PUIHealthEntityPage from '../health-entity/pui-health-entity-page';
import PUIAllergySearch from './pui-allergy-search';

/**
 * Configuration:
 *
 * @param {PUIAllergiesPageConfig} this.config - This configuration for PUIAllergiesPage
 * @param {string} this.config.pageType - Either 'signup' or 'dashboard'
 * @param {string} this.config.backUrl - The url used for going to the previous page (dashboard only)
 * @param {string} this.config.actionUrl - The url for saving allergies
 * @param {string} this.config.nextUrl - The url for the page to transition to after saving allergies
 * @param {string} this.config.searchUrl - The url used for searching allergies
 * @param {string} this.config.getUrl - The url used to get all allergies, common allergies, and isReviewed flag
 * @param {array} this.config.commonAllergies - The common allergies to add to the chiclet container
 * @param {boolean} this.config.isReviewed - Flag if customer has already reviewed their allergies
 * @param {boolean} this.config.hideCommonAllergies - Flag if need to hide common allergies
 * @param {boolean} this.config.disablePageContainer - Flag that will remove `pageContainer` created in underlying
 *                                                     pui-sections which consequently does not include
 *                                                     pui-section-padding scss class.
 * @param {string} this.config.strings.continueButton The string for the saving the selected allergies
 * @param {string} this.config.strings.noSelectionErrorText - The text used for when there's no allergies selected
 * @param {string} this.config.strings.serverErrorText - The text used for when there's a server error
 * @param {string} this.config.strings.commonAllergiesTitle - The title text for common allergies
 * @param {string} this.config.strings.noAllergiesLabel - The label text for no allergies
 * @param {string} this.config.strings.searchInputTitle - The title text used for this allergy search input
 * @param {string} this.config.strings.searchInputPlaceholder - The placeholder text for this allergy search input
 * @param {string} this.config.strings.noResultsFound - The text that shows when no results are found
 * @param {string} this.config.strings.addManuallyText - The text that shows when no results are found
 * @param {string} this.config.strings.itemCloseLabel - The text that indicates chiclet item close pui-icon name for accessibility usage
 *
 * @callback onSave
 * @param {onSave} this.config.callbacks.onSave - A callback that is invoked when saving allergies
 */
export default class PUIAllergiesPage extends PUIHealthEntityPage {
  connectedCallback() {
    super.connectedCallback();
    this.upgradeProperty('config');
    this._render();
  }

  _render() {
    const { strings } = this.config;

    const searchInput = new PUIAllergySearch();
    searchInput.configure({
      ...this.config,
      callbacks: {
        onAllergySelect: this._onAllergySelect.bind(this),
        onAllergyManualAdd: this._onAllergyManualAdd.bind(this),
      },
    });

    const config = {
      ...this.config,
      headerIconClass: this.config.headerIconClass ? this.config.headerIconClass : 'dash-food-med-allergy-icon',
      commonEntities: this.config.commonAllergies,
      hideCommonHealthEntities: this.config.hideCommonAllergies,
      disablePageContainer: this.config.disablePageContainer,
      searchInput,
      prefixId: 'allergies',
      strings: {
        ...strings,
        commonHealthEntitiesTitle: strings.commonAllergiesTitle,
        noHealthEntitiesLabel: strings.noAllergiesLabel,
      },
      fetchResponseConverter: (response) => {
        const { allergies, commonAllergies, isReviewed } = response;
        const entities = allergies.map(allergy => ({ name: allergy.name, id: allergy.pillpackAllergyId }), []);
        const commonEntities = commonAllergies.map(commonAllergy => ({ name: commonAllergy.friendly, id: commonAllergy.id }), []);
        return {
          entities,
          commonEntities,
          isReviewed,
        };
      },
      selectedOptionConverter: selected => ({ name: selected[0], pillpackAllergyId: selected[1] }),
      saveConverter: allergiesToAdd => ({ allergiesToAdd, allergiesToRemove: [] }),
    };

    super.configure(config);
    super._render();
  }

  _onAllergySelect(allergy) {
    super.addEntity(allergy.html, allergy.id);
  }

  _onAllergyManualAdd(allergyLabel) {
    super.addEntity(allergyLabel, '');
  }
}

window.customElements.define('pui-allergies-page', PUIAllergiesPage);
