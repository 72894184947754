/**
 * Port of jQuery scrollParent to get the closest element that is scrollable
 * https://github.com/jquery/jquery-ui/blob/main/ui/scroll-parent.js
 * @param element
 * @param includeHidden
 * @returns {HTMLElement}
 */
export default function getScrollParent(element, includeHidden) {
    var style = getComputedStyle(element);
    var excludeStaticParent = style.position === "absolute";
    var overflowRegex = includeHidden ? /(auto|scroll|hidden)/ : /(auto|scroll)/;

    if (style.position === "fixed") return document.body;
    for (var parent = element; (parent = parent.parentElement);) {
        style = getComputedStyle(parent);
        if (excludeStaticParent && style.position === "static") {
            continue;
        }
        if (overflowRegex.test(style.overflow + style.overflowY + style.overflowX)) return parent;
    }

    return document.body;
}