import PUIHealthEntityPage from '../health-entity/pui-health-entity-page';
import PUIHealthConditionSearch from './pui-health-condition-search';

/**
 * Configuration:
 *
 * @param {PUIHealthConditionsPageConfig} this.config - This configuration for PUIHealthConditionsPage
 * @param {string} this.config.pageType - Either 'signup' or 'dashboard'
 * @param {string} this.config.backUrl - The url used for going to the previous page (dashboard only)
 * @param {string} this.config.actionUrl - The url for saving health conditions
 * @param {string} this.config.nextUrl - The url for the page to transition to after saving health conditions
 * @param {string} this.config.searchUrl - The url used for searching health conditions
 * @param {string} this.config.getUrl - The url used to get all health conditions, common health conditions, and isReviewed flag
 * @param {string} this.config.contactUsUrl - The url used for the contact us option
 * @param {string} this.config.headerIconClass - The icon class that is displayed in the header
 * @param {array} this.config.commonHealthConditions - The common health conditions to add to the chiclet container
 * @param {boolean} this.config.isReviewed - Flag if customer has already reviewed their health conditions
 * @param {boolean} this.config.hideCommonHealthConditions - Flag if need to hide common HealthConditions
 * @param {boolean} this.config.disablePageContainer - Flag that will remove `pageContainer` created in underlying
 *                                                     pui-sections which consequently does not include
 *                                                     pui-section-padding scss class.
 * @param {string} this.config.strings.headerTitle - The page header title. Must be present to render the page header.
 * @param {string} this.config.strings.headerDescription - The page header description
 * @param {string} this.config.strings.subheaderText - The page subheader text. Must be present to render the page subheader.
 * @param {string} this.config.strings.continueButton The string for the saving the selected health conditions
 * @param {string} this.config.strings.noSelectionErrorText - The text used for when there's no health conditions selected
 * @param {string} this.config.strings.serverErrorText - The text used for when there's a server error
 * @param {string} this.config.strings.commonHealthConditionsTitle - The title text for common health conditions
 * @param {string} this.config.strings.noHealthConditionsLabel - The label text for no health conditions
 * @param {string} this.config.strings.searchInputTitle - The title text used for this health condition search input
 * @param {string} this.config.strings.searchInputPlaceholder - The placeholder text for this health condition search input
 * @param {string} this.config.strings.noResultsFound - The text that shows when no results are found
 * @param {string} this.config.strings.contactUsPromptText - The prompt text for the contact us option
 * @param {string} this.config.strings.contactUsLinkText - The link text for the contact us option
 * @param {string} this.config.strings.itemCloseLabel - The text that indicates chiclet item close pui-icon name for accessibility usage
 *
 * @callback onSave
 * @param {string} this.config.callbacks.onSave - A callback that is invoked when saving health conditions
 */
export default class PUIHealthConditionsPage extends PUIHealthEntityPage {
  connectedCallback() {
    super.connectedCallback();
    this.upgradeProperty('config');
    this._render();
  }

  _render() {
    const { strings } = this.config;

    const searchInput = new PUIHealthConditionSearch();
    searchInput.configure({
      ...this.config,
      callbacks: {
        onHealthConditionSelect: this._onHealthConditionSelect.bind(this),
      },
    });

    const config = {
      ...this.config,
      headerIconClass: this.config.headerIconClass ? this.config.headerIconClass : 'dash-health-conditions-icon',
      commonEntities: this.config.commonHealthConditions,
      hideCommonHealthEntities: this.config.hideCommonHealthConditions,
      disablePageContainer: this.config.disablePageContainer,
      searchInput,
      prefixId: 'health-conditions',
      strings: {
        ...strings,
        commonHealthEntitiesTitle: strings.commonHealthConditionsTitle,
        noHealthEntitiesLabel: strings.noHealthConditionsLabel,
      },
      fetchResponseConverter: (response) => {
        const { healthConditions, commonHealthConditions, isReviewed } = response;
        const entities = healthConditions.map(hc => ({ name: hc.name, id: hc.pillpackConditionId }), []);
        const commonEntities = commonHealthConditions.map(commonHc => ({ name: commonHc.friendly, id: commonHc.id }), []);
        return {
          entities,
          commonEntities,
          isReviewed,
        };
      },
      selectedOptionConverter: selected => ({ name: selected[0], pillpackConditionId: selected[1] }),
      saveConverter: conditionsToAdd => ({ conditionsToAdd, conditionsToRemove: [] }),
    };

    super.configure(config);
    super._render();
  }

  _onHealthConditionSelect(healthCondition) {
    super.addEntity(healthCondition.html, healthCondition.id);
  }
}

window.customElements.define('pui-health-conditions-page', PUIHealthConditionsPage);
