import { attachmentPreviewType, compressedImageConfiguration, ERROR_CODES } from '../../constants/fileUpload';
import PUIBase from '../pui-base';
import PUIFilePreview from './pui-file-preview';
import PUIFile from './pui-file';
import { isImageFile } from '../../functions/fileUtils';

/**
 * This class is responsible for creating the file upload preview container to house the previews of different files.
 * It allows another component to quickly add or remove a series of files such as images, pdfs, etc.
 * It leverages pui-file-preview to create instances of each file preview.
 *
 * Callbacks:
 *
 * @callback onRemoved - Callback invoked upon removing an existing file preview.
 * @callback onAdded - Callback invoked upon adding a new file preview.
 * @callback onError - Callback invoked if there is an error while adding a new file preview.
 *
 * Configuration:
 *
 * @param this.previewAppearanceType - File preview type (full or compact)
 * @param this.isMobileDevice - Client using mobile device or not
 * @param this.filesAddedMap - Map containing file name as key and file object as value.
 * @param this.displayBottomSheetOnRemoval - Boolean to indicate whether bottom sheet needs to be displayed for user confirmation before deletion.
 * @param this.maximumAllowedNumberOfFiles - Maximum number of files the user can select or upload
 * @param this.imageThresholdSizeToCompress - Number in bytes to indicate the threshold when PUI is compressing the image, set to 0 will enable compression on every image.
 *
 */
export default class PUIFilePreviewContainer extends PUIBase {
  constructor() {
    super();
    this.innerHTML = '<div class="pui-file-preview-container"></div>';
    this._previews = this.querySelector('div.pui-file-preview-container');
    this.defaultPreviewAppearanceType = attachmentPreviewType.PREVIEW_TYPE_FULL;
  }

  connectedCallback() {
    super.connectedCallback();
    this.upgradeProperty('previewTitle');
    this._setupComponent();
  }

  _setupComponent() {
    this._previews.innerHTML = '';
    if (this.previewAppearanceType === attachmentPreviewType.PREVIEW_TYPE_FULL) {
      this._previews.classList.add('pui-file-preview-container-full');
    }
    this.filesAddedMap = new Map();
  }

  get previewAppearanceType() {
    return this.getAttribute('previewAppearanceType') || this.defaultPreviewAppearanceType;
  }

  set previewAppearanceType(value) {
    this.setAttribute('previewAppearanceType', value);
  }

  get isMobileDevice() {
    return this.getAttribute('isMobileDevice') || false;
  }

  set isMobileDevice(value) {
    this.setAttribute('isMobileDevice', value);
  }

  get displayBottomSheetOnRemoval() {
    return this.getBooleanAttribute('displayBottomSheetOnRemoval');
  }

  set displayBottomSheetOnRemoval(displayBottomSheetOnRemoval) {
    this.setBooleanAttribute('displayBottomSheetOnRemoval', displayBottomSheetOnRemoval);
  }

  get maximumAllowedNumberOfFiles() {
    return this.getAttribute('maximumAllowedNumberOfFiles');
  }

  set maximumAllowedNumberOfFiles(value) {
    this.setAttribute('maximumAllowedNumberOfFiles', value);
  }

  get imageThresholdSizeToCompress() {
    return this.getAttribute('imageThresholdSizeToCompress');
  }

  set imageThresholdSizeToCompress(value) {
    const threshold = parseInt(value, 10);
    if (value != null && Number.isNaN(threshold)) {
      throw new Error('imageThresholdSizeToCompress property is not a number');
    }
    this.setAttribute('imageThresholdSizeToCompress', value);
  }


  /**
   * Renders the container with the file previews. This method is called to add file previews.
   * @param puiFiles: List of PUIFile objects.
   */
  render(puiFiles) {
    puiFiles.forEach((puiFile) => {
      this.addFilePreview(puiFile);
    });
  }

  /**
   * Add a file preview.
   * @param puiFile PUIFile object.
   */
  addFilePreview(puiFile) {
    // Check duplicate id or if number of files exceeded allowed count
    if (this.filesAddedMap.has(puiFile.id) || !this._validMaximumAllowedFiles(puiFile)) {
      return;
    }

    // Run image compression if:
    // 1. It's an image file
    // 2. It's a new file that's added from the file chooser
    // 3. The file size is exceeding the threshold limit for compression
    if (this.imageThresholdSizeToCompress != null
      && isImageFile(puiFile)
      && puiFile.fileObject
      && puiFile.fileObject.size > this.imageThresholdSizeToCompress) {
      this._compressImage(puiFile);
    } else {
      this._addFilePreviewCallback(puiFile);
    }
  }

  /**
   * Callback to add puiFile to PUIFilePreview component
   * @param puiFile
   */
  _addFilePreviewCallback(puiFile) {
    const puiFilePreview = new PUIFilePreview();
    puiFilePreview.configure(this.config);
    puiFilePreview.previewAppearanceType = this.previewAppearanceType;
    puiFilePreview.displayBottomSheetOnRemoval = this.displayBottomSheetOnRemoval;
    puiFilePreview.puiFile = puiFile;
    puiFilePreview.onRemoved = this._removeFilePreview.bind(this);
    this._previews.appendChild(puiFilePreview);

    this.filesAddedMap.set(puiFile.id, puiFile);

    if (this.onAdded) {
      this.onAdded(puiFile);
    }
  }

  /**
   * Validate maximum file size of a newly added file.
   * @param puiFile
   */
  _validMaximumAllowedFiles(file) {
    const previewFiles = Array.from(this.filesAddedMap.values());
    if (previewFiles.length + 1 > this.maximumAllowedNumberOfFiles) {
      if (this.onError) {
        this.onError({ file, errorCode: ERROR_CODES.MAXIMUM_ALLOWED_FILES_EXCEEDED });
      }
      return false;
    }
    return true;
  }

  /**
   * Callback method to update the current state of the container once a file is removed.
   * @param puiFile PUIFile object
   */
  _removeFilePreview(puiFile) {
    this.filesAddedMap.delete(puiFile.id);

    if (this.onRemoved) {
      this.onRemoved(puiFile);
    }
  }

  /**
   * Function to compress uploaded image in the file input component.
   * We are making use of the toBlob method of the canvas element to compress the image.
   */
  _compressImage(puiFile) {
    const _this = this;
    const helperImageObj = new Image();
    helperImageObj.onerror = function () {
      URL.revokeObjectURL(this.src);
      // If error, skip the compress process and load the image
      _this._addFilePreviewCallback(puiFile);
    };

    helperImageObj.onload = function () {
      URL.revokeObjectURL(this.src);
      const canvas = document.createElement('canvas');
      [canvas.width, canvas.height] = _this._calculateImageResizeDimensions(this,
        compressedImageConfiguration.COMPRESSED_IMAGE_MAX_WIDTH,
        compressedImageConfiguration.COMPRESSED_IMAGE_MAX_HEIGHT);
      const ctx = canvas.getContext('2d');
      ctx.drawImage(this, 0, 0, canvas.width, canvas.height);
      canvas.toBlob((blob) => {
        const { fileObject } = puiFile;
        const compressedFile = new File([blob], fileObject.name, { type: fileObject.type, lastModified: Date.now() });
        const reader = new FileReader();
        reader.onloadend = (() => {
          // file is loaded
          const compressedPUIFile = new PUIFile(`id_${Date.now()}`, fileObject.name, fileObject.type, reader.result, compressedFile);
          _this._addFilePreviewCallback(compressedPUIFile);
        });

        reader.readAsDataURL(compressedFile);
      }, puiFile.fileObject.type, compressedImageConfiguration.COMPRESSED_IMAGE_QUALITY);
    };

    // Set the `onload` handler before `src` because `onload` can trigger immediately when we set `src`
    helperImageObj.src = URL.createObjectURL(puiFile.fileObject);
  }

  /**
   * Function to calculate target dimensions of the compressed image
   * while maintaining proportions.
   *
   * @param img HTMLImageElement to compress.
   * @param maxWidth max width of compressed image.
   * @param maxHeight max height of compressed image.
   */
  _calculateImageResizeDimensions(img, maxWidth, maxHeight) {
    let { width, height } = img;
    // calculate the width and height, constraining the proportions
    if (width > height) {
      if (width > maxWidth) {
        height = Math.round((height * maxWidth) / width);
        width = maxWidth;
      }
    } else if (height > maxHeight) {
      width = Math.round((width * maxHeight) / height);
      height = maxHeight;
    }

    return [width, height];
  }
}

window.customElements.define('pui-file-preview-container', PUIFilePreviewContainer);
