import * as shadyCss from '@webcomponents/shadycss';

import PUIBase from './pui-base';
// eslint-disable-next-line import/no-unresolved
import accordionPageCSS from '../../css/_accordion.scss?inline';

const defaultClass = 'pui-accordion';

const innerTemplate = `
  <style>
    :host {
      display: block;
    }
    ${accordionPageCSS}
  </style>
  <div class="${defaultClass}">
    <slot>
        Inner Accordion Items
    </slot>
  </div>
`;

const template = document.createElement('template');

template.innerHTML = innerTemplate;

shadyCss.prepareTemplate(template, 'pui-accordion');

class PUIAccordion extends PUIBase {
  get theme() {
    return this.getAttribute('theme') || '';
  }

  set theme(value) {
    this.setAttribute('theme', value);
  }

  constructor() {
    super();


    shadyCss.styleElement(this);
    if (!this.shadowRoot) {
      this.attachShadow({ mode: 'open' });
      this.shadowRoot.appendChild(
        document.importNode(template.content, true),
      );
    }
  }

  connectedCallback() {
    super.connectedCallback();
    this.setUpComponent();
  }

  setUpComponent() {
    const accordion = this.shadowRoot.querySelector(`.${defaultClass}`);
    if (this.theme === 'minimal') {
      accordion.classList.add(`${defaultClass}-minimal`);
    } else if (this.theme === 'clinic') {
      accordion.classList.add(`${defaultClass}-clinic`);
    }
  }
}

window.customElements.define('pui-accordion', PUIAccordion);
