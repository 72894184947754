import PUIBase from './pui-base';


class PUISignUpBackground extends PUIBase {
  constructor() {
    super();
    this.defaultClass = 'pui-signup-background';
  }

  connectedCallback() {
    this.render();
  }

  get milestonePage() {
    return this.getBooleanAttribute('milestonePage');
  }

  set milestonePage(value) {
    return this.setBooleanAttribute('milestonePage', value);
  }

  get milestoneTransitionPage() {
    return this.getBooleanAttribute('milestoneTransitionPage');
  }

  set milestoneTransitionPage(value) {
    return this.setBooleanAttribute('milestoneTransitionPage', value);
  }

  render() {
    if (this.milestonePage) {
      this.classList.add('pui-signup-background-milestone');
    } else if (this.milestoneTransitionPage) {
      this.classList.add('pui-signup-background-milestone-transition');
    } else {
      this.classList.add(this.defaultClass);
    }

    this.attachShadow({
      mode: 'open',
    });
    this.shadowRoot.innerHTML = `
        <slot>
          Body Elements
        </slot>
    `;
  }
}

window.customElements.define('pui-signup-background', PUISignUpBackground);
