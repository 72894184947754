import PUIBase from './pui-base';

// eslint-disable-next-line import/no-unresolved
import iconButtonContainerCSS from '../../css/_icon-button.scss?inline';

class PUIIconButtonContainer extends PUIBase {
  constructor() {
    super();
  }

  connectedCallback() {
    super.connectedCallback();
    this.render();
  }

  render() {
    this.classList.add('pui-inline-block');
    this.attachShadow({
      mode: 'open',
    });
    this.shadowRoot.innerHTML = `
      <style>
        ${iconButtonContainerCSS}
      </style>
      <div class="pui-icon-button-container">
        <slot>
          Inner Elements
        </slot>
      </div>
    `;
    this.onclick = (event) => {
      this.iconButtonClicked(event);
    };
  }

  iconButtonClicked(event) {
    const iconButtonElements = this.getElementsByTagName('pui-icon-button');
    let elementClickedIndex = -1;
    let currentFocusedIndex = -1;
    for (let i = 0; i < iconButtonElements.length; i += 1) {
      const element = iconButtonElements[i];
      if (element.contains(event.target)) {
        elementClickedIndex = i;
      }
      if (element.lastChild.getElementsByTagName('button')[0].classList.contains('selected-icon-button')) {
        currentFocusedIndex = i;
      }
    }
    if (elementClickedIndex >= 0 && currentFocusedIndex >= 0 && elementClickedIndex !== currentFocusedIndex) {
      const clickedButton = iconButtonElements[elementClickedIndex].lastChild.getElementsByTagName('button')[0];
      const focusedButton = iconButtonElements[currentFocusedIndex].lastChild.getElementsByTagName('button')[0];

      // update button style
      clickedButton.classList.add('selected-icon-button');
      focusedButton.classList.remove('selected-icon-button');

      // update icon style
      clickedButton.getElementsByTagName('i')[0].classList.add('selected-icon-button-icon');
      focusedButton.getElementsByTagName('i')[0].classList.remove('selected-icon-button-icon');

      const tabSwitchEvent = new CustomEvent('tabSwitch', {
        bubbles: true,
        composed: true,
        detail: { clickIndex: elementClickedIndex },
      });
      this.dispatchEvent(tabSwitchEvent);
    }
  }
}

customElements.define('pui-icon-button-container', PUIIconButtonContainer);
