import PUIBase from '../pui-base';

/**
 * This component is responsible for allowing a user to search for a health condition
 *
 * Configuration:
 *
 * @param {PUIHealthConditionSearchConfig} this.config - The configuration for PUIHealthConditionSearch
 * @param {string} this.config.searchUrl - The url used for searching health conditions
 * @param {string} this.config.searchRequestMethod - The request method type for search request, can be 'GET' or 'POST', default is 'POST'
 * @param {string} this.config.contactUsUrl - The url used for the contact us option
 * @param {string} this.config.strings.searchInputTitle - The title text used for this health condition search input
 * @param {string} this.config.strings.searchInputPlaceholder - The placeholder text for this health condition search input
 * @param {string} this.config.strings.noResultsFound - The text that shows when no results are found
 * @param {string} this.config.strings.contactUsPromptText - The prompt text for the contact us option
 * @param {string} this.config.strings.contactUsLinkText - The link text for the contact us option
 * @param {onHealthConditionSelect} this.config.callbacks.onHealthConditionSelect - This callback gets called when a user selects
 *                                                                                  a health condition from the search results
 */
export default class PUIHealthConditionSearch extends PUIBase {
  connectedCallback() {
    super.connectedCallback();
    this.upgradeProperty('config');
    this._render();
  }

  _render() {
    this.classList.add('pui-block');

    const {
      strings,
      searchRequestMethod
    } = this.config;

    if (!strings.searchInputPlaceholder) {
      strings.searchInputPlaceholder = '';
    }

    this.innerHTML = `
      <pui-search-input id="apex-health-conditions-search-input"
                        label="${strings.searchInputTitle}"
                        placeholder="${strings.searchInputPlaceholder}"
                        noResultsStyle="contact-us"
                        noResultsText="${strings.noResultsFound}"
                        contactUsPromptText="${strings.contactUsPromptText}"
                        contactUsLinkText="${strings.contactUsLinkText}"
                        contactUsLinkHref="${this.config.contactUsUrl}"
                        searchRequestMethod="${searchRequestMethod || ''}"
                        style="margin-bottom: 8px">
      </pui-search-input>
    `;

    this._searchInput = this.querySelector('#apex-health-conditions-search-input');

    this._searchInput.queryUrlBuilder = this._queryUrlBuilder.bind(this);
    this._searchInput.querySuccessCb = this._querySuccessCb.bind(this);
    this._searchInput.onSearchResultClick = this._onSearchResultClick.bind(this);
  }

  _queryUrlBuilder(dataSearchUrl, healthConditionSearchInputValue) {
    return `${this.config.searchUrl}?query=${healthConditionSearchInputValue}`;
  }

  _querySuccessCb(response) {
    const healthConditions = response;
    const searchResults = [];
    healthConditions.forEach((healthCondition) => {
      const searchResult = {
        html: healthCondition.friendly,
        id: healthCondition.id,
      };
      searchResults.push(searchResult);
    });
    this._searchInput.updateSearchResults(searchResults);
  }

  _onSearchResultClick(healthCondition) {
    const { onHealthConditionSelect } = this.config.callbacks;
    if (onHealthConditionSelect) {
      onHealthConditionSelect(healthCondition);
    }
  }
}

window.customElements.define('pui-health-condition-search', PUIHealthConditionSearch);
