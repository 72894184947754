import PUIBase from '../pui-base';

/**
 * This class is responsible for displaying a PrimeRx Discount Card.
 * It is similar to the insurance/pui-insurance-item component but does not have a 
 * green, yellow, or red status or a "rank". 
 
 * This component does not use exclusively PUI components (e.g. text) since the 
 * prescribed (designed) UX of the PrimeRx card is significantly different from other
 * Pharmacy components.
 * 
 * Lastly, there are no actions a customer can take on the PrimeRx card itself at this time.
 *
 * Callbacks:
 *  None
 *
 * Configuration:
 *
 * @param {PrimeRxDiscountCardConfig} this.config - The configuration for the PrimeRxDiscountCardConfig
 * @param {Insurance} this.config.cardDetails - The insurance
 * @param {string} this.config.cardDetails.id - The Pillpack Generated insurance id
 * @param {string} this.config.cardDetails.type - STANDARD/MEDICARE/COUPON/PRIMERX
 * @param {string} this.config.cardDetails.cardHolderId - Also known as the memberId
 * @param {string} this.config.cardDetails.groupNumber - Insurance card group number
 * @param {string} this.config.cardDetails.binNumber - Insurance card bin number
 * @param {string} this.config.cardDetails.pcn - Insurance card pcn
 *
 * @param {string} this.config.strings.primeRxDiscountCardTitle - Title that will be placed in the header of the discount card.
 * @param {string} this.config.strings.primeRxDisclaimer - Text that disclaims this is not an insurance, but a discount card. Deductibles will not be affected.
 * @param {string} this.config.strings.primeRxAutoRenewMessage - Informational text about when the customer's card will automatically renew.
 * @param {string} this.config.strings.primeRxSubtitle - Text shown above the card.
 * @param {string} this.config.strings.learnMorePageLinkText - Text that'll be shown and link to the PrimeRx landing page.
 * @param {string} this.config.strings.seeTermsLinkText - Text that'll be shown and link to the PrimeRx terms page.
 * 
 * @param {string} this.config.learnMorePageLink - Link to the PrimeRx landing page.
 * @param {string} this.config.seeTermsLink - Link to the PrimeRx terms page.
 */
export default class PrimeRxDiscountCard extends PUIBase {
  connectedCallback() {
    super.connectedCallback();
    this.upgradeProperty('config');
    this._render();
  }

  getInsurance() {
    return this.config.insurance;
  }

  _render() {
    const {
      cardDetails,
      strings,
    } = this.config;

    if(!strings.primeRxFooterText) {
        strings.primeRxFooterText = "";
    }

    if (cardDetails) {
      this.setAttribute('id', `primerx-discount-card-${cardDetails.id}`);
      this.innerHTML = `
      <pui-section secondaryAxisArrangement="center">
        <pui-section class="primerx-card-subtitle-container">
            <div class="primerx-discount-card-page-subtitle">${strings.primeRxSubtitle} <pui-link text="${strings.learnMorePageLinkText}" href="${this.config.learnMorePageLink}" textSize="medium"></pui-link></div>
        </pui-section>
        <div class="primerx-card primerx-content">
            <div class="primerx-card-logo">
                 <pui-icon imgClass="amazon-prime-logo-dark-blue-img" spacingRight="small"></pui-icon>
            </div>
            <div class="card-box top-space primerx-member-id">
                  <pui-section-column>
                      <div class="card-detail-label">MEMBER ID</div>
                      <div class="card-detail-value">${cardDetails.cardHolderId}</div>
                  </pui-section-column>
            </div>
            <div class="card-box primerx-group">
                  <pui-section-column>
                      <div class="card-detail-label">GROUP</div>
                                      <div class="card-detail-value">${cardDetails.groupNumber}</div>
                  </pui-section-column>
            </div>
            <div class="card-box primerx-bin">
                  <pui-section-column>
                      <div class="card-detail-label">BIN</div>
                        <div class="card-detail-value">${cardDetails.binNumber}</div>
                  </pui-section-column>
            </div>
            <div class="card-box primerx-pcn">
                  <pui-section-column>
                      <div class="card-detail-label">PCN</div>
                          <div class="card-detail-value">${cardDetails.pcn}</div>
                  </pui-section-column>
            </div>
            <div class="primerx-irx-disclaimer">
                  <div class="primerx-card-detail-administrator">Administered by Inside Rx, LLC.</div>
            </div>
            <div class="primerx-card-header-right">
                <div class="primerx-inner">
                     <div class="primerx-savings">RX SAVINGS</div>
                </div>
            </div>       
        </div>
      </pui-section>
      <pui-section>
        <div id="primerx-card-messages" class="primerx-card-messages">
            <!-- messages section -->
            <pui-section>
                <div class="primerx-disclaimer-text">${strings.primeRxDisclaimer}</div>
                <pui-link text="${strings.seeTermsLinkText}" href="${this.config.seeTermsLink}" textSize="small"></pui-link>
            </pui-section>
            <pui-section>
                <div class="primerx-disclaimer-text">${strings.primeRxAutoRenewMessage}</div>
            </pui-section>
        </div>
      </pui-section>
      `;
    }
  }
}

window.customElements.define('pui-primerx-card', PrimeRxDiscountCard);
