import PUIBase from './pui-base';
import ESAPI from 'node-esapi';

export class PUICart extends PUIBase {
  constructor() {
    super();
    this.defaultClass = 'pui-cart';
  }

  connectedCallback() {
    super.connectedCallback();
    this.render();
  }

  static get observedAttributes() {
    return ['color', 'totalItems', 'href', 'textdesktop', 'textmobile'];
  }

  /**
  * @classprop {string} totalItems: determines what number of items in the cart
  */
  get totalItems() {
    return this.getAttribute('totalItems') || '';
  }

  set totalItems(value) {
    this.setAttribute('totalItems', parseInt(value, 10));
    this.render();
  }

  get href() {
    return this.getAttribute('href') || '';
  }

  set href(value) {
    this.setAttribute('href', value);
  }

  get color() {
    return this.getAttribute('color') || '';
  }

  set color(value) {
    this.setAttribute('color', value);
  }

  get minorsSupported() {
    return this.getBooleanAttribute('minorsSupported');
  }

  set minorsSupported(value) {
    this.setBooleanAttribute('minorsSupported', value);
  }

  get textDesktop() {
    return this.getAttribute('textDesktop');
  }

  set textDesktop(value) {
    this.setAttribute('textDesktop', value);
  }

  get textMobile() {
    return this.getAttribute('textMobile');
  }

  set textMobile(value) {
    this.setAttribute('textMobile', value);
  }

  increase(n = 1) {
    this.totalItems = parseInt(this.totalItems, 10) + n;
  }

  decrease(n = 1) {
    this.totalItems = parseInt(this.totalItems, 10) - n;
  }

  encodedValue(value) {
    return (value ? ESAPI.encoder().encodeForHTML(value) : value);
  }

  render() {
    const {
      color,
      totalItems,
      defaultClass,
      textDesktop,
      textMobile,
    } = this;

    this.innerHTML = '';
    this._innerCart = document.createElement('span');
    const colorSuffix = color === 'white' ? '-white' : '';
    const textColor = color === 'white' ? 'white' : 'black';
    const cartTextMobile = textMobile || textMobile === '' ? this.encodedValue(textMobile) : 'RxCart';
    const cartTextDesktop = textDesktop || textDesktop === '' ? this.encodedValue(textDesktop) : 'Pharmacy Cart';
    this._innerCart.innerHTML = `
    <a id="cart-link" href="${this.href ? this.href : '#'}" class="${`${defaultClass}-link`} hover-underline">
      <pui-section flowdirection="horizontal" secondaryaxisarrangement="center">
        <pui-icon imgClass="${totalItems > 0 ? `cart${colorSuffix}-icon` : `cart-empty${colorSuffix}-icon`}"></pui-icon>
        <div class="${totalItems < 10 ? defaultClass : `${defaultClass}-full`} ${this.minorsSupported ? 'minorsSupported' : ''}">
        ${totalItems > 0 ? totalItems : ''}
        </div>
        <div style="padding-left: 3px;">
          <pui-text input="${cartTextMobile}" class="mobile-and-tablet-only small-mobile-only" fontWeight="bold" textSize="large" textColor="${textColor}" inline></pui-text>
          <pui-text input="${cartTextDesktop}" class="desktop-only small-mobile-only" fontWeight="bold" textSize="large" textColor="${textColor}" inline></pui-text>
        </div>
      </pui-section>
    </a>
    `;
    this.appendChild(this._innerCart);
  }
}

window.customElements.define('pui-cart', PUICart);
