import PUIBase from "../pui-base";
import PUISection from "../pui-section";
import { Urls } from '../../constants';
import PUIText from "../pui-text";
import PUIBottomSheet from "../pui-bottom-sheet";
import { PUILink } from "../pui-link";
import PUIHeading from "../pui-heading";
import Request from "../../networking/request";
import { keyListener } from "../../functions/domUtils";

export class PUIProfileAndLocationAwarenessBar extends PUIBase {
    static get observedAttributes() {
        return [
            ...super.observedAttributes,
            'isreadonly',
            'issignupcomplete',
            'friendlyname',
            'islocationenabled',
            'location',
            'data-string-shopping-for',
            'data-string-switching-to',
            'data-string-change',
            'data-string-who-shopping-for',
            'data-string-im-someone-else',
        ]
    }

    #barContainer?: PUISection;
    #initialsRoundel?: PUIText;
    #shoppingFor?: PUIText;
    #shoppingForName?: PUIText;
    #profileSwitchLoadingBottomSheet?: PUIBottomSheet;
    #profileSwitchLoadingContent?: PUIText;
    #changeProfileLink?: PUILink;
    #profileBottomSheet?: PUIBottomSheet;
    #profileBottomSheetHeading?: PUIHeading;
    #profileBottomSheetLinks?: PUISection;
    #profileImSomeoneElseLink?: PUILink;
    #isRendered = false;


    /** Whether this bar is read-only (true on, eg, Checkout) or interactive (default) */
    get isReadOnly() {
        return this.getBooleanAttribute('isReadOnly') ?? false;
    }

    set isReadOnly(value) {
        this.setBooleanAttribute('isReadOnly', value);
    }

    /** Whether the customer has finished signup or not
     * 
     * Used to determine whether to show the 'add a child under 18' button.
     */
    get isSignupComplete() {
        return this.getBooleanAttribute('isSignupComplete') ?? false;
    }

    set isSignupComplete(value) {
        this.setBooleanAttribute('isSignupComplete', value)
    }

    /** The string to use to refer to the customer by.
     * 
     * See ZenoService API docs for details
     */
    get friendlyName() {
        return this.getAttribute('friendlyName') || '';
    }

    set friendlyName(value) {
        this.setAttribute('friendlyName', value);
    }

    get isLocationEnabled() {
        return this.getBooleanAttribute('isLocationEnabled') || false;
    }

    get location() {
        return this.getAttribute('location') || '';
    }

    connectedCallback() {
        super.connectedCallback();
        this.#render();
    }

    attributeChangedCallback() {
        super.attributeChangedCallback();
        this.#update();
    }

    #render() {
        this.innerHTML = `<pui-section id="pui-sub-nav-profile-awareness-bar-container" 
                centerlane="true" 
                flowDirection="horizontal" 
                mainAxisArrangement="space-between"
                class="pui-sub-nav-profile-awareness-bar-container borderRadius"
                secondaryAxisArrangement="center">
            <pui-section flowDirection="horizontal" spacingLeft="medium" secondaryAxisArrangement="center">
                <pui-section class="pui-sub-nav-person-icon pui-sub-nav-bottom-sheet-button">
                    <pui-text id="pui-sub-nav-shopping-for-initials" textSize="small"></pui-text>
                </pui-section>
                <pui-text spacingLeft="small" id="pui-sub-nav-shopping-for"></pui-text>
                <pui-text spacingLeft="mini" inline id="pui-sub-nav-shopping-for-name" fontWeight="bold"></pui-text>
            </pui-section>
            <pui-link id="nav-link-profile-change" class="nav-link-profile-change" spacingRight="small"></pui-link>
        </pui-section>
        <pui-bottom-sheet id="profile-switch-loading-bottom-sheet" hideLink hideClose>
            <pui-section id="profile-switch-loading-bottom-sheet-content"  paddingBottom="large">
                <pui-section flowDirection="vertical" secondaryAxisArrangement="center">
                    <pui-text textSize="extra-large" fontWeight="bold" id="profile-switch-loading-content"></pui-text>
                    <pui-loading-indicator spacingTop="large" query></pui-loading-indicator>
                </pui-section>
            </pui-section>
        </pui-bottom-sheet>
        <pui-bottom-sheet id="profile-awareness-bottom-sheet" hideLink>
            <pui-section>
                <pui-heading id="pab-profile-bottom-sheet-heading"  spacingBottom="medium"></pui-heading>
                <pui-section id="pab-profile-links" flowDirection="vertical" secondaryAxisArrangement="center">
                </pui-section>
                <pui-link id="back-to-pp-link" style="white-space: nowrap" textcolor="link" textSize="medium" href="${Urls.PEOPLE_PICKER_URL}" spacingTop="small"></pui-link>
            </pui-section>  
        </pui-bottom-sheet>`;
        this.#barContainer = this.querySelector('#pui-sub-nav-profile-awareness-bar-container')!;
        this.#initialsRoundel = this.querySelector('#pui-sub-nav-shopping-for-initials')!;
        this.#shoppingFor = this.querySelector('#pui-sub-nav-shopping-for')!;
        this.#shoppingForName = this.querySelector('#pui-sub-nav-shopping-for-name')!;
        this.#profileSwitchLoadingBottomSheet = this.querySelector('#profile-switch-loading-bottom-sheet')!;
        this.#profileSwitchLoadingContent = this.querySelector('#profile-switch-loading-content')!;
        this.#changeProfileLink = this.querySelector("#nav-link-profile-change")!;
        this.#profileBottomSheet = this.querySelector("#profile-awareness-bottom-sheet")!;
        this.#profileBottomSheetHeading = this.querySelector("#pab-profile-bottom-sheet-heading")!;
        this.#profileBottomSheetLinks = this.querySelector("#pab-profile-links")!;
        this.#profileImSomeoneElseLink = this.querySelector("#back-to-pp-link")!;

        if (!this.isReadOnly) {
            // Handles opening the profile selection bottom sheet
            this.#changeProfileLink.addEventListener('click', this._openProfileAwarenessBottomSheet.bind(this));
            this.#changeProfileLink.addEventListener('keyup', keyListener(this._openProfileAwarenessBottomSheet.bind(this)));
        }

        this.#isRendered = true;
        this.#update();
    }

    #update() {
        if (!this.#isRendered) {
            console.warn("Not yet rendered, ignoring update");
            return;
        }
        this.#barContainer!.isMShop = this.isMShop;
        this.#initialsRoundel!.setTextSafe(this._getInitials(this.friendlyName));
        this.#shoppingFor!.setTextSafe(this.dataset.stringShoppingFor || '');
        this.#shoppingForName!.setTextSafe(this.friendlyName);
        this.#profileSwitchLoadingContent!.setTextSafe(`${this.dataset.stringSwitchingTo} ${this.friendlyName}...`);
        this.#changeProfileLink!.text = this.dataset.stringChange || '';
        this.#changeProfileLink!.hidden = this.isReadOnly;
        this.#profileSwitchLoadingBottomSheet!.isMShop = this.isMShop;
        this.#profileBottomSheet!.isMShop = this.isMShop;
        this.#profileBottomSheetHeading!.setTextSafe(this.dataset.stringWhoShoppingFor);
        this.#profileImSomeoneElseLink!.text = this.dataset.stringImSomeoneElse || '';
    }
    
    //#region Profile Awareness Bar
    _openProfileSwitchLoadingBottomSheet() {
        this.#profileSwitchLoadingBottomSheet?.show();
    }

    _openProfileAwarenessBottomSheet() {
        this.#profileBottomSheet?.show();
    }

    _hideProfileAwarenessBottomSheet() {
        this.#profileBottomSheet?.hide();
    }

    _getSubNavProfileAwarenessBarProfileLinkHTML(friendlyName: string, isCurrentSignedIn: boolean, idModifier: string) {
        return `<pui-section fullWidth 
            spacingLeft="medium" 
            spacingRight="medium" 
            spacingBottom="small" 
            flowDirection="horizontal" 
            secondaryAxisArrangement="center"
            padding="small"
            id="profile-switch-${idModifier}"
            class="pui-block pui-box pui-sub-nav-bottom-sheet-button-container ${isCurrentSignedIn ? 'pui-sub-nav-bottom-sheet-active-profile' : ''}">
            <pui-section flowDirection="horizontal" secondaryAxisArrangement="center">
              <pui-section flowDirection="horizontal" mainAxisArrangement="start" secondaryAxisArrangement="center">
                  <pui-section class="pui-sub-nav-person-icon" class="pui-sub-nav-bottom-sheet-button">
                    <pui-text input="${this._getInitials(friendlyName)}" textSize="small"></pui-text>
                  </pui-section>
                  <pui-text spacingLeft="small" input="${friendlyName}"></pui-text>
              </pui-section>
              ${isCurrentSignedIn ? `
              <pui-section class="pui-sub-nav-bottom-sheet-active-profile-viewing" flowDirection="horizontal" mainAxisArrangement="center" secondaryAxisArrangement="center">
                <pui-text textAlign="center" textColor="white" spacingLeft="small" input="${this.dataset.stringViewing}"></pui-text>
              </pui-section>` : `<pui-icon imgClass="chevron-right"></pui-icon>`}
            </pui-section>
       </pui-section>`;
    }

    _getSubNavProfileAwarenessBarAddChildLinkHTML() {
        return `
        <pui-section fullWidth
            spacingLeft="medium"
            spacingRight="medium"
            spacingBottom="small"
            flowDirection="horizontal"
            secondaryAxisArrangement="center"
            padding="small"
            id="profile-switch-add"
            class="pui-block pui-box pui-sub-nav-bottom-sheet-button-container">
            <pui-section flowDirection="horizontal" secondaryAxisArrangement="center">
              <pui-section flowDirection="horizontal" mainAxisArrangement="start" secondaryAxisArrangement="center">
                    <pui-icon imgClass="add-icon"></pui-icon>
                  <pui-link spacingLeft="small" color="black" text="${this.dataset.stringAddChild}"></pui-link>
              </pui-section>
            </pui-section>
       </pui-section>`;
    }

    renderLinks(personProfiles: {currentSignedIn: boolean, friendlyName: string, pid: string}[]) {
        const { isReadOnly, isSignupComplete, friendlyName } = this;

        if (isReadOnly) {
            return;
        }
        const links: string[] = [];

        // Add current signed in profile
        links.push(this._getSubNavProfileAwarenessBarProfileLinkHTML(friendlyName, true, 'signed-in-person'));

        if (personProfiles) {
            links.push(...personProfiles.map((profile, index) => (profile.currentSignedIn
                ? ''
                : this._getSubNavProfileAwarenessBarProfileLinkHTML(profile.friendlyName, false, "" + index))));
        }

        if (isSignupComplete) {
            links.push(this._getSubNavProfileAwarenessBarAddChildLinkHTML());
        }

        this.#profileBottomSheetLinks!.innerHTML = links.join('');

        if (isSignupComplete) {
            this._addEventListenerForAddDependentButton();
        }

        if (personProfiles) {
            personProfiles.forEach((profile, index) => {
                if (!profile.currentSignedIn) {
                    const buttonId = `#profile-switch-${index}`;
                    const profileSwitchButton = this.querySelector<HTMLElement>(buttonId)!;
                    profileSwitchButton.addEventListener('click', this._switchProfile.bind(this, profile.pid, profile.friendlyName));
                    profileSwitchButton.addEventListener('keyup', keyListener(this._switchProfile.bind(this, profile.pid, profile.friendlyName)));
                }
            });
        }
    }

    _addProfile() {
        window.location.href = Urls.ADD_DEPENDENT_URL;
    }

    _addEventListenerForAddDependentButton() {
        const buttonId = '#profile-switch-add';
        const profileAwarenessBottomSheetAddDependentButton = this.querySelector<HTMLElement>(buttonId)!;
        profileAwarenessBottomSheetAddDependentButton.addEventListener('click', this._addProfile.bind(this));
        profileAwarenessBottomSheetAddDependentButton.addEventListener('keyup', keyListener(this._addProfile.bind(this)));
    }

    _switchProfile(pid: string, friendlyName: string) {
        this._hideProfileAwarenessBottomSheet();
        this._openProfileSwitchLoadingBottomSheet();
        this.friendlyName = friendlyName;

        Request.post(Urls.SUB_NAV_PROFILE_SWITCH, {
            returnUrl: Urls.DASHBOARD_URL,
            personId: pid,
        })
            .then((response) => {
                window.location.href = response.redirectUrl;
            });
    }
    //#endregion

    _getInitials(name: string) {
        const initials = name.split(' ').map(x => x[0]);
        return initials.join('');
    }
}

window.customElements.define('pui-profile-and-location-awareness-bar', PUIProfileAndLocationAwarenessBar);
