import keyCodes from '../constants/keyCodes';
import Request from '../networking/request';
import PUIBase from './pui-base';
import PUILoadingIndicator from './pui-loading-indicator';

/**
 * @deprecated Use PUISearchBar instead for new code
 */
export class PUISearchInput extends PUIBase {
  constructor() {
    super();
    this._isFullScreen = false;
    this.minimize = function () {
      this._minimize();
    };
    this.pharmacyResults = [];
  }

  connectedCallback() {
    super.connectedCallback();
    this.upgradeProperty('onInputChange');
    this.upgradeProperty('onSearchResultClick');
    this.render();
  }

  static get observedAttributes() {
    return ['inputId', 'label', 'placeholder', 'disableTransition', 'name', 'maxLength',
      'allowedCharacters', 'iconClass', 'keepInputOnClick'];
  }

  attributeChangedCallback() {
    this.render();
  }

  get inputId() {
    return this.getAttribute('inputId') || '';
  }

  get name() {
    return this.getAttribute('name' || '');
  }

  set name(value) {
    this.setAttribute('name', value);
  }

  setonInputChangeValue(cb) {
    if (!cb) {
      this.onInputChangeValue = undefined;
      return;
    }
    /** @type {((this: void, input: string) => unknown | Promise<unknown>) | undefined} */
    this.onInputChangeValue = cb.bind(void 0);
  }

  get maxLength() {
    return this.getAttribute('maxLength') || '';
  }

  set maxLength(value) {
    this.setAttribute('maxLength', value);
  }

  get allowedCharacters() {
    return this.getAttribute('allowedCharacters') || '';
  }

  set allowedCharacters(value) {
    this.setAttribute('allowedCharacters', value);
  }

  get iconClass() {
    return this.getAttribute('iconClass') || '';
  }

  set iconClass(value) {
    this.setAttribute('iconClass', value);
  }

  // keepInput is a flag that determines if pui-input is cleared after user
  // selects a search result (default) or if we keep the result in the input form
  get keepInputOnClick() {
    return this.getAttribute('keepInputOnClick') || '';
  }

  set keepInputOnClick(value) {
    this.setAttribute('keepInputOnClick', value);
  }

  set inputId(value) {
    this.setAttribute('inputId', value);
  }

  get label() {
    return this.getAttribute('label') || '';
  }

  set label(value) {
    this.setAttribute('label', value);
  }

  get placeholder() {
    return this.getAttribute('placeholder') || '';
  }

  set placeholder(value) {
    this.setAttribute('placeholder', value);
  }

  get dataSearchUrl() {
    return this.getAttribute('dataSearchUrl') || '';
  }

  set dataSearchUrl(value) {
    this.setAttribute('dataSearchUrl', value);
  }

  /**
   * Use GET or POST for search query request. default is POST
   */
  get searchRequestMethod() {
    return this.getAttribute('searchRequestMethod') || 'POST';
  }

  set searchRequestMethod(value) {
    this.setAttribute('searchRequestMethod', value);
  }

  /**
   * If present, this search input will display inline rather than
   * fill entire screen
   */
  get inline() {
    return this.getBooleanAttribute('inline');
  }

  set inline(value) {
    this.setBooleanAttribute('inline', value);
  }

  get showSpinner() {
    return this.getBooleanAttribute('showSpinner');
  }

  set showSpinner(value) {
    this.setBooleanAttribute('showSpinner', value, this._innerButton);
  }

  /**
   * Determines what to show when no results are found. When nothing is specified
   * will default to just showing the "No results found". Values can be:
   * 'manual-add' - Allows a user to manually add the text as is
   * 'contact-us' - Shows the user a message and link to "contact us"
   */
  get noResultsStyle() {
    return this.getAttribute('noResultsStyle') || '';
  }

  set noResultsStyle(value) {
    this.setAttribute('noResultsStyle', value);
  }

  get noResultsText() {
    return this.getAttribute('noResultsText') || '';
  }

  set noResultsText(value) {
    this.setAttribute('noResultsText', value);
  }

  get addManuallyText() {
    return this.getAttribute('addManuallyText') || '';
  }

  set addManuallyText(value) {
    this.setAttribute('addManuallyText', value);
  }

  get addManuallyHref() {
    return this.getAttribute('addManuallyHref') || '';
  }

  set addManuallyHref(value) {
    this.setAttribute('addManuallyHref', value);
  }

  get contactUsPromptText() {
    return this.getAttribute('contactUsPromptText') || '';
  }

  set contactUsPromptText(value) {
    this.setAttribute('contactUsPromptText', value);
  }

  get contactUsLinkText() {
    return this.getAttribute('contactUsLinkText') || '';
  }

  set contactUsLinkText(value) {
    this.setAttribute('contactUsLinkText', value);
  }

  get contactUsLinkHref() {
    return this.getAttribute('contactUsLinkHref') || '';
  }

  set contactUsLinkHref(value) {
    this.setAttribute('contactUsLinkHref', value);
  }

  /**
   * Determines whether to show extra help details on search result
   */
  get searchResultHelp() {
    return this.getBooleanAttribute('searchResultHelp') || '';
  }

  set searchResultHelp(value) {
    this.setBooleanAttribute('searchResultHelp', value);
  }

  get searchResultHelpHeader() {
    return this.getAttribute('searchResultHelpHeader') || '';
  }

  set searchResultHelpHeader(value) {
    this.setAttribute('searchResultHelpHeader', value);
  }

  get searchResultHelpBody() {
    return this.getAttribute('searchResultHelpBody') || '';
  }

  set searchResultHelpBody(value) {
    this.setAttribute('searchResultHelpBody', value);
  }

  /**
   * Used to avoid XSS vulnerability
   */
  get encode() {
    return this.getBooleanAttribute('encode');
  }

  set encode(value) {
    return this.setBooleanAttribute('encode', value);
  }

  /**
   * The delay in milliseconds before a search query is initiated
   * If this parameter is not specified, will default to 200 milliseconds
   */
  get searchQueryDelay() {
    return parseInt(this.getAttribute('searchQueryDelay') || '200');
  }

  set searchQueryDelay(value) {
    this.setAttribute('searchQueryDelay', value);
  }

  // Disables the transition when opening and closing/minimizing the search
  get disableTransition() {
    return this.getBooleanAttribute('disableTransition');
  }

  set disableTransition(value) {
    return this.setBooleanAttribute('disableTransition', value);
  }

  set onInputChange(fnc) {
    this.querySelector('pui-input').onInputChange = fnc;
  }

  /**
   * Invoke from react to push the portal container to this webcomponent.
   * Will always clear any prior element.
   *
   * @param element the React portal container element
   */
  setExtra(element) {
    while (this._searchResultsExtra.firstChild) {
      this._searchResultsExtra.removeChild(this._searchResultsExtra.lastChild);
    }

    this._searchResultsExtra.appendChild(element);
  }

  /**
   * Updates this search component with the list of results in the dropdown
   * The html field of the results object is the only required field
   * You can arbitrarily add whatever other meta data you need into this object and it will
   * be emitted with the onSearchResultClick callback
   *
   * Each result must have the form:
   * {
   *    html: "HTML for the displayed search result item"
   *    ... any other metadata
   * }
   * @param {*} results - the list of result objects
   */
  updateSearchResults(results, clearResults = false) {
    const {
      searchResultHelp,
      inputId,
    } = this;

    this.pharmacyResults = results;

    if (results.length === 0) {
      this.hideSpinner();
      this._searchResultsContainer.classList.add('pui-hidden');
      if (!clearResults) {
        this._displayNoResultsFound();
      } else {
        this._hideNoResultsFound();
      }
    } else {
      this._hideNoResultsFound();
      this._searchResultsContainer.innerHTML = '';

      if ((inputId == 'pharmacy-search-input' || this.keepInputOnClick) && results.length > 10) {
        this._updateSearchResultsWithInfiniteScroll();
      } else {
        results.forEach((result, index) => {
          const searchResultItem = document.createElement('div');
          searchResultItem.setAttribute('id', `apex-search-result-item-${index}`);
          searchResultItem.classList.add('search-results-item');
          searchResultItem.innerHTML = result.html;
          searchResultItem.itemValue = result;
          searchResultItem.setAttribute('tabindex', '0');
          searchResultItem.setAttribute('role', 'button');
          this._searchResultsContainer.appendChild(searchResultItem);
        });

        if (searchResultHelp) {
          this._displaySearchResultHelp();
        }
      }
      this._searchResultsContainer.classList.remove('pui-hidden');
    }
  }

  _updateSearchResultsWithInfiniteScroll() {
    const defaultLoadingSize = 10;
    for (let index = 0; index < defaultLoadingSize && index < this.pharmacyResults.length; index++) {
      const searchResultItem = document.createElement('div');
      searchResultItem.setAttribute('id', `apex-search-result-item-${index}`);
      searchResultItem.classList.add('search-results-item');
      searchResultItem.innerHTML = this.pharmacyResults[index].html;
      searchResultItem.itemValue = this.pharmacyResults[index];
      searchResultItem.setAttribute('tabindex', '0');
      searchResultItem.setAttribute('role', 'button');
      this._searchResultsContainer.appendChild(searchResultItem);
    }

    // remove first 10 results
    this.pharmacyResults.splice(0, defaultLoadingSize);
  }

  getDataSearchUrl() {
    return this.dataSearchUrl;
  }

  focus() {
    this._searchInput.focus();
  }

  /**
   * Show an error message on this control
   * @param {string} text The error message to display
   */
  showError(text) {
    this._searchInput.showError(text);
  }

  hideError() {
    this._searchInput.hideError();
  }

  /**
   * Same as _searchResultClicked() except when user presses Enter
   */
  _searchResultPressed(event) {
    if (event.keyCode === keyCodes.ENTER_KEYCODE) {
      this._searchResultClicked(event);
    }
  }

  _searchEnterPressed(event) {
    if (event.keyCode === keyCodes.ENTER_KEYCODE) {
      this._searchResultsContainer.classList.add('pui-hidden');
    }
  }

  /**
   * Gets the selected search result and then calls the onSearchResultClick callback with the
   * original result object as a parameter
   */
  _searchResultClicked(event) {
    const searchResultItems = this._searchResultsContainer.getElementsByClassName('search-results-item');
    for (let i = 0; i < searchResultItems.length; i += 1) {
      const searchResultItem = searchResultItems[i];
      if (searchResultItem.contains(event.target)) {
        if (this.onSearchResultClick) {
          if (searchResultItem.itemValue && !!searchResultItem.itemValue.disabled) {
            return; // this item is disabled
          }

          if (this.keepInputOnClick) {
            this._searchInput.setValue(searchResultItem.itemValue.values);
          } else {
            this._searchInput.setValue('');
          }
          this._searchResultsContainer.classList.add('pui-hidden');
          this.onSearchResultClick(searchResultItem.itemValue);
          if (!this.inline) {
            this._minimize();
          }
        }
        break;
      }
    }
  }

  /**
   * Will animate the search input page to be full screen and also display the cancel button
   */
  _inputFocusHandler() {
    this._searchResultsExtra.classList.remove('pui-hidden');

    if (!this.inline) {
      if (!this.expanded) {
        this.expanded = true;
        // Create placeholder item to occupy the space left by making search input fixed
        this._holder = this._searchInputContainer.cloneNode(true);
        this._holder.style.visibility = 'hidden';

        // Maximize the search input
        this._setSearchInputLocation(this._searchInputContainer);
        this._searchInputContainer.parentElement.insertBefore(this._holder, this._searchInputContainer);
        this._searchInputContainer.classList.add('search-input-container-before');
        if (this.disableTransition) {
          this._fullScreen();
          this._showSearchCancel();
          this._disableScroll();
        } else {
          setTimeout(this._fullScreen.bind(this), 50);
          setTimeout(this._showSearchCancel.bind(this), 125);
          setTimeout(this._disableScroll.bind(this), 250);
        }
      }
    }
  }

  _fullScreen() {
    this._searchInputContainer.classList.add('search-input-container-fullscreen');
    const scrollingElement = document.scrollingElement || document.documentElement;
    scrollingElement.scrollTop = 0;
    this._isFullScreen = true;
  }

  _showSearchCancel() {
    this._cancelSearchButton.classList.remove('pui-hidden');
  }

  _disableScroll() {
    document.body.classList.add('no-scroll');
  }

  _onSearchCancel() {
    this._minimize();
  }

  _minimize() {
    if (!this._isFullScreen) {
      return;
    }
    // Hide extra html when minimized
    this._searchResultsExtra.classList.add('pui-hidden');

    // Clear out search results
    this._searchInput.clearInput();
    this._searchResultsContainer.classList.add('pui-hidden');

    // Hide no results found
    this._hideNoResultsFound();

    // Hide cancel button
    this._cancelSearchButton.classList.add('pui-hidden');

    // Minimize search input container
    this._searchInputContainer.classList.remove('search-input-container-fullscreen');
    this._setSearchInputLocation(this._holder);
    this._searchInputContainer.classList.add('search-input-container-after');
    this._searchResultsHelpContainer.hide();
    if (this.disableTransition) {
      this._resetAnimation();
    } else {
      setTimeout(this._resetAnimation.bind(this), 250);
    }
    this._isFullScreen = false;

    // allows tabbing past search input
    const lastElement = document.getElementById('last-element');
    if (lastElement) {
      lastElement.focus();
    }
  }

  _resetAnimation() {
    this._searchInputContainer.className = 'search-input-container';
    this._holder.parentNode.removeChild(this._holder);
    document.body.classList.remove('no-scroll');
    this.expanded = false;
  }

  _setSearchInputLocation(component) {
    const componentRect = component.getBoundingClientRect();
    const { left } = componentRect;
    const { top } = componentRect;
    const width = component.offsetWidth;
    const height = component.offsetHeight;
    document.documentElement.style.setProperty('--search-input-left', `${left}px`);
    document.documentElement.style.setProperty('--search-input-top', `${top}px`);
    document.documentElement.style.setProperty('--search-input-width', `${width}px`);
    document.documentElement.style.setProperty('--search-input-height', `${height}px`);
  }

  /**
   * Method to handle input change on search term. This method validates input and triggers search query
   * after a delay to let the customer complete typing the search-input.
   * @param inputValue
   * @private
   */
  _inputChangeHandler(inputValue) {
    if (this.onInputChangeValue) {
      this.onInputChangeValue(inputValue);
    }
    if (inputValue && inputValue.length >= this.minimumRequiredCharacters && this._cleanInput(inputValue) !== '') {
      setTimeout(this._querySearchResults.bind(this), this.searchQueryDelay, inputValue);
    } else {
      // clear search results
      this.updateSearchResults([], true);
    }
  }

  _onInputClear() {
    this.updateSearchResults([], true);
    if (this.onInputChangeValue) {
      this.onInputChangeValue('');
    }
  }

  /**
   * Replaces the pui-search-input's AJAX query behavior with a custom callback.
   *
   * Calling this without a value provided for cb will clear the query callback
   * and revert the component to the AJAX behavior.
   *
   * @param {((this: void, input: string) => unknown | Promise<unknown>) | undefined} cb A callback that can optionally be async
   */
  setQueryCb(cb) {
    if (!cb) {
      this._customQueryCb = undefined;
      return;
    }
    /** @type {((this: void, input: string) => unknown | Promise<unknown>) | undefined} */
    this._customQueryCb = cb.bind(void 0);
  }

  /**
   * Method to fire off search query and its handle resonse. This method validates the search query against the
   * current value of search term, to decide whether to update of search results or not.
   * This method expects widget users to register following methods
   * queryUrlBuilder - required, to provide the search query Url
   * querySuccessCb - required, to handle the success response from the search query
   * queryErrorCb - optional, to handle the error response from the search query
   * @param inputValue
   * @private
   */
  _querySearchResults(inputValue) {
    // Retrieve search results only if the search input is still same as the query
    if (inputValue !== this._searchInput.getValue()) {
      return;
    }

    if (this._customQueryCb) {
      let returnValue;
      try {
        returnValue = this._customQueryCb(inputValue);
      } catch (err) {
        console.error('Error in pui-search-input customQueryCb: ', err);
        this.queryErrorCb && this.queryErrorCb(err);
      }

      if (returnValue instanceof Promise) {
        this.displaySpinner();
        returnValue
          .then(result => void (this.querySuccessCb && this.querySuccessCb(result)))
          .catch(err => void (this.queryErrorCb && this.queryErrorCb(err)))
          .finally(() => this.hideSpinner());
      } else {
        this.querySuccessCb && this.querySuccessCb(returnValue);
      }
      return;
    }

    if (!this.queryUrlBuilder) {
      throw 'queryUrlBuilder should be registered to provide the search query Url.';
    }

    if (!this.querySuccessCb) {
      throw 'querySuccessCb should be registered to handle the response of search query.';
    }

    const cleanedInput = this._cleanInput(inputValue);
    const queryUrl = encodeURI(this.queryUrlBuilder(this.dataSearchUrl, cleanedInput));
    const thisComponent = this;
    this.displaySpinner();
    let responsePromise;
    if (this.searchRequestMethod === 'GET') {
      responsePromise = Request.get(queryUrl);
    } else {
      responsePromise = Request.post(queryUrl);
    }
    responsePromise.then((response) => {
      this.hideSpinner();
      // Populate search results only if the search input is still same as the query
      if (inputValue === thisComponent._searchInput.getValue() && thisComponent.querySuccessCb) {
        thisComponent.querySuccessCb(response);
      }
    }).catch((error) => {
      if (thisComponent.queryErrorCb) {
        thisComponent.queryErrorCb(error);
      }
      this.hideSpinner();
    });
  }

  refresh() {
    const value = this.getValue();
    if (value) {
      this._querySearchResults(value);
      if (this._searchInput) {
        this._searchInput.scrollIntoView(true);
      }
    }
  }

  _cleanInput(inputValue) {
    return inputValue
      // Trim string
      .trim()
      // Replace dashes with spaces
      .replace(/[-]+/g, ' ')
      // Remove all other special characters
      .replace(/[^\w\s]+/g, '');
  }

  setValue(value) {
    this._searchInput.setValue(value);
  }

  getValue() {
    return this._searchInput.getValue();
  }

  /**
   * displays spinner over button to indicate callback is loading
   */
  displaySpinner() {
    this._spinner.show();
  }

  hideSpinner() {
    this._spinner.hide();
  }

  /**
   * Creates the HTML for what to display when there are no results found
   */
  _getNoResultsFoundHTML() {
    const {
      noResultsStyle,
      noResultsText,
    } = this;

    // Get additional no results HTML based on noResultsStyle
    let noResultsAdditionalHTML = '';
    if (noResultsStyle === 'manual-add') {
      const {
        addManuallyText,
        addManuallyHref,
      } = this;
      noResultsAdditionalHTML = `
        <pui-box spacingTop="small">
          <pui-section flowDirection="horizontal" mainAxisArrangement="space-between" secondaryAxisArrangement="center">
            <pui-text class="manually-added-text" input=""></pui-text>
            <pui-section class="add-manually-link" flowDirection="horizontal" secondaryAxisArrangement="center" width="126px">
              <pui-icon imgClass="add-icon"></pui-icon>
              <pui-link text="${addManuallyText}" ${addManuallyHref ? `href=${addManuallyHref}` : ''} style="display: block"></pui-link>
            </pui-section>
          </pui-section>
        </pui-box>
      `;
    } else if (noResultsStyle === 'contact-us') {
      const {
        contactUsPromptText,
        contactUsLinkText,
        contactUsLinkHref,
      } = this;
      noResultsAdditionalHTML = `
        <pui-box spacingTop="small">
          <pui-section mainAxisArrangement="center">
            <pui-text input="${contactUsPromptText}" textAlign="center"></pui-text>
            <pui-link text="${contactUsLinkText}" href="${contactUsLinkHref}" class="text-align-center"></pui-link>
          </pui-section>
        </pui-box>
      `;
    }

    // Create and return no results HTML
    return `
      <pui-section class="no-results-found-container pui-hidden">
        <pui-text input="${noResultsText}" fontSize="small" textColor="grey" spacingTop="small"></pui-text>
        ${noResultsAdditionalHTML}
      </pui-section>
    `;
  }

  /**
   * Creates the HTML for what to display when search result needs help text
   */
  _getSearchResultHelpHTML() {
    const {
      searchResultHelp,
      searchResultHelpHeader,
      searchResultHelpBody,
    } = this;

    // Get search result help HTML
    let searchResultHelpHTML = '';
    if (searchResultHelp) {
      searchResultHelpHTML = `
        <pui-box spacingTop="small">
          <pui-text textAlign="center" fontWeight="bold" input="${searchResultHelpHeader}"></pui-text>
          <pui-text textAlign="center" spacingTop="medium" input="${searchResultHelpBody}"></pui-text>
        </pui-box>
      `;
    }

    // Create and return search result help HTML
    return `
      <pui-section class="search-result-help-container pui-hidden">
        ${searchResultHelpHTML}
      </pui-section>
    `;
  }

  _displaySearchResultHelp() {
    const {
      searchResultHelp,
    } = this;
    if (searchResultHelp) {
      this._searchResultsHelpContainer.show();
    }
  }

  _onManualAdd() {
    if (this.onManualAdd) {
      this.onManualAdd(this.getValue());
      this._minimize();
    }
  }

  _setupNoResultsFoundContainer() {
    const {
      noResultsStyle,
    } = this;
    this._noResultsContainer = this.querySelector('.no-results-found-container');
    if (noResultsStyle === 'manual-add') {
      this._manuallyAddedText = this.querySelector('.manually-added-text');
      this._addManuallyLink = this.querySelector('.add-manually-link');
      this._addManuallyLink.addEventListener('click', this._onManualAdd.bind(this));
    }
  }

  _displayNoResultsFound() {
    const {
      noResultsStyle,
    } = this;
    if (noResultsStyle === 'manual-add') {
      this._manuallyAddedText.setText(this.getValue());
    }
    if (!this.keepInputOnClick) {
      this._noResultsContainer.show();
    }
  }

  _hideNoResultsFound() {
    this._noResultsContainer.hide();
  }

  _loadMoreSearchResults() {
    setTimeout(() => {
      this._updateSearchResultsWithInfiniteScroll();
    }, 1000);
  }

  render() {
    this.innerHTML = '';
    const {
      inputId,
      maxLength,
      allowedCharacters,
      keepInputOnClick,
      name,
      label,
      placeholder,
      inline,
      encode,
      iconClass,
    } = this;

    const imgClass = (iconClass === '') ? 'search-icon' : '';

    this.classList.add('pui-block');
    const noResultsFoundHTML = this._getNoResultsFoundHTML();
    const searchResultHelpHTML = this._getSearchResultHelpHTML();
    this._innerInputField = document.createElement('span');
    this._innerInputField.innerHTML = `

        <div class="search-input-container">
          <pui-section style="margin: auto">
            <pui-input
              name ="${name}"
              inputId="${inputId}"
              iconClass="${imgClass}"
              label="${label}"
              placeholder="${placeholder}"
              maxLength="${maxLength}"
              allowedCharacters="${allowedCharacters}"
              keepInputOnClick="${keepInputOnClick}"
              ${encode ? 'encode' : ''}
            ></pui-input>
            <div class="search-results-container pui-hidden"></div>
            ${searchResultHelpHTML}
            ${noResultsFoundHTML}
            <div class="search-results-extra pui-hidden"></div>
            <pui-section flowDirection="horizontal" mainAxisArrangement="end">
              <button type="button" id="apex-search-input-cancel-button" class="search-cancel-button pui-hidden">
                <pui-text input="Back to previous page" paddingBottom="small" textColor="link"></pui-text>
              </button>
            </pui-section>
          </pui-section>
        </div>
        <div id="last-element" tabindex="0"></div>
    `;
    this.appendChild(this._innerInputField);
    this._searchInput = this.querySelector('pui-input');
    this._searchInput.onInputChange = this._inputChangeHandler.bind(this);
    this._searchInput.onInputFocus = this._inputFocusHandler.bind(this);
    this._searchInput.onInputClear = this._onInputClear.bind(this);
    this._searchResultsContainer = this.querySelector('.search-results-container');
    this._searchResultsHelpContainer = this.querySelector('.search-result-help-container');
    this._searchResultsExtra = this.querySelector('.search-results-extra');
    this.minimumRequiredCharacters = parseInt(this.getAttribute('minimumRequiredCharacters') || '3');
    this._searchResultsContainer.addEventListener('click', this._searchResultClicked.bind(this));
    this._searchResultsContainer.addEventListener('keyup', this._searchResultPressed.bind(this));
    this._searchInputContainer = this.querySelector('.search-input-container');
    if (keepInputOnClick) {
      this._searchInputContainer.addEventListener('keyup', this._searchEnterPressed.bind(this));
    }

    // if it is pharmacy search, we listen on window scroll event to load more results.
    if (inputId == 'pharmacy-search-input') {
      this._searchInputContainer.addEventListener('scroll', () => {
        const { scrollTop, scrollHeight, clientHeight } = this._searchResultsContainer;
        if (scrollTop + clientHeight >= scrollHeight) {
          this._loadMoreSearchResults();
        }
      });
    }

    this._cancelSearchButton = this.querySelector('.search-cancel-button');
    this._cancelSearchButton.addEventListener('click', this._onSearchCancel.bind(this));
    this.expanded = false;

    // Setup loading indicator
    this._spinner = new PUILoadingIndicator();
    this._spinner.setAttribute('query', true);
    this._spinner.hide();
    this._spinner.slot = "input-loader";
    this._searchInput.appendChild(this._spinner);

    this._setupNoResultsFoundContainer();
  }
}

window.customElements.define('pui-search-input', PUISearchInput);
