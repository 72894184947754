import PUIBase from '../pui-base';
import PUIChiclet from '../pui-chiclet';
import convertToMedication from './pui-medication-converter';
import keyCodes from '../../constants/keyCodes';
import 'core-js/es';

/**
 * This class is responsible for creating the chicklet container to house the suggested and selected medications
 * It allows the customer to quick select from a series of common medications and toggle between medications already selected
 *
 *
 * Callbacks:
 *
 * Callback for when item is selected
 * @callback onItemClick
 * @param {PUIMedChickletContainer} this - This item
 * Configuration:
 *
 * @param {MedItemConfig} this.config - The configuration for PUIMedChickletContainer
 * @param {boolean} this.config.displayUnknownOption - The boolean for displaying the "I don't know" option
 * @param {boolean} this.config.strictDescriptorRendering - This option enables a strict type of rendering for titles and descriptions (descriptors),
 *                                                          where they will not render if their subsequent elements are empty or should be hidden.
 *                                                          For example, in contrast to the current rendering strategy, the prescription title and
 *                                                          description will not be displayed if the user is not taking any Amazon Pharmacy
 *                                                          prescriptions (prescriptions list is empty). It will also only render the
 *                                                          commonMedications iff both the prescriptions list and medications list are empty.
 *                                                          Note: if prescriptions are empty, it will not display a title for medications either,
 *                                                          but it will display the medication(s) as chicklet(s).
 * @param {Medication} this.config.medication - The medication for this PUIMedChickletContainer
 * @param {SearchResult} this.config.searchResult - The ES Doc for this PUIMedChickletContainer
 * @param {boolean} this.config.hideCommonMedications - The boolean to hide/show common medications
 * @param {string} this.config.strings.selectedSectionTitle - The title of selectedSection.
 * @param {string} this.config.strings.prescriptionsSectionTitle -  The string for title of prescriptionsSection.
 * @param {string} this.config.strings.prescriptionsSectionDescription -  The string for description of prescriptionsSection.
 * @param {string} this.config.strings.itemCloseLabel - The text that indicates chiclet item close pui-icon name for accessibility usage
 * @param {boolean} this.config.showPrescriptions - the boolean to show/hide prescription
 *
 */
export default class PUIMedChickletContainer extends PUIBase {
  connectedCallback() {
    super.connectedCallback();
    this.upgradeProperty('config');
    this._render();
  }

  /**
   * Returns the medication associated with this PUIMedChickletContainer.
   * This could either be a search medication item or one that has come from their health profile.
   */
  getMedication() {
    return this.config.medication;
  }

  _render() {
    const {
      medicationsList,
      commonMedicationsList,
      prescriptionsList,
      strictDescriptorRendering,
      callbacks,
    } = this.config;

    const isMedicationsEmpty = !medicationsList || medicationsList.length === 0;
    const isPrescriptionsEmpty = !prescriptionsList || prescriptionsList.length === 0;
    const isCommonMedicationsEmpty = !commonMedicationsList || commonMedicationsList.length === 0;

    const showCustom = this._generateShowCustom(strictDescriptorRendering, isPrescriptionsEmpty);
    const selectedSectionTitle = this.__generateSelectedSectionTitle(strictDescriptorRendering, isMedicationsEmpty, showCustom);
    const suggestedSectionTitle = this._generateSuggestedSectionTitle(strictDescriptorRendering);
    const hideSuggested = this.__generateHideSuggested(strictDescriptorRendering, showCustom, isMedicationsEmpty, isCommonMedicationsEmpty);

    this.classList.add('pui-block');

    this.innerHTML = `
      <pui-chiclet-container id="apex-medication-chiclet-container" 
        ${showCustom}
        ${hideSuggested}
        suggestedSectionTitle="${suggestedSectionTitle}"
        selectedSectionTitle="${selectedSectionTitle}"
        customSectionTitle="${this.config.strings.prescriptionsSectionTitle}"
        customSectionDescription="${this.config.strings.prescriptionsSectionDescription}"
        itemCloseLabel="${this.config.strings.itemCloseLabel ? this.config.strings.itemCloseLabel : ''}"
        spacingTop="medium" 
        encode="">
      </pui-chiclet-container>
    `;

    if (showCustom) {
      this._buildPrescriptionChicklet(prescriptionsList);
    }

    this._buildChicklet(medicationsList, callbacks);
    this._buildChicklet(commonMedicationsList, callbacks);
  }

  /**
   * Function will generate a `showCustom` string that acts as a boolean attribute for the pui-chicklet-container. When present, it will render
   * the customSectionTitle and customSectionDescription, which are the prescription descriptors. When absent, the customSectionTitle and
   * customSectionDescription will not be rendered.
   * @param isStrictRendering    {boolean}        The config option determining if strictDescriptorRendering is toggled on.
   * @param isPrescriptionsEmpty {boolean}        Boolean for empty prescriptions list.
   * @return {string}                             'showCustom' when prescriptions are to be displayed; EMPTY_STRING when they should be hidden.
   * @private
   */
  _generateShowCustom(isStrictRendering, isPrescriptionsEmpty) {
    if (isStrictRendering) {
      // For strict rendering, it is only desirable to show the prescription descriptors when the prescriptionsList
      // is non-empty and the `showPrescriptions` config is set to true.
      const isShowingPrescriptionsDescriptors = this.config.showPrescriptions && !isPrescriptionsEmpty;
      return isShowingPrescriptionsDescriptors ? 'showCustom' : '';
    }

    // The default rendering option will always show the prescription descriptors.
    return this.config.showPrescriptions ? 'showCustom' : '';
  }

  /**
   * Function will generate the value for the `selectedSectionTitle` attribute belonging to the pui-chicklet-container.
   * As far as the medications-page is concerned, the selectedSection is the user's medications section title.
   * @param isStrictRendering         {boolean}   The config option determining if strictDescriptorRendering is toggled on.
   * @param isMedicationsEmpty        {boolean}   Boolean for empty medications list.
   * @param isShowingPrescriptions    {string}    The `showCustom` attribute; if non-empty, then True; otherwise, False.
   * @return {string}                             '{config.strings.selectedSectionTitle}' if medications are to be displayed,
   *                                              EMPTY_STRING if the title should be hidden.
   * @private
   */
  __generateSelectedSectionTitle(isStrictRendering, isMedicationsEmpty, isShowingPrescriptions) {
    if (isStrictRendering) {
      // When strictRendering is turned on, it is only desirable to display the medications title when the prescriptions
      // are being displayed and when the medicationsList is not empty.
      const isShowingMedicationsDescriptors = isShowingPrescriptions && !isMedicationsEmpty;
      return isShowingMedicationsDescriptors ? this.config.strings.selectedSectionTitle : '';
    }

    // For default rendering, the title should always be displayed.
    return this.config.strings.selectedSectionTitle;
  }

  /**
   * Function will generate the value for the `suggestedSectionTitle` attribute belonging to the pui-chicklet-container.
   * As far as the medications-page is concerned, the suggestedSectionTitle is the commonMedications' title.
   * @param isStrictRendering   {boolean}   The config option determining if strictDescriptorRendering is toggled on.
   * @return {string}                       'Common Medications' if suggestedSectionTitle should be displayed; EMPTY_STRING if not.
   * @private
   */
  _generateSuggestedSectionTitle(isStrictRendering) {
    // When strictRendering is toggled on, the commonMedications will never have a title, and will essentially be
    // merged with the medicationsList.
    return isStrictRendering ? '' : 'Common Medications';
  }

  /**
   * Function will generate a `hideSuggested` string that acts as a boolean-attribute for the pui-chicklet-container.
   * The `suggestedSectionTitle` and the chicklets generated for each commonMedication all have a `suggested` boolean
   * attribute. When `hideSuggested` is true, all objects with this `suggested` attribute set to true will be hidden;
   * when false, they will be shown.
   * @param isStrictRendering             {boolean}     The config option determining if strictDescriptorRendering is toggled on.
   * @param isShowingPrescriptions        {string}      The `showCustom` boolean attribute; if non-empty, then True; otherwise, False.
   * @param isMedicationsEmpty            {boolean}     Boolean determining whether the medicationsList is empty.
   * @param isCommonMedicationsEmpty      {boolean}     Boolean determining whether the commonMedicationsList is empty.
   * @return {string}                                   'hideSuggested' if commonMedications should be hidden; EMPTY_STRING if
   *                                                    they should be displayed.
   * @private
   */
  __generateHideSuggested(isStrictRendering, isShowingPrescriptions, isMedicationsEmpty, isCommonMedicationsEmpty) {
    if (isStrictRendering) {
      // When strict rendering is on, the commonMedications should only be displayed when:
      // 1. The prescriptions are hidden.
      // 2. The medications are hidden
      // 3. the commonMedications are not empty
      // 4. The `config.hideCommonMedications` is false.
      // Essentially, when they are shown, they should be the only thing on the page.
      const isShowingCommonMedications = !this.config.hideCommonMedications && !isShowingPrescriptions &&
        isMedicationsEmpty && !isCommonMedicationsEmpty;

      return isShowingCommonMedications ? '' : 'hideSuggested';
    }

    // The default rendering option will only depend on the config option when deciding whether to hideSuggested or not.
    return this.config.hideCommonMedications ? 'hideSuggested' : '';
  }

  _buildChicklet(list, callbacks) {
    const container = this.querySelector('#apex-medication-chiclet-container');
    if (this.renderBottomSheet) {
      container.removeChickletCallback = this.renderBottomSheet.bind(this);
    }

    for (const item in list) {
      const chicklet = new PUIChiclet();
      chicklet.label = list[item].displayName;
      chicklet.value = list[item].rxcui;
      chicklet.provenance = list[item].provenance;
      chicklet.closeLabel = this.config.strings.itemCloseLabel ? this.config.strings.itemCloseLabel : '';

      chicklet.data = {
        medicationResultTermType: list[item].medicationResultTermType,
        gsddMarketedProductId: list[item].gsddMarketedProductId,
        ndc: list[item].ndc,
        displayName: list[item].displayName,
        rxcui: list[item].rxcui,
        provenance: list[item].provenance,
        dosageForms: list[item].dosageForms,
        packs: list[item].packs,
      };

      chicklet.selected = list[item].selected;
      chicklet.suggested = list[item].suggested;

      container._addInitializedItem(chicklet);
      chicklet.addEventListener('click', (event) => {
        const { onItemClick } = callbacks;
        if (onItemClick) {
          setTimeout(() => {
            onItemClick(container._getSelectedChiclets(), container._getSuggestedChiclets());
          }, 0);
        }
      });
      chicklet.addEventListener('keyup', (event) => {
        if (event.keyCode === keyCodes.ENTER_KEYCODE) {
          const { onItemClick } = callbacks;
          if (onItemClick) {
            setTimeout(() => {
              onItemClick(container._getSelectedChiclets(), container._getSuggestedChiclets());
            }, 0);
          }
        }
      });
    }
  }

  _buildPrescriptionChicklet(list) {
    const container = this.querySelector('#apex-medication-chiclet-container');
    for (const item in list) {
      const chicklet = new PUIChiclet();
      chicklet.label = list[item].name;
      container._addChicletToCustom(chicklet);
      chicklet.disable();
    }
  }

  _getSelected() {
    const container = this.querySelector('#apex-medication-chiclet-container');
    const medications = [];
    const selected = container._getSelectedChiclets();

    const obj = [...selected.entries()].reduce((obj, [key, value]) => (obj[key] = value, obj), {});

    for (const [key, value] of Object.entries(obj)) {
      const stringData = JSON.stringify(value.data);
      const data = JSON.parse(stringData);
      const { displayName } = data;
      const dosageFormRxcui = data.dosageForms ? data.dosageForms[0].rxcui : null;
      const strengthRxcui = dosageFormRxcui ? data.dosageForms[0].strengths[0].rxcui : null;
      const isOtc = data.medicationResultTermType === 'OTC';
      const packagingType = data.dosageForms ? 'dosageForm' : '';
      const packRxcui = (data.packs && data.packs.length > 0) ? data.packs[0].rxcui : '';
      const strengthKnown = isOtc ? !!data.ppStrength : false;
      const configuredMedication = {
        displayName,
        strengthKnown,
        packagingType,
        dosageFormRxcui,
        strengthRxcui,
        packRxcui,
        isOtc,
      };

      medications.push(convertToMedication(data, configuredMedication));
    }

    return medications;
  }
}

window.customElements.define('pui-med-chicklet-container', PUIMedChickletContainer);
