import PUIBase from '../pui-base';
import PUIMedConfigurator from './pui-med-configurator';
import convertToMedication from './pui-medication-converter';

/**
 * This class is responsible for displaying a medication and its strength options
 * It allows the customer to choose a strength option as well as to remove the medication from their med list.
 * To get the selectedMedication detail from an instance of this class, call getMedication
 *
 * Also, if strength options aren't present, this component will attempt to fetch the options from the server at the
 * configured url
 *
 * Callbacks:
 *
 * Callback for when cancel button was pressed
 * @callback onCancel
 * @param {PUIMedItem} this - This item
 *
 * Callback for when confirm button was pressed
 * @callback onConfirm
 * @param {PUIMedItem} this - This item
 *
 * Configuration:
 *
 * @param {MedItemConfig} this.config - The configuration for PUIMedItem
 * @param {boolean} this.config.displayUnknownOption - The boolean for displaying the "I don't know" option
 * @param {Medication} this.config.medication - The medication for this PUIMedItem
 * @param {SearchResult} this.config.searchResult - The ES Doc for this PUIMedItem
 * @param {Boolean} this.config.hideToggle - Determines whether the toggle button is hidden or not
 * @param {string} this.config.strings.cancelButton - The string for the cancel button
 * @param {string} this.config.strings.confirmButton - The string for the confirm button
 * @param {string} this.config.strings.strengthSelectLabel - The label for the strenght select input
 * @param {string} this.config.strings.dontKnowOption - The string for the don't know option
 * @param {string} this.config.strings.strengthSelectCancel - The string for the cancel option on strength selection
 * @param {string} this.config.strings.configuratorTitle - The title for this configurator
 * @param {string} this.config.strings.packagingQuestion - The question asking which dosage form to select
 * @param {string} this.config.strings.strengthQuestion - The question asking which strength to select
 * @param {string} this.config.strings.completeConfigurationButton - The string for the configurator finish button
 * @param {string} this.config.strings.completeUpdateConfigurationButton - The string for the configurator finish button
 * @param {string} this.config.strings.configurationCancel - The string for cancelling configurator
 * @param {onCancel} this.config.callbacks.onCancel - The callback for the cancel action
 * @param {onConfirm} this.config.callbacks.onConfirm - The callback for the confirm action
 *
*/
export default class PUIMedItem extends PUIBase {
  connectedCallback() {
    super.connectedCallback();
    this.upgradeProperty('config');
    this._render();
  }

  /**
   * Toggles the visiblity of the strength selection section
   */
  toggleExpandedSection() {
    this._toggleExpandedSection();
  }

  /**
   * Returns the medication associated with this PUIMedItem.
   * This could either be a search medication item or one that has come from their health profile.
   */
  getMedication() {
    return this.config.medication;
  }

  _render() {
    const {
      medication,
      hideToggle,
      strings,
    } = this.config;

    this.classList.add('pui-block');
    const hideMed = medication.chaseRequested ? medication.chaseRequested : false;
    this.innerHTML = `
      <pui-box id="apex-medication-item-${medication.id}" spacingTop="small" class="${hideMed ? 'pui-hidden' : ''}">
        <pui-section flowDirection="horizontal" mainAxisArrangement="space-between" secondaryAxisArrangement="center">
            <pui-text id="apex-medication-title-${medication.id}"></pui-text>
            <pui-icon id="apex-medication-expand-icon-${medication.id}" imgClass="ellipsis-icon" 
                      class="${hideToggle ? 'pui-hidden' : ''}"></pui-icon>
        </pui-section>
        <pui-section id="apex-medication-expanded-section-${medication.id}" paddingTop="small" 
                     class="${hideToggle ? '' : 'pui-hidden'}">
          <pui-section flowDirection="horizontal" spacingTop="small">
            <pui-section-column flexGrid="48">
              <pui-button id="apex-medication-cancel-button-${medication.id}" label="${strings.cancelButton}"
                          theme="secondary" textColor="red-color"></pui-button>
            </pui-section-column>
            <pui-section-column flexGrid="4"></pui-section-column>
            <pui-section-column flexGrid="48">
              <pui-button id="apex-medication-confirm-button-${medication.id}"
                          label="${strings.confirmButton}" theme="secondary" ></pui-button>
            </pui-section-column>
          </pui-section>
        </pui-section>
        <div class="pui-hidden med-detail" data-med="${encodeURIComponent(JSON.stringify(medication))}"></div>
      </pui-box>
    `;

    this._label = this.querySelector(`#apex-medication-title-${medication.id}`);
    this._dropdown = this.querySelector(`#apex-medication-strength-select-dropdown-${medication.id}`);
    this._cancelButton = this.querySelector(`#apex-medication-cancel-button-${medication.id}`);
    this._confirmButton = this.querySelector(`#apex-medication-confirm-button-${medication.id}`);
    this._toggleIcon = this.querySelector(`#apex-medication-expand-icon-${medication.id}`);
    this._expandedSection = this.querySelector(`#apex-medication-expanded-section-${medication.id}`);

    this._setLabelText();

    this._cancelButton.onButtonPress = this._onCancel.bind(this);
    this._confirmButton.onButtonPress = this._onConfirm.bind(this);

    this._toggleIcon.addEventListener('click', this._toggleExpandedSection.bind(this));
  }

  _setLabelText() {
    const { medication } = this.config;
    let medicationLabelText = medication.name;
    if (medication.strength) {
      medicationLabelText += `, ${medication.strength}`;
    }
    this._label.setText(medicationLabelText);
  }

  _onCancel() {
    const { onCancel } = this.config.callbacks;
    if (onCancel) {
      onCancel(this);
    }
  }

  _onConfirm() {
    const { onConfirm } = this.config.callbacks;
    this._updateMedItem();
    if (onConfirm) {
      onConfirm(this);
    }
  }

  _updateMedItem() {
    const { medication } = this.config;
    const clickedMedication = medication.searchResult;
    const medConfigurator = new PUIMedConfigurator();
    const { strings } = this.config;
    strings.completeConfigurationButton = strings.completeUpdateConfigurationButton;
    const medConfiguratorConfig = {
      displayUnknownOption: this.config.displayUnknownOption,
      medication: clickedMedication,
      strings,
      callbacks: {
        onConfigurationComplete: this._onConfigurationComplete.bind(this),
      },
    };

    medConfigurator.configure(medConfiguratorConfig);
    this.appendChild(medConfigurator);
  }

  _onConfigurationComplete(searchResult, medicationConfiguration) {
    this.config.medication = convertToMedication(searchResult, medicationConfiguration);
    this.config.searchResult = searchResult;
    this._render();
  }

  _toggleExpandedSection() {
    this._expandedSection.toggleVisibility();
  }
}

window.customElements.define('pui-med-item', PUIMedItem);
