import PUIBase from '../pui-base';

/**
 * This page is shown whenever we encounter an unhandled exception
 *
 * Configuration:
 *
 * @param {ErrorPageConfig} this.config - The configuration for the PUIErrorPage
 *
 */
export default class PUIErrorPage extends PUIBase {
  connectedCallback() {
    super.connectedCallback();
    this._render();
  }

  _render() {
    document.body.classList.add('pui-error-body');
    this.innerHTML = `
      <pui-section pageContainer>
        <pui-back spacingBottom="medium"></pui-back>
        <pui-heading input="Sorry, something went wrong..." textColor="white" spacingBottom="medium"></pui-heading>
        <pui-text input="Please go back or try refreshing the page" textColor="white"></pui-text>
      </pui-section>
    `;
  }
}

window.customElements.define('pui-error-page', PUIErrorPage);
