import PUIPage from '../pui-page';
import PUIMedications from './pui-medications';
import Request from '../../networking/request';
import keyCodes from '../../constants/keyCodes';

/**
 * This page is a container for the pui-medications component and also includes the additional functionality
 * of being able to call out to health profile service to update medications
 *
 *
 * Configuration:
 *
 * @param {MedicationsPageConfig} this.config - The configuration for PUIMedications
 * @param {string} this.config.pageStyle - Either 'card-form' or 'standard-form'
 * @param {boolean} this.config.hideCommonMedications - boolean attribute to hide/show Common Medications
 * @param {boolean} this.config.showMedicationRemovalBottomSheet - boolean attribute to hide/show medication removal confirmation bottom sheet.
 * @param {string} this.config.environment - (Optional) The environment this will be rendered in. Valid options include: ['signup', 'default']
 *                                           At this time, 'default' is equivalent to 'signup which will consist of a PUIMedicationsPage
 *                                           with a simple search box and chiclet container. If undefined, the PUIMedicationsPage will
 *                                           contain a PUIMedConfigurator which enables more complex medication configurations like strength
 *                                           and dosage form.
 * @param {string} this.config.backUrl - The url used for going to the previous page
 * @param {string} this.config.actionUrl - The url for saving the added medications
 * @param {string} this.config.nextUrl - The url for the page to transition to after saving medications
 * @param {string} this.config.searchUrl - The url used for searching medications
 * @param {string} this.config.searchRequestMethod - The request method type for search request, can be 'GET' or 'POST', default is 'POST'
 * @param {string} this.config.searchQueryDelay - Specify the delay in millliseconds before initiating a search query
 * @param {string} this.config.currentMedications - The url used for getting the current medications
 * @param {string} this.config.currentMedicationsUrl - The url used for getting the current medications
 * @param {string} this.config.iconClass - The class for the image icon used for this page.
 * @param {boolean} this.config.displayUnknownOption - The boolean for displaying the "I don't know" option
 * @param {boolean} this.config.commonMedications - the common medications
 * @param {boolean} this.config.showPrescriptions - the boolean to show/hide prescriptions section
 * @param {boolean} this.config.isMedicationsReviewed - The boolean for medication review status
 * @param {boolean} this.config.strictDescriptorRendering - This option enables a strict type of rendering for titles and descriptions, where
 *                                                          they will not render if their subsequent elements are empty or supposed to be hidden.
 *                                                          For example, in contrast to the current rendering strategy, the prescription title and
 *                                                          description will not be displayed if the user is not taking any Amazon Pharmacy
 *                                                          prescriptions (prescriptions list is empty). It will also only render the
 *                                                          commonMedications iff both the prescriptions list and medications list are empty.
 *                                                          Note: if prescriptions are empty, it will not display a title for medications either,
 *                                                          but it will display the medication(s) as chiclet(s).
 * @param {boolean} this.config.disablePageContainer - Flag that will remove `pageContainer` created in underlying
 *                                                     pui-sections which consequently does not include
 *                                                     pui-section-padding scss class.
 * @param {string} this.config.medicationsAndPrescriptionsUrl - The url used for getting the current medications and prescriptions
 * @param {string} this.config.strings.pageTitle - The title text for the medication page
 * @param {string} this.config.strings.pageDescription - The description text for the medication page
 * @param {string} this.config.strings.saveButton - The text for the save medication button
 * @param {string} this.config.strings.searchInputTitle - The title text for the search input
 * @param {string} this.config.strings.searchInputPlaceholder - The placeholder text fof the search input
 * @param {string} this.config.strings.searchResultGeneric - Label to show the search item is generic
 * @param {string} this.config.strings.noResultsFound - The text that displays when no results found
 * @param {string} this.config.strings.cancelButton - The cancel button text
 * @param {string} this.config.strings.addButton - The add button text
 * @param {string} this.config.strings.removeButton - The remove button text
 * @param {string} this.config.strings.updateButton - The update button text
 * @param {string} this.config.strings.strengthSelectLabel - The label for the strenght select input
 * @param {string} this.config.strings.dontKnowOption - The string for the don't know option
 * @param {string} this.config.strings.strengthSelectCancel - The string for the cancel option on strength selection
 * @param {string} this.config.strings.checkboxLabel - The string for the no medications checkbox
 * @param {string} this.config.strings.errorStateText - The string for the error state
 * @param {string} this.config.strings.selectedSectionTitle -  The string for title of selectedSection.
 * @param {string} this.config.strings.prescriptionsSectionTitle -  The string for title of customSection.
 * @param {string} this.config.strings.prescriptionsSectionDescription -  The string for description of customSection.
 * @param {string} this.config.strings.configuratorTitle - The title for this configurator
 * @param {string} this.config.strings.packagingQuestion - The question asking which dosage form to select
 * @param {string} this.config.strings.strengthQuestion - The question asking which strength to select
 * @param {string} this.config.strings.completeConfigurationButton - The string for the configurator finish button
 * @param {string} this.config.strings.completeUpdateConfigurationButton - The string for the configurator finish update button
 * @param {string} this.config.strings.medicationRemovalBottomSheetTitle - The string for title of removal confirmation bottomSheet.
 * @param {string} this.config.strings.medicationRemovalCancelButton - The string for cancel button of removal confirmation bottomSheet.
 * @param {string} this.config.strings.medicationRemovalBottomSheetConfirmButton - The string for remove medication button of removal confirmation bottomSheet.
 * @param {string} this.config.strings.healthProfileRemovalDescription - The description to show on bottomSheet
 * when try to remove medication from HealthProfileService.
 * @param {string} this.config.strings.prescriptionRemovalDescription - The description to show on bottomSheet
 * when try to remove prescription from PrescriptionService.

 * @param {string} this.config.strings.configurationCancel - The string for cancelling configurator
 * @param {string} this.config.strings.finishYourProfileBannerHeader - Header for banner shown during Check Insurance Price finish your profile flow
 * @param {string} this.config.strings.finishYourProfileBannerContent - Content string for banner shown during Check Insurance Price finish your profile flow
 *
 * @callback onSave
 * @param {onSave} this.config.callbacks.onSave - A callback that is invoked when saving medications
 */
export default class PUIMedicationsPage extends PUIPage {
  connectedCallback() {
    super.connectedCallback();
    this._render();
  }

  _render() {
    this.classList.add('pui-block');
    const { strings, feature } = this.config;
    const showCheckBox = this._isEnvironmentSignUpOrDefault();
    strings.checkboxLabel = strings.checkboxLabel ? strings.checkboxLabel : 'I am not taking medications or vitamins';
    const checkBoxHTML = `<pui-checkbox id="no-medications-check-box" label="${strings.checkboxLabel}" style="border-top:1px solid #DFE0E4" spacingTop="medium"></pui-checkbox>`;
    const header = strings.pageTitle ? `
      <pui-section id="med-profile-title-section" spacingBottom="medium">
        <pui-section flowDirection="horizontal" secondaryAxisArrangement="center">
          <pui-section-column>
            <pui-icon imgClass="${this.config.iconClass}" spacingRight="small"></pui-icon>
          </pui-section-column>
          <pui-section-column>
            <pui-heading input="${strings.pageTitle}" textSize="large"></pui-heading>
          </pui-section-column>
        </pui-section>

        <pui-text input="${strings.pageDescription}"
                  spacingTop="medium" textSize="medium"></pui-text>
      </pui-section>
    ` : '';

    // use toLocaleUpperCase and override the locale since customers can configure
    // their own device locale, and that impacts string casing (eg, Turkish uppercases 'i' differently)
    const finishYourProfilePrompt = (!!feature && feature.toLocaleUpperCase("en-US") === "CHECKOUT_INSURANCE_PRICE") ? `
      <pui-banner status="information" spacingBottom="small">
          <pui-text
              input="${strings.finishYourProfileBannerHeader}"
              fontWeight="bold"
              spacingBottom="small"
          ></pui-text>
          <pui-text input="${strings.finishYourProfileBannerContent}"></pui-text>
      </pui-banner>
    ` : '';

    const content = {
      main: `
        ${finishYourProfilePrompt}

        ${header}

        <pui-banner id="medications-error-banner" status="error" class="pui-hidden">
            <pui-text input="${strings.errorStateText}" textColor="red" inline></pui-text>
        </pui-banner>

        <pui-bottom-sheet id="medication-removal-bottom-sheet" hideLink>
            <div id="medication-removal-confirmation-container">
                <pui-heading input="${strings.medicationRemovalBottomSheetTitle}" textSize="medium" spacingBottom="small"></pui-heading>
                <div id="medication-removal-bottom-sheet-description">
                </div>
                <pui-section flowDirection="horizontal" spacingTop="small">
                    <pui-section-column>
                      <pui-button-with-initials id="bottomSheetCancelButton" label="${strings.medicationRemovalBottomSheetCancelButton}"></pui-button-with-initials>
                    </pui-section-column>
                    <pui-section-column> 
                      <pui-button-with-initials id="bottomSheetConfirmButton" spacingLeft="small" textcolor="red-color" label="${strings.medicationRemovalBottomSheetConfirmButton}"></pui-button-with-initials>
                    </pui-section-column>
                </pui-section>
            </div>
        </pui-bottom-sheet>
        
        <div id="apex-medications-container">
        </div>
        ${showCheckBox ? checkBoxHTML : ''}
      `,
      footerDesktop: `
        <pui-button id="apex-add-medication-continue-button-desktop" label="${strings.saveButton}" form-action="submit">
        </pui-button>
      `,
      footerMobile: `
        <pui-button id="apex-add-medication-continue-button-mobile" label="${strings.saveButton}" form-action="submit">
        </pui-button>
      `,
    };
    super._render(content);

    this._medications = new PUIMedications();
    this._medications.configure(this.config);
    if (this.config.showMedicationRemovalBottomSheet === true) {
      this._medications.renderBottomSheet = this._renderBottomSheet.bind(this);
    }
    this._medication_removal_bottomSheet = this.querySelector('#medication-removal-bottom-sheet');
    this._medication_removal_bottomSheet.onClose = this._selectUnremoved.bind(this);
    const apexMedicationContainer = this.querySelector('#apex-medications-container');
    apexMedicationContainer.innerHTML = '';
    apexMedicationContainer.appendChild(this._medications);
    if (this._isEnvironmentSignUpOrDefault()) {
      this._noMedicationsCheckbox = this.querySelector('#no-medications-check-box');
      this._noMedicationsCheckbox.onclick = (event) => {
        const chicletContainer = document.getElementById('apex-medication-chiclet-container');
        if (this._noMedicationsCheckbox.isChecked()) {
          this._removeSelectedState();
          chicletContainer.removeAllSelections();
          this._errorBanner.classList.add('pui-hidden');
        }
      };
      this._noMedicationsCheckbox.onkeyup = (event) => {
        if (event.keyCode === keyCodes.ENTER_KEYCODE) {
          const chicletContainer = document.getElementById('apex-medication-chiclet-container');
          if (this._noMedicationsCheckbox.isChecked()) {
            this._removeSelectedState();
            chicletContainer.removeAllSelections();
            this._errorBanner.classList.add('pui-hidden');
          }
        }
      };
    }

    this._bottomSheetCancelButton = this.querySelector('#bottomSheetCancelButton');
    this._bottomSheetCancelButton.addEventListener('click', this._onBottomSheetCancelClick.bind(this));
    this._bottomSheetConfirmButton = this.querySelector('#bottomSheetConfirmButton');
    this._bottomSheetConfirmButton.addEventListener('click', this._onBottomSheetConfirmClick.bind(this));

    this._continueButtonDesktop = this.querySelector('#apex-add-medication-continue-button-desktop');
    this._continueButtonMobile = this.querySelector('#apex-add-medication-continue-button-mobile');
    this._continueButtonDesktop.addEventListener('click', this._updateMedications.bind(this));
    this._continueButtonMobile.addEventListener('click', this._updateMedications.bind(this));

    this._errorBanner = this.querySelector('#medications-error-banner');
  }

  /**
   * Gets the list of medications from the pui-medications component and updates the health profile service with
   * these medications.
   */
  _updateMedications() {
    let selectedMedications = [];
    selectedMedications = this._medications.getSelectedMedications();
    if (selectedMedications.length === 0 && !this._noMedicationsCheckbox.isChecked()) {
      this._errorBanner.classList.remove('pui-hidden');
      return;
    }

    const { callbacks } = this.config;
    if (callbacks && callbacks.onSave) {
      callbacks.onSave(selectedMedications);
    } else {
      Request.post(this.config.actionUrl, { medications: selectedMedications }).then(() => {
        window.location.href = this.config.nextUrl;
      });
    }
  }

  /**
   * Sets the "selected" state of medications in medicationList and commonMedications to be false when "I am not
   * taking medications" checkbox is checked
   */
  _removeSelectedState() {
    if (this._medications.commonMedicationsList) {
      this._medications.commonMedicationsList.forEach(medItem => medItem.selected = false);
    }
    if (this._medications.medicationsList) {
      this._medications.medicationsList.forEach(medItem => medItem.selected = false);
    }
  }

  _isEnvironmentSignUpOrDefault() {
    return ['signup', 'default'].includes(this.config.environment);
  }

  _renderBottomSheet(chiclet) {
    // prevent bottomSheet to show when remove chiclet comes from search suggested.
    if (chiclet.provenance === 'undefined') {
      const chicletContainer = document.getElementById('apex-medication-chiclet-container');
      chicletContainer._removeChiclet(chiclet);
      return;
    }
    this._toRemoveChiclet = chiclet;
    const description = this._getBottomSheetDescription(chiclet);
    const div = this.querySelector('#medication-removal-bottom-sheet-description');
    div.innerHTML = `
        <pui-section>
            <pui-text input="${chiclet.label}" fontWeight="bold" spacingBottom="small"></pui-text>
        </pui-section>
        <pui-section>
            <pui-text input="${description}"></pui-text>
        </pui-section>
    `;
    this._medication_removal_bottomSheet.show();
  }

  _getBottomSheetDescription(chiclet) {
    const { strings } = this.config;
    if (chiclet.provenance === 'PRESCRIPTION') {
      return strings.prescriptionRemovalDescription.replace('{}', chiclet.label);
    }
    return strings.healthProfileRemovalDescription.replace('{}', chiclet.label);
  }

  _onBottomSheetCancelClick() {
    this._selectUnremoved();
    this._medication_removal_bottomSheet.hide();
  }

  _onBottomSheetConfirmClick() {
    this._removeFromSelected();
    this._medication_removal_bottomSheet.hide();
  }

  _selectUnremoved() {
    this._toRemoveChiclet.selected = true;
  }

  _removeFromSelected() {
    const chicletContainer = document.getElementById('apex-medication-chiclet-container');
    chicletContainer._removeChiclet(this._toRemoveChiclet);
    chicletContainer._addChicletToSuggested(this._toRemoveChiclet);
    // Update medicationsList selected and suggested attribute, this will be used to build selected and suggested chiclets in PUIMedChickletContainer.
    for (const index in this._medications.medicationsList) {
      const med = this._medications.medicationsList[index];
      if (med.displayName === this._toRemoveChiclet.label) {
        this._medications.medicationsList[index].selected = false;
        this._medications.medicationsList[index].suggested = true;
      }
    }
  }
}

window.customElements.define('pui-medications-page', PUIMedicationsPage);
