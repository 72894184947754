import PUIBase from './pui-base';

import includes from '../functions/includes';
import getTextSize from '../attributeValues/textSize';
import getLinkColor from '../attributeValues/linkColor';
import getTextAlign from '../attributeValues/textAlign';
import getFontWeights from '../attributeValues/fontWeight';
import { keyListener } from '../functions/domUtils';

const ESAPI = require('node-esapi');

const LINK_STYLES = ["lower-underscore"];
/**
 * An element to link the customer to another destination, or trigger some action
 * 
 * ### Example
 * 
 *  ```html
 *  <pui-link href="https://pharmacy.amazon.com/" text="Go to AP"></pui-link>
 *  ```
 * 
 * ### Events
 * 
 * This element events a `pressed` event when the link is clicked, or receives
 * an 'enter' keyboard event.
 * 
 * #### Example
 * 
 *  ```js
 *  return html`
 *    <pui-link text="Click me!"
 *        @pressed=${(event) => {
 *          console.log("Link was clicked");
 *          event.preventDefault(); //stops the link click from navigating, optional
 *        }>
 *    </pui-link>`;
 *  ```
 */
export class PUILink extends PUIBase {
  constructor() {
    super();
    this.defaultClass = 'pui-link';
    this.defaultText = '';
    this.defaultTextSize = 'large';
    this.defaultLinkColor = 'link';
    this.defaultTextAlign = 'left';
    this.defaultFontWeight = 'normal';
  }

  connectedCallback() {
    super.connectedCallback();
    this.render();
  }

  attributeChangedCallback() {
    super.attributeChangedCallback();
    this.render();
  }

  static get observedAttributes() {
    return [
      super.observedAttributes,
      'text',
      'textsize',
      'href',
      'color',
      'textalign',
      'external',
      'hoverunderline',
      'underline'
    ];
  }

  /**
     * @classprop {string} linkText This is the class name which determines the element text
     *
     */
  get text() {
    return this.getAttribute('text') || '';
  }

  set text(value) {
    this.setAttribute('text', value);
  }
  /**
   * @classprop {string}} linkStyle This defines whether we will have the pharmacy 2.0 underscored styling.
   * Said styling is a line lower than an underline in a specific lighter teal color
   */

  get linkStyle() {
    return this.getAttribute('linkstyle') || '';
  }

  set linkStyle(value) {
    this.setAttribute('linkstyle', value);
  }
  /**
   * @classprop {string} textSize This is the class name which determines the text textSize
   *
   */
  get textSize() {
    return this.getAttribute('textSize') || '';
  }

  set textSize(value) {
    this.setAttribute('textSize', value);
  }

  get href() {
    return this.getAttribute('href') || '';
  }

  set href(value) {
    this.setAttribute('href', value);
  }

  /**
   * @classprop {string} color This is the class name which determines the link text color
   *
   */
  get color() {
    return this.getAttribute('color') || '';
  }

  set color(value) {
    this.setAttribute('color', value);
  }

  /**
   * @classprop {string} hoverUnderline This is the class name which underlines the link when hovered
   *
   */
  get hoverUnderline() {
    return this.getBooleanAttribute('hoverUnderline');
  }

  set hoverUnderline(value) {
    return this.setBooleanAttribute('hoverUnderline', value);
  }

  /**
   * @classprop {string} textAlign This is the class name which alights the link text
   *
   */
  get textAlign() {
    return this.getAttribute('textAlign') || '';
  }

  set textAlign(value) {
    this.setAttribute('textAlign', value);
  }

  /**
   * @classprop {string} fontWeight This is the class name which determines the font weight
   *
   */
  get fontWeight() {
    return this.getAttribute('fontWeight') || '';
  }

  set fontWeight(value) {
    this.setAttribute('fontWeight', value);
  }

  /**
   * @classprop {string} external. If present, the link will open in new tab
   *
   */
  get external() {
    return this.getBooleanAttribute('external');
  }

  set external(value) {
    this.setBooleanAttribute('external', value);
  }

  /**
   * @classprop {string} underline This is the class name which sets whether or not the link is underlined by default
   */
  get underline() {
    return this.getBooleanAttribute('underline');
  }

  set underline(value) {
    return this.setBooleanAttribute('underline', value);
  }

  get encode() {
    return this.getBooleanAttribute('encode');
  }

  /**
   * Flag to encode. It's important for security risk.
   * Setting this to true will prevent payload injection that interacts with the victim's browser.
   * @param value
   */
  set encode(value) {
    this.setBooleanAttribute('encode', value);
  }

  changeLinkText(text) {
    this.text = text;
    this.render();
  }

  render() {
    this.removeEventListener("click", this._onPressedCb);
    this.removeEventListener("keyup", this._onPressedKeyboardCb);

    let {
      textSize,
      color,
      textAlign,
      fontWeight,
      encode,
    } = this;

    const {
      hoverUnderline,
      underline,
      external,
      linkStyle
    } = this;

    this.innerHTML = '';

    let label = this.text ? this.text : this.defaultText;
    if (encode) {
      label = ESAPI.encoder().encodeForHTML(label);
    }

    if (includes(LINK_STYLES, linkStyle)) {
      this.classList.add(linkStyle);
    }

    if (!includes(getTextSize(), this.textSize)) {
      textSize = this.defaultTextSize;
    }

    if (!includes(getLinkColor(), this.color)) {
      color = this.defaultLinkColor;
    }

    if (includes(getTextAlign(), this.textAlign)) {
      textAlign = `text-align-${textAlign}`;
    }

    if (!includes(getFontWeights(), this.fontWeight)) {
      fontWeight = this.defaultFontWeight;
    }

    this.classList.add('pui-inline');
    this._innerText = document.createElement('span');
    this._innerText.innerHTML = `
                ${textAlign ? `<div class=${textAlign}>` : ''}
                  <a href="${this.href ? this.href : '#'}" ${external ? 'target=_blank' : ''} class="text-align-right ${this.defaultClass} ${color}-color ${hoverUnderline ? 'hover-underline' : ''} ${underline ? 'underline-link' : ''}">
                      <pui-text inline input="${label}" textSize="${textSize}" fontWeight="${fontWeight}" textColor="${color}" ${encode ? 'encode' : ''}>
                      </pui-text>
                  </a>
                ${textAlign ? '</div>' : ''}
            `;
    this.appendChild(this._innerText);

    this.addEventListener("click", this._onPressedCb);
    this.addEventListener("keyup", this._onPressedKeyboardCb);
  }

  _onPressed(domEvent) {
    const event = new CustomEvent('pressed', {cancelable: true});
    const shouldPerformDefaultAction = this.dispatchEvent(event);
    if (!shouldPerformDefaultAction) {
      domEvent.preventDefault();
    }
  }

  _onPressedCb = this._onPressed.bind(this);
  _onPressedKeyboardCb = keyListener(this._onPressedCb);
}

window.customElements.define('pui-link', PUILink);
