import PUIBase from './pui-base';
import PUIFile from './file-upload/pui-file';
import { ERROR_CODES, attachmentPreviewType } from '../constants/fileUpload';

/**
 * This is the component which provides functionality to render input type file.
 *
 * This component currently supports two image capture modes: standard and webcam. The standard flow
 * will allow the user to select an image from their filesystem, or by default will hook into a native phone's
 * ability to take an image. The webcam mode will allow the user to take a picture using a desktop webcam
 * provided the user has one.
 *
 * Callbacks:
 *
 * @callback onPreviewAdded - Callback invoked upon adding a new file.
 * @callback onPreviewRemoved - Callback invoked upon removing an existing file.
 * @callback onValidationError - Callback invoked upon validation error.
 *
 */
export default class PUIFileUploadTwo extends PUIBase {
  constructor() {
    super();
    this._hiddenClass = 'pui-hidden';
    this._files = [];
    this.defaultAcceptedType = 'image/png,image/jpeg,image/jpg';
    this.defaultPlaceholderText = 'Take or upload photo';
    this.defaultUploadFileText = 'Upload photo';
    this.defaultWebcamCaptureText = 'Take photo';
    this.defaultMaximumFilesize = '5242880';
    this.defaultMaximumAllowedNumberOfFiles = 1;
    this.defaultPreviewAppearanceType = attachmentPreviewType.PREVIEW_TYPE_FULL;
    this.defaultInputId = 'pui-file-upload-two-input';
  }

  connectedCallback() {
    super.connectedCallback();
    this.render();
  }

  static get observedAttributes() {
    return [
      'inputId',
      'acceptedType',
      'placeholderIconClass',
      'placeholderText',
      'maximumFilesize',
      'errorMaximumFileSizeId',
      'maximumAllowedNumberOfFiles',
      'previewAppearanceType',
      // webcam specific attributes
      'webcamModeOff',
      'webcamInModal',
      'containerId',
      'useIcons',
      'customUploadFileText',
      'customWebcamCaptureText',
      'imageThresholdSizeToCompress',
      'theme',
    ];
  }

  /**
   * Retrieve strings config.
   * {string} strings.dismissBottomSheetTitle - The string for dismiss bottom sheet title
   * {string} strings.dismissBottomSheetCancelLabel - The string for dismiss bottom sheet cancel label
   * {string} strings.dismissBottomSheetDeleteLabel - The string for dismiss bottom sheet delete label
   * {string} strings.errorMaxFileSizeExceededTitle - Error banner title for maximum file size exceeded
   * {string} strings.errorMaxFileSizeExceededDesc - Error banner description for maximum file size exceeded
   * {string} strings.errorMaxAllowedFilesExceededTitle - Error banner title for maximum allowed files exceeded
   * {string} strings.errorMaxAllowedFilesExceededDesc - Error banner description for maximum allowed files exceeded
   * {string} strings.errorContentTypeNotAllowedTitle - Error banner title for content type not allowed
   * {string} strings.errorContentTypeNotAllowedDesc - Error banner description for content type not allowed
   * @returns {JSON}
   */
  get configStrings() {
    if (this.config && this.config.strings) {
      return this.config.strings;
    }

    const configStrings = this.getAttribute('configStrings') || undefined;
    const configStringsJSON = (configStrings === undefined) ? {} : JSON.parse(configStrings);
    this.config = { strings: configStringsJSON };
    return configStringsJSON;
  }

  set configStrings(value) {
    this.setAttribute('configStrings', value);
  }

  /**
   * Id of the input element to store all inputs
   * This value is required
   *
   * @type {string}
   * @attr
   */
  get inputId() {
    return this.getAttribute('inputId') || this.defaultInputId;
  }

  set inputId(value) {
    return this.setAttribute('inputId', value);
  }

  /**
   * Define the file types the file input should accept.
   * https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file#accept
   * @type {string}
   * @attr
   */
  get acceptedType() {
    return this.getAttribute('acceptedType') || this.defaultAcceptedType;
  }

  set acceptedType(value) {
    this.setAttribute('acceptedType', value);
  }

  /**
   * Default icon class for the placeholder
   * @type {string}
   * @attr
   */
  get placeholderIconClass() {
    return this.getAttribute('placeholderIconClass') || this.defaultPlaceholderIconClass;
  }

  set placeholderIconClass(value) {
    this.setAttribute('placeholderIconClass', value);
  }

  /**
   * Default text for the placeholder
   * @type {string}
   * @attr
   */
  get placeholderText() {
    return this.getAttribute('placeholderText') || this.defaultPlaceholderText;
  }

  set placeholderText(value) {
    this.setAttribute('placeholderText', value);
  }

  /**
   * Default maximum file size of the input
   * @type {string}
   * @attr
   */
  get maximumFilesize() {
    return this.getAttribute('maximumFilesize') || this.defaultMaximumFilesize;
  }

  set maximumFilesize(value) {
    this.setAttribute('maximumFilesize', value);
  }

  /**
   * ID of element that responsible to display error message if the file chosen
   * exceeds the maximum file size
   * @type {string}
   * @deprecated
   */
  get errorMaximumFileSizeId() {
    return this.getAttribute('errorMaximumFileSizeId');
  }

  set errorMaximumFileSizeId(value) {
    this.setAttribute('errorMaximumFileSizeId', value);
  }

  /**
   * Maximum number of files the user can select or upload
   * @type {string}
   * @attr
   */
  get maximumAllowedNumberOfFiles() {
    return this.getAttribute('maximumAllowedNumberOfFiles') || this.defaultMaximumAllowedNumberOfFiles;
  }

  set maximumAllowedNumberOfFiles(value) {
    this.setAttribute('maximumAllowedNumberOfFiles', value);
  }

  /**
   * Value to represent the appearance for the image preview screen. Can either be 'full' or 'compact'. Unknown
   * values will default to 'full' in appearance.
   * @type {string}
   * @attr
   */
  get previewAppearanceType() {
    return this.getAttribute('previewAppearanceType') || this.defaultPreviewAppearanceType;
  }

  set previewAppearanceType(value) {
    this.setAttribute('previewAppearanceType', value);
  }

  /**
   * Value to represent whether this component should be rendered in webcam mode. On mobile, just 1 input
   * button is present, and we rely on the device to select to take an image. On webcam mode, we will allow them to choose
   * from a file or optionally use a webcam to take a photo.
   * @type {boolean}
   * @attr
   */
  get webcamModeOff() {
    return this.getBooleanAttribute('webcamModeOff');
  }

  set webcamModeOff(value) {
    this.setBooleanAttribute('webcamModeOff', value);
  }

  /**
   * Value to represent whether webcam mode should use icons for the initial buttons
   * @type {boolean}
   * @attr
   */
  get useIcons() {
    return this.getBooleanAttribute('useIcons');
  }

  set useIcons(value) {
    this.setBooleanAttribute('useIcons', value);
  }

  /**
   * Value to represent whether webcam should show in modal or not. Not required, defaults to true in webcam component
   * @type {boolean}
   * @attr
   */
  get webcamInModal() {
    return this.getBooleanAttribute('webcamInModal');
  }

  set webcamInModal(value) {
    this.setBooleanAttribute('webcamInModal', value);
  }

  /**
   * Value of container id to pass into webcam component if we want to hide all of container's child elements.
   * Not required
   * @type {string}
   * @attr
   */
  get containerId() {
    return this.getAttribute('containerId');
  }

  set containerId(value) {
    this.setAttribute('containerId', value);
  }

  /**
   * Button text for the upload button when webcam is available
   * @type {string}
   * @attr
   */
  get customUploadFileText() {
    return this.getAttribute('customUploadFileText') || this.defaultUploadFileText;
  }

  set customUploadFileText(value) {
    this.setAttribute('customUploadFileText', value);
  }

  /**
   * Button text for webcam capture when webcam is available
   * @type {string}
   * @attr
   */
  get customWebcamCaptureText() {
    return this.getAttribute('customWebcamCaptureText') || this.defaultWebcamCaptureText;
  }

  set customWebcamCaptureText(value) {
    this.setAttribute('customWebcamCaptureText', value);
  }

  /**
   * Boolean to indicate whether bottom sheet will be displayed for confirmation when user dismisses a preview
   * @returns {boolean}
   */
  get displayBottomSheetOnRemoval() {
    return this.getBooleanAttribute('displayBottomSheetOnRemoval');
  }

  set displayBottomSheetOnRemoval(displayBottomSheetOnRemoval) {
    this.setBooleanAttribute('displayBottomSheetOnRemoval', displayBottomSheetOnRemoval);
  }

  /**
   * Feature ID defined for requesting native device functions (e.g. camera, photos) from PermissionService onboarding
   * https://w.amazon.com/bin/view/MShopPermissionService
   * @type {string}
   * @attr
   */
  get featureId() {
    return this.getAttribute('featureId');
  }

  set featureId(value) {
    this.setAttribute('featureId', value);
  }

  /**
   * Request ID defined for requesting native device functions (e.g. camera, photos) from PermissionService onboarding
   * https://w.amazon.com/bin/view/MShopPermissionService
   * @type {string}
   * @attr
   */
  get requestId() {
    return this.getAttribute('requestId');
  }

  set requestId(value) {
    this.setAttribute('requestId', value);
  }

  get imageThresholdSizeToCompress() {
    return this.getAttribute('imageThresholdSizeToCompress');
  }

  set imageThresholdSizeToCompress(value) {
    this.setAttribute('imageThresholdSizeToCompress', value);
  }

  /**
   * Specifies the type of styling to be used for this button
   * @type {"primary"|"secondary"|"line-item-delete"}
   * @attr
   */
  get theme() {
    return this.getAttribute('theme') || '';
  }

  set theme(value) {
    this.setAttribute('theme', value);
  }

  fileInput() {
    return this._innerFileInput;
  }

  _isMobileDevice() {
    return document.documentElement.classList.contains('a-mobile');
  }

  // Expected behavior:
  // - On iOS, this should prompt the user to choose between Take Photo or Video, Photo Library, or Browse.
  // - On Android, this should prompt the user to choose between Camera or Files.
  render() {
    const {
      name,
      acceptedType,
      useIcons,
      webcamInModal,
      webcamModeOff,
      customWebcamCaptureText,
      customUploadFileText,
      maximumFilesize,
      featureId,
      requestId,
      theme,
    } = this;

    this.innerHTML = `
        <div class="pui-file-upload-two ${theme}">
            <input 
                class="pui-hidden" 
                id="${this.inputId}" 
                type="file" 
                name="${name}" 
                accept="${acceptedType}"/>
            <pui-file-upload-button-container 
                id="pui-file-upload-button-container" 
                inputId="${this.inputId}"
                ${this._isMobileDevice() ? 'isMobileDevice' : ''}
                placeholderIconClass="${this.placeholderIconClass}"
                placeholderText="${this.placeholderText}"
                ${useIcons ? 'useIcons' : ''}
                ${webcamInModal ? 'webcamInModal' : ''}
                ${webcamModeOff ? 'webcamModeOff' : ''}
                customWebcamCaptureText="${customWebcamCaptureText}"
                customUploadFileText="${customUploadFileText}"
                acceptedType="${acceptedType}"
                maximumFilesize="${maximumFilesize}"
                featureId="${featureId}"
                requestId="${requestId}">
            </pui-file-upload-button-container>
            <div class="pui-file-upload-two-error"></div>
            <pui-file-preview-container 
                id="pui-file-preview-container" 
                previewAppearanceType=${this.previewAppearanceType}
                maximumAllowedNumberOfFiles=${this.maximumAllowedNumberOfFiles}
                ${this.displayBottomSheetOnRemoval ? 'displayBottomSheetOnRemoval' : ''}
                ${this._isMobileDevice() ? 'isMobileDevice' : ''}>
            </pui-file-preview-container>
        </div>`;

    this._setupElements();

    // Legacy element
    // TODO: Migrate to use built-in validator or use error callback.
    this._errorMaximumFileSizeElement = document.getElementById(this.errorMaximumFileSizeId);
  }

  /**
   * Add PUIFile to the file preview list.
   * @param puiFile existing file
   */

  addFilePreview(puiFile) {
    this._previewContainer.addFilePreview(puiFile);
  }

  _setupElements() {
    // Main element
    this._innerFileInput = this.querySelector(`#${this.inputId}`);

    // File upload button container
    this._fileUploadContainer = this.querySelector('#pui-file-upload-button-container');
    this._fileUploadContainer.onSelect = this._handleFileInputOnChange.bind(this);
    this._fileUploadContainer.onError = this._onErrorCallback.bind(this);

    // Preview container
    this._previewContainer = this.querySelector('#pui-file-preview-container');
    this._previewContainer.configure({ strings: this.configStrings });
    this._previewContainer.dismissBottomSheetProps = (this.displayBottomSheetOnRemoval && this.dismissBottomSheetProps) ? this.dismissBottomSheetProps : '';
    this._previewContainer.onRemoved = this._dismissHandlerOnClick.bind(this);
    this._previewContainer.onAdded = this._addNewFileCallback.bind(this);
    this._previewContainer.onError = this._onErrorCallback.bind(this);
    this._previewContainer.imageThresholdSizeToCompress = this.imageThresholdSizeToCompress;

    // Error banner
    this._errorBannerElement = this.querySelector('.pui-file-upload-two-error');
  }

  /**
   * Add new selected file to the file preview container.
   * @param newFile File
   */
  _renderPreview(newFile) {
    const reader = new FileReader();
    reader.onloadend = (() => {
      // file is loaded
      const puiFile = new PUIFile(`id_${Date.now()}`, newFile.name, newFile.type, reader.result, newFile);
      this.addFilePreview(puiFile);
    });

    reader.readAsDataURL(newFile);
  }

  /**
   * Handling logic when user selected a photo or take photo via webcam.
   * This method will also perform validation and render error elements or callbacks.
   * Validation performed: maximumFileSize, allowedContentTypes, maximumAllowedFiles.
   * @param file File
   */
  _handleFileInputOnChange(file) {
    if (file) {
      this._renderPreview(file);
    }
  }

  /**
   * onError callback from PUIFileUploadButtonContainer
   * @param file
   * @param errorCode
   * @private
   */
  _onErrorCallback(errorObject) {
    const { file, errorCode } = errorObject;
    const {
      errorMaxFileSizeExceededTitle,
      errorMaxFileSizeExceededDesc,
      errorContentTypeNotAllowedTitle,
      errorContentTypeNotAllowedDesc,
      errorMaxAllowedFilesExceededTitle,
      errorMaxAllowedFilesExceededDesc,
    } = this.configStrings;

    if (errorCode === ERROR_CODES.MAXIMUM_FILE_SIZE_EXCEEDED) {
      // TODO: Migrate to use built-in validator or use error callback.
      this._show(this._errorMaximumFileSizeElement);
      if (errorMaxFileSizeExceededTitle && errorMaxFileSizeExceededDesc) {
        this.showErrorBanner(errorMaxFileSizeExceededTitle, errorMaxFileSizeExceededDesc);
      }
    } else if (errorCode === ERROR_CODES.CONTENT_TYPE_NOT_ALLOWED && errorContentTypeNotAllowedTitle && errorContentTypeNotAllowedDesc) {
      this.showErrorBanner(errorContentTypeNotAllowedTitle, errorContentTypeNotAllowedDesc);
    } else if (errorCode === ERROR_CODES.MAXIMUM_ALLOWED_FILES_EXCEEDED && errorMaxAllowedFilesExceededTitle && errorMaxAllowedFilesExceededDesc) {
      this.showErrorBanner(errorMaxAllowedFilesExceededTitle, errorMaxAllowedFilesExceededDesc);
    }

    this._setInputFileList();

    if (this.onValidationError) {
      this.onValidationError({ file, errorCode });
    }
  }

  /**
   * Callback from pui file preview container.
   * @param puiFile PUIFile
   */
  _addNewFileCallback(puiFile) {
    this._resetErrorBanner();
    this._setInputFileList();
    if (this.onPreviewAdded) {
      this.onPreviewAdded({
        file: puiFile,
      });
    }
  }

  /**
   * Using DataTransfer to alter immutable file list, see https://github.com/whatwg/html/issues/3269
   * Necessary to use file list in input vs form data, since SIEGE encryption library only accepts HTML element
   * https://code.amazon.com/packages/SiegeCryptoJavaScript/blobs/0c0b031569549a0f837660456d6131f77107c81a/--/src/lib/siegeCrypto.ts#L29
   */
  _setInputFileList() {
    this._files = Array.from(this._previewContainer.filesAddedMap.values());
    const dataTransfer = new DataTransfer();
    this._files.forEach((puiFile) => {
      if (puiFile.fileObject) {
        dataTransfer.items.add(puiFile.fileObject);
      }
    });

    this._innerFileInput.files = dataTransfer.files;
  }

  _show(element) {
    return element instanceof Element && element.classList.remove(this._hiddenClass);
  }

  _dismissHandlerOnClick(puiFile) {
    this._resetErrorBanner();
    this._setInputFileList();
    this._innerFileInput.dispatchEvent(new CustomEvent('dismissEvent', {
      bubbles: true,
    }));

    if (this.onPreviewRemoved) {
      this.onPreviewRemoved({
        file: puiFile,
      });
    }
  }

  /**
   * Show error banner. Can be called by client.
   * @param title
   * @param description
   */
  showErrorBanner(title, description) {
    this._resetErrorBanner();
    this._errorBannerElement.innerHTML = `<pui-banner status="error" spacingTop="medium">
            <pui-text input="${title}" fontWeight="bold" textColor="red" spacingBottom="small"></pui-text>
            <pui-text input="${description}"></pui-text>
            </pui-banner>`;
  }

  _resetErrorBanner() {
    this._errorBannerElement.innerHTML = '';
  }
}

window.customElements.define('pui-file-upload-two', PUIFileUploadTwo);
