import getLinkColor from '../attributeValues/linkColor';
import getTextSize from '../attributeValues/textSize';
import includes from '../functions/includes';

/* eslint-disable import/no-unresolved */
import { injectPUIStyles } from '../functions/domUtils';
import PUIBottomSheet from './pui-bottom-sheet';
/* eslint-enable import/no-unresolved */

export default class PUIFullSheet extends PUIBottomSheet {
  constructor() {
    super();
    this.defaultClass = 'pui-full-sheet';
  }

  static get observedAttributes() {
    return [...super.observedAttributes];
  }

  _onCloseClick(event) {
    const closeButton = this.shadowRoot.querySelector('#pui-bottom-sheet-close-button');
    const scrim = this.shadowRoot.querySelector('#pui-full-sheet-container');
    const fullSheetModal = this.shadowRoot.querySelector('#pui-full-sheet-modal');
    if (closeButton.contains(event.target) || (scrim.contains(event.target) && !fullSheetModal.contains(event.target))) {
      this.modalEntered = false;
      this.hide();
      this.focus();
      this._triggerCloseCallback(event);
    }
  }

  render() {
    let {
      textSize, 
      linkColor,
      spacingTop,
      hideLink,
      emphasizeLink,
      text,
      minHeight,
      isMShop,
      icon,
      iconWidth,
      iconHeight,
      iconHover,
    } = this;

    // XSS sanitization
    textSize = this.encodedValue(textSize);
    linkColor = this.encodedValue(linkColor);
    spacingTop = this.encodedValue(spacingTop);
    text = this.encodedValue(text);
    minHeight = this.encodedValue(minHeight);
    icon = this.encodedValue(icon);
    iconWidth = this.encodedValue(iconWidth);
    iconHeight = this.encodedValue(iconHeight);

    if (!includes(getTextSize(), this.textSize)) {
      textSize = PUIBottomSheet.DEFAULT_TEXT_SIZE;
    }
    textSize = `${textSize}-font`;

    if (includes(getLinkColor(), this.linkColor)) {
      linkColor = `full-sheet-link-${linkColor}`;
    }

    if (hideLink) {
      this.classList.add('pui-block');
    } else {
      this.classList.add('pui-inline-block');
    }

    const linkIcon = iconHover ? `
      <div class="full-sheet-link-hover-target">
        <pui-icon imgClass="${icon}" width="${iconWidth}" height="${iconHeight}">
        </pui-icon>
      </div>
    ` : `<pui-icon imgClass="${icon}" width="${iconWidth}" height="${iconHeight}">
    </pui-icon>`;

    this.attachShadow({ mode: 'open' });
    this.shadowRoot.innerHTML = `
      <div>
        <div role="button" id="pui-bottom-sheet-link" class="full-sheet-link ${emphasizeLink ? 'full-sheet-link-emphasized' : ''}
        ${linkColor} ${spacingTop} ${hideLink ? 'pui-hidden' : ''} ${textSize}">
          ${icon ? linkIcon : text}
        </div>
        <div id="pui-full-sheet-container" class="full-sheet-container collapsed"
          role="dialog" aria-labelledby="full-sheet-modal-content" aria-modal="true">
          <div id="pui-full-sheet-modal" style="min-height: ${minHeight};"
            class="full-sheet-modal ${isMShop ? 'full-sheet-modal-mshop' : ''} full-sheet-modal-disable-scrollbar">
            <div class="full-sheet-modal-content full-sheet-modal-content-disable-scrollbar">
              <div id="pui-full-sheet-header" class="full-sheet-header">
                <div id="pui-full-sheet-header-content" class="full-sheet-header-content">
                  <slot name="full-sheet-header-content">
                  </slot>
                  <button id="pui-bottom-sheet-close-button" class="full-sheet-header-close-button">
                    <pui-icon imgclass="close-x-icon"></pui-icon>
                  </button>
                </div>
              </div>
              <div id="pui-full-sheet-inner-elements" class="full-sheet-inner-elements">
                <slot>
                  Bottom Sheet Inner Elements
                </slot>
              </div>
            </div>
          </div>
        </div>
      </div>
    `;
    injectPUIStyles(this.shadowRoot);
    this.setupRender();
    const scrim = this.shadowRoot.querySelector('#pui-full-sheet-container');
    scrim.addEventListener('click', this._onCloseClick.bind(this));
  }
}

window.customElements.define('pui-full-sheet', PUIFullSheet);
