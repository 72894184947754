import * as shadyCss from '@webcomponents/shadycss';
import PUIBase from './pui-base';

// eslint-disable-next-line import/no-unresolved
import listCSS from '../../css/_list.scss?inline';

const innerTemplate = `
  <style>
    ${listCSS}
  </style>
  <ul class="pui-list">
    <slot></slot>
  </ul>
`;

const template = document.createElement('template');

template.innerHTML = innerTemplate;

shadyCss.prepareTemplate(template, 'pui-list');

class PUIList extends PUIBase {
  constructor() {
    super();

    shadyCss.styleElement(this);
    if (!this.shadowRoot) {
      this.attachShadow({ mode: 'open' });
      this.shadowRoot.appendChild(
        document.importNode(template.content, true),
      );
    }
  }

  connectedCallback() {
    super.connectedCallback();
    this.setAttribute('role', 'list');
  }
}

window.customElements.define('pui-list', PUIList);
