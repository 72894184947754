import PUIBase from '../pui-base';
import Request from '../../networking/request';

/**
 * This class is responsible for displaying a input for choosing a zipcode.
 * If a valid zipcode is inputted, this component will make a call to get the
 * latitude and longitude for the zipcode.
 *
 * Callbacks:
 *
 * Callback for when the 'Cancel' button is pressed
 * @callback onCancel
 *
 * Callback for when the 'Apply' button is pressed
 * @callback onApply
 *
 * Configuration:
 *
 * @param {ZipUpdaterConfig} this.config - The configuration for the PUIZipUpdater
 * @param {string} this.config.id - The id for this component
 * @param {string} this.config.initialZip - The initial zip code
 * @param {string} this.config.getCoordinatesUrl - The url used for getting the coordinates for a zipcode
 * @param {string} this.config.strings.zipPrompt - The prompt string for this zip updater
 * @param {string} this.config.strings.zipInputTitle - The title string for the zip input
 * @param {string} this.config.strings.zipInputPlaceholder - The placeholder for the zip input
 * @param {string} this.config.strings.invalidZipCodeMessage - The error text for an invalid zip code
 * @param {string} this.config.strings.cancelButton - The string for the cancel button
 * @param {string} this.config.strings.applyButton - The string for the apply button
 * @param {onCancel} this.config.callbacks.onCancel - The callback for the cancel action
 * @param {onApply} this.config.callbacks.onApply - The callback for the apply action
 *
 */

export default class PUIZipUpdater extends PUIBase {
  connectedCallback() {
    super.connectedCallback();
    this.upgradeProperty('config');
    this._render();
  }

  /**
   * Returns the coordinates the last inputted zipcode or
   * the initial zipcode
   *
   * @param {Coordinates} coordinates - The coordiantes for the inputted zipcode
   * @param {latitude} coordinates.latitude
   * @param {longitude} coordinates.longitude
   */
  getCoordinates() {
    return this.coordinates;
  }

  _render() {
    const {
      strings,
    } = this.config;

    this._getCoordinates(this.config.initialZip);
    this.setAttribute('id', this.config.id);
    this.classList.add('pui-block');
    this.innerHTML = `
      <pui-heading input="${strings.zipPrompt}" textSize="medium"
                   spacingBottom="medium"></pui-heading>
      <pui-input class="zip-input" allowedCharacters="numeric" maxLength="5" label="${strings.zipInputTitle}"
                 textInputType="tel" placeholder="${strings.zipInputPlaceholder}"></pui-input>
      <pui-section flowDirection="horizontal" spacingTop="small">
        <pui-section-column flexGrid="48">
          <pui-button class="cancel-button" label="${strings.cancelButton}" theme="secondary"></pui-button>
        </pui-section-column>
        <pui-section-column flexGrid="4">
        </pui-section-column>
        <pui-section-column flexGrid="48">
          <pui-button class="apply-button" label="${strings.applyButton}"></pui-button>
        </pui-section-column>
      </pui-section>
    `;
    this._zipInput = this.querySelector('.zip-input');
    this._cancelButton = this.querySelector('.cancel-button');
    this._applyButton = this.querySelector('.apply-button');

    this._cancelButton.addEventListener('click', this._cancelPress.bind(this));
    this._applyButton.addEventListener('click', this._applyPress.bind(this));
  }

  _getCoordinates(searchZip) {
    return new Promise((resolve, reject) => {
      Request.get(this.config.getCoordinatesUrl, { searchZip }).then((response) => {
        if (response && response.latitude && response.longitude) {
          this.coordinates = response;
          resolve();
        } else {
          reject();
        }
      });
    });
  }

  _cancelPress() {
    const { onCancel } = this.config.callbacks;
    if (onCancel) {
      onCancel();
    }
  }

  _applyPress() {
    const zipInputValue = this._zipInput.getValue();
    if (zipInputValue.length < 5) {
      this._zipInput.showError(this.config.strings.invalidZipCodeMessage);
    } else {
      this._applyButton.displaySpinner();
      this._zipInput.hideError();
      this._getCoordinates(zipInputValue).then(() => {
        const { onApply } = this.config.callbacks;
        if (onApply) {
          onApply(zipInputValue);
        }
        this._zipInput.clearInput();
        this._applyButton.hideSpinner();
      }).catch(() => {
        this._applyButton.hideSpinner();
        this._zipInput.showError(this.config.strings.invalidZipCodeMessage);
      });
    }
  }
}

window.customElements.define('pui-zip-updater', PUIZipUpdater);
