import PUIBase from './pui-base';
import keyCodes from '../constants/keyCodes';


class PUIPersonPicker extends PUIBase {
  constructor() {
    super();
    this.defaultSpacingTop = 'medium';
  }

  connectedCallback() {
    super.connectedCallback();
    this.render();
  }

  render() {
    // TODO: Refactor using shadowDOM
    const innerElements = this.innerHTML;
    this.innerHTML = '';

    this.classList.add('pui-inline-block');
    this._innerPersonPicker = document.createElement('span');
    this._innerPersonPicker.innerHTML = `
        <div class="pui-person-picker-container">
            ${innerElements}
        </div>
    `;
    this.appendChild(this._innerPersonPicker);

    this._innerPersonPicker.onclick = (event) => {
      this.personPickerClicked(event);
    };
    this._innerPersonPicker.onkeyup = (event ) => {
      this.personPickerPressed(event);
    }
  }
  
  personPickerPressed(event) {
    if (event.keyCode === keyCodes.ENTER_KEYCODE) {
      this.personPickerClicked(event);
    }
  }
  
  personPickerClicked(event) {
    const personPickerElements = this.getElementsByTagName('pui-person-picker-element');
    let elementClickedIndex = -1;
    let currentFocusedIndex = -1;
    for (let i = 0; i < personPickerElements.length; i += 1) {
      const element = personPickerElements[i];
      if (element.contains(event.target)) {
        elementClickedIndex = i;
      }
      if (element.lastChild.classList.contains('selected-person')) {
        currentFocusedIndex = i;
      }
    }
    if (elementClickedIndex >= 0 && elementClickedIndex !== currentFocusedIndex) {
      personPickerElements[elementClickedIndex].lastChild.classList.add('selected-person');
      personPickerElements[currentFocusedIndex].lastChild.classList.remove('selected-person');
      const tabSwitchEvent = new CustomEvent('tabSwitch', {
        bubbles: true,
        composed: true,
        detail: { clickIndex: elementClickedIndex },
      });
      this.dispatchEvent(tabSwitchEvent);
    }
  }
}

customElements.define('pui-person-picker', PUIPersonPicker);
