import PUIBase from './pui-base';


class PUIProgressBar extends PUIBase {
  constructor() {
    super();
    this.defaultClass = 'pui-progress-bar';
    this.defaultProgressColor = 'white';
    this.defaultBackgroundColor = 'translucent';
    this.defaultThickness = 'thin';
  }

  connectedCallback() {
    super.connectedCallback();
    this.render();
  }

  attributeChangedCallback() {
    this.render();
  }

  static get observedAttributes() {
    return ['totalsteps', 'currentstep', 'progress', 'progressColor', 'backgroundColor', 'thickness', 'squared'];
  }

  get totalSteps() {
    return this.getAttribute('totalSteps') || '';
  }

  set totalSteps(value) {
    this.setAttribute('totalSteps', value);
  }

  get currentStep() {
    return this.getAttribute('currentStep') || '';
  }

  set currentStep(value) {
    this.setAttribute('currentStep', value);
  }

  get progressColor() {
    return this.getAttribute('progressColor') || '';
  }

  set progressColor(value) {
    this.setAttribute('progressColor', value);
  }

  get backgroundColor() {
    return this.getAttribute('backgroundColor') || '';
  }

  set backgroundColor(value) {
    this.setAttribute('backgroundColor', value);
  }

  get thickness() {
    return this.getAttribute('thickness') || '';
  }

  set thickness(value) {
    this.setAttribute('thickness', value);
  }

  get squared() {
    return this.getBooleanAttribute('squared');
  }

  set squared(value) {
    this.setBooleanAttribute('squared', value);
  }

  calculateProgress() {
    return Math.round((this.currentStep / this.totalSteps) * 100);
  }

  render() {
    this.innerHTML = '';
    this.classList.add('fullWidth');
    this.classList.add('pui-inline-block');

    this._innerBar = document.createElement('div');
    this._innerBar.classList.add(this.defaultClass);

    const {
      progressColor,
      backgroundColor,
      thickness,
      squared,
    } = this;

    const resolvedBackgroundColor = backgroundColor || this.defaultBackgroundColor;
    this._innerBar.classList.add(`${this.defaultClass}-${resolvedBackgroundColor}-color`);

    const resolvedThickness = thickness || this.defaultThickness;
    this._innerBar.classList.add(`${this.defaultClass}-${resolvedThickness}`);

    const progressBar = document.createElement('div');
    const progress = this.calculateProgress();

    const progressBarClass = 'current-progress';
    progressBar.classList.add(`${progressBarClass}-${progress}`);

    const resolvedProgressColor = progressColor || this.defaultProgressColor;
    progressBar.classList.add(`${progressBarClass}-${resolvedProgressColor}-color`);

    progressBar.classList.add(`${progressBarClass}-${resolvedThickness}`);

    if (!squared) {
      progressBar.classList.add(`${progressBarClass}-rounded`);
    }

    this._innerBar.appendChild(progressBar);
    this.appendChild(this._innerBar);
  }
}

window.customElements.define('pui-progress-bar', PUIProgressBar);
