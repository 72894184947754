import PUIBase from './pui-base';

export default class PUIStep extends PUIBase {
  connectedCallback() {
    super.connectedCallback();
    this._render();
  }

  get stepNumber() {
    return this.getAttribute('stepNumber') || '';
  }

  set stepNumber(value) {
    this.setAttribute('stepNumber', value);
  }

  _render() {
    const {
      stepNumber,
    } = this;
    this.classList.add('pui-block');
    this.innerHTML = `
      <div class="pui-step-container">
        <pui-text textSize="extra-large" textColor="white" input="${stepNumber}">
        </pui-text>
      </div>
    `;
  }
}

window.customElements.define('pui-step', PUIStep);
