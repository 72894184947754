import PUIBase from './pui-base';

const BOX_TYPES = ['pui-2_0'];

class PUIBox extends PUIBase {
  constructor() {
    super();
    this.defaultClass = 'pui-box';
    this.attachShadow({
      mode: 'open',
    });
    this.shadowRoot.innerHTML = `
      <slot>
        Inner Elements
      </slot>
    `;
  }

  connectedCallback() {
    super.connectedCallback();
    this.render();
  }

  updateTheme(theme) {
    if (this.currentTheme) {
      this.classList.remove(`pui-box-${this.currentTheme}`);
    }
    this.classList.add(`pui-box-${theme}`);
    this.currentTheme = theme;
  }

  static get observedAttributes() {
    return ['theme', 'selected', 'boxtype'];
  }

  get theme() {
    return this.getAttribute('theme') || '';
  }

  set theme(value) {
    this.setAttribute('theme', value);
  }

  get selected() {
    return this.getBooleanAttribute('selected');
  }

  set selected(value) {
    this.setBooleanAttribute('selected', value, this._innerInput);
  }

  get boxType() {
    return this.getAttribute('boxtype') || '';
  }

  set boxType(value) {
    this.setAttribute('boxtype', value);
  }

  _isVersion2() {
    return (this.boxType && this.boxType === BOX_TYPES[0]);
  }

  render() {
    this.classList.add('pui-block');

    const {
      theme,
      selected,
    } = this;

    let { defaultClass } = this;

    if (this._isVersion2()) {
      defaultClass = 'pui-box-2_0';
    }

    this.classList.add(defaultClass);

    if (theme) {
      this.updateTheme(theme);
    }
    if (selected) {
      this.classList.add('pui-box-selected');
    }
  }
}

window.customElements.define('pui-box', PUIBox);
