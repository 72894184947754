import PUIBase from './pui-base';
import getStackDirectionList from '../attributeValues/stackDirection';
import includes from '../functions/includes';

// eslint-disable-next-line import/no-unresolved
import listCSS from '../../css/_stack.scss?inline';

/**
 * Create a vertical or horizontal stack of components
 * where each component added to the stack may include a right-chevron.
 *
 * Set the direction with the direction attribute as either "horizontal", or "vertical".
 * By default, it is vertical.
 *
 * A vertical stack will root items at the top and then horizontally stretch them to fill.
 * A horizontal stack will evenly distribute items horizontally and then vertically stretch them to fill.
 *
 * A stack is composed of a sequence of pui-stack-item, and pui-stack-item-button.
 */
class PUIStack extends PUIBase {
  /**
   * Specifies the direction how stack items will be organized. It can be either "horizontal", or "vertical". By default, it is vertical.
   * @type {"horizontal"|"vertical"}
   * @attr
   */
  get direction() {
    return this.getAttribute('direction') || 'vertical';
  }

  set direction(value) {
    if (includes(getStackDirectionList(), value)) {
      this.setAttribute('direction', value);
    } else {
      throw Error(`Invalid direction value for pui-stack: ${value}`);
    }
  }

  /**
   * Specifies how the main axis will be arranged. By default, for horizontal, it will be "space-between", and, for vertical, it will be "flex-start".
   * @type {"start"|"center"|"end"|"space-around"|"space-between"}
   * @attr
   */
  get mainAxisArrangement() {
    if (this.getAttribute('mainAxisArrangement')) {
      return this.getAttribute('mainAxisArrangement');
    }
    return this.defaultMainAxisArrangement[this.direction];
  }

  set mainAxisArrangement(value) {
    this.setAttribute('mainAxisArrangement', value);
  }

  /**
   * Specifies how the secondary axis will be arranged. By default, for horizontal, it will be "center", and, for vertical, it will be "stretch".
   * @type {"start"|"center"|"end"|"stretch"|"baseline"}
   * @attr
   */
  get secondaryAxisArrangement() {
    if (this.getAttribute('secondaryAxisArrangement')) {
      return this.getAttribute('secondaryAxisArrangement');
    }
    return this.defaultSecondaryAxisArrangement[this.direction];
  }

  set secondaryAxisArrangement(value) {
    this.setAttribute('secondaryAxisArrangement', value);
  }

  constructor() {
    super();

    this.defaultMainAxisArrangement = {
      horizontal: 'space-between',
      vertical: 'flex-start',
    };

    this.defaultSecondaryAxisArrangement = {
      horizontal: 'center',
      vertical: 'stretch',
    };
  }

  connectedCallback() {
    super.connectedCallback();
    this._setupComponent();
  }

  _setupComponent() {
    this.setAttribute('role', 'list');

    const templateHTML = `
      <style>
        ${listCSS}
      </style>
      <pui-section
        class="pui-stack"
        flowDirection="${this.direction}" 
        mainAxisArrangement="${this.mainAxisArrangement}" 
        secondaryAxisArrangement="${this.secondaryAxisArrangement}">
        <slot></slot>
      </pui-section>
    `;
    const template = document.createElement('template');
    template.innerHTML = templateHTML;
    const shadowRoot = this.attachShadow({ mode: 'open' });
    shadowRoot.appendChild(template.content.cloneNode(true));
  }
}

window.customElements.define('pui-stack', PUIStack);
