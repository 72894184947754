import PUIBase from '../pui-base';

/**
 * This component is responsible for allowing a user to search for an allergy
 *
 * Configuration:
 *
 * @param {PUIAllergySearchConfig} this.config - The configuration for PUIAllergySearch
 * @param {string} this.config.searchUrl - The url used for searching allergies
 * @param {string} this.config.searchRequestMethod - The request method type for search request, can be 'GET' or 'POST', default is 'POST'
 * @param {string} this.config.strings.searchInputTitle - The title text used for this allergy search input
 * @param {string} this.config.strings.searchInputPlaceholder - The placeholder text for this allergy search input
 * @param {string} this.config.strings.noResultsFound - The text that shows when no results are found
 * @param {string} this.config.strings.addManuallyText - The text that shows when no results are found
 * @param {onAllergySelect} this.config.callbacks.onAllergySelect - This callback gets called when a user selects
 *                                                                  an allergy from the search results
 * @param {onAllergyManualAdd} this.config.callbacks.onAllergyManualAdd - This callback gets called when a user selects
 *                                                                        add manually
 */
export default class PUIAllergySearch extends PUIBase {
  connectedCallback() {
    super.connectedCallback();
    this.upgradeProperty('config');
    this._render();
  }

  _render() {
    const {
      strings,
      searchRequestMethod
    } = this.config;
    this.classList.add('pui-block');

    if (!strings.searchInputPlaceholder) {
      strings.searchInputPlaceholder = '';
    }

    this.innerHTML = `
      <pui-search-input id="apex-allergies-search-input"
                        label="${strings.searchInputTitle}"
                        placeholder="${strings.searchInputPlaceholder}"
                        noResultsStyle="manual-add"
                        noResultsText="${strings.noResultsFound}"
                        addManuallyText="${strings.addManuallyText}"
                        searchRequestMethod="${searchRequestMethod || ''}"
                        style="margin-bottom: 8px">
      </pui-search-input>
    `;

    this._searchInput = this.querySelector('#apex-allergies-search-input');

    this._searchInput.queryUrlBuilder = this._queryUrlBuilder.bind(this);
    this._searchInput.querySuccessCb = this._querySuccessCb.bind(this);
    this._searchInput.onSearchResultClick = this._onSearchResultClick.bind(this);
    this._searchInput.onManualAdd = this._onManualAdd.bind(this);
  }

  _queryUrlBuilder(dataSearchUrl, allergySearchInputValue) {
    return `${this.config.searchUrl}?query=${allergySearchInputValue}`;
  }

  _querySuccessCb(response) {
    const allergies = response;
    const searchResults = [];
    allergies.forEach((allergy) => {
      const searchResult = {
        html: allergy.friendly,
        id: allergy.id,
      };
      searchResults.push(searchResult);
    });
    this._searchInput.updateSearchResults(searchResults);
  }

  _onSearchResultClick(allergy) {
    const { onAllergySelect } = this.config.callbacks;
    if (onAllergySelect) {
      onAllergySelect(allergy);
    }
  }

  _onManualAdd(allergyLabel) {
    const { onAllergyManualAdd } = this.config.callbacks;
    if (onAllergyManualAdd) {
      onAllergyManualAdd(allergyLabel);
    }
  }
}

window.customElements.define('pui-allergy-search', PUIAllergySearch);
