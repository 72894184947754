import * as shadyCss from '@webcomponents/shadycss';
import PUIBase from './pui-base';

const defaultClass = 'pui-carousel-swipe-element';

const innerTemplate = `
  <style>
    :host {
      display: flex;
    }
  </style>
  <slot> Inner Elements </slot>
`;

const template = document.createElement('template');

template.innerHTML = innerTemplate;

shadyCss.prepareTemplate(template, 'pui-carousel-swipe-element');

export class PUICarouselSwipeElement extends PUIBase {
  constructor() {
    super();

    shadyCss.styleElement(this);
    if (!this.shadowRoot) {
      this.attachShadow({ mode: 'open' });
      this.shadowRoot.appendChild(
        document.importNode(template.content, true),
      );
    }
  }

  static get observedAttributes() { return ['minWidth', 'border']; }

  connectedCallback() {
    super.connectedCallback();
    this.render();
  
    if (this.minWidth) {
      this.addToAttribute('style', `min-width: ${this.minWidth}`);
    } else {
      this.classList.add('pui-carousel-swipe-element-default-min-width');
    }
    if (this.border) {
      this.addToAttribute('style', `border: ${this.border}`);
    } else {
      this.classList.add('pui-carousel-swipe-element-default-border');
    }
  }

  get minWidth() {
    return this.getAttribute('minWidth');
  }

  set minWidth(value) {
    this.setAttribute('minWidth', value);
  }

  get border() {
    return this.getAttribute('border');
  }

  set border(value) {
    this.setAttribute('border', value);
  }

  render() {
    this.classList.add(defaultClass);
    this.setAttribute('id', 'pui-carousel-swipe-element');
  }
}

window.customElements.define('pui-carousel-swipe-element', PUICarouselSwipeElement);