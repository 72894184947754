import PUIPage from '../pui-page';
import PUIPharmacySearch from './pui-pharmacy-search';
import PUIPharmacyItem from './pui-pharmacy-item';
import PUIZipUpdater from '../zip/pui-zip-updater';
import Request from '../../networking/request';

/**
 * In the signup experience, this page allows a user to search for and select their most recent pharmacy
 * In dashboard, this page allows a user to edit their list of pharmacy that they are currently seeing
 *
 * Configuration:
 *
 * @param {PharmacyPageConfig} this.config - This configuration for PUIPharmacyPage
 * @param {string} this.config.pageType - Either 'signup' or 'dashboard'
 * @param {string} this.config.backUrl - The url used for going to the previous page (dashboard only)
 * @param {string} this.config.actionUrl - The url for saving the primary pharmacy
 * @param {string} this.config.nextUrl - The url for the page to transition to after saving their pharmacy
 * @param {string} this.config.searchUrl - The url used for searching pharmacy
 * @param {string} this.config.getPrimaryPharmacyUrl - The url used to get your primary pharmacy (signup only)
 * @param {string} this.config.getPharmaciesUrl - The url to get all of your pharmacy (dashboard only)
 * @param {string} this.config.searchQueryDelay - Specify the delay in millliseconds before initiating a search query
 * @param {string} this.config.getCoordinatesUrl - The url used for getting the coordinates for a zipcode
 * @param {string} this.config.iconClass - The class for the image icon used for this page
 * @param {string} this.config.initialZip - The initial zip code used for the pharmacy search
 * @param {string} this.config.contactUsUrl - The url for the help page
 * @param {string} this.config.nextUrl - url path for next page
 * @param {string} this.config.strings.pageTitle - The title text for the pharmacy page
 * @param {string} this.config.strings.pageDescription - The description text for the pharmacy page (signup only)
 * @param {string} this.config.strings.pagePrompt - The prompt to add a new pharmacy (dashboard only)
 * @param {string} this.config.strings.listTitle - The title before the list of the pharmacy (dashboard only)
 * @param {string} this.config.strings.searchZip - The text indicating which zipcode we are using for the search
 * @param {string} this.config.strings.zipChangeLink - The link text for changing the search zip
 * @param {string} this.config.strings.continueButton - The string for the saving the selected / list of pharmacy(s)
 * @param {string} this.config.strings.zipUpdater.zipPrompt - The prompt string for this zip updater
 * @param {string} this.config.strings.zipUpdater.zipInputTitle - The title string for the zip input
 * @param {string} this.config.strings.zipUpdater.zipInputPlaceholder - The placeholder for the zip input
 * @param {string} this.config.strings.zipUpdater.invalidZipCodeMessage - The error text for an invalid zip code
 * @param {string} this.config.strings.zipUpdater.cancelButton - The string for the cancel button
 * @param {string} this.config.strings.zipUpdater.applyButton - The string for the apply button
 * @param {string} this.config.strings.pharmacySearch.searchInputTitle - The title text used for the search input
 * @param {string} this.config.strings.pharmacySearch.searchInputPlaceholder - The placeholder text for search input
 * @param {string} this.config.strings.pharmacySearch.noResultsFound - The text that shows when no results are found
 * @param {string} this.config.strings.pharmacySearch.changeZipText - The text that prompts you to change your zip code and try again
 * @param {string} this.config.strings.pharmacySearch.addPrescriptionLaterText - The text that prompts tyou to continue without pharmacy
 * @param {string} this.config.strings.pharmacySearch.addPharmacySubtext - The text that prompts tyou to continue without pharmacy
 * @param {string} this.config.strings.pharmacySearch.contactUsPromptText - A prompt for the user to contact us,
 *                                                                            when no pharmacy are found.
 * @param {string} this.config.strings.pharmacySearch.contactUsLinkText - The text for the contact us link
 * @param {string} this.config.strings.pharmacySearch.searchResultHelpHeader - The text for search result header
 * @param {string} this.config.strings.pharmacySearch.searchResultHelpBody - The text for search result body
 */
export default class PUIPharmacyPage extends PUIPage {
  connectedCallback() {
    super.connectedCallback();
    this._render();
  }

  _render() {
    this.classList.add('pui-block');

    const { pageType, strings } = this.config;
    if (!strings.pageDescription) {
      strings.pageDescription = '';
    }
    if (!strings.pagePrompt) {
      strings.pagePrompt = '';
    }
    if (!strings.listTitle) {
      strings.listTitle = '';
    }
    this.config.pageStyle = (pageType === 'signup') ? 'card-form' : 'standard-form';
    const content = {
      main: `
        <pui-section spacingBottom="small">
          <pui-section flowDirection="horizontal" secondaryAxisArrangement="center">
            <pui-section-column>
              <pui-icon imgClass="${this.config.iconClass}" spacingRight="small"></pui-icon>
            </pui-section-column>
            <pui-section-column>
              <pui-heading input="${strings.pageTitle}" textSize="large"></pui-heading>
            </pui-section-column>
          </pui-section>

          <pui-text input="${strings.pageDescription}"
                    spacingTop="medium" textSize="medium" hideIfEmpty></pui-text>
          <pui-text input="${strings.pagePrompt}"
                    spacingTop="medium" textSize="large" fontWeight="bold" hideIfEmpty></pui-text>
        </pui-section>

        <div>
          <pui-text input="${strings.searchZip}" inline></pui-text>
          <pui-text id="apex-zip-display-text" input="${this.config.initialZip}" inline fontWeight="bold"></pui-text>
          <pui-link id="apex-zip-update-link" text="${strings.zipChangeLink}"></pui-link>
        </div>

        <div id="apex-pharmacy-search-container" class="medium-margin-top">
        </div>

        <pui-text input="${strings.listTitle}" textSize="large" fontWeight="bold" 
                  spacingTop="medium" spacingBottom="medium" hideIfEmpty></pui-text>
        <pui-divider class="${strings.listTitle ? '' : 'pui-hidden'}"></pui-divider>
        
        <div id="apex-pharmacy-list">
        </div>

        <pui-bottom-sheet id="apex-zip-updater-bottom-sheet" hideLink>
          <div id="apex-zip-updater-container">
          </div>
        </pui-bottom-sheet>
      `,
      footerDesktop: `
        <pui-button disabled id="apex-pharmacy-continue-button-desktop" label="${strings.continueButton}">
        </pui-button>
      `,
      footerMobile: `
        <pui-button disabled id="apex-pharmacy-continue-button-mobile" label="${strings.continueButton}">
        </pui-button>
      `,
    };
    super._render(content);

    this._pharmacyList = this.querySelector('#apex-pharmacy-list');
    this._pharmacyContinueButtonDesktop = this.querySelector('#apex-pharmacy-continue-button-desktop');
    this._pharmacyContinueButtonMobile = this.querySelector('#apex-pharmacy-continue-button-mobile');
    this._zipUpdaterBottomSheet = this.querySelector('#apex-zip-updater-bottom-sheet');
    this._zipDisplayText = this.querySelector('#apex-zip-display-text');
    this._fetchPharmacies();
    this._pharmacySearch = new PUIPharmacySearch();
    this._pharmacySearch.configure({
      zipUpdaterId: 'apex-pharmacy-zip-updater',
      searchUrl: this.config.searchUrl,
      searchQueryDelay: this.config.searchQueryDelay,
      contactUsUrl: this.config.contactUsUrl,
      nextUrl: this.config.nextUrl,
      strings: this.config.strings.pharmacySearch,
      callbacks: {
        onPharmacySelect: this._onPharmacySelect.bind(this),
      },
    });
    this.querySelector('#apex-pharmacy-search-container').appendChild(this._pharmacySearch);

    this._zipUpdater = new PUIZipUpdater();
    this._zipUpdater.configure({
      id: 'apex-pharmacy-zip-updater',
      initialZip: this.config.initialZip,
      getCoordinatesUrl: this.config.getCoordinatesUrl,
      strings: this.config.strings.zipUpdater,
      callbacks: {
        onCancel: this._onZipUpdaterCancel.bind(this),
        onApply: this._onZipUpdaterApply.bind(this),
      },
    });
    this.querySelector('#apex-zip-updater-container').appendChild(this._zipUpdater);
    this.querySelector('#apex-zip-update-link').addEventListener('click', this._onZipLinkPress.bind(this));

    this._pharmacyContinueButtonDesktop.addEventListener('click', this._onPharmacyContinue.bind(this));
    this._pharmacyContinueButtonMobile.addEventListener('click', this._onPharmacyContinue.bind(this));
    this._setContinueButtonState();
  }

  _fetchPharmacies() {
    if (this.config.pageType === 'signup') {
      Request.get(this.config.getPrimaryPharmacyUrl).then((response) => {
        const { pharmacy } = response;
        if (pharmacy) {
          this._addPharmacy(pharmacy);
        }
      });
    } else {
      Request.get(this.config.getPharmaciesUrl).then((response) => {
        const { pharmacies } = response;
        pharmacies.forEach((pharmacy) => {
          this._addPharmacy(pharmacy);
        });
      });
    }
  }

  _onPharmacySelect(pharmacy) {
    this._addPharmacy(pharmacy);
  }

  _addPharmacy(pharmacy) {
    if (!this._isDuplicatePharmacy(pharmacy)) {
      const pharmacyItemConfig = {
        itemStyle: 'box',
        environment: this.config.environment ? this.config.environment : 'webapp',
        pharmacy,
        callbacks: {
          onDelete: this._onPharmacyDelete.bind(this),
        },
      };

      if (this.config.pageType === 'signup') {
        this._pharmacyList.innerHTML = '';
        pharmacyItemConfig.itemStyle = 'box';
      }

      const pharmacyItem = new PUIPharmacyItem();
      pharmacyItem.configure(pharmacyItemConfig);
      this._pharmacyList.insertBefore(pharmacyItem, this._pharmacyList.firstChild);

      this._pharmacyContinueButtonDesktop.enable();
      this._pharmacyContinueButtonMobile.enable();
    }
  }

  _getPharmacies() {
    return [...this._pharmacyList.querySelectorAll('pui-pharmacy-item')]
      .map(pharmacyItem => pharmacyItem.getPharmacy());
  }

  _isDuplicatePharmacy(pharmacy) {
    const pharmacies = this._getPharmacies();
    return pharmacies.some(pharmacyItem => pharmacyItem.spi === pharmacy.spi);
  }

  _onPharmacyDelete() {
    this._setContinueButtonState();
  }

  _onZipLinkPress() {
    this._zipUpdaterBottomSheet.show();
  }

  _onZipUpdaterCancel() {
    this._zipUpdaterBottomSheet.hide();
  }

  _onZipUpdaterApply(zip) {
    this._zipDisplayText.setText(zip);
    this._zipUpdaterBottomSheet.hide();
  }

  _onPharmacyContinue() {
    const pharmacies = this._getPharmacies();
    const pharmacy = pharmacies[0];
      if (this.config.callbacks) {
        const { onPharmacyAdd } = this.config.callbacks;
        if (onPharmacyAdd) {
          onPharmacyAdd(pharmacy);
        }
      }
  }

  _setContinueButtonState() {
    if (this._pharmacyList.querySelectorAll('pui-pharmacy-item').length > 0) {
      this._pharmacyContinueButtonDesktop.enable();
      this._pharmacyContinueButtonMobile.enable();
    } else {
      this._pharmacyContinueButtonDesktop.disable();
      this._pharmacyContinueButtonMobile.disable();
    }
  }
}

window.customElements.define('pui-pharmacy-page', PUIPharmacyPage);
