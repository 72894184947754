import PUIBase from '../pui-base';
import PUISelector from '../pui-selector';

const uuidv4 = require('uuid/v4');

/**
 * This component is responsible for allowing a user to configure a chosen medication. First, the user will select
 * among the dosageForms/packs available.  If a user selects a dosageForm, then the user will be prompted to select
 * from among the strength options available or select the "I don't know" option.  If a user selects a pack, then
 * the user will be prompted to confirm their selection on the next page.
 *
 * @callback onConfigurationComplete
 * @param {MedicationSearchResult} medicationSearchResult - The original medication that this configurator
 * ]                                                        was initialized with
 * @param {MedicationConfiguration} medicationConfiguration - The configuration for this medication
 * @param {string} medicationConfiguration.displayName - The display name for the configured med
 * @param {string} medicationConfiguration.packagingType - Either 'dosageForm' | 'pack'
 * @param {string} medicationConfiguration.dosageFormRxcui - The rxcui for the selected dosageForm (could be null)
 * @param {string} medicationConfiguration.strengthRxcui - The rxcui for the strength selected
 * @param {boolean} medicationConfiguration.strengthKnown - true if a valid strength was chosen
 * @param {string} medicationConfiguration.packRxcui - The rxcui for the seleted pack (could be null)
 *
 * Configuration:
 *
 * @param {PUIMedConfiguratorConfig} this.config - The configuration for PUIMedConfigurator
 * @param {PrescriberPageConfig} this.config.extendedConfig - The configuration for PrescriberPageConfig
 * @param {MedicationSearchResult} this.config.medication - The medication search result entry from elastic search
 * @param {boolean} this.config.displayUnknownOption - The boolean for displaying the "I don't know" option
 * @param {string} this.config.environment - Specify if this is for react environment or horizonte
 * @param {string} this.config.strings.configuratorTitle - The title for this configurator
 * @param {string} this.config.strings.packagingQuestion - The question asking which dosage form to select
 * @param {string} this.config.strings.strengthQuestion - The question asking which strength to select
 * @param {string} this.config.strings.completeConfigurationButton - The string for the configurator finish button
 * @param {string} this.config.strings.configurationCancel - The string for cancelling configurator
 * @param {onConfigurationComplete} this.config.callbacks.onConfigurationComplete - The callback for when a user has
 * finished configuring a medication
 */
export default class PUIMedConfigurator extends PUIBase {
  connectedCallback() {
    super.connectedCallback();
    this.upgradeProperty('onConfigurationComplete');
    this._render();
  }

  _render() {
    const {
      medication,
      strings,
    } = this.config;

    this.medicationConfiguration = {};
    this.medicationConfiguration.displayName = medication.displayName;
    this.classList.add('pui-block');
    this.innerHTML = `
      <div class="pui-full-page">
        <pui-section style="margin:auto">
          <pui-section flowDirection="horizontal" mainAxisArrangement="space-between" spacingBottom="medium">
            <pui-back id="configurator-back" class="pui-invisible"></pui-back>
            <pui-link id="configurator-cancel" text="${strings.configurationCancel}" paddingBottom="small"></pui-link>
          </pui-section>

          <pui-text input="${strings.configuratorTitle}" textSize="mini"></pui-text>
          <pui-heading id="configurator-med-name" input="${medication.displayName}" fontWeight="bold" textSize="large" spacingTop="small"></pui-heading>
          <pui-divider spacingTop="medium"></pui-divider>

          <pui-transition-container id="configurator-transition">
            <pui-transition-page id="packaging-selector-page">
              <pui-text input="${strings.packagingQuestion}" textSize="large" fontWeight="bold" spacingTop="medium"></pui-text>
            </pui-transition-page>
            <pui-transition-footer style="visibility: hidden">
            </pui-transition-footer>

            <pui-transition-page id="strength-selector-page">
            <pui-text input="${strings.strengthQuestion}" textSize="large" fontWeight="bold" spacingTop="medium"></pui-text>
          </pui-transition-page>
          <pui-transition-footer>
            <pui-button id="complete-configuration-button" label="${strings.completeConfigurationButton}">
            </pui-button>
          </pui-transition-footer>
          </pui-transition-container>
        <pui-section>
      </div>
    `;
    this._backButton = this.querySelector('#configurator-back');
    this._cancelButton = this.querySelector('#configurator-cancel');
    this._configuratorMedName = this.querySelector('#configurator-med-name');
    this._configuratorTransition = this.querySelector('#configurator-transition');
    this._packagingSelectorPage = this.querySelector('#packaging-selector-page');
    this._strengthSelectorPage = this.querySelector('#strength-selector-page');
    this._completeConfigurationButton = this.querySelector('#complete-configuration-button');
    this._backButton.addEventListener('click', this._backPress.bind(this));
    this._cancelButton.addEventListener('click', this._cancelPress.bind(this));
    this._completeConfigurationButton.addEventListener('click', this._onCompletePress.bind(this));

    // Setup package selector
    this._packagingSelector = new PUISelector();
    const items = [];
    if (medication.medicationResultTermType === 'BPCK') {
      this._doseFormOrPackSelected(medication);
    } else {
      if (medication.dosageForms) {
        medication.dosageForms.forEach((dosageForm) => {
          const item = {
            id: uuidv4(),
            content: `<pui-text input="${dosageForm.dosageForm}" textAlign="center"></pui-text>`,
            value: dosageForm,
          };
          item.value.type = 'dosageForm';
          items.push(item);
        });
      }
      if (medication.packs) {
        medication.packs.forEach((pack) => {
          const item = {
            id: uuidv4(),
            content: `<pui-text input="${pack.displayName}" textAlign="center"></pui-text>`,
            value: pack,
          };
          item.value.type = 'pack';
          items.push(item);
        });
      }
    }
    this._packagingSelector.onSelect = this._doseFormOrPackSelected.bind(this);
    this._packagingSelectorPage.appendChild(this._packagingSelector);
    this._packagingSelector.updateItems(items);
  }

  _backPress() {
    this._backButton.classList.add('pui-invisible');
  }

  _cancelPress() {
    this._removeSelf();
  }

  _onCompletePress() {
    const { onConfigurationComplete } = this.config.callbacks;
    if (onConfigurationComplete) {
      onConfigurationComplete(this.config.medication, this.medicationConfiguration);
    }
    this._removeSelf();
  }

  _removeSelf() {
    this.parentNode.removeChild(this);
  }

  _doseFormOrPackSelected(packaging) {
    // Remove strength selector if it already exists
    if (this._strengthSelector) {
      this._strengthSelector.parentNode.removeChild(this._strengthSelector);
    }

    const { medication } = this.config;
    // Set the packaging type: 'dosageForm' | 'pack'
    this.medicationConfiguration.packagingType = packaging.type;
    const strengthItems = [];
    if (packaging.type === 'dosageForm') {
      // Update display name and get strength items for this dosage form
      this.medicationConfiguration.displayName = `${medication.displayName}, ${packaging.dosageForm}`;
      this.medicationConfiguration.dosageFormRxcui = packaging.rxcui;
      // If there are duplicate strengths in this group, we'll show precise ingredients for the batch
      const strengthSet = new Set();
      const hasDuplicates = packaging.strengths.some(strength => strengthSet.size === strengthSet.add(strength.strength).size);
      packaging.strengths.forEach((strength) => {
        const input = hasDuplicates ? this._getLabelName(strength) : strength.strength;
        strengthItems.push({
          id: uuidv4(),
          content: `<pui-text input="${input}" textAlign="center"></pui-text>`,
          value: strength,
        });
      });

      this._sortStrengthItems(strengthItems);

      this._completeConfigurationButton.disable();
    } else {
      // Update display name and populate a single strength option for packs
      this.medicationConfiguration.displayName = packaging.displayName;
      this.medicationConfiguration.packRxcui = packaging.rxcui;
      strengthItems.push({
        id: uuidv4(),
        content: `<pui-text input="${packaging.displayName}" textAlign="center"></pui-text>`,
        selected: true,
      });
      this._completeConfigurationButton.enable();
    }

    // Create new strength selector
    this._strengthSelector = new PUISelector();
    this._strengthSelector.onSelect = this._strengthSelected.bind(this);
    this._strengthSelectorPage.appendChild(this._strengthSelector);
    this._strengthSelector.updateItems(strengthItems);
    // Update display name, show back button, and then transition to next page
    this._configuratorMedName.setText(this.medicationConfiguration.displayName);
    this._backButton.classList.remove('pui-invisible');
    this._configuratorTransition.next();
  }

  _getLabelName(strength) {
    if (strength.psn) {
      return this._toTitleCase(strength.psn);
    }
    return strength.displayName;
  }

  _toTitleCase(text) {
    return text.toLowerCase()
      .split(' ')
      .map(s => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' ');
  }

  // Get the float value of the label and sort from smallest to largest
  // Otherwise just sort alphabetically
  _sortStrengthItems(strengthItems) {
    strengthItems.sort((a, b) => {
      const aVal = parseFloat(a.label);
      const bVal = parseFloat(b.label);
      if (!isNaN(aVal) && !isNaN(bVal)) {
        return aVal - bVal;
      }
      return a.label < b.label ? -1 : 1;
    });
  }

  _strengthSelected(strength) {
    if (strength) {
      // strength will only be valid for dosageForms
      this.medicationConfiguration.strengthRxcui = strength.rxcui;
      this.medicationConfiguration.strengthKnown = !strength.dontKnow;

      if (strength.dontKnow) {
        this.medicationConfiguration.displayName = strength.displayName;
      } else {
        this.medicationConfiguration.displayName = `${this.medicationConfiguration.displayName}, ${strength.strength}`;
      }

      this._completeConfigurationButton.enable();
    }
  }
}

window.customElements.define('pui-med-configurator', PUIMedConfigurator);
