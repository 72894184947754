import PUIBase from './pui-base';

/* eslint-disable import/no-unresolved */
import transitionPageCSS from '../../css/_transition.scss?inline';
import sectionCss from '../../css/_section.scss?inline';
/* eslint-enable import/no-unresolved */

class PUITransitionFooter extends PUIBase {
  constructor() {
    super();
    this.defaultClass = 'pui-transition-footer';
  }

  connectedCallback() {
    super.connectedCallback();
    this.render();
  }


  render() {
    const shadowRoot = this.attachShadow({ mode: 'open' });
    this.classList.add(this.defaultClass);
    this.classList.add('pui-transition-hidden');
    shadowRoot.innerHTML = `
        <style>
          :host {
            display: block;
          }
          ${transitionPageCSS}
          ${sectionCss}
        </style>
        <div>
          <slot> Inner Elements </slot>
        </div>
      `;
  }
}

window.customElements.define('pui-transition-footer', PUITransitionFooter);
