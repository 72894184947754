import PUIErrorBox from "../error/pui-error-box";

/**
 * This error box will show up when there is a page level error in the insurance capture UI
 *
 */
export default class PUIInsuranceError extends PUIErrorBox {

  _render() {
    this.classList.add('pui-block');
    this.innerHTML = `
    <pui-box theme="page-error" spacingBottom="small">
      <pui-text id="error-title" input="${this.title}" fontWeight="bold" textColor="red"></pui-text>
      <pui-text id="error-description" input="${this.description}"></pui-text>
    </pui-box>
    `;
    this._errorTitle = this.querySelector('#error-title');
    this._errorDescription = this.querySelector('#error-description');
  }
}

window.customElements.define('pui-insurance-error', PUIInsuranceError);
