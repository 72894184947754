import PUIBase from './pui-base';


class PUICard extends PUIBase {
  constructor() {
    super();
    this.defaultClass = 'pui-card';
  }

  connectedCallback() {
    super.connectedCallback();
    this.render();
  }

  static get observedAttributes() {
    return ['fullWidth', 'noShadow', 'noBorder'];
  }

  /**
   * @classprop {string} fullWidth This is the class name which determines the whether or not this
   * card needs to fill the width of the viewport
   */
  get fullWidth() {
    return this.hasAttribute('fullWidth');
  }

  set fullWidth(value) {
    if (value) {
      this.setAttribute('fullWidth', '');
    } else {
      this.removeAttribute('fullWidth');
    }
  }

  get noShadow() {
    return this.getAttribute('noShadow');
  }

  set noShadow(value) {
    this.setAttribute('noShadow', value);
  }

  get noBorder() {
    return this.getAttribute('noBorder');
  }

  set noBorder(value) {
    this.setAttribute('noBorder', value);
  }

  render() {
    this.classList.add('pui-block');
    this.classList.add(this.defaultClass);
    const { 
      fullWidth,
      noShadow,
      noBorder
     } = this;
    if (fullWidth) {
      this.classList.add(fullWidth ? 'fullWidth' : 'responsiveWidth');
    }
    if (noShadow == "true") {
      this.classList.add(noShadow ? 'no-shadow' : '');
    }

    if (noBorder == "true") {
      this.classList.add(noBorder ? 'no-border' : '');
    }

    this.attachShadow({
      mode: 'open',
    });
    this.shadowRoot.innerHTML = `
        <slot>
          Inner Elements
        </slot>
    `;
  }
}

window.customElements.define('pui-card', PUICard);
