import getSpacingSizes from '../attributeValues/spacingSize';
import includes from '../functions/includes';
import 'core-js/es';
import '@webcomponents/webcomponentsjs';

/**
 *  This is the base component which provides functionality that is common to all of our components
 *
 */
export default class PUIBase extends HTMLElement {
  connectedCallback() {
    // check if there's any internal classes already set, and warn
    if (this.classList.contains("pui-hidden")) {
      console.warn(
        "⚠️ WARNING: You have set an internal CSS class on a PUI element, which " +
        "will be removed when this component renders (`pui-hidden`). Use the " +
        "`hidden` attribute instead."
      );
    }
    this.addAttributesAndClasses();
  }

  static get observedAttributes() {
    return [
      'name',
      'spacing',
      'spacingleft',
      'spacingtop',
      'spacingright',
      'spacingbottom',
      'padding',
      'paddingleft',
      'paddingtop',
      'paddingright',
      'paddingbottom'
    ]
  }

  attributeChangedCallback() {
    this.clearClasses();
    this.addAttributesAndClasses();
  }

  /**
   * Sets the config for this PUI Element
   * @param {Config} config - The configuration object
   */
  configure(config) {
    this.config = config;
  }

  /**
   *  The id for this component
   * @type {string}
   * @attr
   *
   */
  get id() {
    return this.getAttribute('id') || '';
  }

  set id(value) {
    this.setAttribute('id', value);
  }

  /**
   * The name for this component
   * @type {string}
   * @attr
   *
   */
  get name() {
    return this.getAttribute('name') || '';
  }

  set name(value) {
    this.setAttribute('name', value);
  }

  /**
   * Specifies the top spacing
   * @type {"mini"|"small"|"medium"|"med-large"|"large"|"extra-large"}
   * @attr
   */
  get spacingTop() {
    return this.getAttribute('spacingTop') || '';
  }

  set spacingTop(value) {
    this.setAttribute('spacingTop', value);
  }

  /**
   * Specifies the left spacing
   * @type {"mini"|"small"|"medium"|"med-large"|"large"|"extra-large"}
   * @attr
   */
  get spacingLeft() {
    return this.getAttribute('spacingLeft') || '';
  }

  set spacingLeft(value) {
    this.setAttribute('spacingLeft', value);
  }

  /**
   * Specifies the right spacing
   * @type {"mini"|"small"|"medium"|"med-large"|"large"|"extra-large"}
   * @attr
   */
  get spacingRight() {
    return this.getAttribute('spacingRight') || '';
  }

  set spacingRight(value) {
    this.setAttribute('spacingRight', value);
  }

  /**
   * Specifies the bottom spacing
   * @type {"mini"|"small"|"medium"|"med-large"|"large"|"extra-large"}
   * @attr
   */
  get spacingBottom() {
    return this.getAttribute('spacingBottom') || '';
  }

  set spacingBottom(value) {
    this.setAttribute('spacingBottom', value);
  }

  /**
   * Specifies spacing to be applied to all four sides of this pui component.
   * If a more specific spacing is specified (ie spacingTop, spacingRight, etc.) then that spacing attribute
   * will take precedence
   * @type {"mini"|"small"|"medium"|"med-large"|"large"|"extra-large"}
   * @attr
   */
  get spacing() {
    return this.getAttribute('spacing') || '';
  }

  set spacing(value) {
    this.setAttribute('spacing', value);
  }

  /**
   * Specifies the top padding
   * @type {"mini"|"small"|"medium"|"med-large"|"large"|"extra-large"}
   * @attr
   */
  get paddingTop() {
    return this.getAttribute('paddingTop') || '';
  }

  set paddingTop(value) {
    this.setAttribute('paddingTop', value);
  }

  /**
   * Specifies the left padding
   * @type {"mini"|"small"|"medium"|"med-large"|"large"|"extra-large"}
   * @attr
   */
  get paddingLeft() {
    return this.getAttribute('paddingLeft') || '';
  }

  set paddingLeft(value) {
    this.setAttribute('paddingLeft', value);
  }

  /**
   * Specifies the right padding
   * @type {"mini"|"small"|"medium"|"med-large"|"large"|"extra-large"}
   * @attr
   */
  get paddingRight() {
    return this.getAttribute('paddingRight') || '';
  }

  set paddingRight(value) {
    this.setAttribute('paddingRight', value);
  }

  /**
   * Specifies the bottom padding
   * @type {"mini"|"small"|"medium"|"med-large"|"large"|"extra-large"}
   * @attr
   */
  get paddingBottom() {
    return this.getAttribute('paddingBottom') || '';
  }

  set paddingBottom(value) {
    this.setAttribute('paddingBottom', value);
  }

  /**
   * Specifies padding to be applied to all four sides of this pui component.
   * If a more specific padding is specified (ie paddingTop, paddingRight, etc.) then that padding attribute
   * will take precedence
   * @type {"none"|"mini"|"small"|"medium"|"med-large"|"large"|"extra-large"}
   * @attr
   */
  get padding() {
    return this.getAttribute('padding') || '';
  }

  set padding(value) {
    this.setAttribute('padding', value);
  }

  /**
   * If present, this component will have display set to none
   * @type {boolean}
   * @attr
   */
  get hidden() {
    return this.getBooleanAttribute('hidden');
  }

  set hidden(value) {
    this.setBooleanAttribute('hidden', value);
  }

  hide() {
    this.hidden = true;
  }

  show() {
    this.hidden = false;
    this.classList.remove('pui-hidden');
  }

  toggleVisibility() {
    if (this.hidden) {
      this.show();
    } else {
      this.hide();
    }
  }

  /**
   * Boolean attributes will return true if present and false otherwise
   */
  getBooleanAttribute(attributeName) {
    return this.hasAttribute(attributeName);
  }

  /**
   * Set a boolean attribute with a JS-friendly API.
   * 
   * Note that in HTML, boolean attributes are handled as presense/absense
   * instead of by, say, setting an attribute to `myAttr="false"`. This is
   * because all attribute values are actually strings under the hood, and the
   * string 'false' is still technically 'truthy' under JS's arcane boolean-coercion
   * logic.
   * 
   * With this method, you don't have to worry about that. If the value is false,
   * this method will remove the attribute (and vice-versa if the value is true).
   * 
   * @param {string} attributeName The name of the attribute to update
   * @param {boolean} value The value to set the attribute to
   * @param {Element | undefined} innerElement Optional element to also modify
   */
  setBooleanAttribute(attributeName, value, innerElement = undefined) {
    if (value) {
      this.setAttribute(attributeName, '');
      if (innerElement) {
        innerElement.setAttribute(attributeName, '');
      }
    } else {
      this.removeAttribute(attributeName);
      if (innerElement) {
        innerElement.removeAttribute(attributeName);
      }
    }
  }

  /**
   * Specifies when running in mShop
   */
  get isMShop() {
    return this.getBooleanAttribute('isMShop');
  }

  set isMShop(value) {
    this.setBooleanAttribute('isMShop', value);
  }

  addToAttribute(attributeName, value) {
    const current = this.getAttribute(attributeName) || '';
    this.setAttribute(attributeName, `${current} ${value};`);
  }

  addClassWithDefault(attributeName, validValues, value, defaultValue) {
    if (includes(validValues, value)) {
      this.classList.add(`${value}-${attributeName}`);
    } else if (defaultValue) {
      this.classList.add(`${defaultValue}-${attributeName}`);
    }
  }

  addAttributesAndClasses() {
    const {
      spacingTop,
      spacingLeft,
      spacingRight,
      spacingBottom,
      spacing,
      paddingTop,
      paddingLeft,
      paddingRight,
      paddingBottom,
      padding,
      isMShop
    } = this;
    const validSpacingSizes = getSpacingSizes();
    this.classList.add("pui-element");
    this.addClassWithDefault('margin-top', validSpacingSizes, spacingTop, spacing);
    this.addClassWithDefault('margin-left', validSpacingSizes, spacingLeft, spacing);
    this.addClassWithDefault('margin-right', validSpacingSizes, spacingRight, spacing);
    this.addClassWithDefault('margin-bottom', validSpacingSizes, spacingBottom, spacing);
    this.addClassWithDefault('padding-top', validSpacingSizes, paddingTop, padding);
    this.addClassWithDefault('padding-left', validSpacingSizes, paddingLeft, padding);
    this.addClassWithDefault('padding-right', validSpacingSizes, paddingRight, padding);
    this.addClassWithDefault('padding-bottom', validSpacingSizes, paddingBottom, padding);
    if (isMShop) {
      this.classList.add('mshop');
    } else {
      this.classList.remove('mshop');
    }
  }

  clearClasses() {
    const validSpacingSizes = getSpacingSizes();
    let classesToClear = [];
    for (const side of ["top", "left", "right", "bottom"]) {
      for (const size of validSpacingSizes) {
        classesToClear.push(size + '-padding-' + side);
        classesToClear.push(size + '-margin-' + side);
      }
    }
    this.classList.remove(...classesToClear);
  }

  upgradeProperty(property) {
    if (this.hasOwnProperty(property)) {
      const value = this[property];
      delete this[property];
      this[property] = value;
    }
  }
}
