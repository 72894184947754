
/**
 * A Promise that resolves when something needs to be cancelled.
 * 
 * ### Example
 * 
 * This example cancels a timeout after 500ms that would have eventually
 * printed something to the console:
 * 
 *  ```ts
 *  const myToken = CancelToken.create();
 *  
 *  const myTimeout = setTimeout(() => {
 *      console.log("Hello after a while!");
 *  }, 1000);
 *  
 *  myToken.then(() => clearTimeout(myTimeout));
 *  
 *  setTimeout(() => myToken.cancel(), 500);
 *  ```
 * 
 * #### Cancelling `fetch()` requests
 * 
 * This example is a bit more involved, and shows how you can use this to cancel
 * AJAX requests that are in-flight by leveraging the DOM
 * [AbortController](https://developer.mozilla.org/en-US/docs/Web/API/AbortController),
 * which is a part of the `fetch` API.
 * 
 *  ```ts
 *  const myToken = CancelToken.create();
 *  const abortController = new AbortController(); 
 * 
 *  fetch("/myReallyExpensiveRequest", { signal: abortController.signal })
 *      .then((result) => console.log("Got a result!"));
 *  
 *  myToken.then(() => abortController.abort());
 *  ```
 * 
 * #### Cancelling XmlHttpRequests
 * 
 * XHR requests can also be cancelled with cancel tokens:
 * 
 * ```ts
 * const myToken = CancelToken.create();
 * const request = new XmlHttpRequest();
 * req.addEventListener("load", () => console.log("Got a result")));
 * req.open("GET", "/myExpensiveAPICall");
 * req.send();
 * 
 * myToken.then(() => req.abort());
 * ```
 * 
 * ### Why not just use AbortController?
 * 
 * AbortController is a DOM-only API that is tightly coupled to fetch()- XHR
 * requests use a completely different API. Further, the AbortController's signal
 * is a bit unwieldy to use directly- you have to attach an event listener to
 * the object to receive an `abort` event. With CancelTokens, they're just
 * promises- so you can just call `.then()` to hook into the cancellation signal
 * in your code.
 * 
 */
export class CancelToken extends Promise<void> {
    public static create() { 
        return new CancelToken(() => void 0);
    }
    private wasCancelled = false;

    // why a protected constructor?
    // well, to extend Promise we need to make the JS engine happy with some
    // magical constructor parameters that _must_ get executed. They do nothing,
    // except that we have to pass it along and the promise will break if we
    // don't provide them. Since we don't want consumers to provide their own,
    // protect the constructor and make cancel tokens with a static method instead.
    // for more details:
    // https://gist.github.com/domenic/8ed6048b187ee8f2ec75?permalink_comment_id=2297518#gistcomment-2297518
    protected constructor(_bsCallback: (resolve: () => void, reject: () => void) => void) {
        super((resolve, reject) => {
            _bsCallback(resolve, reject);
            Promise.resolve().then(() => {
                this._resolve = resolve;
                this._reject = () => {
                    this.wasCancelled = true;
                    return reject();
                }
   
            })
        });
    }

    public isCancelled() {
        return this.wasCancelled;
    }
    
    private _resolve?: () => void;
    private _reject?: () => void;

    /**
     * Send a signal to consumers that they should cancel anything they're
     * trying to do, like issuing Ajax requests or processing data.
     */
    public cancel() {
        if (!this._reject) {
            // There's only codepath where this can be null, and that's the case
            // where this method is called synchronously after the initial
            // creation of the cancel token. The resolve/reject methods are set
            // in the promise callback in the constructor above, which will
            // execute in the next free microtask queue slot. By placing this
            // in a promise.resolve, we can ensure this executes right after
            // that callback does.
            Promise.resolve().then(() => this._reject!());
        } else {
            this._reject();
        }
    }

    public onCancelled(callback: () => void | Promise<void>) {
        return this.catch(callback);
    }

    /**
     * Send a signal to consumers that they don't need to keep a reference to
     * this cancel token anymore, and that the underlying action has completed.
     */
    public resolve() {
        // see reject() for why this Promise.resolve exists
        if (!this._resolve) {
            Promise.resolve().then(() => this._resolve!());
        } else {
            this._resolve();
        }
    }
}
