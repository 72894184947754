import PUIPage from '../pui-page';
import PrimeRxDiscountCard from './primerx-discount-card';
import Request from '../../networking/request';

/**
 * This page is used to show a customer's discount cards and if they have 
 * none, will allow them to check if they are eligible for a PrimeRx card.
 *
 * Configuration:
 *
 * @param {InsurancesPageConfig} this.config - The configuration for this page
 * @param {string} this.config.backUrl - The url used for going to the previous page (dashboard only)
 * @param {string} this.config.getDiscountCardsUrl - The url used to get all the discount cards for a person
 * @param {string} this.config.checkForPrimeRxDiscountCardUrl - The url used to check for a PrimeRx Discount Card
 * @param {string} this.config.iconClass - The class for the image icon used for this page
 * 
 * @param {string} this.config.strings.errorTitle - The title text for the error box
 * @param {string} this.config.strings.pageTitle - The title text for the discount card page
 * @param {string} this.config.strings.pageDescriptionFetching - The description text when retrieving discount cards
 * @param {string} this.config.strings.noDiscountCardFound - The text that displays when a user has no discount card
 * @param {string} this.config.strings.noDiscountCardsFoundAfterCheck - The text that displays when a user has no discount card
 * @param {string} this.config.strings.checkForCardsButtonLabel - The text for the button that triggers the action to fetch discount cards.
 * @param {string} this.config.strings.retryButtonLabel - The text for the button that retrys to fetch discount cards in case of an error.
 * @param {string} this.config.strings.retryButtonLimitExceededLabel - The text for the retry button when the limit has been exceeded.
 */

export default class PUIDiscountCardPage extends PUIPage {
  connectedCallback() {
    super.connectedCallback();
    this._render();
  }

  showError() {
    this._pageError.show();
  }

  hideError() {
    this._pageError.hide();
  }

  _render() {
    this.classList.add('pui-block');

    const { strings } = this.config;
    
    const content = {
      main: `
        <pui-error-box id="apex-discount-card-page-error" title="${this.config.strings.errorTitle}" class="pui-hidden"></pui-error-box>
        <pui-section id="apex-discount-cards">
          
          <!-- Heading -->
          <pui-section spacingBottom="small">
            <pui-section flowDirection="horizontal" secondaryAxisArrangement="center">
              <pui-section-column>
                <pui-icon imgClass="${this.config.iconClass}" spacingRight="small"></pui-icon>
              </pui-section-column>
              <pui-section-column>
                <pui-heading id="apex-discount-card-page-title" input="${strings.pageTitle}" textSize="large" fontWeight="bold"></pui-heading>
              </pui-section-column>
            </pui-section>
            <!-- Here for style -->
            <pui-text id="apex-discount-card-page-description-fetching" input="${strings.pageDescriptionFetching}" spacingTop="small" textSize="medium"></pui-text>
            <pui-text id="apex-discount-card-page-none-found-after-check" input="${strings.noDiscountCardsFoundAfterCheck}" spacingTop="small" textSize="medium" hidden></pui-text>
          </pui-section>

          <!-- Discount Card Loading Container -->
          <pui-section id="apex-discount-card-page-loading-container" secondaryAxisArrangement="center" spacingTop="medium" style="position:relative; height: 100px">
            <pui-loading-indicator id="apex-discount-card-page-loading-indicator"></pui-loading-indicator>
          </pui-section>

          <!-- Discount Card Content Container -->
          <pui-section id="apex-discount-card-content-container">

            <pui-section id="apex-no-discount-card-found-container" class="pui-hidden">
              <!-- No Discount Cards Found -->
              <pui-box id="apex-no-discount-card-found-box" theme="dashed">
                <pui-text input="${strings.noDiscountCardFound}" textAlign="center" spacing="medium" textColor="grey"></pui-text>
              </pui-box>
              <!-- Check for Discount Cards --> 
              <pui-button id="check-for-cards-button" spacingTop="medium" label="${strings.checkForCardsButtonLabel}"></pui-button>
            </pui-section>
            
            <pui-section id="discount-card-list-container">
              <!-- Discount Card List -->
              <pui-section id="discount-card-list" size="small" flowDirection="vertical" class="pui-hidden"></pui-section>
            </pui-section>

          </pui-section>

          <!-- Retry Button -->
          <pui-button id="retry-button" label="${strings.retryButtonLabel}" spacingTop="medium" class="pui-hidden"></pui-button>
          
        </pui-section>`,
      footerDesktop: ``,
      footerMobile: ``,
    };

    super._render(content);
    
    this._discountCardPageTitle = this.querySelector('#apex-insurace-page-title');
    this._discountCardPageDescriptionFetching = this.querySelector('#apex-discount-card-page-description-fetching');
    this._noneFoundLabel = this.querySelector('#apex-discount-card-page-none-found-after-check');
    
    // button shown to retry fetching cards on profile
    this._retryButton = this.querySelector('#retry-button');
    this._retryButton.addEventListener('click', this._retryFetch.bind(this));

    // error shown if something goes wrong
    this._pageError = this.querySelector('#apex-discount-card-page-error');

    this._topContainer = this.querySelector('#apex-discount-cards');

    // initial loading container
    this._discountCardLoadingContainer = this.querySelector('#apex-discount-card-page-loading-container');
    
    this._discountCardContentContainer = this.querySelector('#apex-discount-card-content-container');
    
    // section where discount cards will be displayed
    this._discountCardList = this.querySelector('#discount-card-list');
    
    // message and button when no discount cards are found
    this._noDiscountCardFoundContainer = this.querySelector('#apex-no-discount-card-found-container');
    this._noDiscountCardFoundBox = this.querySelector('#apex-no-discount-card-found-box');

    this._checkForDiscountCardButton = this.querySelector('#check-for-cards-button');
    this._checkForDiscountCardButton.addEventListener('click', this._checkForDiscountCard.bind(this));
    this._checkForDiscountCardButton.addEventListener('keyup', this._checkForDiscountCard.bind(this));

    // constant for the number of times a customer can retry if an error occurs on the page
    this._retryLimit = 3;

    this._fetchDiscountCards({showLoading: true});
  }

  _checkForDiscountCard() {
    this._noneFoundLabel.hide();
    this._checkForDiscountCardButton.setAttribute("disabled", "true");
    this._checkForDiscountCardButton.displaySpinner();
    setTimeout(() => {
      Request.get(this.config.checkForPrimeRxDiscountCardUrl).then((response) => {
        if (!response || response.discountCards === undefined) {
          this.showError();
          return;
        }
        if (response.error) {
          this.showError();
          return;
        }
        let discountCards = response.discountCards;
        this._displayDiscountCards(discountCards, {showNotFoundMessage : true});
      }).catch(() => {
        this.showError();
      }).finally(() => {
        this._checkForDiscountCardButton.removeAttribute("disabled");
        this._checkForDiscountCardButton.hideSpinner();
      })
    }, 70);
  }

  _retryFetch() {
    if (!this._retryCount){
      this._retryCount = 1;
    } else {
      this._retryCount += 1;
    }
    this._retryButton.setAttribute("disabled", "true");
    this._retryButton.displaySpinner();
    setTimeout(() => {
      this._fetchDiscountCards({showLoading: false}).then(() => {
        this._retryButton.hideSpinner();
        if (this._retryCount < this._retryLimit) {
          this._retryButton.removeAttribute("disabled");
        } else {
          this._retryButton.setAttribute("label", this.config.strings.retryButtonLimitExceededLabel)
        }
      });
    }, 70);
  }

  _fetchDiscountCards(cfg) {
    return new Promise((resolve) => {
      if (cfg && cfg.showLoading) {
        this._showLoading();
      }
      this.hideError();
      this._noDiscountCardFoundContainer.hide();

      // Fetch Discount Cards. Response will be an array of discount cards.
      Request.get(this.config.getDiscountCardsUrl).then((response) => {
        
        if (!response || response.discountCards === undefined) {
          this.showError();
          this._retryButton.show();
          return;
        }
        this._retryButton.hide();  
        
        let discountCards = response.discountCards;

        this._displayDiscountCards(discountCards)

        this._retryButton.hide();

      }).catch(() => {
        this.showError();
        this._retryButton.show();
      }).finally(() => {
        this._hideLoading();
        return resolve();
      });
    });
  }

  _showLoading() {
    this._discountCardPageDescriptionFetching.show();
    this._discountCardLoadingContainer.show();
  }

  _hideLoading() {
    this._discountCardLoadingContainer.hide();
    this._discountCardPageDescriptionFetching.hide();
  }

  _displayDiscountCards(discountCards, cfg) {
    if (discountCards.length == 0) {
      this._noDiscountCardFoundContainer.show();
      if (cfg && cfg.showNotFoundMessage) {
        this._noneFoundLabel.show();
      }
      return;
    }
    // clear out list of any elements
    while(this._discountCardList.firstChild) {
      this._discountCardList.removeChild(this._discountCardList.lastChild);
    }

    // using filter and shift since they're implemented in IE
    const primeRxCardData = discountCards.filter((card) => { return card.type == "primeRx" }).shift();

    // If primeRx discount card is there, add it first
    if (primeRxCardData) {
      // create and add PrimeRx card to list
      let discountCard = this._createPrimeRxCard(primeRxCardData);
      this._discountCardList.appendChild(discountCard);
      this._discountCardList.show();
      this._noDiscountCardFoundContainer.hide();
    }
    // no longer need the button
    this._checkForDiscountCardButton.hide();
  }

  _createPrimeRxCard(primeRxData) {
    const discountCard = new PrimeRxDiscountCard();
    discountCard.id = "primeRxCardId";
    discountCard.configure(primeRxData);
    return discountCard;
  }

}

window.customElements.define('pui-discount-card-page', PUIDiscountCardPage);
