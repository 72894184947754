import PUIBase from './pui-base';
import keyCodes from '../constants/keyCodes';

export default class PUIIcon extends PUIBase {
  constructor() {
    super();
  }

  connectedCallback() {
    super.connectedCallback();
    this.render();
  }

  attributeChangedCallback() {
    this.render();
  }

  static get observedAttributes() {
    return ['imgclass', 'alt', 'url'];
  }

  get imgClass() {
    return this.getAttribute('imgClass') || '';
  }

  set imgClass(value) {
    this.setAttribute('imgClass', value);
  }

  get alt() {
    return this.getAttribute('alt') || '';
  }

  set alt(value) {
    this.setAttribute('alt', value);
  }

  get url() {
    return this.getAttribute('url') || '';
  }

  set url(value) {
    this.setAttribute('url', value);
  }

  get width() {
    return this.getAttribute('width') ?? '';
  }

  set width(value: string) {
    this.setAttribute('width', value);
  }

  get height() {
    return this.getAttribute('height') ?? '';
  }

  set height(value: string) {
    this.setAttribute('height', value);
  }

  buttonPressed(event: KeyboardEvent) {
    if (event.keyCode === keyCodes.ENTER_KEYCODE) { // Pressed Enter
      this.buttonClicked();
    }
  }

  buttonClicked() {
    if (this.url) {
      window.location.href = this.url;
    }
  }

  render() {
    this.innerHTML = '';
    const {
      imgClass,
      alt,
      width,
      height,
    } = this;
    const tabableIcons = ['back-arrow-icon']; // list of "tab-able" icons

    // add custom width and height to icon if specified
    const style = width || height ? `
      ${width ? `width: ${width}` : ''};
      ${height ? `height: ${height}` : ''}
    ` : '';

    this.classList.add('pui-inline-block');
    const innerIcon = document.createElement('span');
    innerIcon.classList.add('icon-span');
    innerIcon.innerHTML = `
      <div class="pui-icon ${imgClass}" style="${style}">
      </div>
      <div class="pui-icon-alt">
        ${alt}
      </div>
    `;
    this.addEventListener('click', this.buttonClicked.bind(this));
    this.addEventListener('keyup', this.buttonPressed.bind(this));
    this.appendChild(innerIcon);
    if (tabableIcons.includes(imgClass)) {
      this.setAttribute('tabindex', '0');
    }
  }
}
window.customElements.define('pui-icon', PUIIcon);
