import { injectPUIStyles } from "../../../functions/domUtils";
import PUIBase from "../../pui-base";
import PUIBottomSheet from "../../pui-bottom-sheet";
import PUISection from "../../pui-section";

const NAV_TEMPLATE = document.createElement('template');
NAV_TEMPLATE.innerHTML = `
<pui-section class="pui-sub-nav-container" fullWidth flowDirection="horizontal" 
                    mainAxisArrangement="space-between" secondaryAxisArrangement="center">
    <pui-section fullWidth>
        <button id="links-bottomsheet-button" class="pui-sub-nav-bottom-sheet-button mobile-and-tablet-only">
            <slot name="mobile-logo">
                To insert content here, add a child element with attribute <code>slot="mobile-logo"</code>
            </slot>
        </button>
        <pui-bottom-sheet id="links-bottomsheet" hideLink>
            <slot name="mobile-links">
                To insert content here, add a child element with attribute <code>slot="mobile-links"</code>.
            </slot>
        </pui-bottom-sheet>
        <pui-section fullWidth class="desktop-only" flowDirection="horizontal" mainAxisArrangement="start"  secondaryAxisArrangement="center">
            <slot name="desktop-logo">
                To insert content here, add a child element with attribute <code>slot="desktop-logo"</code>
            </slot>
            <slot name="desktop-links">
                To insert content here, add a child element with attribute <code>slot="desktop-links"</code>.
            </slot>
        </pui-section>
    </pui-section>
    <pui-section fullWidth flowDirection="horizontal" mainAxisArrangement="end" secondaryAxisArrangement="center" spacingRight="medium">
        <slot name="nav-widgets">
            To insert content here, add a child element with attribute <code>slot="nav-widgets"</code>.
        </slot>
    </pui-section>
</pui-section>
`;

export class PUINavigationBar extends PUIBase {
    static get observedAttributes() {
        return [
            ...super.observedAttributes
        ]
    }

    /** @type {PUISection} */
    #subNavContainer;
    /** @type {PUIBottomSheet} */
    #linksBottomSheet;
    /** @type {HTMLButtonElement} */
    #linksButton;

    attributeChangedCallback() {
        this.#update();
    }

    connectedCallback() {
        super.connectedCallback();
        this.#render();
        this.#update();
    }

    #render() {
        this.attachShadow({ mode: "open" });
        // copy style nodes into the shadow dom, because there's no way to open
        // up the shadow to parent CSS styles, and we _need_ a shadow dom in
        // place for slots to work. yuck.
        // TODO: Stencil.JS apparently solves for this, might be worth exploring as part of TS migration
        injectPUIStyles(this.shadowRoot);
        // copy in the template node as well so that the slots will work
        this.shadowRoot.appendChild(
            NAV_TEMPLATE.content.cloneNode(true),
        );
        this.#subNavContainer = this.shadowRoot.querySelector('.pui-sub-nav-container');
        this.#linksBottomSheet = this.shadowRoot.querySelector('#links-bottomsheet');
        this.#linksButton = this.shadowRoot.querySelector('#links-bottomsheet-button');
        this.#installEventHooks();
    }

    #update() {
        this.#linksBottomSheet.isMShop = this.isMShop;
        this.#subNavContainer.isMShop = this.isMShop;
    }

    #installEventHooks() {
        this.#linksButton.addEventListener('click', () => this.#onClickMobileLinks());
        this.#linksButton.addEventListener('keyup', (event) => {
            if (!event.key === 'Enter') return;
            this.#onClickMobileLinks();
        });
    }

    #onClickMobileLinks() {
        this.#linksBottomSheet.show();
    }
}

window.customElements.define('pui-nav-bar', PUINavigationBar);
