import PUIBase from './pui-base';

/**
 * This is the component which creates a pizza tracker progress bar.
 * This progress bar holds the number of steps/milestones and the current step
 * and displays the current progress.
 */
class PUIPizzaTrackerBar extends PUIBase {
  constructor() {
    super();
    this.defaultClass = 'pui-pizza-tracker-bar';
  }

  connectedCallback() {
    super.connectedCallback();
    this.render();
  }

  attributeChangedCallback() {
    this.render();
  }

  static get observedAttributes() {
    return ['totalsteps', 'currentstep'];
  }

  /**
   * The totalSteps attribute holds how many steps/milestones we should render in the progress bar.
   */
  get totalSteps() {
    return this.getAttribute('totalSteps') || '';
  }

  set totalSteps(value) {
    this.setAttribute('totalSteps', value);
  }

  /**
   * The currentStep attribute holds the current step/milestone we are currently on.
   * This will be the separation point between current/complete/incomplete steps
   * and affect the coloring of the pizza tracker bar and milestones.
   *
   * The operator of this component must update this
   * attribute for progress on the bar to be reflected.
   * @type {string}
   * @attr
   */
  get currentStep() {
    return this.getAttribute('currentStep') || '';
  }

  set currentStep(value) {
    this.setAttribute('currentStep', value);
  }

  render() {
    this.innerHTML = '';
    this.classList.add('fullWidth');
    this.classList.add('pui-inline-block');
    this.classList.add('pui-pizza-tracker-bar');

    const {
      totalSteps,
      currentStep,
    } = this;

    for (let i = 1; i <= totalSteps; i++) {
      if (i != 1) {
        const divider = document.createElement('div');
        divider.classList.add('divider');

        if (i > currentStep) {
          divider.classList.add('incomplete');
        }

        this.append(divider);
      }

      const milestone = document.createElement('div');
      milestone.classList.add('milestone-item');

      if (i < currentStep) {
        milestone.classList.add('complete');
      }

      if (i > currentStep) {
        milestone.classList.add('incomplete');
      }

      this.append(milestone);
    }
  }
}

window.customElements.define('pui-pizza-tracker-bar', PUIPizzaTrackerBar);
