import PUIBase from './pui-base';
import includes from '../functions/includes';
import getTextSize from '../attributeValues/textSize';


class PUICheckCard extends PUIBase {
  constructor() {
    super();
    this.defaultClass = 'pui-check-card';
    this.defaultSize = 'small';
  }

  connectedCallback() {
    super.connectedCallback();
    this.render();
  }

  static get observedAttributes() {
    return ['value', 'label', 'disabled', 'checked', 'textSize', 'elementValue'];
  }

  get label() {
    return this.getAttribute('label') || '';
  }

  set label(value) {
    this.setAttribute('label', value);
  }

  get value() {
    return this.getAttribute('value') || '';
  }

  set value(value) {
    this.setAttribute('value', value);
  }

  get disabled() {
    return this.getBooleanAttribute('disabled');
  }

  set disabled(value) {
    this.setBooleanAttribute('disabled', value);
  }

  get checked() {
    return this.getBooleanAttribute('checked');
  }

  set checked(value) {
    this.setBooleanAttribute('checked', value, this._innerInput);
  }

  get textSize() {
    return this.getAttribute('textSize') || '';
  }

  set textSize(value) {
    this.setAttribute('textSize', value);
  }

  enable() {
    this.disabled = false;
  }

  disable() {
    this.disabled = true;
  }

  isDisabled() {
    return Boolean(this.disabled);
  }

  check() {
    this.checked = true;
  }

  uncheck() {
    this.checked = false;
  }

  toggle() {
    this.checked = !this.checked;
  }

  isChecked() {
    return Boolean(this.checked);
  }

  get elementValue() {
    return this.getAttribute('elementValue') || '';
  }

  set elementValue(value) {
    this.setAttribute('elementValue', value);
  }

  getElementValue() {
    return this.elementValue;
  }

  render() {
    const {
      name,
      value,
      disabled,
      label,
      checked,
    } = this;

    let {
      textSize,
    } = this;

    if (!includes(getTextSize(), this.textSize)) {
      textSize = this.defaultTextSize;
    }

    textSize = `${textSize}-font`;

    const shadowRoot = this.attachShadow({ mode: 'open' });
    this.classList.add(this.defaultClass);
    this.classList.add(`carousel-element-${this.flowDirection}`);
    if (disabled) {
      this.classList.add('disabled');
    }
    shadowRoot.innerHTML = `
      <style>
        :host {
          display: block;
        }
      .pui-checkbox {
        display: flex;
        align-items: center;
        position: relative;
        padding-left: 35px;
        margin-bottom: 12px;
        cursor: pointer;
        user-select: none;
        font-size: 15px;
        text-align: initial;
        display: block;
      }

      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
      }

      .pui-hidden {
        display: none !important;
      }
      </style>
      <span class="pui-checkbox">
          <input type="checkbox" class="pui-hidden" name="${name}" value="${value}" ${checked ? 'checked' : ''}>
      </span>

      <slot> Inner Elements </slot>
    `;

    this._innerInput = this.shadowRoot.children[1].firstElementChild;

    this.onclick = (event) => {
      // Do nothing. creating this empty listener propogates up to the parent.
    };
  }
}

window.customElements.define('pui-check-card', PUICheckCard);
