import PUIBase from "./pui-base";


/**
 * @element pui-button-with-initials
 * button with optional icon and label
 */
class PUIButtonWithInitials extends PUIBase {
    constructor() {
        super();
        this.defaultClass = 'pui-button-with-initials';
        this.defaultText = 'PUI Button With Initials';
    }

    connectedCallback() {
        super.connectedCallback();
        this.render();
        this.attachEventListener();
    }

    attributeChangedCallback() {
        this.render();
    }

    static get observedAttributes() {
        return [
            'label', 'form-action', 'url', 'isSelected',
            'textColor', 'disabled', 'icon', 'elementValue',
            'height'
        ];
    }

    /**
     * This is the text for the button that will be displayed to the user
     * @type {string}
     * @attr
     */
    get label() {
        return this.getAttribute('label') || '';
    }

    set label(value) {
        this.setAttribute('label', value);
    }

    /**
     * If present, background-color of the button will be $primary-color
     * @type {boolean}
     * @attr
     */
    get isSelected() {
        return this.getBooleanAttribute('isSelected');
    }

    set isSelected(value) {
        this.setBooleanAttribute('isSelected', value);
    }

    /**
     * Used to set a specific height for a button (eg. 40px). If not present,
     * the button height will be 36px as default.
     * @type {string}
     * @attr
     */
    get height() {
        return this.getAttribute('height') || '';
    }

    set height(value) {
        this.setAttribute('height', value);
    }

    /**
     * Used to set a specific width for a button (eg. 100%).
     * @type {string}
     * @attr
     */
    get width() {
        return this.getAttribute('width') || '';
    }

    set width(value) {
        this.setAttribute('width', value);
    }

    /**
     * Optional value to specify where the user should be redirected to after
     * clicking this button
     * @type {string}
     * @attr
     */
    get url() {
        return this.getAttribute('url') || '';
    }

    set url(value) {
        this.setAttribute('url', value);
    }

    /**
     * If present, will determine if this button is clickable or not
     * @type {boolean}
     * @attr
     */
    get disabled() {
        return this.getBooleanAttribute('disabled');
    }

    set disabled(value) {
        this.setBooleanAttribute('disabled', value, this._innerButton);
    }

    /**
     * Specifies the string to be displayed inside the icon
     * @type {string}
     * @attr
     */
    get icon() {
        return this.getAttribute('icon') || '';
    }

    set icon(value) {
        this.setAttribute('icon', value);
    }

    /**
     * Used to set the color for the button label
     * @type {"black-color"|"grey-color"|"white-color"|"link-color"|"red-color"}
     * @attr
     */
    get textColor() {
        return this.getAttribute('textColor') || '';
    }

    set textColor(value) {
        this.setAttribute('textColor', value);
    }

    /**
     * The action a button performs. When set to submit, button will submit form data.
     * When set to reset, the button will clear a form.
     * @type {"button"|"submit"|"reset"}
     * @attr [form-action=button]
     */
    get formAction() {
        return this.getAttribute('form-action') || 'button';
    }

    set formAction(value) {
        this.setAttribute('form-action', value);
    }

    /**
     * The value that will be used in the button press callback
     * @type {string}
     * @attr
     */
    get elementValue() {
        return this.getAttribute('elementValue') || '';
    }

    set elementValue(value) {
        this.setAttribute('elementValue', value);
    }

    buttonClicked() {
        if (this.url) {
            window.location.href = this.url;
            return;
        }
        if (this.onButtonPress && this.isEnabled()) {
            this.onButtonPress(this.elementValue);
        }
    }

    isEnabled() {
        return !this.disabled;
    }

    updateLabel(text) {
        this._innerButton.innerText = text;
    }

    enable() {
        this.disabled = false;
    }

    disable() {
        this.disabled = true;
    }

    displayLabel() {
        const button = this.querySelector('button');
        button.innerText = this.label;
    }


    render() {
        this.innerHTML = '';
        const {
            name,
            formAction,
            label,
            icon,
            isSelected,
            disabled,
            textColor,
            height,
            width
        } = this;

        this.innerHTML = `
          <button class="${this.defaultClass} ${textColor}" name=${name}>
            ${label}
          </button>
        `;

        this.style.textAlign = 'center';
        this._innerButton = this.querySelector('button');
        this._innerButton.disabled = disabled;

        if (height) {
            this._innerButton.style.height = `${height}`;
        } else {
            this._innerButton.style.height = '36px';
        }

        if (width) {
            this._innerButton.style.width = width;
        }

        if (isSelected) {
            this._innerButton.classList.add('pui-button-with-initials-selected');
        } else {
            this._innerButton.classList.add('pui-button-with-initials-not-selected');
        }

        if (icon) {
            this._innerIcon = document.createElement('pui-text');
            this._innerIcon.classList.add('profile-circle-with-initials');
            this._innerIcon.setAttribute('textColor', 'black');
            this._innerIcon.setAttribute('textSize','small');
            this._innerIcon.setAttribute('input', icon);
            this._innerIcon.setAttribute('inline', 'true');
            this._innerButton.prepend(this._innerIcon);
        }

        if (formAction) {
            this._innerButton.setAttribute('type', formAction);
        }
    }

    attachEventListener() {
        this.addEventListener('click', this.buttonClicked.bind(this));
    }
}

window.customElements.define('pui-button-with-initials', PUIButtonWithInitials);

export default PUIButtonWithInitials;
