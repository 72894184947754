import PUIBase from "../pui-base";
import "../../../css/_group-accordion-item.scss"
import {injectPUIStyles} from "../../functions/domUtils";

/**
 * This is a PUI component for collapsible accordion content
 * which are collapsible in groups.
 */

export class PUIGroupAccordionItem extends PUIBase {

    groupAccordionItem: HTMLDivElement;

    static get observedAttributes() {
        return [
            ...super.observedAttributes,
            "hasaccordioncontrol",
            "notcollapsed",
        ];
    }

    constructor() {
        super();
        this.groupAccordionItem = document.createElement("div");
        this.groupAccordionItem.innerHTML = `
            <div class="group-accordion-item">
                <div class="group-accordion-item-header">
                    <slot name="header"></slot>
                    <div class="chevron"></div>
                </div>
                <div class="group-accordion-item-content">
                    <slot></slot>
                </div>
            </div>
        `;
        this.attachShadow({ mode: "open" });
        injectPUIStyles(this.shadowRoot!);
    }

    /**
     * This is important attribute if the group accordion needs to be collapsible.
     * This determines which particular item will have the chevron-arrow
     * which is the control to collapse the accordion group
     * this attribute should be provided on <pui-group-accordion-item>
     */
    get hasAccordionControl() {
        return this.getBooleanAttribute("hasAccordionControl") || false;
    }

    set hasAccordionControl(value: true | false) {
        this.setBooleanAttribute("hasAccordionControl", value);
    }

    /**
     * This attribute defines the state of the <pui-group-accordion-item>
     *     if notCollapsed==="true" the accordion item is not collapsed
     *     else it is collapsed.
     */
    get notCollapsed() {
        return this.getBooleanAttribute("notCollapsed") || false;
    }

    set notCollapsed(value) {
        this.setBooleanAttribute("notCollapsed", value);
    }

    connectedCallback() {
        super.connectedCallback();
        this.render();
    }

    attributeChangedCallback() {
        super.attributeChangedCallback();
        this.determineStateOfAccordionItem();
    }

    private render() {
        this.shadowRoot!.append(this.groupAccordionItem);
        const chevron: Element | null = this.shadowRoot!.querySelector(".chevron");

        if(this.hasAccordionControl) {
            const chevronIcon: HTMLSpanElement = document.createElement("span");
            chevronIcon.classList.add("chevron-icon");
            chevronIcon.addEventListener("click", this.onClick.bind(this));
            chevron!.append(chevronIcon);
        }
        this.determineStateOfAccordionItem();
    }

    determineStateOfAccordionItem() {
        const chevronIcon: Element | null = this.shadowRoot!.querySelector(".chevron-icon");
        const groupAccordionItemContent: Element | null = this.shadowRoot!.querySelector(".group-accordion-item-content");
        if (groupAccordionItemContent) {
            (this.notCollapsed) ?
                groupAccordionItemContent!.classList.remove("pui-hidden") :
                groupAccordionItemContent!.classList.add("pui-hidden");
        }
        if(chevronIcon && this.hasAccordionControl) {
            (this.notCollapsed) ?
                chevronIcon!.classList.add("rotated") :
                chevronIcon!.classList.remove("rotated");
        }
    }

    onClick() {
        this.dispatchAccordionClickEvent();
    }

    /**
     * This is a custom event used by <pui-group-accordion-item> which has the control to
     * collapse the whole group. It is dispatched when the chevron-arrow is clicked.
     * This event bubbles up to <pui-group-accordion> which queries all the items
     * enclosed with in and collapses their content.
     */
    dispatchAccordionClickEvent() {
        this.dispatchEvent(new CustomEvent("group-accordion-click", {
            bubbles: true,
            cancelable: true,
        }));
    }
}

window.customElements.define("pui-group-accordion-item", PUIGroupAccordionItem);
