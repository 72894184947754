const PUI_STYLE_TAG_SELECTOR = 'style[data-pui-style-tag="true"]';

/**
 * Helper method to copy PUI style tags from the main document and insert them
 * into a given shadow DOM root.
 * 
 * This will let you use PUI themes inside the shadow DOM- without calling
 * this method, shadow DOM elements will not render with PUI theming.
 * 
 * @param shadowRoot The shadow document to inject PUI styles into.
 * 
 * @example
 *  // Inside a webcomponent:
 *  this.attachShadow({mode: 'open'});
 *  injectPUIStyles(this.shadowRoot);
 *  this.shadowRoot.appendChild(new PUIBottomSheet());
 */
export function injectPUIStyles(shadowRoot: DocumentFragment) {
    const styleNodes = document.querySelectorAll(PUI_STYLE_TAG_SELECTOR);

    for (let node of styleNodes) {
        shadowRoot.appendChild(node.cloneNode(true));
    }
}

/**
 * Given a string of HTML, stuff the HTML into a <template> node for use with
 * WebComponents.
 * 
 * This is meant to be used as a tagged string function:
 * 
 * The templating works just like template literals do, and there is no magic
 * beyond handling some DOM boilerplate to create the template.
 * 
 * @param templateString The HTML template to inject into the page.
 * @param values String values to be transcluded into the template above
 * 
 * 
 * @example
 *      // Inside a webcomponent render method:
 *      const myTemplate = template`<slot> Inner elements here </slot>`
 *      this.appendChild(myTemplate.content.cloneNode(true));
 */
export function template(templateString: TemplateStringsArray, ...values: string[]): HTMLTemplateElement {
    let templateElement = document.createElement('template');
    templateElement.innerHTML = String.raw(templateString, ...values);
    return templateElement;
}

/**
 * Helper utility to call `callback` if the key event is for an enter key.
 * 
 * This is to reduce boilerplate around accessibility features, and simplifies
 * adding keyboard accessibility to your PUI components.
 * 
 * @example
 *  this.myLink.addEventListener('keydown', keyListener(onClickLink));
 */
export function keyListener(callback: (event: KeyboardEvent) => void) {
    return (event: KeyboardEvent) => {
        // TODO: Should we add spacebar support as well?
        if (event.key === 'Enter') {
            callback(event);
        }
    }
}