const CSRF_TOKEN_HEADER_NAME = 'anti-csrftoken-a2z';

export default class Request {
  static get(url, params = {}) {
    return new Promise((resolve, reject) => {
      const xhttp = new XMLHttpRequest();
      xhttp.withCredentials = true;
      xhttp.onreadystatechange = () => {
        if (xhttp.readyState === 4) {
          if (xhttp.status === 200) {
            let response;
            if (xhttp.responseText) {
              try {
                response = JSON.parse(xhttp.responseText);
              } catch(e) {
                reject(e);
              }
              if (response.error) {
                return reject(response);
              }
              return resolve(response);
            }
          }
          return reject(xhttp);
        }
      };
      xhttp.open('GET', Request._constructUrl(url, params), true);
      xhttp.send();
    });
  }

  static post(url, body = {}) {
    return new Promise((resolve, reject) => {
      const xhttp = new XMLHttpRequest();
      xhttp.withCredentials = true;
      xhttp.onreadystatechange = () => {
        if (xhttp.readyState === 4) {
          if (xhttp.status === 200) {
            const response = JSON.parse(xhttp.response);
            if (response.error) {
              return reject(response);
            }
            return resolve(response);
          }
          return reject(xhttp);
        }
      };
      xhttp.open('POST', url, true);
      xhttp.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
      xhttp.send(JSON.stringify(body));
    });
  }

  static delete(url, params = {}) {
    return new Promise((resolve, reject) => {
      const xhttp = new XMLHttpRequest();
      xhttp.withCredentials = true;
      xhttp.onreadystatechange = () => {
        if (xhttp.readyState === 4) {
          if (xhttp.status === 200) {
            let response;
            if (xhttp.responseText) {
              response = JSON.parse(xhttp.responseText);
            }
            if (response.error) {
              return reject(response);
            }
            return resolve(response);
          }
          return reject(xhttp);
        }
      };
      xhttp.open('DELETE', Request._constructUrl(url, params), true);
      xhttp.send();
    });
  }

  static _constructUrl(url, params) {
    let constructedUrl = url.indexOf('?') > 0 ? `${url}&` : `${url}?`;
    Object.keys(params).forEach((queryParamName) => {
      const queryParamValue = params[queryParamName];
      constructedUrl += `${queryParamName}=${queryParamValue}&`;
    });
    return constructedUrl;
  }

  static _getAntiCsrfToken() {
    const csrfTokenTag = document.querySelector(`input[name='${CSRF_TOKEN_HEADER_NAME}']`);

    if (csrfTokenTag === null) {
      return null;
    }

    const csrfToken = csrfTokenTag.getAttribute("value");

    if (csrfToken === null || csrfToken === "") {
      return null;
    }

    return csrfToken;
  }

  static _isStateChangingMethod(requestMethod) {
    return typeof requestMethod === 'string' && !['GET', 'HEAD', 'OPTIONS'].includes(requestMethod.toUpperCase());
  }

  static _isChatRequest() {
    const chatContainer = document.querySelector(".connect-customer-interface");
    if (chatContainer === null) {
      return false;
    }
    return true
  }

  /**
     * This method is a common handler to intercept all ajax requests, in order to handle customer authentication
     * failures. We are adding a header to the request which can be detected on server to identify ajax request, and
     * we are handling 401 response from the server in case of auth failure.
     * @private
     */
  static _interceptAjaxForAuthHandling() {
    const { send } = XMLHttpRequest.prototype;
    XMLHttpRequest.prototype.send = function () {
      // Customized request header (e.g. X-Requested-With) may trigger preflighted request for cross-site request which may not be desired.
      // Since 'X-Requested-With' is not a standard requeset header and no formal usage, the implementation may vary from different frameworks,
      // e.g. jQuery doesn't append 'X-Requested-With' for cross-site request by default.
      // I simply prevent preflighted request with customized request header for cross-domain PID authentication, and we can come back for further changes.
      // See https://whidbeycollab.aka.corp.amazon.com/j/browse/APEX-1581
      // See https://developer.mozilla.org/en-US/docs/Web/HTTP/CORS#Simple_requests
      if(!Request._isChatRequest()) {
        this.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
      }

      const csrfToken = Request._getAntiCsrfToken();
      if (csrfToken !== null) {
        this.setRequestHeader(CSRF_TOKEN_HEADER_NAME, csrfToken);
      }

      this.addEventListener('readystatechange', function () {
        if (this.readyState === 4 && this.status === 401) {
          const response = JSON.parse(this.responseText);
          if (response != null && response['apex-ajax-login-url'] != null) {
            window.location.href = response['apex-ajax-login-url'];
          }
        }
      }, false);
      send.apply(this, arguments);
    };

    // Calls using the modern fetch() API do not use XMLHttpRequest and will lack the authorization headers set in the
    // interceptor for XMLHttpRequest.send above. We can similarly provide the anti-csrftoken-a2z header by overriding
    // window.fetch with a custom implementation.
    const { fetch } = window;

    // leave the original fetch available on the page for LEGO widgets
    // TODO: we should make the polyfill opt-in instead of on-by-default
    window._fetch = fetch;

    window.fetch = function (input, options) {
      const request = new window.Request(input, options);
      
      // State changing HTTP methods require a CSRF token (i.e. all methods other than GET, HEAD, and OPTIONS):
      // https://skb.highcastle.a2z.com/implementations/8#mech
      if (Request._isStateChangingMethod(request.method)) {
        const csrfToken = Request._getAntiCsrfToken();

        // Set the anti-csrftoken-a2z header only if the fetch caller did not already set the header. This allows
        // applications to customize the anti-csrftoken-a2z header for individual requests.
        if (!!csrfToken && !request.headers.has(CSRF_TOKEN_HEADER_NAME)) {
          request.headers.set(CSRF_TOKEN_HEADER_NAME, csrfToken);
        }
      }

      return fetch(request);
    };
  }
}
Request._interceptAjaxForAuthHandling();
