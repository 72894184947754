import PUIBase from './pui-base';
import * as shadyCss from '@webcomponents/shadycss';

/* eslint-disable import/no-unresolved */
import baseCss from '../../css/_base.scss?inline';
import sectionCss from '../../css/_section.scss?inline';
import textCss from '../../css/_text.scss?inline';
import dividerCss from '../../css/_divider.scss?inline';
import dropdownItemCss from '../../css/_dropdown-item-two.scss?inline';
/* eslint-enable import/no-unresolved */

const innerTemplate = `
  <style>
    ${baseCss}
    ${sectionCss}
    ${textCss}
    ${dividerCss}
    ${dropdownItemCss}
  </style>
  <pui-section role="button" class="pui-dropdown-item-two" flowDirection="vertical" mainAxisArrangement="center">
    <pui-text textAlign="left" spacingTop="small" spacingBottom="small" class="dropdown-two-text-item"></pui-text>
    <pui-divider></pui-divider>
  </pui-section>
`;

let template = document.createElement('template');

template.innerHTML = innerTemplate;

shadyCss.prepareTemplate(template, 'pui-dropdown-item-two');

export default class PUIDropdownItemTwo extends PUIBase {
  constructor() {
    super();

    shadyCss.styleElement(this);
    if (!this.shadowRoot) {
      this.attachShadow({mode: 'open'});
      this.shadowRoot.appendChild(
        document.importNode(template.content, true));
    }

    this.upgradeProperty = this.upgradeProperty.bind(this);
  }

  attributeChangedCallback(name, oldValue, newValue) {
    if (this.disabled) {
      this.classList.add('pui-carousel-element-disabled');
    }

    if (this.selected) {
      this.select();
    }
    properties.forEach(this.upgradeProperty);
  }

  connectedCallback() {
    super.connectedCallback();

    this.classList.add('pui-block');
    this.textInput = this.shadowRoot.querySelector('pui-text');
    if (this.textInput) {
      if (this.type == 'cancel') {
        this.textInput.setAttribute('input', 'cancel');
        this.textInput.setAttribute('textColor', 'link');
      } else {
        if (this.label) {
          this.textInput.setAttribute('input', this.label);
        }
      }
    }
    this.setAttribute('tabindex', '0');
    this.checkWhenSelected(this.selectedValue);
  }

  checkWhenSelected(selectedValue) {
    if (!this.textInput) return;

    this.checked = selectedValue && (this.value === selectedValue);
    if (this.checked) {
      this.textInput.classList.add('pui-dropdown-item-text-checked');
    } else {
      this.textInput.classList.remove('pui-dropdown-item-text-checked');
    }
  }

  get type() {
    return this.getAttribute('type') || '';
  }

  set type(value) {
    this.setAttribute('type', value);
  }

  get label() {
    return this.getAttribute('label') || '';
  }

  set label(value) {
    this.setAttribute('label', value);
  }

  get value() {
    return this.getAttribute('value') || '';
  }

  set value(value) {
    this.setAttribute('value', value);
  }

  get selectedValue() {
    return this.getAttribute('selectedValue');
  }
}

window.customElements.define('pui-dropdown-item-two', PUIDropdownItemTwo);
