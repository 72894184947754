import PUIBase from './pui-base';

class PUIPhoneInput extends PUIBase {
  constructor() {
    super();
    this.defaultSpacingTop = 'small';
    this.defaultInputType = 'text';
    this.maxLength = 14; // maximum length for phone number after formatting
    this.phoneNumber = '';
  }

  static get observedAttributes() {
    return ['inputId', 'label', 'placeholder', 'textInputType', 'status', 'name', 'value', 'maxLength'];
  }

  connectedCallback() {
    super.connectedCallback();
    this.render();
  }

  get inputId() {
    return this.getAttribute('inputId') || '';
  }

  set inputId(value) {
    this.setAttribute('inputId', value);
  }

  get label() {
    return this.getAttribute('label') || '';
  }

  set label(value) {
    this.setAttribute('label', value);
  }

  get placeholder() {
    return this.getAttribute('placeholder') || '';
  }

  set placeholder(value) {
    this.setAttribute('placeholder', value);
  }

  get name() {
    return this.getAttribute('name') || '';
  }

  set name(value) {
    this.setAttribute('name', value);
  }

  get value() {
    return this.getAttribute('value') || '';
  }

  set value(value) {
    this.setAttribute('value', value);
  }

  get iconClass() {
    return this.getAttribute('iconClass') || '';
  }

  set iconClass(value) {
    this.setAttribute('iconClass', value);
  }


  inputClicked(event) {
    const inputContainer = this.querySelector('.pui-phone-input-container');
    if (!inputContainer.classList.contains('input-disabled')) {
      if (this.contains(event.target)) {
        this.querySelector('input').focus();
        inputContainer.classList.remove('input-error');
        inputContainer.classList.add('input-selected');
      } else {
        inputContainer.classList.remove('input-selected');
      }
    }
  }

  getInput() {
    const inputElement = this.getElementsByClassName('pui-phone-input')[0];
    return inputElement;
  }

  getValue() {
    return this.phoneNumber;
  }

  getFormattedValue() {
    return this._innerInputField.value;
  }

  setValue(value) {
    this.phoneNumber = value;
    this._innerInputField.setAttribute('value', this.formatPhoneNumber(value));
  }

  getDataSearchUrl() {
    const dataSearchUrl = this.getElementsByClassName('pui-phone-input-container')[0].attributes[0].nodeValue;
    return dataSearchUrl;
  }

  inputChangedHandler(event) {
    if (this.onInputChange) {
      this.onInputChange(this.getValue());
    }
  }

  formatPhoneNumber(phonenumber) {
    const cleaned = (`${phonenumber}`).replace(/\D/g, '');
    let phone = '';
    const match = cleaned.match(/^(\d{1,3})(\d{0,3})(\d{0,4})$/);
    if (match) {
      if (match[0].length < 4) {
        phone = `${match[1]}${match[2] ? ' ' : ''}${match[2]}${match[3] ? '-' : ''}${match[3]}`;
      } else {
        phone = `(${match[1]})${match[2] ? ' ' : ''}${match[2]}${match[3] ? '-' : ''}${match[3]}`;
      }
    }
    return phone;
  }

  render() {
    this.innerHTML = '';
    const {
      label,
      placeholder,
      name,
      value,
      maxLength,
    } = this;

    let {
      status,
      iconClass,
    } = this;

    if (status) {
      status = `input-${status}`;
    }

    if (value) {
        this.phoneNumber = value;
    }

    iconClass = 'flag-usa'; // hard coded until we add other locales

    let labelHidden = '';
    if (label === '') {
      labelHidden = 'pui-hidden';
    }

    let iconHidden = '';
    if (iconClass === '') {
      iconHidden = 'pui-hidden';
    }

    this.classList.add('pui-block');
    this._innerContainer = document.createElement('span');
    this._innerContainer.innerHTML = `
        <div class="pui-phone-input-container ${status}" >
            <pui-text input="${label}" class="${labelHidden}" textSize="mini"></pui-text>
            <div class="pui-phone-input-line-container">
              <pui-icon class="${iconHidden}" paddingRight="small" imgClass="${iconClass}"></pui-icon>
              
            </div>
        </div>
        `;
    this._innerInputField = document.createElement('input');
    this._innerInputField.setAttribute('id', this.inputId);
    this._innerInputField.classList.add('pui-phone-input');
    this._innerInputField.setAttribute('name', name);
    this._innerInputField.setAttribute('value', this.formatPhoneNumber(value));
    this._innerInputField.setAttribute('autocomplete', 'on');
    this._innerInputField.setAttribute('maxLength', maxLength);
    this._innerInputField.setAttribute('type', 'tel');
    this._innerInputField.setAttribute('placeholder', placeholder);

    const inputSlot = this._innerContainer.lastElementChild.lastElementChild;
    inputSlot.appendChild(this._innerInputField);
    this.appendChild(this._innerContainer);

    document.body.addEventListener('click', this.inputClicked.bind(this));

    const self = this;
    this._innerInputField.addEventListener('keyup', function (event) {
      const n = parseInt(self._innerInputField.value.replace(/\D/g, ''), 10);
      if (n.toString().length <= 10) {
        self.phoneNumber = n;
        self._innerInputField.value = self.formatPhoneNumber(n);
      }
    }, false);
  }
}

window.customElements.define('pui-phone-input', PUIPhoneInput);
