import PUIBase from '../pui-base';
import Request from '../../networking/request';
import PUILoadingIndicator from '../pui-loading-indicator';

/**
 *  This is the base component which provides functionality that is common to
 *  identity verification components
 */
export default class PUIUploadCardBase extends PUIBase {
  constructor() {
    super();
    this.hiddenClass = 'pui-hidden';
    this.pageElements = new Map();
  }

  connectedCallback() {
    super.connectedCallback();
  }

  get captureType() {
    return this.getAttribute('captureType') || 'user';
  }

  set captureType(value) {
    if (!['user', 'environment'].includes(value)) {
      throw new Error('Invalid value for capture type');
    }
    this.setAttribute('captureType', value);
  }

  /**
   * Implementation required
   */
  renderTakePhotoPage() {
    throw new Error('You have to implement the method renderTakePhotoPage');
  }

  /**
   * Render confirm photo page for self photo and document upload page
   * @returns {string}
   */
  renderConfirmPhotoPage() {
    const { strings } = this.config;
    const html = `
        <div class="confirmPhotoPage ${this.hiddenClass}">
            <pui-section-column>
                <pui-heading input="${strings.confirmCardTitle}" textSize="extra-large"></pui-heading>
            </pui-section-column>
            <pui-text spacingTop="medium" spacingBottom="medium" input="${strings.confirmCardMessage}"></pui-text>
            <img class="confirm-image-element" src="#" alt="selfie-photo" />
            <input type="text" class="image-encoded-element ${this.hiddenClass}" name="encodedImage[]" spellcheck="false" />
            <pui-button class="confirm-button" spacingTop="medium" label="${strings.confirmButtonLabel}"></pui-button>
            <pui-button class="retake-button" spacingTop="medium" label="${strings.retakeButtonLabel}" theme="secondary"></pui-button>
        </div>
    `;

    return html;
  }

  /**
   * Responsible to build and render the file input
   */
  buildFileSelector(pageId) {
    // TODO: Enable video screen for desktop; https://issues.amazon.com/issues/KATARA-SX-261

    // Build file selector
    const fileSelector = document.createElement('input');
    fileSelector.name = 'file';
    fileSelector.classList.add('fileInput');
    fileSelector.type = 'file';
    fileSelector.accept = 'image/*';
    fileSelector.setAttribute('capture', this.captureType);
    fileSelector.addEventListener('change', this._handleFileSelectClick.bind(this, fileSelector, pageId));

    const { fileSelectorContainer, fileSelectorTriggers } = this.getPageElements(pageId);

    // Render file selector
    fileSelectorContainer.innerHTML = '';
    fileSelectorContainer.append(fileSelector);

    // Attach onclick handler on file selector trigger
    Array.from(fileSelectorTriggers).forEach((fileSelectorTriggerButton) => {
      fileSelectorTriggerButton.addEventListener('click', () => fileSelector.click());
    });
  }

  /**
   * Handling logic when user selected a photo or finished taking a photo
   */
  _handleFileSelectClick(fileSelector, pageId, event) {
    const theFile = fileSelector.files[0];
    if (theFile) {
      // TODO: File validation; https://issues.amazon.com/issues/KATARA-SX-260
      // Read in the image file as a data URL.
      const { confirmImageTag, confirmImageEncodedInput } = this.getPageElements(pageId);
      const reader = new FileReader();
      reader.onload = function (event) {
        // Convert to base64string
        const readerResult = event.target.result;
        const base64String = readerResult
          .replace('data:', '')
          .replace(/^.+,/, '');

        confirmImageTag.setAttribute('src', readerResult.toString());
        confirmImageEncodedInput.setAttribute('value', base64String);
      };

      reader.readAsDataURL(theFile);

      // Reset the value of input target
      const fileTarget = event.target;
      fileTarget.value = '';

      // Show confirm section
      this._showConfirmPhotoPage(pageId);
    }
  }

  /**
   * Responsible for uploading photos to the server ${this.config.uploadUrl} url.
   */
  uploadHandler(pageId) {
    const base64EncodedStrings = [];
    const imageEncodedInputs = this.querySelectorAll('.image-encoded-element');
    const hasEmptyValue = Array.from(imageEncodedInputs).some((imageEncodedInput) => {
      const _encodedValue = imageEncodedInput.getAttribute('value');
      base64EncodedStrings.push({ bytes: _encodedValue });
      return _encodedValue === '';
    });

    // Some of the inputs has empty value
    if (hasEmptyValue) {
      this.retakeButtonClickHandler(pageId);
      return false;
    }

    // Show loading indicator during upload
    this.displaySpinner();

    Request.post(this.config.uploadUrl, {
      attachments: base64EncodedStrings,
    }).then(() => {
      window.location.href = this.config.successRedirectUrl;
    }).catch((response) => {
      this._showError(response);
      this.hideSpinner();
    });

    return true;
  }

  /**
   * Responsible for handling retake button event on the confirm page
   */
  retakeButtonClickHandler(pageId) {
    this._resetConfirmImage(pageId);
    this._showTakePhotoPage(pageId);
  }

  setupLoadingIndicator() {
    this._loadingIndicator = new PUILoadingIndicator();
    this._loadingIndicator.hide();

    const rootContainer = document.getElementById('idv-page');
    rootContainer.before(this._loadingIndicator);
  }

  /**
   * Displays spinner over button to indicate callback is loading
   */
  displaySpinner() {
    this._loadingIndicator.show();
  }

  hideSpinner() {
    this._loadingIndicator.hide();
  }

  /**
   * Reset confirmation image element. Should be used when user wants to retake their photo.
   */
  _resetConfirmImage(pageId) {
    const { confirmImageTag, confirmImageEncodedInput } = this.getPageElements(pageId);
    confirmImageTag.setAttribute('src', '#');
    confirmImageEncodedInput.setAttribute('value', '');
  }

  /**
   * Show the take photo page and hide the confirm page
   */
  _showTakePhotoPage(pageId) {
    const { takePhotoPage, confirmPhotoPage } = this.getPageElements(pageId);
    takePhotoPage.classList.remove(this.hiddenClass);
    confirmPhotoPage.classList.add(this.hiddenClass);
  }

  /**
   * Show the confirm page and hide the the take photo page
   */
  _showConfirmPhotoPage(pageId) {
    const { takePhotoPage, confirmPhotoPage, puiPageError } = this.getPageElements(pageId);
    takePhotoPage.classList.add(this.hiddenClass);
    confirmPhotoPage.classList.remove(this.hiddenClass);
    puiPageError.hide();
  }

  _showError(err, pageId) {
    let errMessage = 'An error occurred and we are looking into it. Please try again.';
    if (err && err.message) {
      errMessage = err.message;
    }

    const { puiPageError } = this.getPageElements(pageId);
    puiPageError.setDescription(errMessage);
    puiPageError.show();
  }

  getPageElements(pageId) {
    if (this.pageElements.has(pageId)) {
      return this.pageElements.get(pageId);
    }

    const mapValue = {
      pageElement: this.querySelector(`#${pageId}`),
      takePhotoPage: this.querySelector(`#${pageId} .takePhotoPage`),
      fileSelectorContainer: this.querySelector(`#${pageId} .file-selector-container`),
      fileSelectorTriggers: this.querySelectorAll(`#${pageId} .file-selector-trigger`),
      confirmPhotoPage: this.querySelector(`#${pageId} .confirmPhotoPage`),
      retakeButton: this.querySelector(`#${pageId} .retake-button`),
      confirmButton: this.querySelector(`#${pageId} .confirm-button`),
      confirmImageTag: this.querySelector(`#${pageId} .confirm-image-element`),
      confirmImageEncodedInput: this.querySelector(`#${pageId} .image-encoded-element`),
      puiPageError: this.querySelector(`#${pageId} .page-error`),
    };

    this.pageElements.set(pageId, mapValue);
    return mapValue;
  }
}
