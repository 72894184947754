const ERROR_CODES = {
  MAXIMUM_FILE_SIZE_EXCEEDED: 'MAXIMUM_FILE_SIZE_EXCEEDED',
  MAXIMUM_ALLOWED_FILES_EXCEEDED: 'MAXIMUM_ALLOWED_FILES_EXCEEDED',
  CONTENT_TYPE_NOT_ALLOWED: 'CONTENT_TYPE_NOT_ALLOWED',
};

const attachmentPreviewType = {
  PREVIEW_TYPE_COMPACT: 'compact',
  PREVIEW_TYPE_FULL: 'full',
};

const compressedImageConfiguration = {
  COMPRESSED_IMAGE_MAX_WIDTH: 1000,
  COMPRESSED_IMAGE_MAX_HEIGHT: 1000,
  // JPEG/PNG at 100% quality
  COMPRESSED_IMAGE_QUALITY: 1,
};

export {
  ERROR_CODES,
  attachmentPreviewType,
  compressedImageConfiguration,
};
