import PUIBase from './pui-base';


class PUIListLinkItem extends PUIBase {
  constructor() {
    super();
    this.defaultClass = 'pui-list-link-item';
  }

  connectedCallback() {
    super.connectedCallback();
    this.render();
  }

  attributeChangedCallback() {
    this.render();
  }

  static get observedAttributes() {
    return ['label', 'input', 'subtext', 'url', 'icontype', 'iconoptions', 'aligniconleftoflabel',
    'labeltextsize', 'hidedivider', 'disabled', 'showborder', 'labelleftspacing', 'chevronleftspacing'];
  }

  /**
     * @classprop {string} url This is the url path
     *
     */
  get url() {
    return this.getAttribute('url') || '';
  }

  set url(value) {
    this.setAttribute('url', value);
  }

  /**
     * @classprop {string} label This is the item label
     *
     */
  get label() {
    return this.getAttribute('label') || '';
  }

  set label(value) {
    this.setAttribute('label', value);
  }

  /**
     * @classprop {string} input This is the item text
     *
     */
  get input() {
    return this.getAttribute('input') || '';
  }

  set input(value) {
    this.setAttribute('input', value);
  }

  /**
     * @classprop {string} subtext This is the item text
     *
     */
  get subtext() {
    return this.getAttribute('subtext') || '';
  }

  set subtext(value) {
    this.setAttribute('subtext', value);
  }

  /**
   * @classprop {string} iconType Select an iconType to add inline before an input
   *
   */
  get iconType() {
    return this.getAttribute('iconType') || '';
  }

  set iconType(value) {
    this.setAttribute('iconType', value);
  }

  /**
   * @classprop iconOptions Provide additional information for icon type
   *
   */
  get iconOptions() {
    return JSON.parse(this.getAttribute('iconOptions')) || '';
  }

  set iconOptions(value) {
    this.setAttribute('iconOptions', value);
  }

  /**
   * If present, will hide the bottom divider
   * @type {boolean}
   * @attr
   */
  get hideDivider() {
    return this.getBooleanAttribute('hideDivider');
  }

  set hideDivider(value) {
    this.setBooleanAttribute('hideDivider', value);
  }

  get disabled() {
    return this.getBooleanAttribute('disabled');
  }

  set disabled(value) {
    this.setBooleanAttribute('disabled', value, this._innerListLinkItem);
  }

  get alignIconLeftOfLabel() {
    return this.getBooleanAttribute('alignIconLeftOfLabel');
  }

  set alignIconLeftOfLabel(value) {
    this.setBooleanAttribute('alignIconLeftOfLabel', value);
  }

  /**
   * If present, will show the border.
   * @type {boolean}
   * @attr
   */
  get showBorder() {
    return this.getBooleanAttribute('showBorder');
  }

  set showBorder(value) {
    this.setBooleanAttribute('showBorder', value);
  }

  /**
   * @classprop labelLeftSpacing Provide spacing to the left of the label
   *
   */
  get labelLeftSpacing() {
    return this.getAttribute('labelLeftSpacing') || '';
  }

  set labelLeftSpacing(value) {
    this.setAttribute('labelLeftSpacing', value);
  }

  /**
   * @classprop chevronLeftSpacing Provide spacing to the left of the chevron button
   *
   */
  get chevronLeftSpacing() {
    return this.getAttribute('chevronLeftSpacing') || '';
  }

  set chevronLeftSpacing(value) {
    this.setAttribute('chevronLeftSpacing', value);
  }

  get labelTextSize() {
    return this.getAttribute('labelTextSize') || '';
  }

  set labelTextSize(value) {
    this.setAttribute('labelTextSize', value);
  }

  get encode() {
    return this.getBooleanAttribute('encode');
  }

  set encode(value) {
    return this.setBooleanAttribute('encode', value);
  }

  enable() {
    this.disabled = false;
  }

  disable() {
    this.disabled = true;
  }

  alignIconLeftOfLabel() {
    this.alignIconLeftOfLabel = true;
  }

  render() {
    this.innerHTML = '';
    this.classList.add('pui-block');
    this._innerListLinkItem = document.createElement('div');
    this.classList.add(this.defaultClass);

    if (this.showBorder) {
      this.classList.add('pui-link-list-item-border');
    }

    let icon = '';
    if (this.iconType && this.iconType === 'initial') {
      icon = `
        <pui-text input="${this.iconOptions.initial}" 
                  class="person-circle" 
                  textColor="white" 
                  inline="true" 
                  fontweight="bold" 
                  spacingRight="medium">
        </pui-text>`;
    } else if (this.iconType && this.iconType === 'img') {
      icon = `<pui-icon imgClass="${this.iconOptions.imgClass}" spacingRight=${this.iconOptions.spacingRight ?? 'medium'} spacingTop=${this.iconOptions.spacingTop ?? ''}></pui-icon>`;
    }

    let label = '';
    if (this.label && !this.labelTextSize) {
      label = `<pui-text input="${this.label}" textSize="mini" spacingLeft="${this.labelLeftSpacing}"></pui-text>`;
    } else if (this.label && this.labelTextSize) {
      label = `<pui-text input="${this.label}" fontWeight="bold" textSize="${this.labelTextSize}" spacingLeft="${this.labelLeftSpacing}"></pui-text>`;
    }

    const displaySubtext = this.subtext ? `<pui-text spacingTop="small" spacingLeft="${this.labelLeftSpacing}" input="${this.subtext}" textSize="small"></pui-text>` : '';
    // Divider for this list link item
    const divider = this.hideDivider ? '' : '<pui-divider></pui-divider>';

    let mainContent = '';
    if (this.alignIconLeftOfLabel) {
      mainContent = `
        <pui-section class="pui-list-link-item-container"
                     flowDirection="horizontal" 
                     mainAxisArrangement="flex-start" 
                     secondaryAxisArrangement="center">
          <pui-section width="auto">
            ${icon}
          </pui-section>
          <pui-section>
            ${label}
            <pui-text ${label ? `spacingTop="small"` : ``} input="${this.input}" textSize="medium" ${this.encode ? 'encode' : ''} spacingLeft="${this.labelLeftSpacing}"></pui-text>
          </pui-section>
          ${displaySubtext}
          <pui-icon imgClass=${this.disabled ? 'no-icon' : 'chevron-right'} spacingRight="medium" spacingLeft="${this.chevronLeftSpacing}"></pui-icon>
        </pui-section>
      `;
    } else {
      mainContent = `
      <pui-section class="pui-list-link-item-container"
                   flowDirection="horizontal"
                   mainAxisArrangement="space-between"
                   secondaryAxisArrangement="center">
        <pui-section mainAxisArrangement="space-between">
          ${label}
          <pui-section flowDirection="horizontal"
                       secondaryAxisArrangement="center">
            ${icon}
            <pui-text input="${this.input}" inline="true" ${this.encode ? 'encode' : ''}></pui-text>
          </pui-section>
          ${displaySubtext}
        </pui-section>
        <pui-icon imgClass=${this.disabled ? 'no-icon' : 'chevron-right'} spacingLeft="${this.chevronLeftSpacing}"></pui-icon>
      </pui-section>
      `;
    }

    if (this.disabled || this.url === "") {
      this._innerListLinkItem.innerHTML = `
        ${mainContent}
        ${divider}
      `;
    } else {
      this._innerListLinkItem.innerHTML = `
        <a href="${this.url}" style="text-decoration: none">
          ${mainContent}
        </a>
        ${divider}
      `;
    }

    this.appendChild(this._innerListLinkItem);
  }
}

window.customElements.define('pui-list-link-item', PUIListLinkItem);
