import ESAPI from 'node-esapi';
import PUIBase from './pui-base';

class PUILineItem extends PUIBase {
  connectedCallback() {
    super.connectedCallback();
    this.render();
  }

  attributeChangedCallback() {
    this.render();
  }

  static get observedAttributes() {
    return [
      'url', 'iconclass', 'imgsrc', 'imgWidth',
      'imgHeight', 'headline', 'sublineiconclass', 'subline',
      'tertiaryline', 'hidechevron',
      'lineitembuttonlabel', 'lineitembuttontheme',
      'lineitembuttonwidth', 'lineitembuttonheight',
      'righticonclass', 'headlinesize', 'headlineweight', 'spacingbottom',
      'sublinesize', 'sublineweight', 'imgclass', 'contentsectionclass',
      'headlineclass', 'sublineclass', 'containersectionclass', 'hidetertiaryline', 'headlinetextcolor'
    ];
  }


  /**
   * Url to navigate to when clicking on this pui-line-item. Don't include this if you
   * want to handle routing yourself.
   * @type {string}
   * @attr
   */
  get url() {
    return this.getAttribute('url') || '';
  }

  set url(value) {
    this.setAttribute('url', value);
  }

  /**
   * imgClass for the icon for this line item. Don't use this if you want to use
   * an image instead of an icon.
   * @type {string}
   * @attr
   */
  get iconClass() {
    return this.getAttribute('iconClass') || '';
  }

  set iconClass(value) {
    this.setAttribute('iconClass', value);
  }

  /**
   * The img src for this line item. Don't use this if you want an icon instead of an image
   * @type {string}
   * @attr
   */
  get imgSrc() {
    return this.getAttribute('imgSrc') || '';
  }

  set imgSrc(value) {
    this.setAttribute('imgSrc', value);
  }

  /**
   * The img width
   * @type {string}
   * @attr
   */
  get imgWidth() {
    return this.getAttribute('imgWidth') || '';
  }

  set imgWidth(value) {
    this.setAttribute('imgWidth', value);
  }

  /**
   * The img height
   * @type {string}
   * @attr
   */
  get imgHeight() {
    return this.getAttribute('imgHeight') || '';
  }

  set imgHeight(value) {
    this.setAttribute('imgHeight', value);
  }

  /**
   * Headline for this line item
   * @type {string}
   * @attr
   */
  get headline() {
    return this.getAttribute('headline') || '';
  }

  set headline(value) {
    this.setAttribute('headline', value);
  }

  /**
   * The imgClass for the sublineIcon for this line item
   * @type {string}
   * @attr
   */
  get sublineIconClass() {
    return this.getAttribute('sublineIconClass') || '';
  }

  set sublineIconClass(value) {
    this.setAttribute('sublineIconClass', value);
  }

  /**
   * Subline for this line item
   * @type {string}
   * @attr
   */
  get subline() {
    return this.getAttribute('subline') || '';
  }

  set subline(value) {
    this.setAttribute('subline', value);
  }

  /**
   * The imgClass for the tertiarylineIcon for this line item
   * @type {string}
   * @attr
   */
  get tertiarylineIconClass() {
    return this.getAttribute('tertiarylineIconClass') || '';
  }

  set tertiarylineIconClass(value) {
    this.setAttribute('tertiarylineIconClass', value);
  }

  /**
   * Tertiaryline for this line item
   * @type {string}
   * @attr
   */
  get tertiaryline() {
    return this.getAttribute('tertiaryline') || '';
  }

  set tertiaryline(value) {
    this.setAttribute('tertiaryline', value);
  }

  /**
   * If present, will hide the right chevron
   * @type {boolean}
   * @attr
   */
  get hideChevron() {
    return this.getBooleanAttribute('hideChevron');
  }

  set hideChevron(value) {
    this.setBooleanAttribute('hideChevron', value);
  }

  get righticonclass() {
    return this.getAttribute('righticonclass');
  }

  set righticonclass(value) {
    return this.setAttribute('righticonclass', this.encodedValue(value));
  }

  /**
   * If present, will hide the bottom divider
   * @type {boolean}
   * @attr
   */
  get hideDivider() {
    return this.getBooleanAttribute('hideDivider');
  }

  set hideDivider(value) {
    this.setBooleanAttribute('hideDivider', value);
  }

  /**
   * If present, will render a button with
   * the following label on the line item
   * @type {string}
   * @attr
   */
  get lineItemButtonLabel() {
    return this.getAttribute('lineItemButtonLabel') || '';
  }

  set lineItemButtonLabel(value) {
    this.setAttribute('lineItemButtonLabel', value);
  }

  /**
   * If present, will use the theme on the line item button
   * @type {string}
   * @attr
   */
  get lineItemButtonTheme() {
    return this.getAttribute('lineItemButtonTheme') || '';
  }

  set lineItemButtonTheme(value) {
    this.setAttribute('lineItemButtonTheme', value);
  }

  /**
   * The button width
   * @type {string}
   * @attr
   */
  get lineItemButtonWidth() {
    return this.getAttribute('lineItemButtonWidth') || '';
  }

  set lineItemButtonWidth(value) {
    this.setAttribute('lineItemButtonWidth', value);
  }

  /**
   * The button height
   * @type {string}
   * @attr
   */
  get lineItemButtonHeight() {
    return this.getAttribute('lineItemButtonHeight') || '';
  }

  set lineItemButtonHeight(value) {
    this.setAttribute('lineItemButtonHeight', value);
  }

  /**
   * Line item content axis arrangement
   * @type {string}
   * @attr
   */
  get lineItemContentAxisArrangement() {
    return this.getAttribute('lineItemContentAxisArrangement') || 'space-between';
  }

  set lineItemContentAxisArrangement(value) {
    this.setAttribute('lineItemContentAxisArrangement', value);
  }

  /**
   * Hide sub-lines if this property is set to true
   * @type {string}
   * @attr
   */
  get showOnlyHeadline() {
    return this.getAttribute('showOnlyHeadline');
  }

  set showOnlyHeadline(value) {
    this.setAttribute('showOnlyHeadline', value);
  }

  get headlinesize() {
    return this.getAttribute('headlinesize') || 'large';
  }

  set headlinesize(value) {
    this.setAttribute('headlinesize', this.encodedValue(value));
  }

  get headlineweight() {
    return this.getAttribute('headlineweight') || 'bold';
  }

  set headlineweight(value) {
    this.setAttribute('headlineweight', this.encodedValue(value));
  }

  /**
   * The subline size
   * @type {string}
   * @attr
   */
  get sublinesize() {
    return this.getAttribute('sublinesize') || 'small';
  }

  set sublinesize(value) {
    this.setAttribute('sublinesize', this.encodedValue(value));
  }

  /**
   * The subline weight
   * @type {string}
   * @attr
   */
  get sublineweight() {
    return this.getAttribute('sublineweight') || 'normal';
  }

  set sublineweight(value) {
    this.setAttribute('sublineweight', this.encodedValue(value));
  }

  /**
   * Set spacing for line items
   */
  get spacingbottom() {
    return this.getAttribute('spacingbottom') || 'medium';
  }

  set spacingbottom(value) {
    this.setAttribute('spacingbottom', this.encodedValue(value));
  }

  /**
   * Img class for styling
   * @type {string}
   * @attr
   */
  get imgClass() {
    return this.getAttribute('imgClass');
  }

  set imgClass(value) {
    this.setAttribute('imgClass', this.encodedValue(value));
  }

  /**
   * Content section class for styling
   * @type {string}
   * @attr
   */
  get contentSectionClass() {
    return this.getAttribute('contentSectionClass');
  }

  set contentSectionClass(value) {
    this.setAttribute('contentSectionClass', this.encodedValue(value));
  }

  /**
   * Headline class for styling
   * @type {string}
   * @attr
   */
  get headlineClass() {
    return this.getAttribute('headlineClass');
  }

  set contentSectionClass(value) {
    this.setAttribute('headlineClass', this.encodedValue(value));
  }

  /**
   * Subline class for styling
   * @type {string}
   * @attr
   */
  get sublineClass() {
    return this.getAttribute('sublineClass');
  }

  set sublineClass(value) {
    this.setAttribute('sublineClass', this.encodedValue(value));
  }

  /**
   * Container section class for styling
   * @type {string}
   * @attr
   */
  get containerSectionClass() {
    return this.getAttribute('containerSectionClass');
  }

  set containerSectionClass(value) {
    this.setAttribute('containerSectionClass', this.encodedValue(value));
  }

  /**
   * Flag to disable tertiary line section
   * @type {boolean}
   * @attr
   */
  get hideTertiaryLine() {
    return this.getAttribute('hideTertiaryLine');
  }

  set hideTertiaryLine(value) {
    this.setAttribute('hideTertiaryLine', value);
  }

    /**
   * The headline text's color
   * @type {string}
   * @attr
   */
    get headlineTextColor() {
      return this.getAttribute('headlineTextColor') || '';
    }
  
    set headlineTextColor(value) {
      this.setAttribute('headlineTextColor', value);
    }

  encodedValue(value) {
    return (value ? ESAPI.encoder().encodeForHTML(value) : value);
  }

  render() {
    this.innerHTML = '';
    this.classList.add('pui-block');
    this._innerLineItem = document.createElement('div');

    // Image for this line item
    let lineItemImage = '';
    if (this.iconClass) {
      lineItemImage = `<pui-icon imgClass="${this.iconClass}" spacingRight="medium"></pui-icon>`;
    } else if (this.imgSrc) {
      lineItemImage = `
        <img src="${this.imgSrc}" width="${this.imgWidth}" height="${this.imgHeight}" class="${this.imgClass ? this.imgClass : 'pui-line-item-image'}">
      `;
    }

    // Image for the subline
    const sublineIcon = this.sublineIconClass ? `<pui-icon imgClass="${this.sublineIconClass}" spacingRight="mini"></pui-icon>` : '';

    // Image for the subline
    const tertiarylineIcon = this.tertiarylineIconClass ? `<pui-icon imgClass="${this.tertiarylineIconClass}" spacingRight="mini" style="margin-left: 0px;"></pui-icon>` : '';

    // Chevron for this line item
    const rightIcon = this.righticonclass ? `
      <pui-icon imgClass="${this.righticonclass}"></pui-icon>
    ` : `
      <pui-icon imgClass="chevron-right" spacingRight="small"></pui-icon>
    `;
    const chevron = this.hideChevron ? '' : rightIcon;

    // Divider for this line item
    const divider = this.hideDivider ? '' : '<pui-divider></pui-divider>';

    // eslint-disable-next-line max-len
    const lineItemButton = this.lineItemButtonLabel ? `<pui-button id="${this.id}-lineItemButton" label="${this.lineItemButtonLabel}" theme="${this.lineItemButtonTheme}" height="${this.lineItemButtonHeight}" width="${this.lineItemButtonWidth}"></pui-button>` : '';

    // Main content for this line item
    const lineItemContent = `
    <pui-section
      id="${this.id}-container-section"
      flowDirection="horizontal"
      mainAxisArrangement="space-between"
      secondaryAxisArrangement="center"
      spacingbottom="${this.spacingbottom}"
      class="${this.containerSectionClass}"
    >
      <pui-section flowDirection="horizontal">
        ${lineItemImage}
        <pui-section id="${this.id}-content-section" mainAxisArrangement=${this.lineItemContentAxisArrangement} class="${this.contentSectionClass}">
          <pui-text id="${this.id}-headline" input="${this.headline}" textColor="${this.headlineTextColor}" textSize="${this.headlinesize}" fontWeight="${this.headlineweight}" class="${this.headlineClass}" hideIfEmpty></pui-text>
          ${this.showOnlyHeadline ? '' : 
            `
              <pui-section flowDirection="horizontal" spacingTop="mini">
                ${sublineIcon}
                <pui-text id="${this.id}-subline" input="${this.subline}" textSize="${this.sublinesize}" fontWeight="${this.sublineweight}" class="${this.sublineClass}" hideIfEmpty></pui-text>
              </pui-section>
              ${this.hideTertiaryLine ? '' : 
                `
                  <pui-text id="${this.id}-tertiaryline" input="${this.tertiaryline}" textSize="small" textColor="grey" hideIfEmpty></pui-text>
                  <pui-section id="${this.id}-tertiaryline-icon-section" flowDirection="horizontal" spacingTop="mini">
                    ${tertiarylineIcon}
                  </pui-section>
                `
              }
            `
          }
        </pui-section>
      </pui-section>
      ${lineItemButton}
      ${chevron}
    </pui-section>
    <pui-section>
      ${divider}
    </pui-section>
    `;

    if (this.url) {
      this._innerLineItem.innerHTML = `<a href="${this.url}" style="text-decoration: none">${lineItemContent}</a>`;
    } else {
      this._innerLineItem.innerHTML = `${lineItemContent}`;
    }

    this.appendChild(this._innerLineItem);
  }
}

window.customElements.define('pui-line-item', PUILineItem);
