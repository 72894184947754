import getTextSize from '../attributeValues/textSize';
import keyCodes from '../constants/keyCodes';
import includes from '../functions/includes';
import PUIBase from './pui-base';
import PUIText from './pui-text';
import { injectPUIStyles } from "../functions/domUtils";


import '../../css/flyout.scss';

/**
 * @element pui-flyout
 * 
 * ### Events
 * 
 * This element emits a `hideflyout` event when close button is clicked or anywhere else of the screen is clicked.
 */
const ESAPI = require('node-esapi').encoder();
const flyoutTemplateInnerHTML =`
  <div id="flyout">
    <div role="button" aria-haspopup="menu" aria-expanded="false" tabindex="0" id="pui-flyout-link" class="pui-flyout-link">
      <div class="pui-flyout-link-components">
        <div id="pui-flyout-ingress-button">
          <pui-text id ="ingress"fontWeight="normal" style="white-space: nowrap"></pui-text>
        </div>
        <pui-icon imgClass="chevron-down-grey-small" spacingLeft="mini"></pui-icon>
      </div>
    </div>
    <div id="pui-flyout-container" class="pui-flyout-container collapsed">
      <div id="pui-flyout-modal" class="pui-flyout-modal  pui-flyout-modal-disable-scrollbar" ">
        <button id="pui-flyout-close-button" class="pui-flyout-close-button ">
          <pui-text textColor="white" textAlign="right"></pui-text>
        </button>
        
        <div id="pui-flyout-modal-content" class=" pui-flyout-modal-content-disable-scrollbar">
          <div id="arrow-up" class="pui-nav-arrow" >
            <div class="pui-nav-arrow-inner"></div>
          </div>
          <slot>
            Flyout Inner Elements
          </slot>
        </div>
      </div>
    </div>
  </div>
`;

export default class PUIFlyout extends PUIBase {
  defaultClass: string;
  defaultText: string;
  defaultSpacingTop: string;
  defaultTextSize: string;
  defaultCloseText: string;
  scrollDisabledClass: string;
  isMobile: boolean;
  isTemplateAttached:boolean;
  flyoutTemplate: HTMLElement;
  _flyoutClickedCb:EventListener;
  _flyoutKeydownCb:EventListener;
  _closeButtonClickCb:EventListener;
  _outsideClickCb:EventListener;

  constructor() {
    super();

    this.defaultClass = 'pui-flyout';
    this.defaultText = 'Learn more';
    this.defaultTextSize = 'large';
    this.defaultSpacingTop = 'small';
    this.defaultCloseText = 'CLOSE';
    this.scrollDisabledClass = 'pui-scroll-disabled';
    this.isMobile = document.documentElement.classList.contains('a-mobile');
    this.isTemplateAttached = false;
    this.flyoutTemplate = document.createElement("div");
    this.flyoutTemplate.innerHTML = flyoutTemplateInnerHTML;
    this._flyoutClickedCb = this.flyoutClicked.bind(this);
    this._flyoutKeydownCb = this.flyoutPressed.bind(this);
    this._closeButtonClickCb = this._onCloseClick.bind(this)
    this._outsideClickCb = this._onCloseOutsideClick.bind(this);

    this.attachShadow({ mode: "open" });
    injectPUIStyles(this.shadowRoot!);
  }

  connectedCallback() {
    super.connectedCallback();
    this.render();
    this.attachEventListeners();
  }

  attributeChangedCallback() {
    super.attributeChangedCallback();
    this.render();
  }

  disconnectedCallback() {
    this.removeEventListeners();
  }

  static get observedAttributes() {
    return [...super.observedAttributes, 'text', 'textSize', 'closetext', 'minheight', 'minwidth', 'linkcolor', 'iscollapsed', 'enablescrollbar'];
  }

  /**
   * @classprop {string} text This is the attribute for the flyout link text
   *
   */
  get text() {
    return this.getAttribute('text') || '';
  }

  set text(value) {
    this.setAttribute('text', value);
  }

  /**
   * @classprop {string} closeText This is the attribute for the flyout close button text
   *
   */
  get closeText() {
    return this.getAttribute('closeText') || '';
  }

  set closeText(value) {
    this.setAttribute('closeText', this.encodedValue(value));
  }

  /**
   * @classprop {string} textSize This is the attribute for the flyout link text size
   *
   */
  get textSize() {
    return this.getAttribute('textSize') || '';
  }

  set textSize(value) {
    this.setAttribute('textSize', value);
  }
  /**
   * @classprop {string} hideClose This is the attribute for hide the close flyout button
   * 
   */
  get hideClose() {
    return this.getBooleanAttribute('hideClose');
  }

  set hideClose(value) {
    this.setBooleanAttribute('hideClose', value);
  }
  /**
   * @classprop {string} minHeight This is the attribute for the minimum height for the flyout
   * 
   */
  get minHeight() {
    return this.getAttribute('minHeight') || '';
  }

  set minHeight(value) {
    this.setAttribute('minHeight', value);
  }

  /**
   * @classprop {string} minWidth This is the attribute for the minimum width for the flyout
   * 
   */
  get minWidth() {
    return this.getAttribute('minWidth') || '';
  }

  set minWidth(value) {
    this.setAttribute('minWidth', value);
  }

  /**
   * @classprop {string} linkColor This is the attribute for the link text color
   * 
   */
  get linkColor() {
    return this.getAttribute('linkColor') || '';
  }

  set linkColor(value) {
    this.setAttribute('linkColor', value);
  }
  /**
   * @classprop {string} isCollapsed This is the attribute to see if the flyout is hidden or not
   * 
   */
  get isCollapsed() {
    return this.classList.contains('collapsed');
  }

  encodedValue(value: string) {
    return (value ? ESAPI.encoder().encodeForHTML(value) : value);
  }

  flyoutPressed(event: Event) {
    if (event instanceof KeyboardEvent && event.keyCode === keyCodes.ENTER_KEYCODE) { // Pressed Enter
      this.toggleFlyoutVisibility();
    }
  }

  flyoutClicked(event: Event) {
    const clickTarget = event.currentTarget;
    const flyoutLink = this.shadowRoot!.querySelector('.pui-flyout-link');
    if (flyoutLink!.id === (clickTarget! as HTMLElement).id) {
      this.toggleFlyoutVisibility();
    }
  }

  show() {
    //For accessibility, need to set aria-expanded value to true for screen reader
    const flyoutLink = this.shadowRoot!.querySelector('.pui-flyout-link');
    flyoutLink!.setAttribute("aria-expanded", "true");
    const flyoutContainer = this.shadowRoot!.querySelector('.pui-flyout-container');
    flyoutContainer!.classList.remove('collapsed');
  }

  hide() {
    //For accessibility, need to set aria-expanded value to false for screen reader
    const flyoutLink = this.shadowRoot!.querySelector('.pui-flyout-link');
    flyoutLink!.setAttribute("aria-expanded", "false");
    const flyoutContainer = this.shadowRoot!.querySelector('.pui-flyout-container');
    flyoutContainer!.classList.add('collapsed');
    const hideEvent = new Event('hideflyout');
    this.dispatchEvent(hideEvent);
  }

  toggleFlyoutVisibility() {
    const flyoutContainer = this.shadowRoot!.querySelector('.pui-flyout-container');
    if (flyoutContainer!.classList.contains("collapsed")) {
      this.show();
    } else {
      this.hide();
    }
  }

  _triggerCloseCallback(event: Event) {
    if (this.onclose) {
      this.onclose(event);
    }
  }

  _onCloseClick(event: Event) {
    this.hide();
    this._triggerCloseCallback(event);
  }

  _onCloseOutsideClick(event: Event) {
    const flyoutContainer = this.shadowRoot!.querySelector('.pui-flyout-container');
    if (!(event.target as HTMLElement).matches('pui-flyout') && !(event.target as HTMLElement).matches('pui-flyout *')) {
      if (!flyoutContainer!.classList.contains('collapsed')) {
        this.hide();
        this._triggerCloseCallback(event);
      }
    }
  }

  getArrowUpPosition(element: Element) {
    var positionInfo = element.getBoundingClientRect();
    return positionInfo.width + "px";
  }

  render() {
    let {
      textSize, linkColor,
    } = this;
    const {
      hideClose,
      text,
      closeText,
      minHeight,
      minWidth,
      isMShop,
    } = this;

    if (!includes(getTextSize(), this.textSize)) {
      textSize = this.defaultTextSize;
    }

    if (!this.isTemplateAttached) {
      this.shadowRoot!.appendChild(this.flyoutTemplate);
      this.isTemplateAttached = true;
    }
    (this.shadowRoot!.querySelector('#pui-flyout-close-button pui-text')! as PUIText).setTextSafe(closeText || this.defaultCloseText);
    (this.shadowRoot!.querySelector('#pui-flyout-ingress-button pui-text')! as PUIText).setTextSafe(text);
    (this.shadowRoot!.querySelector('#ingress')!).setAttribute('textSize', textSize);
    (this.shadowRoot!.querySelector('#ingress')!).setAttribute('textColor', linkColor);
    (this.shadowRoot!.querySelector('#pui-flyout-modal')!).setAttribute('style', `min-height: ${minHeight}; min-width: ${minWidth};`);
    if (hideClose) {
      (this.shadowRoot!.querySelector('#pui-flyout-close-button')!).classList.add('pui-hidden');
    }
    (this.shadowRoot!.querySelector('#pui-flyout-modal-content')!).classList.add(isMShop ? 'pui-flyout-modal-content-mshop' : 'pui-flyout-modal-content');

    (this.shadowRoot!.querySelector('#arrow-up')!).setAttribute('style', "left:" + this.getArrowUpPosition((this.shadowRoot!.querySelector('#ingress')!)));
  }

  attachEventListeners() {
    const flyoutLinkButton = this.shadowRoot!.querySelector('.pui-flyout-link');
    flyoutLinkButton!.addEventListener('click', this._flyoutClickedCb);
    flyoutLinkButton!.addEventListener('keydown', this._flyoutKeydownCb)

    const closeButton = this.shadowRoot!.querySelector('.pui-flyout-close-button');
    closeButton!.addEventListener('click', this._closeButtonClickCb);

    window.addEventListener('click', this._outsideClickCb);
  }

  removeEventListeners() {
    const flyoutLinkButton = this.shadowRoot!.querySelector('.pui-flyout-link');
    flyoutLinkButton!.removeEventListener('click', this._flyoutClickedCb);
    flyoutLinkButton!.removeEventListener('keydown', this._flyoutKeydownCb)

    const closeButton = this.shadowRoot!.querySelector('.pui-flyout-close-button');
    closeButton!.removeEventListener('click', this._closeButtonClickCb);

    window.removeEventListener('click', this._outsideClickCb);
  }
}

window.customElements.define('pui-flyout', PUIFlyout);
