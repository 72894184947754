import PUIBase from './pui-base';
import includes from '../functions/includes';
import {getRioAlertStatus} from '../attributeValues/rioAlertStatus';

export class PUIRioAlert extends PUIBase {
  private readonly defaultClass = 'pui-rio-alert';
  private readonly defaultStatus=  'success';

  constructor() {
    super();
  }

  connectedCallback() {
    super.connectedCallback();
    this.render();
  }

  static get observedAttributes() {
    return ['status'];
  }

  get status() {
    return this.getAttribute('status') || '';
  }

  set status(value) {
    this.setAttribute('status', value);
  }

  render() {
    let { status } = this;

    if (!includes(getRioAlertStatus(), this.status)) {
      status = this.defaultStatus;
    }

    const statusClass = `${status}-rio-alert`;

    this.setAttribute('tabindex', '0');

    this.classList.add(this.defaultClass);
    this.classList.add(statusClass);

    this.attachShadow({
      mode: 'open',
    });

    this.shadowRoot!.innerHTML = `
        <slot>
          Inner Elements
        </slot>
    `;
  }
}

window.customElements.define('pui-rio-alert', PUIRioAlert);
