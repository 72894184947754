let baseUrl = '';
switch (BUILD_STAGE) {
  case 'local':
  case 'test':
    // Feel free to configure this to whatever you need when building locally
    baseUrl = 'http://localhost:6006';
    break;
  case 'alpha':
    baseUrl = 'https://pharmacy-alpha.integ.amazon.com';
    break;
  case 'beta':
    baseUrl = 'https://pharmacy.integ.amazon.com';
    break;
  case 'gamma':
    baseUrl = 'https://pharmacy-preprod.iad.xcorp.amazon.com';
    break;
  case 'prod':
    baseUrl = 'https://pharmacy.amazon.com';
    break;
  default:
    baseUrl = '';
}

const Urls = {
  PHARMACY_HOME_URL: baseUrl,
  LANDING_PAGE_URL: `${baseUrl}/main`,
  HOW_IT_WORKS_URL: `${baseUrl}/how-it-works`,
  PRICING_URL: `${baseUrl}/pricing`,
  PROMISE_URL: `${baseUrl}/promise`,
  PRESCRIBERS_URL: `${baseUrl}/prescribers`,
  DASHBOARD_URL: `${baseUrl}/dashboard`,
  YOUR_ORDERS_URL: `${baseUrl}/dashboard/orders`,
  HEALTH_PROFILE_URL: `${baseUrl}/dashboard/health-profile`,
  ACCOUNT_SETTINGS_URL: `${baseUrl}/dashboard/settings`,
  HELP_URL: `${baseUrl}/help`,
  SHOP_URL: baseUrl,
  NOTIFICATIONS_URL: `/dashboard/notifications`,
  CART_URL: `${baseUrl}/cart/cartPage`,
  FEEDBACK_URL: `${baseUrl}/dashboard/shared/feedback`,
  GET_SUB_NAV_DATA_URL: `/dashboard/getSubNavData`,
  SUB_NAV_PROFILE_SWITCH: `/dashboard/subNavProfileSwitch`,
  GET_CART_COUNT_URL: `${baseUrl}/dashboard/cart/getCount`,
  SIGN_OUT_URL: `/dashboard/signOut`,
  SIGN_IN_URL: `/dashboard`,
  PEOPLE_PICKER_URL: `/signup/person-account/picker`,
  ADD_DEPENDENT_URL: `${baseUrl}/signup/start/dependent`,
  CUSTOMER_CARE_CHAT_URL: `${baseUrl}/chat/start-chat`,
  SELECT_ADDRESS_URL: `${baseUrl}/dashboard/settings/select-address`,
  MANAGE_CARE_PARTNERS_URL: `${baseUrl}/dashboard/care-partners/manage`,
  INVITE_CARE_PARTNER_URL: `${baseUrl}/dashboard/care-partners/invite`
};


export default Urls;

export const KYANITE_PREFIX = '/onemedical';