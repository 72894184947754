/**
 * PUI File object that is used by PUIFileUploadTwo.
 * It wraps File object and add extra attributes.
 * @param id - Identifier for the object
 * @param filename - Filename of the object
 * @param contentType - File content type of the object
 * @param previewLink - An external file link or file's contents (base64)
 * @param fileObject - File object (https://developer.mozilla.org/en-US/docs/Web/API/File)
 */
export default class PUIFile {
  constructor(id, filename, contentType, previewLink, fileObject) {
    this.id = id;
    this.filename = filename;
    this.contentType = contentType;
    this.previewLink = previewLink;
    this.fileObject = fileObject;
  }
}
