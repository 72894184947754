class PUIDropdownItem extends HTMLElement {
  constructor() {
    super();
    this.defaultClass = 'pui-dropdown-item';
    this.defaultSize = 'small';
  }


  static get observedAttributes() {
    return ['label', 'value'];
  }

  connectedCallback() {
    this.render();
  }

  /**
   * @classprop {string} label This is the atribute whih determines the option text
   *
   */
  get label() {
    return this.getAttribute('label') || '';
  }

  set label(value) {
    this.setAttribute('label', value);
  }

  /**
   * @classprop {string} value This is the atribute whih determines the option value
   *
   */
  get value() {
    return this.getAttribute('value') || '';
  }

  set value(value) {
    this.setAttribute('value', value);
  }

  render() {
    this.innerHTML = '';

    if (!this.value) {
      console.error('You must set a value for pui-dropdown-item');
    }

    if (!this.label) {
      console.error('You must set a label for pui-dropdown-item');
    }

    this._innerOption = document.createElement('span');
    this._innerOption.classList.add(this.defaultClass);
    this._innerOption.setAttribute('value', this.value);
    this._innerOption.innerHTML = this.label;

    this.appendChild(this._innerOption);
  }
}

window.customElements.define('pui-dropdown-item', PUIDropdownItem);
