import PUIMedicationsPage from './medications/pui-medications-page';
import PUIMedPicker from './medications/pui-med-picker';
import PUIMedSearch from './medications/pui-med-search';
import PUIMedConfiguratorPage from './medications/pui-med-configurator-page';
import PUIPrescriberPage from './prescriber/pui-prescriber-page';
import PUIPharmacyPage from './pharmacy/pui-pharmacy-page';
import PUIPharmacySearchPage from './pharmacy/pui-pharmacy-search-page';
import PUIFeedbackPage from './feedback/pui-feedback-page';
import PUIInsurancePage from './insurance/pui-insurance-page';
import PUIErrorPage from './error/pui-error-page';
import PUIDiscountCardPage from './primerx/pui-discount-card-page';
import PUIBluesRxDetailPage from './discount-cards/pui-bluesrx-card-page';
import PUIAllergiesPage from './allergies/pui-allergies-page';
import PUIHealthConditionsPage from './health-conditions/pui-health-conditions-page';
import PUIProceduresPage from './procedures/pui-procedures-page';
import PUIProcedureDatePickerPage from './procedures/pui-procedure-date-picker-page';

export default class PUIComponentLoader extends HTMLElement {
  load(componentName, config) {
    switch (componentName) {
      case 'pui-medications-page':
        const medPage = new PUIMedicationsPage();
        medPage.configure(config);
        return medPage;
      case 'pui-med-picker':
        const medPicker = new PUIMedPicker();
        medPicker.configure(config);
        return medPicker;
      case 'pui-med-search':
        const medSearch = new PUIMedSearch();
        medSearch.configure(config);
        return medSearch;
      case 'pui-med-configurator-page':
        const medConfigPage = new PUIMedConfiguratorPage();
        medConfigPage.configure(config);
        return medConfigPage;
      case 'pui-prescriber-page':
        const prescriberPage = new PUIPrescriberPage();
        prescriberPage.configure(config);
        return prescriberPage;
      case 'pui-pharmacy-page':
        const pharmacyPage = new PUIPharmacyPage();
        pharmacyPage.configure(config);
        return pharmacyPage;
      case 'pui-pharmacy-search-page':
        const pharmacySearchPage = new PUIPharmacySearchPage();
        pharmacySearchPage.configure(config);
        return pharmacySearchPage;
      case 'pui-insurance-page':
        const insurancePage = new PUIInsurancePage();
        insurancePage.configure(config);
        return insurancePage;
      case 'pui-error-page':
        const errorPage = new PUIErrorPage();
        errorPage.configure(config);
        return errorPage;
      case 'pui-feedback-page':
        const feedbackPage = new PUIFeedbackPage();
        feedbackPage.configure(config);
        return feedbackPage;
      case 'pui-discount-card-page':
        const discountCardPage = new PUIDiscountCardPage();
        discountCardPage.configure(config);
        return discountCardPage;
      case 'pui-bluesrx-card-page':
        const bluesrxCardPage = new PUIBluesRxDetailPage();
        bluesrxCardPage.configure(config);
        return bluesrxCardPage;
      case 'pui-allergies-page':
        const allergiesPage = new PUIAllergiesPage();
        allergiesPage.configure(config);
        return allergiesPage;
      case 'pui-health-conditions-page':
        const healthConditionsPage = new PUIHealthConditionsPage();
        healthConditionsPage.configure(config);
        return healthConditionsPage;
      case 'pui-procedures-page':
        const proceduresPage = new PUIProceduresPage();
        proceduresPage.configure(config);
        return proceduresPage;
      case 'pui-procedure-date-picker-page':
        const procedureDatePickerPage = new PUIProcedureDatePickerPage();
        procedureDatePickerPage.configure(config);
        return procedureDatePickerPage;
      default:
        console.warn('No component defined in pui-component-loader for ', componentName, 'with config', config);
          // Do nothing
    }
  }
}

window.customElements.define('pui-component-loader', PUIComponentLoader);
