import PUIBase from './pui-base';

class PUIDividerLabel extends PUIBase {
    constructor() {
        super();
        this.defaultClass = 'pui-divider-label';
    }

    connectedCallback() {
        super.connectedCallback();
        this.render();
    }

    static get observedAttributes() { return ['label']; }

    get label() {
        return this.getAttribute('label') || '';
    }

    set label(value) {
        this.setAttribute('label', value);
    }

    render() {
        let {
            label
        } = this;

        this.innerHTML = `
            <pui-divider></pui-divider>
            <span class="pui-divider-label-contents">${label}</span>
        `;

        this.classList.add('pui-block');
        this.classList.add(this.defaultClass);
    }
}

window.customElements.define('pui-divider-label', PUIDividerLabel);
