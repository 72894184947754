import PUIBase from './pui-base';
import PUIText from './pui-text';
import keyCodes from '../constants/keyCodes';

class PUIDropdown extends PUIBase {
  /*
   * Uses and unordered list <ul> to display a list of selectable items.
   */

  constructor() {
    super();
    this.defaultClass = 'pui-dropdown';
    this.defaultInputType = 'text';
    this.ddListOpen = false;
    this.optionsCleared = false;
  }

  static get observedAttributes() {
    return ['dropdowndata', 'label', 'placeholder', 'textInputType', 'status', 'value', 'name', 'elementValue'];
  }

  connectedCallback() {
    super.connectedCallback();
    if (!this.optionsCleared) {
      this.render();
    }
  }

  attributeChangedCallback(attribute, oldValue, newValue) {
    if (oldValue !== newValue) {
      if (attribute === 'dropdowndata') {
        this.dropdowndata = newValue;
      }
      this.render();
    }
  }

  get dropdowndata() {
    return this._dropdowndata;
  }

  set dropdowndata(value) {
    this._dropdowndata = value;
    this.render();
  }

  get label() {
    return this.getAttribute('label') || '';
  }

  set label(value) {
    this.setAttribute('label', value);
  }

  get placeholder() {
    return this.getAttribute('placeholder') || '';
  }

  set placeholder(value) {
    this.setAttribute('placeholder', value);
  }

  get textInputType() {
    return this.getAttribute('textInputType') || '';
  }

  set textInputType(value) {
    this.setAttribute('textInputType', value);
  }

  get status() {
    return this.getAttribute('status') || '';
  }

  set status(value) {
    this.setAttribute('status', value);
  }

  get value() {
    return this.getAttribute('value') || '';
  }

  set value(value) {
    this.setAttribute('value', value);
  }

  get elementValue() {
    return this.getAttribute('elementValue') || '';
  }

  set elementValue(value) {
    this.setAttribute('elementValue', value);
  }

  getElementValue() {
    return this.elementValue;
  }

  elementPressed(event) {
    if (event.keyCode === keyCodes.ENTER_KEYCODE) { // Pressed Enter
      this.elementClicked(event);
    }
  }

  elementClicked(event) {
    const elementContainer = this.querySelector(`.${this.defaultClass}`);
    if (elementContainer) {
      if (event.target.className !== 'pui-dropdown-list-item') {
        if (this.contains(event.target)) {
          elementContainer.classList.remove('dropdown-error');
          elementContainer.classList.add('dropdown-selected');
          this.ddListOpen = false;
          this.toggleDropDownList();
        } else {
          elementContainer.classList.remove('dropdown-selected');
          this.ddListOpen = true;
          this.toggleDropDownList();
        }
      }
    }
  }

  toggleDropDownList() {
    const ddList = this.querySelector('.pui-dropdown-list');
    if (!this.ddListOpen) {
      this.ddListOpen = true;
      ddList.classList.remove('pui-dropdown-collapsed');
    } else {
      this.ddListOpen = false;
      ddList.classList.add('pui-dropdown-collapsed');
    }
  }


  setDropdownSelection(selector, selection, placeholder = null, value = null) {
    if (!selector) {
      return;
    }
    if (selector.options.length > 0) {
      selector.options[0] = null;
    }
    const option = document.createElement('option');
    if (selection) {
      // Set dropdown option based off user selection
      selection.innerText = this.truncateText(selection.innerText);
      selector.classList.remove('dropdown-placeholder');
      option.text = selection.innerText;
      option.value = selection.attributes[1].value;
      this.ddListOpen = true;
      this.toggleDropDownList();
    } else {
      // Set initial dropdown state
      option.text = placeholder || '';
      selector.classList.add(placeholder ? 'dropdown-placeholder' : '');
      option.value = value || '';
      option.setAttribute('selected', true);
    }
    selector.options.add(option);
    selector.setAttribute('value', option.value);
  }

  truncateText(str) {
    const element = this.getElementsByClassName(this.defaultClass)[0];
    const width = element.offsetWidth;
    const stringLength = Math.ceil(width / 11);
    const ending = '...';
    if (str.length > stringLength) {
      return str.substring(0, stringLength - ending.length) + ending;
    }
    return str;
  }

  render() {
    const self = this;
    const innerElements = this.innerHTML;
    const parser = new DOMParser();
    let optionList = [];

    if (this._dropdowndata) {
      let dropdownObjects = null;
      if (typeof this._dropdowndata === 'string') {
        dropdownObjects = JSON.parse(this._dropdowndata);
      } else {
        dropdownObjects = this._dropdowndata;
      }
      if (dropdownObjects) {
        optionList = dropdownObjects.map((element) => {
          const option = document.createElement('option');
          option.text = element.label;
          option.value = element.value;
          return option;
        });
      }
    } else {
      const parsedHtml = parser.parseFromString(innerElements, 'text/html');
      const elementList = parsedHtml.getElementsByTagName('pui-dropdown-item');
      for (let i = 0; i < elementList.length; i += 1) {
        const option = document.createElement('option');
        option.text = elementList[i].getAttribute('label');
        option.value = elementList[i].getAttribute('value');
        optionList.push(option);
      }

      if (optionList.length > 0) {
        this.optionsCleared = true;
      }
    }

    this.innerHTML = '';

    const {
      label,
      placeholder,
      name,
      value,
      spacingTop,
    } = this;

    let {
      textInputType,
      status,
    } = this;


    if (status) {
      status = `input-${status}`;
    }

    if (!textInputType) {
      textInputType = this.defaultInputType;
    }

    let labelHidden = '';
    if (label === '') {
      labelHidden = 'pui-input-label-hidden';
    }

    this._innerDropdown = document.createElement('span');
    this._innerDropdown.classList.add(this.defaultClass);
    this._innerDropdown.classList.add(`${spacingTop}-margin-top`);
    if (status) {
      this._innerDropdown.classList.add(status);
    }

    const dropdownLabel = new PUIText();
    dropdownLabel.textSize = 'mini';
    dropdownLabel.input = label;
    if (labelHidden) {
      dropdownLabel.hide();
    }

    const dropdownSelector = document.createElement('select');
    dropdownSelector.classList.add('pui-dropdown-selector');
    dropdownSelector.setAttribute('name', name);
    dropdownSelector.setAttribute('tabindex', '-1');
    if (this.setDropdownSelection()) {
      this.setDropdownSelection(dropdownSelector, null, placeholder, value);
    }

    this._innerDropdownList = document.createElement('span');
    this._innerDropdownList.classList.add('pui-dropdown-list');
    this._innerDropdownList.classList.add('pui-dropdown-collapsed');
    const ul = document.createElement('ul');
    ul.classList.add('pui-dropdown-unordered-list');
    ul.setAttribute('style', 'margin: 0px');

    // Create all list items
    optionList.map((element) => {
      const li = document.createElement('li');
      li.classList.add('pui-dropdown-list-item');
      const text = document.createTextNode(element.text);
      li.appendChild(text);
      li.setAttribute('value', element.value);
      li.setAttribute('style', 'list-style-type: none');
      li.setAttribute('tabindex', '0');
      li.addEventListener('click', (e) => {
        self.setDropdownSelection(dropdownSelector, e.target);
      });
      li.addEventListener('keyup', (e) => {
        if (e.key === 'Enter') {
          self.setDropdownSelection(dropdownSelector, e.target);
        }
      });
      // append new list item to the ul
      ul.appendChild(li);
    });

    this._innerDropdownList.appendChild(ul);
    this._innerDropdown.appendChild(dropdownLabel);
    this._innerDropdown.appendChild(dropdownSelector);

    this.appendChild(this._innerDropdown);
    this.appendChild(this._innerDropdownList);

    document.body.addEventListener('click', this.elementClicked.bind(this));
    document.body.addEventListener('keyup', this.elementPressed.bind(this));
    this.setAttribute('tabindex', '0');
    this.setAttribute('role', 'button');
  }
}

window.customElements.define('pui-dropdown', PUIDropdown);
