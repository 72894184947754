import PUIBase from './pui-base';

// eslint-disable-next-line import/no-unresolved
import listItemCSS from '../../css/_stack-item-button.scss?inline';

/**
 * A button component for the pui-stack that includes the right-chevron.
 *
 * It has a single, default slot placed within the button to enable adding a label.
 *
 * You can make the button bigger by changing the buttonPadding attribute.
 * By default, it is medium, which covers the predominate usage throughout PUI.
 * This buttonPadding is in addition to whatever content you provide.
 *
 * You can make the button's label bigger by changing the textSize attribute.
 * By default, it is medium, which covers the predominate usage throughout PUI.
 *
 * The button fully spans the horizontal and vertical space of the slot,
 * which provides a large click target for mice, and fingers.
 *
 * Attach the click handler to this component directly as the button's click event will bubble up here.
 * This means the chevron will appear to be part of the button.
 *
 * See the pui-stack for an example.
 *
 * Note: pui-icon's are actually background images and thus cannot center automatically.
 * You may need to make accomodations here to better align the icon with your content
 * by introducing a new attribute to control the spacingTop (margin-top) attribute on the pui-icon.
 *
 * Further, since pui-icon's are not text, it is not possible to exactly baseline or midline align
 * the icon with your text. A 3px delta is typical causing the chevron to appear slightly higher than the middle.
 */
export default class PUIStackItemButton extends PUIBase {
  /**
   * Specifies if the contents of the pui-stack-item-button will overflow. By setting this to "true", the right chevron icon will be centered vertically.
   * @type {"true"|"false"}
   * @attr
   */
  get overflow() {
    return this.getAttribute('overflow') || 'false';
  }

  set overflow(value) {
    this.setAttribute('overflow', value);
  }

  /**
   * Specifies the padding around the stack item content. This will be defaulted to "medium"
   * @type
   * @attr
   */
  get buttonPadding() {
    return this.getAttribute('buttonPadding') || 'medium';
  }

  set buttonPadding(size) {
    this.setAttribute('buttonPadding', size);
  }

  /**
   * Specifies the size of the text in the stack item content. This will be defaulted to "medium"
   * @type
   * @attr
   */
  get textSize() {
    return this.getAttribute('textSize') || 'medium';
  }

  set textSize(size) {
    this.setAttribute('textSize', size);
  }

  constructor() {
    super();
  }

  connectedCallback() {
    super.connectedCallback();
    this.setAttribute('role', 'listitem');
    this.upgradeProperty('onclick');

    this._setupComponent();
  }

  _setupComponent() {
    const secondaryAxisArrangement = this.overflow === 'true' ? 'center' : 'stretch';
    const spacingTop = this.overflow === 'true' ? '' : 'spacingTop="medium"';
    const templateHTML = `
      <style>
        ${listItemCSS}
      </style>
      <pui-section 
        class="pui-stack-item"
        flowDirection="horizontal" 
        mainAxisArrangement="space-between" 
        secondaryAxisArrangement="${secondaryAxisArrangement}"
        >
          <pui-section-column>
            <button class="${this.textSize}-font ${this.buttonPadding}-padding-all">
              <slot></slot>
            </button>
          </pui-section-column>
          
          <pui-section-column>
            <pui-icon imgClass='chevron-right' spacingRight="medium" ${spacingTop}></pui-icon>
          </pui-section-column>  
      </pui-section>
    `;
    const shadowRoot = this.attachShadow({
      mode: 'open',
    });
    const template = document.createElement('template');
    template.innerHTML = templateHTML;
    shadowRoot.appendChild(template.content.cloneNode(true));
  }
}

window.customElements.define('pui-stack-item-button', PUIStackItemButton);
