import PUIBase from './pui-base';

class PUIIconButton extends PUIBase {
  static get observedAttributes() { return ['icon', 'elementValue', 'status']; }

  constructor() {
    super();
  }

  connectedCallback() {
    super.connectedCallback();
    this.render();
  }

  /**
     * @classprop {string} icon This is the icon path ---- TODO: add icons and icon paths
     *
     */
  get icon() {
    return this.getAttribute('icon') || '';
  }

  set icon(value) {
    this.setAttribute('icon', value);
  }

  get status() {
    return this.getAttribute('status') || '';
  }

  set status(value) {
    this.setAttribute('status', value);
  }

  render() {
    this.innerHTML = '';
    const { status } = this;

    const statusClass = status === 'selected-icon-button' ? 'selected-icon-button-icon' : '';
    this._innerPersonPickerElement = document.createElement('div');
    this._innerPersonPickerElement.innerHTML = `
        <button class="pui-icon-button ${status}">
            <pui-icon class="${this.icon} pui-icon-button-icon ${statusClass}">
            </pui-icon>
        </button>
    `;
    this.appendChild(this._innerPersonPickerElement);
  }
}

customElements.define('pui-icon-button', PUIIconButton);
