import * as shadyCss from '@webcomponents/shadycss';
import PUIBase from './pui-base';
import PUIInput from './pui-input';
import PUIBottomSheet from './pui-bottom-sheet';
import keyCodes from '../constants/keyCodes';

/* eslint-disable import/no-unresolved */
import baseCss from '../../css/_base.scss?inline';
import sectionCss from '../../css/_section.scss?inline';
import inputCss from '../../css/_input.scss?inline';
import textCss from '../../css/_text.scss?inline';
import dropdownCss from '../../css/_dropdown-two.scss?inline';
/* eslint-enable import/no-unresolved */

const innerTemplate = `
  <style>
    ${baseCss}
    ${sectionCss}
    ${inputCss}
    ${textCss}
    ${dropdownCss}
  </style>
`;

const template = document.createElement('template');

template.innerHTML = innerTemplate;

shadyCss.prepareTemplate(template, 'pui-dropdown-two');

export class PUIDropdownTwo extends PUIBase {
  constructor() {
    super();

    shadyCss.styleElement(this);
    if (!this.shadowRoot) {
      this.attachShadow({ mode: 'open' });
      this.shadowRoot.appendChild(
        document.importNode(template.content, true),
      );
    }
  }

  static get observedAttributes() {
    return ['selectedtext', 'value', 'enablescrollbar', 'modalAriaLabel', 'disabled'];
  }

  attributeChangedCallback() {
    if (this._visibleInput) {
      this._visibleInput.setValue(this.selectedText);
    }
  }

  connectedCallback() {
    super.connectedCallback();
    if (this.embeddable) {
      // Create full page options list
      this._createEmbeddable();
    } else {
    // Create bottom sheet with options list
      this._createBottomSheet();
    }
    this._setupComponent();
    this.classList.add('pui-block');
    this._displayedInput = new PUIInput();
    this._displayedInput.classList.add('visible');
    this._displayedInput.classList.add('small-margin-top');
    this._displayedInput.classList.add('pui-dropdown-two');
    if (!this._shouldDisableShowOptions()) {
        this._displayedInput.setAttribute('iconRightClass', 'dropdown-icon');
    }
    if (this._shouldDisableDropDown() || this._shouldDisableShowOptions()) {
        this._displayedInput.setAttribute('status', 'disabled');
    }
    this._displayedInput.setAttribute('disabled', 'true');
    this._displayedInput.setAttribute('tabindex', '0');
    this._displayedInput.setAttribute('noTabbing', 'true'); // the inner 'input' not tabable
    if (this.label) {
      this._displayedInput.setAttribute('label', this.label);
    }

    if (this.selectedText) {
      this._displayedInput.setAttribute('value', this.selectedText);
    }

    if (this.placeholder) {
      this._displayedInput.setAttribute('placeholder', this.placeholder);
    }

    this._displayedInput.setAttribute('spacingTop', this.spacingTop);
    this.shadowRoot.insertBefore(this._displayedInput, this.shadowRoot.childNodes[0]);

    this._visibleInput = this.shadowRoot.querySelector('pui-input.visible');

    this.addEventListener('click', this._handleDropdownClick.bind(this));
    this._displayedInput.addEventListener('keyup', this._handleDropdownPress.bind(this));
  }

  reset() {
    this.selectedText = '';
    this.value = '';
    this.data = '';
  }

  get label() {
    return this.getAttribute('label') || '';
  }

  set label(value) {
    this.setAttribute('label', value);
  }

  get value() {
    return this.getAttribute('value') || '';
  }

  set value(value) {
    this.setAttribute('value', value);
  }

  get selectedText() {
    return this.getAttribute('selectedText') || '';
  }

  set selectedText(value) {
    this.setAttribute('selectedText', value);
    this._visibleInput.setValue(value);
  }

  get placeholder() {
    return this.getAttribute('placeholder') || '';
  }

  set placeholder(value) {
    this.setAttribute('placeholder', value);
  }

  /**
   * Whether to hide the options bottom sheet when there is only a single option to pick. If more than 1 options, this will be ignored.
   */
  get disableShowOptionsIfSingle() {
    return this.getBooleanAttribute('disableShowOptionsIfSingle');
  }

  set disableShowOptionsIfSingle(value) {
    this.setBooleanAttribute('disableShowOptionsIfSingle', value);
  }

  get embeddable() {
    return this.getBooleanAttribute('embeddable');
  }

  set embeddable(value) {
    this.setBooleanAttribute('embeddable', value);
  }

  set enableScrollbar(value) {
    this.setBooleanAttribute('enableScrollbar', value);
  }

  get enableScrollbar() {
    return this.getBooleanAttribute('enableScrollbar');
  }

  get disabled() {
    return this.getBooleanAttribute('disabled');
  }

  set disabled(value) {
    this.setBooleanAttribute('disabled', value);
  }
  
  /**
   *  The aria-label for dropdown model
   * @type {string}
   * @attr
   *
   */
  get modalAriaLabel() {
    return this.getAttribute('modalAriaLabel') || this.label || "";
  }

  set modalAriaLabel(value) {
    this.setAttribute('modalAriaLabel', value);
  }

  _createEmbeddable() {
    this._optionsPage = document.createElement('div');
    this._optionsPage.classList.add('pui-fill-modal');
    this._optionsPage.classList.add('pui-hidden');

    // Wrap the options page around the slot
    this.shadowRoot.appendChild(this._optionsPage);
    Array.from(this.childNodes).forEach(e => this._optionsPage.appendChild(e));
  }

  _createBottomSheet() {
    this._bottomSheet = new PUIBottomSheet();
    this._bottomSheet.enableScrollbar = this.enableScrollbar;
    this._bottomSheet.contentAriaLabel = this.modalAriaLabel;
    this._bottomSheet.contentAriaRole = "listbox"; // dropdown is always a listbox
    // kill the close event so that we don't re-recieve it in our onClick
    // event listener
    this._bottomSheet.onClose = (event) => event.stopPropagation();

    // Wrap the bottom sheet around options list
    this.shadowRoot.appendChild(this._bottomSheet);
    Array.from(this.childNodes).forEach(e => this._bottomSheet.appendChild(e));
  }

  _setupComponent() {
    window.addEventListener('DOMContentLoaded', this._addItemSelectEventHandlers.bind(this));
    if (document.readyState === 'complete' || document.readyState === 'loaded') {
      this._addItemSelectEventHandlers();
    }
  }

  _addItemSelectEventHandlers() {
    this._checkIfOptionsUpdated();
    Array.from(this.shadowRoot.querySelectorAll('pui-dropdown-item-two'))
      .forEach((dropdownItem) => {
        dropdownItem.addEventListener('click', this._handleDropdownItemSelect.bind(this));
        dropdownItem.addEventListener('keyup', this._handleDropdownItemPress.bind(this));
      });
  }

  _handleDropdownPress(event) {
    if (event.keyCode === keyCodes.ENTER_KEYCODE) { // Pressed Enter
      this._handleDropdownClick();
    }
  }

  _handleDropdownClick() {
    if (!this._shouldDisableShowOptions() && !this._shouldDisableDropDown()) {
      this._showOptions();
    }
  }

  _handleDropdownItemPress(event) {
    if (event.keyCode === keyCodes.ENTER_KEYCODE) { // Pressed Enter
      this._handleDropdownItemSelect(event);
    }
  }

  _checkIfOptionsUpdated(){
    // All `dropdown-item`s should be inside bottomSheet, move if any found under parent.
    if(this.childNodes?.length!==0) {
      Array.from(this.childNodes).forEach(e => this._bottomSheet.appendChild(e));
    }
  }

  _handleDropdownItemSelect(event) {
    const selectedDropdownItem = event.target;
    const {
      type,
      label,
      value,
      data,
    } = selectedDropdownItem;
    if (type === 'option') {
      this.selectedText = label;
      this.value = value;
      this.data = data;
    } else if (type === 'cancel') {
      // No op
    }
    // stop the mouse event from propagating to keep the options from re-opening
    event.stopPropagation();
    this._hideOptions();
    if (this.onSelect) {
      this.onSelect(this.value);
    }
  }

  _showOptions() {
    this._checkSelected();
    if (this.embeddable) {
      this._optionsPage.classList.remove('pui-hidden');
    } else {
      this._bottomSheet.show();
    }
  }

  _hideOptions() {
    if (this.embeddable) {
      this._optionsPage.classList.add('pui-hidden');
    } else {
      this._bottomSheet.hide();
    }
  }

  _checkSelected() {
    Array.from(this.shadowRoot.querySelectorAll('pui-dropdown-item-two'))
      .forEach((dropdownItem) => {
        dropdownItem.checkWhenSelected(this.value);
      });
  }

  _shouldDisableShowOptions() {
    return this.shadowRoot.querySelectorAll('pui-dropdown-item-two').length === 1 && this.disableShowOptionsIfSingle;
  }

  _shouldDisableDropDown() {
    return this.disabled;
  }
}

window.customElements.define('pui-dropdown-two', PUIDropdownTwo);
