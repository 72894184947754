import PUIBase from '../pui-base';
import PUIMedConfigurator from './pui-med-configurator';
import convertToMedication from './pui-medication-converter';

/**
 * This component is responsible for allowing a user to search for medications, choosing a strength, and then adding
 * this medication.  When a user types into the search input field, this component will call out to Elastic Search using
 * the 'searchUrl'.  After a search option is selected, it will appear in the 'apex-selected-search-result-container'
 * with the ability to choose strength, add, and cancel. Clicking on 'Add' will call the 'onMedicationAdd' callback
 * function.  This callback function should be implemented by the component using PUIMedSearch to determine what to
 * do with a medication after it has been selected to be added.
 *
 * Callbacks:
 *
 * Callback for when the medication is selected/added
 * @callback onMedicationAdd
 * @param {Medication} medication - Medication item passed into the onMedicationAdd callback function
 * @param {string} medication.name - Name of the search/configured medication
 * @param {string} medication.groupingId Id used to identify the ES document that represents this medication and
 * its sibling options
 * @param {string} medication.rxcui rxcui for the configured medication
 * @param {string} medication.gsddMarketedProductId gsddMarketedProductId for configured medication
 * @param {string} medication.rxcuiTty term type for the configured medication
 *
 * Configuration:
 *
 * @param {PUIMedSearchConfig} this.config - The configuration for PUIMedSearch
 * @param {string} this.config.searchUrl - The URL to use to initiate the medication search
 * @param {string} this.config.searchRequestMethod - The request method type for search request, can be 'GET' or 'POST', default is 'POST'
 * @param {string} this.config.environment - Specify if this is for react environment or horizonte
 * @param {string} this.config.searchQueryDelay - Specify the delay in milliseconds before initiating a search query
 * @param {boolean} this.config.onlyShowSellableDrugs - If true, will only show search results that are sold by AP
 * @param {boolean} this.config.displayUnknownOption - The boolean for displaying the "I don't know" option
 * @param {string} this.config.strings.searchInputTitle - The title text for the search input
 * @param {string} this.config.strings.searchInputPlaceholder - The placeholder text of the search input
 * @param {string} this.config.strings.searchResultGeneric - Label to show the search item is generic
 * @param {string} this.config.strings.noResultsFound - The text that displays when no results found
 * @param {string} this.config.strings.cancelButton - The cancel button text
 * @param {string} this.config.strings.addButton - The add button text
 * @param {string} this.config.strings.strengthSelectLabel - The label for the strength select input
 * @param {string} this.config.strings.dontKnowOption - The string for the don't know option
 * @param {string} this.config.strings.strengthSelectCancel - The string for the cancel option on strength selection
 *
 * @param {string} this.config.strings.configuratorTitle - The title for this configurator
 * @param {string} this.config.strings.packagingQuestion - The question asking which dosage form to select
 * @param {string} this.config.strings.strengthQuestion - The question asking which strength to select
 * @param {string} this.config.strings.completeConfigurationButton - The string for the configurator finish button
 * @param {string} this.config.strings.configurationCancel - The string for cancelling configurator
 * @param {onMedicationAdd} this.config.callbacks.onMedicationAdd - The callback for when a medication has been selected
 */
export default class PUIMedSearch extends PUIBase {
  connectedCallback() {
    super.connectedCallback();
    this.upgradeProperty('onSearchAdd');
    this._render();
  }

  _render() {
    const {
      strings,
      searchQueryDelay,
      searchRequestMethod
    } = this.config;
    this.classList.add('pui-block');
    this.innerHTML = `
      <pui-search-input id="apex-medication-search-input" 
                        name="medicationSearchInput" 
                        placeholder="${strings.searchInputPlaceholder}" 
                        label="${strings.searchInputTitle}"
                        searchQueryDelay="${searchQueryDelay}" 
                        noResultsText="${strings.noResultsFound}" 
                        searchRequestMethod="${searchRequestMethod || ''}"
                        style="margin-bottom: 8px">
      </pui-search-input>
    `;

    this._searchInput = this.querySelector('#apex-medication-search-input');

    this._searchInput.queryUrlBuilder = this._queryUrlBuilder.bind(this);
    this._searchInput.querySuccessCb = this._querySuccessCb.bind(this);
    this._searchInput.onSearchResultClick = this._onSearchResultClick.bind(this);
  }


  /**
     * this function is invoked by the pui-search-input to build the query url
     * @param dataSearchUrl
     * @param medicationSearchInputValue
     * @returns {string}
     * @private
     */
  _queryUrlBuilder(dataSearchUrl, medicationSearchInputValue) {
    // ignore the dataSearchUrl and instead use this.searchUrl, as pui-search-input is not been given the
    // search url input
    const onlyShowSellableDrugs = this.config.onlyShowSellableDrugs ? 1 : 0;
    return `${this.config.searchUrl}?searchTerm=${medicationSearchInputValue}&onlyShowSellableDrugs=${onlyShowSellableDrugs}`;
  }

  /**
     * This function is invoked by the pui-search-input to handle the success response of the search query
     * @param response
     * @private
     */
  _querySuccessCb(response) {
    const medications = response.results;
    const searchResults = [];
    medications.forEach((medication) => {
      let medicationName = medication.displayName;
      if (medication.medicationResultTermType === 'IN' || medication.medicationResultTermType === 'MIN') {
        medicationName += ` ${this.config.strings.searchResultGeneric}`;
      }
      const nameLabel = `<pui-text input="${medicationName}"></pui-text>`;
      const searchResult = {
        html: nameLabel,
        ...medication,
      };
      searchResults.push(searchResult);
    });
    this._searchInput.updateSearchResults(searchResults);
  }

  /**
   *
   * @param {SearchMedication} medication - The medication returned from Elastic Search
   * @param {string} medication.name - Usually will be the medication name + dosage form
   * @param {Strengths} medication.strengths - A map between strength name and strength object
   * @param {Strength} strengths[strength] - Contains values that uniqely identify this strength selection
   * @param {string} strength.gsddMarketedProductId - The GSDD Marketed Product Id
   * @param {string} strength.rxnormGroupingRxcui - grouping rxcui used as our 'groupingId'
   * @param {string} strength.rxnormStrength - The name for this strength
   * @param {string} strength.rxnormRxcui - The RXCUI for this strength
   */
  _onSearchResultClick(medication) {
    this._onConfigurationComplete(medication);
  }

  _onConfigurationComplete(searchResult) {
    const { onMedicationAdd } = this.config.callbacks;
    if (onMedicationAdd) {
      onMedicationAdd(searchResult);
    }
  }
}

window.customElements.define('pui-med-search', PUIMedSearch);
