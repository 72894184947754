import PUIBase from './pui-base';

const properties = ['width', 'height', 'borderRadius'];

/**
 * Implements a skeleton/ghost loading UX gradient bar or circle. The caller can customize the width, height, and border
 * radius to match the real UX that is loading.
 */
class PUILoadingGradient extends PUIBase {
  constructor() {
    super();
    this.defaultClass = 'pui-loading-gradient';

    this.upgradeProperty = this.upgradeProperty.bind(this);
  }

  static get observedAttributes() {
    return properties;
  }

  connectedCallback() {
    super.connectedCallback();
    properties.forEach(this.upgradeProperty);

    const { width, height, borderRadius } = this;

    if (width) {
      this.addToAttribute('style', `width: ${width}`);
    }

    if (height) {
      this.addToAttribute('style', `height: ${height}`);
    }

    /**
     * If border radius is not set, the default border radius on the class style is used.
     */
    if (borderRadius) {
      this.addToAttribute('style', `border-radius: ${borderRadius}`);
    }

    this.render();
  }

  get width() {
    return this.getAttribute('width') || '';
  }

  set width(value) {
    this.setAttribute('width', value);
  }

  get height() {
    return this.getAttribute('height') || '';
  }

  set height(value) {
    this.setAttribute('height', value);
  }

  get borderRadius() {
    return this.getAttribute('borderRadius') || '';
  }

  set borderRadius(value) {
    this.setAttribute('borderRadius', value);
  }

  render() {
    this.innerHTML = '';
    this.classList.add('pui-loading-gradient');
  }
}

window.customElements.define('pui-loading-gradient', PUILoadingGradient);
