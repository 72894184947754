// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../icons/dropdown_arrows.svg";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".pui-dropdown-two{cursor:pointer}.dropdown-icon{display:block;width:30px;height:30px;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:no-repeat;background-size:30px 30px;cursor:pointer}.hidden{display:none !important}.pui-dropdown-two input:disabled{color:#000;opacity:1 !important}", "",{"version":3,"sources":["webpack://./src/css/_dropdown-two.scss"],"names":[],"mappings":"AAgBA,kBACE,cAAA,CAGF,eACE,aAAA,CACA,UAAA,CACA,WAAA,CACA,wDAAA,CACA,2BAAA,CACA,yBAAA,CACA,cAAA,CAGF,QACE,uBAAA,CAGF,iCACE,UAAA,CACA,oBAAA","sourcesContent":["// Amazon Pharmacy\n$theme-postfix: '';\n$primary-color: #0C8281;\n$primary-color-gradient-0: #0D8583;\n$primary-color-gradient-1: #159B8E;\n$primary-color-faded: #E8F6ED;\n$primary-color-faded-1: #E8F6ED;\n$primary-amazon-link-text-color: #0066C0;\n$primary-amazon-color: #feb447; \n$link-text-color: $primary-color;\n$pui-sub-nav-sign-up-background-color: linear-gradient(180deg, $primary-color 0%, $primary-color-gradient-0 70.26%, $primary-color-gradient-1 100%);\n$drop-down-item-text-color: $primary-color;\n$body-signup-background-color: $primary-color-gradient-1;\n\n@import 'common_theme.scss';\n\n.pui-dropdown-two {\n  cursor: pointer;\n}\n\n.dropdown-icon {\n  display: block;\n  width: 30px;\n  height: 30px;\n  background-image: url('../icons/dropdown_arrows.svg');\n  background-repeat: no-repeat;\n  background-size: 30px 30px;\n  cursor: pointer;\n}\n\n.hidden {\n  display: none !important;\n}\n\n.pui-dropdown-two input:disabled {\n  color: #000000;\n  opacity: 1.0 !important;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
