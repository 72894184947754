import PUIBase from '../pui-base';

/**
 * This component is responsible for showing information that will help a customer
 * to manually add an insurance
 *
 * Configuration:
 *
 * @param {PUIInsuranceInfo} this.config - The configuration for PUIInsuranceInfo
 * @param {string} this.config.strings.title - Title for the insurance info page
 * @param {string} this.config.strings.cancelButton - The text for the cancel button
 * @param {string} this.config.strings.helpfulTipsHeader - The subheader for the helpful tips section
 * @param {string} this.config.strings.helpfulTipOne - The first tip
 * @param {string} this.config.strings.helpfulTipTwo - The second tip
 * @param {string} this.config.strings.helpfulTipThree - The third tip
 * @param {string} this.config.strings.helpfulTipFour - The fourth tip
 * @param {string} this.config.strings.helpfulTipFive - The fifth tip
 * @param {string} this.config.strings.insuranceInfo.helpfulTipSix - The sixth tip
 * @param {string} this.config.strings.insuranceInfo.helpfulTipSeven - The seventh tip
 * @param {string} this.config.strings.insuranceInfo.helpfulTipEight - the eighth tip
 * @param {string} this.config.strings.insuranceInfo.helpfulTipNine - the ninth tip
 * @param {string} this.config.strings.insuranceInfo.helpfulTipTen - the tenth tip
 *
 */
export default class PUIInsuranceInfo extends PUIBase {
  connectedCallback() {
    super.connectedCallback();
    this._render();
  }

  close() {
    this.parentNode.removeChild(this);
  }

  _render() {
    const {
      strings,
    } = this.config;

    this.classList.add('pui-block');
    let insuranceInfoHTML_T1 = '';
    let insuranceInfoHTML_C = '';
    const isAddPersonCodeWeblabEnable = this.config.strings.weblabPharmacyAddPersonCodeInsurance === 'T1';
    let insuranceInfoHeaderHTML= `
      <pui-text
        input="${strings.helpfulTipSix}"
        spacingTop="med-small"
      ></pui-text>
      `;
    insuranceInfoHTML_T1 = `
      <pui-text
          input="${strings.helpfulTipSeven}"
          spacingBottom="medium"
      ></pui-text>
      ${isAddPersonCodeWeblabEnable
    ? `<pui-text input="${strings.helpfulTipEleven}" spacingBottom="medium"></pui-text>` : ''}
      <pui-text
          input="${strings.helpfulTipEight}"
          spacingBottom="medium"
      ></pui-text>
      <pui-text
          input="${strings.helpfulTipNine}"
          spacingBottom="medium"
      ></pui-text>
      <pui-text
          input="${strings.helpfulTipTen}"
          spacingBottom="medium"
      ></pui-text>
    `;
    this.innerHTML = `
      <div class="pui-full-page" style="z-index: 212">
        <pui-section style="margin:auto">
          <pui-back id="apex-insurance-info-back"></pui-back>
          <pui-section spacingTop="medium">
              <pui-heading input="${strings.title}" textSize="medium"></pui-heading>
              ${insuranceInfoHeaderHTML}
              <pui-icon imgClass="insurance-card-front-enhancement-icon" spacingTop="medium"></pui-icon>
          </pui-section>

          <pui-section spacingTop="medium">
              ${insuranceInfoHTML_T1}
          </pui-section>
        </pui-section>
      </div>
    `;
    this._insuranceInfoBackButton = this.querySelector('#apex-insurance-info-back');
    this._insuranceInfoBackButton.onBack = this._onBackPress.bind(this);
  }

  _onBackPress() {
    this.close();
  }
}

window.customElements.define('pui-insurance-info', PUIInsuranceInfo);
