import Request from '../../networking/request';
import PUIPage from '../pui-page';

/**
 *
 * Configuration:
 *
 * @param {FeedbackPageConfig} this.config - The configuration for PUIFeedbackPage
 * @param {string} this.config.cancelUrl - The url used for exiting out of the tool
 * @param {string} this.config.helpUrl - The url used for going to the help page
 * @param {string} this.config.actionUrl - The url used for going submitting feedback
 * @param {string} this.config.currentUrl - The current url
 * @param {boolean} this.config.isMShop - The mShop flag
 * @param {string} this.config.strings.feedbackType- The string to indicate if feedback is from mShop
 * @param {string} this.config.strings.header - The string for the feedback page header
 * @param {string} this.config.strings.body - The string for the feature feedback page body
 * @param {string} this.config.strings.placeholderText - The string for the feature feedback page body
 * @param {string} this.config.strings.customerSupportLink - The link text for redirect to help page
 * @param {string} this.config.strings.submitFeedbackButton - The submit feedback button text
 * @param {string} this.config.strings.successMessage1 - The success message displayed
 * @param {string} this.config.strings.successMessage2 - The success message displayed
 * @param {string} this.config.strings.errorMessage - The empty feedback error message displayed
 */
export default class PUIFeedbackPage extends PUIPage {
  connectedCallback() {
    super.connectedCallback();
    this._render();
  }

  _render() {
    this.classList.add('pui-block');

    const { strings } = this.config;
    this.config.pageStyle = 'blank-form';
    const content = {
      main: `
        <pui-section id="feedback-card">
          <pui-section flowDirection="horizontal" mainAxisArrangement="end">
            <pui-link id="feedback-cancel" color="link" text="Cancel" href="${this.config.cancelUrl}"></pui-link>
          </pui-section>

          <pui-banner id="feedback-success-banner" status="success" spacingTop="medium" class="pui-hidden">
            <pui-text input="${strings.successMessage1}" textColor="link" fontWeight="bold"></pui-text>
            <pui-text input="${strings.successMessage2}" textColor="black" inline></pui-text>
          </pui-banner>

          <pui-banner id="feedback-error-banner" status="error" spacingTop="medium" class="pui-hidden">
            <pui-text input="${strings.errorMessage}" textColor="red" inline></pui-text>
          </pui-banner>

          <pui-heading spacingTop="medium" spacingBottom="small" input="${strings.header}" textSize="large" 
          fontWeight="bold"></pui-heading>
          <pui-text textSize="medium"
            input="${strings.body} <pui-link id='help-page' color='link' text='${strings.customerSupportLink}' textSize='medium' href=${this.config.helpUrl}></pui-link>">
          </pui-text>
          <pui-text-area id="feedback-detail" inputId="feedback-input" spacingTop="medium" placeholder="${strings.placeholderText}"></pui-text-area>
        </pui-section>
        `,
      footerDesktop: `
      <pui-button id="submit-feedback-button-desktop" label="${strings.submitFeedbackButton}" form-action="submit">
      </pui-button>
      `,
      footerMobile: `
      <pui-button id="submit-feedback-button-mobile" label="${strings.submitFeedbackButton}" form-action="submit">
      </pui-button>
      `,
    };

    super._render(content);
    this._pageSuccess = this.querySelector('#feedback-success-banner');
    this._pageError = this.querySelector('#feedback-error-banner');
    this._textArea = this.querySelector('#feedback-detail');
    this._feedbackSubmitMobileButton = this.querySelector('#submit-feedback-button-mobile');
    this._feedbackSubmitDesktopButton = this.querySelector('#submit-feedback-button-desktop');
    this._feedbackSubmitMobileButton.addEventListener('click', this._onFeedbackSubmit.bind(this));
    this._feedbackSubmitDesktopButton.addEventListener('click', this._onFeedbackSubmit.bind(this));
  }

  showSuccess() {
    this._pageSuccess.show();
  }

  hideSuccess() {
    this._pageSuccess.hide();
  }

  showError() {
    this._pageError.show();
  }

  hideError() {
    this._pageError.hide();
  }

  _onFeedbackSubmit() {
    if (this._textArea.getValue()) {
      this.hideError();
      const browser = (this.config.isMShop ? 'MShop, ' : '') + window.navigator.userAgent;
      const generalDetail = this._textArea.getValue();
      Request.post(this.config.actionUrl, {
        feedbackType: 'general',
        general: generalDetail,
        browser,
        currentUrl: this.config.currentUrl,
      }).then(() => {
        this.showSuccess();
        this._textArea.getElementsByTagName('textarea')[0].value = '';
      });
    } else {
      this.showError();
      this.hideSuccess();
    }
  }
}

window.customElements.define('pui-feedback-page', PUIFeedbackPage);
