import PUIBase from './pui-base';


class PUINotification extends PUIBase {
  constructor() {
    super();
    this.defaultClass = 'pui-notification';
  }

  connectedCallback() {
    super.connectedCallback();
    this.render();
  }

  get notificationCount() {
    return this.getAttribute('notificationCount') || '';
  }

  set notificationCount(value) {
    this.setAttribute('notificationCount', value);
  }

  render() {
    this.innerHTML = '';
    const {
      defaultClass,
      notificationCount,
    } = this;
    this.classList.add('pui-inline-block');
    this._innerNotification = document.createElement('span');
    this._innerNotification.innerHTML = `
      <div class="${defaultClass}">
        ${notificationCount}
      </div>
    `;
    this.appendChild(this._innerNotification);
  }
}

window.customElements.define('pui-notification', PUINotification);
