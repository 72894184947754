import PUIBase from './pui-base';
import keyCodes from '../constants/keyCodes';

class PUIBack extends PUIBase {
  connectedCallback() {
    super.connectedCallback();
    this.render();
  }

  static get observedAttributes() {
    return ['url', 'text'];
  }

  backPressed(event) {
    if (event.keyCode === keyCodes.ENTER_KEYCODE) { // Pressed Enter
      this.backClicked();
    }
  }

  backClicked() {
    if (this.url) {
      window.location.href = this.url;
    } else if (this.onBack) {
      this.onBack();
    } else {
      window.history.back();
    }
  }

  get url() {
    return this.getAttribute('url') || '';
  }

  set url(value) {
    this.setAttribute('url', value);
  }

  get text() {
    return this.getAttribute('text') || '';
  }

  set text(value) {
    this.setAttribute('text', value);
  }

  render() {
    this.innerHTML = '';
    this.classList.add('pui-block');
    this._innerBack = document.createElement('span');
    this._innerBack.innerHTML = `
      <pui-section flowDirection="horizontal" secondaryAxisArrangement="center">
        <pui-icon imgClass="info-back-icon"></pui-icon>
        <pui-text tabindex="0" style="cursor: pointer;" textColor="link" input="${this.text ? this.text : 'Back'}" spacingLeft="small"></pui-text>
      </pui-section>
    `;
    this._innerBack.addEventListener('click', this.backClicked.bind(this));
    this._innerBack.addEventListener('keyup', this.backPressed.bind(this));
    this.appendChild(this._innerBack);
  }
}

window.customElements.define('pui-back', PUIBack);
