import PUIBase from '../../pui-base';
import { Urls } from '../../../constants';
import Request from '../../../networking/request';
import SubNavWidgets from '../pui-sub-nav-widgets';
import {
    PUICustomerCareChatWidget
} from "../widgets";
import { PUIProfileAndLocationAwarenessBar } from '../profile-and-location';
import { PUICart } from '../../pui-cart';
import { PUINavigationBar } from './pui-nav-bar';
import PUISection from '../../pui-section';
// static imports to ensure these components are available for use
import "./pui-nav-bar";
import "../widgets";
import '../profile-and-location';

const enum PageTypes {
    /* disables profile and location switches, deletes nav bar */
    CHECKOUT = 'CHECKOUT',
    /* 'default' appearance- used on pages like Dashboard, PDP, etc. */
    DEFAULT =  'DEFAULT'
};

export default class PUISubNavView extends PUIBase {
    constructor() {
        super();
    }

    connectedCallback() {
        super.connectedCallback();
        this.#render();
        if (this._subNavData != null) {
            // subnav data has already been set by the consumer
            this._renderSubNavContent();
        } else if (this.autoload) {
            this.#fetchSubNavData();
        }
    }

    attributeChangedCallback() {
        super.attributeChangedCallback();
        if (!this.isConnected) return;
        this.#render();
        if (this.autoload) {
            this.#fetchSubNavData();
        }
    }

    static get observedAttributes() {
        return [
            ...super.observedAttributes,
            'pagetype', 'imgclasslogo', 'autoload'
        ]; 
    }

    #awarenessBar?: PUIProfileAndLocationAwarenessBar;
    private _cartIcon?: PUICart | null = null;
    private _subNavContainer?: PUINavigationBar;
    /** The type of this response depends on the response of
     * SubNavActionsController.getSubNavData in Dashboard. It varies based on
     * program. If you add properties to that response, please also take
     * the time to narrow this type. */
    private _subNavData: any;
    private mobileLogoSlot?: PUISection;
    private desktopLogoSlot?: HTMLAnchorElement;
    private mobileLinksSlot?: PUISection;
    private desktopLinksSlot?: PUISection;
    private navWidgetsSlot?: PUISection;

    //#region HTML Attribute getters/setters
    /**
     * @type {string} The icon (as recognized by <pui-icon>) to display in the logo area.
     */
    get imgClassLogo() {
        return this.getAttribute('imgClassLogo') || 'sub-nav-logo-img';
    }

    set imgClassLogo(value /** @type {string} */) {
        this.setAttribute('imgClassLogo', value);
    }

    /**
     * Get the value of the pageType HTML prop, which specifies which surface
     * this component is being rendered on.
     * @type {string}
     */
    get pageType() {
        return this.getAttribute('pagetype') || '';
    }

    /**
     * Set the HTML prop pageType
     */
    set pageType(value: string) {
        this.setAttribute('pageType', value);
    }

    /** Whether this component should fetch it's subnav data itself.
     * 
     * If false, you must supply data to the subnav by setting subNavData.
     * 
     * Note that setting this value will trigger a refresh of the subnav.
     */
    get autoload() {
        return this.getBooleanAttribute('autoload');
    }

    set autoload(value: boolean) {
        this.setBooleanAttribute('autoload', value);
        this.#fetchSubNavData();
    }
    //#endregion

    /** The databag used to populate the subnav with links and widgets */
    set subNavData(value: any) {
        this._subNavData = value;
        if (!this.isConnected) return;
        this._renderSubNavContent();
    }

    //#region cart stuff
    #setCartCount(itemCount: number) {
        if (this._cartIcon) this._cartIcon.totalItems = "" + itemCount;
    }

    refreshCartCount() {
        Request.get(Urls.GET_CART_COUNT_URL).then((response) => {
            const { itemCount } = response;
            this.#setCartCount(itemCount);
        });
    }

    get isCartEnabled() {
        const { enabledWidgets } = this._subNavData;
        return (enabledWidgets || [SubNavWidgets.CART]).includes(SubNavWidgets.CART);
    }
    //#endregion

    // here be dragons

    #render() {
        const { isMShop } = this;
        this.classList.add('pui-block');

        // Hide the subnav on Checkout
        // TODO: I think this was originally a bug, but it's been in so long that
        // the checkout UI has evolved alongside it, and it does work out OK
        // from a UX perspective (esp in mWeb/mShop), so we're leaving it in.
        let willHideSubNav = this.pageType === PageTypes.CHECKOUT;

        this.innerHTML = `
            <pui-nav-bar ${isMShop ? 'isMShop' : ''}>
                <pui-section flowDirection="horizontal" secondaryAxisArrangement="center" slot="mobile-logo">
                    <span class="pui-loading-text-medium"></span>
                </pui-section>
                <a id="desktop-pharmacy-logo" slot="desktop-logo" href="${Urls.PHARMACY_HOME_URL}">
                    <span class="pui-loading-text-medium"></span>
                </a>
                <pui-section flowDirection="vertical" fullWidth slot="mobile-links">
                    <pui-loading-indicator></pui-loading-indicator>
                </pui-section>
                <pui-section flowDirection="horizontal" secondaryAxisArrangement="center" slot="desktop-links">
                    <span class="pui-loading-text-medium"></span>
                </pui-section>
                <pui-section fullWidth flowDirection="horizontal" mainAxisArrangement="end"
                        secondaryAxisArrangement="center" spacingRight="medium" slot="nav-widgets">
                    <span class="pui~-loading-text-medium"></span>
                </pui-section>

            </pui-nav-bar>
            <pui-profile-and-location-awareness-bar hidden><pui-profile-and-location-awareness-bar />`;

        this._subNavContainer = this.querySelector('pui-nav-bar')!;
        this.#awarenessBar = this.querySelector('pui-profile-and-location-awareness-bar')!;
        this.mobileLogoSlot = this.querySelector("pui-section[slot='mobile-logo']")!;
        this.desktopLogoSlot = this.querySelector("#desktop-pharmacy-logo")!;
        this.mobileLinksSlot = this.querySelector("pui-section[slot='mobile-links']")!;
        this.desktopLinksSlot = this.querySelector("pui-section[slot='desktop-links']")!;
        this.navWidgetsSlot = this.querySelector("pui-section[slot='nav-widgets']")!;

        if (willHideSubNav) {
            this._subNavContainer.hide();
        } else {
            this._subNavContainer.show();
        }
    }

    #fetchSubNavData() {
        Request.get(Urls.GET_SUB_NAV_DATA_URL).then((response) => {
            this.subNavData = response;
        }).catch((e) => {
            console.log(e);
        });
    }

    _renderSubNavContent() {
        const {
            loggedIntoPharmacyProfile,
            requestFromMShop,
            profileAwarenessEnabled,
            signupComplete,
            isReadOnlyProfileAwarenessBar,
            friendlyName,
            personProfiles,
            strings
        } = this._subNavData;

        if (this.#awarenessBar && loggedIntoPharmacyProfile && profileAwarenessEnabled) {
            this.#awarenessBar.hidden = false;
            this.#awarenessBar.isReadOnly = this.pageType === PageTypes.CHECKOUT || isReadOnlyProfileAwarenessBar;
            this.#awarenessBar.isSignupComplete = signupComplete;
            this.#awarenessBar.friendlyName = friendlyName;
            this.#awarenessBar.dataset.stringShoppingFor = strings['shopping-for'];
            this.#awarenessBar.dataset.stringSwitchingTo = strings['switching-to'];
            this.#awarenessBar.dataset.stringChange = strings['change'];
            this.#awarenessBar.dataset.stringWhoShoppingFor = strings['who-shopping-for'];
            this.#awarenessBar.dataset.stringImSomeoneElse = strings['im-someone-else'];
            this.#awarenessBar.dataset.stringAddChild = strings['add-child'];
            this.#awarenessBar.dataset.stringViewing = strings['viewing'];

            this.#awarenessBar.renderLinks(personProfiles);
        } else {
            this.#awarenessBar!.hide();
        }

        // Will use isMShop flag if it is available and if it’s not available, then check the pulled in value
        this.isMShop = this.isMShop || requestFromMShop;

        if (this.isMShop) {
            this._subNavContainer!.classList.add('mshop');
        }

        this.#renderMobileLogo();
        this.#renderDesktopLogo();
        this.#renderMobileLinks();
        this.#renderDesktopLinks();
        // I'm not entirely sure why, but profileAwarenessEnabled appears to be
        // a proxy for clinic vs pharmacy- we should think about just
        // exposing the program directly in getSubNavData.
        this.#renderNavWidgets(profileAwarenessEnabled ? 'pharmacy' : 'clinic');

        let careChatButton: PUICustomerCareChatWidget | null = this.querySelector('pui-care-chat-widget');
        if (!this._subNavData.customerCareChatSupported || !this._subNavData.loggedIntoPharmacyProfile) {
            careChatButton?.hide();
        } else {
            careChatButton?.show();
        }

        // Create reference to pui-cart component
        /** @type {PUICartNavWidget} */
        this._cartIcon = this.querySelector('pui-cart-widget');
    }

    #renderMobileLogo() {
        const strings = this._subNavData.strings;
        // profileAwarenessEnabled = false means Clinic
        const isClinic = this._subNavData.profileAwarenessEnabled;
        this.mobileLogoSlot!.innerHTML = this.isMShop ? `
                <pui-text textColor="white"
                        input="${strings['menu-text']}"
                        spacingLeft="medium"
                        spacingRight="small"></pui-text>
                <pui-icon imgClass="chevron-down-dash-white"></pui-icon>
            ` : `
                <pui-icon imgClass="${this.imgClassLogo} ${isClinic || !this.isMShop ? '' : 'minorsSupported'}" 
                    class="pui-sub-nav-logo"></pui-icon>
                <pui-icon imgClass="chevron-down-dash"></pui-icon>
            `;
    }

    #renderDesktopLogo() {
        const { isMShop } = this;
        const {
            strings,
            linkConfigs,
            logoConfig,
        } = this._subNavData;
        const logoHrefUrl = !!logoConfig ? logoConfig.hrefUrl : Urls.PHARMACY_HOME_URL;
        this.desktopLogoSlot!.href = logoHrefUrl;
        this.desktopLogoSlot!.innerHTML = `
            <pui-icon imgClass="${this.imgClassLogo} 
                ${/*todo: katara?*/!isMShop ? '' : 'minorsSupported'}" class="pui-sub-nav-logo"></pui-icon>`
    }

    #renderMobileLinks() {
        const {
            strings,
            linkConfigs
        } = this._subNavData;
        const links = linkConfigs.map((link: any) => Object.assign({ text: strings[link.stringId] }, link));
        const mobileLinks = links.map((link: any) => `<pui-list-link-item id="nav-link-${link.id}-mobile" input="${link.text}" url="${link.url}"></pui-list-link-item>`);
        this.mobileLinksSlot!.innerHTML = mobileLinks.join('')
    }

    #renderDesktopLinks() {
        const {
            strings,
            linkConfigs,
            profileAwarenessEnabled
        } = this._subNavData;
        const links = linkConfigs.map((link: any) => Object.assign({ text: strings[link.stringId] }, link));
        const isClinic = !profileAwarenessEnabled;

        const desktopLinks = links.map((link: any) => `<a id="nav-link-${link.id}-desktop" href="${link.url}" style="white-space: nowrap" class="hover-underline">
            <pui-text input="${link.text}" ${isClinic ? '' : 'textColor="black"'} spacingRight="med-large"></pui-text>
        </a>`);
        this.desktopLinksSlot!.innerHTML = desktopLinks.join(' ');
    }

    #renderNavWidgets(program: 'pharmacy' | 'clinic' | 'health') {
        const { isMShop, isCartEnabled } = this;
        const {
            strings,
            friendlyName,
            loggedIntoPharmacyProfile,
            personBottomsheetLinkConfigs,
            cartCount,
        } = this._subNavData;
        switch (program) {
            case 'pharmacy': {
                if (loggedIntoPharmacyProfile) {
                    this.navWidgetsSlot!.innerHTML = `
                        <pui-care-chat-widget></pui-care-chat-widget>
                        <a id="nav-link-notification" href="${Urls.NOTIFICATIONS_URL}"><pui-icon imgClass="${!isMShop ? 'notification-icon' : 'notification-white-icon'}" spacingRight="small" style="display: flex"></pui-icon></a>
                        <pui-cart-widget ${isMShop ? 'isMshop' : ''} ${isCartEnabled ? '' : 'hidden'}
                            cartCount=${"" + (+cartCount)}></pui-cart-widget>
                    `;
                } else {
                    this.navWidgetsSlot!.innerHTML = `
                        <pui-link style="white-space: nowrap" color="${!isMShop ? 'black' : 'white'}" textSize="medium" text="${strings['sign-in']}" href="${Urls.SIGN_IN_URL}"></pui-link>
                    `;
                }
                break;
            }
            case 'clinic': {
                const linkColor = isMShop ? 'white' : 'link';
                const iconColor = isMShop ? 'white-' : '';
                if (loggedIntoPharmacyProfile) {
                    const personBottomsheetLinksContentHtml = personBottomsheetLinkConfigs.map((link: {id: string, stringId: string, subtitleStringId: string, url: string}) => {
                        const label = strings[link.subtitleStringId] ? strings[link.stringId] : '';
                        const input = strings[link.subtitleStringId] || strings[link.stringId];
                        return `<pui-list-link-item id="${link.id}" label="${label}" input="${input}" url="${link.url}"></pui-list-link-item>`;
                    }).join('');
                    this.navWidgetsSlot!.innerHTML = `
                        <a id="nav-link-notification" href="${Urls.NOTIFICATIONS_URL}"><pui-icon imgClass="${`notification-${iconColor}icon`}" spacingRight="small" style="display: flex"></pui-icon></a>
                        <pui-profile-widget friendlyName="${friendlyName}"
                            data-string-account-holder="${strings['account-holder']}"
                            data-string-change-profile-link="${strings['change-profile-link']}"
                            data-string-sign-out="${strings['sign-out']}">
                                ${personBottomsheetLinksContentHtml}
                        </pui-profile-widget>
                        <pui-cart-widget ${isMShop ? 'isMshop' : ''} ${isCartEnabled ? '' : 'hidden'}
                            cartCount=${"" + (+cartCount)}></pui-cart-widget>
                    `;
                } else {
                    return `
                        <pui-link style="white-space: nowrap" color="${linkColor}" textSize="medium" text="${strings['sign-in']}" href="${Urls.SIGN_IN_URL}"></pui-link>
                    `;
                }
            }
            case 'health': {
                //save the space for upcoming health subnav widget
                break;
            }
        }
    }
}

window.customElements.define('pui-sub-nav-view', PUISubNavView);
