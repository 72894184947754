import PUIUploadCardBase from '../identity-verification/pui-upload-card-base';
import PUIInsuranceError from "./pui-insurance-error";
import Request from '../../networking/request';
import keyCodes from '../../constants/keyCodes';


/**
 * This component is responsible for manually adding new insurances or
 * updating an existing insurance
 *
 * Configuration:
 *
 * @param {PUIInsuranceUploadConfig} this.config - The configuration for PUIInsuranceUpload
 * @param {string} this.config.uploadUrl - The url for the IDES endpoint
 * @param {string} this.config.saveInsuranceUrl - The url for creating/updating an insurance
 * @param {string} this.config.strings.defaultErrorMessage - The default error message to fall back to
 * @param {string} this.config.strings.backButton - The text of the back button (go to previous page)
 * @param {string} this.config.strings.cancelButton - The text of the cancel button (cancel operation entirely)
 * @param {string} this.config.strings.cardTitle - The title of the upload page for the front of the card
 * @param {string} this.config.strings.retakeTitle - The title of the retake page for the front of the card
 * @param {string} this.config.strings.cardMessage - The message displayed on the upload page for the front of the card
 * @param {string} this.config.strings.cardTitleSecondPage - The title of the upload page for the back of the card
 * @param {string} this.config.strings.retakeTitleSecondPage - The title of the retake page for the back of the card
 * @param {string} this.config.strings.cardMessageSecondPage - The message displayed on the upload page for the back
 *                                                             of the card
 * @param {string} this.config.strings.frontCardMessage - The text of the card prompting users for the front of the card
 * @param {string} this.config.strings.backCardMessage - The text of the card prompting users for the back of the card
 * @param {string} this.config.strings.takeButtonLabel - The text of the button asking the user to take or upload an image
 * @param {string} this.config.strings.confirmCardTitle - The message displayed on the confirmation page after image upload
 * @param {string} this.config.strings.confirmCardMessage - The text displayed on the confirmation page after image upload
 * @param {string} this.config.strings.confirmButtonLabel - The text of the confirm button on the confirmation page
 * @param {string} this.config.strings.retakeButtonLabel - The text of the retake button on the confirmation page
 * @param {string} this.config.strings.uploadFailureTitle - The title of the insurance saver error box after too
 *                                                          many failures
 * @param {string} this.config.strings.uploadFailureMessage - The message of the insurance saver error box after too
 *                                                          many failures
 *
 * @param {onSaveComplete} this.config.callbacks.onSaveComplete - On Save Complete Callback
 * @param {close} this.config.callbacks.onSuccess - Closes Insurance Select on complete Callback
 * @param {openInsuranceSaver} this.config.callbacks.uploadFailed - Opens Insurance Saver on failure
 *
 */
export default class PUIInsuranceUploadCard extends PUIUploadCardBase {
  constructor() {
    super();
    this.id = 'pui-insurance-upload-card';
    this.captureType = 'environment';
  }

  connectedCallback() {
    super.connectedCallback();
    this._render();
  }

  _render() {
    const { strings } = this.config;
    const {
      cardTitle,
      cardMessage,
      cardTitleSecondPage,
      cardMessageSecondPage,
      frontCardMessage,
      backCardMessage,
    } = strings;
    const confirmPhotoPageHtml = this.renderConfirmPhotoPage();

    this.requestBody = {
      identificationNumber: '',
      binNumber: '',
      groupNumber: '',
      pcnNumber: '',
    };

    // number of upload failures before manual insurance redirect
    this.tolerance = 2;
    this.failures = [0, 0];

    this.firstPageId = 'insuranceUpload-firstPhoto';
    this.secondPageId = 'insuranceUpload-secondPhoto';

    const firstPhotoContainer = document.createElement('div');
    firstPhotoContainer.id = this.firstPageId;
    firstPhotoContainer.innerHTML = this.renderTakePhotoPage(cardTitle, cardMessage, 'upload-front-icon', frontCardMessage) + confirmPhotoPageHtml;

    const secondPhotoContainer = document.createElement('div');
    secondPhotoContainer.id = this.secondPageId;
    secondPhotoContainer.classList.add(`${this.hiddenClass}`);
    secondPhotoContainer.innerHTML = this.renderTakePhotoPage(cardTitleSecondPage, cardMessageSecondPage, 'upload-back-icon', backCardMessage) + confirmPhotoPageHtml;

    this.innerHTML = `
      <div class="pui-full-page">
      <pui-section style="margin:auto" id="idv-page">
        <pui-section id="apex-insurance-card-upload" spacingBottom="small">
        ${firstPhotoContainer.outerHTML}${secondPhotoContainer.outerHTML}
        </pui-section>
      </pui-section>
      </div>
    `;

    // Attach event listeners to file selector button, confirm button, and retake button on firstPhoto page
    this._bindButtons(this.firstPageId);
    this._bindButtons(this.secondPageId);

    // Setup loading indicator
    this.setupLoadingIndicator();
  }

  close() {
    this.parentNode.removeChild(this);
  }

  _onCancelPress() {
    this.close();
  }

  _bindButtons(pageId) {
    const {
      retakeButton, confirmButton, takePhotoBackButton, takePhotoCancelButton,
      confirmPhotoBackButton, confirmPhotoCancelButton,
    } = this.getPageElements(pageId);
    retakeButton.addEventListener('click', this.retakeButtonClickHandler.bind(this, pageId));
    retakeButton.addEventListener('keyup', this._keyListener.bind(this,
      this.retakeButtonClickHandler.bind(this, pageId)));
    if (pageId === this.firstPageId) {
      takePhotoBackButton.addEventListener('click', this._onCancelPress.bind(this));
      takePhotoBackButton.addEventListener('keyup', this._keyListener.bind(this,
        this._onCancelPress.bind(this)));
    } else {
      takePhotoBackButton.addEventListener('click', this._changePageHandler.bind(this, this.secondPageId,
        this.firstPageId));
      takePhotoBackButton.addEventListener('keyup', this._keyListener.bind(this,
        this._changePageHandler.bind(this, this.secondPageId, this.firstPageId)));
    }
    takePhotoCancelButton.addEventListener('click', this._onCancelPress.bind(this));
    takePhotoCancelButton.addEventListener('keyup', this._keyListener.bind(this, this._onCancelPress.bind(this)));
    confirmPhotoBackButton.addEventListener('click', this.retakeButtonClickHandler.bind(this, pageId));
    confirmPhotoBackButton.addEventListener('keyup', this._keyListener.bind(this,
      this.retakeButtonClickHandler.bind(this, pageId)));
    confirmPhotoCancelButton.addEventListener('click', this._onCancelPress.bind(this));
    confirmPhotoCancelButton.addEventListener('keyup', this._keyListener.bind(this, this._onCancelPress.bind(this)));
    confirmButton.addEventListener('click', this.uploadHandler.bind(this, pageId));
    confirmButton.addEventListener('keyup', this._keyListener.bind(this, this.uploadHandler.bind(this, pageId)));
    this.buildFileSelector(pageId);
  }

  encodeUpload(pageId) {
    const imageEncodedInput = this.querySelector(`#${pageId} .image-encoded-element`);
    const _encodedValue = imageEncodedInput.getAttribute('value');
    const hasEmptyValue = _encodedValue === '';

    // Some of the inputs has empty value
    if (hasEmptyValue) {
      return false;
    }

    return { bytes: _encodedValue };
  }

  _updateRequestBody(response) {
    if (response.insuranceInformation.rxBin) {
      this.requestBody.binNumber = response.insuranceInformation.rxBin;
    }
    if (response.insuranceInformation.rxGroup) {
      this.requestBody.groupNumber = response.insuranceInformation.rxGroup;
    }
    if (response.insuranceInformation.memberId) {
      this.requestBody.identificationNumber = response.insuranceInformation.memberId;
    }
    if (response.insuranceInformation.rxPcn) {
      this.requestBody.pcnNumber = response.insuranceInformation.rxPcn;
    }
  }

  _triggerManualInput() {
    const { uploadFailed } = this.config.callbacks;
    uploadFailed();
    const insuranceSaver = document.querySelector('pui-insurance-saver');
    const errorBox = insuranceSaver.querySelector('pui-insurance-error');
    errorBox.classList.remove(`${this.hiddenClass}`);
    errorBox.setTitle(this.config.strings.uploadFailureTitle);
    errorBox.setDescription(this.config.strings.uploadFailureMessage);
    this.close();
  }

  _keyListener(event, callback) {
    if (event.keyCode === keyCodes.ENTER_KEYCODE) {
      callback();
    }
  }

  /**
   * Responsible for changing between the currently displayed page.
   * Only handles shifts between upload pages: the "back" button on the confirm page mirrors the "retake" button.
   */
  _changePageHandler(currentPageId, nextPageId) {
    // upload the first photo page here
    const { pageElement: currentPageElement } = this.getPageElements(currentPageId);
    const { pageElement: nextPageElement } = this.getPageElements(nextPageId);
    currentPageElement.classList.add(`${this.hiddenClass}`);
    nextPageElement.classList.remove(`${this.hiddenClass}`);
  }

  uploadHandler(pageId) {
    // Show loading indicator during upload
    this.displaySpinner();

    const base64EncodedString = this.encodeUpload(pageId);

    if (!base64EncodedString) {
      this.retakeButtonClickHandler(pageId);
      this.hideSpinner();
      this._showError(0, pageId);
      return false;
    }

    Request.post(this.config.uploadUrl, {
      attachments: base64EncodedString,
    }).then((idesResponse) => {
      this._updateRequestBody(idesResponse);
      const hasEmptyValue = Object.values(this.requestBody).some(insuranceInfo => insuranceInfo === '');

      // Don't continue if fields are missing
      if (hasEmptyValue) {
        if (pageId === this.firstPageId) {
          this._changePageHandler(this.firstPageId, this.secondPageId);
          this.hideSpinner();
          return;
        }
        this._triggerManualInput();
        return;
      }

      Request.post(this.config.saveInsuranceUrl, this.requestBody).then((response) => {
        const { insuranceId } = response;
        const { onSaveComplete, onSuccess } = this.config.callbacks;
        if (onSaveComplete) {
          onSaveComplete(insuranceId, this.config.insurance);
        }
        if (onSuccess) {
          onSuccess();
        }
        this.close();
      }).catch((saveResponse) => {
        if (saveResponse.error) {
          this._triggerManualInput();
        }
      });
    }).catch((response) => {
      const pageIndex = pageId === this.firstPageId ? 0 : 1;
      this.failures[pageIndex] += 1;
      if (this.failures[pageIndex] < this.tolerance) {
        this._showError(response.error, pageId);
        this.retakeButtonClickHandler(pageId);
        this.hideSpinner();
      } else {
        this._triggerManualInput();
      }
    });

    return true;
  }

  getPageElements(pageId) {
    const mapValue = super.getPageElements(pageId);

    mapValue.takePhotoBackButton = this.querySelector(`#${pageId} .take-photo-back-button`);
    mapValue.takePhotoCancelButton = this.querySelector(`#${pageId} .take-photo-cancel-button`);
    mapValue.confirmPhotoBackButton = this.querySelector(`#${pageId} .confirm-photo-back-button`);
    mapValue.confirmPhotoCancelButton = this.querySelector(`#${pageId} .confirm-photo-cancel-button`);

    this.pageElements.set(pageId, mapValue);
    return mapValue;
  }

  header(pageClass) {
    const html = `
        <div class="insuranceUploadHeader">
            <pui-section flowDirection="horizontal" mainAxisArrangement="space-between" spacingBottom="medium">
                <pui-section flowDirection="horizontal" secondaryAxisArrangement="center">
                  <pui-icon imgClass="info-back-icon"></pui-icon>
                  <pui-text class="${pageClass}-back-button" style="cursor: pointer;" spacingLeft="small"
                    textColor="link" input="${this.config.strings.backButton}">
                  </pui-text>
                </pui-section>
                <pui-text class="${pageClass}-cancel-button" style="cursor: pointer;"
                  textColor="link" input="${this.config.strings.cancelButton}">
                </pui-text>
            </pui-section>
        </div>
    `;
    return html;
  }

  /**
   * Responsible to build and render take document photo page
   */
  renderTakePhotoPage(cardTitle, cardMessage, iconClass, iconMessage) {
    const { strings } = this.config;
    const html = `
        <div class="takePhotoPage">
            ${this.header('take-photo')}
            <pui-insurance-error class="page-error ${this.hiddenClass}"></pui-insurance-error>
            <pui-section-column>
                <pui-heading input="${cardTitle}" textSize="extra-large"></pui-heading>
            </pui-section-column>
            <pui-text spacingTop="small" spacingBottom="medium" input="${cardMessage}"></pui-text>
            <pui-box class="file-selector-trigger" id="file-selector-card-trigger" theme="selected">
                <pui-section secondaryAxisArrangement="center">
                    <pui-icon imgClass="${iconClass}" spacingTop="medium"> </pui-icon>
                    <pui-text input="${iconMessage}" textAlign="center" spacingTop="small" spacingBottom="medium"></pui-text>
                </pui-section>
            </pui-box>
            <pui-button class="file-selector-trigger" id="file-selector-button-trigger" label="${strings.takeButtonLabel}"
                spacingTop="medium"></pui-button>
            <span class="file-selector-container ${this.hiddenClass}"></span>
        </div>
    `;

    return html;
  }

  renderConfirmPhotoPage() {
    const { strings } = this.config;
    const html = `
        <div class="confirmPhotoPage ${this.hiddenClass}">
            ${this.header('confirm-photo')}
            <pui-section-column>
                <pui-heading input="${strings.confirmCardTitle}" textSize="extra-large"></pui-heading>
            </pui-section-column>
            <pui-text spacingTop="small" spacingBottom="medium" input="${strings.confirmCardMessage}"></pui-text>
            <img class="confirm-image-element" src="#" alt="selfie-photo" />
            <input type="text" class="image-encoded-element ${this.hiddenClass}" name="encodedImage[]" spellcheck="false" />
            <pui-button class="confirm-button" id="confirm-upload-confirm-button" spacingTop="medium" 
                label="${strings.confirmButtonLabel}"></pui-button>
            <pui-button class="retake-button" id="confirm-upload-retake-button" spacingTop="medium" 
                label="${strings.retakeButtonLabel}" theme="secondary"></pui-button>
        </div>
    `;

    return html;
  }

  _showError(err, pageId) {
    let errMessage = this.config.strings.defaultErrorMessage;
    if (err && err.message) {
      errMessage = err.message;
    }

    const { puiPageError, takePhotoPage } = this.getPageElements(pageId);
    puiPageError.setTitle(errMessage);
    puiPageError.show();

    const heading = takePhotoPage.querySelector('pui-heading');
    const newHeading = pageId === this.firstPageId ? this.config.strings.retakeTitle : this.config.strings.retakeTitleSecondPage;
    heading.setText(newHeading);
  }
}

window.customElements.define('pui-insurance-upload-card', PUIInsuranceUploadCard);
