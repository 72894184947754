import { keyListener } from "../functions/domUtils";
import PUIBase from "./pui-base";
import { PUICarouselSwipeElement } from "./pui-carousel-swipe-element";

/**
 * PUINextBestActionCardElement compatible with pui-carousel-swipe-element - this would
 * result ux where next best action cards are displayed in a scrollable/swipeable carousel.
 * It can also be used as a stand alone pui component.
 */
export class PUINextBestActionCardElement extends PUIBase {
  constructor() {
    super();
  }

  connectedCallback() {
    super.connectedCallback();
    this.render();

    // Initial update if this is the active card (not hidden)
    if (!this.hidden) {
      this.updateCarouselNavigation();
    }
  }

  attributeChangedCallback(name, oldValue, newValue) {
    if (name === 'hidden' && oldValue !== newValue && newValue === null) {
        // The 'hidden' attribute was removed
        this.updateCarouselNavigation();
    }
    super.attributeChangedCallback();
  }

  updateCarouselNavigation() {
    // Get the parent carousel
    const carousel = this.closest('pui-carousel-swipe');
    if (!carousel) return;

    // Get the navigation buttons
    const prevButton = carousel.querySelector('#prev-button');
    const nextButton = carousel.querySelector('#next-button');

    const setNavButtonAttributes = (button) => {
      if (!button) return;
      button.setAttribute('data-nba-type', this.nbaType);
      button.setAttribute('data-csa-c-type', `ap-carousel-swipe-${this.nbaType}`);
      button.setAttribute('data-csa-c-item-type', `ap-carousel-swipe-${this.nbaType}-category-type`);
      button.setAttribute('data-csa-c-item-id', `ap-carousel-swipe-${this.nbaType}-category-id`);
      button.setAttribute('data-csa-c-action', `ap-carousel-swipe-${this.nbaType}-category-action`);
    };

    // Update the visible navigation buttons
    if (!prevButton?.hidden) {
      setNavButtonAttributes(prevButton);
    }
    if (!nextButton?.hidden) {
      setNavButtonAttributes(nextButton);
    }
  }

  static get observedAttributes() { return ['nbatype', 'hidden', 'theme', 'href', 'linkText', 'title', 'description']; }

   /**
   * Specifies the next best action type
   * @type {string}
   * @attr
   */
    get nbaType() {
      return this.getAttribute('nbaType') || '';
    }

    set nbaType(value) {
      this.setAttribute('nbaType', value);
    }

  /**
   * Specifies the type of banner card - this determines the content and layout within each card
   * New themes can be added if needed due to new requirements, etc.
   * @type {"dismissible"|"link"|"bottomsheet"}
   * @attr
   */
    get theme() {
      return this.getAttribute('theme') || '';
    }

    set theme(value) {
      this.setAttribute('theme', value);
    }

  /**
   * Specifies the destination URL where customers will be directed when they click on the banner.
   * @type {string}
   * @attr
   */
    get href() {
      return this.getAttribute('href');
    }

    set href(value) {
      this.setAttribute('href', value);
    }

    get linkText() {
      return this.getAttribute('linkText');
    }

    set linkText(value) {
      this.setAttribute('linkText', value);
    }

    get title() {
      return this.getAttribute('title');
    }

    set title(value) {
      this.setAttribute('title', value);
    }

    get description() {
      return this.getAttribute('description');
    }

    set description(value) {
      this.setAttribute('description', value);
    }

    get imgClass() {
      return this.getAttribute('imgClass');
    }

    set imgClass(value) {
      this.setAttribute('imgClass', value);
    }

    _linkClicked() {
      window.location.href = this.href;
      return;
    }

    render() {
      const {
        nbaType,
        theme,
        title,
        description,
        linkText,
        imgClass,
      } = this;
      this.setAttribute('id', 'pui-next-best-action-card');
      this.classList.add('pui-carousel-swipe-element', 'pui-next-best-action-card');
      const carouselSwipeElem = new PUICarouselSwipeElement();
      carouselSwipeElem.classList.add('pui-next-best-action-card-elem');
      let actionButton = '';
      let id = '';
      if (theme === 'dismissible') {
        id = 'pui-next-best-action-dismissible-' + nbaType;
        actionButton = `
          <button id="pui-next-best-action-card-dismiss-button" class="pui-next-best-action-card-action-button align-top" 
            data-csa-c-item-type="ap-dismiss-banner-${nbaType}-category-type"
            data-csa-c-item-id="ap-dismiss-banner-${nbaType}-category-id"
            data-csa-c-type="ap-dismiss-banner-${nbaType}"
            data-csa-c-action="ap-dismiss-banner-${nbaType}-category-action">
              <pui-icon imgClass="dismiss-icon"/>
          </button>`;
      }
      if (theme === 'link') {
        id = 'pui-next-best-action-card-link-' + nbaType;
        actionButton = `<pui-icon class="pui-next-best-action-card-action-button align-center" imgClass="chevron-right"></pui-icon>`;
      }
      if (theme === 'bottomsheet') {
        id = 'pui-next-best-action-bottomsheet-' + nbaType;
        actionButton = `
          <button id="pui-next-best-action-card-bottomsheet-button" class="pui-next-best-action-card-action-button align-middle">
            <pui-icon imgClass="menu-meatball-grey"/>
          </button>`;
      }
      carouselSwipeElem.innerHTML = `
        <pui-section id=${id} fullWidth flowDirection="horizontal"
                        mainAxisArrangement="flex-start" secondaryAxisArrangement="center"
                        spacingLeft="small" spacingRight="small"
                        spacingTop="small" spacingBottom="small">
          <pui-section id="pui-next-best-action-card-icon" class="pui-next-best-action-card-content">
            <pui-icon imgClass="${imgClass}" spacingRight="small"></pui-icon>
          </pui-section>
          <pui-section fullWidth id="${`pui-next-best-action-text-link-` + nbaType}"
            class="pui-next-best-action-card-content full-width" spacingRight="small">
              <pui-text id="pui-next-best-action-card-title" input="${title}" fontWeight="bold" textSize="medium" spacingTop="mini"></pui-text>
              <pui-text id="pui-next-best-action-card-description" input="${description}" textSize="small-medium" spacingTop="mini"></pui-text>
              ${linkText ? `<pui-text id="pui-next-best-action-card-link" input="${linkText}" textSize="small-medium" textColor="link" spacingTop="mini"></pui-text>` : ``}
          </pui-section>
          ${actionButton}
        </pui-section>
      `
      const linkCard = carouselSwipeElem.querySelector('#pui-next-best-action-card-link-' + nbaType);
      if (linkCard) {
        linkCard.addEventListener('click', this._linkClicked.bind(this));
      }
      if (theme === 'dismissible' || theme === 'bottomsheet') {
        const linkButton = carouselSwipeElem.querySelector('#pui-next-best-action-text-link-' + nbaType);
        if (linkButton) {
          linkButton.addEventListener('click', this._linkClicked.bind(this));
        }
        const bannerIcon = carouselSwipeElem.querySelector('#pui-next-best-action-card-icon');
        if (bannerIcon) {
          bannerIcon.addEventListener('click', this._linkClicked.bind(this));
        }
      }

      // add event listener for accessibility
      this.setAttribute('tabindex', '0');
      this.addEventListener('keyup', keyListener(this._linkClicked.bind(this)));

      // apply no-scroll css class if card is not within a swipe carousel
      const hasSiblings = this.parentNode.id === 'pui-carousel-swipe-container' &&
        (this.nextElementSibling || this.previousElementSibling);
      if (this.parentNode.id !== 'pui-carousel-swipe-container' || !hasSiblings) {
        carouselSwipeElem.classList.remove('pui-next-best-action-card-elem');
        carouselSwipeElem.classList.add('pui-carousel-swipe-element-no-scroll');
        this.classList.add('pui-next-best-action-card-elem');
        this.classList.remove('pui-next-best-action-card')
      }

      this.appendChild(carouselSwipeElem);
    }
}

window.customElements.define('pui-next-best-action-card', PUINextBestActionCardElement);
