import PUIPage from '../pui-page';
import PUIMedications from './pui-medications';
import PUIMedConfigurator from './pui-med-configurator';
import Request from '../../networking/request';

/**
 * This page is a container for the pui-medications component and also includes the additional functionality
 * of being able to call out to health profile service to update medications
 *
 *
 * Configuration:
 *
 * @param {MedicationsPageConfig} this.config - The configuration for PUIMedications
 * @param {string} this.config.pageStyle - Either 'card-form' or 'standard-form'
 * @param {string} this.config.backUrl - The url used for going to the previous page
 * @param {string} this.config.actionUrl - The url for saving the added medications
 * @param {string} this.config.nextUrl - The url for the page to transition to after saving medications
 * @param {string} this.config.searchUrl - The url used for searching medications
 * @param {string} this.config.searchQueryDelay - Specify the delay in millliseconds before initiating a search query
 * @param {string} this.config.currentMedicationsUrl - The url used for getting the current medications
 * @param {string} this.config.iconClass - The class for the image icon used for this page
 * @param {boolean} this.config.displayUnknownOption - The boolean for displaying the "I don't know" option
 * @param {string} this.config.strings.pageTitle - The title text for the medication page
 * @param {string} this.config.strings.pageDescription - The description text for the medication page
 * @param {string} this.config.strings.saveButton - The text for the save medication button
 * @param {string} this.config.strings.searchInputTitle - The title text for the search input
 * @param {string} this.config.strings.searchInputPlaceholder - The placeholder text fof the search input
 * @param {string} this.config.strings.searchResultGeneric - Label to show the search item is generic
 * @param {string} this.config.strings.noResultsFound - The text that displays when no results found
 * @param {string} this.config.strings.cancelButton - The cancel button text
 * @param {string} this.config.strings.addButton - The add button text
 * @param {string} this.config.strings.removeButton - The remove button text
 * @param {string} this.config.strings.updateButton - The update button text
 * @param {string} this.config.strings.strengthSelectLabel - The label for the strenght select input
 * @param {string} this.config.strings.dontKnowOption - The string for the don't know option
 * @param {string} this.config.strings.strengthSelectCancel - The string for the cancel option on strength selection
 * @param {string} this.config.strings.checkboxLabel - The string for the no medications checkbox
 *
 * @param {string} this.config.strings.configuratorTitle - The title for this configurator
 * @param {string} this.config.strings.packagingQuestion - The question asking which dosage form to select
 * @param {string} this.config.strings.strengthQuestion - The question asking which strength to select
 * @param {string} this.config.strings.completeConfigurationButton - The string for the configurator finish button
 * @param {string} this.config.strings.completeUpdateConfigurationButton - The string for the configurator
 *                                                                         finish update button
 * @param {string} this.config.strings.configurationCancel - The string for cancelling configurator
 */
export default class PUIMedConfiguratorPage extends PUIPage {
  connectedCallback() {
    super.connectedCallback();
    this._render();
  }
  
  _render() {
    this.classList.add('pui-block');

    this.innerHTML = `
        <pui-section id="apex-medications-configurator-container">

        </pui-section>
    `;

    const apexMedicationConfiguratorContainer = this.querySelector('#apex-medications-configurator-container');
    const selectedMedication = this.config.medItem.searchResult;
    const medConfigurator = new PUIMedConfigurator();
    const { strings, environment } = this.config;
    const medConfiguratorConfig = {
      displayUnknownOption: true,
      medication: selectedMedication,
      environment,
      strings,
      extendedConfig: this.config.extendedConfig,
      callbacks: {
        onConfigurationComplete: this._onConfigurationComplete.bind(this)
      },
    };
    medConfigurator.configure(medConfiguratorConfig);
    apexMedicationConfiguratorContainer.appendChild(medConfigurator);

    
  }

  /**
   * Gets the list of medications from the pui-medications component and updates the health profile service with
   * these medications.
   */
  _onConfigurationComplete(searchResult, medicationConfiguration) {
    const { onMedicationAdd } = this.config.callbacks;
    if (onMedicationAdd) {
        onMedicationAdd(searchResult, medicationConfiguration);
    }
  }
}

window.customElements.define('pui-med-configurator-page', PUIMedConfiguratorPage);
