import PUIBase from './pui-base';


// Extend the LitElement base class
class PUIPin extends PUIBase {
  constructor() {
    super();
    this.defaultClass = 'pui-pin';
    this.defaultLength = 4;
    this.pin = '';
    this.html = '';
  }

  connectedCallback() {
    super.connectedCallback();
    this.render();
  }

  static get observedAttributes() { return ['length']; }

  /**
     * @classprop {string} length This is the length of the pin. defaults to 4
     *
     */
  get length() {
    return this.getAttribute('length') || '';
  }

  set length(value) {
    this.setAttribute('length', value);
  }

  generateLength(pinLength = 0) {
    let length = this.length ? this.length : this.defaultLength;
    let html = '';

    length -= pinLength;
    for (let i = 0; i < length; i++) {
      html += `<div id="pin-${i}" class=empty-pin></div>`;
    }
    this.html = html;
  }

  populatePin(pin = '') {
    if (pin.length < 1) {
      this.generateLength();
    }
    const pinItem = this._innerPin.childNodes[pin.length - 1];
    pinItem.classList.remove('empty-pin');
    pinItem.classList.add('filled-pin');
  }

  clearPinItem(pin) {
    const pinItem = this._innerPin.childNodes[pin.length];
    pinItem.classList.remove('filled-pin');
    pinItem.classList.add('empty-pin');
  }

  clearPin() {
    for (let i = this.length - 1; i > -1; i--) {
      const pinItem = this._innerPin.childNodes[i];
      pinItem.classList.remove('filled-pin');
      pinItem.classList.add('empty-pin');
      this.pin = '';
    }
  }

  processPinInput(event) {
    const digits = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
    if (digits.includes(event.key)) {
      if (this.pin.length < this.length) {
        this.pin += event.key;
        this.populatePin(this.pin);
      }

      // Drop focus once we reach length
      if (this.pin.length === this.length) {
        this._innerInput.blur();
      }
    } else if (event.key === 'Backspace' && event.metaKey === true) {
      this.clearPin();
    } else if (event.key === 'Backspace') {
      this.pin = this.pin.substring(0, this.pin.length - 1);
      this.clearPinItem(this.pin);
    }
  }

  getPin() {
    return this.pin;
  }

  /**
   * Focuses on this PIN Input
   * Note: Must be called inside of a click event to work properly in mobile Safari
   * @param {integer} delay - The delay in milliseconds before focusing on this pin input
   * Defaults to 0
   */
  focusPin(delay = 0) {
    if (delay === 0) {
      this._innerInput.focus();
    } else {
      // .focus() call won't open up the keyboard inside of a setTimeout for mobile Safari
      // so we have to focus on this fake input before focusing on the real one
      const fakeInput = document.createElement('input');
      fakeInput.classList.add('pui-hidden-selectable');
      fakeInput.setAttribute('type', 'tel');
      document.body.appendChild(fakeInput);
      fakeInput.focus();

      setTimeout(() => {
        this._innerInput.focus();
        // remove fake input
        fakeInput.parentNode.removeChild(fakeInput);
      }, delay);
    }
  }

  _onTouchStart() {
    this._innerInput.focus();
  }

  getInput() {
    return this._innerInput;
  }

  render() {
    if (this.html.length < 1) {
      this.generateLength();
    }
    this.innerHTML = '';
    this.classList.add('pui-inline-block');
    this._innerElement = document.createElement('span');
    this.length = this.length ? this.length : this.defaultLength;


    this._innerPin = document.createElement('div');
    this._innerInput = document.createElement('input');

    this._innerPin.classList.add(`custom-pui-pin-${this.id}`);
    this._innerPin.innerHTML = this.html;

    this._innerInput.classList.add('pui-hidden-selectable');
    this._innerInput.setAttribute('id', this.id);
    this._innerInput.setAttribute('type', 'tel');
    this._innerInput.setAttribute('pattern', '\d*');
    this._innerInput.setAttribute('title', 'Enter a numeric PIN');
    this._innerInput.setAttribute('maxLength', this.length);
    this._innerInput.setAttribute('autocomplete', 'off');

    this._innerElement.appendChild(this._innerPin);
    this._innerElement.appendChild(this._innerInput);


    this.appendChild(this._innerElement);

    this._innerInput.onkeydown = (event) => {
      this.processPinInput(event);
    };

    this.onclick = () => {
      this.focusPin();
    };
  }
}

window.customElements.define('pui-pin', PUIPin);
