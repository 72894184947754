import PUIPage from '../pui-page';
import PUIPrescriberSearch from './pui-prescriber-search';
import PUIPrescriberItem from './pui-prescriber-item';
import PUIZipUpdater from '../zip/pui-zip-updater';
import Request from '../../networking/request';

/**
 * In the signup experience, this page allows a user to search for and select their most recent prescriber
 * In dashboard, this page allows a user to edit their list of prescribers that they are currently seeing
 *
 * Configuration:
 *
 * @param {PrescriberPageConfig} this.config - This configuration for PUIPrescriberPage
 * @param {string} this.config.pageType - Either 'signup' or 'dashboard'
 * @param {string} this.config.backUrl - The url used for going to the previous page (dashboard only)
 * @param {string} this.config.actionUrl - The url for saving the primary prescriber
 * @param {string} this.config.nextUrl - The url for the page to transition to after saving their prescriber
 * @param {string} this.config.searchUrl - The url used for searching prescribers
 * @param {string} this.config.getPrimaryPrescriberUrl - The url used to get your primary prescriber (signup only)
 * @param {string} this.config.getPrescribersUrl - The url to get all of your prescribers (dashboard only)
 * @param {string} this.config.searchQueryDelay - Specify the delay in millliseconds before initiating a search query
 * @param {string} this.config.getCoordinatesUrl - The url used for getting the coordinates for a zipcode
 * @param {string} this.config.iconClass - The class for the image icon used for this page
 * @param {string} this.config.initialZip - The initial zip code used for the prescriber search
 * @param {string} this.config.contactUsUrl - The url for the help page
 * @param {string} this.config.nextUrl - url path for next page
 * @param {string} this.config.strings.pageTitle - The title text for the prescriber page
 * @param {string} this.config.strings.pageDescription - The description text for the prescriber page (signup only)
 * @param {string} this.config.strings.pagePrompt - The prompt to add a new prescriber (dashboard only)
 * @param {string} this.config.strings.listTitle - The title before the list of the prescribers (dashboard only)
 * @param {string} this.config.strings.searchZip - The text indicating which zipcode we are using for the search
 * @param {string} this.config.strings.zipChangeLink - The link text for changing the search zip
 * @param {string} this.config.strings.saveButton - The string for the saving the selected / list of prescriber(s)
 * @param {string} this.config.strings.zipUpdater.zipPrompt - The prompt string for this zip updater
 * @param {string} this.config.strings.zipUpdater.zipInputTitle - The title string for the zip input
 * @param {string} this.config.strings.zipUpdater.zipInputPlaceholder - The placeholder for the zip input
 * @param {string} this.config.strings.zipUpdater.invalidZipCodeMessage - The error text for an invalid zip code
 * @param {string} this.config.strings.zipUpdater.cancelButton - The string for the cancel button
 * @param {string} this.config.strings.zipUpdater.applyButton - The string for the apply button
 * @param {string} this.config.strings.prescriberSearch.searchInputTitle - The title text used for the search input
 * @param {string} this.config.strings.prescriberSearch.searchInputPlaceholder - The placeholder text for search input
 * @param {string} this.config.strings.prescriberSearch.noResultsFound - The text that shows when no results are found
 * @param {string} this.config.strings.prescriberSearch.changeZipText - The text that prompts you to change your zip code and try again
 * @param {string} this.config.strings.prescriberSearch.addPrescriptionLaterText - The text that prompts tyou to continue without prescriber
 * @param {string} this.config.strings.prescriberSearch.addPrescriberSubtext - The text that prompts tyou to continue without prescriber
 * @param {string} this.config.strings.prescriberSearch.contactUsPromptText - A prompt for the user to contact us,
 *                                                                            when no prescribers are found.
 * @param {string} this.config.strings.prescriberSearch.contactUsLinkText - The text for the contact us link
 * @param {string} this.config.strings.prescriberSearch.searchResultHelpHeader - The text for search result header
 * @param {string} this.config.strings.prescriberSearch.searchResultHelpBody - The text for search result body
 */
export default class PUIPrescriberPage extends PUIPage {
  connectedCallback() {
    super.connectedCallback();
    this._render();
  }

  _render() {
    this.classList.add('pui-block');

    const { pageType, strings, isMShop } = this.config;
    if (!strings.pageDescription) {
      strings.pageDescription = '';
    }
    if (!strings.pagePrompt) {
      strings.pagePrompt = '';
    }
    if (!strings.listTitle) {
      strings.listTitle = '';
    }
    this.config.pageStyle = (pageType === 'signup') ? 'card-form' : 'standard-form';
    const content = {
      main: `
        <pui-section spacingBottom="small">
          <pui-section flowDirection="horizontal" secondaryAxisArrangement="center">
            <pui-section-column>
              <pui-icon imgClass="${this.config.iconClass}" spacingRight="small"></pui-icon>
            </pui-section-column>
            <pui-section-column>
              <pui-heading input="${strings.pageTitle}" textSize="large"></pui-heading>
            </pui-section-column>
          </pui-section>

          <pui-text input="${strings.pageDescription}"
                    spacingTop="medium" textSize="medium" hideIfEmpty></pui-text>
          <pui-text input="${strings.pagePrompt}"
                    spacingTop="medium" textSize="large" fontWeight="bold" hideIfEmpty></pui-text>
        </pui-section>

        <div>
          <pui-text input="${strings.searchZip}" inline></pui-text>
          <pui-text id="apex-zip-display-text" input="${this.config.initialZip}" inline fontWeight="bold"></pui-text>
          <pui-link id="apex-zip-update-link" text="${strings.zipChangeLink}"></pui-link>
        </div>
        <pui-bottom-sheet id="apex-zip-updater-bottom-sheet" hideLink ${isMShop ? 'isMShop' : ''}>
          <div id="apex-zip-updater-container">
          </div>
        </pui-bottom-sheet>

        <div id="apex-prescriber-search-container" class="medium-margin-top">
        </div>

        <div id="apex-prescriber-list-section">
          <pui-text input="${strings.listTitle}" textSize="large" fontWeight="bold"
                    spacingTop="medium" spacingBottom="medium" hideIfEmpty></pui-text>

          <div id="apex-prescriber-list">
          </div>
        </div>
      `,
      footerDesktop: `
        <pui-button disabled id="apex-prescriber-save-button-desktop" label="${strings.saveButton}">
        </pui-button>
      `,
      footerMobile: `
        <pui-button disabled id="apex-prescriber-save-button-mobile" label="${strings.saveButton}">
        </pui-button>
      `,
    };
    super._render(content);

    this._prescriberList = this.querySelector('#apex-prescriber-list');
    this._prescriberListSection = this.querySelector("#apex-prescriber-list-section")
    this._prescriberSaveButtonDesktop = this.querySelector('#apex-prescriber-save-button-desktop');
    this._prescriberSaveButtonMobile = this.querySelector('#apex-prescriber-save-button-mobile');
    this._zipUpdaterBottomSheet = this.querySelector('#apex-zip-updater-bottom-sheet');
    this._zipDisplayText = this.querySelector('#apex-zip-display-text');
    this._fetchPrescribers();
    this._prescriberSearch = new PUIPrescriberSearch();
    this._prescriberSearch.configure({
      zipUpdaterId: 'apex-prescriber-zip-updater',
      searchUrl: this.config.searchUrl,
      searchQueryDelay: this.config.searchQueryDelay,
      contactUsUrl: this.config.contactUsUrl,
      nextUrl: this.config.nextUrl,
      strings: this.config.strings.prescriberSearch,
      callbacks: {
        onPrescriberSelect: this._onPrescriberSelect.bind(this),
      },
    });
    this.querySelector('#apex-prescriber-search-container').appendChild(this._prescriberSearch);

    this._zipUpdater = new PUIZipUpdater();
    this._zipUpdater.configure({
      id: 'apex-prescriber-zip-updater',
      initialZip: this.config.initialZip,
      getCoordinatesUrl: this.config.getCoordinatesUrl,
      strings: this.config.strings.zipUpdater,
      callbacks: {
        onCancel: this._onZipUpdaterCancel.bind(this),
        onApply: this._onZipUpdaterApply.bind(this),
      },
    });
    this.querySelector('#apex-zip-updater-container').appendChild(this._zipUpdater);
    this.querySelector('#apex-zip-update-link').addEventListener('click', this._onZipLinkPress.bind(this));

    this._prescriberSaveButtonDesktop.addEventListener('click', this._onPrescriberSave.bind(this));
    this._prescriberSaveButtonMobile.addEventListener('click', this._onPrescriberSave.bind(this));
    this._setSaveButtonState();
  }

  _fetchPrescribers() {
    if (this.config.pageType === 'signup') {
      Request.get(this.config.getPrimaryPrescriberUrl).then((response) => {
        const { doctor } = response;
        if (doctor) {
          this._addPrescriber(doctor);
        }
      });
    } else {
      Request.get(this.config.getPrescribersUrl).then((response) => {
        const { doctors } = response;
        doctors.reverse();
        doctors.forEach((doctor) => {
          this._addPrescriber(doctor);
        });
      });
    }
  }

  _onPrescriberSelect(prescriber) {
    this._addPrescriber(prescriber);
  }

  _addPrescriber(prescriber) {
    if (!this._isDuplicatePrescriber(prescriber)) {
      const prescriberItemConfig = {
        itemStyle: 'box',
        environment: this.config.environment ? this.config.environment : 'webapp',
        prescriber,
        callbacks: {
          onDelete: this._onPrescriberDelete.bind(this),
        },
      };

      if (this.config.pageType === 'signup') {
        this._prescriberList.innerHTML = '';
        prescriberItemConfig.itemStyle = 'box';
      }

      const prescriberItem = new PUIPrescriberItem();
      prescriberItem.configure(prescriberItemConfig);
      this._prescriberList.insertBefore(prescriberItem, this._prescriberList.firstChild);

      this._setSaveButtonState();
    }
  }

  _getPrescribers() {
    return [...this._prescriberList.querySelectorAll('pui-prescriber-item')]
      .map(prescriberItem => prescriberItem.getPrescriber());
  }

  _isDuplicatePrescriber(prescriber) {
    const prescribers = this._getPrescribers();
    return prescribers.some(prescriberItem => prescriberItem.spi === prescriber.spi);
  }

  _onPrescriberDelete() {
    this._setSaveButtonState();
  }

  _onZipLinkPress() {
    this._zipUpdaterBottomSheet.show();
  }

  _onZipUpdaterCancel() {
    this._zipUpdaterBottomSheet.hide();
  }

  _onZipUpdaterApply(zip) {
    this._zipDisplayText.setText(zip);
    this._zipUpdaterBottomSheet.hide();
  }

  _onPrescriberSave() {
    const prescribers = this._getPrescribers();
    const requestParams = (this.config.pageType === 'signup') ? { doctor: prescribers[0] } : { doctors: prescribers };
    if (this.config.environment === 'react') {
      if (this.config.callbacks) {
        const { onPrescriberAdd } = this.config.callbacks;
        if (onPrescriberAdd) {
          onPrescriberAdd(requestParams);
        }
      }
    } else {
      Request.post(this.config.actionUrl, requestParams).then(() => {
        window.location.href = this.config.nextUrl;
      });
    }
  }

  _setSaveButtonState() {
    if (this._prescriberList.querySelectorAll('pui-prescriber-item').length > 0) {
      this._prescriberSaveButtonDesktop.enable();
      this._prescriberSaveButtonMobile.enable();
      this._prescriberListSection.classList.remove('pui-hidden');
    } else {
      this._prescriberSaveButtonDesktop.disable();
      this._prescriberSaveButtonMobile.disable();
      this._prescriberListSection.classList.add('pui-hidden');
    }
  }
}

window.customElements.define('pui-prescriber-page', PUIPrescriberPage);
