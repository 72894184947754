import uuidv4 from 'uuid/v4';
import PUIBase from './pui-base';
import includes from '../functions/includes';
import getTextSize from '../attributeValues/textSize';
import keyCodes from '../constants/keyCodes';

export default class PUICheckbox extends PUIBase {
  constructor() {
    super();
    this.defaultClass = 'pui-checkbox';
  }

  connectedCallback() {
    super.connectedCallback();
    this.upgradeProperty('onCheckBoxToggle');
    this.render();
  }

  attributeChangedCallback(attributeName, oldValue, newValue) {
    if (this._innerInput) {
      this._innerInput.checked = this.checked;
    }
    if (attributeName === 'label' && this._innerTextInput) {
      this._innerTextInput.setAttribute('input', newValue);
      if (newValue) {
        this._innerTextInput.show();
      }
    }
  }

  static get observedAttributes() {
    return ['value', 'label', 'disabled', 'checked', 'textSize', 'elementValue', 'inputid'];
  }

  get label() {
    return this.getAttribute('label') || '';
  }

  set label(value) {
    this.setAttribute('label', value);
  }

  get value() {
    return this.getAttribute('value') || '';
  }

  set value(value) {
    this.setAttribute('value', value);
  }

  get disabled() {
    return this.getBooleanAttribute('disabled');
  }

  set disabled(value) {
    this.setBooleanAttribute('disabled', value, this._innerInput);
  }

  get checked() {
    return this.getBooleanAttribute('checked');
  }

  set checked(value) {
    this.setBooleanAttribute('checked', value, this._innerInput);
    const changeEvent = new Event('change');
    this._innerInput.dispatchEvent(changeEvent);
  }

  get textSize() {
    return this.getAttribute('textSize') || '';
  }

  set textSize(value) {
    this.setAttribute('textSize', value);
  }

  enable() {
    this.disabled = false;
  }

  disable() {
    this.disabled = true;
  }

  isDisabled() {
    return Boolean(this.disabled);
  }

  check() {
    this.checked = true;
  }

  uncheck() {
    this.checked = false;
  }

  toggle() {
    this.checked = !this.checked;
  }

  isChecked() {
    return Boolean(this.checked);
  }

  get elementValue() {
    return this.getAttribute('elementValue') || '';
  }

  set elementValue(value) {
    this.setAttribute('elementValue', value);
  }

  getElementValue() {
    return this.elementValue;
  }

  checkBoxInputChange() {
    if (this.onCheckBoxToggle) {
      this.onCheckBoxToggle(this.elementValue);
    }
  }

  toggleCheckBoxInput() {
    if (this.isChecked() === false) {
      this.check();
    } else {
      this.uncheck();
    }
  }

  /**
   * The id for the inner input element
   * @type {string}
   * @attr
   */
  get inputId() {
    return this.getAttribute('inputId') || '';
  }

  set inputId(value) {
    this.setAttribute('inputId', value);
  }

  render() {
    this.innerHTML = '';
    const {
      name,
      value,
      disabled,
      label,
      checked,
      inputId
    } = this;

    let {
      textSize,
    } = this;


    if (!includes(getTextSize(), this.textSize)) {
      textSize = this.defaultTextSize;
    }
    textSize = `${textSize}-font`;

    this.classList.add('pui-block');
    this._innerCheckbox = document.createElement('span');
    this._innerCheckbox.classList.add(this.defaultClass);

    const checkboxLabelId = `pui-checkbox-label-${uuidv4()}`;
    if (label) {
      this._innerCheckbox.tabIndex = 0;
    }
    // in order to get voiceover announcing the correct element, removed the `tabindex="-1"` from the `input` element
    this._innerCheckbox.innerHTML = `
      <input ${label !== '' ? `aria-labelledby=${checkboxLabelId}` : 'tabindex="-1"'} type="checkbox" name="${name}" value="${value}" ${disabled ? 'disabled' : ''} ${checked ? 'checked' : ''}> 
      <span class="checkmark ${disabled ? 'disabled' : ''}" ${label === '' ? 'tabindex="0"' : ''}></span>
      <pui-text id="${checkboxLabelId}" input="${label}" textSize="${textSize}"></pui-text>
    `;

    this._innerInput = this._innerCheckbox.firstElementChild;
    this._innerTextInput = this._innerCheckbox.lastElementChild;

    this.appendChild(this._innerCheckbox);

    if (this.isDisabled() === false) {
      this.addEventListener('click', function () {
        this.toggleCheckBoxInput();
      });
      this.addEventListener('keydown', function (event) {
        if (event.keyCode === keyCodes.SPACE_KEYCODE) {
          event.preventDefault();
        }
      });
      this.addEventListener('keyup', function (event) {
        if (event.keyCode === keyCodes.SPACE_KEYCODE) {
          this.toggleCheckBoxInput();
        }
      });
    }
    if (this.inputId) {
      this._innerInput.id = inputId;
    }

    this._innerInput.addEventListener('change', this.checkBoxInputChange.bind(this));
  }
}

window.customElements.define('pui-checkbox', PUICheckbox);
