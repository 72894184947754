import PUIBase from './pui-base';

export class PUISwipeCarousel extends PUIBase {
  constructor() {
    super();
    this.defaultClass = 'pui-carousel-swipe';
    this.defaultSize = 'small';
    this.cards = [];
    this.activeCard = null;
  }

  connectedCallback() {
    super.connectedCallback();

    const cardCountObserver = (mutationList, observer) => {
      for (const m of mutationList) {
        if (m.removedNodes?.length && this.cards.includes(m.removedNodes[0])) {
          // Card was removed
          const removedCard = m.removedNodes[0];

          if (this.activeCard === m.removedNodes[0]) {
            // Currently active card is being removed, set a new active card

            if (this.activeCardIndex === 0) {
              // First card being removed, make second card active
              this.activeCard = this.cards[1];
            } else {
              // Make previous card in list active
              this.activeCard = this.cards[this.activeCardIndex - 1];
            }

            // Ensure new active card is visible
            if (this.activeCard != null) {
              this.activeCard.hidden = false;
            }
          }
        }
        this.updateCards();
        this.updateNavDots();
        this.updateNavControls();
        this._renderCards();
      }
    };

    this.cardCountObserver = new MutationObserver(cardCountObserver);
    this.cardCountObserver.observe(this, { childList: true });

    this.render();
  }

  disconnectedCallback() {
    this.cardCountObserver.disconnect();
  }

  get cardsCount() {
    return this.cards.length;
  }

  get activeCardIndex() {
    return this.cards.indexOf(this.activeCard);
  }

  createNavDots() {
    this._navDotsList = document.createElement('ul');
    this._navDotsList.setAttribute('id', 'dots');
    this._navDotsList.classList.add('carousel-dots');

    this.cards.forEach((card, idx) => {
      this._dot = document.createElement('li');
      this._dot.classList.add('dot');
      if (idx === this.activeCardIndex) {
        this._dot.classList.add('dot-active');
      }
      this._navDotsList.appendChild(this._dot);
    });

    return this._navDotsList;
  }

  updateNavDots() {
    this._navDotsContainer.innerHTML = this.createNavDots().outerHTML;
  }

  updateCards() {
    // Filters out expected children added in the component
    this.cards = [...this.children].filter((c) => c.getAttribute('id') !== 'pui-carousel-swipe-nav-button-container');

    // Handle setting the active card on first render, or if all cards were removed, and then a new card is added
    if (this.cards.length && this.activeCard == null) {
      this.activeCard = this.cards[0];
    }
  }

  updateNavControls() {
    // hide prev or next buttons if on first or last card
    if (this.activeCardIndex === 0) {
      this._prevButton.classList.add('pui-hidden');
      this._prevButtonHover.classList.add('pui-hidden');
      this._nextButton.classList.remove('pui-hidden');
      this._nextButtonHover.classList.remove('pui-hidden');
    } else if (this.activeCardIndex === this.cardsCount - 1) {
      this._prevButton.classList.remove('pui-hidden');
      this._prevButtonHover.classList.remove('pui-hidden');
      this._nextButton.classList.add('pui-hidden');
      this._nextButtonHover.classList.add('pui-hidden');
    } else {
      this._prevButton.classList.remove('pui-hidden');
      this._prevButtonHover.classList.remove('pui-hidden');
      this._nextButton.classList.remove('pui-hidden');
      this._nextButtonHover.classList.remove('pui-hidden');
    }
  }

  activateDot(activeDotIdx) {
    const dots = this.nextElementSibling.querySelectorAll('.dot');
    dots.forEach((dot, idx) => {
      if (idx === activeDotIdx) {
        dot.classList.add('dot-active');
        this.children[idx].classList.add('focused-elem');
      } else {
        dot.classList.remove('dot-active');
        this.children[idx].classList.remove('focused-elem');
      }
    });
  }

  _onCarouselScroll() {
    const cardsCount = this.children.length;
    const cardWidth = this.scrollWidth / cardsCount;
    const focusedCardCount = this.scrollLeft / cardWidth;
    const focusedDotCount = Math.floor(focusedCardCount);

    this.activateDot(focusedDotCount);
  }

  _onCarouselNavigationClick() {
    this.cards.forEach((card, idx) => {
      // hide non-active cards
      if (idx !== this.activeCardIndex && card.getAttribute('id') !== 'pui-carousel-swipe-nav-button-container') {
        card.hidden = true;
      } else {
        card.hidden = false;
      }
    });
    this.activateDot(this.activeCardIndex);
    this.updateNavControls();
  }

  _renderMultipleCards() {
    // Show navigation dots and navigation buttons for mobile and desktop experience
    this._navDotsContainer.classList.remove('pui-hidden');
    this._navigationButtonsContainer.classList.remove('pui-hidden');

    this.cards.forEach((card) => card.classList.remove('pui-carousel-swipe-element-no-scroll'));
    this.classList.remove('pui-carousel-swipe-no-scroll');
  }

  _renderSingleCard() {
    // If there is only one card, simply show the card
    // This hides the navigation buttons, and the navigation dots
    this.firstElementChild?.classList.add('pui-carousel-swipe-element-no-scroll');
    this.classList.add('pui-carousel-swipe-no-scroll');

    this._navDotsContainer.classList.add('pui-hidden');
    this._navigationButtonsContainer.classList.add('pui-hidden');
  }

  _renderCards() {
    //navigation dots for mobile and desktop experience
    if (this._navDotsContainer == null) {
      this._navDotsContainer = document.createElement('div');
      this._navDotsContainer.setAttribute('id', 'pui-carousel-dots-container');
      this._navDotsContainer.classList.add('carousel-dots-container');
      this._navDotsContainer.innerHTML = this.createNavDots().outerHTML;
      this.after(this._navDotsContainer);
      // Add event listeners to scroll event and update active dot - mobile experience only
      this.addEventListener('scroll', this._onCarouselScroll.bind(this), { passive: true });
    } 

    //navigation buttons for tablet and desktop experience
    if (this._navigationButtonsContainer == null) {
      this._navigationButtonsContainer = document.createElement('div');
      this._navigationButtonsContainer.setAttribute('id', 'pui-carousel-swipe-nav-button-container');
      this._navigationButtonsContainer.classList.add('carousel-nav-button-container');
      this._navigationButtonsContainer.innerHTML = `
        <pui-icon id="prev-button" class="carousel-prev-button" imgClass="carousel-navigation-prev-button"></pui-icon>
        <pui-icon id="prev-button-hover" class="carousel-prev-button-hover" imgClass="carousel-navigation-prev-button-hover"></pui-icon>
        <pui-icon id="next-button" class="carousel-next-button" imgClass="carousel-navigation-next-button"></pui-icon>
        <pui-icon id="next-button-hover" class="carousel-next-button-hover" imgClass="carousel-navigation-next-button-hover"></pui-icon>
      `;
      this.appendChild(this._navigationButtonsContainer);

      // Add event listeners to nav buttons
      // previous button
      this._prevButton = this._navigationButtonsContainer.querySelector('#prev-button');
      this._prevButton.classList.add('pui-hidden');
      this._prevButtonHover = this._navigationButtonsContainer.querySelector('#prev-button-hover');
      this._prevButtonHover.addEventListener('click', (e) => {
        if (this.activeCardIndex > 0) {
          this.activeCard = this.cards[this.activeCardIndex - 1];
          this._onCarouselNavigationClick();
        }
      });
      // next button
      this._nextButton = this._navigationButtonsContainer.querySelector('#next-button');
      this._nextButtonHover = this._navigationButtonsContainer.querySelector('#next-button-hover');
      this._nextButtonHover.addEventListener('click', (e) => {
        if (this.activeCardIndex < this.cardsCount - 1) {
          this.activeCard = this.cards[this.activeCardIndex + 1];
          this._onCarouselNavigationClick();
        }
      });
    }

    this.cardsCount > 1 ? this._renderMultipleCards() : this._renderSingleCard();
  }

  render() {
    this.classList.add(this.defaultClass);
    this.setAttribute('id', 'pui-carousel-swipe-container');

    // Attach a Shadow DOM and set to 'open' to allows the Shadow DOM to be
    // accessed from outside the element - eg. pui-carousel-swipe-element
    this.attachShadow({ mode: 'open' });
    this.shadowRoot.innerHTML = `
        <slot>
          Inner Cards
        </slot>
      `;

    this.updateCards();
    this._renderCards();
    //calling navigation click to properly assign hidden property to hidden banners on first render
    this._onCarouselNavigationClick();
  }
}

window.customElements.define('pui-carousel-swipe', PUISwipeCarousel);
