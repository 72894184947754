import ESAPI from 'node-esapi';
import PUIBase from "../pui-base";

class PUIMilestoneItem extends PUIBase {
    connectedCallback() {
        super.connectedCallback();
        this.render();
    }

    attributeChangedCallback() {
        super.attributeChangedCallback();
        this.render();
    }

    static get observedAttributes() {
        return [
            'milestonename', 'milestonedate', 'ismilestonecompleted', 'ishidemilestonebar', 'isloading', 'milestonedescription'
        ];
    }

    /**
     * Is milestone completed? This boolean will determine what status icons to
     * display for each milestone
     * @type {boolean}
     * @attr
     */
    get isMilestoneCompleted() {
        return this.getBooleanAttribute('isMilestoneCompleted') || '';
    }

    set isMilestoneCompleted(value) {
        this.getBooleanAttribute('isMilestoneCompleted', value);
    }

    /**
     * This boolean determines whether to include tracker progress bar
     * @type {boolean}
     * @attr
     */
    get isHideMilestoneBar() {
        return this.getBooleanAttribute('isHideMilestoneBar') || '';
    }

    set isHideMilestoneBar(value) {
        this.getBooleanAttribute('isHideMilestoneBar', value);
    }    

    /**
     * Name of this milestone. This string will be displayed as the main 
     * text for a milestone item
     * @type {string}
     * @attr
     */
    get milestoneName() {
        return this.encodedValue(this.getAttribute('milestoneName')) || ''
    }

    set milestoneName(value) {
        this.setAttribute('milestoneName', value);
    }

    /**
     * Description of this milestone. This string will be displayed under the milestone name text
     * @type {string}
     * @attr
     */
    get milestoneDescription() {
        return this.encodedValue(this.getAttribute('milestoneDescription')) || '';
    }

    set milestoneDescription(value) {
        this.setAttribute('milestoneDescription', value);
    }

    /**
     * Date the milestone was reached, this is expected to be in the ISO 8601 format, i.e. "2023-09-21T09:37:23.594Z"
     * @type {string}
     * @attr
     */
    get milestoneDate() {
        return this.getAttribute('milestoneDate') || '';
    }

    set milestoneDate(value) {
        this.setAttribute('milestoneDate', value);
    }

    /**
     * Whether the milestone should display loading placeholders instead of text.
     * 
     * If true, any text attributes will be ignored and the component will instead
     * display pui loading gradients in their place.
     * 
     * @type {boolean}
     * @attr
     */
    get isLoading() {
        return this.getBooleanAttribute('isLoading');
    }

    set isLoading(value) {
        this.setBooleanAttribute('isLoading', value);
    }

    encodedValue(value) {
        return (value ? ESAPI.encoder().encodeForHTML(value) : '');
    }

    /**
     * Converts UTC timestamp such as "2023-09-21T09:37:23.594Z" to a formatted local DateTime such as: "Sep 21 4:37 AM" 
     * @returns string local DateTime in the format "MMM DD H:MM AM/PM"
     */
    getLocalDateTime() {
        if (this.milestoneDate == null || this.milestoneDate == '') {
            return "";
        }
        const dateFormatter = new Intl.DateTimeFormat('en-us', {
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
        });
        const date = new Date(this.milestoneDate);
        return dateFormatter.format(date).replace(/,/g, '');
    }

    render() {
        this.innerHTML = '';
        this.classList.add('pui-block');
        this._innerLineItem = document.createElement('div');

        let statusIcon = 'status-not-started-icon';
        let statusbarIcon = 'vertical-progress-bar-not-started-icon';
        if (this.isMilestoneCompleted) {
            statusIcon = 'status-success-icon';
            statusbarIcon = 'vertical-progress-bar-success-icon';
        }

        const localDate = this.getLocalDateTime();

        const lineItemContent = `
        <pui-section flowDirection="horizontal" mainAxisArrangement="space-between" secondaryAxisArrangement="start">
            <pui-section-column flexGrid="5">
                <pui-icon imgclass="${statusIcon}" spacingRight="mini" style="margin-top: 2px"></pui-icon>
                ${this.isHideMilestoneBar ? '' :
                    `<pui-icon imgclass="${statusbarIcon}" style="margin-top: 2px; margin-left: 6px"></pui-icon>`}
            </pui-section-column>
            <pui-section-column flexGrid="90">
                ${this.isLoading ? `
                    <div style='width:52px;display:inline-block' class='pui-loading-text-medium'></div>
                    <div style='width:97px;display:inline-block;align-self:center' class='pui-loading-text-small'></div>
                ` : `
                    <pui-text id="pui-milestone-name" input="${this.milestoneName}"
                        textSize="medium" hideIfEmpty spacingRight="mini" inline>
                    </pui-text>
                    <pui-text id="pui-milestone-date-time" class="pui-milestone-item-date-time"
                        input="${localDate}" inline
                        textSize="small" textColor="med-grey" hideIfEmpty style="align-self: center">
                    </pui-text>
                    <pui-text id="pui-milestone-description" class="pui-milestone-item-date-time"
                        input="${this.milestoneDescription}" textSize="small" hideIfEmpty>
                    </pui-text>
                `}
            </pui-section-column>
          </pui-section>
        `;

        this._innerLineItem.innerHTML = `${lineItemContent}`;
        this.appendChild(this._innerLineItem);
    }
}

window.customElements.define('pui-milestone-item', PUIMilestoneItem);
