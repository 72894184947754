import PUIBase from "../pui-base"
import {PUIGroupAccordionItem} from "./pui-group-accordion-item";

const defaultClass:String = 'pui-group-accordion';

export class PUIGroupAccordion extends PUIBase {
    static get observedAttributes() {
        return [
            ...super.observedAttributes,
            'nocollapse',
        ];
    }

    constructor() {
        super();
    }

    /**
     * This attribute defines the group accordion is collapsible or not
     * if noCollapse==="true" the accordion item is not collapsible
     * else it is collapsible.
     */
    get noCollapse() {
        return this.getBooleanAttribute('noCollapse') || false;
    }

    set noCollapse(value: true | false) {
        this.setBooleanAttribute('noCollapse', value);
    }

    connectedCallback() {
        super.connectedCallback();
        this.render();
        this.addEventListener('group-accordion-click', this.changeStateOfAllAccordionItems.bind(this));
        if (this.noCollapse) {
            this.setStateOfAllAccordionItemsToExpandedWithNoAccordionControl();
        } else {
            this.giveAccordionControlIfNotPresent();
        }
    }

    private render() {
        this.attachShadow({ mode: 'open' });
        this.shadowRoot!.innerHTML = `
          <style>
            :host {
              display: block;
            }
          </style>
          <div class="${defaultClass}">
            <slot>
                Inner Accordion Items
            </slot>
          </div>
        `;
    }

    private changeStateOfAllAccordionItems() {
        const groupAccordionItems: HTMLCollectionOf<Element> = this.getElementsByTagName('pui-group-accordion-item');
        for (let i = 0; i < groupAccordionItems.length; i += 1) {
            const groupAccordionItem: PUIGroupAccordionItem = <PUIGroupAccordionItem>groupAccordionItems[i];
            const notCollapsed: true | false = groupAccordionItem.getBooleanAttribute("notCollapsed");
            (notCollapsed!) ? groupAccordionItem.setBooleanAttribute("notCollapsed", false)
                : groupAccordionItem.setBooleanAttribute("notCollapsed", true) ;
        }
    }

    private setStateOfAllAccordionItemsToExpandedWithNoAccordionControl() {
        const groupAccordionItems: HTMLCollectionOf<Element> = this.getElementsByTagName('pui-group-accordion-item');
        for (let i = 0; i < groupAccordionItems.length; i += 1) {
            const groupAccordionItem: PUIGroupAccordionItem = <PUIGroupAccordionItem>groupAccordionItems[i];
            groupAccordionItem.setBooleanAttribute("notCollapsed", true);
            groupAccordionItem.setBooleanAttribute("hasAccordionControl", false);
        }
    }

    private giveAccordionControlIfNotPresent() {
        const groupAccordionItems: HTMLCollectionOf<Element> = this.getElementsByTagName('pui-group-accordion-item');
        let anyAccordionItemHasControl: boolean = false;
        for (let i = 0; i < groupAccordionItems.length; i += 1) {
            const groupAccordionItem: PUIGroupAccordionItem = <PUIGroupAccordionItem>groupAccordionItems[i];
            const hasAccordionControl: true | false = groupAccordionItem.getBooleanAttribute("hasAccordionControl");
            if(hasAccordionControl!) {
                anyAccordionItemHasControl = true;
                break;
            }
        }
        if(!anyAccordionItemHasControl) {
            const groupAccordionItem: PUIGroupAccordionItem = <PUIGroupAccordionItem>groupAccordionItems[0];
            groupAccordionItem.setBooleanAttribute("hasAccordionControl", true);
        }
    }
}

window.customElements.define('pui-group-accordion', PUIGroupAccordion);
