import PUIBase from './pui-base';

class PUICheckCardContainer extends PUIBase {
  constructor() {
    super();
    this.defaultClass = 'pui-check-card-container';
    this.defaultSize = 'small';
    this.defaultSpacingTop = 'medium';
  }

  connectedCallback() {
    super.connectedCallback();
    this.render();
  }

  get flowDirection() {
    return this.getAttribute('flowDirection');
  }

  set flowDirection(value) {
    this.setAttribute('flowDirection', value);
  }

  getElementValue() {
    return this.elementValue;
  }

  render() {
    this.classList.add('pui-block');
    this.classList.add(this.defaultClass);
    this.classList.add(`carousel-${this.flowDirection}`);
    this.elementValue = null;

    this.attachShadow({ mode: 'open' });
    this.shadowRoot.innerHTML = `
        <slot>
          Inner Check Cards
        </slot>
    `;

    this.shadowRoot.lastElementChild.onclick = (event) => {
      this.checkCardClicked(event);
    };
  }


  checkCardClicked(event) {
    const cardElements = this.getElementsByTagName('pui-check-card');
    let elementClickedIndex = -1;
    let currentFocusedIndex = -1;
    for (let i = 0; i < cardElements.length; i += 1) {
      const element = cardElements[i];
      if (element.contains(event.target)) {
        elementClickedIndex = i;
      }
      if (element.classList.contains('focused')) {
        currentFocusedIndex = i;
      }
    }
    if (elementClickedIndex >= 0) {
      const selectedElement = cardElements[elementClickedIndex];
      if (!selectedElement.disabled) {
        if (selectedElement.classList.contains('focused')) {
            selectedElement.classList.remove('focused');
            selectedElement.uncheck();
        } else {
            selectedElement.classList.add('focused');
            selectedElement.check();
        }
        this.elementValue = selectedElement.elementValue;
      }
    }
  }
}

window.customElements.define('pui-check-card-container', PUICheckCardContainer);
