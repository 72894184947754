import getBackgroundColor from '../attributeValues/backgroundColor';
import getDividerHeight from '../attributeValues/dividerHeight';
import includes from '../functions/includes';
import PUIBase from './pui-base';


export class PUIDivider extends PUIBase {
  constructor() {
    super();
    this.defaultClass = 'pui-divider';
    this.defaultBackgroundColor = 'grey';
    this.defaultDividerHeight = 'small';
  }

  connectedCallback() {
    super.connectedCallback();
    this.render();
  }

  static get observedAttributes() { return ['backgroundColor', 'dividerHeight']; }

  get backgroundColor() {
    return this.getAttribute('backgroundColor') || '';
  }

  set backgroundColor(value) {
    this.setAttribute('backgroundColor', value);
  }

  get dividerHeight() {
    return this.getAttribute('dividerHeight') || '';
  }

  set dividerHeight(value) {
    this.setAttribute('dividerHeight', value);
  }

  render() {
    let {
      backgroundColor,
      dividerHeight,
    } = this;
    this.innerHTML = '';

    if (!includes(getBackgroundColor(), this.backgroundColor)) {
      backgroundColor = this.defaultBackgroundColor;
    }
    backgroundColor = `${backgroundColor}-background-color`;

    if (!includes(getDividerHeight(), this.dividerHeight)) {
      dividerHeight = this.defaultDividerHeight;
    }
    dividerHeight = `${dividerHeight}-divider-height`;

    this.classList.add('pui-block');
    this.classList.add(this.defaultClass);
    this.classList.add(backgroundColor);
    this.classList.add(dividerHeight);
  }
}

window.customElements.define('pui-divider', PUIDivider);
