import PUIBase from '../pui-base';
import { injectPUIStyles } from "../../functions/domUtils";

const ESAPI = require('node-esapi').encoder();

class PUIProductCardSecondaryMessage extends PUIBase {
  constructor() {
    super();
    this.defaultClass = 'pui-product-card-secondary-message';
  }

  connectedCallback() {
    super.connectedCallback();
    this.render();
  }

  attributeChangedCallback() {
    this.render();
  }

  static get observedAttributes() {
    return ['icon', 'input', 'boldedinputcontent', 'righticonclass'];
  }

  get icon() {
    return this.getAttribute('icon');
  }

  set icon(value) {
    this.setAttribute('icon', value);
  }

  get input() {
    return this.getAttribute('input');
  }

  set input(value) {
    this.setAttribute('input', value);
  }

  get righticonclass() {
    return this.getAttribute('righticonclass');
  }
 
  set righticonclass(value) {
    return this.setAttribute('righticonclass', value);
  }

  /**
   * This bolded input content will always be at the start of input (but after icon)
   */
  get boldedinputcontent() {
    return this.getAttribute('boldedinputcontent');
  }

  set boldedinputcontent(value) {
    this.setAttribute('boldedinputcontent', value);
  }

  render() {
    const {
      icon,
      input,
      boldedinputcontent,
      righticonclass
    } = this;

    this.classList.add('pui-block');

    const encodedIcon = icon ? ESAPI.encodeForHTML(icon) : null;
    const encodedInput = input ? ESAPI.encodeForHTML(input) : null;
    const encodedBoldedInputContent = boldedinputcontent ? ESAPI.encodeForHTML(boldedinputcontent) : null;
    const encodedRightIcon = righticonclass ? ESAPI.encodeForHTML(righticonclass) : null;

    // icon to display on right of secondary message
    const rightIcon = encodedRightIcon ? `
      <pui-icon spacingRight="small" imgClass="${encodedRightIcon}"></pui-icon>` : '';

      if (!this.shadowRoot) {
        this.attachShadow({
          mode: 'open',
        });
      }

    this.shadowRoot.innerHTML = `
    <div class="pui-product-card-secondary-message" >
      <pui-section flowDirection="horizontal">
        ${encodedIcon ? `<pui-icon spacingRight="small" imgClass="${encodedIcon}"></pui-icon>` : ''}
        ${encodedBoldedInputContent ? 
          `<pui-text 
            spacingRight="mini"
            fontWeight="bold"
            input="${encodedBoldedInputContent}"
            style="min-width: max-content"
            inline
          ></pui-text>` : ''}
        <pui-section flowDirection="vertical">
          <pui-text input="${encodedInput}" inline></pui-text>
          <slot name="pui-secondary-message-subtext"></slot>
        </pui-section>
      </pui-section>
      ${rightIcon}
    </div>
    `;
    injectPUIStyles(this.shadowRoot);
  }
}

window.customElements.define('pui-product-card-secondary-message', PUIProductCardSecondaryMessage);
