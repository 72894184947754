import PUIBase from './pui-base';
import includes from '../functions/includes';

// eslint-disable-next-line import/no-unresolved
import listItemCSS from '../../css/_stack-item.scss?inline';

/**
 * A child for the pui-stack component.
 *
 * This component has a default slot and an "icon" slot.
 * The default slot is the content to the left that fills the horizontal space.
 * The icon slot is intended for a pui-icon (you can sub something else if desired).
 * The icon slot will only occupy as-much space as it needs with the rest going to the default slot.
 *
 * You may attach a click event here to handle a click of any component contained within.
 * This is useful as it will make the icon slot's content seem clickable too.
 *
 * You may also attach your click event handler to any child provided for any slot; however,
 * the user must then click that specific element.
 *
 * See the pui-stack for an example.
 */
export default class PUIStackItem extends PUIBase {
  /**
   * Specifies if the contents of the pui-stack-item-button will overflow.
   * @type {"horizontal"|"vertical"}
   * @attr
   */
  get overflow() {
    return this.getAttribute('overflow') || 'false';
  }

  constructor() {
    super();
  }

  connectedCallback() {
    super.connectedCallback();
    this.setAttribute('role', 'listitem');
    this.upgradeProperty('onclick');
    this._setupComponent();
  }

  _setupComponent() {
    const flex = this.overflow === 'true' ? '' : '1 0 auto';

    const templateHTML = `
      <style>
        ${listItemCSS}
      </style>
      <pui-section 
        class="pui-stack-item"
        flowDirection="horizontal" 
        mainAxisArrangement="space-between"
        secondaryAxisArrangement="stretch"
        style="flex-basis: 1 0 auto;">
          <pui-section-column flexGrid="${flex}">
            <slot></slot>
          </pui-section-column>
          <pui-section-column>
            <slot name="icon"></slot>
          </pui-section-column>
      </pui-section>
    `;

    const shadowRoot = this.attachShadow({
      mode: 'open',
    });

    const template = document.createElement('template');
    template.innerHTML = templateHTML;
    shadowRoot.appendChild(template.content.cloneNode(true));
  }
}

window.customElements.define('pui-stack-item', PUIStackItem);
