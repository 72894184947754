import PUIBase from './pui-base';


export default class PUILoadingIndicator extends PUIBase {
  constructor() {
    super();
    this.defaultClass = 'pui-loading-indicator';
    this.defaultSpinnerClass = 'spinner';
  }

  connectedCallback() {
    super.connectedCallback();
    this.render();
  }

  static get observedAttributes() {
    return ['button'];
  }

  get button() {
    return this.getBooleanAttribute('button');
  }

  set button(value) {
    this.setBooleanAttribute('button', value, this._innerButton);
  }

  get query() {
    return this.getBooleanAttribute('query');
  }

  set query(value) {
    this.setBooleanAttribute('query', value, this._innerButton);
  }

  render() {
    if (this.button || this.query) {
      this.defaultClass = 'pui-loading-indicator-contextual';
    }

    if (this.query) {
      this.defaultSpinnerClass = 'querySpinner';
    }

    this.classList.add(this.defaultClass);
    this.innerHTML = `
      <div class="${this.defaultSpinnerClass}">
        <div class="circle">
        </div>
      </div>
    `;
  }
}

window.customElements.define('pui-loading-indicator', PUILoadingIndicator);
