import PUIPage from '../pui-page';
import PUIDiscountCardItem from './pui-discount-card-item';

/**
 * This static page is used in conjunction with PUIDiscountCardItem to render a
 * detail page for a discount card, with card info and a brief disclaimer below.
 * 
 * Configuration:
 *
 * @param {PUIDiscountCardItemConfig} this.config - The configuration for DiscountCard
 * 
 * @typedef PUIBluesRxDetailPageConfig
 * @type {object}
 * @property {string} iconClass - The <pui-icon> type to render
 * @property {string} planName - The name of the discount card program, rendered as a title
 * @extends {import("./pui-discount-card-item").PUIDiscountCardItemConfig}
 */
export default class PUIBluesRxDetailPage extends PUIPage {
  constructor() {
    super();
  }

  connectedCallback() {
    super.connectedCallback();
    this.upgradeProperty('config');
    this.render();
  }

  attributeChangedCallback() {
    this.render();
  }

  render() {
    /**@type {PUIBluesRxDetailPageConfig}*/
    const config = this.config;
    const content = {
      main: `
        <!-- Heading -->
        <pui-section spacingBottom="small">
          <pui-section flowDirection="horizontal" secondaryAxisArrangement="center">
            <pui-section-column>
              <pui-icon imgClass="${config.iconClass}" spacingRight="small"></pui-icon>
            </pui-section-column>
            <pui-section-column>
              <pui-heading id="apex-discount-card-page-title" input="${config.planName}" textSize="large" fontWeight="bold"></pui-heading>
            </pui-section-column>
          </pui-section>
        </pui-section>
        
        <!-- Main section -->
        <pui-section id="apex-bluesrx-card-content-container"></pui-section>
      `,
      footerDesktop: ``,
      footerMobile: ``,
    }
    this._render(content);

    const container = this.querySelector("#apex-bluesrx-card-content-container");
    const card = this._renderDiscountCard();
    container.appendChild(card);
  }

  _renderDiscountCard() {
      let card = new PUIDiscountCardItem();
      card.configure(this.config);
      return card;
  }
}
// Register the new element with the browser.
window.customElements.define('pui-bluesrx-card-page', PUIBluesRxDetailPage);
