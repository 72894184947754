import PUIBase from '../pui-base';
import Request from '../../networking/request';
import keyCodes from '../../constants/keyCodes';
import generateVerificationFixUpFiledsMessage from '../../functions/parseInsuranceFixupFields';

/**
 * This class is responsible for displaying a single insurance entry.
 * If the insurance object is not passed in, this will show the "Continue without insurance" item
 *
 * Callbacks:
 *
 * Callback for when the trash icon is pressed
 * @callback onDelete
 *
 * Configuration:
 *
 * @param {InsuranceItemConfig} this.config - The configuration for the PUIInsuranceItem
 * @param {string} this.config.weblabPurchaseTwo - String indicating current weblab status for purchase 2.0 
 * @param {string} this.config.embeddable - Determines if this page should render in a embeddable format
 * @param {string} this.config.actionUrl - Action URL
 * @param {string} this.config.returnUrl - Determines if insurance should be editable
 * @param {string} this.config.saveInsuranceUrl - The url for creating/updating an insurance
 * @param {string} this.config.removeInsuranceUrl - The url used for removing insurances
 * @param {string} this.config.insurancePageUrl - THe url page for insurances in account settings
 * @param {boolean} this.config.isReadOnly - A boolean to determine if the page is read only or not
 * @param {Insurance} this.config.insurance - The insurance
 * @param {string} this.config.insurance.insuranceId - Pillpack generated id
 * @param {string} this.config.insurance.insuranceType - STANDARD/MEDICARE/COUPON
 * @param {string} this.config.insurance.insuranceState - INIT/VERIFIED/UNVERIFIED/ACTIVE/INACTIVE/DELETED
 * @param {string} this.config.insurance.insuranceVerificationStatus - VERIFIED/FAILED_TO_VERIFY/
 *                                                   VERIFICATION_NOT_REQUESTED/VERIFICATION_PENDING
 * @param {string} this.config.insurance.insuranceStatus - GREEN/YELLOW_SELF_SERVE_FIXUP/YELLOW_NO_SELF_SERVE_FIXUP/
 *                                                    YELLOW_TEMPORARY_FAILURE/RED_INSURANCE_NOT_CURRENT/
 *                                                    RED_INSURANCE_NOT_CONTRACTED
 * @param {string} this.config.insurance.insuranceName - Insurance Provider Name (ie. Premera)
 * @param {string} this.config.insurance.cardHolderId - Also known as the memberId
 * @param {string} this.config.insurance.groupNumber - Insurance card group number
 * @param {string} this.config.insurance.binNumber - Insurance card bin number
 * @param {string} this.config.insurance.pcn - Insurance card pcn
 * @param {InsuranceDate} this.config.insurance.startDate - { year, month, day } of insurance start date
 * @param {InsuranceDate} this.config.insurance.endDate - { year, month, day } of insurance end date
 * @param {string} this.config.insurance.insuranceRank - PRIMARY/SECONDARY/TERTIARY
 * @param {string} this.config.insurance.verificationFixUpFields - Claim rejection: the fileds needed to be updated in claim rejection [BIN, PCN, GROUP]
 * @param {string} this.config.insurance.rejectTypes - CUSTOMER_INSURANCE means there are fileds needed to be corredted
 *
 * @param {string} this.config.strings.memberIdLabel - The label indicator for memberId
 * @param {string} this.config.strings.memberIdHelpText - The help text to for memberIdHelpText
 * @param {string} this.config.strings.eligible - The label for a verified insurance
 * @param {string} this.config.strings.ineligible - The label for an unverified insurance
 * @param {string} this.config.strings.yellowStatus - Label for yellow insurance
 * @param {string} this.config.strings.yellowBody - Body for yellow insurance
 * @param {string} this.config.strings.redStatus - Label for red insurance
 * @param {string} this.config.strings.redBodyNotContracted - Body for red not contracted insurance
 * @param {string} this.config.strings.redBodyInactive - Body for red inactive insurance
 * @param {string} this.config.strings.greenBody - Body for green insurance
 * @param {string} this.config.strings.greyBody - Body for grey insurances
 * @param {string} this.config.strings.greyStatus - Label for grey statuses
 * @param {string} this.config.strings.insuranceCompanyNameLabel - Label for insurance company name
 * @param {string} this.config.strings.planSupportStatusTitleRed - Label for red title plan support status
 * @param {string} this.config.strings.planSupportStatusTitleGreen - Label for green title plan support status
 * @param {string} this.config.strings.planSupportStatusTitleGrey - Label for grey title plan support status
 * @param {string} this.config.strings.planSupportStatusBodyRed - Label for red body plan support status
 * @param {string} this.config.strings.planSupportStatusBodyGreen - Label for green body plan support status
 * @param {string} this.config.strings.planSupportStatusBodyGrey - Label for grey body plan support status
 * @param {string} this.config.strings.planSupportStatusSecondBodyGrey - Label for grey second body plan support status
 * @param {string} this.config.strings.planSupportStatusThirdBodyGrey - Label for grey third body plan support status
 * @param {string} this.config.strings.deleteButton - The delete button text for an insurance item
 * @param {string} this.config.strings.editButton - The edit button text for an insurance item
 * @param {string} this.config.strings.continueWithoutInsurance - The label for continuing without
 *                                                                              a selected insurance
 * @param {string} this.config.strings.continueWithoutInsuranceDescription - The description for continue without
 *                                                                              a selected insurance
 * @param {string} this.config.strings.verificationErrorTitle - The title for verification error banner in insurance card
 * @param {string} this.config.strings.verificationErrorMessageOne - The first part of desc for verification error banner in insurance card
 * @param {string} this.config.strings.verificationErrorMessageTwo - The second part of desc for verification error banner in insurance card
 * @param {string} this.config.strings.updateInsuranceAvailability - The text on the button to update an insurances availability for MI
 * @param {string} this.config.callbacks.onDelete - The callback for the delete button
 *
 */
export default class PUIInsuranceItem extends PUIBase {
  connectedCallback() {
    super.connectedCallback();
    this.upgradeProperty('config');
    this._render();
  }

  isSelected() {
    return this._radioButton.isChecked();
  }

  select() {
    this._insuranceItemContainer.select();
    this._radioButton.check();
  }

  unselect() {
    this._insuranceItemContainer.deselect();
    this._radioButton.uncheck();
  }

  remove() {
    this.parentNode.removeChild(this);
  }

  getInsurance() {
    return this.config.insurance;
  }

  _render() {
    const {
      insurance,
      strings,
    } = this.config;

    const isDashboard = this.config.pageType === 'dashboard';
    const isWeblabPurchaseTwo_T1 = this.config.weblabPurchaseTwo && this.config.weblabPurchaseTwo !== 'C';
    const isAddPersonCodeWeblabEnable = this.config.strings.weblabPharmacyAddPersonCodeInsurance === 'T1';
    this.classList.add('pui-block');
    if (insurance) {
      this.setAttribute('id', `apex-insurance-item-${insurance.insuranceId}`);
      let insuranceProviderNameHTML = '';
      if (this.config.pageType !== 'signup') {
        if (insurance.insuranceName) {
          insuranceProviderNameHTML = `
            <pui-section flowDirection="horizontal"
                               secondaryAxisArrangement="center"
                               spacingTop="small">
                <pui-text textSize="small" input="${insurance.insuranceName.toUpperCase()}">
                </pui-text>
            </pui-section>
          `;
        }
      }
      let boxTheme;
      let banner;
      let content;
      let secondContent = '';
      let thirdContent = '';
      let displaySecondThirdContent = false;
      let icon;
      let planSupportStatusHTML = '';
      let rejectionStatusHTML = '';
      let isInsurancePlanSupported = insurance.planSupportStatus === "SUPPORTED" || insurance.planSupportStatus === "SUPPORTED_UNKNOWN_PLAN";
      let displayVerificationErrorMessage = insurance.rejectTypes && insurance.verificationFixUpFields && insurance.rejectTypes[0] === 'CUSTOMER_INSURANCE' && insurance.verificationFixUpFields.length !== 0;
      let verificationFixUpFieldsMessage = '';
      verificationFixUpFieldsMessage = generateVerificationFixUpFiledsMessage(insurance.verificationFixUpFields);
      let planSupportStatusBanner;
      switch (insurance.planSupportStatus) {
        case 'SUPPORTED':
          boxTheme = 'success';
          icon = 'status-success-icon';
          banner = strings.planSupportStatusTitleGreen;
          content = strings.planSupportStatusBodyGreen;
          planSupportStatusBanner = `
          <pui-section class="insurance-card-accepted-section" flowDirection="horizontal" mainAxisArrangement="start">
            <pui-icon
              imgClass="dropdown-checkmark-icon"
              spacingTop="mini"
              spacingRight="mini"
              height="10px"
              width="10px"
              tabindex="0"
            ></pui-icon>
            <pui-text
              input="${strings.planSupportStatusTitleAccepted}"
              textColor="dark-teal"
              fontWeight="medium"
            ></pui-text>
          </pui-section>`;
          break;
        case 'SUPPORTED_UNKNOWN_PLAN':
          boxTheme = 'solid';
          icon = 'status-info-icon';
          displaySecondThirdContent = true;
          banner = strings.planSupportStatusTitleGrey;
          content = strings.planSupportStatusBodyGrey;
          secondContent = strings.planSupportStatusSecondBodyGrey;
          thirdContent = strings.planSupportStatusThirdBodyGrey;
          planSupportStatusBanner = `
          <pui-section class="insurnace-card-pending-section" flowDirection="vertical" mainAxisArrangement="start">
            <pui-text
              input="${strings.planSupportStatusTitlePending}"
              textColor="squid-ink"
              fontWeight="medium"
            ></pui-text>
            <pui-text
              input="${strings.planSupportStatusBodyPending}"
              textColor="squid-ink"
              spacingTop="mini"
            ></pui-text>
          </pui-section>`;
          break;
        default:
          boxTheme = 'error';
          icon = 'status-error-icon';
          banner = strings.planSupportStatusTitleRed;
          content = strings.planSupportStatusBodyRed;
          planSupportStatusBanner = `
          <pui-section class="insurance-card-unsupported-section" flowDirection="horizontal" mainAxisArrangement="start">
            <pui-text
              input="${strings.planSupportStatusTitleNotAccepted}"
              textColor="squid-ink"
              fontWeight="medium"
            ></pui-text>
          </pui-section>`;
      }

      rejectionStatusHTML = `
        <pui-section fullWidth spacingTop="medium">
          <pui-box theme="error" style="border-radius: 3px">
            <pui-section flowDirection="horizontal"
                mainAxisArrangement="start"
                secondaryAxisArrangement="center"
                fullWidth>
              <pui-section>
                <pui-section flowDirection="horizontal">
                  <pui-icon imgClass="status-error-icon" spacingRight="small"></pui-icon>
                  <pui-text textSize="small" fontWeight="bold" input="${strings.verificationErrorTitle}"></pui-text>
                </pui-section>
                <pui-section flowDirection="horizontal" spacingTop="mini">
                  <pui-icon imgClass="status-error-icon" spacingRight="small" style="visibility: hidden"></pui-icon>
                  <pui-text inline textSize="small" input="${strings.verificationErrorMessageOne}"></pui-text>
                  &nbsp;
                  <pui-text inline textSize="small" fontWeight="bold" input="${verificationFixUpFieldsMessage}"></pui-text>
                  &nbsp;
                  <pui-text inline textSize="small" input="${strings.verificationErrorMessageTwo}"></pui-text>
                </pui-section>
              </pui-section>
            </pui-section>
          </pui-box>
        </pui-section>
      `;

      planSupportStatusHTML = isAddPersonCodeWeblabEnable
        ? `${planSupportStatusBanner}`
        : `<pui-section fullWidth spacingTop="medium">
          <pui-box theme="${boxTheme}" style="border-radius: 3px">
            <pui-section flowDirection="horizontal"
                mainAxisArrangement="start"
                secondaryAxisArrangement="center"
                fullWidth>
              <pui-section>
                <pui-section flowDirection="horizontal">
                  <pui-icon imgClass="${icon}" spacingRight="small"></pui-icon>
                  <pui-text textSize="small" fontWeight="bold" input="${banner}"></pui-text>
                </pui-section>
                <pui-section flowDirection="horizontal" spacingTop="mini">
                  <pui-icon imgClass="${icon}" spacingRight="small" style="visibility: hidden"></pui-icon>
                  <pui-text textSize="small" input="${content}"></pui-text>
                </pui-section>
                <br class = "${displaySecondThirdContent ? '' : 'pui-hidden'}"/>
                <pui-section flowDirection="horizontal" spacingTop="mini" class = "${displaySecondThirdContent ? '' : 'pui-hidden'}">
                  <pui-icon imgClass="${icon}" spacingRight="small" style="visibility: hidden"></pui-icon>
                </pui-section>
              </pui-section>
            </pui-section>
          </pui-box>
        </pui-section>
      `;
      const insuranceDetailHTML_ControlTreatment = `
      <pui-section flowDirection="horizontal"
        mainAxisArrangement="start"
        secondaryAxisArrangement="center"
        fullWidth>
        <pui-radio-button class="apex-insurance-radio-button pui-radio-align-top ${(this.config.isReadOnly || isDashboard) ? 'pui-hidden' : ''}" tabindex="-1"></pui-radio-button>
        <pui-section spacingLeft="mini">
          <pui-text textSize="medium" fontWeight="bold" input="${strings.memberIdLabel}"></pui-text>
        </pui-section>
        <pui-section>
          <pui-text textSize="medium" input="${insurance.cardHolderId}"></pui-text>
        </pui-section>
        <pui-icon id="apex-insurance-item-ellipses-${insurance.insuranceId}" class="apex-insurance-options-expander ${this.config.isReadOnly ? 'pui-hidden' : ''}" imgClass="ellipsis-icon" spacingLeft="mini" tabindex="0"></pui-icon>
      </pui-section>
      `;
      const insuranceDetailHTML_Treatment = `
      <pui-section flowDirection="horizontal"
        mainAxisArrangement="start"
        secondaryAxisArrangement="top"
        fullWidth>
        <pui-radio-button class="apex-insurance-radio-button pui-radio-align-top ${(this.config.isReadOnly || isDashboard) ? 'pui-hidden' : ''}" tabindex="-1" ${isInsurancePlanSupported ? '' : 'disabled'}></pui-radio-button>
        <pui-section spacingLeft="med-small" flowDirection="vertical">
          <pui-text id="apex-insurance-member-id-label-${insurance.insuranceId}" textSize="small" input="${strings.memberIdLabel}" style="text-transform: uppercase"></pui-text>
          <pui-text id="apex-insurance-member-id-${insurance.insuranceId}" textSize="medium" fontWeight="bold" input="${insurance.cardHolderId}"></pui-text>
          <pui-text id="apex-insurance-member-id-help-${insurance.insuranceId}" spacingTop="small" textSize="small" input="${strings.memberIdHelpText}"></pui-text>
        </pui-section>
        <pui-icon id="apex-insurance-item-ellipses-${insurance.insuranceId}" class="apex-insurance-options-expander ${this.config.isReadOnly ? 'pui-hidden' : ''}" imgClass="ellipsis-icon" spacingLeft="mini" tabindex="0"></pui-icon>
      </pui-section>
      `;

      let insuranceItemEditDeleteHTML_ControlTreatment = `
      <pui-section-column flexGrid="32"></pui-section-column>
        <pui-section-column flexGrid="32">
          <pui-button id="apex-insurance-item-delete-button-${insurance.insuranceId}"
                      class="apex-insurance-delete-button" label="${strings.deleteButton}"
                      theme="secondary" textColor="red-color"></pui-button>
        </pui-section-column>
      <pui-section-column flexGrid="4"></pui-section-column>
        <pui-section-column flexGrid="32">
          <pui-button id="apex-insurance-item-edit-button-${insurance.insuranceId}"
                      class="apex-insurance-edit-button" label="${strings.editButton}"
                      theme="secondary" textColor="black-color"></pui-button>
        </pui-section-column>
      `;
      let insuranceItemEditDeleteHTML_Treatment = `
      <pui-section-column flexGrid="${isInsurancePlanSupported ? '32' : '68'}"></pui-section-column>
      <pui-section-column flexGrid="32">
        <pui-button id="apex-insurance-item-delete-button-${insurance.insuranceId}"
                    class="apex-insurance-delete-button" label="${strings.deleteButton}"
                    theme="secondary" textColor="red-color"></pui-button>
      </pui-section-column>
      <pui-section-column flexGrid="4" class="${isInsurancePlanSupported ? '' : 'pui-hidden'}"></pui-section-column>
      <pui-section-column flexGrid="32" class="${isInsurancePlanSupported ? '' : 'pui-hidden'}">
        <pui-button id="apex-insurance-item-edit-button-${insurance.insuranceId}"
                    class="apex-insurance-edit-button" label="${strings.editButton}"
                    theme="secondary" textColor="black-color"></pui-button>
      </pui-section-column>
      `;

      let carouselElementHTML_ControlTreatment = `
      <pui-carousel-element class="apex-insurance-item-container no-background" flowDirection="vertical" 
                                  ${this.config.isReadOnly ? 'disabled' : ''}>
      `;
      let carouselElementHTML_Treatment = `
      <pui-carousel-element class="apex-insurance-item-container no-background" flowDirection="vertical" 
                                  ${this.config.isReadOnly ? 'disabled' : ''}
                                  ${isInsurancePlanSupported ? '' : 'disabled'}
                                  style="${isAddPersonCodeWeblabEnable ? 'padding: 0px; overflow: hidden;' : ''}"
                                  >
      `;

      this.innerHTML = isAddPersonCodeWeblabEnable
        ? `<pui-section secondaryAxisArrangement="center">
        <pui-section spacingBottom="medium">
          <pui-section>
            ${carouselElementHTML_Treatment}

              <!-- Insurance Details -->
                <pui-section>
    
                  <pui-section>
                    <!-- Insurance Cardholder Id -->
                      <pui-section style="padding: 15px;" flowDirection="vertical" mainAxisArrangement="space-between">
                        <pui-section flowDirection="horizontal" mainAxisArrangement="space-between" secondaryAxisArrangement="top">
                          <pui-radio-button class="apex-insurance-radio-button pui-radio-align-top ${(this.config.isReadOnly || isDashboard) ? 'pui-hidden' : ''}" tabindex="-1" ${isInsurancePlanSupported ? '' : 'disabled'}></pui-radio-button>
                          <pui-section width="25%">
                            <pui-text textSize="large" input="${strings.memberIdLabel}" textColor="grey" style="line-height: 20px;"></pui-text>
                            ${insurance.personCode ? `<pui-text textSize="large" input="${strings.suffixLabel}" textColor="grey" spacingTop="mini" style="line-height: 20px;"></pui-text>` : ''}
                            ${insurance.binNumber ? `<pui-text textSize="large" input="${strings.rxBinLabel}" textColor="grey" spacingTop="mini" style="line-height: 20px;"></pui-text>` : ''}
                            ${insurance.pcn ? `<pui-text textSize="large" input="${strings.rxPcnLabel}" textColor="grey" spacingTop="mini" style="line-height: 20px;"></pui-text>` : ''}
                            ${insurance.groupNumber ? `<pui-text textSize="large" input="${strings.rxGroupLabel}" textColor="grey" spacingTop="mini" style="line-height: 20px;"></pui-text>` : ''}
                          </pui-section>
                          <pui-section width="60%">
                            <pui-text textSize="medium" input=${insurance.cardHolderId}></pui-text>
                            ${isAddPersonCodeWeblabEnable && insurance.personCode ? `<pui-text textSize="medium" input=${insurance.personCode} spacingTop="mini"></pui-text>` : ''}
                            ${insurance.binNumber ? `<pui-text textSize="medium" input=${insurance.binNumber} spacingTop="mini"></pui-text>` : ''}
                            ${insurance.pcn ? `<pui-text textSize="medium" input=${insurance.pcn} spacingTop="mini"></pui-text>` : ''}
                            ${insurance.groupNumber ? `<pui-text textSize="medium" input=${insurance.groupNumber} spacingTop="mini"></pui-text>` : ''}
                          </pui-section>
                          <pui-section width="5%" tabindex="0" paddingRight="medium">
                            <pui-icon id="apex-insurance-item-ellipses-${insurance.insuranceId}" class="apex-insurance-options-expander ${this.config.isReadOnly ? 'pui-hidden' : ''}" imgClass="ellipsis-icon" tabindex="0"></pui-icon>
                          </pui-section>

                        </pui-section>
                      </pui-section>

                    <!-- Insurance Verification Status -->
                    ${displayVerificationErrorMessage && isWeblabPurchaseTwo_T1 ? rejectionStatusHTML : planSupportStatusHTML}
                  </pui-section>
                </pui-section>
                <pui-button class="apex-update-insurance-button ${isDashboard && isInsurancePlanSupported && this.config.insurance.insuranceState !== 'ACTIVE' ? '' : 'pui-hidden'}" 
                    label="${strings.updateInsuranceAvailability}"
                    spacingTop="small"></pui-button>
              </pui-carousel-element>
              
            </pui-section>
          
          <pui-section class="apex-insurance-edit-remove-section pui-hidden" 
                       flowDirection="horizontal" spacingTop="small" spacingBottom="small">
            ${insuranceItemEditDeleteHTML_Treatment}
          </pui-section>
        </pui-section>
      </pui-section>
      ` : `<pui-section secondaryAxisArrangement="center">
        <pui-section spacingBottom="medium">
          <pui-section>
            ${carouselElementHTML_Treatment}

              <!-- Insurance Details -->
                <pui-section flowDirection="horizontal"
                            mainAxisArrangement="space-between"
                            secondaryAxisArrangement="center"
                            fullWidth>
    
                  <pui-section>
                    <!-- Insurance Cardholder Id -->
                    ${insuranceDetailHTML_Treatment} 
                    <!-- Insurance Verification Status -->
                    ${displayVerificationErrorMessage && isWeblabPurchaseTwo_T1 ? rejectionStatusHTML : planSupportStatusHTML}
                  </pui-section>
                </pui-section>
                <pui-button class="apex-update-insurance-button ${isDashboard && isInsurancePlanSupported && this.config.insurance.insuranceState !== 'ACTIVE' ? '' : 'pui-hidden'}" 
                    label="${strings.updateInsuranceAvailability}"
                    spacingTop="small"></pui-button>
              </pui-carousel-element>
              
            </pui-section>
          
          <pui-section class="apex-insurance-edit-remove-section pui-hidden" 
                       flowDirection="horizontal" spacingTop="small" spacingBottom="small">
            ${insuranceItemEditDeleteHTML_Treatment}
          </pui-section>
        </pui-section>
      </pui-section>
      `;

      if (!this.config.isReadOnly) {
        this._optionsExpander = this.querySelector('.apex-insurance-options-expander');
        this._optionsExpander.addEventListener('click', this._optionsExpanderClick.bind(this));
        this._optionsExpander.addEventListener('keyup', this._optionsExpanderPress.bind(this));
        this._editRemoveSection = this.querySelector('.apex-insurance-edit-remove-section');

        this._deleteButton = this.querySelector('.apex-insurance-delete-button');
        this._deleteButton.addEventListener('click', this._deletePress.bind(this));

        this._editButton = this.querySelector('.apex-insurance-edit-button');
        this._editButton.addEventListener('click', this._editPress.bind(this));

        this._updateInsuranceButton = this.querySelector('.apex-update-insurance-button');
        this._updateInsuranceButton.addEventListener('click', this._updatePress.bind(this));
      }
    } else {
      this.setAttribute('id', 'apex-continue-without-insurance-option');
      this.innerHTML = `
      <pui-section secondaryAxisArrangement="center">
        <pui-section spacingBottom="small">
          <pui-carousel-element class="apex-insurance-item-container no-background" flowDirection="vertical"
                                ${this.config.isReadOnly ? 'disabled' : ''}>
            <pui-section flowDirection="horizontal" mainAxisArrangement="space-between" 
                         secondaryAxisArrangement="center">
              <pui-radio-button class="apex-insurance-radio-button pui-radio-align-top ${this.config.isReadOnly ? 'pui-hidden' : ''}" tabindex="-1"}></pui-radio-button>
              <pui-section spacingLeft="med-small" flowDirection="vertical">
                <pui-text textSize="medium" fontWeight="bold" input="${strings.continueWithoutInsurance}"></pui-text>
                <pui-section class="${''}">
                  <pui-text textSize="small" input="${strings.continueWithoutInsuranceDescription}"></pui-text>
                </pui-section>
              </pui-section>
            </pui-section>
          </pui-carousel-element>
        </pui-section>
      </pui-section>
      `;
    }
    this._insuranceItemContainer = this.querySelector('.apex-insurance-item-container');
    this._insuranceItemContainer.addEventListener('click', this._insuranceItemClick.bind(this));
    this._insuranceItemContainer.addEventListener('keyup', this._insuranceItemPress.bind(this));

    this._radioButton = this.querySelector('.apex-insurance-radio-button');
  }

  /**
   * Responsible for checking this radio button
   */
  _insuranceItemClick() {
    const radioButtons = document.querySelectorAll('.apex-insurance-radio-button');
    [...radioButtons].forEach((radioButton) => {
      radioButton.uncheck();
    });
    this._radioButton.check();
  }

  _insuranceItemPress(event) {
    if (event.keyCode === keyCodes.ENTER_KEYCODE || event.keyCode === keyCodes.SPACE_KEYCODE) {
      this._insuranceItemClick();
    }
  }

  /**
   * Responsible for opening up the edit/delete section
   */
  _optionsExpanderClick() {
    const editRemoveSections = document.querySelectorAll('.apex-insurance-edit-remove-section');
    [...editRemoveSections].forEach((editRemoveSection) => {
      if (editRemoveSection !== this._editRemoveSection) {
        editRemoveSection.hide();
      }
    });
    this._editRemoveSection.toggleVisibility();
  }

  _optionsExpanderPress(event) {
    if (event.keyCode === keyCodes.ENTER_KEYCODE) {
      this._optionsExpanderClick();
    }
  }

  /**
   * Make a call to delete this insurance and then remove it from the page
   */
  _deletePress() {
    if (this._deleteButton.isEnabled()) {
      this._deleteButton.disable();
      this._deleteButton.displaySpinner();
      Request.delete(this.config.removeInsuranceUrl, { insuranceId: this.config.insurance.insuranceId })
        .then(() => {
          this.parentNode.removeChild(this);

          const { onDelete } = this.config.callbacks;
          if (onDelete) {
            onDelete();
          }
        }).catch((response) => {
          const insurancePage = document.querySelector('pui-insurance-page');
          insurancePage.showError(response.error);
        });
    }
  }

  _updatePress() {
    const requestBody = {};
    requestBody.insuranceId = this.config.insurance.insuranceId;
    Request.post(this.config.actionUrl, requestBody).then(() => {
      window.location.href = this.config.insurancePageUrl;
    }).catch((response) => {
      this.showError(response.error);
    });
  }

  /**
   * Creates and opens up the insurance saver
   */
  _editPress() {
    document.querySelector('pui-insurance-page').openInsuranceSaver(this.config.insurance, "edit");
  }

  _reviewPress() {
    document.querySelector('pui-insurance-page').openInsuranceSaver(this.config.insurance, "review");
  }
}

window.customElements.define('pui-insurance-item', PUIInsuranceItem);
