import PUIBase from '../pui-base';
import keyCodes from '../../constants/keyCodes';

/**
 * This class is responsible for displaying a single prescriber entry on the prescriber page
 *
 * Callbacks:
 *
 * Callback for when the trash icon is pressed
 * @callback onDelete
 *
 * Configuration:
 *
 * @param {PrescriberItemConfig} this.config - The configuration for this PUIPrescriberItem
 * @param {string} this.config.itemStyle - Either 'box' or 'divider'
 * @param {Prescriber} this.config.prescriber - The prescriber
 * @param {string} this.config.prescriber.npi - National Provider Identifier (only used in signup for insurance)
 * @param {string} this.config.prescriber.spi - Surescripts Provider Identifier
 * @param {string} this.config.prescriber.firstName - The prescriber's first name
 * @param {string} this.config.prescriber.lastName - The prescriber's last name
 * @param {string} this.config.prescriber.suffix - The prescriber's suffix
 * @param {string} this.config.prescriber.phoneNumber - The prescriber's phone number
 * @param {string} this.config.prescriber.addressLineOne - The prescriber clinic's address line one
 * @param {string} this.config.prescriber.addressLineTwo - The prescriber clinic's address line two
 * @param {string} this.config.callbacks.onDelete - The callback for the delete button
 *
 */
export default class PUIPrescriberItem extends PUIBase {
  connectedCallback() {
    super.connectedCallback();
    this.upgradeProperty('config');
    this._render();
  }

  getPrescriber() {
    return this.config.prescriber;
  }

  _formatPhoneNumber(phoneNumberString) {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3]
    }
    return null;
  }

  _render() {
    const {
      prescriber,
      itemStyle,
      environment
    } = this.config;

    this.classList.add('pui-block');
    const suffix = (prescriber.suffix) ? `, ${prescriber.suffix}` : '';

    if (environment === "react") { // react version.
      const baseHTML = `
        <pui-section flowDirection="horizontal" mainAxisArrangement="space-between" secondaryAxisArrangement="center">
          <pui-section>
            <pui-text input="${prescriber.firstName} ${prescriber.lastName} ${suffix}"
                      fontWeight="bold"></pui-text>
            <pui-text input="${prescriber.addressLineOne}"></pui-text>
            <pui-text input="${prescriber.addressLineTwo}"></pui-text>
            <pui-text input="${this._formatPhoneNumber(prescriber.businessPracticeAddressTelephoneNumber)}"></pui-text>
          </pui-section>

        </pui-section>
      `;
      if (itemStyle === 'box') {
        this.innerHTML = `
          <pui-box spacingTop="small" selected>
            <pui-section-column flexGrid="2">
            <pui-radio-button checked spacingTop="small"></pui-radio-button>
            </pui-section-column>

            <pui-section-column flexGrid="5">
              ${baseHTML}
            </pui-section-column>
          </pui-box>
        `;
      } else {
        this.innerHTML = `
          <pui-section spacingTop="small">
            ${baseHTML}
          </pui-section>
          <pui-divider spacingTop="medium"></pui-divider>
        `;
      }

    } else { // Webapp version. Will be removed once we fully transition over to react
      const baseHTML = `
      <pui-section flowDirection="horizontal" mainAxisArrangement="space-between" secondaryAxisArrangement="center">
        <pui-section>
          <pui-text input="${prescriber.firstName} ${prescriber.lastName} ${suffix}"
                    fontWeight="bold"></pui-text>
          <pui-text input="${prescriber.addressLineOne}"></pui-text>
          <pui-text input="${prescriber.addressLineTwo}"></pui-text>
        </pui-section>

        <pui-box id="apex-delete-prescriber-button" style="padding: 0; width: 35px; display: flex">
          <pui-icon imgClass="trash-icon"></pui-icon>
        </pui-box>
      </pui-section>
    `;
    if (itemStyle === 'box') {
      this.innerHTML = `
        <pui-box spacingTop="small">
          ${baseHTML}
        </pui-box>
      `;
    } else {
      this.innerHTML = `
        <pui-section spacingTop="small">
          ${baseHTML}
        </pui-section>
        <pui-divider spacingTop="medium"></pui-divider>
      `;
    }

    this._deleteButton = this.querySelector('#apex-delete-prescriber-button');
    this._deleteButton.addEventListener('click', this._onDelete.bind(this));
    this._deleteButton.addEventListener('keyup', this._onDeletePress.bind(this));
    }
  }

  _onDeletePress(event) {
    if (event.keyCode === keyCodes.ENTER_KEYCODE) { // Pressed Enter
      this._onDelete();
    }
  }

  _onDelete() {
    this.parentNode.removeChild(this);
    const { onDelete } = this.config.callbacks;
    if (onDelete) {
      onDelete();
    }
  }
}

window.customElements.define('pui-prescriber-item', PUIPrescriberItem);
