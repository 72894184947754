import PUIBase from './pui-base';

/**
 * This is the PUICarouselCard. This component can be nested inside pui-carousel-elements 
 * to create a card in the carousel
 */

class PUICarouselCard extends PUIBase {
    constructor() {
        super();
        this.defaultClass = 'pui-carousel-card';
    }

    connectedCallback() {
        super.connectedCallback();
        this.render();
    }

    static get observedAttributes() {
        return ['title', 'descriptionLine1', 'descriptionLine2', 'descriptionLine3', 'contentType', 'bigText', 'smallText', 'badgeText'];
    }

    /**
     * This is the heading text for the card
     * @returns {string|string}
     */
    get title() {
        return this.getAttribute('title') || '';
    }
    
    set title(value) {
        this.setAttribute('title', value);
    }

    /**
     * This is the subtext line 1 within the body of the card
     * @returns {string|string}
     */
    get descriptionLine1() {
        return this.getAttribute('descriptionLine1') || '';
    }

    set descriptionLine1(value) {
        this.setAttribute('descriptionLine1', value);
    }

    /**
     * This is the subtext line 2 within the body of the card
     * @returns {string|string}
     */
    get descriptionLine2() {
        return this.getAttribute('descriptionLine2') || '';
    }

    set descriptionLine2(value) {
        this.setAttribute('descriptionLine2', value);
    }

    /**
     * This is the subtext line 3 within the body of the card
     * @returns {string|string}
     */
    get descriptionLine3() {
        return this.getAttribute('descriptionLine3') || '';
    }

    set descriptionLine3(value) {
        this.setAttribute('descriptionLine3', value);
    }

    /**
     * This is establishes the content type of the card, allowing us to control the size of different texts
     * 
     * Valid options: price|prescriber
     * @returns {string|string}
     */
    get contentType() {
        return this.getAttribute('contentType') || '';
    }

    set contentType(value) {
        this.setAttribute('contentType', value);
    }

    /**
     * If provided, this will show the bigText in a larger font on the left
     * @returns {string|string}
     */
    get bigText() {
        return this.getAttribute('bigText') || '';
    }

    set bigText(value) {
        this.setAttribute('bigText', value);
    }

    /**
     * If provided, this will show the smallText to the right side of the bigText
     * @returns {string|string}
     */
    get smallText() {
        return this.getAttribute('smallText') || '';
    }

    set smallText(value) {
        this.setAttribute('smallText', value);
    }

    /**
     * If provided, this will display the badge in the top right with the badgeText
     * @returns {string|string}
     */
    get badgeText() {
        return this.getAttribute('badgeText') || '';
    }

    set badgeText(value) {
        this.setAttribute('badgeText', value);
    }


    render() {
        const {
            title,
            descriptionLine1,
            descriptionLine2,
            descriptionLine3,
            contentType,
            bigText,
            smallText,
            badgeText
        } = this;
        this._innerCard = document.createElement('pui-section');
        this._innerCard.classList.add(this.defaultClass);
        this._innerCard.classList.add(`${this.defaultClass}-type-${contentType}`);
        this._innerCard.setAttribute('backgroundColor', 'white');
        this._innerCard.innerHTML = `
            ${badgeText ? 
                `<pui-badge
                    text="${badgeText}"
                    textColor="white"
                    textSize="small"
                    fontWeight="bold"
                    backgroundColor="green"
                    badgeType="upper-right-tab"
                ></pui-badge>`
             : ''}
            <pui-heading
                class="pui-carousel-card-heading"
                input="${title}"
                textSize="small"
                padding="small"
                textAlign="left"
            ></pui-heading>
            ${bigText ? 
                `<pui-section flowDirection="horizontal" secondaryAxisArrangement="baseline" paddingTop="small">
                    <pui-text
                        input="${bigText}"
                        textSize="double-extra-large"
                        paddingLeft="small"
                        inline
                    ></pui-text>
                    ${smallText ? 
                        `<pui-text
                            input="${smallText}"
                            textSize="small"
                            paddingLeft="small"
                        ></pui-text>`
                     : ''}
                </pui-section>`
             : ''}
            <pui-section padding="small">
                ${ descriptionLine1 ?
                `<pui-text input="${descriptionLine1}" textSize=${contentType === 'price' ? 'small' : 'medium'} padding="none"></pui-text>`
                : ''}
                ${ descriptionLine2 ?
                `<pui-text input="${descriptionLine2}" textSize="medium" padding="none"></pui-text>`
                : ''}
                ${ descriptionLine3 ?
                `<pui-text input="${descriptionLine3}" textSize="medium" padding="none"></pui-text>`
                : ''}
            </pui-section>
            `;
        this.appendChild(this._innerCard);
        
    }
}

window.customElements.define('pui-carousel-card', PUICarouselCard);
