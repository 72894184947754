import PUIBase from '../pui-base';

/**
 * This error box will show up when there is a page level error
 *
 */
export default class PUIErrorBox extends PUIBase {
  connectedCallback() {
    super.connectedCallback();
    this._render();
  }

  setTitle(titleText) {
    this._errorTitle.setText(titleText);
    this._errorTitle.show();
  }

  setDescription(descriptionText) {
    this._errorDescription.setText(descriptionText);
    this._errorDescription.show();
  }

  get title() {
    return this.getAttribute('title') || '';
  }

  set title(value) {
    this.setAttribute('title', value);
  }

  get description() {
    return this.getAttribute('description') || '';
  }

  set description(value) {
    this.setAttribute('description', value);
  }

  _render() {
    this.classList.add('pui-block');
    this.innerHTML = `
    <pui-box theme="alert" spacingBottom="small">
      <pui-text id="error-title" input="${this.title}" fontWeight="bold" textColor="red"></pui-text>
      <pui-text id="error-description" input="${this.description}"></pui-text>
    </pui-box>
    `;
    this._errorTitle = this.querySelector('#error-title');
    this._errorDescription = this.querySelector('#error-description');
  }
}

window.customElements.define('pui-error-box', PUIErrorBox);
