import { html, render } from "lit-html";
import PUIBase from "./pui-base";
import { injectPUIStyles } from "../functions/domUtils";
import PUILoadingIndicator from "./pui-loading-indicator";
import { CancelToken } from "../functions/asyncUtils";
import PUISection from "./pui-section";
import PUIText from "./pui-text";
import { PUIDivider } from "./pui-divider";
import { keyListener } from "../functions/domUtils";
import { PUILink } from "./pui-link";
import {ref, createRef, Ref} from 'lit/directives/ref.js';
import PUIInput from "./pui-input";

const DEFAULT_DELAY_MS = 200;
const DEFAULT_MINIMUM_CHARACTERS = 2;
const INPUT_STYLES = ["pui-2_0"];

/**
 * Component to asynchronously search through a set of items
 * 
 * ### Children
 * 
 * This component currently supports rendering two children. The first is above the search bar. 
 * The name of the slot is `above-search-slot`
 * To use this named slot you can add a child tag like so:
 * ```
 * <pui-searchbar>
 *      <div slot="above-search-slot">Hello, World!</div>
 * </pui-searchbar>
 * ```
 * 
 * The second child is an error message that is shown whenever the query callback throws an error. 
 * The name of the slot is `search-error-slot`
 * To use this named slot you add it like so:
 * ```
 * <pui-searchbar>
 *      <div slot="search-error-slot">Your query threw an error</div>
 * </pui-searchbar>
 * ```
 *
 * ### Events
 * 
 * When a search result is selected, this component emits a `selected` event
 * with `event.detail` set to the selected search result value. You may use the
 * event target to determine which result was clicked, or you can supply your
 * own handling of result selection by overriding `renderSearchResults` and
 * appending your own event handlers.
 * 
 * By default, this event will close the search results container after it
 * finishes propagating. You can prevent this by calling `.preventDefault()` on
 * the selected event.
 * 
 * @slot above-search-slot - A slot for inserting elements above the searchbar, even in fullscreen mode
 * @slot search-error-slot - A slot for customizing error messages
 * @example
 * <pui-searchbar
 *      id="my-searchbar"
 *      label="Medications"
 *      .query=${async (cancelToken: CancelToken, searchText: string) => {
 *          const cancel = new AbortController();
 *          cancelToken.then(() => cancel.abort());
 *          let response = await fetch("/my-search-endpoint?searchText=" + searchText, {
 *              signal: cancel.signal
 *          })
 *          return await response.json();
 *      }}
 *      \@selected=${(event) => console.log("Search result selected!", event.detail)}></pui-searchbar>
 */
export class PUISearchBar<SearchResultType = string> extends PUIBase {
    static get observedAttributes() {
        return [
            ...super.observedAttributes,
            'label',
            'placeholder',
            'fullscreen',
            'inputstyle',

        ]
    }

    private puiInputRef: Ref<PUIInput> = createRef();

    constructor() {
        super();
        this.attachShadow({mode: "open"});
        injectPUIStyles(this.shadowRoot!);
    }

    //#region HTML attributes
    /**
     * The label displayed on the searchbar
     */
    get label() {
        return this.getAttribute("label") || '';
    }

    set label(newValue: string) {
        this.setAttribute("label", newValue);
    }

    /**
     * What input style the inner pui-input should use.
     * The only current option is "pui-2_0", as that is the only option that pui-input supports.
     * To get the default style, leave this attribute un-set.
     */
    get inputstyle() {
        return this.getAttribute("inputstyle") || '';
    }

    set inputstyle(newValue: string) {
        this.setAttribute("inputstyle", newValue);
    }

    /**
     * Whether this searchbar should render inline (fullscreen=false, default)
     * or expand to fill the screen when focused (fullscreen=true).
     */
    get fullscreen() {
        return this.getBooleanAttribute("fullscreen")
    }

    set fullscreen(value) {
        this.setBooleanAttribute("fullscreen", value);
    }

    /**
     * The placeholder attribute
     */
    get placeholder() {
        return this.getAttribute("placeholder") || '';
    }

    set placeholder(newValue: string) {
        this.setAttribute("placeholder", newValue);
    }
    //#endregion

    private queryCb?: (cancelToken: CancelToken, searchText: string) => Promise<SearchResultType[]>;

    /**
     * An async method that, when invoked with the current value of the search,
     * should return a list of search results.
     * 
     * ### Cancel Tokens
     * 
     * The first argument supplied to this callback is a CancelToken- a promise
     * that resolves if the request should be 'cancelled', and rejects otherwise.
     * 
     * You can use this to cancel any requests that you have in-flight, such as
     * via the `fetch()` API (see example below). Using the CancelToken is
     * optional, but may lead to a better user experience (as well as reducing
     * unnecessary load on your services).
     * 
     * Take care not to store references to this cancel token- it is a
     * self-referential data structure, and the JS garbage collector will not
     * be able to free it until all references to it are removed. Keeping multiple
     * cancel tokens in memory may lead to a memory leak.
     * 
     * ### Example
     * 
     *  ```ts
     *  const myEl = document.querySelector("pui-searchbar");
     *  myEl.query = async (cancelToken, searchText) => {
     *      const abort = new AbortController();
     *      cancelToken.then(() => abort.abort());
     *      let response = await fetch("/my-search-endpoint?" + searchText, {
     *          signal: abort.signal
     *      });
     *      let data = await response.json();
     *      return data;
     *  }
     *  ```
     * 
     * @type {(cancelToken: Promise<void>, searchText: string) => Promise<unknown>}
     */
    set query(callback: (cancelToken: CancelToken, searchText: string) => Promise<SearchResultType[]>) {
        this.queryCb = callback;
    }

    private noResultsFoundCb?: (this: PUISearchBar<SearchResultType>, container: PUISection) => void;

    /** A callback to be invoked when there were no results found.
     * 
     * If specified, this will suppress the default behavior of rendering the
     * text "no results found" when the query returns the empty set. Instead,
     * PUI Searchbar will call this method with a reference to the results area
     * for the consumer to specify their own 'no results' CX.
     * 
     * This callback will be bound to this searchbar, so if you define your
     * callback with `function()` instead of an arrow function, you can use
     * `this` to refer to the searchbar for convenience.
     * 
     * If you set this callback to null, the searchbar will revert to the
     * default behavior.
     * 
     * ### Example
     * 
     * This example replaces the usual 'no results' CX with a different message
     * 
     * ```ts
     * const myEl = document.querySelector("pui-searchbar");
     * myEl.onNoResultsFound = (section) => {
     *     const node = PUIText.Create("No results found :( Try something else");
     *     section.appendChild(node)
     * }
     * ```
     * 
     * @type {(section: HTMLElement) => void}
     */
    set onNoResultsFound(callback: typeof this.noResultsFoundCb) {
        this.noResultsFoundCb = callback?.bind(this);
    }

    /** Retrieve the value of the textbox inside this search bar
     *
     * ### Note to React users
     * 
     * This is a 'pull' API, so if you use it in React you should treat it as
     * 'uncontrolled' state. This affects how React handles state updates. Read
     * more on this here:
     * https://react.dev/learn/sharing-state-between-components#controlled-and-uncontrolled-components
     */
    value() {
        return this.puiInputRef?.value?.getValue();
    }

    private renderSearchResultsCb: (results: SearchResultType[], resultsSection: PUISection) => void = (
        results, section
    ) => {
        let outputNodes: HTMLElement[] = [];
        results.forEach(result => {
            const stringValue = "" + result;
            const element = PUIText.Create(stringValue);
            element.padding = "small";
            element.tabIndex = 0;
            element.dataset.puiSearchResultValue = stringValue;
            element.classList.add("pui-searchbar-result");
            outputNodes.push(
                element,
                new PUIDivider()
            );
        });
        // drop the last divider
        outputNodes.pop();
        section.append(...outputNodes);
    };

    private onSearchResultSelected(event: Event) {
        let target = event.target as HTMLElement;
        let resultValue: string | undefined;
        let resultContainer = this.shadowRoot?.querySelector(".pui-search-results")!;

        while (target && resultContainer.contains(target)) {
            if ('puiSearchResultValue' in target.dataset) {
                resultValue = target.dataset.puiSearchResultValue || '';
                break;
            }
            target = target.parentElement!;
        }

        if (resultValue) {
            const event = new CustomEvent("selected", {
                detail: resultValue,
                cancelable: true
            })
            const shouldPerformDefaultAction = this.dispatchEvent(event);
            if (shouldPerformDefaultAction) {
                this.clearSearchResults();
                const input = this.shadowRoot?.querySelector<PUIInput>("#pui-searchbar-input")!;
                input.clearInput();
                if (this.fullscreen) {
                    this.exitFullscreenView();
                }
            }
        }
    }

    /**
     * Callback to render search results into the results container.
     * 
     * The default implementation simply coerces the search results to strings,
     * renders them into PUIText nodes, and appends them to the results section.
     * 
     * If you wish to customize this, override this method to render the results
     * into the container yourself. Note that you should not attempt to edit
     * the section itself- doing so may mean that a future PUI update will break
     * you without warning.
     * 
     * ### Example
     * 
     * Here's an example that transforms the search results, and renders them as
     * a line with a set of initials in a circle and the result name. This might
     * be used by a profile search input, like the one in Minor Dependent signup.
     * 
     *  ```ts
     *  const myEl = document.querySelector("pui-searchbar");
     *  myEl.renderSearchResults = (results, section) => {
     *      section.append(...results.map(result => {
     *          const node = new PUISection();
     *          node.mainAxisArrangement = "horizontal";
     *          let roundel = new PUIRoundel();
     *          roundel.initials = getInitials(result);
     *          node.append(roundel, result);
     *          return node;
     *      }))
     *  }
     *  ```
     * 
     * @type {(results: unknown[], section: HTMLElement) => void}
     */
    set renderSearchResults(cb: (results: SearchResultType[], section: PUISection) => void) {
        this.renderSearchResultsCb = cb;
    }

    connectedCallback(): void {
        super.connectedCallback();
        render(
            this.render(),
            this.shadowRoot!
        );
    }

    attributeChangedCallback(): void {
        super.attributeChangedCallback();
        render(
            this.render(),
            this.shadowRoot!
        );
    }

    private render() {
        return html`
            <pui-section
                    class="pui-searchbar"
                    secondaryAxisArrangement="center"
                    fullWidth>
                <pui-section>
                    ${this.fullscreen ? html`
                        <pui-section flowDirection="horizontal"
                                fullWidth
                                paddingBottom="small"
                                mainAxisArrangement="end">
                            <pui-link @pressed=${this.exitFullscreenView.bind(this)}
                                    text="Back to previous page"
                                    href="javascript:void 0"
                                    hidden></pui-link>
                        </pui-section>
                    ` : ''}
                    <slot name="above-search-slot"></slot>
                    <pui-input
                            ${ref(this.puiInputRef)}
                            id="pui-searchbar-input"
                            label=${this.label}
                            placeholder=${this.placeholder}
                            inputstyle=${this.inputstyle}
                            iconClass="search-icon"
                            .onInputChange=${this.submitQuery.bind(this)}
                            .onInputClear=${this.onInputCleared.bind(this)}
                            .onInputFocus=${this.onInputFocused.bind(this)}>
                        <pui-loading-indicator query hidden slot="input-loader"></pui-loading-indicator>
                    </pui-input>
                    <pui-card hidden fullWidth spacingTop="mini" padding="none">
                        <pui-section fullWidth
                                class="pui-search-results"
                                flowDirection="vertical"
                                @click=${(e: Event) => this.onSearchResultSelected(e)}
                                @keyup=${keyListener((e) => this.onSearchResultSelected(e))}>
                        </pui-section>
                    </pui-card>
                </pui-section>
            </pui-section>
        `;
    }

    /*
     Temporary solution for showing error on the inner input element. 
     Not reccomended to use this, avoid showing errors with these functions if possible.
     */

    _showError(message: String) {
        this.puiInputRef.value?.showError(message);
    }

    _hideError() {
        this.puiInputRef.value?.hideError();
    }

    focus() {
        const input = this.shadowRoot?.querySelector<PUIInput>("#pui-searchbar-input")!;
        input.focus();
        input.inputFocusedHandler();
    }
    private queryTimeoutId?: number;
    
    /*
     Method for starting the search query process.
     This is needed for when you need to kick off a search manually.
     (ex: pharmacy search and the cusotmer changes the zipcode).
     This method is also used as the onInputChange call-back for the inner pui-input, but
     users of the searchbar component should call this method without any arguments
     */
    
    submitQuery(newValue?: string) {
        const searchValue = newValue ? newValue : this.puiInputRef.value!.getValue();
        this.cancelSearch();
        if (searchValue.trim() == '') {
            this.clearSearchResults();
        }
        if (searchValue.trim().length < DEFAULT_MINIMUM_CHARACTERS) {
            // nothing to search yet
            return;
        }
        this.queryTimeoutId = window.setTimeout(() => this.dispatchQuery(searchValue), DEFAULT_DELAY_MS)
    }

    private onInputCleared() {
        this.clearSearchResults();
        this.cancelSearch();
    }

    private onInputFocused() {
        if (!this.fullscreen) return; // nothing to do, really
        const section = this.shadowRoot?.querySelector<PUISection>(".pui-searchbar")!;
        const link = this.shadowRoot?.querySelector<PUILink>("pui-link")!;
        link.show();
        section.padding = "medium";
        section.classList.add("fullscreen");
        // For iOS safari, summoning the virtual keyboard doesn't actually resize
        // the viewport, _nor does it keep it's original position_. Instead,
        // the screen actually shifts upwards to make room. The net effect of
        // this is that in fullscreen mode, if the screen slides up and the
        // text box is at the top of the screen, it's actually going to slide
        // up and out of view. This article has some details on this "feature"
        // (yes, really) of Safari:
        // https://blog.opendigerati.com/the-eccentric-ways-of-ios-safari-with-the-keyboard-b5aa3f34228d
        // The most reliable fix I've found (because of course scrollIntoView()
        // doesn't work 🙃) is to wait a brief period of time for the virtual
        // keyboard to summon on the device, and _then_ scroll to the top of the
        // page. Doing it right away won't work since unfortunately, at that
        // exact instant the virtual keyboard hasn't rendered yet!
        // I picked 250 ms since it seemed to work fairly reliably on iOS simulator,
        // and is less than 300 ms (and therefore unlikely to be perceived as 
        // a delay by the customer).
        setTimeout(() => {
            window.scrollTo(0, 0)
        }, 250);
    }

    private exitFullscreenView() {
        const section = this.shadowRoot?.querySelector<PUISection>(".pui-searchbar")!;
        const link = this.shadowRoot?.querySelector<PUILink>("pui-link")!;
        const input = this.shadowRoot?.querySelector<PUIInput>("#pui-searchbar-input")!;
        input.clearInput();
        link.hide();
        section.padding = "none";
        section.classList.remove("fullscreen");
        this.clearSearchResults();
    }

    /**
     * Clears the search input and search results
     */
    public clear() {
        this.puiInputRef.value?.clearInput();
        this.clearSearchResults();
    }

    public clearSearchResults() {
        const el = this.shadowRoot?.querySelector(".pui-search-results") as PUISection;
        el.replaceChildren();
        this.hideSearchResults();
    }

    private hideSearchResults() {
        (this.shadowRoot?.querySelector("pui-card") as PUIBase).hide();
    }

    private showSearchResults() {
        (this.shadowRoot?.querySelector("pui-card") as PUIBase).show();
    }

    private dispatchQuery(searchText: string) {
        if (!this.queryCb) {
            console.warn("No query callback set. Set one with .query");
            return;
        }
        this.cancelSearch();
        this.showLoadingSpinner();
        let cancelToken = this.cancelToken = CancelToken.create();
        try {
            let queryResponse = this.queryCb.call(void 0, this.cancelToken, searchText);
            if (!(queryResponse instanceof Promise)) {
                // if it's an array, try to do the right thing
                if (Array.isArray(queryResponse)) {
                    this.cancelToken.resolve();
                    this.tryRenderSearchResults(queryResponse);
                    this.hideLoadingSpinner();
                    // drop the cancel token so the garbage collector can eat it
                    this.cancelToken = undefined;
                    return;
                } else {
                    // complain about the API contract being violated
                    console.warn("query returned an unexpected value: ", queryResponse);
                    console.log("query must be an async function returning a list of search results.");
                    return;
                }
            }
            queryResponse
                .then((results) => {
                    if (cancelToken.isCancelled()) {
                        return;
                    }
                    this.cancelToken?.resolve();
                    this.tryRenderSearchResults(results);
                })
                .catch(err => {
                    console.warn("Error in pui-searchbar query:");
                    console.warn(err);
                    if ((err instanceof Error) && (err.name === "AbortError")) {
                        // Handling edge case. If the operation gets aborted, we just log a warning
                        console.warn("Request was aborted");
                    } else {
                        this.clearSearchResults();
                        this.showSearchError();
                        this.showSearchResults();
                    }
                    this.cancelToken?.cancel();

                })
                .finally(() => {
                    this.hideLoadingSpinner();
                    // drop the cancel token so the garbage collector can eat it
                    this.cancelToken = undefined;
                })
        } catch (err) {
            console.error("Synchronous error thrown in pui-searchbar query");
            console.error(err);
            this.cancelToken?.cancel();
            this.cancelToken = undefined;
            this.clearSearchResults();
            this.showSearchError();
            this.showSearchResults();
            this.hideLoadingSpinner();
        }
    }

    private tryRenderSearchResults(results: SearchResultType[]) {
        this.clearSearchResults();
        if (results.length === 0) {
            // no search results
            this.showSearchResults();
            this.showNoResultsFound();
            return;
        }
        const resultsSection: PUISection = this.shadowRoot?.querySelector(".pui-search-results")!;
        try {
            this.showSearchResults();
            this.renderSearchResultsCb.call(void 0, results, resultsSection as PUISection);
        } catch (err) {
            this.hideSearchResults();
            console.error("Error thrown by renderSearchResults callback:");
            console.error(err);
            this.clearSearchResults();
        }
    }

    private showNoResultsFound() {
        const resultsSection: PUISection = this.shadowRoot?.querySelector(".pui-search-results")!;
        if (this.noResultsFoundCb) {
            this.noResultsFoundCb(resultsSection);
            return;
        }
        const textNode = PUIText.Create("No results found");
        textNode.spacing = "medium";
        resultsSection.append(textNode);
    }

    private showSearchError() {
        const resultsSection: PUISection = this.shadowRoot?.querySelector(".pui-search-results")!;
        const errorSlot: HTMLSlotElement = document.createElement('slot');
        errorSlot.name = 'search-error-slot'
        errorSlot.innerHTML = `
            <pui-section id="search-error-slot-default" slot="search-error-slot" spacing="med-small" spacingleft="medium" spacingright="medium" width="auto">
                <pui-text fontWeight="bold" input="Something went wrong" spacingBottom="tiny"></pui-text>
                <pui-text input="Please try again"></pui-text>
            </pui-section>  
        `;
        resultsSection.append(errorSlot);
    }


    private showLoadingSpinner() {
        this.shadowRoot?.querySelector<PUILoadingIndicator>("pui-loading-indicator")?.show();
    }

    private hideLoadingSpinner() {
        this.shadowRoot?.querySelector<PUILoadingIndicator>("pui-loading-indicator")?.hide();
    }

    private cancelToken?: CancelToken;
    private cancelSearch() {
        if (this.queryTimeoutId) {
            clearTimeout(this.queryTimeoutId);
        }
        if (this.cancelToken) {
            this.cancelToken.cancel();
        }
    }
}

window.customElements.define('pui-searchbar', PUISearchBar);
