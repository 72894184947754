import { ERROR_CODES } from '../../constants/fileUpload';
import PUIBase from '../pui-base';

/**
 * This class is responsible to define the button.
 * It is used for managing pui-file-select and pui-webcam.
 *
 * Callbacks:
 *
 * @callback onSelect - Callback invoked when file is selected or captured.
 * @callback onError - Callback invoked when error occurs in file select.
 *
 * Configuration:
 *
 * @param this.inputId - Input id to hold uploaded files.
 * @param this.isMobileDevice - Client using mobile device or not
 * @param this.placeholderIconClass - Icon class in case icon is used.
 * @param this.placeholderText - file upload display text
 * @param this.useIcons - Use icons in file upload buttons
 * @param this.webcamInModal - show webcam in modal
 * @param this.webcamModeOff - webcam mode off.
 * @param this.customWebcamCaptureText - webcam capture display text
 * @param this.customUploadFileText - file upload display text
 * @param this.acceptedType - accepted file types
 * @param this.maximumFilesize - Maximum size of file allowed
 */
export default class PUIFileUploadButtonContainer extends PUIBase {
  constructor() {
    super();
    this.innerHTML = '<div class="pui-file-upload-button-container"></div>';
    this._mainContainer = this.querySelector('div.pui-file-upload-button-container');
  }

  connectedCallback() {
    super.connectedCallback();
    this._setupComponent();
    this._render();
  }

  _setupComponent() {
    this._mainContainer.innerHTML = '';
    this._innerFileInput = document.getElementById(this.inputId);
  }

  /**
   * Id of the input element in pui file upload two to hold the files
   * This value is required
   *
   * @type {string}
   * @attr
   */
  get inputId() {
    const inputId = this.getAttribute('inputId');
    if (inputId) {
      return inputId;
    }
    throw new Error('Please specify a valid id to store the files selected');
  }

  set inputId(value) {
    return this.setAttribute('inputId', value);
  }

  /**
   * Boolean to indicate if it's a mobile device or not.
   * @returns {boolean}
   */
  get isMobileDevice() {
    return this.getBooleanAttribute('isMobileDevice') || false;
  }

  set isMobileDevice(value) {
    this.setBooleanAttribute('isMobileDevice', value);
  }

  /**
   * Default icon class for the placeholder
   * @type {string}
   * @attr
   */
  get placeholderIconClass() {
    return this.getAttribute('placeholderIconClass') || this.defaultPlaceholderIconClass;
  }

  set placeholderIconClass(value) {
    this.setAttribute('placeholderIconClass', value);
  }

  /**
   * Default text for the placeholder
   * @type {string}
   * @attr
   */
  get placeholderText() {
    return this.getAttribute('placeholderText') || this.defaultPlaceholderText;
  }

  set placeholderText(value) {
    this.setAttribute('placeholderText', value);
  }

  /**
   * Value to represent whether webcam mode should use icons for the initial buttons
   * @type {boolean}
   * @attr
   */
  get useIcons() {
    return this.getBooleanAttribute('useIcons');
  }

  set useIcons(value) {
    this.setBooleanAttribute('useIcons', value);
  }

  /**
   * Value to represent whether webcam should show in modal or not. Not required, defaults to true in webcam component
   * @type {boolean}
   * @attr
   */
  get webcamInModal() {
    return this.getBooleanAttribute('webcamInModal');
  }

  set webcamInModal(value) {
    this.setBooleanAttribute('webcamInModal', value);
  }

  /**
   * Value to represent whether this component should be rendered in webcam mode. On mobile, just 1 input
   * button is present, and we rely on the device to select to take an image. On webcam mode, we will allow them to choose
   * from a file or optionally use a webcam to take a photo.
   * @type {boolean}
   * @attr
   */
  get webcamModeOff() {
    return this.getBooleanAttribute('webcamModeOff');
  }

  set webcamModeOff(value) {
    this.setBooleanAttribute('webcamModeOff', value);
  }

  /**
   * Button text for webcam capture when webcam is available
   * @type {string}
   * @attr
   */
  get customWebcamCaptureText() {
    return this.getAttribute('customWebcamCaptureText') || this.defaultWebcamCaptureText;
  }

  set customWebcamCaptureText(value) {
    this.setAttribute('customWebcamCaptureText', value);
  }

  /**
   * Button text for the upload button when webcam is available
   * @type {string}
   * @attr
   */
  get customUploadFileText() {
    return this.getAttribute('customUploadFileText') || this.defaultUploadFileText;
  }

  set customUploadFileText(value) {
    this.setAttribute('customUploadFileText', value);
  }

  /**
   * Define the file types the file input should accept.
   * https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file#accept
   * @type {string}
   * @attr
   */
  get acceptedType() {
    return this.getAttribute('acceptedType') || this.defaultAcceptedType;
  }

  set acceptedType(value) {
    this.setAttribute('acceptedType', value);
  }

  /**
   * Default maximum file size of the input
   * @type {string}
   * @attr
   */
  get maximumFilesize() {
    return this.getAttribute('maximumFilesize') || this.defaultMaximumFilesize;
  }

  set maximumFilesize(value) {
    this.setAttribute('maximumFilesize', value);
  }

  /** See {@link PUIFileUploadTwo#featureId} */
  get featureId() {
    return this.getAttribute('featureId');
  }

  set featureId(value) {
    this.setAttribute('featureId', value);
  }

  /** See {@link PUIFileUploadTwo#requestId} */
  get requestId() {
    return this.getAttribute('requestId');
  }

  set requestId(value) {
    this.setAttribute('requestId', value);
  }

  /**
   * Renders the file upload button container.
   * @param puiFiles: List of PUIFile objects.
   */
  _render() {
    if (this.isMobileDevice || !navigator.mediaDevices || !navigator.mediaDevices.getUserMedia || !navigator.mediaDevices.enumerateDevices) {
      this._renderAndSetupMobile();
    } else {
      this._isVideoInputAvailable().then((isAvailable) => {
        if (!isAvailable || this.webcamModeOff) {
          this._renderAndSetupMobile();
        } else {
          this._renderAndSetupWebcam();
        }
      });
    }
  }

  _renderAndSetupMobile() {
    const {
      placeholderIconClass,
      placeholderText,
      useIcons,
      featureId,
      requestId,
    } = this;
    const that = this;
    const insertHtml = `
          <pui-file-select
            id="puiFileSelect"
            class="pui-file-upload-two-button" 
            iconClassName="${placeholderIconClass}"
            inputId="${this.inputId}"
            ${useIcons ? 'useIcons' : ''}
            customUploadFileText="${placeholderText}"
            featureId="${featureId}"
            requestId="${requestId}"
          ></pui-file-select>`;

    this._mainContainer.insertAdjacentHTML('afterbegin', insertHtml);

    this._innerFileInput.addEventListener('change', (event) => {
      if (event && event.target && event.target.files && event.target.files.length > 0) {
        that._handleFileInputOnChange(event.target.files[0]);
      }
    });
  }

  _renderAndSetupWebcam() {
    const {
      webcamInModal,
      containerId,
      useIcons,
      customUploadFileText,
      customWebcamCaptureText,
    } = this;
    const that = this;
    const insertHtml = `
        <div class="pui-file-upload-two-button-container">
            <pui-file-select
                id="puiFileSelect"
                class="pui-file-upload-two-button pui-file-upload-two-button-flex1" 
                inputId="${this.inputId}"
                iconClassName="attachment-icon-transparent"
                ${useIcons ? 'useIcons' : ''}
                customUploadFileText="${customUploadFileText}"
            ></pui-file-select>
            <pui-webcam
                id="pui-file-upload-webcam"
                class="pui-file-upload-two-button pui-file-upload-two-button-flex1" 
                inputId="${this.inputId}"
                webcamCaptureText="${customWebcamCaptureText}"
                ${webcamInModal ? 'openInModal' : ''}
                ${containerId ? `containerIdToHide=${containerId}` : ''}
                ${useIcons ? 'useIcons' : ''}
            ></pui-webcam>
        </div>`;

    this._mainContainer.insertAdjacentHTML('afterbegin', insertHtml);

    this._innerFileInput.addEventListener('change', (event) => {
      if (event && event.target && event.target.files && event.target.files.length > 0) {
        that._handleFileInputOnChange(event.target.files[0]);
      }
    });
    this._innerFileInput.addEventListener('webcamCapture', (event) => {
      that._handleFileInputOnChange(event.detail);
    });
  }

  /**
   * Handles the addition of a new file
   * @param file
   * @private
   */
  _handleFileInputOnChange(file) {

    if (file) {
      if (!this._validMaximumFileSize(file) || !this._validAllowedFileType(file)) {
        return;
      }
      this.onSelect(file);
    }
  }

  /**
   * Validate maximum file size of a newly added file.
   * @param puiFile
   */
  _validMaximumFileSize(file) {
    if (file.size > parseInt(this.maximumFilesize, 10)) {

      if (this.onError) {
        this.onError({ file, errorCode: ERROR_CODES.MAXIMUM_FILE_SIZE_EXCEEDED });
      }
      return false;
    }
    return true;
  }

  /**
   * Validate allowed content types of a newly added file.
   * @param puiFile
   */
  _validAllowedFileType(file) {
    const acceptedTypes = this.acceptedType.split(',');
    if (!acceptedTypes.includes(file.type)) {

      if (this.onError) {
        this.onError({ file, errorCode: ERROR_CODES.CONTENT_TYPE_NOT_ALLOWED });
      }
      return false;
    }
    return true;
  }

  _isVideoInputAvailable() {
    return navigator.mediaDevices.enumerateDevices()
      .then(devices => devices.some(device => device.kind === 'videoinput'))
      .catch((err) => {
        console.log(`${err.name}: ${err.message}`);
        return false;
      });
  }
}

window.customElements.define('pui-file-upload-button-container', PUIFileUploadButtonContainer);
