import PUIBase from '../pui-base';
import formatPhoneNumber from '../../functions/phoneNumber';

/**
 * This component is responsible for allowing a user to search for a prescriber
 *
 * @callback onPrescriberSelect
 * @param {Prescriber} prescriber - The prescriber that got selected
 * @param {string} prescriber.npi - National Provider Identifier
 * @param {string} prescriber.spi - Surescripts Provider Identifier
 * @param {string} prescriber.displayName - The prescriber's name in a display friendly format
 * @param {string} prescriber.firstName - The prescriber's first name
 * @param {string} prescriber.lastName - The prescriber's last name
 * @param {string} prescriber.suffixName - The prescriber's suffix
 * @param {string} prescriber.clinicName - The clinic for this prescriber
 * @param {string} prescriber.businessPracticeAddressTelephoneNumber - The clinic's phone number
 * @param {string} prescriber.displayAddressLine1 - Line One for the clinic's address
 * @param {string} prescriber.displayAddressLine2 - Line Two for the clinic's address
 *
 * Configuration:
 *
 * @param {PUIPrescriberSearchConfig} this.config - The configuration for PUIPrescriberSearch
 * @param {string} this.config.zipUpdaterId - The id for the zip code updater used to get the search coordinates
 * @param {string} this.config.searchUrl - The url used for searching prescribers
 * @param {string} this.config.searchRequestMethod - The request method type for search request, can be 'GET' or 'POST', default is 'POST'
 * @param {string} this.config.searchQueryDelay - Specify the delay in milliseconds before initiating a search query
 * @param {string} this.config.contactUsUrl - The url for the help page
 * @param {string} this.config.nextUrl - url path for next page
 * @param {string} this.config.strings.searchInputTitle - The title text used for this prescriber search input
 * @param {string} this.config.strings.searchInputPlaceholder - The placeholder text for this prescriber search input
 * @param {string} this.config.strings.noResultsFound - The text that shows when no results are found
 * @param {string} this.config.strings.changeZipText - The text that prompts you to change your zip code and try again
 * @param {string} this.config.strings.addPrescriptionLaterText - The text that prompts tyou to contineu without prescriber
 * @param {string} this.config.strings.prescriberSearch.addPrescriberSubtext - The text that prompts tyou to continue without prescriber
 * @param {string} this.config.strings.contactUsPromptText - A prompt for the user to contact us, when no prescribers
 *                                                           are found.
 * @param {string} this.config.strings.contactUsLinkText - The text for the contact us link
 * @param {string} this.config.strings.searchResultHelpHeader - The header text for search help
 * @param {string} this.config.strings.searchResultHelpBody - The text for search help
 * @param {onPrescriberSelect} this.config.callbacks.onPrescriberSelect - This callback gets called when a user selects
 *                                                                        a prescriber from the search results
 */
export default class PUIPrescriberSearch extends PUIBase {
  connectedCallback() {
    super.connectedCallback();
    this.upgradeProperty('config');
    this._render();
  }

  _render() {
    const {
      strings,
      searchQueryDelay,
      searchRequestMethod
    } = this.config;
    this.classList.add('pui-block');
    this.innerHTML = `
      <pui-search-input id="apex-doctor-search-input"
                        label="${strings.searchInputTitle}" placeholder="${strings.searchInputPlaceholder}"
                        minimumRequiredCharacters="2" searchQueryDelay="${searchQueryDelay}"
                        noResultsStyle="contact-us" noResultsText="${strings.noResultsFound}" 
                        contactUsPromptText="${strings.contactUsPromptText}" 
                        contactUsOptionOneText="${strings.changeZipText}"
                        contactUsOptionOneSubText=""
                        contactUsOptionOneUrl=""
                        contactUsOptionTwoText="${strings.contactUsLinkText}"
                        contactUsLinkText="${strings.contactUsLinkText}"
                        contactUsOptionTwoSubText=""
                        contactUsOptionTwoUrl="${this.config.contactUsUrl}"
                        contactUsLinkHref="${this.config.contactUsUrl}"
                        contactUsOptionThreeText="${strings.addPrescriptionLaterText}"
                        contactUsOptionThreeSubText="${strings.addPrescriberSubtext}"
                        contactUsOptionThreeUrl="${this.config.nextUrl}"
                        searchResultHelp="true" searchResultHelpHeader="${strings.searchResultHelpHeader}"
                        searchResultHelpBody="${strings.searchResultHelpBody}"
                        searchRequestMethod="${searchRequestMethod || ''}">
      </pui-search-input>
    `;

    this._searchInput = this.querySelector('#apex-doctor-search-input');

    this._searchInput.queryUrlBuilder = this._queryUrlBuilder.bind(this);
    this._searchInput.querySuccessCb = this._querySuccessCb.bind(this);
    this._searchInput.onSearchResultClick = this._onSearchResultClick.bind(this);
  }

  _queryUrlBuilder(dataSearchUrl, prescriberSearchInputValue) {
    const { latitude, longitude } = document.querySelector(`#${this.config.zipUpdaterId}`).getCoordinates();
    return `${this.config.searchUrl}?doctorName=${prescriberSearchInputValue}&latitude=${latitude}&longitude=${longitude}`;
  }

  _querySuccessCb(response) {
    const prescribers = response;
    const searchResults = [];
    prescribers.forEach((prescriber) => {
      const clinicName = (prescriber.clinicName) ? prescriber.clinicName : '';
      const phoneNumber = formatPhoneNumber(prescriber.businessPracticeAddressTelephoneNumber);
      const html = `
        <div>
          <pui-text fontWeight="bold" input="${prescriber.displayName}"></pui-text>
          <pui-text input="${clinicName}" hideIfEmpty></pui-text>
          <pui-text input="${prescriber.displayAddressLine1}"></pui-text>
          <pui-text input="${prescriber.displayAddressLine2}"></pui-text>
          <pui-text input="${phoneNumber}"></pui-text>
        </div>
      `;
      const searchResult = {
        html,
        ...prescriber,
      };
      searchResults.push(searchResult);
    });
    this._searchInput.updateSearchResults(searchResults);
  }

  _onSearchResultClick(prescriber) {
    const { onPrescriberSelect } = this.config.callbacks;
    if (onPrescriberSelect) {
      const selectedPrescriber = prescriber;
      selectedPrescriber.addressLineOne = prescriber.displayAddressLine1;
      selectedPrescriber.addressLineTwo = prescriber.displayAddressLine2;
      onPrescriberSelect(selectedPrescriber);
    }
  }
}

window.customElements.define('pui-prescriber-search', PUIPrescriberSearch);
