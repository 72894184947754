import PUIBase from './pui-base';
import includes from '../functions/includes';
import getTextSize from '../attributeValues/textSize';
import getLinkColor from '../attributeValues/linkColor';

// eslint-disable-next-line import/no-unresolved
import auiBottomSheetCSS from '../../css/aui-bottom-sheet.scss?inline';

class AuiBottomSheet extends PUIBase {
  constructor() {
    super();
    this.defaultClass = 'aui-bottom-sheet';
    this.defaultText = 'Learn more';
    this.defaultTextSize = 'large';
    this.defaultSpacingTop = 'small';
    this.defaultExitText = 'CLOSE';
  }

  connectedCallback() {
    super.connectedCallback();
    this.render();
  }

  static get observedAttributes() {
    return ['text', 'hideLink', 'minHeight', 'closeable', 'exitText',
      'linkColor', 'removeCloseTopBorder'];
  }

  get text() {
    return this.getAttribute('text') || '';
  }

  set text(value) {
    this.setAttribute('text', value);
  }

  get textSize() {
    return this.getAttribute('textSize') || '';
  }

  set textSize(value) {
    return this.setAttribute('textSize', value);
  }

  get hideLink() {
    return this.getBooleanAttribute('hideLink');
  }

  set hideLink(value) {
    this.setBooleanAttribute('hideLink', value);
  }

  get minHeight() {
    return this.getAttribute('minHeight') || '';
  }

  set minHeight(value) {
    this.setAttribute('minHeight', value);
  }

  get exitText() {
    return this.getAttribute('exitText') || '';
  }

  set exitText(value) {
    return this.setAttribute('exitText', value);
  }

  get linkColor() {
    return this.getAttribute('linkColor') || '';
  }

  set linkColor(value) {
    this.setAttribute('linkColor', value);
  }

  get removeCloseTopBorder() {
    return this.getBooleanAttribute('removeCloseTopBorder');
  }

  set removeCloseTopBorder(value) {
    this.setBooleanAttribute('removeCloseTopBorder', value);
  }


  bottomSheetClicked(event) {
    const clickTarget = event.composedPath()[0];
    const bottomSheetLink = this.shadowRoot.querySelector('.bottom-sheet-link');
    const bottomSheetContainer = this.shadowRoot.querySelector('.bottom-sheet-container');
    if (bottomSheetLink.id === clickTarget.id) {
      bottomSheetContainer.classList.remove('collapsed');
    } else if (bottomSheetContainer.id === clickTarget.id) {
      this.closeBottomSheet(bottomSheetContainer);
    }
  }

  closeBottomSheet(bottomSheetContainer) {
    bottomSheetContainer.classList.add('collapsed');
  }

  show() {
    const bottomSheetContainer = this.shadowRoot.querySelector('.bottom-sheet-container');
    bottomSheetContainer.classList.remove('collapsed');
  }

  hide() {
    const bottomSheetContainer = this.shadowRoot.querySelector('.bottom-sheet-container');
    bottomSheetContainer.classList.add('collapsed');
  }

  render() {
    let {
      textSize, exitText, linkColor, removeCloseTopBorder,
    } = this;
    const {
      spacingTop,
      hideLink,
      text,
      minHeight,
      isMShop,
    } = this;

    if (!includes(getTextSize(), this.textSize)) {
      textSize = this.defaultTextSize;
    }
    textSize = `${textSize}-font`;

    if (includes(getLinkColor(), this.linkColor)) {
      linkColor = `bottom-sheet-link-${linkColor}`;
    }

    if (!this.exitText) {
      exitText = this.defaultExitText;
    }

    if (hideLink) {
      this.classList.add('pui-block');
    } else {
      this.classList.add('pui-inline-block');
    }
    this.attachShadow({mode: 'open'});
    this.shadowRoot.innerHTML = `
      <style>
        ${auiBottomSheetCSS}
      </style>
      <div>
        <div 
            id="aui-bottom-sheet-link" 
            class="bottom-sheet-link ${linkColor} ${spacingTop} ${hideLink ? 'pui-hidden' : ''} ${textSize}"
         >
            ${text}
        </div>
        <div id="aui-bottom-sheet-container" class="bottom-sheet-container collapsed">
            <div id="bottom-sheet-modal" class="bottom-sheet-modal ${isMShop ? 'mshop' : ''}" style="min-height: ${minHeight}" >
                <pui-text 
                  id="${this.defaultClass}-close-link" 
                  class="${this.defaultClass}-close-link" 
                  input="${exitText}" 
                  textColor="white"
                ></pui-text>
                <div id="bottom-sheet-modal-text-area" class="bottom-sheet-modal-text-area" >
                    <slot>
                        Bottom Sheet Inner Elements
                    </slot>
               </div>
            </div>
        </div>
      </div>
    `;

    this.shadowRoot.lastElementChild.onclick = (event) => {
      this.bottomSheetClicked(event);
    };

    const closeLink = this.shadowRoot.querySelector('pui-text.aui-bottom-sheet-close-link');
    if (closeLink) {
      closeLink.onclick = () => {
        const bottomSheetContainer = this.shadowRoot.querySelector('.bottom-sheet-container');
        this.closeBottomSheet(bottomSheetContainer);
      };
    }

    this._slot = this.shadowRoot.querySelector('slot');
  }
}

window.customElements.define('aui-bottom-sheet', AuiBottomSheet);
