import PUIBase from '../pui-base';

export default class PUIInsurancePriceLoadingModal extends PUIBase {
  get heading() {
    return this.getAttribute('heading') || '';
  }

  set heading(value) {
    this.setAttribute('heading', value);
  }

  get stepOne() {
    return this.getAttribute('stepOne') || '';
  }

  set stepOne(value) {
    this.setAttribute('stepOne', value);
  }

  get stepTwo() {
    return this.getAttribute('stepTwo') || '';
  }

  set stepTwo(value) {
    this.setAttribute('stepTwo', value);
  }

  get stepThree() {
    return this.getAttribute('stepThree') || '';
  }

  set stepThree(value) {
    this.setAttribute('stepThree', value);
  }

  connectedCallback() {
    super.connectedCallback();
    this._render();
  }

  _render() {
    this.classList.add('pui-insurance-price-loading-container');
    this.innerHTML = `
        <div class="pui-insurance-price-loading-modal">
              <pui-heading class="pui-insurance-price-loading-content-header" input="${this.heading}"></pui-heading>
              <pui-list class="pui-insurance-price-loading-content-list">
                  <pui-list-item color="black" listStyle="disc"><pui-text input="${this.stepOne}"></pui-text></pui-list-item>
                  <pui-list-item color="black" listStyle="disc"><pui-text input="${this.stepTwo}">}</pui-list-item>
                  <pui-list-item color="black" listStyle="disc"><pui-text input="${this.stepThree}"></pui-list-item>
              </pui-list>
            <pui-section spacingTop="large" style="position: relative">
                <pui-loading-indicator></pui-loading-indicator>
            </pui-section>
        </div>
    `;
  }
}

window.customElements.define('pui-insurance-price-loading-modal', PUIInsurancePriceLoadingModal);
