// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../icons/dropdown-checkmark-s.svg";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".pui-dropdown-item-two{cursor:pointer}.pui-dropdown-item-text-checked .pui-text{color:#0c8281}.pui-dropdown-item-text-checked ::before{content:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");position:relative;margin-right:.5em}", "",{"version":3,"sources":["webpack://./src/css/_dropdown-item-two.scss"],"names":[],"mappings":"AAgBA,uBACE,cAAA,CAIA,0CACE,aApBY,CAuBd,yCACE,+CAAA,CACA,iBAAA,CACA,iBAAA","sourcesContent":["// Amazon Pharmacy\n$theme-postfix: '';\n$primary-color: #0C8281;\n$primary-color-gradient-0: #0D8583;\n$primary-color-gradient-1: #159B8E;\n$primary-color-faded: #E8F6ED;\n$primary-color-faded-1: #E8F6ED;\n$primary-amazon-link-text-color: #0066C0;\n$primary-amazon-color: #feb447; \n$link-text-color: $primary-color;\n$pui-sub-nav-sign-up-background-color: linear-gradient(180deg, $primary-color 0%, $primary-color-gradient-0 70.26%, $primary-color-gradient-1 100%);\n$drop-down-item-text-color: $primary-color;\n$body-signup-background-color: $primary-color-gradient-1;\n\n@import 'common_theme.scss';\n\n.pui-dropdown-item-two {\n  cursor: pointer;\n}\n\n.pui-dropdown-item-text-checked {\n  .pui-text {\n    color: $drop-down-item-text-color;\n  }\n\n  ::before {\n    content: url('../icons/dropdown-checkmark-s#{$theme-postfix}.svg');\n    position: relative;\n    margin-right: 0.5em;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
