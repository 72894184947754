import PUIPage from '../pui-page';
import keyCodes from '../../constants/keyCodes';

/**
 * A date picker page for a single procedure.
 *
 * Configuration:
 *
 * @param {PUIProcedureDatePickerPageConfig} this.config - This configuration for PUIProcedureDatePickerPageConfig
 * @param {string} this.config.pageType - Either 'signup' or 'dashboard'
 * @param {string} this.config.procedureName - The name of the procedure
 * @param {number} this.config.maxYear - The max year in the dropdown. If not provided it will default to the current year.
 * @param {number} this.config.minYear - The min year in the dropdown. If not provided it will default to maxYear - 100.

 * @param {string} this.config.strings.headerTitle - The page header title. Must be present to render the page header.
 * @param {string} this.config.strings.headerDescription - The page header description
 * @param {array} this.config.strings.months - An ordered list of 12 months
 * @param {array} this.config.strings.monthLabel - The label text for the month dropdown
 * @param {array} this.config.strings.yearLabel - The label text for the year dropdown
 * @param {string} this.config.strings.noProcedureDateLabel - The label text for no procedure date
 * @param {string} this.config.strings.continueButton The string for the saving the selected procedure date
 * @param {string} this.config.strings.datePickerBoxPlaceholderText - The placeholder text for the date picker box
 */
export default class PUIProcedureDatePickerPage extends PUIPage {
  connectedCallback() {
    super.connectedCallback();
    this.upgradeProperty('config');
    this._render();
  }

  getProcedureDate() {
    if (this._isDontKnowChecked()) {
      return undefined;
    }

    return {
      month: this._toInteger(this._monthDropdown.getAttribute('value')),
      year: this._toInteger(this._yearDropdown.getAttribute('value')),
    };
  }

  _render() {
    this.classList.add('pui-block');

    const { pageType, strings } = this.config;

    this.config.pageStyle = (pageType === 'signup') ? 'card-form' : 'standard-form';

    const maxYear = this.config.maxYear ? this.config.maxYear : new Date().getFullYear();
    const minYear = this.config.minYear ? this.config.minYear : maxYear - 100;

    const header = strings.headerTitle ? `
      <pui-section id="procedure-date-picker-header" spacingTop="medium" spacingBottom="small">
        <pui-section flowDirection="horizontal" secondaryAxisArrangement="center">
          <pui-section-column>
              <pui-heading input="${strings.headerTitle}" textSize="large"></pui-heading>
          </pui-section-column>
        </pui-section>
        <pui-text input="${strings.headerDescription}" textSize="medium" spacingTop="small"></pui-text>
      </pui-section>
    ` : '';

    const content = {
      main: `
        ${header}
        <pui-section spacingTop="mini">
          <pui-banner id="procedure-date-picker-error-banner" status="error" class="pui-hidden" spacingBottom="medium">
            <pui-text input="default" textColor="red" inline></pui-text>
          </pui-banner>

          <pui-section flowDirection="horizontal" mainAxisArrangement="space-between" secondaryAxisArrangement="center">
            <pui-section-column flexGrid="67">
              <pui-dropdown-two id="procedure-date-picker-procedure-date-month" 
                                label="${this.config.strings.monthLabel}" 
                                placeholder="${this.config.strings.datePickerBoxPlaceholderText}">
                ${this._renderMonthDropdownItems(this.config.strings.months)}
              </pui-dropdown-two>
            </pui-section-column>
            <pui-section-column flexGrid="3">
            </pui-section-column>
            <pui-section-column flexGrid="30">
              <pui-dropdown-two id="procedure-date-picker-procedure-date-year"
                                label="${this.config.strings.yearLabel}" 
                                placeholder="${this.config.strings.datePickerBoxPlaceholderText}">
                ${this._renderYearDropdownItems(minYear, maxYear)}
              </pui-dropdown-two>
            </pui-section-column>
          </pui-section>

          <pui-checkbox id="procedure-date-picker-dont-know-check-box" label="${strings.noProcedureDateLabel}">
          </pui-checkbox>
        </pui-section>
      `,
      footerDesktop: `
        <pui-button id="procedure-date-picker-save-button-desktop" label="${strings.continueButton}">
        </pui-button>
      `,
      footerMobile: `
        <pui-button id="procedure-date-picker-save-button-mobile" label="${strings.continueButton}">
        </pui-button>
      `,
    };
    super._render(content);

    this._errorBanner = this.querySelector('#procedure-date-picker-error-banner');

    this._monthDropdown = this.querySelector('#procedure-date-picker-procedure-date-month');
    this._monthDropdown.onSelect = this._onDropdownSelect.bind(this);

    this._yearDropdown = this.querySelector('#procedure-date-picker-procedure-date-year');
    this._yearDropdown.onSelect = this._onDropdownSelect.bind(this);

    this._checkBox = this.querySelector('#procedure-date-picker-dont-know-check-box');
    this._checkBox.onCheckBoxToggle = this._onCheckBoxToggle.bind(this);
    this._checkBox.addEventListener('click', this._checkBoxClicked.bind(this));
    this._checkBox.addEventListener('keyup', this._checkBoxPressed.bind(this));

    this._saveButtonDesktop = this.querySelector('#procedure-date-picker-save-button-desktop');
    this._saveButtonDesktop.addEventListener('click', this._onSaveButtonClicked.bind(this));
    this._saveButtonDesktop.addEventListener('keyup', this._onSaveButtonPressed.bind(this));

    this._saveButtonMobile = this.querySelector('#procedure-date-picker-save-button-mobile');
    this._saveButtonMobile.addEventListener('click', this._onSaveButtonClicked.bind(this));
    this._saveButtonMobile.addEventListener('keyup', this._onSaveButtonPressed.bind(this));
  }

  _renderMonthDropdownItems(months) {
    return months.reduce((html, month, index) => `${html}<pui-dropdown-item-two type="option" label="${month}" value="${index + 1}"></pui-dropdown-item-two>`, '');
  }

  _renderYearDropdownItems(minYear, maxYear) {
    const dropdownItems = [];
    for (let year = maxYear; year >= minYear; year -= 1) {
      dropdownItems.push(`<pui-dropdown-item-two type="option" label="${year}" value="${year}"></pui-dropdown-item-two>`);
    }
    return dropdownItems.join('');
  }

  _onDropdownSelect(value) {
    if (this._isDontKnowChecked()) {
      this._checkBox.uncheck();
    } else if (this._isProcedureDateValid(this.getProcedureDate())) {
      this._hideError();
    }
  }

  _resetDropdowns() {
    this._monthDropdown.reset();
    this._yearDropdown.reset();
  }

  _isProcedureDateValid(procedureDate) {
    return procedureDate && this._isInteger(procedureDate.month) && this._isInteger(procedureDate.year);
  }

  _toInteger(value) {
    return Number.parseInt(value, 10);
  }

  _isInteger(value) {
    return Number.isInteger(this._toInteger(value));
  }

  _isDontKnowChecked() {
    return this._checkBox.isChecked();
  }

  _setErrorText(value) {
    this._errorBanner.firstElementChild.setText(value);
  }

  _showError() {
    this._errorBanner.classList.remove('pui-hidden');
  }

  _hideError() {
    this._errorBanner.classList.add('pui-hidden');
  }

  _onCheckBoxToggle() {
    this._hideError();
  }

  _checkBoxClicked(event) {
    if (this._checkBox.isChecked()) {
      this._resetDropdowns();
    }
  }

  _checkBoxPressed(event) {
    if (event.keyCode === keyCodes.ENTER_KEYCODE) {
      this._checkBoxClicked(event);
    }
  }

  _onSave() {
    const procedureDate = this.getProcedureDate();
    if (!this._isProcedureDateValid(procedureDate) && !this._isDontKnowChecked()) {
      this._setErrorText(this.config.strings.noSelectionErrorText);
      this._showError();
      return;
    }

    const { callbacks } = this.config;
    if (callbacks && callbacks.onSave) {
      callbacks.onSave(procedureDate);
    }
  }

  _onSaveButtonClicked(event) {
    this._onSave();
  }

  _onSaveButtonPressed(event) {
    if (event.keyCode === keyCodes.ENTER_KEYCODE) {
      this._onSaveButtonClicked(event);
    }
  }
}

window.customElements.define('pui-procedure-date-picker-page', PUIProcedureDatePickerPage);
