import PUIBase from '../pui-base';

/**
 * This component is responsible for allowing a user to search for a procedure
 *
 * Configuration:
 *
 * @param {PUIProcedureSearchConfig} this.config - The configuration for PUIProcedureSearch
 * @param {string} this.config.searchUrl - The url used for searching procedures
 * @param {string} this.config.searchRequestMethod - The request method type for search request, can be 'GET' or 'POST', default is 'POST'
 * @param {string} this.config.strings.searchInputTitle - The title text used for this procedure search input
 * @param {string} this.config.strings.searchInputPlaceholder - The placeholder text for this procedure search input
 * @param {string} this.config.strings.noResultsFound - The text that shows when no results are found
 * @param {string} this.config.strings.addManuallyText - The text that shows when no results are found
 * @param {onProcedureSelect} this.config.callbacks.onProcedureSelect - This callback gets called when a user selects
 *                                                                       an procedure from the search results
 * @param {onProcedureManualAdd} this.config.callbacks.onProcedureManualAdd - This callback gets called when a user selects
 *                                                                            add manually
 */
export default class PUIProcedureSearch extends PUIBase {
  connectedCallback() {
    super.connectedCallback();
    this.upgradeProperty('config');
    this._render();
  }

  _render() {
    const {
      strings,
      searchRequestMethod
    } = this.config;
    this.classList.add('pui-block');

    if (!strings.searchInputPlaceholder) {
      strings.searchInputPlaceholder = '';
    }

    if (!strings.addManuallyHref) {
      // This will prevent adding an entry to the browser history/reloading the page

      // eslint-disable-next-line no-script-url
      strings.addManuallyHref = 'javascript:;';
    }

    this.innerHTML = `
      <pui-search-input id="procedures-search-input"
                        label="${strings.searchInputTitle}"
                        placeholder="${strings.searchInputPlaceholder}"
                        noResultsStyle="manual-add"
                        noResultsText="${strings.noResultsFound}"
                        addManuallyText="${strings.addManuallyText}"
                        addManuallyHref="${strings.addManuallyHref}"
                        searchRequestMethod="${searchRequestMethod || ''}"
                        style="margin-bottom: 8px">
      </pui-search-input>
    `;

    this._searchInput = this.querySelector('#procedures-search-input');

    this._searchInput.queryUrlBuilder = this._queryUrlBuilder.bind(this);
    this._searchInput.querySuccessCb = this._querySuccessCb.bind(this);
    this._searchInput.onSearchResultClick = this._onSearchResultClick.bind(this);
    this._searchInput.onManualAdd = this._onManualAdd.bind(this);
  }

  _queryUrlBuilder(dataSearchUrl, procedureSearchInputValue) {
    return `${this.config.searchUrl}?query=${procedureSearchInputValue}`;
  }

  _querySuccessCb(response) {
    const procedures = response;
    const searchResults = [];
    procedures.forEach((procedure) => {
      const suggestionKeywords = procedure.keywords.join(', ');
      const html = `
        <div>
          <pui-text spacingBottom="mini" textSize="large" input="${procedure.name}"></pui-text>
          <pui-text textSize="medium" fontStyle="italic" input="${suggestionKeywords}"></pui-text>
        </div>
      `;
      const searchResult = {
        html,
        ...procedure,
      };
      searchResults.push(searchResult);
    });
    this._searchInput.updateSearchResults(searchResults);
  }

  _onSearchResultClick(procedure) {
    const { onProcedureSelect } = this.config.callbacks;
    if (onProcedureSelect) {
      onProcedureSelect(procedure.name);
    }
  }

  _onManualAdd(procedureLabel) {
    const { onProcedureManualAdd } = this.config.callbacks;
    if (onProcedureManualAdd) {
      onProcedureManualAdd(procedureLabel);
    }
  }
}

window.customElements.define('pui-procedure-search', PUIProcedureSearch);
