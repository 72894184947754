import PUIPage from '../pui-page';
import PUIInsuranceItem from './pui-insurance-item';
import PUIInsuranceSaver from './pui-insurance-saver';
import PUIInsuranceSelect from './pui-insurance-select';
import Request from '../../networking/request';
import keyCodes from '../../constants/keyCodes';
/**
 * This page is used to show a customer's insurances and will allow
 * a customer to set their primary insurance
 *
 * Configuration:
 *
 * @param {InsurancesPageConfig} this.config - The configuration for PUIInsurances
 * @param {string} this.config.pageType - Either 'signup' or 'dashboard'
 * @param {string} this.config.weblabPharmacyInsuranceInputSearch - String indicating current weblab status for insurance search input enhancement
 * @param {string} this.config.weblabPurchaseTwo - String indicating current weblab status for purchase 2.0 
 * @param {string} this.config.insuranceCardCaptureUrl - Url where a user can capture insurance card
 * @param {string} this.config.backUrl - The url used for going to the previous page (dashboard only)
 * @param {string} this.config.returnUrl - The url used for navigating back to the user's original page
 * @param {string} this.config.settingsUrl - The url for the account settings page
 * @param {string} this.config.saveInsuranceUrl - The url for creating/updating an insurance
 * @param {string} this.config.removeInsuranceUrl - The url used for removing insurances
 * @param {string} this.config.getInsuranceUrl - The url used to get a single insurance
 * @param {string} this.config.getInsurancesUrl - The url used to get all the insurances for a person
 * @param {string} this.config.actionUrl - The url used to set the users primary insurance
 * @param {string} this.config.insurancePageUrl - The url for the insurance page in account settings
 * @param {string} this.config.iconClass - The class for the image icon used for this page
 * @param {string} this.config.embeddable - Determines if this page should render in a embeddable format
 * @param {boolean} this.config.insuranceReviewed - An optional flag to determine if user visited insurance page,
 *                                                  only use for sign-up
 * @param {boolean} this.config.useHistoryBack - An optional flag to determine if we should use the window.history.back() API for the back button
 *                                                We would want to set this flag to true, when we want to show the back button and not specify a specific URL to redirect to
 * @param {boolean} this.config.displayLoadingIndicator - A toggle to show or hide the loading indicator
 * @param {boolean} this.config.isReadOnly - A boolean to determine if the page is read only or not
 * @param {string} this.config.strings.errorTitle - The title text for the error box
 * @param {string} this.config.strings.pageTitleSearching - The title text when searching for insurance
 * @param {string} this.config.strings.pageDescriptionSearching - The description text when searching for insurance
 * @param {string} this.config.strings.pageTitle - The title text for the insurance page
 * @param {string} this.config.strings.multiInsurancePageTitle - The page title for multi-insurance
 * @param {string} this.config.strings.embeddedTitle - The title text for the insurance page when rendering as an embedded checkout widget
 * @param {string} this.config.strings.pageDescription - The description text for the insurance page
 * @param {string} this.config.strings.multiInsurancePageDescription - The description text for the insurance page for MI launch
 * @param {string} this.config.strings.addInsuranceLink - The text for the add insurance link
 * @param {string} this.config.strings.noInsuranceFound - The text that displays when a user has no insurances
 * @param {string} this.config.strings.defaultInsurance - The subheader for the selected primary
 *                                                        insurance section (dashboard only)
 * @param {string} this.config.strings.insuranceUnableAddInsuranceErrorMessage - The text for general error message for adding insurance
 * @param {string} this.config.strings.insuranceUnableAddInsuranceErrorTitle - The title of general error for adding insurance
 * @param {string} this.config.strings.otherInsurances - The subheader for the other insurances found (dashboard only)
 * @param {string} this.config.strings.saveButton - The text for the button to save the users primary insurance
 * @param {string} this.config.strings.defaultErrorMessage - The default error message to fall back to
 * @param {string} this.config.strings.multiInsuranceAddInsuranceButton - Add insurance button text for multi-insurance
 * @param {string} this.config.strings.multiInsuranceAvailableTitle - Available title for multi-insurance
 * @param {string} this.config.strings.multiInsuranceAvailableBody - Available insurances description
 * @param {string} this.config.strings.multiInsuranceNotAvailableTitle - Not available title for multi-insurance
 * @param {string} this.config.strings.multiInsuranceNotAvailableBody - Not available insurances description
 * @param {string} this.config.strings.multiInsuranceEmpty - Text for displaying when no insurances are available
 * @param {string} this.config.strings.insuranceAdded - Text for the banner that displays when an insurance is added
 * @param {string} this.config.strings.insuranceSaver.cancelButton - The text of the cancel button
 * @param {string} this.config.strings.insuranceSaver.addInsuranceTitle - The title for the insurance saver
 *                                                                        if creating a new insurance
 * @param {string} this.config.strings.insuranceSaver.updateInsuranceTitle - The title for the insurance saver
 *                                                                           if updating an existing insurance
 * @param {string} this.config.strings.insuranceSaver.helperText - Helper text for the insurance saver
 * @param {string} this.config.strings.insuranceSaver.moreInfoLink - The text for the more info link
 * @param {string} this.config.strings.insuranceSaver.memberIdInputLabel - Label text for the memberId input
 * @param {string} this.config.strings.insuranceSaver.memberIdInputPlaceholder - Placeholder text for memberId input
 * @param {string} this.config.strings.insuranceSaver.binInputLabel - Label text for the bin input
 * @param {string} this.config.strings.insuranceSaver.binInputPlaceholder - Placeholder text for bin input
 * @param {string} this.config.strings.insuranceSaver.groupInputLabel - Label text for the group input
 * @param {string} this.config.strings.insuranceSaver.groupInputPlaceholder - Placeholder text for group input
 * @param {string} this.config.strings.insuranceSaver.pcnInputLabel - Label text for the pcn input
 * @param {string} this.config.strings.insuranceSaver.pcnInputPlaceholder - Placeholder text for pcn input
 * @param {string} this.config.strings.insuranceSaver.addInsuranceButton - The save button when creating insurance
 * @param {string} this.config.strings.insuranceSaver.updateInsuranceButton - The save button when updating insurance
 *
 * @param {string} this.config.strings.insuranceUpload.backButton - The text of the back button (go to previous page)
 * @param {string} this.config.strings.insuranceUpload.cancelButton - The text of the cancel button (cancel operation entirely)
 * @param {string} this.config.strings.insuranceUpload.cardTitle - The title of the upload page for the front of the card
 * @param {string} this.config.strings.insuranceUpload.retakeTitle - The title of the retake page for the front of the card
 * @param {string} this.config.strings.insuranceUpload.cardMessage - The message displayed on the upload page for the front of the card
 * @param {string} this.config.strings.insuranceUpload.cardTitleSecondPage - The title of the upload page for the back of the card
 * @param {string} this.config.strings.insuranceUpload.retakeTitleSecondPage - The title of the retake page for the back of the card
 * @param {string} this.config.strings.insuranceUpload.cardMessageSecondPage - The message displayed on the upload page for the back
 *                                                                             of the card
 * @param {string} this.config.strings.insuranceUpload.frontCardMessage - The text of the card prompting users for the front of the card
 * @param {string} this.config.strings.insuranceUpload.backCardMessage - The text of the card prompting users for the back of the card
 * @param {string} this.config.strings.insuranceUpload.takeButtonLabel - The text of the button asking the user to take or upload an image
 * @param {string} this.config.strings.insuranceUpload.confirmCardTitle - The message displayed on the confirmation page after image upload
 * @param {string} this.config.strings.insuranceUpload.confirmCardMessage - The text displayed on the confirmation page after image upload
 * @param {string} this.config.strings.insuranceUpload.confirmButtonLabel - The text of the confirm button on the confirmation page
 * @param {string} this.config.strings.insuranceUpload.retakeButtonLabel - The text of the retake button on the confirmation page
 * @param {string} this.config.strings.insuranceUpload.uploadFailureTitle - The title of the insurance saver error box after too
 *                                                                          many failures
 * @param {string} this.config.strings.insuranceUpload.uploadFailureMessage - The message of the insurance saver error box after too
 *                                                                            many failures
 *
 * @param {string} this.config.strings.insuranceSelect.backButton - The text of the back button (go to previous page)
 * @param {string} this.config.strings.insuranceSelect.cancelButton - The text of the cancel button (go to previous page)
 * @param {string} this.config.strings.insuranceSelect.errorTitle - The title for the page level error box
 * @param {string} this.config.strings.insuranceSelect.pageTitle - The title for the insurance input selection page
 * @param {string} this.config.strings.insuranceSelect.pageLabel: - The message displayed on the insurance input selection page
 * @param {string} this.config.strings.insuranceSelect.captureCardTitle: - The title of the insurance image capture card
 * @param {string} this.config.strings.insuranceSelect.captureCardLabel: - The text of the insurance image capture card
 * @param {string} this.config.strings.insuranceSelect.manualCardTitle: - The title of the manual insurance input card
 * @param {string} this.config.strings.insuranceSelect.manualCardLabel: - The text of the manual insurance input card
 * @param {string} this.config.strings.planSupportStatusTitleRed - Label for red title plan support status
 * @param {string} this.config.strings.planSupportStatusBodyRed - Label for red body plan support status
 * @param {string} this.config.strings.duplicateInsuranceBody - Label for duplicate insurance error
 *
 * @param {string} this.config.strings.insuranceInfo.title - Title for the insurance info page
 * @param {string} this.config.strings.insuranceInfo.cancelButton - The text for the cancel button
 * @param {string} this.config.strings.insuranceInfo.helpfulTipsHeader - The subheader for the helpful tips section
 * @param {string} this.config.strings.insuranceInfo.helpfulTipOne - The first tip
 * @param {string} this.config.strings.insuranceInfo.helpfulTipTwo - The second tip
 * @param {string} this.config.strings.insuranceInfo.helpfulTipThree - The third tip
 * @param {string} this.config.strings.insuranceInfo.helpfulTipFour - The fourth tip
 * @param {string} this.config.strings.insuranceInfo.helpfulTipFive - The fifth tip
 *
 * @param {string} this.config.strings.insuranceItem.memberIdLabel - The label indicator for memberId
 * @param {string} this.config.strings.insuranceItem.memberIdHelpText - The help text to for memberIdHelpText
 * @param {string} this.config.strings.insuranceItem.insuranceCompanyNameLabel - The label for insurance company name
 * @param {string} this.config.strings.insuranceItem.eligible - The label for a verified insurance
 * @param {string} this.config.strings.insuranceItem.ineligible - The label for an unverified insurance
 * @param {string} this.config.strings.insuranceItem.deleteButton - The delete button text for an insurance item
 * @param {string} this.config.strings.insuranceItem.editButton - The edit button text for an insurance item
 * @param {string} this.config.strings.insuranceItem.continueWithoutInsurance - The label for continuing without
 *                                                                              a selected insurance
 */
export default class PUIInsurancePage extends PUIPage {
  connectedCallback() {
    super.connectedCallback();
    this._render();
  }

  showError(error) {
    let message = this.config.strings.defaultErrorMessage;
    if (error && error.message) {
      message = error.message;
    }
    this._pageError.setDescription(message);
    this._pageError.show();
  }

  showGeneralAddInsuranceError_Treatment() {
    this._pageError_Treatment.show();
  }

  showDuplicateInsuranceError(error) {
    this._pageError_Duplicate.show();
  }

  showAddUnsupportedInsuranceError(error) {
    this._pageError_Unsupported.show();
  }

  hideError() {
    this._pageError.hide();
    this._pageError_Treatment.hide();
    this._pageError_Unsupported.hide();
    this._pageError_Duplicate.hide();
  }

  insuranceSaverConfig(insurance) {
    const insuranceSaverConfig = {
      saveInsuranceUrl: this.config.saveInsuranceUrl,
      insurance,
      strings: {
        ...this.config.strings.insuranceSaver,
        insuranceInfo: this.config.strings.insuranceInfo,
      },
      callbacks: {
        onSaveComplete: this._onSaveComplete.bind(this),
      },
    };
    return insuranceSaverConfig;
  }

  insuranceReviewerConfig(insurance) {
    const insuranceSaverConfig = {
      saveInsuranceUrl: this.config.saveInsuranceUrl,
      insurance,
      strings: {
        ...this.config.strings.insuranceReviewer,
        insuranceInfo: this.config.strings.insuranceInfo,
      },
      callbacks: {
        onSaveComplete: this._onSaveComplete.bind(this),
      },
    };
    return insuranceSaverConfig;
  }

  openInsuranceSaver(insurance, insuranceSaverContext) {
    const insuranceSaverConfig = this.insuranceSaverConfig(insurance);
    const insuranceReviewerConfig = this.insuranceReviewerConfig(insurance);
    const insuranceSaver = new PUIInsuranceSaver();
    if (insuranceSaverContext === 'review') {
      insuranceSaver.configure(insuranceReviewerConfig);
    } else {
      insuranceSaver.configure(insuranceSaverConfig);
    }
    this.appendChild(insuranceSaver);
    insuranceSaver.setAttribute('tabindex', '0');
    insuranceSaver.focus();
  }

  openInsuranceSelect(insurance) {
    const insuranceSaverConfig = this.insuranceSaverConfig(insurance, insuranceSaverContext);
    const insuranceUploadConfig = {
      uploadUrl: this.config.getInsuranceInformationUrl,
      saveInsuranceUrl: this.config.saveInsuranceUrl,
      strings: {
        ...this.config.strings.insuranceUpload,
        defaultErrorMessage: this.config.strings.defaultErrorMessage,
      },
      callbacks: {
        onSaveComplete: this._onSaveComplete.bind(this),
      },
    };
    const insuranceSelectConfig = {
      strings: {
        ...this.config.strings.insuranceSelect,
        errorTitle: this.config.strings.errorTitle,
        defaultErrorMessage: this.config.strings.defaultErrorMessage,
      },
    };
    const insuranceSelect = new PUIInsuranceSelect();
    insuranceSelect.setConfigs(insuranceSaverConfig, insuranceUploadConfig);
    insuranceSelect.configure(insuranceSelectConfig);
    this.appendChild(insuranceSelect);
    insuranceSelect.setAttribute('tabindex', '0');
    insuranceSelect.focus();
  }

  _render() {
    this.classList.add('pui-block');

    const { pageType, displayLoadingIndicator, strings } = this.config;

    if (!strings.defaultInsurance) {
      strings.defaultInsurance = '';
    }
    if (!strings.otherInsurances) {
      strings.otherInsurances = '';
    }
    if (pageType === 'signup') {
      this.config.pageStyle = 'card-form';
    } else if (this.config.embeddable || this.config.isReadOnly) {
      this.config.pageStyle = 'standard-form-no-footer';
    } else {
      this.config.pageStyle = 'standard-form';
    }

    const urlParams = new URLSearchParams(window.location.search);
    const insuranceBanner = (`
      <pui-banner status="success" spacingBottom="medium" class="${urlParams.get('insuranceStatus') === 'INSURANCE_ADDED' ? '' : 'pui-hidden'}">
        <pui-text
            input="${this.config.strings.insuranceAdded}"
            textColor="link"
            fontWeight="bold"
        ></pui-text>
      </pui-banner>
      `);
    if (urlParams.get('insuranceStatus') === 'INSURANCE_ADDED') {
      if (window.history.replaceState) {
        urlParams.delete('insuranceStatus');
        const newUrl = `${window.location.origin + window.location.pathname}?${urlParams}`;
        window.history.replaceState(null, '', newUrl);
      }
    }

    const isDashboard = this.config.pageType === 'dashboard';
    const pageTitleString = (this.config.pageType === 'signup') ? strings.multiInsurancePageTitle : strings.pageTitle;
    const pageDescriptionString = (this.config.pageType === 'signup') ? strings.multiInsurancePageDescription : strings.pageDescription;
    const content = !this.config.isReadOnly ? {
      main: `
        ${insuranceBanner}
        <pui-error-box id="apex-insurance-page-error" title="${this.config.strings.errorTitle}" 
                       class="pui-hidden"></pui-error-box>
        <pui-banner
          id="apex-insurance-page-insurance-unsupported-error"
          status="error"
          spacingBottom="medium"
          class="pui-hidden"
          >
          <pui-text
            input="${strings.planSupportStatusTitleRed}"
            textSize="medium"
            textColor="red"
            fontWeight="bold"
          ></pui-text>
          <pui-text
            input="${strings.planSupportStatusBodyRed}"
            textSize="medium"
            spacingTop="mini"
            ></pui-text>
        </pui-banner>
        <pui-banner
          id="apex-insurance-page-insurance-duplicate-error"
          status="error"
          spacingBottom="medium"
          class="pui-hidden"
          >
          <pui-text
            input="${strings.insuranceUnableAddInsuranceErrorTitle}"
            textSize="medium"
            textColor="red"
            fontWeight="bold"
          ></pui-text>
          <pui-text
            input="${strings.duplicateInsuranceBody}"
            textSize="medium"
            spacingTop="mini"
            ></pui-text>
        </pui-banner>
        <pui-banner
          id="apex-insurance-page-error-treatment"
          status="error"
          spacingBottom="medium"
          class="pui-hidden"
          >
          <pui-text
            input="${strings.insuranceUnableAddInsuranceErrorTitle}"
            textColor="red"
            textSize="medium"
            fontWeight="bold"
          ></pui-text>
          <pui-text
            input="${strings.insuranceUnableAddInsuranceErrorMessage}"
            spacingTop="mini"
            textSize="medium"
            ></pui-text>
        </pui-banner>
        <pui-section id="apex-insurances-card">
          <pui-section>
            <pui-section flowDirection="horizontal" secondaryAxisArrangement="center">
              <pui-section-column>
                <pui-icon imgClass="${this.config.iconClass}" spacingRight="small"></pui-icon>
              </pui-section-column>
              <pui-section-column>
                <pui-heading id="apex-insurance-page-title" 
                             input="${displayLoadingIndicator ? strings.pageTitleSearching : pageTitleString}" 
                             textSize="large">
                </pui-heading>
              </pui-section-column>
            </pui-section>

            <pui-text id="apex-insurance-page-description" 
                      input="${displayLoadingIndicator ? strings.pageDescriptionSearching : pageDescriptionString}"
                      spacingTop="small"
                      textSize="medium">
            </pui-text>
          </pui-section>

          <!-- Insurance Loading Container -->
          <pui-section id="apex-insurance-loading-container" 
                       secondaryAxisArrangement="center" 
                       spacingTop="medium" 
                       style="position:relative; 
                       height: 100px" 
                       class="${displayLoadingIndicator ? '' : 'pui-hidden'}">
            <pui-loading-indicator id="apex-transfer-med-loading-indicator"></pui-loading-indicator>
          </pui-section>

          <!-- Insurance Content Container -->
          <pui-section id="apex-insurance-content-container" class="${displayLoadingIndicator ? 'pui-hidden' : ''}">

            <!-- Add Insurance -->
            <pui-section flowDirection="vertical">
                <pui-section role="button" id="apex-add-insurance-button" flowDirection="horizontal" secondaryAxisArrangement="center" spacingTop="small" spacingBottom="med-small" tabindex="0" style="cursor: pointer; max-width: fit-content;">
                    <pui-icon imgClass="add-icon" spacingRight="small"></pui-icon>
                <pui-text input="${strings.addInsuranceLink}" textSize="large" textColor="link"></pui-text>
                </pui-section>
                <pui-divider dividerHeight="large" id="" spacingBotton="medium" class="${isDashboard ? '' : 'pui-hidden'}"/>
            </pui-section>

            <!-- No Insurance Found -->
            <pui-box id="apex-no-insurance-found-box" theme="dashed" class="pui-hidden">
              <pui-text input="${strings.noInsuranceFound}" textAlign="center" spacing="medium" textColor="grey"></pui-text>
            </pui-box>

            <!-- Insurance List -->
            <pui-carousel id="insurance-select-carousel" size="small"
                          flowDirection="vertical">
              <!-- Default Insurance -->
              <pui-section spacingBottom="${this.config.pageType === 'signup' ? '' : 'small'}">
                <pui-heading id="apex-default-insurance-heading" input="${isDashboard ? strings.multiInsuranceAvailableTitle : strings.defaultInsurance}"
                           class="${isDashboard ? '' : 'pui-hidden'}" textSize="small"></pui-heading>
                <pui-section class="${isDashboard ? '' : 'pui-hidden'}" textAlign="left" spacingBottom="medium">
                    <pui-text input="${strings.multiInsuranceAvailableBody}"></pui-text>
                </pui-section>
                <pui-box id="apex-no-active-insurance-found-box" theme="dashed" class="pui-hidden">
                <pui-text input="${strings.multiInsuranceEmpty}" textAlign="center" spacing="medium" textColor="grey"></pui-text>
                </pui-box>
              </pui-section>
              <div id="apex-default-insurance">
              <pui-divider dividerHeight="large" id="" spacingBottom="medium" class="${isDashboard ? '' : 'pui-hidden'}" />
              </div>

              <!-- Other Insurances -->
              <pui-section spacingBottom="${this.config.pageType === 'signup' ? '' : 'small'}">
                <pui-heading id="apex-other-insurances-heading" input="${isDashboard ? strings.multiInsuranceNotAvailableTitle : strings.otherInsurances}" 
                class="${isDashboard ? '' : 'pui-hidden'}" textSize="small"></pui-heading>
                <pui-section class="${isDashboard ? '' : 'pui-hidden'}"  textAlign="left" spacingBottom="medium">
                  <pui-text input="${strings.multiInsuranceNotAvailableBody}" inline></pui-text> 
                </pui-section>
                <pui-box id="apex-no-inactive-insurance-found-box" theme="dashed" class="pui-hidden">
                <pui-text input="${strings.multiInsuranceEmpty}" textAlign="center" spacing="medium" textColor="grey"></pui-text>
                </pui-box>
              </pui-section>
              <div id="apex-other-insurances">
              </div>

            </pui-carousel>

          </pui-section>

        </pui-section>`,
      footerDesktop: `
        <pui-button disabled id="apex-insurance-continue-button-desktop" label="${strings.saveButton}">
        </pui-button>
      `,
      footerMobile: `
        <pui-button disabled id="apex-insurance-continue-button-mobile" label="${strings.saveButton}">
        </pui-button>
      `,
    } : {
      main: `
      ${insuranceBanner}
      <pui-error-box id="apex-insurance-page-error" title="${this.config.strings.errorTitle}" 
      class="pui-hidden"></pui-error-box>
      <pui-banner
        id="apex-insurance-page-insurance-unsupported-error"
        status="error"
        spacingBottom="medium"
        class="pui-hidden"
        >
        <pui-text
          input="${strings.planSupportStatusTitleRed}"
          textSize="medium"
          textColor="red"
          fontWeight="bold"
        ></pui-text>
        <pui-text
          input="${strings.planSupportStatusBodyRed}"
          textSize="medium"
          spacingTop="mini"
          ></pui-text>
      </pui-banner>
      <pui-banner
        id="apex-insurance-page-error-treatment"
        status="error"
        spacingBottom="medium"
        class="pui-hidden"
        >
        <pui-text
          input="${strings.insuranceUnableAddInsuranceErrorTitle}"
          textColor="red"
          textSize="medium"
          fontWeight="bold"
        ></pui-text>
        <pui-text
          input="${strings.insuranceUnableAddInsuranceErrorMessage}"
          textSize="medium"
          spacingTop="mini"
          ></pui-text>
      </pui-banner>
      <pui-section id="apex-insurances-card">
        <pui-section flowDirection="horizontal" secondaryAxisArrangement="center">
          <pui-section-column>
          <pui-icon imgClass="${this.config.iconClass}" spacingRight="small"></pui-icon>
          </pui-section-column>
          <pui-section-column>
          <pui-heading id="apex-insurace-page-title" input="${this.config.strings.embeddedTitle}" textSize="large"></pui-heading>
          </pui-section-column>
        </pui-section>
      </pui-section>

      <!-- Insurance Loading Container -->
      <pui-section id="apex-insurance-loading-container" 
                   secondaryAxisArrangement="center" 
                   spacingTop="medium" 
                   style="position:relative; 
                   height: 100px"
                   class="${displayLoadingIndicator ? '' : 'pui-hidden'}">
        <pui-loading-indicator id="apex-transfer-med-loading-indicator"></pui-loading-indicator>
      </pui-section>

      <!-- Insurance Content Container -->
      <pui-section id="apex-insurance-content-container" class="${displayLoadingIndicator ? 'pui-hidden' : ''}">
        <!-- No Insurance Found -->
        <pui-box id="apex-no-insurance-found-box" theme="dashed" class="pui-hidden">
        <pui-text input="${strings.noInsuranceFound}" textAlign="center" spacing="medium" textColor="grey"></pui-text>
        </pui-box>

        <!-- Insurance List -->
        <pui-carousel id="insurance-select-carousel" size="small"
                flowDirection="vertical">
          <!-- Default Insurance -->
          <pui-heading id="apex-default-insurance-heading" input="${strings.defaultInsurance}" 
                        class="pui-hidden" textSize="small"></pui-heading>
          <div id="apex-default-insurance">
          </div>

          <!-- Other Insurances -->
          <pui-heading id="apex-other-insurances-heading" input="${strings.otherInsurances}" 
                        class="pui-hidden" textSize="small"></pui-heading>
          <div id="apex-other-insurances">
          </div>
        </pui-carousel>
      </pui-section>

      </pui-section>`,
    };
    super._render(content);

    if (!this.config.isReadOnly) {
      this._addInsuranceButton = this.querySelector('#apex-add-insurance-button');
      this._addInsuranceButton.addEventListener('click', this._addInsurancePress.bind(this));
      this._addInsuranceButton.addEventListener('keyup', this._addInsurancePressEnter.bind(this));

      this._continueButtonDesktop = this.querySelector('#apex-insurance-continue-button-desktop');
      this._continueButtonMobile = this.querySelector('#apex-insurance-continue-button-mobile');
      this._continueButtonDesktop.addEventListener('click', this._onSetPrimaryInsurance.bind(this));
      this._continueButtonDesktop.addEventListener('keyup', this._onSetPrimaryInsurancePressed.bind(this));
      this._continueButtonMobile.addEventListener('click', this._onSetPrimaryInsurance.bind(this));
    }

    this._pageError = this.querySelector('#apex-insurance-page-error');
    this._pageError_Treatment = this.querySelector('#apex-insurance-page-error-treatment');
    this._pageError_Unsupported = this.querySelector('#apex-insurance-page-insurance-unsupported-error');
    this._pageError_Duplicate = this.querySelector('#apex-insurance-page-insurance-duplicate-error');

    this._insurancePageTitle = this.querySelector('#apex-insurance-page-title');
    this._insurancePageDescription = this.querySelector('#apex-insurance-page-description');
    this._insuranceLoadingContainer = this.querySelector('#apex-insurance-loading-container');
    this._insuranceContentContainer = this.querySelector('#apex-insurance-content-container');
    this._noActiveInsuranceFoundBox = this.querySelector('#apex-no-active-insurance-found-box');
    this._noInactiveInsuranceFoundBox = this.querySelector('#apex-no-inactive-insurance-found-box');
    this._noInsuranceFoundBox = this.querySelector('#apex-no-insurance-found-box');
    this._defaultInsuranceHeader = this.querySelector('#apex-default-insurance-heading');
    this._defaultInsuranceContainer = this.querySelector('#apex-default-insurance');
    this._otherInsurancesContainer = this.querySelector('#apex-other-insurances');
    this._otherInsurancesHeader = this.querySelector('#apex-other-insurances-heading');
    this._insurancesCarousel = this.querySelector('#insurance-select-carousel');
    this._insurancesCarousel.addEventListener('click', this._onCarouselPress.bind(this));
    this._insurancesCarousel.addEventListener('keyup', this._onCarouselPressEnter.bind(this));


    this._fetchInsurances();

    if (urlParams.get("insuranceStatus") === "ADD_INSURANCE_FAILED") {
      this.showGeneralAddInsuranceError_Treatment();
      if (window.history.replaceState) {
        urlParams.delete("insuranceStatus");
        const newUrl = window.location.origin + window.location.pathname + "?" + urlParams;
        window.history.replaceState(null, '', newUrl);
      }
    }

    if (urlParams.get("insuranceStatus") === "UNSUPPORTED_INSURANCE_PLAN") {
      this.showAddUnsupportedInsuranceError();
      if (window.history.replaceState) {
        urlParams.delete("insuranceStatus");
        const newUrl = window.location.origin + window.location.pathname + "?" + urlParams;
        window.history.replaceState(null, '', newUrl);
      }
    }

    if (urlParams.get("insuranceStatus") === "DUPLICATE_INSURANCE_PLAN") {
      this.showDuplicateInsuranceError();
      if (window.history.replaceState) {
        urlParams.delete("insuranceStatus");
        const newUrl = window.location.origin + window.location.pathname + "?" + urlParams;
        window.history.replaceState(null, '', newUrl);
      }
    }
  }

  _addInsurancePressEnter(event) {
    if (event.keyCode === keyCodes.ENTER_KEYCODE) { // Pressed Enter
      this._addInsurancePress();
    }
  }

  _addInsurancePress() {
    window.location.href = this.config.insuranceCardCaptureUrl + window.location.search;
  }

  _fetchInsurances() {
    // Fetch Insurances
    const skipPolling = !this.config.displayLoadingIndicator && this.config.pageType === 'dashboard';

    const isDashboard = this.config.pageType === 'dashboard';
    Request.get(this.config.getInsurancesUrl, { skipPolling }).then((response) => {
      const { primaryInsurance, insurances } = response;
      const { strings } = this.config;
      // If primary insurance present, then add it
      if (primaryInsurance) {
        if (this.config.pageType === 'signup') {
          this._addInsurance(primaryInsurance, this._defaultInsuranceContainer);
        }
      }

      if (this.config.pageType === 'signup') {
        const continueWithoutInsurance = this._addInsurance(null, this._otherInsurancesContainer);
        if (!primaryInsurance && (insurances.length === 0
            || this.config.insuranceReviewed === 'true')) {
          continueWithoutInsurance.select();
        }
      }
      const notAvailableInsuranceStates = ['INIT', 'VERIFIED', 'UNVERIFIED'];
      const activeInsurances = [];
      const inactiveInsurances = [];

      if (insurances) {
        if (primaryInsurance && primaryInsurance.insuranceState === 'ACTIVE') {
          activeInsurances.push(primaryInsurance);
        }
        insurances.forEach((insurance) => {
          if (insurance.insuranceState === 'ACTIVE') {
            activeInsurances.push(insurance);
          } else if (notAvailableInsuranceStates.includes(insurance.insuranceState)) {
            inactiveInsurances.push(insurance);
          }
        });
        if (isDashboard) {
          activeInsurances.forEach((insurance) => {
            this._addInsurance(insurance, this._defaultInsuranceContainer);
          });
          inactiveInsurances.forEach((insurance) => {
            this._addInsurance(insurance, this._otherInsurancesContainer);
          });
        } else {
          insurances.forEach((insurance) => {
            this._addInsurance(insurance, this._otherInsurancesContainer);
          });
        }
      }
      if (isDashboard) {
        if (activeInsurances.length === 0) {
          this._noActiveInsuranceFoundBox.show();
        }
        if (inactiveInsurances.length === 0) {
          this._noInactiveInsuranceFoundBox.show();
        }
      } else {
        if (!primaryInsurance && insurances.length === 0) {
          this._noInsuranceFoundBox.show();
        } else if (!primaryInsurance && insurances.length === 1 && this.config.insuranceReviewed !== 'true') {
          const selectedInsurance = this._getSelectedInsuranceItem();
          if (selectedInsurance) {
            selectedInsurance.unselect();
          }
          const insuranceItems = this.querySelectorAll('pui-insurance-item');
          if (insuranceItems) {
            insuranceItems[0].select();
          }
        }
      }

      if (!this.config.embeddable && this.config.displayLoadingIndicator) {
        this._insurancePageTitle.setText(strings.multiInsurancePageTitle);
        this._insurancePageDescription.setText(strings.multiInsurancePageDescription);
      }

      if (this.config.displayLoadingIndicator) {
        this._insuranceLoadingContainer.hide();
        this._insuranceContentContainer.show();
      }

      this._setSaveButtonState();
    });
  }

  _fetchInsurance(insuranceId, sectionContainer, rank, selectAfterFetch = false) {
    Request.get(this.config.getInsuranceUrl, { insuranceId }).then((response) => {
      this._noInsuranceFoundBox.hide();
      response.insurance.rank = rank;
      const insuranceItem = this._addInsurance(response.insurance, sectionContainer);
      if (selectAfterFetch) {
        const selectedInsurance = this._getSelectedInsuranceItem();
        if (selectedInsurance) {
          selectedInsurance.unselect();
        }
        insuranceItem.select();

        this._setSaveButtonState();
      }
    });
  }

  /**
   * Adds an insurance item into the DOM
   * @param {Insurance} insurance - The insurance object
   * @param {HTMLElement} - The HTMLElement to append the insurance item to
   */
  _addInsurance(insurance, sectionContainer) {
    // Create insurance item
    const insuranceItemConfig = {
      insurance,
      pageType: this.config.pageType,
      embeddable: this.config.embeddable,
      isReadOnly: this.config.isReadOnly,
      saveInsuranceUrl: this.config.saveInsuranceUrl,
      removeInsuranceUrl: this.config.removeInsuranceUrl,
      returnUrl: this.config.returnUrl,
      actionUrl: this.config.actionUrl,
      insurancePageUrl: this.config.insurancePageUrl,
      strings: this.config.strings.insuranceItem,
      weblabPharmacyInsuranceInputSearch: this.config.weblabPharmacyInsuranceInputSearch,
      weblabPurchaseTwo: this.config.weblabPurchaseTwo,
      callbacks: {
        onDelete: this._onInsuranceDelete.bind(this),
      },
    };
    const insuranceItem = new PUIInsuranceItem();
    insuranceItem.configure(insuranceItemConfig);
    // Add to DOM according to sectionName
    sectionContainer.insertBefore(insuranceItem, sectionContainer.firstChild);

    // Handle showing subsection header
    this._handleSectionHeader(sectionContainer);

    // Hide no insurance found box
    this._noInsuranceFoundBox.hide();
    return insuranceItem;
  }

  _handleSectionHeader(sectionContainer) {
    if (this.config.pageType === 'dashboard') {
      const sectionHeader = sectionContainer.getAttribute('id') === 'apex-default-insurance'
        ? this._defaultInsuranceHeader : this._otherInsurancesHeader;
      sectionHeader.show();
    }
  }

  _onInsuranceDelete() {
    const insuranceItems = this.querySelectorAll('pui-insurance-item');
    if (insuranceItems.length === 0 || (insuranceItems.length === 1 && this.config.pageType === 'signup')) {
      this._noInsuranceFoundBox.show();
      this._handleSectionHeader(this._defaultInsuranceContainer);
      this._handleSectionHeader(this._otherInsurancesContainer);
    }
    this._setSaveButtonState();
  }

  _onSaveComplete(insuranceId, previousInsurance) {
    let sectionContainer = this._otherInsurancesContainer;
    let rank;
    const isDashboard = this.config.pageType === 'dashboard';
    if (previousInsurance) {
      const insuranceItem = this.querySelector(`#apex-insurance-item-${previousInsurance.insuranceId}`);
      insuranceItem.remove();
      if (isDashboard) {
        if (previousInsurance.insuranceState === 'ACTIVE') {
          sectionContainer = this._defaultInsuranceContainer;
        }
      }
      if (previousInsurance.rank === 'PRIMARY') {
        rank = 'PRIMARY';
        sectionContainer = this._defaultInsuranceContainer;
      }
    }
    this._fetchInsurance(insuranceId, sectionContainer, rank, !previousInsurance);
    this._setSaveButtonState();
  }

  _getSelectedInsuranceItem() {
    const insuranceItems = this.querySelectorAll('pui-insurance-item');
    const selectedItems = [...insuranceItems].filter(insuranceItem => insuranceItem.isSelected());
    if (selectedItems.length > 0) {
      return selectedItems[0];
    }
    return null;
  }

  _onSetPrimaryInsurancePressed(event) {
    if (event.keyCode === keyCodes.ENTER_KEYCODE) {
      this._onSetPrimaryInsurance();
    }
  }

  _onSetPrimaryInsurance() {
    this._setContinueButtonLoading();
    if (this.config.pageType === 'dashboard') {
      this._removeContinueButtonLoading();
      window.location.href = this.config.returnUrl || this.config.settingsUrl;
    } else {
      const insurance = this._getSelectedInsuranceItem().getInsurance();
      const requestBody = {};
      if (insurance) {
        requestBody.insuranceId = insurance.insuranceId;
      }
      Request.post(this.config.actionUrl, requestBody).then((response) => {
        this._removeContinueButtonLoading();
        window.location.href = response.nextUrl;
      }).catch((response) => {
        this._removeContinueButtonLoading();
        this.showError(response.error);
      });
    }
  }

  _setContinueButtonLoading() {
    this._continueButtonDesktop.displaySpinner();
    this._continueButtonMobile.displaySpinner();
    this._continueButtonDesktop.disable();
    this._continueButtonMobile.disable();
    this._continueButtonDesktop.label += '...';
    this._continueButtonMobile.label += '...';
  }

  _removeContinueButtonLoading() {
    this._continueButtonDesktop.hideSpinner();
    this._continueButtonMobile.hideSpinner();
    this._continueButtonDesktop.enable();
    this._continueButtonMobile.enable();
    this._continueButtonMobile.label = this._continueButtonMobile.label.replaceAll('.', '');
    this._continueButtonDesktop.label = this._continueButtonDesktop.label.replaceAll('.', '');
  }

  _onCarouselPressEnter(event) {
    if (event.keyCode === keyCodes.ENTER_KEYCODE) { // Pressed Enter
      this._onCarouselPress();
    }
  }

  _onCarouselPress() {
    this._setSaveButtonState();
  }

  _setSaveButtonState() {
    if (this._continueButtonDesktop && this._continueButtonMobile) {
      const insuranceItem = this._getSelectedInsuranceItem();
      let planSupportStatus;
      if (insuranceItem && insuranceItem.getInsurance()) {
        planSupportStatus = insuranceItem.getInsurance().planSupportStatus;
      }
      const unsupportedInsuaranceStatusSet = new Set(['UNSUPPORTED_INVALID_STATUS', 'UNSUPPORTED_INVALID_PAYER_TYPE', 'UNSUPPORTED_NON_COMPLIANT']);
      if (insuranceItem && !(unsupportedInsuaranceStatusSet.has(planSupportStatus)) || this.config.pageType === 'dashboard') {
        this._continueButtonDesktop.enable();
        this._continueButtonMobile.enable();
      } else {
        this._continueButtonDesktop.disable();
        this._continueButtonMobile.disable();
      }
    }
  }
}

window.customElements.define('pui-insurance-page', PUIInsurancePage);
