const themesList = [
  'primary',
  'secondary',
  'light',
  'transparent',
  'line-item-delete',
  'dashboard-line-item-request-rx',
  'line-item-button',
  'link',
  'product-card-button-primary',
  'product-card-button-secondary',
  'white-round-button',
  'selected-button',
  'teal-round-button',
  'yellow-button',
  'health-teal-2-button',
  'yellow-round-button',
  'round-button-secondary',
];

export default function getThemes() {
  return themesList;
}
